import { ToastContainer, Flip } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Game } from "./components/Game";
import React, { useEffect, useMemo, useState } from "react";
import { Infos } from "./components/panels/Infos";
import { useTranslation } from "react-i18next";
import { InfosFr } from "./components/panels/InfosFr";
import { Settings } from "./components/panels/Settings";
import { useSettings } from "./hooks/useSettings";
import { Flagdle } from "./components/Flagdle";
import { Stats } from "./components/panels/Stats";
import { useReactPWAInstall } from "@teuteuf/react-pwa-install";
import { InstallButton } from "./components/InstallButton";
import { getDayString, useCountry } from "./hooks/useCountry";

function App() {
  const { t, i18n } = useTranslation();

  const dayString = useMemo(getDayString, []);
  const [country] = useCountry(dayString);

  const { pwaInstall, supported, isInstalled } = useReactPWAInstall();

  const [infoOpen, setInfoOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [statsOpen, setStatsOpen] = useState(false);

  const [settingsData, updateSettings] = useSettings();

  useEffect(() => {
    if (settingsData.theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [settingsData.theme]);

  return (
    <>
      <ToastContainer
        hideProgressBar
        position="top-center"
        transition={Flip}
        theme={settingsData.theme}
        autoClose={2000}
        bodyClassName="font-bold text-center"
      />
      {i18n.resolvedLanguage === "fr" ? (
        <InfosFr
          isOpen={infoOpen}
          close={() => setInfoOpen(false)}
          settingsData={settingsData}
        />
      ) : (
        <Infos
          isOpen={infoOpen}
          close={() => setInfoOpen(false)}
          settingsData={settingsData}
        />
      )}
      <Settings
        isOpen={settingsOpen}
        close={() => setSettingsOpen(false)}
        settingsData={settingsData}
        updateSettings={updateSettings}
      />
      <Stats
        isOpen={statsOpen}
        close={() => setStatsOpen(false)}
        distanceUnit={settingsData.distanceUnit}
      />
      <div className="flex justify-center flex-auto dark:bg-slate-900 dark:text-slate-50">
        <div className="w-full max-w-lg flex flex-col">
          <header className="border-b-2 px-3 border-gray-200 flex">
            <button
              className="mr-3 text-xl"
              type="button"
              onClick={() => setInfoOpen(true)}
            >
              <img
                className={"h-8 w-10 dark:invert"}
                alt="Flag logo"
                src={"./icons/info-svgrepo-com.svg"}
              />
            </button>
            {supported() && !isInstalled() && (
              <InstallButton pwaInstall={pwaInstall} />
            )}
            <h1 className="text-4xl font-bold uppercase tracking-wide text-center my-1 flex-auto">
              <span className="inline-block">
                <img
                  className={"h-8 w-10 dark:invert"}
                  alt="How to play"
                  src={"./icons/flag-svgrepo-com.svg"}
                />
              </span>
              <span className="text-blue-600">F</span>
              <span className="text-yellow-600">L</span>
              <span className="text-gray-600">A</span>
              <span className="text-red-600">G</span>
              <span className="text-green-600">D</span>
              <span className="text-orange-600">L</span>
              <span className="text-black-600">E</span>
            </h1>
            <button
              className="ml-3 text-xl"
              type="button"
              onClick={() => setStatsOpen(true)}
            >
              <img
                className={"h-8 w-10 dark:invert"}
                alt="Game stats"
                src={"./icons/trophy-svgrepo-com.svg"}
              />
            </button>
            <button
              className="ml-3 text-xl"
              type="button"
              onClick={() => setSettingsOpen(true)}
            >
              <img
                className={"h-8 w-10 dark:invert"}
                alt="Settings"
                src={"./icons/gear-svgrepo-com.svg"}
              />
            </button>
          </header>
          <Game settingsData={settingsData} />
          <footer className="flex justify-center items-center text-sm mt-8 mb-1">
            <a href="https://buymeacoffee.com/nickholl">
              <span>
                <img
                  className={"h-7 dark:invert"}
                  alt="Buy me a coffee"
                  src={"./images/bmc-full-logo.svg"}
                />
              </span>
            </a>
            <img
              className={"h-8 w-10 dark:invert"}
              alt="Love"
              src={"./icons/heart-svgrepo-com.svg"}
            />
            <img
              className={"h-6 dark:invert"}
              alt="Flagdle"
              src={"./icons/flag-svgrepo-com.svg"}
            />
          </footer>
        </div>
      </div>
    </>
  );
}

export default App;
