import { Guesses } from "../Guesses";
import { Panel } from "./Panel";
import React from "react";
import { Flagdle } from "../Flagdle";
import { formatDistance } from "../../domain/geography";
import { SettingsData } from "../../hooks/useSettings";
import { Twemoji } from "react-emoji-render";

interface InfosProps {
  isOpen: boolean;
  close: () => void;
  settingsData: SettingsData;
}

export function Infos({ isOpen, close, settingsData }: InfosProps) {
  return (
    <Panel title="How to play" isOpen={isOpen} close={close}>
      <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
        <div>
          Guess the <Flagdle /> in 6 guesses.
        </div>
        <div>Each guess must be a valid country or territory.</div>
        <div>
          After each guess, you will see the flag and how similar that flag is
          to the flag you are trying to guess.
        </div>
        <div>
          Whether you are a {""}
          <a href="https://en.wiktionary.org/wiki/vexillophile">vexillophile</a>
          {""} (flag lover) or just like a fun quiz, a new <Flagdle /> will be
          available every day!
        </div>
      </div>
      <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
        <div className="font-bold">Examples</div>
        <div>
          <Guesses
            rowCount={1}
            guesses={[
              {
                name: "Chile",
                code: "CL",
                direction: "NE",
                distance: 84,
              },
            ]}
            settingsData={settingsData}
          />
          <div className="my-2">
            Your guess <span className="uppercase font-bold">Chile</span> is 16%
            similar to the correct country flag, probably most of the colours
            are wrong.
          </div>
        </div>
        <div>
          <Guesses
            rowCount={1}
            guesses={[
              {
                name: "Nicaragua",
                code: "NI",
                direction: "SE",
                distance: 38,
              },
            ]}
            settingsData={settingsData}
          />
          <div className="my-2">
            Your second guess{" "}
            <span className="uppercase font-bold">Nicaragua</span> is getting
            better! It&apos;s 62% similar, pretty close with colours but not the
            pattern!
          </div>
        </div>
        <div>
          <Guesses
            rowCount={1}
            guesses={[
              {
                name: "Greece",
                code: "GR",
                direction: "N",
                distance: 0,
              },
            ]}
            settingsData={settingsData}
          />
          <div className="my-2">
            The next guess, <span className="uppercase font-bold">Greece</span>,
            is the correct flag, good times!!{" "}
            <Twemoji text="🎉" options={{ className: "inline-block" }} />
          </div>
        </div>
      </div>
      <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3 font-bold">
        A new <Flagdle /> will be available every day!
      </div>
      <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
        <div className="font-bold">Any question or suggestion?</div>
        <div>
          Check the{" "}
          <a
            className="underline"
            href="https://www.google.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Flagdle FAQ
          </a>
          !
        </div>
      </div>
      <div className="space-y-3 text-justify border-b-2 border-gray-200 pb-3 mb-3">
        <Flagdle /> has been <span className="font-bold">heavily</span> inspired
        by Worldle which is heavily inspired by{" "}
        <a
          className="underline"
          href="https://www.powerlanguage.co.uk/wordle/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Wordle
        </a>{" "}
        created by{" "}
        <a
          className="underline"
          href="https://twitter.com/powerlanguish"
          target="_blank"
          rel="noopener noreferrer"
        >
          Josh Wardle (@powerlanguish)
        </a>
        .
      </div>
    </Panel>
  );
}
