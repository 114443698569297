// Source:
// Countries with long/lat => https://developers.google.com/public-data/docs/canonical/countries_csv
// Countries  => https://github.com/djaiss/mapsicon

const countryCodesWithImage = [
  "cy",
  "af",
  "cn",
  "bj",
  "la",
  "me",
  "om",
  "ni",
  "mr",
  "tg",
  "tp",
  "vn",
  "zm",
  "md",
  "co",
  "ag",
  "ae",
  "cz",
  "ar",
  "bi",
  "cm",
  "lb",
  "lu",
  "td",
  "tr",
  "ua",
  "lt",
  "lc",
  "mg",
  "cl",
  "bh",
  "ad",
  "ch",
  "no",
  "mt",
  "mc",
  "tv",
  "tw",
  "us",
  "mu",
  "bz",
  "ci",
  "bm",
  "ck",
  "at",
  "bo",
  "ls",
  "nl",
  "mw",
  "ye",
  "ug",
  "tt",
  "nz",
  "ma",
  "mv",
  "lr",
  "by",
  "bn",
  "au",
  "ge",
  "gr",
  "in",
  "rs",
  "pl",
  "sv",
  "sa",
  "hk",
  "kg",
  "gd",
  "gq",
  "dj",
  "ke",
  "kr",
  "sc",
  "st",
  "py",
  "sb",
  "il",
  "dk",
  "do",
  "ru",
  "sg",
  "pk",
  "gb",
  "gu",
  "gw",
  "jp",
  "sr",
  "se",
  "sd",
  "ss",
  "ph",
  "rw",
  "ku",
  "hn",
  "ga",
  "dz",
  "dm",
  "fr",
  "gl",
  "es",
  "ht",
  "pe",
  "qa",
  "si",
  "pr",
  "ps",
  "iq",
  "hu",
  "kn",
  "ky",
  "fi",
  "er",
  "gm",
  "ee",
  "eg",
  "id",
  "is",
  "sk",
  "ro",
  "pg",
  "km",
  "ir",
  "kz",
  "ie",
  "gn",
  "fj",
  "gy",
  "jm",
  "ki",
  "hr",
  "so",
  "pt",
  "sy",
  "sn",
  "kh",
  "ec",
  "et",
  "de",
  "sl",
  "pw",
  "sz",
  "pa",
  "sm",
  "it",
  "jo",
  "gh",
  "cg",
  "bt",
  "ao",
  "ml",
  "tn",
  "li",
  "mm",
  "mz",
  "na",
  "cf",
  "bb",
  "cd",
  "bw",
  "al",
  "nc",
  "mx",
  "tz",
  "ve",
  "mn",
  "my",
  "am",
  "cr",
  "ba",
  "az",
  "cv",
  "br",
  "ca",
  "be",
  "th",
  "ws",
  "uz",
  "ng",
  "np",
  "mk",
  "bd",
  "bs",
  "cu",
  "bf",
  "nr",
  "ne",
  "zw",
  "vc",
  "uy",
  "tj",
  "vu",
  "za",
  "bg",
];

export interface Country {
  code: string;
  latitude: number;
  longitude: number;
  name: string;
}

export const countries: Country[] = [
  { code: "AD", latitude: 42.546245, longitude: 1.601554, name: "Andorra" },
  {
    code: "AE",
    latitude: 23.424076,
    longitude: 53.847818,
    name: "United Arab Emirates",
  },
  { code: "AF", latitude: 33.93911, longitude: 67.709953, name: "Afghanistan" },
  {
    code: "AG",
    latitude: 17.060816,
    longitude: -61.796428,
    name: "Antigua and Barbuda",
  },
  { code: "AI", latitude: 18.220554, longitude: -63.068615, name: "Anguilla" },
  { code: "AL", latitude: 41.153332, longitude: 20.168331, name: "Albania" },
  { code: "AM", latitude: 40.069099, longitude: 45.038189, name: "Armenia" },
  {
    code: "AN",
    latitude: 12.226079,
    longitude: -69.060087,
    name: "Netherlands Antilles",
  },
  { code: "AO", latitude: -11.202692, longitude: 17.873887, name: "Angola" },
  {
    code: "AQ",
    latitude: -75.250973,
    longitude: -0.071389,
    name: "Antarctica",
  },
  {
    code: "AR",
    latitude: -38.416097,
    longitude: -63.616672,
    name: "Argentina",
  },
  {
    code: "AS",
    latitude: -14.270972,
    longitude: -170.132217,
    name: "American Samoa",
  },
  { code: "AT", latitude: 47.516231, longitude: 14.550072, name: "Austria" },
  {
    code: "AU",
    latitude: -25.274398,
    longitude: 133.775136,
    name: "Australia",
  },
  { code: "AW", latitude: 12.52111, longitude: -69.968338, name: "Aruba" },
  { code: "AZ", latitude: 40.143105, longitude: 47.576927, name: "Azerbaijan" },
  {
    code: "BA",
    latitude: 43.915886,
    longitude: 17.679076,
    name: "Bosnia and Herzegovina",
  },
  { code: "BB", latitude: 13.193887, longitude: -59.543198, name: "Barbados" },
  { code: "BD", latitude: 23.684994, longitude: 90.356331, name: "Bangladesh" },
  { code: "BE", latitude: 50.503887, longitude: 4.469936, name: "Belgium" },
  {
    code: "BF",
    latitude: 12.238333,
    longitude: -1.561593,
    name: "Burkina Faso",
  },
  { code: "BG", latitude: 42.733883, longitude: 25.48583, name: "Bulgaria" },
  { code: "BH", latitude: 25.930414, longitude: 50.637772, name: "Bahrain" },
  { code: "BI", latitude: -3.373056, longitude: 29.918886, name: "Burundi" },
  { code: "BJ", latitude: 9.30769, longitude: 2.315834, name: "Benin" },
  { code: "BM", latitude: 32.321384, longitude: -64.75737, name: "Bermuda" },
  { code: "BN", latitude: 4.535277, longitude: 114.727669, name: "Brunei" },
  { code: "BO", latitude: -16.290154, longitude: -63.588653, name: "Bolivia" },
  { code: "BR", latitude: -14.235004, longitude: -51.92528, name: "Brazil" },
  { code: "BS", latitude: 25.03428, longitude: -77.39628, name: "Bahamas" },
  { code: "BT", latitude: 27.514162, longitude: 90.433601, name: "Bhutan" },
  {
    code: "BV",
    latitude: -54.423199,
    longitude: 3.413194,
    name: "Bouvet Island",
  },
  { code: "BW", latitude: -22.328474, longitude: 24.684866, name: "Botswana" },
  { code: "BY", latitude: 53.709807, longitude: 27.953389, name: "Belarus" },
  { code: "BZ", latitude: 17.189877, longitude: -88.49765, name: "Belize" },
  { code: "CA", latitude: 56.130366, longitude: -106.346771, name: "Canada" },
  {
    code: "CC",
    latitude: -12.164165,
    longitude: 96.870956,
    name: "Cocos Islands",
  },
  {
    code: "CD",
    latitude: -4.038333,
    longitude: 21.758664,
    name: "Democratic Republic of the Congo",
  },
  {
    code: "CF",
    latitude: 6.611111,
    longitude: 20.939444,
    name: "Central African Republic",
  },
  {
    code: "CG",
    latitude: -0.228021,
    longitude: 15.827659,
    name: "Congo",
  },
  { code: "CH", latitude: 46.818188, longitude: 8.227512, name: "Switzerland" },
  {
    code: "CI",
    latitude: 7.539989,
    longitude: -5.54708,
    name: "Côte d'Ivoire",
  },
  {
    code: "CK",
    latitude: -21.236736,
    longitude: -159.777671,
    name: "Cook Islands",
  },
  { code: "CL", latitude: -35.675147, longitude: -71.542969, name: "Chile" },
  { code: "CM", latitude: 7.369722, longitude: 12.354722, name: "Cameroon" },
  { code: "CN", latitude: 35.86166, longitude: 104.195397, name: "China" },
  { code: "CO", latitude: 4.570868, longitude: -74.297333, name: "Colombia" },
  { code: "CR", latitude: 9.748917, longitude: -83.753428, name: "Costa Rica" },
  { code: "CU", latitude: 21.521757, longitude: -77.781167, name: "Cuba" },
  {
    code: "CV",
    latitude: 16.002082,
    longitude: -24.013197,
    name: "Cape Verde",
  },
  {
    code: "CX",
    latitude: -10.447525,
    longitude: 105.690449,
    name: "Christmas Island",
  },
  { code: "CY", latitude: 35.126413, longitude: 33.429859, name: "Cyprus" },
  {
    code: "CZ",
    latitude: 49.817492,
    longitude: 15.472962,
    name: "Czechia",
  },
  { code: "DE", latitude: 51.165691, longitude: 10.451526, name: "Germany" },
  { code: "DJ", latitude: 11.825138, longitude: 42.590275, name: "Djibouti" },
  { code: "DK", latitude: 56.26392, longitude: 9.501785, name: "Denmark" },
  { code: "DM", latitude: 15.414999, longitude: -61.370976, name: "Dominica" },
  {
    code: "DO",
    latitude: 18.735693,
    longitude: -70.162651,
    name: "Dominican Republic",
  },
  { code: "DZ", latitude: 28.033886, longitude: 1.659626, name: "Algeria" },
  { code: "EC", latitude: -1.831239, longitude: -78.183406, name: "Ecuador" },
  { code: "EE", latitude: 58.595272, longitude: 25.013607, name: "Estonia" },
  { code: "EG", latitude: 26.820553, longitude: 30.802498, name: "Egypt" },
  {
    code: "EH",
    latitude: 24.215527,
    longitude: -12.885834,
    name: "Western Sahara",
  },
  { code: "ER", latitude: 15.179384, longitude: 39.782334, name: "Eritrea" },
  { code: "ES", latitude: 40.463667, longitude: -3.74922, name: "Spain" },
  { code: "ET", latitude: 9.145, longitude: 40.489673, name: "Ethiopia" },
  { code: "FI", latitude: 61.92411, longitude: 25.748151, name: "Finland" },
  { code: "FJ", latitude: -16.578193, longitude: 179.414413, name: "Fiji" },
  {
    code: "FK",
    latitude: -51.796253,
    longitude: -59.523613,
    name: "Falkland Islands (Malvinas)",
  },
  { code: "FM", latitude: 7.425554, longitude: 150.550812, name: "Micronesia" },
  {
    code: "FO",
    latitude: 61.892635,
    longitude: -6.911806,
    name: "Faroe Islands",
  },
  { code: "FR", latitude: 46.227638, longitude: 2.213749, name: "France" },
  { code: "GA", latitude: -0.803689, longitude: 11.609444, name: "Gabon" },
  {
    code: "GB",
    latitude: 55.378051,
    longitude: -3.435973,
    name: "United Kingdom",
  },
  { code: "GD", latitude: 12.262776, longitude: -61.604171, name: "Grenada" },
  { code: "GE", latitude: 42.315407, longitude: 43.356892, name: "Georgia" },
  {
    code: "GF",
    latitude: 3.933889,
    longitude: -53.125782,
    name: "French Guiana",
  },
  { code: "GH", latitude: 7.946527, longitude: -1.023194, name: "Ghana" },
  { code: "GI", latitude: 36.137741, longitude: -5.345374, name: "Gibraltar" },
  { code: "GL", latitude: 71.706936, longitude: -42.604303, name: "Greenland" },
  { code: "GM", latitude: 13.443182, longitude: -15.310139, name: "Gambia" },
  { code: "GN", latitude: 9.945587, longitude: -9.696645, name: "Guinea" },
  {
    code: "GP",
    latitude: 16.995971,
    longitude: -62.067641,
    name: "Guadeloupe",
  },
  {
    code: "GQ",
    latitude: 1.650801,
    longitude: 10.267895,
    name: "Equatorial Guinea",
  },
  { code: "GR", latitude: 39.074208, longitude: 21.824312, name: "Greece" },
  {
    code: "GS",
    latitude: -54.429579,
    longitude: -36.587909,
    name: "South Georgia and the South Sandwich Islands",
  },
  { code: "GT", latitude: 15.783471, longitude: -90.230759, name: "Guatemala" },
  { code: "GU", latitude: 13.444304, longitude: 144.793731, name: "Guam" },
  {
    code: "GW",
    latitude: 11.803749,
    longitude: -15.180413,
    name: "Guinea-Bissau",
  },
  { code: "GY", latitude: 4.860416, longitude: -58.93018, name: "Guyana" },
  { code: "GZ", latitude: 31.354676, longitude: 34.308825, name: "Gaza Strip" },
  { code: "HK", latitude: 22.396428, longitude: 114.109497, name: "Hong Kong" },
  {
    code: "HM",
    latitude: -53.08181,
    longitude: 73.504158,
    name: "Heard Island and McDonald Islands",
  },
  { code: "HN", latitude: 15.199999, longitude: -86.241905, name: "Honduras" },
  { code: "HR", latitude: 45.1, longitude: 15.2, name: "Croatia" },
  { code: "HT", latitude: 18.971187, longitude: -72.285215, name: "Haiti" },
  { code: "HU", latitude: 47.162494, longitude: 19.503304, name: "Hungary" },
  { code: "ID", latitude: -0.789275, longitude: 113.921327, name: "Indonesia" },
  { code: "IE", latitude: 53.41291, longitude: -8.24389, name: "Ireland" },
  { code: "IL", latitude: 31.046051, longitude: 34.851612, name: "Israel" },
  {
    code: "IM",
    latitude: 54.236107,
    longitude: -4.548056,
    name: "Isle of Man",
  },
  { code: "IN", latitude: 20.593684, longitude: 78.96288, name: "India" },
  { code: "IQ", latitude: 33.223191, longitude: 43.679291, name: "Iraq" },
  { code: "IR", latitude: 32.427908, longitude: 53.688046, name: "Iran" },
  { code: "IS", latitude: 64.963051, longitude: -19.020835, name: "Iceland" },
  { code: "IT", latitude: 41.87194, longitude: 12.56738, name: "Italy" },
  { code: "JE", latitude: 49.214439, longitude: -2.13125, name: "Jersey" },
  { code: "JM", latitude: 18.109581, longitude: -77.297508, name: "Jamaica" },
  { code: "JO", latitude: 30.585164, longitude: 36.238414, name: "Jordan" },
  { code: "JP", latitude: 36.204824, longitude: 138.252924, name: "Japan" },
  { code: "KE", latitude: -0.023559, longitude: 37.906193, name: "Kenya" },
  { code: "KG", latitude: 41.20438, longitude: 74.766098, name: "Kyrgyzstan" },
  { code: "KH", latitude: 12.565679, longitude: 104.990963, name: "Cambodia" },
  { code: "KI", latitude: -3.370417, longitude: -168.734039, name: "Kiribati" },
  { code: "KM", latitude: -11.875001, longitude: 43.872219, name: "Comoros" },
  {
    code: "KN",
    latitude: 17.357822,
    longitude: -62.782998,
    name: "Saint Kitts and Nevis",
  },
  {
    code: "KP",
    latitude: 40.339852,
    longitude: 127.510093,
    name: "North Korea",
  },
  {
    code: "KR",
    latitude: 35.907757,
    longitude: 127.766922,
    name: "South Korea",
  },
  { code: "KW", latitude: 29.31166, longitude: 47.481766, name: "Kuwait" },
  {
    code: "KY",
    latitude: 19.513469,
    longitude: -80.566956,
    name: "Cayman Islands",
  },
  { code: "KZ", latitude: 48.019573, longitude: 66.923684, name: "Kazakhstan" },
  { code: "LA", latitude: 19.85627, longitude: 102.495496, name: "Laos" },
  { code: "LB", latitude: 33.854721, longitude: 35.862285, name: "Lebanon" },
  {
    code: "LC",
    latitude: 13.909444,
    longitude: -60.978893,
    name: "Saint Lucia",
  },
  { code: "LI", latitude: 47.166, longitude: 9.555373, name: "Liechtenstein" },
  { code: "LK", latitude: 7.873054, longitude: 80.771797, name: "Sri Lanka" },
  { code: "LR", latitude: 6.428055, longitude: -9.429499, name: "Liberia" },
  { code: "LS", latitude: -29.609988, longitude: 28.233608, name: "Lesotho" },
  { code: "LT", latitude: 55.169438, longitude: 23.881275, name: "Lithuania" },
  { code: "LU", latitude: 49.815273, longitude: 6.129583, name: "Luxembourg" },
  { code: "LV", latitude: 56.879635, longitude: 24.603189, name: "Latvia" },
  { code: "LY", latitude: 26.3351, longitude: 17.228331, name: "Libya" },
  { code: "MA", latitude: 31.791702, longitude: -7.09262, name: "Morocco" },
  { code: "MC", latitude: 43.750298, longitude: 7.412841, name: "Monaco" },
  { code: "MD", latitude: 47.411631, longitude: 28.369885, name: "Moldova" },
  { code: "ME", latitude: 42.708678, longitude: 19.37439, name: "Montenegro" },
  {
    code: "MG",
    latitude: -18.766947,
    longitude: 46.869107,
    name: "Madagascar",
  },
  {
    code: "MH",
    latitude: 7.131474,
    longitude: 171.184478,
    name: "Marshall Islands",
  },
  {
    code: "MK",
    latitude: 41.608635,
    longitude: 21.745275,
    name: "Macedonia",
  },
  { code: "ML", latitude: 17.570692, longitude: -3.996166, name: "Mali" },
  {
    code: "MM",
    latitude: 21.913965,
    longitude: 95.956223,
    name: "Myanmar",
  },
  { code: "MN", latitude: 46.862496, longitude: 103.846656, name: "Mongolia" },
  { code: "MO", latitude: 22.198745, longitude: 113.543873, name: "Macau" },
  {
    code: "MP",
    latitude: 17.33083,
    longitude: 145.38469,
    name: "Northern Mariana Islands",
  },
  {
    code: "MQ",
    latitude: 14.641528,
    longitude: -61.024174,
    name: "Martinique",
  },
  { code: "MR", latitude: 21.00789, longitude: -10.940835, name: "Mauritania" },
  {
    code: "MS",
    latitude: 16.742498,
    longitude: -62.187366,
    name: "Montserrat",
  },
  { code: "MT", latitude: 35.937496, longitude: 14.375416, name: "Malta" },
  { code: "MU", latitude: -20.348404, longitude: 57.552152, name: "Mauritius" },
  { code: "MV", latitude: 3.202778, longitude: 73.22068, name: "Maldives" },
  { code: "MW", latitude: -13.254308, longitude: 34.301525, name: "Malawi" },
  { code: "MX", latitude: 23.634501, longitude: -102.552784, name: "Mexico" },
  { code: "MY", latitude: 4.040255, longitude: 109.091912, name: "Malaysia" },
  {
    code: "MZ",
    latitude: -18.665695,
    longitude: 35.529562,
    name: "Mozambique",
  },
  { code: "NA", latitude: -22.95764, longitude: 18.49041, name: "Namibia" },
  {
    code: "NC",
    latitude: -20.904305,
    longitude: 165.618042,
    name: "New Caledonia",
  },
  { code: "NE", latitude: 17.607789, longitude: 8.081666, name: "Niger" },
  {
    code: "NF",
    latitude: -29.040835,
    longitude: 167.954712,
    name: "Norfolk Island",
  },
  { code: "NG", latitude: 9.081999, longitude: 8.675277, name: "Nigeria" },
  { code: "NI", latitude: 12.865416, longitude: -85.207229, name: "Nicaragua" },
  { code: "NL", latitude: 52.132633, longitude: 5.291266, name: "Netherlands" },
  { code: "NO", latitude: 60.472024, longitude: 8.468946, name: "Norway" },
  { code: "NP", latitude: 28.394857, longitude: 84.124008, name: "Nepal" },
  { code: "NR", latitude: -0.522778, longitude: 166.931503, name: "Nauru" },
  { code: "NU", latitude: -19.054445, longitude: -169.867233, name: "Niue" },
  {
    code: "NZ",
    latitude: -40.900557,
    longitude: 174.885971,
    name: "New Zealand",
  },
  { code: "OM", latitude: 21.512583, longitude: 55.923255, name: "Oman" },
  { code: "PA", latitude: 8.537981, longitude: -80.782127, name: "Panama" },
  { code: "PE", latitude: -9.189967, longitude: -75.015152, name: "Peru" },
  {
    code: "PF",
    latitude: -17.679742,
    longitude: -149.406843,
    name: "French Polynesia",
  },
  {
    code: "PG",
    latitude: -6.314993,
    longitude: 143.95555,
    name: "Papua New Guinea",
  },
  {
    code: "PH",
    latitude: 12.879721,
    longitude: 121.774017,
    name: "Philippines",
  },
  { code: "PK", latitude: 30.375321, longitude: 69.345116, name: "Pakistan" },
  { code: "PL", latitude: 51.919438, longitude: 19.145136, name: "Poland" },
  {
    code: "PM",
    latitude: 46.941936,
    longitude: -56.27111,
    name: "Saint Pierre and Miquelon",
  },
  {
    code: "PN",
    latitude: -24.703615,
    longitude: -127.439308,
    name: "Pitcairn Islands",
  },
  {
    code: "PR",
    latitude: 18.220833,
    longitude: -66.590149,
    name: "Puerto Rico",
  },
  {
    code: "PS",
    latitude: 31.952162,
    longitude: 35.233154,
    name: "Palestine",
  },
  { code: "PT", latitude: 39.399872, longitude: -8.224454, name: "Portugal" },
  { code: "PW", latitude: 7.51498, longitude: 134.58252, name: "Palau" },
  { code: "PY", latitude: -23.442503, longitude: -58.443832, name: "Paraguay" },
  { code: "QA", latitude: 25.354826, longitude: 51.183884, name: "Qatar" },
  { code: "RE", latitude: -21.115141, longitude: 55.536384, name: "Réunion" },
  { code: "RO", latitude: 45.943161, longitude: 24.96676, name: "Romania" },
  { code: "RS", latitude: 44.016521, longitude: 21.005859, name: "Serbia" },
  { code: "RU", latitude: 61.52401, longitude: 105.318756, name: "Russia" },
  { code: "RW", latitude: -1.940278, longitude: 29.873888, name: "Rwanda" },
  {
    code: "SA",
    latitude: 23.885942,
    longitude: 45.079162,
    name: "Saudi Arabia",
  },
  {
    code: "SB",
    latitude: -9.64571,
    longitude: 160.156194,
    name: "Solomon Islands",
  },
  { code: "SC", latitude: -4.679574, longitude: 55.491977, name: "Seychelles" },
  { code: "SD", latitude: 15.8905231, longitude: 30.7671933, name: "Sudan" },
  { code: "SS", latitude: 6.85065, longitude: 30.659016, name: "South Sudan" },
  { code: "SE", latitude: 60.128161, longitude: 18.643501, name: "Sweden" },
  { code: "SG", latitude: 1.352083, longitude: 103.819836, name: "Singapore" },
  {
    code: "SH",
    latitude: -24.143474,
    longitude: -10.030696,
    name: "Saint Helena",
  },
  { code: "SI", latitude: 46.151241, longitude: 14.995463, name: "Slovenia" },
  {
    code: "SJ",
    latitude: 77.553604,
    longitude: 23.670272,
    name: "Svalbard and Jan Mayen",
  },
  { code: "SK", latitude: 48.669026, longitude: 19.699024, name: "Slovakia" },
  {
    code: "SL",
    latitude: 8.460555,
    longitude: -11.779889,
    name: "Sierra Leone",
  },
  { code: "SM", latitude: 43.94236, longitude: 12.457777, name: "San Marino" },
  { code: "SN", latitude: 14.497401, longitude: -14.452362, name: "Senegal" },
  { code: "SO", latitude: 5.152149, longitude: 46.199616, name: "Somalia" },
  { code: "SR", latitude: 3.919305, longitude: -56.027783, name: "Suriname" },
  {
    code: "ST",
    latitude: 0.18636,
    longitude: 6.613081,
    name: "São Tomé and Príncipe",
  },
  {
    code: "SV",
    latitude: 13.794185,
    longitude: -88.89653,
    name: "El Salvador",
  },
  { code: "SY", latitude: 34.802075, longitude: 38.996815, name: "Syria" },
  { code: "SZ", latitude: -26.522503, longitude: 31.465866, name: "Eswatini" },
  {
    code: "TC",
    latitude: 21.694025,
    longitude: -71.797928,
    name: "Turks and Caicos Islands",
  },
  { code: "TD", latitude: 15.454166, longitude: 18.732207, name: "Chad" },
  {
    code: "TF",
    latitude: -49.280366,
    longitude: 69.348557,
    name: "French Southern Territories",
  },
  { code: "TG", latitude: 8.619543, longitude: 0.824782, name: "Togo" },
  { code: "TH", latitude: 15.870032, longitude: 100.992541, name: "Thailand" },
  { code: "TJ", latitude: 38.861034, longitude: 71.276093, name: "Tajikistan" },
  { code: "TK", latitude: -8.967363, longitude: -171.855881, name: "Tokelau" },
  {
    code: "TL",
    latitude: -8.874217,
    longitude: 125.727539,
    name: "Timor-Leste",
  },
  { code: "TN", latitude: 33.886917, longitude: 9.537499, name: "Tunisia" },
  { code: "TR", latitude: 38.963745, longitude: 35.243322, name: "Turkey" },
  {
    code: "TT",
    latitude: 10.691803,
    longitude: -61.222503,
    name: "Trinidad and Tobago",
  },
  { code: "TV", latitude: -7.109535, longitude: 177.64933, name: "Tuvalu" },
  { code: "TW", latitude: 23.69781, longitude: 120.960515, name: "Taiwan" },
  { code: "TZ", latitude: -6.369028, longitude: 34.888822, name: "Tanzania" },
  { code: "UA", latitude: 48.379433, longitude: 31.16558, name: "Ukraine" },
  { code: "UG", latitude: 1.373333, longitude: 32.290275, name: "Uganda" },
  {
    code: "US",
    latitude: 37.09024,
    longitude: -95.712891,
    name: "United States",
  },
  { code: "UY", latitude: -32.522779, longitude: -55.765835, name: "Uruguay" },
  { code: "UZ", latitude: 41.377491, longitude: 64.585262, name: "Uzbekistan" },
  {
    code: "VA",
    latitude: 41.902916,
    longitude: 12.453389,
    name: "Vatican City",
  },
  {
    code: "VC",
    latitude: 12.984305,
    longitude: -61.287228,
    name: "Saint Vincent and the Grenadines",
  },
  { code: "VE", latitude: 6.42375, longitude: -66.58973, name: "Venezuela" },
  {
    code: "VG",
    latitude: 18.420695,
    longitude: -64.639968,
    name: "British Virgin Islands",
  },
  {
    code: "VI",
    latitude: 18.335765,
    longitude: -64.896335,
    name: "U.S. Virgin Islands",
  },
  { code: "VN", latitude: 14.058324, longitude: 108.277199, name: "Vietnam" },
  { code: "VU", latitude: -15.376706, longitude: 166.959158, name: "Vanuatu" },
  {
    code: "WF",
    latitude: -13.768752,
    longitude: -177.156097,
    name: "Wallis and Futuna",
  },
  { code: "WS", latitude: -13.759029, longitude: -172.104629, name: "Samoa" },
  { code: "XK", latitude: 42.602636, longitude: 20.902977, name: "Kosovo" },
  { code: "YE", latitude: 15.552727, longitude: 48.516388, name: "Yemen" },
  { code: "YT", latitude: -12.8275, longitude: 45.166244, name: "Mayotte" },
  {
    code: "ZA",
    latitude: -30.559482,
    longitude: 22.937506,
    name: "South Africa",
  },
  { code: "ZM", latitude: -13.133897, longitude: 27.849332, name: "Zambia" },
  { code: "ZW", latitude: -19.015438, longitude: 29.154857, name: "Zimbabwe" },
];

export const countriesWithImage = countries.filter((c) =>
  countryCodesWithImage.includes(c.code.toLowerCase())
);

// Source: https://fr.wikipedia.org/wiki/ISO_3166
const frenchCountryNames: Record<string, string> = {
  AF: "Afghanistan",
  AX: "Åland",
  AL: "Albanie",
  DZ: "Algérie",
  AS: "Samoa américaines",
  AD: "Andorre",
  AO: "Angola",
  AI: "Anguilla",
  AQ: "Antarctique",
  AG: "Antigua-et-Barbuda",
  AR: "Argentine",
  AM: "Arménie",
  AW: "Aruba",
  AU: "Australie",
  AT: "Autriche",
  AZ: "Azerbaïdjan",
  BS: "Bahamas",
  BH: "Bahreïn",
  BD: "Bangladesh",
  BB: "Barbade",
  BY: "Bélarus",
  BE: "Belgique",
  BZ: "Belize",
  BJ: "Bénin",
  BM: "Bermudes",
  BT: "Bhoutan",
  BO: "Bolivie",
  BQ: "Bonaire, Saint-Eustache et Saba",
  BA: "Bosnie-Herzégovine",
  BW: "Botswana",
  BV: "Bouvet",
  BR: "Brésil",
  IO: "Territoire britannique de l'océan Indien (Diego Garcia)",
  BN: "Brunéi Darussalam",
  BG: "Bulgarie",
  BF: "Burkina Faso",
  BI: "Burundi",
  CV: "Cabo Verde",
  KH: "Cambodge",
  CM: "Cameroun",
  CA: "Canada",
  KY: "Caïmans",
  CF: "République centrafricaine",
  TD: "Tchad",
  CL: "Chili",
  CN: "Chine",
  CX: "Christmas",
  CC: "Cocos",
  CO: "Colombie",
  KM: "Comores",
  CD: "République démocratique du Congo",
  CG: "Congo",
  CK: "Cook",
  CR: "Costa Rica",
  HR: "Croatie",
  CU: "Cuba",
  CW: "Curaçao",
  CY: "Chypre",
  CZ: "Tchéquie",
  DK: "Danemark",
  DJ: "Djibouti",
  DM: "Dominique",
  DO: "République dominicaine",
  EC: "Équateur",
  EG: "Égypte",
  SV: "Salvador",
  GQ: "Guinée équatoriale",
  ER: "Érythrée",
  EE: "Estonie",
  SZ: "Eswatini",
  ET: "Éthiopie",
  FK: "Îles Falkland (Malvinas)",
  FO: "Féroé",
  FJ: "Fidji",
  FI: "Finlande",
  FR: "France",
  GF: "Guyane française",
  PF: "Polynésie française",
  TF: "Terres australes françaises",
  GA: "Gabon",
  GM: "Gambie",
  GE: "Géorgie",
  DE: "Allemagne",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Grèce",
  GL: "Groenland",
  GD: "Grenade",
  GP: "Guadeloupe",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernesey",
  GN: "Guinée",
  GW: "Guinée-Bissau",
  GY: "Guyana",
  HT: "Haïti",
  HM: "Heard-et-Îles MacDonald",
  VA: "Saint-Siège",
  HN: "Honduras",
  HK: "Hong Kong",
  HU: "Hongrie",
  IS: "Islande",
  IN: "Inde",
  ID: "Indonésie",
  IR: "Iran",
  IQ: "Iraq",
  IE: "Irlande",
  IM: "Île de Man",
  IL: "Israël",
  IT: "Italie",
  CI: "Côte d'Ivoire",
  JM: "Jamaïque",
  JP: "Japon",
  JE: "Jersey",
  JO: "Jordanie",
  KZ: "Kazakhstan",
  KE: "Kenya",
  KI: "Kiribati",
  KP: "Corée du Nord",
  KR: "Corée du Sud",
  KW: "Koweït",
  KG: "Kirghizistan",
  LA: "Lao",
  LV: "Lettonie",
  LB: "Liban",
  LS: "Lesotho",
  LR: "Libéria",
  LY: "Libye",
  LI: "Liechtenstein",
  LT: "Lituanie",
  LU: "Luxembourg",
  MO: "Macao",
  MG: "Madagascar",
  MW: "Malawi",
  MY: "Malaisie",
  MV: "Maldives",
  ML: "Mali",
  MT: "Malte",
  MH: "Marshall",
  MQ: "Martinique",
  MR: "Mauritanie",
  MU: "Maurice",
  YT: "Mayotte",
  MX: "Mexique",
  FM: "Micronésie",
  MD: "Moldova",
  MC: "Monaco",
  MN: "Mongolie",
  ME: "Monténégro",
  MS: "Montserrat",
  MA: "Maroc",
  MZ: "Mozambique",
  MM: "Myanmar",
  NA: "Namibie",
  NR: "Nauru",
  NP: "Népal",
  NL: "Pays-Bas",
  NC: "Nouvelle-Calédonie",
  NZ: "Nouvelle-Zélande",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigéria",
  NU: "Niue",
  NF: "Norfolk",
  MK: "Macédoine du Nord",
  MP: "Mariannes du Nord",
  NO: "Norvège",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palaos",
  PS: "Palestine",
  PA: "Panama",
  PG: "Papouasie-Nouvelle-Guinée",
  PY: "Paraguay",
  PE: "Pérou",
  PH: "Philippines",
  PN: "Pitcairn",
  PL: "Pologne",
  PT: "Portugal",
  PR: "Porto Rico",
  QA: "Qatar",
  RE: "Réunion",
  RO: "Roumanie",
  RU: "Russie",
  RW: "Rwanda",
  BL: "Saint-Barthélemy",
  SH: "Sainte-Hélène, Ascension et Tristan da Cunha",
  KN: "Saint-Kitts-et-Nevis",
  LC: "Sainte-Lucie",
  MF: "Saint-Martin",
  PM: "Saint-Pierre-et-Miquelon",
  VC: "Saint-Vincent-et-les Grenadines",
  WS: "Samoa",
  SM: "Saint-Marin",
  ST: "Sao Tomé-et-Principe",
  SA: "Arabie saoudite",
  SN: "Sénégal",
  RS: "Serbie",
  SC: "Seychelles",
  SL: "Sierra Leone",
  SG: "Singapour",
  SX: "Saint-Martin",
  SK: "Slovaquie",
  SI: "Slovénie",
  SB: "Salomon",
  SO: "Somalie",
  ZA: "Afrique du Sud",
  GS: "Géorgie du Sud-et-les Îles Sandwich du Sud",
  SS: "Soudan du Sud",
  ES: "Espagne",
  LK: "Sri Lanka",
  SD: "Soudan",
  SR: "Suriname",
  SJ: "Svalbard et l'Île Jan Mayen",
  SE: "Suède",
  CH: "Suisse",
  SY: "Syrie",
  TW: "Taïwan",
  TJ: "Tadjikistan",
  TZ: "Tanzanie",
  TH: "Thaïlande",
  TL: "Timor-Leste",
  TG: "Togo",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinité-et-Tobago",
  TN: "Tunisie",
  TR: "Turquie",
  TM: "Turkménistan",
  TC: "Turks-et-Caïcos",
  TV: "Tuvalu",
  UG: "Ouganda",
  UA: "Ukraine",
  AE: "Émirats arabes unis",
  GB: "Royaume-Uni",
  UM: "Îles mineures éloignées des États-Unis",
  US: "États-Unis d'Amérique",
  UY: "Uruguay",
  UZ: "Ouzbékistan",
  VU: "Vanuatu",
  VE: "Venezuela",
  VN: "Viet Nam",
  VG: "Vierges britanniques",
  VI: "Vierges des États-Unis",
  WF: "Wallis-et-Futuna",
  EH: "Sahara occidental",
  YE: "Yémen",
  ZM: "Zambie",
  ZW: "Zimbabwe",
  AN: "Antilles néerlandaises",
  GZ: "Bande de Gaza",
  XK: "Kosovo",
};

export const cyMap = new Map<string, number>([
  ["CY", 0],
  ["AF", 93.72085320614732],
  ["CN", 93.24258081611023],
  ["BJ", 93.62844462109167],
  ["LA", 94.34850291467939],
  ["ME", 92.95475622681505],
  ["OM", 72.1465951245363],
  ["NI", 70.93667196608374],
  ["MR", 94.73469793322734],
  ["TG", 90.54815845257022],
  ["TP", 89.39984101748809],
  ["North Korea", 87.14030206677266],
  ["VN", 93.28100158982512],
  ["ZM", 95.16096979332274],
  ["MD", 91.46098304186539],
  ["CO", 94.36406995230526],
  ["AG", 89.31736883942766],
  ["AE", 81.82200582935877],
  ["CZ", 65.77040275569688],
  ["AR", 71.68124006359301],
  ["BI", 71.41991255961844],
  ["CM", 93.60029146793852],
  ["LB", 58.85400105988341],
  ["LU", 71.64348171701113],
  ["TD", 93.19654213036566],
  ["TR", 90.53557233704292],
  ["Scotland", 65.4481319554849],
  ["UA", 94.12592739798622],
  ["LT", 95.99032856385797],
  ["LC", 92.53941441441441],
  ["MG", 63.58604928457869],
  ["CL", 69.93508214096448],
  ["BH", 64.62837837837839],
  ["AD", 89.57869634340223],
  ["CH", 85.83797032326444],
  ["NO", 81.94488606253312],
  ["MT", 55.30339162692104],
  ["MC", 52.51987281399047],
  ["TV", 85.8535373608903],
  ["TW", 92.07670906200318],
  ["US", 64.89103073661897],
  ["MU", 95.72303921568628],
  ["BZ", 81.7713301536831],
  ["CI", 74.90229199788023],
  ["BM", 84.72111817700053],
  ["CK", 86.74483306836248],
  ["AT", 71.83591679915209],
  ["BO", 94.23025967143614],
  ["LS", 69.3667196608373],
  ["NL", 71.91474562798092],
  ["MW", 95.72270800211977],
  ["YE", 73.29193163751987],
  ["UG", 94.37632485426603],
  ["TT", 88.66057233704292],
  ["NZ", 89.44223635400105],
  ["MA", 94.36340752517222],
  ["MV", 94.06763381028087],
  ["LR", 63.02530471648119],
  ["BY", 90.3921568627451],
  ["BN", 73.30385532591414],
  ["AU", 87.95376258611553],
  ["GE", 37.629835718071014],
  ["GR", 58.738407525172235],
  ["IN", 71.67329093799682],
  ["GBENG", 32.75337837837838],
  ["RS", 64.30080816110227],
  ["PL", 55.246754107048226],
  ["SV", 72.78120031796502],
  ["SA", 92.73449920508745],
  ["HK", 90.22688129305776],
  ["KG", 88.80696873343932],
  ["GD", 86.9223635400106],
  ["GQ", 78.38334658187598],
  ["DJ", 73.88977212506624],
  ["KE", 89.60618706942236],
  ["KR", 24.864864864864867],
  ["SC", 77.6589825119237],
  ["ST", 89.5604795972443],
  ["PY", 71.03106783253843],
  ["SB", 89.418057763646],
  ["IL", 42.776563328033916],
  ["DK", 80.02517223105458],
  ["DO", 72.59273979862215],
  ["RU", 66.84784048754638],
  ["SG", 49.460121886592475],
  ["PK", 69.57074721780604],
  ["GB", 66.84220985691573],
  ["GU", 93.20945945945947],
  ["GW", 93.5171568627451],
  ["JP", 20.660108638049817],
  ["SR", 79.62572866984632],
  ["SE", 93.55491520932698],
  ["SD", 81.91077106518283],
  ["SS", 90.05564387917329],
  ["PH", 78.64699258081612],
  ["RW", 93.82419183889772],
  ["KU", 80.36433492315845],
  ["HN", 71.46032061473238],
  ["GA", 93.50225225225225],
  ["DZ", 53.93746687864335],
  ["DM", 85.20932697403286],
  ["FR", 75.63029941706412],
  ["GL", 53.263447270800214],
  ["ES", 90.8790408055114],
  ["HT", 96.22747747747748],
  ["PE", 75.19972178060414],
  ["QA", 66.89851616322204],
  ["SI", 66.43846051934287],
  ["PR", 74.20806836248013],
  ["PS", 81.27351616322204],
  ["IQ", 73.6469925808161],
  ["HU", 71.81372549019608],
  ["KN", 88.54961579226284],
  ["KY", 87.16017488076312],
  ["FI", 42.81664016958134],
  ["ER", 90.07386062533122],
  ["GM", 88.2369501854796],
  ["EE", 67.72522522522523],
  ["EG", 72.45594859565448],
  ["ID", 52.57187334393216],
  ["IS", 83.81359300476947],
  ["SK", 65.48655272919979],
  ["RO", 94.3448595654478],
  ["PG", 93.71356650768415],
  ["KM", 80.44780074191839],
  ["IR", 67.49271330153684],
  ["KZ", 89.52437731849497],
  ["IE", 75.24509803921569],
  ["GN", 93.7543057763646],
  ["FJ", 85.72635135135135],
  ["GY", 84.06796502384738],
  ["JM", 87.53510863804982],
  ["KI", 74.99271330153682],
  ["HR", 70.04537625861155],
  ["SO", 93.65825384207737],
  ["PT", 91.6378510863805],
  ["SY", 73.91593799682035],
  ["SN", 92.83485691573927],
  ["KH", 91.15461049284579],
  ["EC", 90.40242448330683],
  ["ET", 93.7023052464229],
  ["DE", 97.02172760996291],
  ["SL", 71.84154742978272],
  ["PW", 93.74635665076842],
  ["SZ", 92.27212506624272],
  ["PA", 55.32922628510863],
  ["SM", 53.92421833598304],
  ["IT", 75.1725622681505],
  ["JO", 81.55902225755167],
  ["GH", 93.76788553259141],
  ["CG", 89.70886327503975],
  ["BT", 87.33969263381029],
  ["AO", 93.68607578166402],
  ["ML", 93.68905670376259],
  ["TN", 90.09903285638579],
  ["LI", 95.60910174880763],
  ["MM", 88.97091944886063],
  ["MZ", 90.09837042925278],
  ["NA", 87.1810413354531],
  ["CF", 78.68375728669847],
  ["BB", 92.54968203497616],
  ["CD", 90.6293057763646],
  ["BW", 91.38116057233704],
  ["AL", 95.16626921038686],
  ["NC", 93.07299947005829],
  ["MX", 74.66481187069422],
  ["TZ", 90.8065050344462],
  ["VE", 94.35446475887652],
  ["MN", 94.19150768415474],
  ["MY", 63.163089560148386],
  ["AM", 99.12758346581876],
  ["CR", 67.59638314785373],
  ["BA", 89.55153683094859],
  ["AZ", 93.34161367249602],
  ["CV", 84.59856915739269],
  ["BR", 91.88029941706412],
  ["CA", 66.07545045045045],
  ["BE", 94.5601483836778],
  ["TH", 70.3832140964494],
  ["WS", 95.06094329623741],
  ["UZ", 71.80743243243242],
  ["Wales", 59.760863804981454],
  ["NG", 75.16593799682035],
  ["NP", 50.880696873343936],
  ["MK", 86.52325119236885],
  ["BD", 94.9827768945416],
  ["BS", 94.67011128775835],
  ["CU", 74.18852676205617],
  ["BF", 93.87751722310546],
  ["NR", 94.82346316905141],
  ["NE", 71.91474562798092],
  ["ZW", 81.15461049284579],
  ["VC", 90.55312665606783],
  ["UY", 45.841944886062535],
  ["TJ", 65.54153418124007],
  ["VU", 91.26821674615793],
  ["ZA", 85.04338897721252],
  ["BG", 65.18282988871225],
]);

export const afMap = new Map<string, number>([
  ["CY", 93.86110391293082],
  ["AF", 0],
  ["CN", 89.01788027986525],
  ["BJ", 92.0316144078777],
  ["LA", 90.63552733868877],
  ["ME", 75.1431718061674],
  ["OM", 78.92588753563099],
  ["NI", 93.39952060119202],
  ["MR", 88.65314848406322],
  ["TG", 91.34749935216378],
  ["TP", 75.55875874578906],
  ["North Korea", 90.12827157294636],
  ["VN", 90.76120756672714],
  ["ZM", 77.32832339984452],
  ["MD", 93.65735941953875],
  ["CO", 92.88319512827158],
  ["AG", 77.94992225965277],
  ["AE", 91.20756672713138],
  ["CZ", 81.94804353459445],
  ["AR", 96.59821197201347],
  ["BI", 89.76742679450635],
  ["CM", 90.41202384037315],
  ["LB", 92.79994817310184],
  ["LU", 93.82190982119721],
  ["TD", 94.13351904638508],
  ["TR", 89.996760818865],
  ["Scotland", 90.3210028504794],
  ["UA", 91.67465664679969],
  ["LT", 91.08318217154702],
  ["LC", 95.60345944545219],
  ["MG", 89.42569318476289],
  ["CL", 92.30240995076444],
  ["BH", 89.40334283493134],
  ["AD", 94.65081627364602],
  ["CH", 92.22175434050273],
  ["NO", 89.22842705364083],
  ["MT", 93.04321067634103],
  ["MC", 92.3250842187095],
  ["TV", 86.18910339466183],
  ["TW", 86.32579683855921],
  ["US", 88.90742420316144],
  ["MU", 90.62580979528376],
  ["BZ", 87.63604560767038],
  ["CI", 96.17096398030579],
  ["BM", 83.66804871728426],
  ["CK", 84.18081109095621],
  ["AT", 93.71145374449338],
  ["BO", 92.63215859030836],
  ["LS", 94.05577869914485],
  ["NL", 93.62917854366415],
  ["MW", 72.30597305001297],
  ["YE", 82.84983156258097],
  ["UG", 75.59600932884167],
  ["TT", 84.9284140969163],
  ["NZ", 84.31264576315107],
  ["MA", 89.71268463332468],
  ["MV", 89.19603524229075],
  ["LR", 91.36596268463333],
  ["BY", 90.2853718579943],
  ["BN", 86.0313552733869],
  ["AU", 86.33681005441825],
  ["GE", 90.06672713138119],
  ["GR", 91.9318476289194],
  ["IN", 83.7830396475771],
  ["GBENG", 92.75557139155222],
  ["RS", 89.7133324695517],
  ["PL", 93.76619590567505],
  ["SV", 93.30688002073076],
  ["SA", 88.44324954651465],
  ["HK", 91.26975900492356],
  ["KG", 87.2888053899974],
  ["GD", 88.36745270795544],
  ["GQ", 93.40470329100803],
  ["DJ", 93.92005700958798],
  ["KE", 77.88254729204456],
  ["KR", 87.82877688520342],
  ["SC", 86.85216377299818],
  ["ST", 93.06296968126458],
  ["PY", 92.99948173101839],
  ["SB", 88.213267685929],
  ["IL", 93.72862140450894],
  ["DK", 93.04580202124903],
  ["DO", 93.31821715470329],
  ["RU", 91.38734128012439],
  ["SG", 92.1070873283234],
  ["PK", 89.02144337911376],
  ["GB", 81.29891163513864],
  ["GU", 88.41733609743457],
  ["GW", 91.00252656128531],
  ["JP", 89.75997667789582],
  ["SR", 91.16157035501425],
  ["SE", 92.50161959056751],
  ["SD", 86.45957501943509],
  ["SS", 83.47888053899975],
  ["PH", 88.95989893754859],
  ["RW", 86.32352941176471],
  ["KU", 76.82981342316663],
  ["HN", 93.26962943767816],
  ["GA", 92.4297097693703],
  ["DZ", 90.66338429644986],
  ["DM", 86.16966830785178],
  ["FR", 95.58305260430163],
  ["GL", 91.786732314071],
  ["ES", 76.89880798134232],
  ["HT", 90.56782845296709],
  ["PE", 96.17096398030579],
  ["QA", 88.88539777144338],
  ["SI", 91.40774812127495],
  ["PR", 90.08810572687224],
  ["PS", 86.56743975123089],
  ["IQ", 81.90917336097434],
  ["HU", 93.58642135268204],
  ["KN", 80.65658201606634],
  ["KY", 82.51263280642654],
  ["FI", 91.68761337133972],
  ["ER", 85.42660015548068],
  ["GM", 91.45115314848407],
  ["EE", 83.07786991448562],
  ["EG", 82.75136045607671],
  ["ID", 92.46987561544442],
  ["IS", 90.11110391293082],
  ["SK", 91.11913708214563],
  ["RO", 94.27377558953096],
  ["PG", 61.49682560248769],
  ["KM", 92.48542368489245],
  ["IR", 81.98399844519305],
  ["KZ", 94.90703550142524],
  ["IE", 96.17096398030579],
  ["GN", 94.999352163773],
  ["FJ", 88.61265871987561],
  ["GY", 86.81944804353459],
  ["JM", 74.53744493392071],
  ["KI", 84.58959575019435],
  ["HR", 88.91228297486396],
  ["SO", 95.02591344908006],
  ["PT", 88.54819901528893],
  ["SY", 82.07825861622182],
  ["SN", 95.28763928478881],
  ["KH", 88.81543145892718],
  ["EC", 91.3902565431459],
  ["ET", 79.92128789841928],
  ["DE", 78.55694480435346],
  ["SL", 94.47557657424203],
  ["PW", 95.15871987561545],
  ["SZ", 81.67400881057269],
  ["PA", 92.10676341020991],
  ["SM", 94.34568541072817],
  ["IT", 96.17096398030579],
  ["JO", 87.10158072039388],
  ["GH", 92.80383519046384],
  ["CG", 91.24222596527598],
  ["BT", 91.4994169473957],
  ["AO", 73.26509458408914],
  ["ML", 94.86168696553511],
  ["TN", 91.1052086032651],
  ["LI", 87.05526043016324],
  ["MM", 93.18379113760041],
  ["MZ", 91.27396994039907],
  ["NA", 83.47143042238922],
  ["CF", 89.5756672713138],
  ["BB", 93.9663772998186],
  ["CD", 88.56698626587199],
  ["BW", 90.2879632029023],
  ["AL", 85.29152630215081],
  ["NC", 86.29567245400362],
  ["MX", 94.96177766260689],
  ["TZ", 81.91370821456336],
  ["VE", 90.64718839077482],
  ["MN", 90.11531484840633],
  ["MY", 83.90418502202644],
  ["AM", 81.3601321585903],
  ["CR", 91.33421870951024],
  ["BA", 88.07463073335062],
  ["AZ", 90.23354495983415],
  ["CV", 88.41960352422907],
  ["BR", 88.04450634879503],
  ["CA", 91.16286602746825],
  ["BE", 61.58849442860844],
  ["TH", 92.18320808499611],
  ["WS", 87.00796838559212],
  ["UZ", 93.1449209639803],
  ["Wales", 77.46274941694739],
  ["NG", 96.17096398030579],
  ["NP", 85.40878465923815],
  ["MK", 68.48859808240478],
  ["BD", 86.30798134231667],
  ["BS", 74.71948691370821],
  ["CU", 91.80098471106504],
  ["BF", 89.14841927960612],
  ["NR", 88.12807722207826],
  ["NE", 91.73911635138637],
  ["ZW", 89.67478621404508],
  ["VC", 93.3544959834154],
  ["UY", 92.09996112982638],
  ["TJ", 84.83318217154702],
  ["VU", 73.16727131381187],
  ["ZA", 81.34264058046125],
  ["BG", 90.87425498833895],
]);

export const cnMap = new Map<string, number>([
  ["CY", 93.16517335410985],
  ["AF", 88.92871055707052],
  ["CN", 0],
  ["BJ", 66.93221659524737],
  ["LA", 47.70808985845994],
  ["ME", 84.06116088819634],
  ["OM", 51.91273860537593],
  ["NI", 96.43487858719647],
  ["MR", 92.95383716400467],
  ["TG", 93.40540189585768],
  ["TP", 24.709453317750942],
  ["North Korea", 45.539540319439034],
  ["VN", 9.114725360342813],
  ["ZM", 84.35138293728087],
  ["MD", 91.10699909102713],
  ["CO", 71.6669912998312],
  ["AG", 48.15478509284509],
  ["AE", 74.82469809115699],
  ["CZ", 58.36482275029217],
  ["AR", 96.61472536034282],
  ["BI", 63.908258667705496],
  ["CM", 66.35242176340735],
  ["LB", 45.79567588624854],
  ["LU", 96.83352811323205],
  ["TD", 96.39689650694714],
  ["TR", 8.661537462667185],
  ["Scotland", 89.65231788079471],
  ["UA", 96.18004155304506],
  ["LT", 63.587521101155694],
  ["LC", 97.81554343591742],
  ["MG", 96.61504999350734],
  ["CL", 46.70594727957408],
  ["BH", 31.703674847422413],
  ["AD", 93.13173613816387],
  ["CH", 94.24814959096221],
  ["NO", 38.99331255681081],
  ["MT", 47.73438514478639],
  ["MC", 52.38183352811323],
  ["TV", 78.80762238670303],
  ["TW", 96.88384625373328],
  ["US", 88.25964160498636],
  ["MU", 95.25483703415141],
  ["BZ", 73.03856641994545],
  ["CI", 96.06349824698091],
  ["BM", 21.755291520581743],
  ["CK", 82.34807167900273],
  ["AT", 97.00201272562005],
  ["BO", 67.73535904427996],
  ["LS", 96.91923126866641],
  ["NL", 96.90267497727568],
  ["MW", 61.24626671860798],
  ["YE", 68.08466432930788],
  ["UG", 92.63277496429035],
  ["TT", 30.74827944422802],
  ["NZ", 82.9015712245163],
  ["MA", 2.6778989741592003],
  ["MV", 93.74561745227894],
  ["LR", 91.88741721854305],
  ["BY", 89.69419555901831],
  ["BN", 92.05038306713413],
  ["AU", 93.38332684067005],
  ["GE", 91.72380210362292],
  ["GR", 95.7479548110635],
  ["IN", 97.22406181015452],
  ["GBENG", 69.22412673678743],
  ["RS", 89.45559018309311],
  ["PL", 98.39403973509934],
  ["SV", 96.04726658875471],
  ["SA", 89.32930788209323],
  ["HK", 10.828139202700948],
  ["KG", 10.68692377613297],
  ["GD", 52.63699519542917],
  ["GQ", 63.577457473055446],
  ["DJ", 95.0659005323984],
  ["KE", 92.32664589014414],
  ["KR", 94.40721984157902],
  ["SC", 61.0349305285028],
  ["ST", 93.43559278015842],
  ["PY", 66.35664199454617],
  ["SB", 86.51051811453058],
  ["IL", 93.45929100116868],
  ["DK", 94.54616283599533],
  ["DO", 62.07765225295417],
  ["RU", 63.78846902999611],
  ["SG", 93.6819893520322],
  ["PK", 94.15725230489547],
  ["GB", 56.396571873782634],
  ["GU", 88.14082586677054],
  ["GW", 69.0647318530061],
  ["JP", 95.74925334372159],
  ["SR", 94.41533567069212],
  ["SE", 96.52512660693417],
  ["SD", 94.22315283729385],
  ["SS", 74.96429035190235],
  ["PH", 56.80788209323464],
  ["RW", 91.3693026879626],
  ["KU", 68.39890923256719],
  ["HN", 96.41540059732502],
  ["GA", 96.12615244773406],
  ["DZ", 91.96338137904169],
  ["DM", 87.67530190884301],
  ["FR", 97.17406830281782],
  ["GL", 94.52506168030126],
  ["ES", 45.970653161927025],
  ["HT", 93.2924295546033],
  ["PE", 34.739644202051686],
  ["QA", 94.91007661342682],
  ["SI", 62.17731463446305],
  ["PR", 87.14939618231399],
  ["PS", 82.90676535514868],
  ["IQ", 66.30242825607064],
  ["HU", 68.63913777431503],
  ["KN", 65.9839631216725],
  ["KY", 82.05979742890534],
  ["FI", 96.58291131021946],
  ["ER", 80.33015192832099],
  ["GM", 67.57174392935983],
  ["EE", 97.06693935852486],
  ["EG", 67.0419426048565],
  ["ID", 52.8181405012336],
  ["IS", 80.20938839111804],
  ["SK", 57.060122062069865],
  ["RO", 64.00207765225295],
  ["PG", 55.95929100116868],
  ["KM", 75.59959745487599],
  ["IR", 88.02038696273212],
  ["KZ", 84.71659524737048],
  ["IE", 97.25522659394883],
  ["GN", 67.63277496429035],
  ["FJ", 83.85469419555902],
  ["GY", 76.21023243734581],
  ["JM", 92.43345020127256],
  ["KI", 47.793793013894295],
  ["HR", 90.66160238930009],
  ["SO", 94.62375016231658],
  ["PT", 88.34696792624335],
  ["SY", 66.79619529931178],
  ["SN", 62.80028567718479],
  ["KH", 90.63757953512531],
  ["EC", 65.15939488378133],
  ["ET", 57.33346318659914],
  ["DE", 97.82852876249838],
  ["SL", 97.12472406181016],
  ["PW", 93.22717828853395],
  ["SZ", 84.65426567978184],
  ["PA", 96.26801714063109],
  ["SM", 92.2357486040774],
  ["IT", 97.12082846383586],
  ["JO", 75.54895468121023],
  ["GH", 67.58570315543436],
  ["CG", 61.50305155174652],
  ["BT", 56.05895338267758],
  ["AO", 53.91345279833788],
  ["ML", 63.76314764316322],
  ["TN", 92.7386053759252],
  ["LI", 44.84092974938319],
  ["MM", 93.55148681989353],
  ["MZ", 91.49071549149461],
  ["NA", 86.48389819503961],
  ["CF", 94.87858719646799],
  ["BB", 96.84456564082586],
  ["CD", 72.80677834047526],
  ["BW", 98.02590572652902],
  ["AL", 13.91118036618621],
  ["NC", 93.50473964420205],
  ["MX", 60.67101675107129],
  ["TZ", 91.70107778210622],
  ["VE", 59.63089209193612],
  ["MN", 90.950201272562],
  ["MY", 58.33268406700428],
  ["AM", 95.69796130372679],
  ["CR", 66.33229450720685],
  ["BA", 89.38092455525256],
  ["AZ", 92.4448123620309],
  ["CV", 85.4785092845085],
  ["BR", 87.8032073756655],
  ["CA", 93.05544734450072],
  ["BE", 98.71088170367484],
  ["TH", 64.99123490455784],
  ["WS", 19.663030775223998],
  ["UZ", 92.04064407219842],
  ["Wales", 77.21724451369953],
  ["NG", 97.29677963900792],
  ["NP", 84.41923126866641],
  ["MK", 80.14316322555513],
  ["BD", 90.97357486040774],
  ["BS", 95.5739514348786],
  ["CU", 77.33638488507985],
  ["BF", 52.707440592130894],
  ["NR", 93.64887676925075],
  ["NE", 95.71386832878846],
  ["ZW", 73.71477730164914],
  ["VC", 94.55135696662771],
  ["UY", 95.36326451110246],
  ["TJ", 96.39105310998572],
  ["VU", 94.3370990780418],
  ["ZA", 91.70854434489027],
  ["BG", 63.93617711985456],
]);

export const bjMap = new Map<string, number>([
  ["CY", 93.52097130242825],
  ["AF", 92.00753148941696],
  ["CN", 66.93221659524737],
  ["BJ", 0],
  ["LA", 81.80885599272821],
  ["ME", 89.53739774055317],
  ["OM", 84.86884820153226],
  ["NI", 95.13245033112582],
  ["MR", 95.25808336579665],
  ["TG", 75.49571484222828],
  ["TP", 67.08836514738346],
  ["North Korea", 77.12569796130373],
  ["VN", 70.85508375535645],
  ["ZM", 91.78872873652773],
  ["MD", 70.06265420075314],
  ["CO", 53.82125697961304],
  ["AG", 72.5201272562005],
  ["AE", 91.79229970133748],
  ["CZ", 66.29236462797039],
  ["AR", 96.62089339046877],
  ["BI", 80.30515517465264],
  ["CM", 36.21445266848461],
  ["LB", 78.42487988572913],
  ["LU", 97.77528892351643],
  ["TD", 85.25159070250616],
  ["TR", 67.56265420075314],
  ["Scotland", 90.63790416828982],
  ["UA", 99.26308271653032],
  ["LT", 65.66549798727438],
  ["LC", 97.04681210232438],
  ["MG", 96.02746396571874],
  ["CL", 67.45292819114401],
  ["BH", 66.49818205427866],
  ["AD", 68.99104012465914],
  ["CH", 96.01740033761848],
  ["NO", 73.55700558369043],
  ["MT", 73.8680041553045],
  ["MC", 99.35073367095183],
  ["TV", 85.43370990780417],
  ["TW", 97.85222698350864],
  ["US", 79.4637060122062],
  ["MU", 89.0767432800935],
  ["BZ", 83.03759252045188],
  ["CI", 98.92481495909622],
  ["BM", 65.94955200623296],
  ["CK", 89.1293338527464],
  ["AT", 78.1456953642384],
  ["BO", 76.22419166342034],
  ["LS", 85.70964809764966],
  ["NL", 97.82398389819504],
  ["MW", 88.03954031943904],
  ["YE", 98.04538371640047],
  ["UG", 85.71516686144656],
  ["TT", 81.5510972600961],
  ["NZ", 90.25483703415141],
  ["MA", 97.11368653421634],
  ["MV", 66.67153616413452],
  ["LR", 89.39715621347877],
  ["BY", 91.96987404233217],
  ["BN", 69.64874691598494],
  ["AU", 92.70289572782755],
  ["GE", 91.17160109076742],
  ["GR", 92.55616153746267],
  ["IN", 96.13556680950526],
  ["GBENG", 87.57109466303078],
  ["RS", 91.24561745227892],
  ["PL", 68.89202700947928],
  ["SV", 95.11784183872224],
  ["SA", 56.44137125048695],
  ["HK", 69.16991299831191],
  ["KG", 67.41656927671731],
  ["GD", 60.30937540579146],
  ["GQ", 73.40670042851578],
  ["DJ", 96.20698610570055],
  ["KE", 92.55323983898195],
  ["KR", 95.45740812881444],
  ["SC", 85.37722373717699],
  ["ST", 84.59875340864824],
  ["PY", 95.89728606674458],
  ["SB", 86.52869757174393],
  ["IL", 93.31093364498118],
  ["DK", 97.50032463316452],
  ["DO", 94.23256719906506],
  ["RU", 77.6347227632775],
  ["SG", 95.49798727437995],
  ["PK", 96.45987534086483],
  ["GB", 71.89845474613686],
  ["GU", 91.6267367874302],
  ["GW", 63.79983119075445],
  ["JP", 97.08836514738346],
  ["SR", 94.33515127905466],
  ["SE", 91.38391118036618],
  ["SD", 93.70341514089078],
  ["SS", 83.20542786651085],
  ["PH", 67.62303596935463],
  ["RW", 96.87118556031685],
  ["KU", 67.86099207895079],
  ["HN", 95.75152577587326],
  ["GA", 75.92098428775483],
  ["DZ", 93.22393195688872],
  ["DM", 59.53772237371769],
  ["FR", 82.16368004155305],
  ["GL", 72.84411115439553],
  ["ES", 79.28548240488247],
  ["HT", 68.54791585508376],
  ["PE", 82.16108297623686],
  ["QA", 96.50564861706272],
  ["SI", 75.81060901181664],
  ["PR", 89.82956758862485],
  ["PS", 87.20068822230878],
  ["IQ", 95.11524477340605],
  ["HU", 83.95078561225814],
  ["KN", 48.33008700168809],
  ["KY", 89.39293598233996],
  ["FI", 98.01389429944163],
  ["ER", 76.6455655109726],
  ["GM", 96.89001428385924],
  ["EE", 99.34846123880016],
  ["EG", 95.6697182184132],
  ["ID", 99.43903389170238],
  ["IS", 92.35521360862225],
  ["SK", 73.31775094143617],
  ["RO", 69.24814959096221],
  ["PG", 81.14076094013765],
  ["KM", 59.481560836255035],
  ["IR", 87.85482404882484],
  ["KZ", 87.00460979093624],
  ["IE", 65.95377223737177],
  ["GN", 85.6093364498117],
  ["FJ", 87.23380080509024],
  ["GY", 79.00792104921439],
  ["JM", 78.8530710297364],
  ["KI", 85.11686793922867],
  ["HR", 93.0700558369043],
  ["SO", 96.47383456693936],
  ["PT", 91.33326840670043],
  ["SY", 96.34820153226855],
  ["SN", 48.58167770419426],
  ["KH", 85.77002986625114],
  ["EC", 52.40975198026231],
  ["ET", 69.50623295675886],
  ["DE", 98.78392416569277],
  ["SL", 98.08239189715621],
  ["PW", 90.95312297104272],
  ["SZ", 85.08830022075055],
  ["PA", 97.11238800155823],
  ["SM", 91.31638748214517],
  ["IT", 49.59810414231918],
  ["JO", 87.37209453317752],
  ["GH", 76.93221659524737],
  ["CG", 44.88572912608752],
  ["BT", 78.34794182573692],
  ["AO", 97.2006882223088],
  ["ML", 69.24879885729126],
  ["TN", 69.94156603038566],
  ["LI", 67.07895078561225],
  ["MM", 59.04265679781846],
  ["MZ", 85.4434489027399],
  ["NA", 84.16796519932477],
  ["CF", 87.04713673548889],
  ["BB", 87.35781067393845],
  ["CD", 89.12316582262044],
  ["BW", 98.6293987793793],
  ["AL", 70.11816647188677],
  ["NC", 83.80534995455136],
  ["MX", 46.76632904817556],
  ["TZ", 91.0755096740683],
  ["VE", 56.32482794442281],
  ["MN", 93.40994676016102],
  ["MY", 75.56680950525906],
  ["AM", 98.57064017660045],
  ["CR", 83.12167251006363],
  ["BA", 74.49227373068432],
  ["AZ", 88.39468900142839],
  ["CV", 91.2209453317751],
  ["BR", 57.6697831450461],
  ["CA", 92.74022854174783],
  ["BE", 82.83177509414362],
  ["TH", 87.50519413063238],
  ["WS", 66.20698610570057],
  ["UZ", 92.61686793922867],
  ["Wales", 79.04070899883132],
  ["NG", 65.73756654979873],
  ["NP", 78.92027009479288],
  ["MK", 77.67075704453967],
  ["BD", 57.92104921438774],
  ["BS", 96.83190494740943],
  ["CU", 89.4085183742371],
  ["BF", 76.93286586157642],
  ["NR", 94.09946760161017],
  ["NE", 96.21802363329438],
  ["ZW", 79.34651343981301],
  ["VC", 81.27678223607325],
  ["UY", 93.00642773665759],
  ["TJ", 93.73003506038177],
  ["VU", 88.97610699909103],
  ["ZA", 77.68406700428517],
  ["BG", 64.77989871445267],
]);

export const laMap = new Map<string, number>([
  ["CY", 94.3531355273387],
  ["AF", 90.63552733868877],
  ["CN", 47.693379113760045],
  ["BJ", 81.88973827416429],
  ["LA", 0],
  ["ME", 59.90671158331173],
  ["OM", 83.568281938326],
  ["NI", 90.81206271054677],
  ["MR", 94.02014770665976],
  ["TG", 87.21462814200571],
  ["TP", 51.19622959315885],
  ["North Korea", 90.63941435605079],
  ["VN", 45.288287121015806],
  ["ZM", 89.12801243845556],
  ["MD", 79.02565431458926],
  ["CO", 72.77371080590827],
  ["AG", 66.81135009069708],
  ["AE", 77.9288675822752],
  ["CZ", 54.79819901528894],
  ["AR", 94.51120756672712],
  ["BI", 61.6766001554807],
  ["CM", 79.68903861103914],
  ["LB", 49.04120238403731],
  ["LU", 67.1041720653019],
  ["TD", 63.984840632288154],
  ["TR", 44.724669603524234],
  ["Scotland", 84.77617258357087],
  ["UA", 97.322168955688],
  ["LT", 72.6062451412283],
  ["LC", 97.144337911376],
  ["MG", 98.13358383000778],
  ["CL", 67.80027209121535],
  ["BH", 62.296255506607935],
  ["AD", 80.92057527856957],
  ["CH", 87.95283752267426],
  ["NO", 82.1734905415911],
  ["MT", 68.95180098471107],
  ["MC", 69.46942213008552],
  ["TV", 84.74248509976678],
  ["TW", 92.09024358642135],
  ["US", 63.45879761596268],
  ["MU", 53.06620886239959],
  ["BZ", 73.7830396475771],
  ["CI", 91.30020730759264],
  ["BM", 46.65489764187613],
  ["CK", 57.065625809795286],
  ["AT", 42.863112205234515],
  ["BO", 71.61311220523451],
  ["LS", 93.09471365638767],
  ["NL", 90.2542757190982],
  ["MW", 90.68476289194092],
  ["YE", 65.42206530189169],
  ["UG", 87.78763928478881],
  ["TT", 59.34503757450117],
  ["NZ", 55.934503757450116],
  ["MA", 47.4630733350609],
  ["MV", 45.48684892459186],
  ["LR", 61.745270795542886],
  ["BY", 92.67459186317699],
  ["BN", 90.97337393107023],
  ["AU", 84.65211194610004],
  ["GE", 92.83363565690593],
  ["GR", 91.55318735423685],
  ["IN", 93.47337393107023],
  ["GBENG", 92.10125680228039],
  ["RS", 71.43366157035501],
  ["PL", 69.78945322622441],
  ["SV", 91.83693962166363],
  ["SA", 90.59924850997668],
  ["HK", 41.74721430422389],
  ["KG", 40.35339466182949],
  ["GD", 62.302733868877944],
  ["GQ", 66.23671935734646],
  ["DJ", 94.7139803057787],
  ["KE", 93.14654055454781],
  ["KR", 93.9965016843742],
  ["SC", 72.12814200570095],
  ["ST", 90.23710805908266],
  ["PY", 67.25608966053382],
  ["SB", 86.73652500647836],
  ["IL", 95.13507385332987],
  ["DK", 49.18566986265872],
  ["DO", 59.343741902047164],
  ["RU", 72.33480176211454],
  ["SG", 72.12198756154444],
  ["PK", 92.59264058046126],
  ["GB", 72.74747343871469],
  ["GU", 47.00246177766261],
  ["GW", 78.61233480176212],
  ["JP", 95.35209898937549],
  ["SR", 97.51619590567503],
  ["SE", 96.16966830785178],
  ["SD", 64.83739310702255],
  ["SS", 90.59050272091216],
  ["PH", 72.91915003887017],
  ["RW", 96.431070225447],
  ["KU", 65.99183726353978],
  ["HN", 89.97408655091994],
  ["GA", 97.8912930811091],
  ["DZ", 92.83979010106245],
  ["DM", 93.99423425757968],
  ["FR", 74.7489634620368],
  ["GL", 68.66157035501426],
  ["ES", 45.584348276755634],
  ["HT", 69.36479657942472],
  ["PE", 57.85145115314848],
  ["QA", 94.6080590826639],
  ["SI", 71.1388960870692],
  ["PR", 88.05325213785954],
  ["PS", 83.9586032650946],
  ["IQ", 66.3212619849702],
  ["HU", 65.56426535371858],
  ["KN", 77.08927183208085],
  ["KY", 61.7864083959575],
  ["FI", 74.45743715988598],
  ["ER", 75.24229074889868],
  ["GM", 69.70944545218968],
  ["EE", 97.08279346981084],
  ["EG", 67.06562580979528],
  ["ID", 69.62328323399845],
  ["IS", 89.27895827934698],
  ["SK", 93.25861622181912],
  ["RO", 64.13546255506608],
  ["PG", 69.15198237885463],
  ["KM", 92.38565690593418],
  ["IR", 88.212619849702],
  ["KZ", 95.58175693184762],
  ["IE", 91.15962684633324],
  ["GN", 80.24391033946618],
  ["FJ", 85.58953096657164],
  ["GY", 88.04159108577352],
  ["JM", 90.01684374190204],
  ["KI", 59.30649131899456],
  ["HR", 89.81957761078],
  ["SO", 95.5014252396994],
  ["PT", 90.79521896864473],
  ["SY", 64.18275459963722],
  ["SN", 78.98386887794764],
  ["KH", 91.57683337652242],
  ["EC", 70.8217802539518],
  ["ET", 66.24643690075149],
  ["DE", 95.20374449339208],
  ["SL", 90.9639803057787],
  ["PW", 98.32696294376781],
  ["SZ", 83.76166105208604],
  ["PA", 80.30998963462036],
  ["SM", 95.79748639543924],
  ["IT", 73.96022285566208],
  ["JO", 82.57547292044572],
  ["GH", 70.15904379372896],
  ["CG", 77.68268981601452],
  ["BT", 93.73671935734646],
  ["AO", 68.25764446747861],
  ["ML", 80.75537704068412],
  ["TN", 42.94959834153926],
  ["LI", 52.55895309665717],
  ["MM", 68.33052604301632],
  ["MZ", 85.25362788287121],
  ["NA", 66.49650168437418],
  ["CF", 88.33991966830786],
  ["BB", 62.887082145633585],
  ["CD", 88.26768592899715],
  ["BW", 97.41383778180875],
  ["AL", 41.05597305001296],
  ["NC", 92.65450894013993],
  ["MX", 78.15949727908784],
  ["TZ", 94.1079295154185],
  ["VE", 48.397577092511014],
  ["MN", 62.5362788287121],
  ["MY", 71.81394143560507],
  ["AM", 72.2120367970977],
  ["CR", 96.63189945581757],
  ["BA", 89.98121274941695],
  ["AZ", 96.15412023840373],
  ["CV", 92.75395180098471],
  ["BR", 90.47097693703033],
  ["CA", 93.85980824047681],
  ["BE", 81.11265871987563],
  ["TH", 66.83985488468515],
  ["WS", 48.6055325213786],
  ["UZ", 88.97220782586163],
  ["Wales", 79.77973568281939],
  ["NG", 90.64071002850478],
  ["NP", 69.58991966830786],
  ["MK", 82.20037574501166],
  ["BD", 94.30875874578906],
  ["BS", 95.05182689816014],
  ["CU", 86.37762373671936],
  ["BF", 97.90036278828713],
  ["NR", 65.06672713138119],
  ["NE", 97.02610779994816],
  ["ZW", 92.54275719098212],
  ["VC", 95.79619072298523],
  ["UY", 93.33473697849183],
  ["TJ", 90.36019694221301],
  ["VU", 66.57359419538741],
  ["ZA", 90.38319512827158],
  ["BG", 73.5342705364084],
]);

export const meMap = new Map<string, number>([
  ["CY", 92.99394463667821],
  ["AF", 74.3378027681661],
  ["CN", 83.45544982698961],
  ["BJ", 88.75216262975778],
  ["LA", 59.40873702422146],
  ["ME", 0],
  ["OM", 58.37456747404845],
  ["NI", 91.10380622837371],
  ["MR", 86.95847750865052],
  ["TG", 87.04584775086505],
  ["TP", 81.71020761245674],
  ["North Korea", 85.6825259515571],
  ["VN", 86.73659169550173],
  ["ZM", 75.5787197231834],
  ["MD", 89.43425605536332],
  ["CO", 74.69636678200692],
  ["AG", 47.121972318339104],
  ["AE", 74.15354671280276],
  ["CZ", 67.90787197231833],
  ["AR", 94.02508650519032],
  ["BI", 73.02681660899654],
  ["CM", 75.09731833910035],
  ["LB", 89.55320069204153],
  ["LU", 90.75173010380622],
  ["TD", 89.54195501730104],
  ["TR", 26.814013840830448],
  ["Scotland", 88.0181660899654],
  ["UA", 89.03762975778547],
  ["LT", 89.00865051903114],
  ["LC", 93.60596885813149],
  ["MG", 85.18641868512111],
  ["CL", 90.92084775086505],
  ["BH", 48.33001730103806],
  ["AD", 89.0817474048443],
  ["CH", 91.95588235294117],
  ["NO", 86.74307958477509],
  ["MT", 93.18122837370242],
  ["MC", 61.089965397923876],
  ["TV", 84.55276816608996],
  ["TW", 83.80190311418684],
  ["US", 82.37024221453288],
  ["MU", 87.34602076124568],
  ["BZ", 83.94593425605537],
  ["CI", 97.65614186851211],
  ["BM", 78.57698961937716],
  ["CK", 83.31185121107266],
  ["AT", 91.84645328719722],
  ["BO", 87.86505190311419],
  ["LS", 93.1038062283737],
  ["NL", 71.65700692041523],
  ["MW", 57.424307958477506],
  ["YE", 72.38840830449827],
  ["UG", 66.08737024221453],
  ["TT", 41.00043252595156],
  ["NZ", 83.43944636678201],
  ["MA", 86.8317474048443],
  ["MV", 86.2409169550173],
  ["LR", 89.62067474048443],
  ["BY", 82.23918685121107],
  ["BN", 89.87370242214533],
  ["AU", 82.09256055363322],
  ["GE", 87.95631487889274],
  ["GR", 90.90311418685121],
  ["IN", 94.09688581314879],
  ["GBENG", 77.9446366782007],
  ["RS", 85.91046712802768],
  ["PL", 93.66608996539793],
  ["SV", 91.90700692041523],
  ["SA", 85.55968858131487],
  ["HK", 89.06704152249135],
  ["KG", 84.97923875432527],
  ["GD", 66.59688581314879],
  ["GQ", 67.94809688581314],
  ["DJ", 87.82136678200692],
  ["KE", 69.89100346020761],
  ["KR", 91.17776816608996],
  ["SC", 83.7621107266436],
  ["ST", 83.97707612456747],
  ["PY", 70.76384083044984],
  ["SB", 80.27076124567473],
  ["IL", 95.92517301038063],
  ["DK", 91.35250865051903],
  ["DO", 70.5856401384083],
  ["RU", 91.8689446366782],
  ["SG", 88.34126297577855],
  ["PK", 90.65138408304499],
  ["GB", 79.19377162629758],
  ["GU", 87.25173010380622],
  ["GW", 63.292387543252595],
  ["JP", 91.41262975778547],
  ["SR", 89.71626297577855],
  ["SE", 88.25692041522491],
  ["SD", 90.0583910034602],
  ["SS", 74.1924740484429],
  ["PH", 63.73096885813149],
  ["RW", 80.63884083044984],
  ["KU", 71.37629757785467],
  ["HN", 91.28373702422145],
  ["GA", 86.60813148788927],
  ["DZ", 92.12673010380624],
  ["DM", 86.17993079584775],
  ["FR", 96.89446366782008],
  ["GL", 92.07136678200692],
  ["ES", 53.20285467128028],
  ["HT", 88.37456747404843],
  ["PE", 45.00821799307958],
  ["QA", 88.46064013840831],
  ["SI", 88.35596885813149],
  ["PR", 85.53762975778547],
  ["PS", 78.39965397923876],
  ["IQ", 70.68771626297577],
  ["HU", 71.09385813148789],
  ["KN", 64.44290657439447],
  ["KY", 81.84212802768165],
  ["FI", 94.55666089965398],
  ["ER", 72.5333044982699],
  ["GM", 68.99048442906575],
  ["EE", 93.84083044982698],
  ["EG", 70.7651384083045],
  ["ID", 61.07915224913495],
  ["IS", 88.84558823529412],
  ["SK", 87.81747404844292],
  ["RO", 64.82612456747405],
  ["PG", 60.13321799307958],
  ["KM", 77.00778546712803],
  ["IR", 69.56487889273356],
  ["KZ", 86.20328719723183],
  ["IE", 97.38754325259515],
  ["GN", 64.25821799307958],
  ["FJ", 78.17301038062284],
  ["GY", 67.61980968858131],
  ["JM", 80.21280276816609],
  ["KI", 52.09818339100346],
  ["HR", 89.15830449826989],
  ["SO", 86.22923875432525],
  ["PT", 84.98961937716263],
  ["SY", 71.04238754325259],
  ["SN", 65.58953287197232],
  ["KH", 58.799307958477506],
  ["EC", 89.03546712802768],
  ["ET", 62.61245674740484],
  ["DE", 66.95069204152249],
  ["SL", 91.21885813148789],
  ["PW", 92.00346020761245],
  ["SZ", 61.49870242214532],
  ["PA", 92.16306228373703],
  ["SM", 91.1916089965398],
  ["IT", 96.71410034602076],
  ["JO", 71.50346020761246],
  ["GH", 68.38105536332179],
  ["CG", 85.84645328719724],
  ["BT", 82.37543252595155],
  ["AO", 55.11418685121108],
  ["ML", 64.08217993079585],
  ["TN", 90.85769896193771],
  ["LI", 51.6128892733564],
  ["MM", 93.12370242214533],
  ["MZ", 85.03892733564014],
  ["NA", 80.6955017301038],
  ["CF", 87.39878892733563],
  ["BB", 92.02897923875433],
  ["CD", 69.91262975778547],
  ["BW", 93.17560553633218],
  ["AL", 80.71064013840831],
  ["NC", 80.32828719723183],
  ["MX", 68.11505190311419],
  ["TZ", 82.00648788927336],
  ["VE", 89.42603806228374],
  ["MN", 83.27811418685121],
  ["MY", 77.4212802768166],
  ["AM", 66.82482698961938],
  ["CR", 72.88451557093425],
  ["BA", 87.1583044982699],
  ["AZ", 84.60121107266436],
  ["CV", 83.62283737024222],
  ["BR", 85.51384083044982],
  ["CA", 88.84385813148789],
  ["BE", 90.48832179930795],
  ["TH", 92.6371107266436],
  ["WS", 35.74524221453287],
  ["UZ", 86.1007785467128],
  ["Wales", 72.7772491349481],
  ["NG", 96.8416955017301],
  ["NP", 83.1583044982699],
  ["MK", 41.57396193771626],
  ["BD", 80.82482698961938],
  ["BS", 82.60726643598616],
  ["CU", 89.47664359861591],
  ["BF", 84.99178200692042],
  ["NR", 87.02378892733564],
  ["NE", 85.50086505190312],
  ["ZW", 72.91349480968859],
  ["VC", 89.78200692041523],
  ["UY", 93.33477508650519],
  ["TJ", 75.03157439446366],
  ["VU", 75.93944636678201],
  ["ZA", 84.38840830449827],
  ["BG", 89.27378892733564],
]);

export const omMap = new Map<string, number>([
  ["CY", 71.79671280276817],
  ["AF", 78.66262975778547],
  ["CN", 51.530276816609],
  ["BJ", 84.39186851211072],
  ["LA", 82.7067474048443],
  ["ME", 58.37456747404845],
  ["OM", 0],
  ["NI", 95.86678200692042],
  ["MR", 90.9455017301038],
  ["TG", 82.8992214532872],
  ["TP", 72.7045847750865],
  ["North Korea", 59.02162629757785],
  ["VN", 55.870674740484425],
  ["ZM", 77.18425605536332],
  ["MD", 79.44766435986159],
  ["CO", 88.76470588235294],
  ["AG", 67.34731833910035],
  ["AE", 76.45371972318338],
  ["CZ", 57.28416955017301],
  ["AR", 97.96064013840831],
  ["BI", 80.10986159169549],
  ["CM", 85.63278546712803],
  ["LB", 77.44420415224913],
  ["LU", 92.15570934256056],
  ["TD", 84.47621107266437],
  ["TR", 53.08737024221454],
  ["Scotland", 82.6470588235294],
  ["UA", 94.72880622837371],
  ["LT", 86.26557093425605],
  ["LC", 97.58953287197232],
  ["MG", 92.52465397923876],
  ["CL", 48.761245674740486],
  ["BH", 71.91955017301038],
  ["AD", 90.37629757785467],
  ["CH", 89.0666089965398],
  ["NO", 72.84731833910034],
  ["MT", 73.10250865051903],
  ["MC", 76.6007785467128],
  ["TV", 79.64273356401385],
  ["TW", 92.1120242214533],
  ["US", 77.77378892733564],
  ["MU", 90.4878892733564],
  ["BZ", 80.08953287197231],
  ["CI", 86.32309688581314],
  ["BM", 57.700692041522494],
  ["CK", 81.58953287197231],
  ["AT", 84.22404844290658],
  ["BO", 91.83564013840831],
  ["LS", 94.41089965397924],
  ["NL", 96.19896193771626],
  ["MW", 65.70934256055364],
  ["YE", 92.59169550173011],
  ["UG", 77.97621107266436],
  ["TT", 61.43382352941177],
  ["NZ", 83.090830449827],
  ["MA", 51.00821799307958],
  ["MV", 70.20847750865052],
  ["LR", 65.96237024221453],
  ["BY", 88.29801038062284],
  ["BN", 88.12629757785467],
  ["AU", 88.37326989619378],
  ["GE", 72.91998269896195],
  ["GR", 87.58217993079585],
  ["IN", 72.57915224913495],
  ["GBENG", 56.07352941176471],
  ["RS", 89.7720588235294],
  ["PL", 71.76038062283737],
  ["SV", 95.15787197231835],
  ["SA", 87.29368512110727],
  ["HK", 55.752595155709336],
  ["KG", 53.81444636678201],
  ["GD", 71.70025951557093],
  ["GQ", 88.27249134948096],
  ["DJ", 93.57828719723183],
  ["KE", 69.2227508650519],
  ["KR", 70.29671280276817],
  ["SC", 75.32785467128028],
  ["ST", 83.1946366782007],
  ["PY", 90.21020761245676],
  ["SB", 85.42560553633218],
  ["IL", 81.17733564013841],
  ["DK", 63.67171280276816],
  ["DO", 76.54108996539793],
  ["RU", 61.69809688581315],
  ["SG", 78.08564013840831],
  ["PK", 93.47102076124567],
  ["GB", 60.31660899653979],
  ["GU", 83.24740484429066],
  ["GW", 72.7833044982699],
  ["JP", 63.890570934256054],
  ["SR", 85.13840830449827],
  ["SE", 92.77811418685121],
  ["SD", 92.45804498269896],
  ["SS", 71.62932525951558],
  ["PH", 80.48961937716263],
  ["RW", 92.58391003460208],
  ["KU", 93.61937716262976],
  ["HN", 95.35250865051903],
  ["GA", 95.87802768166091],
  ["DZ", 74.09039792387543],
  ["DM", 82.95285467128028],
  ["FR", 74.2136678200692],
  ["GL", 52.04628027681662],
  ["ES", 82.98659169550173],
  ["HT", 69.55666089965398],
  ["PE", 51.10121107266436],
  ["QA", 91.78070934256056],
  ["SI", 61.048442906574394],
  ["PR", 68.3568339100346],
  ["PS", 82.47058823529412],
  ["IQ", 90.77984429065744],
  ["HU", 92.1038062283737],
  ["KN", 81.96972318339101],
  ["KY", 81.9325259515571],
  ["FI", 77.86072664359862],
  ["ER", 59.56833910034602],
  ["GM", 91.45674740484428],
  ["EE", 96.87283737024221],
  ["EG", 92.07352941176471],
  ["ID", 76.6007785467128],
  ["IS", 77.85553633217994],
  ["SK", 56.94204152249135],
  ["RO", 84.3992214532872],
  ["PG", 80.4779411764706],
  ["KM", 74.1743079584775],
  ["IR", 82.76946366782006],
  ["KZ", 84.93295847750865],
  ["IE", 85.01643598615917],
  ["GN", 71.4545847750865],
  ["FJ", 84.66782006920414],
  ["GY", 73.43425605536332],
  ["JM", 92.8317474048443],
  ["KI", 69.81920415224914],
  ["HR", 92.83996539792388],
  ["SO", 91.84861591695501],
  ["PT", 83.22967128027682],
  ["SY", 91.23010380622837],
  ["SN", 83.21193771626298],
  ["KH", 65.45631487889273],
  ["EC", 82.99610726643598],
  ["ET", 79.48745674740483],
  ["DE", 66.7136678200692],
  ["SL", 96.23356401384083],
  ["PW", 97.74913494809688],
  ["SZ", 83.66306228373702],
  ["PA", 80.465830449827],
  ["SM", 69.35467128027682],
  ["IT", 73.91608996539793],
  ["JO", 75.01686851211072],
  ["GH", 91.6673875432526],
  ["CG", 81.26903114186851],
  ["BT", 90.53070934256056],
  ["AO", 76.99956747404845],
  ["ML", 84.05233564013841],
  ["TN", 55.886245674740486],
  ["LI", 68.4303633217993],
  ["MM", 82.61461937716264],
  ["MZ", 79.84861591695501],
  ["NA", 72.73918685121107],
  ["CF", 85.1522491349481],
  ["BB", 93.71193771626297],
  ["CD", 78.79022491349481],
  ["BW", 98.9022491349481],
  ["AL", 57.70415224913494],
  ["NC", 91.15960207612457],
  ["MX", 71.2249134948097],
  ["TZ", 89.69550173010381],
  ["VE", 81.91998269896193],
  ["MN", 66.34991349480968],
  ["MY", 68.74437716262976],
  ["AM", 90.09039792387543],
  ["CR", 53.905276816608996],
  ["BA", 87.26773356401384],
  ["AZ", 64.60640138408304],
  ["CV", 83.1803633217993],
  ["BR", 85.24653979238754],
  ["CA", 76.4204152249135],
  ["BE", 88.37932525951557],
  ["TH", 75.71193771626298],
  ["WS", 61.08737024221453],
  ["UZ", 93.43339100346022],
  ["Wales", 57.32179930795848],
  ["NG", 83.77551903114187],
  ["NP", 63.76946366782007],
  ["MK", 63.24351211072664],
  ["BD", 89.95977508650519],
  ["BS", 96.56228373702422],
  ["CU", 67.87543252595157],
  ["BF", 74.5977508650519],
  ["NR", 91.36634948096885],
  ["NE", 94.92733564013841],
  ["ZW", 79.7750865051903],
  ["VC", 91.90527681660899],
  ["UY", 76.6219723183391],
  ["TJ", 68.7863321799308],
  ["VU", 88.92949826989619],
  ["ZA", 90.30147058823529],
  ["BG", 61.753460207612456],
]);

export const niMap = new Map<string, number>([
  ["CY", 70.93137254901961],
  ["AF", 93.39388696655134],
  ["CN", 96.27234717416378],
  ["BJ", 95.1128171856978],
  ["LA", 90.7720588235294],
  ["ME", 91.93555363321799],
  ["OM", 96.09897635524798],
  ["NI", 0],
  ["MR", 95.85748269896193],
  ["TG", 93.89597750865052],
  ["TP", 93.21330738177625],
  ["North Korea", 93.53373702422145],
  ["VN", 93.9496828143022],
  ["ZM", 95.12363033448673],
  ["MD", 95.69528546712803],
  ["CO", 94.36598904267589],
  ["AG", 93.3236014994233],
  ["AE", 75.3881920415225],
  ["CZ", 87.53640426758939],
  ["AR", 68.2428633217993],
  ["BI", 82.98514994232987],
  ["CM", 96.94997116493657],
  ["LB", 70.39359861591696],
  ["LU", 67.07107843137254],
  ["TD", 93.24682814302192],
  ["TR", 94.00014417531719],
  ["Scotland", 49.91926182237601],
  ["UA", 63.873269896193776],
  ["LT", 99.51448961937717],
  ["LC", 97.30356113033449],
  ["MG", 87.96208189158016],
  ["CL", 87.56523933102652],
  ["BH", 86.83751441753171],
  ["AD", 95.09515570934256],
  ["CH", 85.04108996539792],
  ["NO", 86.23954728950403],
  ["MT", 82.56163494809688],
  ["MC", 83.59212802768165],
  ["TV", 85.85207612456747],
  ["TW", 97.58794694348327],
  ["US", 79.62045847750865],
  ["MU", 95.69852941176471],
  ["BZ", 81.95609861591694],
  ["CI", 89.5141291810842],
  ["BM", 88.76766147635526],
  ["CK", 88.88948961937716],
  ["AT", 67.07071799307958],
  ["BO", 92.15037485582468],
  ["LS", 69.78301614763552],
  ["NL", 67.07035755478663],
  ["MW", 95.45847750865052],
  ["YE", 67.07107843137254],
  ["UG", 94.5685553633218],
  ["TT", 93.22556228373703],
  ["NZ", 90.4516291810842],
  ["MA", 99.18216551326412],
  ["MV", 96.57187139561707],
  ["LR", 83.7550461361015],
  ["BY", 92.94622260668973],
  ["BN", 82.4142156862745],
  ["AU", 92.65282583621683],
  ["GE", 84.20307093425605],
  ["GR", 38.58347750865052],
  ["IN", 68.59140715109572],
  ["GBENG", 87.75951557093425],
  ["RS", 90.54173875432527],
  ["PL", 83.14698673587083],
  ["SV", 67.01232698961938],
  ["SA", 91.83210784313725],
  ["HK", 91.69189734717416],
  ["KG", 90.04361303344868],
  ["GD", 87.79231545559401],
  ["GQ", 67.91882929642445],
  ["DJ", 84.42185697808536],
  ["KE", 93.55824682814303],
  ["KR", 75.69780853517878],
  ["SC", 82.98370818915801],
  ["ST", 88.89525663206459],
  ["PY", 68.23565455594003],
  ["SB", 65.14453575547866],
  ["IL", 70.06163494809688],
  ["DK", 83.89165224913495],
  ["DO", 77.74257497116494],
  ["RU", 99.36670991926182],
  ["SG", 79.7570645905421],
  ["PK", 86.67856113033449],
  ["GB", 73.09760668973472],
  ["GU", 93.77306805074971],
  ["GW", 94.89691464821223],
  ["JP", 77.48666378316032],
  ["SR", 96.1966551326413],
  ["SE", 94.86627739331026],
  ["SD", 74.15585351787773],
  ["SS", 92.91882929642445],
  ["PH", 86.26477797001154],
  ["RW", 92.94910611303345],
  ["KU", 73.71864186851211],
  ["HN", 1.740916955017301],
  ["GA", 91.80615628604383],
  ["DZ", 82.0916234140715],
  ["DM", 89.55233564013841],
  ["FR", 89.25425317185697],
  ["GL", 81.81588811995385],
  ["ES", 90.8881199538639],
  ["HT", 96.98565455594003],
  ["PE", 89.35012975778547],
  ["QA", 86.63819204152249],
  ["SI", 97.37276528258361],
  ["PR", 83.82857554786621],
  ["PS", 73.91652249134948],
  ["IQ", 69.69831314878893],
  ["HU", 67.0696366782007],
  ["KN", 92.20516147635524],
  ["KY", 90.12146770472896],
  ["FI", 94.71489331026528],
  ["ER", 85.88811995386389],
  ["GM", 88.6854815455594],
  ["EE", 99.67452422145328],
  ["EG", 67.6178633217993],
  ["ID", 83.58924452133795],
  ["IS", 87.83628892733563],
  ["SK", 95.75728085351788],
  ["RO", 93.32432237600923],
  ["PG", 93.32504325259515],
  ["KM", 83.55968858131489],
  ["IR", 59.59486735870819],
  ["KZ", 90.17264994232987],
  ["IE", 89.61829584775086],
  ["GN", 93.38271337946944],
  ["FJ", 88.30089388696655],
  ["GY", 86.03445790080738],
  ["JM", 88.71143310265282],
  ["KI", 82.58578431372548],
  ["HR", 64.6763264129181],
  ["SO", 95.5594002306805],
  ["PT", 92.61534025374856],
  ["SY", 68.88119953863898],
  ["SN", 94.54440599769319],
  ["KH", 91.96835351787774],
  ["EC", 90.92416378316032],
  ["ET", 89.42185697808536],
  ["DE", 99.7862600922722],
  ["SL", 34.22758073817763],
  ["PW", 93.32396193771626],
  ["SZ", 88.88985005767013],
  ["PA", 81.73551038062283],
  ["SM", 84.09854382929642],
  ["IT", 89.35229238754326],
  ["JO", 77.38141580161476],
  ["GH", 93.93202133794695],
  ["CG", 89.87096309111881],
  ["BT", 90.35034602076124],
  ["AO", 96.56862745098039],
  ["ML", 93.28034890426758],
  ["TN", 91.79318050749711],
  ["LI", 97.21561418685121],
  ["MM", 91.68829296424452],
  ["MZ", 93.62312572087659],
  ["NA", 88.43894175317186],
  ["CF", 83.08282871972318],
  ["BB", 95.65311418685121],
  ["CD", 92.590830449827],
  ["BW", 91.62161188004615],
  ["AL", 95.72015570934256],
  ["NC", 91.91176470588235],
  ["MX", 91.74560265282584],
  ["TZ", 93.4083044982699],
  ["VE", 98.32179930795849],
  ["MN", 94.9253892733564],
  ["MY", 82.89936562860439],
  ["AM", 99.32129469434832],
  ["CR", 97.63372260668973],
  ["BA", 89.47592272202999],
  ["AZ", 97.5443339100346],
  ["CV", 89.70948673587081],
  ["BR", 91.824538638985],
  ["CA", 90.50064878892734],
  ["BE", 93.61555651672434],
  ["TH", 99.92683102652826],
  ["WS", 96.46626297577855],
  ["UZ", 63.93166089965398],
  ["Wales", 79.00374855824683],
  ["NG", 89.54404555940023],
  ["NP", 77.14568915801615],
  ["MK", 83.03921568627452],
  ["BD", 95.01333621683968],
  ["BS", 90.35467128027682],
  ["CU", 85.88667820069205],
  ["BF", 97.57352941176471],
  ["NR", 95.77638408304499],
  ["NE", 70.73961937716263],
  ["ZW", 90.62572087658593],
  ["VC", 78.07814302191464],
  ["UY", 84.37067474048443],
  ["TJ", 67.32122260668973],
  ["VU", 93.20033160322953],
  ["ZA", 88.27277970011535],
  ["BG", 99.2499279123414],
]);

export const mrMap = new Map<string, number>([
  ["CY", 94.72178937800285],
  ["AF", 88.69497467861316],
  ["CN", 92.95383716400467],
  ["BJ", 95.25808336579665],
  ["LA", 94.20432411375145],
  ["ME", 87.46169328658617],
  ["OM", 91.5510972600961],
  ["NI", 95.91643942345151],
  ["MR", 0],
  ["TG", 49.870146734190364],
  ["TP", 90.80119465004545],
  ["North Korea", 93.65472016621219],
  ["VN", 94.3530061031035],
  ["ZM", 90.57070510323335],
  ["MD", 91.22062069861056],
  ["CO", 95.55025321386833],
  ["AG", 86.24886378392416],
  ["AE", 96.28132710037659],
  ["CZ", 93.77580833657967],
  ["AR", 96.2722373717699],
  ["BI", 88.53460589533827],
  ["CM", 94.6604337099078],
  ["LB", 93.50019477989872],
  ["LU", 96.77249707830153],
  ["TD", 93.58881963381378],
  ["TR", 93.85079859758473],
  ["Scotland", 90.45059083235944],
  ["UA", 94.17608102843786],
  ["LT", 69.37702895727827],
  ["LC", 95.28535255161668],
  ["MG", 67.52759381898454],
  ["CL", 95.74211141410206],
  ["BH", 93.18919620828464],
  ["AD", 92.48733930658356],
  ["CH", 94.61076483573562],
  ["NO", 92.90903778730035],
  ["MT", 96.21932216595248],
  ["MC", 96.39754577327619],
  ["TV", 90.37267887287366],
  ["TW", 91.3180106479678],
  ["US", 85.64309829892221],
  ["MU", 93.09667575639527],
  ["BZ", 88.29989611738736],
  ["CI", 96.88774185170756],
  ["BM", 85.96253733281391],
  ["CK", 86.26996493961823],
  ["AT", 96.61569925983639],
  ["BO", 63.41286845864174],
  ["LS", 96.88611868588495],
  ["NL", 96.39884430593429],
  ["MW", 86.2439942864563],
  ["YE", 97.0107778210622],
  ["UG", 85.96026490066225],
  ["TT", 91.88904038436567],
  ["NZ", 85.88235294117646],
  ["MA", 93.31482924295545],
  ["MV", 72.33670951824438],
  ["LR", 93.6914037138034],
  ["BY", 89.95747305544735],
  ["BN", 89.81041423191793],
  ["AU", 83.77450980392157],
  ["GE", 90.63011297234127],
  ["GR", 95.17302947669134],
  ["IN", 95.23957927541878],
  ["GBENG", 95.92487988572913],
  ["RS", 89.70653161927022],
  ["PL", 97.29970133748864],
  ["SV", 95.36229061160888],
  ["SA", 11.208609271523178],
  ["HK", 92.2763277496429],
  ["KG", 88.48428775483703],
  ["GD", 84.41825736917284],
  ["GQ", 92.4594208544345],
  ["DJ", 94.80651863394365],
  ["KE", 90.43857940527205],
  ["KR", 92.13219062459422],
  ["SC", 73.35475912219192],
  ["ST", 89.52116608232697],
  ["PY", 95.18471627061422],
  ["SB", 86.14498117127646],
  ["IL", 94.59193611219322],
  ["DK", 96.18750811582912],
  ["DO", 95.29866251136217],
  ["RU", 95.76483573561875],
  ["SG", 92.60453187897676],
  ["PK", 93.5066874431892],
  ["GB", 82.26496558888456],
  ["GU", 89.96591351772497],
  ["GW", 92.79898714452669],
  ["JP", 95.09511751720557],
  ["SR", 95.32690559667576],
  ["SE", 95.06719906505649],
  ["SD", 79.02252954161797],
  ["SS", 89.28743020386962],
  ["PH", 93.15673289183223],
  ["RW", 89.04395533047656],
  ["KU", 66.89293598233995],
  ["HN", 96.14595507077003],
  ["GA", 93.89592260745357],
  ["DZ", 51.2615244773406],
  ["DM", 26.954291650435007],
  ["FR", 96.68744318919622],
  ["GL", 94.97695104531878],
  ["ES", 92.00038955979743],
  ["HT", 94.43903389170238],
  ["PE", 97.08382028308013],
  ["QA", 93.82677574340995],
  ["SI", 93.83651473834567],
  ["PR", 87.45942085443448],
  ["PS", 65.16361511492013],
  ["IQ", 95.05031814050123],
  ["HU", 96.89553304765614],
  ["KN", 87.70159719516946],
  ["KY", 86.68711855603168],
  ["FI", 96.00571354369562],
  ["ER", 79.73412543825478],
  ["GM", 95.43663160628489],
  ["EE", 95.28405401895857],
  ["EG", 96.04304635761589],
  ["ID", 96.58713154135826],
  ["IS", 94.51856901701078],
  ["SK", 92.5512920399948],
  ["RO", 93.74237112063368],
  ["PG", 88.3745617452279],
  ["KM", 89.17315932995716],
  ["IR", 88.88196338137904],
  ["KZ", 90.51714063108687],
  ["IE", 97.19614335800544],
  ["GN", 93.70406440721985],
  ["FJ", 87.19094922737307],
  ["GY", 83.8154135826516],
  ["JM", 87.58667705492793],
  ["KI", 87.38378132710038],
  ["HR", 91.40176600441501],
  ["SO", 95.14965588884561],
  ["PT", 90.07499026100506],
  ["SY", 96.26574470847942],
  ["SN", 94.81041423191793],
  ["KH", 91.75951175172055],
  ["EC", 91.05765485001947],
  ["ET", 87.37663939748084],
  ["DE", 93.82710037657446],
  ["SL", 96.74652642513959],
  ["PW", 94.81041423191793],
  ["SZ", 84.0377873003506],
  ["PA", 94.84125438254772],
  ["SM", 93.6212829502662],
  ["IT", 96.77866510842748],
  ["JO", 65.60868718348266],
  ["GH", 60.69244253992988],
  ["CG", 93.09342942475003],
  ["BT", 92.71653032073756],
  ["AO", 90.94013764446176],
  ["ML", 93.68101545253863],
  ["TN", 93.19698740423321],
  ["LI", 92.82723022984028],
  ["MM", 94.52473704713672],
  ["MZ", 88.97123750162316],
  ["NA", 86.44234514998053],
  ["CF", 94.2978184651344],
  ["BB", 93.33982599662382],
  ["CD", 91.07258797558758],
  ["BW", 96.50824568237891],
  ["AL", 86.7033502142579],
  ["NC", 90.37397740553175],
  ["MX", 61.40273990390859],
  ["TZ", 87.51915335670692],
  ["VE", 93.14634463056746],
  ["MN", 91.04824048824828],
  ["MY", 86.09206596545903],
  ["AM", 95.34898065186339],
  ["CR", 94.45201921828334],
  ["BA", 88.10122062069861],
  ["AZ", 93.24211141410207],
  ["CV", 92.41007661342682],
  ["BR", 89.67536683547591],
  ["CA", 93.74496818594987],
  ["BE", 95.68757304246202],
  ["TH", 96.39527334112454],
  ["WS", 92.77009479288404],
  ["UZ", 93.04798078171666],
  ["Wales", 74.87891182963251],
  ["NG", 96.82703544994156],
  ["NP", 85.39442929489677],
  ["MK", 82.1604337099078],
  ["BD", 16.374172185430464],
  ["BS", 91.69718218413193],
  ["CU", 90.65413582651604],
  ["BF", 94.48935203220361],
  ["NR", 93.05999220880406],
  ["NE", 95.28210621997142],
  ["ZW", 88.85501882872354],
  ["VC", 93.07265290222048],
  ["UY", 93.85923905986235],
  ["TJ", 94.97370471367354],
  ["VU", 82.2224386443319],
  ["ZA", 65.56096610829762],
  ["BG", 95.93883911180366],
]);

export const tgMap = new Map<string, number>([
  ["CY", 90.20658263305322],
  ["AF", 91.28151260504201],
  ["CN", 93.36274509803921],
  ["BJ", 75.63130252100841],
  ["LA", 86.95903361344538],
  ["ME", 88.40231092436974],
  ["OM", 83.43942577030813],
  ["NI", 93.6046918767507],
  ["MR", 50.46008403361345],
  ["TG", 0],
  ["TP", 88.38690476190476],
  ["North Korea", 86.55882352941177],
  ["VN", 77.05532212885154],
  ["ZM", 93.64040616246498],
  ["MD", 84.53501400560224],
  ["CO", 84.46778711484593],
  ["AG", 81.71008403361346],
  ["AE", 83.49264705882354],
  ["CZ", 94.78221288515407],
  ["AR", 93.24649859943978],
  ["BI", 82.16036414565826],
  ["CM", 73.91736694677871],
  ["LB", 79.73949579831933],
  ["LU", 85.77100840336135],
  ["TD", 84.39775910364146],
  ["TR", 79.00350140056022],
  ["Scotland", 88.4313725490196],
  ["UA", 76.64810924369748],
  ["LT", 77.33368347338936],
  ["LC", 93.21183473389357],
  ["MG", 75.82317927170868],
  ["CL", 92.93067226890757],
  ["BH", 91.14495798319328],
  ["AD", 83.33193277310924],
  ["CH", 94.76365546218487],
  ["NO", 85.30602240896359],
  ["MT", 95.43522408963585],
  ["MC", 82.81512605042018],
  ["TV", 80.5360644257703],
  ["TW", 91.66456582633053],
  ["US", 78.76785714285714],
  ["MU", 73.37079831932773],
  ["BZ", 81.5672268907563],
  ["CI", 97.7563025210084],
  ["BM", 82.03256302521008],
  ["CK", 84.30217086834733],
  ["AT", 83.05567226890757],
  ["BO", 57.14075630252101],
  ["LS", 92.0717787114846],
  ["NL", 92.50070028011204],
  ["MW", 86.73914565826331],
  ["YE", 86.26330532212884],
  ["UG", 72.37850140056022],
  ["TT", 83.50280112044818],
  ["NZ", 84.74229691876751],
  ["MA", 75.812675070028],
  ["MV", 75.15126050420167],
  ["LR", 83.39250700280112],
  ["BY", 90.40336134453781],
  ["BN", 76.2265406162465],
  ["AU", 85.83228291316527],
  ["GE", 87.69887955182072],
  ["GR", 88.6502100840336],
  ["IN", 92.46673669467788],
  ["GBENG", 85.593837535014],
  ["RS", 88.78886554621849],
  ["PL", 93.24404761904762],
  ["SV", 90.10749299719888],
  ["SA", 49.56057422969188],
  ["HK", 89.90756302521008],
  ["KG", 71.5014005602241],
  ["GD", 58.331932773109244],
  ["GQ", 89.33403361344537],
  ["DJ", 92.74789915966386],
  ["KE", 89.07457983193278],
  ["KR", 91.44887955182072],
  ["SC", 74.8298319327731],
  ["ST", 71.63690476190476],
  ["PY", 83.80147058823529],
  ["SB", 84.2626050420168],
  ["IL", 90.88480392156862],
  ["DK", 85.35434173669468],
  ["DO", 94.60014005602241],
  ["RU", 95.01155462184873],
  ["SG", 84.8343837535014],
  ["PK", 91.90441176470588],
  ["GB", 70.96218487394958],
  ["GU", 87.76085434173669],
  ["GW", 70.07212885154061],
  ["JP", 93.3718487394958],
  ["SR", 80.92156862745098],
  ["SE", 93.76435574229693],
  ["SD", 86.26225490196079],
  ["SS", 72.55882352941177],
  ["PH", 91.33123249299719],
  ["RW", 76.42296918767506],
  ["KU", 82.12675070028011],
  ["HN", 91.92997198879553],
  ["GA", 86.031162464986],
  ["DZ", 79.38550420168067],
  ["DM", 63.5875350140056],
  ["FR", 96.41456582633053],
  ["GL", 89.35224089635855],
  ["ES", 62.903361344537814],
  ["HT", 90.21148459383753],
  ["PE", 80.8953081232493],
  ["QA", 92.625],
  ["SI", 94.63095238095238],
  ["PR", 83.94537815126051],
  ["PS", 69.91981792717087],
  ["IQ", 83.03991596638656],
  ["HU", 63.87920168067227],
  ["KN", 88.5280112044818],
  ["KY", 83.53151260504201],
  ["FI", 93.26820728291317],
  ["ER", 77.062324929972],
  ["GM", 80.90371148459383],
  ["EE", 97.78886554621849],
  ["EG", 84.81582633053222],
  ["ID", 83.0514705882353],
  ["IS", 87.82598039215686],
  ["SK", 90.41456582633053],
  ["RO", 84.41036414565826],
  ["PG", 84.73844537815125],
  ["KM", 87.59733893557423],
  ["IR", 86.99719887955182],
  ["KZ", 86.75840336134453],
  ["IE", 96.515756302521],
  ["GN", 68.85084033613445],
  ["FJ", 83.44817927170868],
  ["GY", 73.42542016806722],
  ["JM", 83.6390056022409],
  ["KI", 71.17997198879551],
  ["HR", 89.67051820728291],
  ["SO", 93.98074229691876],
  ["PT", 90.0812324929972],
  ["SY", 84.6484593837535],
  ["SN", 87.63375350140056],
  ["KH", 83.21043417366947],
  ["EC", 82.33053221288516],
  ["ET", 72.95378151260505],
  ["DE", 84.61064425770309],
  ["SL", 93.2359943977591],
  ["PW", 88.13865546218487],
  ["SZ", 81.95098039215686],
  ["PA", 94.29446778711484],
  ["SM", 89.60819327731092],
  ["IT", 96.48704481792717],
  ["JO", 66.69327731092437],
  ["GH", 57.42927170868347],
  ["CG", 92.3970588235294],
  ["BT", 87.98949579831933],
  ["AO", 78.65091036414566],
  ["ML", 84.09803921568627],
  ["TN", 75.03186274509804],
  ["LI", 90.49509803921568],
  ["MM", 85.421918767507],
  ["MZ", 64.21183473389355],
  ["NA", 85.82212885154061],
  ["CF", 88.2139355742297],
  ["BB", 94.66386554621849],
  ["CD", 87.60749299719888],
  ["BW", 95.32773109243698],
  ["AL", 77.30987394957984],
  ["NC", 89.21813725490196],
  ["MX", 85.59803921568627],
  ["TZ", 88.51120448179272],
  ["VE", 85.6169467787115],
  ["MN", 78.37605042016807],
  ["MY", 83.71043417366947],
  ["AM", 95.79026610644257],
  ["CR", 87.73564425770309],
  ["BA", 80.52380952380952],
  ["AZ", 86.07002801120449],
  ["CV", 87.72584033613445],
  ["BR", 85.04586834733894],
  ["CA", 91.02170868347339],
  ["BE", 98.3609943977591],
  ["TH", 88.71743697478992],
  ["WS", 90.55672268907563],
  ["UZ", 91.70483193277312],
  ["Wales", 82.92121848739495],
  ["NG", 70.92471988795518],
  ["NP", 78.78326330532212],
  ["MK", 80.64145658263305],
  ["BD", 56.60749299719888],
  ["BS", 95.28081232492997],
  ["CU", 78.13725490196079],
  ["BF", 80.43487394957984],
  ["NR", 93.25805322128852],
  ["NE", 92.50455182072828],
  ["ZW", 80.13165266106442],
  ["VC", 80.42156862745098],
  ["UY", 89.92997198879551],
  ["TJ", 89.54271708683473],
  ["VU", 88.57738095238096],
  ["ZA", 72.17682072829132],
  ["BG", 94.73634453781513],
]);

export const tpMap = new Map<string, number>([
  ["CY", 89.48053633217992],
  ["AF", 75.3356401384083],
  ["CN", 24.811418685121104],
  ["BJ", 67.34948096885813],
  ["LA", 50.904411764705884],
  ["ME", 81.71020761245674],
  ["OM", 72.7045847750865],
  ["NI", 93.3287197231834],
  ["MR", 90.67733564013841],
  ["TG", 88.68339100346022],
  ["TP", 0],
  ["North Korea", 57.24653979238754],
  ["VN", 29.49740484429066],
  ["ZM", 84.39532871972318],
  ["MD", 56.63019031141868],
  ["CO", 71.56790657439447],
  ["AG", 63.082179930795846],
  ["AE", 91.30925605536332],
  ["CZ", 59.35337370242214],
  ["AR", 92.27854671280276],
  ["BI", 62.49178200692042],
  ["CM", 68.03849480968857],
  ["LB", 47.57050173010381],
  ["LU", 67.63927335640139],
  ["TD", 60.80536332179931],
  ["TR", 26.841262975778545],
  ["Scotland", 89.6946366782007],
  ["UA", 95.88321799307958],
  ["LT", 68.49826989619378],
  ["LC", 95.44723183391004],
  ["MG", 92.50346020761245],
  ["CL", 58.68641868512111],
  ["BH", 30.893166089965398],
  ["AD", 58.099913494809684],
  ["CH", 94.17214532871972],
  ["NO", 48.398356401384085],
  ["MT", 44.38624567474048],
  ["MC", 59.16306228373702],
  ["TV", 79.08564013840831],
  ["TW", 94.09342560553632],
  ["US", 88.04195501730105],
  ["MU", 72.93987889273356],
  ["BZ", 70.32309688581316],
  ["CI", 93.53070934256056],
  ["BM", 37.429930795847746],
  ["CK", 82.8689446366782],
  ["AT", 41.075692041522494],
  ["BO", 68.71453287197232],
  ["LS", 93.23399653979239],
  ["NL", 93.8477508650519],
  ["MW", 69.39359861591696],
  ["YE", 65.21842560553634],
  ["UG", 88.76124567474048],
  ["TT", 48.634515570934255],
  ["NZ", 83.42690311418686],
  ["MA", 24.814446366782008],
  ["MV", 44.45328719723183],
  ["LR", 59.55752595155709],
  ["BY", 93.73096885813149],
  ["BN", 85.61115916955018],
  ["AU", 91.5696366782007],
  ["GE", 91.2257785467128],
  ["GR", 93.8780276816609],
  ["IN", 93.0916955017301],
  ["GBENG", 75.96669550173011],
  ["RS", 67.91782006920415],
  ["PL", 59.732698961937714],
  ["SV", 92.3075259515571],
  ["SA", 89.22837370242215],
  ["HK", 30.320934256055367],
  ["KG", 30.30666089965398],
  ["GD", 62.614619377162626],
  ["GQ", 68.41955017301038],
  ["DJ", 92.77076124567473],
  ["KE", 86.5363321799308],
  ["KR", 87.79714532871972],
  ["SC", 65.76038062283736],
  ["ST", 94.71885813148789],
  ["PY", 65.25129757785467],
  ["SB", 89.88494809688581],
  ["IL", 90.20631487889274],
  ["DK", 38.28157439446367],
  ["DO", 67.81920415224914],
  ["RU", 68.28935986159169],
  ["SG", 59.498702422145335],
  ["PK", 90.93944636678201],
  ["GB", 64.70501730103805],
  ["GU", 76.89749134948097],
  ["GW", 85.1613321799308],
  ["JP", 92.44290657439447],
  ["SR", 93.38321799307958],
  ["SE", 93.79022491349481],
  ["SD", 69.83780276816609],
  ["SS", 78.80579584775087],
  ["PH", 55.81574394463667],
  ["RW", 90.82006920415225],
  ["KU", 55.714965397923876],
  ["HN", 92.74610726643598],
  ["GA", 92.19939446366782],
  ["DZ", 88.93771626297577],
  ["DM", 83.78243944636678],
  ["FR", 60.997837370242216],
  ["GL", 59.219723183391004],
  ["ES", 51.84948096885813],
  ["HT", 59.00821799307958],
  ["PE", 53.99567474048443],
  ["QA", 91.9606401384083],
  ["SI", 68.68425605536332],
  ["PR", 89.53762975778547],
  ["PS", 96.67214532871972],
  ["IQ", 63.43425605536333],
  ["HU", 67.35337370242215],
  ["KN", 65.05579584775086],
  ["KY", 82.36505190311418],
  ["FI", 94.35942906574395],
  ["ER", 82.76773356401385],
  ["GM", 67.59948096885813],
  ["EE", 89.91089965397924],
  ["EG", 64.16176470588235],
  ["ID", 59.13581314878893],
  ["IS", 82.60207612456747],
  ["SK", 66.5060553633218],
  ["RO", 58.84169550173011],
  ["PG", 40.330449826989614],
  ["KM", 82.44333910034602],
  ["IR", 86.67084775086505],
  ["KZ", 89.13062283737024],
  ["IE", 96.4446366782007],
  ["GN", 85.49005190311418],
  ["FJ", 83.72967128027682],
  ["GY", 94.52984429065744],
  ["JM", 77.51773356401384],
  ["KI", 55.49956747404844],
  ["HR", 86.13148788927336],
  ["SO", 94.80839100346022],
  ["PT", 86.95544982698962],
  ["SY", 64.12024221453288],
  ["SN", 62.27076124567474],
  ["KH", 89.91998269896195],
  ["EC", 67.83780276816609],
  ["ET", 63.06790657439446],
  ["DE", 92.12110726643598],
  ["SL", 93.9537197231834],
  ["PW", 91.71020761245676],
  ["SZ", 84.75259515570934],
  ["PA", 67.90268166089966],
  ["SM", 88.98442906574394],
  ["IT", 63.318771626297575],
  ["JO", 97.97794117647058],
  ["GH", 66.07525951557093],
  ["CG", 61.99437716262975],
  ["BT", 50.14792387543252],
  ["AO", 56.062283737024224],
  ["ML", 61.413494809688586],
  ["TN", 92.64532871972318],
  ["LI", 57.57828719723184],
  ["MM", 70.15657439446366],
  ["MZ", 95.98053633217994],
  ["NA", 70.66955017301038],
  ["CF", 77.87975778546713],
  ["BB", 92.23399653979239],
  ["CD", 82.94723183391004],
  ["BW", 92.35813148788927],
  ["AL", 34.410034602076124],
  ["NC", 75.73875432525952],
  ["MX", 59.080882352941174],
  ["TZ", 91.68339100346022],
  ["VE", 65.21929065743944],
  ["MN", 53.27897923875432],
  ["MY", 61.73226643598616],
  ["AM", 94.33434256055364],
  ["CR", 74.87759515570934],
  ["BA", 87.28589965397924],
  ["AZ", 93.20544982698962],
  ["CV", 85.58953287197232],
  ["BR", 87.91089965397923],
  ["CA", 92.56920415224914],
  ["BE", 49.10553633217993],
  ["TH", 65.5454152249135],
  ["WS", 32.92387543252595],
  ["UZ", 89.67171280276817],
  ["Wales", 74.80320069204153],
  ["NG", 96.37673010380622],
  ["NP", 69.48356401384082],
  ["MK", 83.25389273356402],
  ["BD", 92.13581314878893],
  ["BS", 80.28070934256056],
  ["CU", 94.82352941176471],
  ["BF", 60.278979238754324],
  ["NR", 91.6461937716263],
  ["NE", 93.16955017301038],
  ["ZW", 77.70847750865052],
  ["VC", 91.38321799307958],
  ["UY", 90.75778546712803],
  ["TJ", 91.33564013840831],
  ["VU", 51.6492214532872],
  ["ZA", 65.35121107266436],
  ["BG", 69.34126297577855],
]);

export const northkoreaMap = new Map<string, number>([
  ["CY", 87.19117647058823],
  ["AF", 89.66998269896193],
  ["CN", 44.16046712802768],
  ["BJ", 76.41003460207612],
  ["LA", 89.09688581314879],
  ["ME", 85.6825259515571],
  ["OM", 59.02162629757785],
  ["NI", 92.21842560553634],
  ["MR", 93.63451557093425],
  ["TG", 85.29282006920415],
  ["TP", 57.24653979238754],
  ["North Korea", 0],
  ["VN", 49.081747404844286],
  ["ZM", 86.6068339100346],
  ["MD", 78.29022491349481],
  ["CO", 91.12889273356402],
  ["AG", 90.26859861591696],
  ["AE", 93.39576124567473],
  ["CZ", 73.68685121107266],
  ["AR", 93.42084775086505],
  ["BI", 84.7962802768166],
  ["CM", 91.06271626297577],
  ["LB", 79.89576124567475],
  ["LU", 81.1946366782007],
  ["TD", 93.67517301038062],
  ["TR", 45.26254325259516],
  ["Scotland", 67.75562283737024],
  ["UA", 76.37283737024222],
  ["LT", 94.01816608996539],
  ["LC", 96.47015570934256],
  ["MG", 92.09645328719724],
  ["CL", 61.75648788927336],
  ["BH", 90.08780276816609],
  ["AD", 72.5250865051903],
  ["CH", 93.13321799307958],
  ["NO", 65.11980968858131],
  ["MT", 61.78806228373702],
  ["MC", 88.90960207612457],
  ["TV", 79.07396193771626],
  ["TW", 93.48053633217994],
  ["US", 86.05147058823529],
  ["MU", 88.28589965397924],
  ["BZ", 69.61418685121107],
  ["CI", 92.73053633217994],
  ["BM", 53.90138408304498],
  ["CK", 81.10164359861591],
  ["AT", 69.02508650519032],
  ["BO", 82.1470588235294],
  ["LS", 93.36029411764706],
  ["NL", 77.12543252595155],
  ["MW", 88.75865051903115],
  ["YE", 91.85250865051903],
  ["UG", 87.98442906574394],
  ["TT", 89.83001730103807],
  ["NZ", 81.96020761245674],
  ["MA", 92.27768166089966],
  ["MV", 66.45025951557093],
  ["LR", 90.16911764705883],
  ["BY", 88.2720588235294],
  ["BN", 87.1401384083045],
  ["AU", 89.38148788927336],
  ["GE", 86.45977508650519],
  ["GR", 67.47361591695503],
  ["IN", 93.39662629757785],
  ["GBENG", 88.13451557093425],
  ["RS", 87.53762975778547],
  ["PL", 66.78676470588235],
  ["SV", 60.607698961937714],
  ["SA", 88.87370242214533],
  ["HK", 49.90268166089965],
  ["KG", 49.51773356401384],
  ["GD", 81.41911764705883],
  ["GQ", 79.75735294117648],
  ["DJ", 91.1258650519031],
  ["KE", 90.32525951557093],
  ["KR", 87.79455017301038],
  ["SC", 65.05622837370242],
  ["ST", 81.42517301038062],
  ["PY", 73.03114186851211],
  ["SB", 74.33693771626298],
  ["IL", 78.83650519031143],
  ["DK", 93.14273356401384],
  ["DO", 91.57915224913495],
  ["RU", 80.55968858131489],
  ["SG", 66.62240484429066],
  ["PK", 92.25086505190312],
  ["GB", 62.845155709342556],
  ["GU", 88.45501730103807],
  ["GW", 90.6682525951557],
  ["JP", 88.7045847750865],
  ["SR", 93.99264705882354],
  ["SE", 65.61634948096886],
  ["SD", 82.7954152249135],
  ["SS", 73.41955017301038],
  ["PH", 77.56012110726644],
  ["RW", 89.85596885813149],
  ["KU", 91.22491349480968],
  ["HN", 92.64057093425605],
  ["GA", 93.86029411764706],
  ["DZ", 89.1924740484429],
  ["DM", 85.62889273356402],
  ["FR", 73.08477508650519],
  ["GL", 67.12067474048443],
  ["ES", 92.80839100346022],
  ["HT", 69.78762975778547],
  ["PE", 57.423442906574394],
  ["QA", 92.21583044982698],
  ["SI", 78.95112456747405],
  ["PR", 89.13581314878893],
  ["PS", 91.74264705882354],
  ["IQ", 91.17906574394463],
  ["HU", 91.14489619377163],
  ["KN", 91.34861591695503],
  ["KY", 80.95891003460207],
  ["FI", 91.26773356401384],
  ["ER", 57.41479238754326],
  ["GM", 91.18728373702422],
  ["EE", 95.16955017301038],
  ["EG", 91.2067474048443],
  ["ID", 89.64576124567473],
  ["IS", 56.404844290657444],
  ["SK", 77.03546712802768],
  ["RO", 92.55882352941175],
  ["PG", 90.25692041522491],
  ["KM", 86.81660899653978],
  ["IR", 85.25519031141869],
  ["KZ", 85.0544982698962],
  ["IE", 92.8659169550173],
  ["GN", 92.52032871972318],
  ["FJ", 83.76730103806229],
  ["GY", 88.25562283737024],
  ["JM", 89.78503460207612],
  ["KI", 71.73442906574394],
  ["HR", 88.48442906574394],
  ["SO", 93.74048442906575],
  ["PT", 89.97361591695501],
  ["SY", 91.07915224913495],
  ["SN", 72.4576124567474],
  ["KH", 57.8507785467128],
  ["EC", 81.64965397923876],
  ["ET", 77.6946366782007],
  ["DE", 93.19896193771626],
  ["SL", 93.20198961937717],
  ["PW", 93.27768166089966],
  ["SZ", 84.4204152249135],
  ["PA", 68.01773356401384],
  ["SM", 88.19723183391004],
  ["IT", 73.51081314878893],
  ["JO", 91.77595155709342],
  ["GH", 91.48659169550173],
  ["CG", 71.6167820069204],
  ["BT", 91.83044982698962],
  ["AO", 92.5],
  ["ML", 92.02768166089965],
  ["TN", 51.61548442906574],
  ["LI", 93.0696366782007],
  ["MM", 80.73053633217994],
  ["MZ", 81.33520761245676],
  ["NA", 74.03806228373703],
  ["CF", 70.86375432525952],
  ["BB", 94.25173010380622],
  ["CD", 80.24956747404845],
  ["BW", 97.3416955017301],
  ["AL", 53.664792387543244],
  ["NC", 75.00735294117648],
  ["MX", 88.85164359861591],
  ["TZ", 91.98356401384083],
  ["VE", 78.21323529411764],
  ["MN", 79.62024221453288],
  ["MY", 60.56833910034602],
  ["AM", 82.8083910034602],
  ["CR", 88.74048442906575],
  ["BA", 88.98745674740485],
  ["AZ", 68.75346020761246],
  ["CV", 55.95847750865052],
  ["BR", 86.27941176470588],
  ["CA", 88.64792387543253],
  ["BE", 75.49134948096886],
  ["TH", 90.36807958477509],
  ["WS", 91.46410034602076],
  ["UZ", 88.27638408304497],
  ["Wales", 75.20631487889273],
  ["NG", 92.95501730103807],
  ["NP", 63.838667820069205],
  ["MK", 80.48226643598616],
  ["BD", 80.26427335640139],
  ["BS", 93.8862456747405],
  ["CU", 80.13667820069205],
  ["BF", 69.24956747404845],
  ["NR", 94.46885813148789],
  ["NE", 93.30276816608996],
  ["ZW", 72.92214532871972],
  ["VC", 90.79152249134948],
  ["UY", 83.70285467128028],
  ["TJ", 93.42863321799308],
  ["VU", 80.28373702422145],
  ["ZA", 84.4969723183391],
  ["BG", 80.41825259515572],
]);

export const vnMap = new Map<string, number>([
  ["CY", 93.20510323334632],
  ["AF", 90.73269705233086],
  ["CN", 9.114725360342813],
  ["BJ", 70.85508375535645],
  ["LA", 45.332424360472665],
  ["ME", 87.70549279314375],
  ["OM", 56.236527723672246],
  ["NI", 93.96052460719388],
  ["MR", 94.3530061031035],
  ["TG", 77.08933904687703],
  ["TP", 29.36631606284898],
  ["North Korea", 49.84028048305415],
  ["VN", 0],
  ["ZM", 85.29963641085573],
  ["MD", 62.39254642254253],
  ["CO", 71.82443838462538],
  ["AG", 45.95052590572653],
  ["AE", 72.69120893390468],
  ["CZ", 61.91663420335022],
  ["AR", 95.943383976107],
  ["BI", 63.275223996883526],
  ["CM", 71.99162446435527],
  ["LB", 46.16510842747695],
  ["LU", 64.84514998052201],
  ["TD", 64.15920010388261],
  ["TR", 10.869367614595507],
  ["Scotland", 89.61433580054539],
  ["UA", 97.00688222308791],
  ["LT", 64.4111154395533],
  ["LC", 97.67595117517206],
  ["MG", 96.36378392416569],
  ["CL", 50.601220620698605],
  ["BH", 39.16926373198286],
  ["AD", 62.08187248409297],
  ["CH", 94.33742371120634],
  ["NO", 36.674133229450725],
  ["MT", 51.43942345149981],
  ["MC", 52.152317880794705],
  ["TV", 76.47286066744579],
  ["TW", 96.16510842747695],
  ["US", 85.06297883391767],
  ["MU", 71.44948707960005],
  ["BZ", 73.32943773535905],
  ["CI", 97.3357356187508],
  ["BM", 25.999545513569664],
  ["CK", 80.62329567588625],
  ["AT", 33.17328918322296],
  ["BO", 60.05940786910791],
  ["LS", 97.22958057395144],
  ["NL", 96.8598233995585],
  ["MW", 66.18263861836125],
  ["YE", 65.31749123490455],
  ["UG", 93.14472146474483],
  ["TT", 30.364887676925072],
  ["NZ", 81.24659135177251],
  ["MA", 6.788728736527724],
  ["MV", 28.95890144137125],
  ["LR", 57.729191014154004],
  ["BY", 90.06297883391767],
  ["BN", 93.71412803532009],
  ["AU", 93.43948837813271],
  ["GE", 91.34430593429424],
  ["GR", 95.0405791455655],
  ["IN", 95.24834437086092],
  ["GBENG", 75.58076873133359],
  ["RS", 88.51934813660563],
  ["PL", 52.48733930658356],
  ["SV", 94.85553824178679],
  ["SA", 90.62686664069601],
  ["HK", 6.128100246721205],
  ["KG", 7.790222049084535],
  ["GD", 53.47552265939488],
  ["GQ", 63.63589144266978],
  ["DJ", 94.4825347357486],
  ["KE", 93.11680301259577],
  ["KR", 94.09102713933255],
  ["SC", 68.18692377613297],
  ["ST", 81.502726918582],
  ["PY", 63.38462537332814],
  ["SB", 86.5754447474354],
  ["IL", 95.34248798857291],
  ["DK", 25.31651733541099],
  ["DO", 62.60096091416699],
  ["RU", 64.45072068562524],
  ["SG", 55.36910790806389],
  ["PK", 94.11277756135567],
  ["GB", 61.50792104921439],
  ["GU", 80.3489806518634],
  ["GW", 65.36326451110246],
  ["JP", 95.41650435008441],
  ["SR", 95.6940657057525],
  ["SE", 96.28100246721205],
  ["SD", 66.93286586157642],
  ["SS", 79.777626282301],
  ["PH", 60.952473704713675],
  ["RW", 92.18802752889235],
  ["KU", 68.55862874951305],
  ["HN", 95.99402674977276],
  ["GA", 96.27808076873133],
  ["DZ", 92.668484612388],
  ["DM", 93.58297623685236],
  ["FR", 64.87858719646799],
  ["GL", 52.147123750162315],
  ["ES", 43.36741981560836],
  ["HT", 50.0525905726529],
  ["PE", 31.293338527463966],
  ["QA", 94.25691468640436],
  ["SI", 62.15134398130113],
  ["PR", 88.64368263861836],
  ["PS", 80.12206206986106],
  ["IQ", 62.92137384755227],
  ["HU", 65.13667056226464],
  ["KN", 68.32164653941047],
  ["KY", 80.02434748733931],
  ["FI", 97.05005843396961],
  ["ER", 79.37313335930398],
  ["GM", 63.74724061810154],
  ["EE", 98.79950655758992],
  ["EG", 62.96519932476302],
  ["ID", 52.49253343721595],
  ["IS", 81.11706271912739],
  ["SK", 58.83878717049734],
  ["RO", 63.89851967276977],
  ["PG", 57.89020906375796],
  ["KM", 78.53525516166732],
  ["IR", 87.50162316582262],
  ["KZ", 88.12848980651863],
  ["IE", 97.34514998052201],
  ["GN", 64.02512660693417],
  ["FJ", 80.13180106479678],
  ["GY", 73.78619659784444],
  ["JM", 92.37891182963251],
  ["KI", 43.78749513050253],
  ["HR", 90.79307882093235],
  ["SO", 94.40851837423712],
  ["PT", 90.08635242176341],
  ["SY", 63.97967796390079],
  ["SN", 62.69348136605636],
  ["KH", 91.52772367225036],
  ["EC", 69.16536813400857],
  ["ET", 60.139916893909884],
  ["DE", 96.62478898844306],
  ["SL", 97.20945331775094],
  ["PW", 94.7357486040774],
  ["SZ", 85.84761719257239],
  ["PA", 72.66004415011037],
  ["SM", 92.63374886378392],
  ["IT", 64.46922477600312],
  ["JO", 72.8356706921179],
  ["GH", 63.76087521101156],
  ["CG", 62.767173094403326],
  ["BT", 55.92228282041294],
  ["AO", 52.88306713413843],
  ["ML", 63.889754577327615],
  ["TN", 93.18919620828464],
  ["LI", 49.15238280742761],
  ["MM", 64.09524737047137],
  ["MZ", 80.39150759641605],
  ["NA", 69.13063238540448],
  ["CF", 85.21847811972472],
  ["BB", 96.4825996623815],
  ["CD", 78.221010258408],
  ["BW", 98.76606934164394],
  ["AL", 13.682313985196728],
  ["NC", 73.91799766264121],
  ["MX", 61.950071419296194],
  ["TZ", 93.76931567328919],
  ["VE", 62.10459680560966],
  ["MN", 36.86761459550708],
  ["MY", 59.87728866380989],
  ["AM", 96.43585248669004],
  ["CR", 72.92169848071678],
  ["BA", 90.18569017010778],
  ["AZ", 94.15205817426309],
  ["CV", 87.3490455784963],
  ["BR", 90.31359563693027],
  ["CA", 93.92124399428646],
  ["BE", 64.90942734709778],
  ["TH", 64.11634852616544],
  ["WS", 26.13167121153097],
  ["UZ", 91.29755875860278],
  ["Wales", 79.00370081807557],
  ["NG", 97.36787430203869],
  ["NP", 61.181989352032204],
  ["MK", 79.80457083495651],
  ["BD", 91.6806258927412],
  ["BS", 94.69419555901831],
  ["CU", 75.9514998052201],
  ["BF", 50.92065965459032],
  ["NR", 94.18127515907025],
  ["NE", 96.74652642513959],
  ["ZW", 78.30898584599403],
  ["VC", 94.98896247240619],
  ["UY", 93.94818854694196],
  ["TJ", 93.39793533307362],
  ["VU", 65.92552915205817],
  ["ZA", 72.4987014673419],
  ["BG", 64.48772886638099],
]);

export const zmMap = new Map<string, number>([
  ["CY", 95.04155304505908],
  ["AF", 77.40520711595896],
  ["CN", 84.35138293728087],
  ["BJ", 91.78872873652773],
  ["LA", 89.35462926892612],
  ["ME", 76.52350344111154],
  ["OM", 77.77691208933905],
  ["NI", 95.51194650045448],
  ["MR", 90.57070510323335],
  ["TG", 93.71607583430723],
  ["TP", 84.48058693676145],
  ["North Korea", 87.61686793922867],
  ["VN", 85.29963641085573],
  ["ZM", 0],
  ["MD", 90.25159070250616],
  ["CO", 93.66413452798338],
  ["AG", 89.47928840410336],
  ["AE", 91.49493572263341],
  ["CZ", 90.1834177379561],
  ["AR", 96.88871575120113],
  ["BI", 87.6603687832749],
  ["CM", 94.80814179976626],
  ["LB", 88.06908193741071],
  ["LU", 95.32723022984028],
  ["TD", 95.49636410855733],
  ["TR", 84.99155953772237],
  ["Scotland", 92.01045318789768],
  ["UA", 96.33813790416829],
  ["LT", 93.23042462017919],
  ["LC", 97.99928580703805],
  ["MG", 94.91202441241397],
  ["CL", 92.06206986105701],
  ["BH", 85.49961044020257],
  ["AD", 92.49253343721595],
  ["CH", 93.02265939488377],
  ["NO", 86.92507466562785],
  ["MT", 88.13952733411246],
  ["MC", 95.01428385923906],
  ["TV", 82.36040773925464],
  ["TW", 92.73016491364758],
  ["US", 86.73191793273601],
  ["MU", 93.3382028308012],
  ["BZ", 82.98305414881185],
  ["CI", 95.53207375665498],
  ["BM", 85.17660044150111],
  ["CK", 86.21412803532009],
  ["AT", 95.54798078171666],
  ["BO", 94.88183352811323],
  ["LS", 96.59005323983898],
  ["NL", 96.34008570315544],
  ["MW", 90.52915205817426],
  ["YE", 92.76132969744188],
  ["UG", 89.35625243474873],
  ["TT", 88.1872484092975],
  ["NZ", 84.64355278535255],
  ["MA", 85.20322036099208],
  ["MV", 93.54726658875471],
  ["LR", 95.24087780807687],
  ["BY", 88.23951434878587],
  ["BN", 89.86300480457083],
  ["AU", 91.04791585508376],
  ["GE", 93.47065316192702],
  ["GR", 97.19159849370212],
  ["IN", 75.98720945331775],
  ["GBENG", 96.63582651603689],
  ["RS", 85.38663809894818],
  ["PL", 99.40462277626281],
  ["SV", 95.4661732242566],
  ["SA", 88.21354369562394],
  ["HK", 83.60180496039476],
  ["KG", 79.89124788988443],
  ["GD", 85.87715881054409],
  ["GQ", 87.90124659135176],
  ["DJ", 95.3019088430074],
  ["KE", 90.3113232047786],
  ["KR", 94.85943383976107],
  ["SC", 85.45351253084014],
  ["ST", 91.60531099857161],
  ["PY", 93.84722763277496],
  ["SB", 86.77996364108557],
  ["IL", 93.24600701207635],
  ["DK", 94.98441760810285],
  ["DO", 93.41546552395793],
  ["RU", 93.17815868069081],
  ["SG", 91.50824568237891],
  ["PK", 92.29158550837553],
  ["GB", 83.32099727308142],
  ["GU", 86.4676665368134],
  ["GW", 95.7034800675237],
  ["JP", 97.57693805999222],
  ["SR", 93.26094013764447],
  ["SE", 94.73087910660954],
  ["SD", 89.81008959875341],
  ["SS", 88.85826516036879],
  ["PH", 86.99941566030385],
  ["RW", 93.42390598623555],
  ["KU", 91.1683547591222],
  ["HN", 94.96688741721854],
  ["GA", 94.57343202181535],
  ["DZ", 94.7831450460979],
  ["DM", 85.77587326321256],
  ["FR", 95.37624983768342],
  ["GL", 96.58615764186469],
  ["ES", 88.82385404492923],
  ["HT", 92.47500324633164],
  ["PE", 90.46390079210492],
  ["QA", 91.64329307882093],
  ["SI", 92.13673548889754],
  ["PR", 88.01162186728996],
  ["PS", 95.6846513439813],
  ["IQ", 90.98818335281132],
  ["HU", 96.74814959096221],
  ["KN", 89.04427996364109],
  ["KY", 86.59005323983898],
  ["FI", 98.24146214777302],
  ["ER", 79.01701077782106],
  ["GM", 95.37722373717699],
  ["EE", 92.40618101545253],
  ["EG", 91.71503700818076],
  ["ID", 95.23048954681211],
  ["IS", 92.13381379041682],
  ["SK", 89.87923646279704],
  ["RO", 90.18082067263991],
  ["PG", 88.27002986625114],
  ["KM", 87.17893780028567],
  ["IR", 86.49363718997533],
  ["KZ", 84.06245942085442],
  ["IE", 88.81281651733542],
  ["GN", 95.09349435138293],
  ["FJ", 88.60472665887546],
  ["GY", 88.79755875860278],
  ["JM", 88.34339696143358],
  ["KI", 83.98292429554604],
  ["HR", 88.76769250746656],
  ["SO", 93.60829762368523],
  ["PT", 86.32028308011947],
  ["SY", 91.44039735099338],
  ["SN", 88.29275418776781],
  ["KH", 89.47149720815479],
  ["EC", 86.65692767173094],
  ["ET", 80.12498376834178],
  ["DE", 96.93026879626022],
  ["SL", 95.96805609661084],
  ["PW", 93.09115699259837],
  ["SZ", 83.99785742111415],
  ["PA", 94.9435138293728],
  ["SM", 93.5781067393845],
  ["IT", 95.22399688352162],
  ["JO", 94.06830281781586],
  ["GH", 95.57070510323335],
  ["CG", 88.23009998701468],
  ["BT", 88.32716530320738],
  ["AO", 86.93643682638618],
  ["ML", 90.18146993896896],
  ["TN", 91.39559797428906],
  ["LI", 87.36300480457083],
  ["MM", 94.4055966757564],
  ["MZ", 87.10914166991299],
  ["NA", 86.02324373457992],
  ["CF", 94.7562004934424],
  ["BB", 94.32703544994156],
  ["CD", 90.02726918582002],
  ["BW", 96.02843786521231],
  ["AL", 81.18848201532268],
  ["NC", 91.6400467471757],
  ["MX", 87.10816777041943],
  ["TZ", 94.21795870666148],
  ["VE", 88.5323334631866],
  ["MN", 89.24555252564602],
  ["MY", 83.76931567328919],
  ["AM", 94.52343851447864],
  ["CR", 90.40319439033891],
  ["BA", 87.39579275418777],
  ["AZ", 91.03395662900921],
  ["CV", 90.13277496429035],
  ["BR", 86.15536943254124],
  ["CA", 91.97279574081288],
  ["BE", 95.107778210622],
  ["TH", 93.61024542267238],
  ["WS", 85.11005064277366],
  ["UZ", 91.44169588365148],
  ["Wales", 78.06843267108168],
  ["NG", 95.4716919880535],
  ["NP", 86.01934813660563],
  ["MK", 80.13796909492274],
  ["BD", 90.59667575639527],
  ["BS", 93.3761849110505],
  ["CU", 89.93377483443709],
  ["BF", 91.5553174912349],
  ["NR", 91.8893650175302],
  ["NE", 94.50947928840411],
  ["ZW", 69.47604207245813],
  ["VC", 93.29502661991948],
  ["UY", 93.97318530061031],
  ["TJ", 95.24899363718997],
  ["VU", 91.05603168419685],
  ["ZA", 88.85404492922997],
  ["BG", 94.53317750941436],
]);

export const mdMap = new Map<string, number>([
  ["CY", 91.1916089965398],
  ["AF", 93.64186851211073],
  ["CN", 91.17171280276817],
  ["BJ", 70.10510380622837],
  ["LA", 78.82828719723184],
  ["ME", 89.43425605536332],
  ["OM", 79.44766435986159],
  ["NI", 95.56704152249135],
  ["MR", 91.15614186851211],
  ["TG", 84.3992214532872],
  ["TP", 56.63019031141868],
  ["North Korea", 78.29022491349481],
  ["VN", 62.36807958477508],
  ["ZM", 90.22145328719724],
  ["MD", 0],
  ["CO", 67.34904844290658],
  ["AG", 64.38840830449827],
  ["AE", 95.61375432525952],
  ["CZ", 76.52768166089965],
  ["AR", 94.44506920415225],
  ["BI", 81.9515570934256],
  ["CM", 94.92603806228374],
  ["LB", 90.27508650519032],
  ["LU", 96.1007785467128],
  ["TD", 37.84558823529412],
  ["TR", 59.724913494809684],
  ["Scotland", 73.52551903114187],
  ["UA", 66.12197231833909],
  ["LT", 73.3879757785467],
  ["LC", 87.90484429065744],
  ["MG", 94.50432525951558],
  ["CL", 78.90138408304497],
  ["BH", 58.865916955017305],
  ["AD", 39.34948096885813],
  ["CH", 94.08996539792388],
  ["NO", 90.49394463667821],
  ["MT", 61.91479238754325],
  ["MC", 78.31055363321799],
  ["TV", 83.09861591695503],
  ["TW", 90.86072664359861],
  ["US", 63.71972318339101],
  ["MU", 81.92993079584775],
  ["BZ", 56.51254325259516],
  ["CI", 93.4234429065744],
  ["BM", 63.26211072664359],
  ["CK", 86.32612456747405],
  ["AT", 95.73442906574394],
  ["BO", 91.64186851211072],
  ["LS", 95.55017301038062],
  ["NL", 85.23615916955018],
  ["MW", 81.3507785467128],
  ["YE", 85.55320069204151],
  ["UG", 83.05666089965398],
  ["TT", 67.97837370242215],
  ["NZ", 86.09688581314879],
  ["MA", 61.61678200692041],
  ["MV", 69.06271626297578],
  ["LR", 70.4173875432526],
  ["BY", 88.4446366782007],
  ["BN", 76.36894463667821],
  ["AU", 84.00173010380622],
  ["GE", 87.65095155709342],
  ["GR", 73.0432525951557],
  ["IN", 94.69160899653978],
  ["GBENG", 86.33607266435986],
  ["RS", 88.19204152249135],
  ["PL", 79.00562283737024],
  ["SV", 73.95891003460207],
  ["SA", 90.11072664359861],
  ["HK", 92.23659169550173],
  ["KG", 58.74480968858131],
  ["GD", 62.33520761245674],
  ["GQ", 82.01989619377163],
  ["DJ", 92.83996539792388],
  ["KE", 92.32698961937716],
  ["KR", 91.26254325259515],
  ["SC", 72.51038062283737],
  ["ST", 85.97491349480968],
  ["PY", 73.75043252595155],
  ["SB", 68.30709342560553],
  ["IL", 82.60899653979239],
  ["DK", 66.88927335640138],
  ["DO", 81.04022491349481],
  ["RU", 82.86721453287197],
  ["SG", 91.0363321799308],
  ["PK", 93.86721453287197],
  ["GB", 70.93771626297578],
  ["GU", 88.030276816609],
  ["GW", 80.92301038062284],
  ["JP", 92.18728373702422],
  ["SR", 79.23313148788928],
  ["SE", 60.95285467128028],
  ["SD", 81.6128892733564],
  ["SS", 63.14489619377163],
  ["PH", 70.36461937716263],
  ["RW", 86.08693771626298],
  ["KU", 81.47923875432525],
  ["HN", 93.75735294117648],
  ["GA", 91.11072664359861],
  ["DZ", 92.48399653979239],
  ["DM", 88.51643598615917],
  ["FR", 93.2750865051903],
  ["GL", 76.01384083044984],
  ["ES", 69.79671280276817],
  ["HT", 79.09212802768167],
  ["PE", 60.34126297577854],
  ["QA", 93.34429065743944],
  ["SI", 81.42517301038062],
  ["PR", 86.08520761245674],
  ["PS", 92.99264705882354],
  ["IQ", 83.29368512110726],
  ["HU", 84.85380622837371],
  ["KN", 70.47967128027682],
  ["KY", 84.89792387543253],
  ["FI", 93.65916955017302],
  ["ER", 76.4636678200692],
  ["GM", 82.96626297577855],
  ["EE", 95.26557093425606],
  ["EG", 84.84126297577855],
  ["ID", 78.27076124567473],
  ["IS", 67.9545847750865],
  ["SK", 83.39489619377163],
  ["RO", 38.51427335640138],
  ["PG", 67.64965397923875],
  ["KM", 72.34039792387543],
  ["IR", 89.59039792387543],
  ["KZ", 92.93728373702422],
  ["IE", 93.87067474048443],
  ["GN", 71.44809688581316],
  ["FJ", 82.86721453287197],
  ["GY", 81.36678200692042],
  ["JM", 85.97967128027682],
  ["KI", 69.68987889273356],
  ["HR", 93.46842560553632],
  ["SO", 95.50865051903115],
  ["PT", 91.37629757785467],
  ["SY", 83.68814878892734],
  ["SN", 61.75778546712802],
  ["KH", 59.9173875432526],
  ["EC", 74.5242214532872],
  ["ET", 83.15657439446366],
  ["DE", 83.2136678200692],
  ["SL", 95.59558823529412],
  ["PW", 88.85121107266436],
  ["SZ", 81.75432525951557],
  ["PA", 59.422577854671275],
  ["SM", 91.63192041522491],
  ["IT", 93.27595155709344],
  ["JO", 91.86764705882354],
  ["GH", 80.4779411764706],
  ["CG", 69.12629757785467],
  ["BT", 87.54498269896193],
  ["AO", 75.57612456747404],
  ["ML", 38.36807958477509],
  ["TN", 60.23053633217993],
  ["LI", 74.92560553633218],
  ["MM", 73.61721453287198],
  ["MZ", 79.10207612456747],
  ["NA", 77.80752595155708],
  ["CF", 88.02465397923875],
  ["BB", 93.19896193771626],
  ["CD", 83.5363321799308],
  ["BW", 94.12759515570934],
  ["AL", 62.299740484429066],
  ["NC", 79.59256055363322],
  ["MX", 58.38581314878893],
  ["TZ", 89.07871972318338],
  ["VE", 72.35596885813149],
  ["MN", 57.634083044982695],
  ["MY", 58.72534602076125],
  ["AM", 82.48053633217994],
  ["CR", 78.78287197231833],
  ["BA", 79.72015570934255],
  ["AZ", 83.5211937716263],
  ["CV", 65.7612456747405],
  ["BR", 85.88062283737024],
  ["CA", 90.08347750865052],
  ["BE", 94.37932525951557],
  ["TH", 91.67733564013841],
  ["WS", 58.8689446366782],
  ["UZ", 91.88148788927336],
  ["Wales", 82.21064013840831],
  ["NG", 93.12889273356402],
  ["NP", 79.2015570934256],
  ["MK", 80.02854671280278],
  ["BD", 92.66219723183391],
  ["BS", 91.85769896193771],
  ["CU", 80],
  ["BF", 93.93382352941177],
  ["NR", 92.16219723183391],
  ["NE", 93.90268166089966],
  ["ZW", 79.41652249134948],
  ["VC", 71.49005190311418],
  ["UY", 81.74740484429066],
  ["TJ", 94.00692041522491],
  ["VU", 70.72534602076125],
  ["ZA", 87.7037197231834],
  ["BG", 93.16955017301038],
]);

export const coMap = new Map<string, number>([
  ["CY", 94.1981560836255],
  ["AF", 92.76068043111285],
  ["CN", 71.6669912998312],
  ["BJ", 53.82125697961304],
  ["LA", 72.7749642903519],
  ["ME", 75.25418776782236],
  ["OM", 89.41923126866641],
  ["NI", 94.25172055577198],
  ["MR", 95.55025321386833],
  ["TG", 84.42897026360214],
  ["TP", 71.49201402415271],
  ["North Korea", 92.02408778080769],
  ["VN", 71.82443838462538],
  ["ZM", 93.66413452798338],
  ["MD", 67.51720555771978],
  ["CO", 0],
  ["AG", 70.75704453967018],
  ["AE", 89.91462147773017],
  ["CZ", 64.99675366835476],
  ["AR", 96.75431762108818],
  ["BI", 77.26399168939099],
  ["CM", 72.61394624074795],
  ["LB", 67.72139981820543],
  ["LU", 97.35099337748345],
  ["TD", 74.17932736008311],
  ["TR", 70.53726788728737],
  ["Scotland", 90.42267238021037],
  ["UA", 99.77924944812362],
  ["LT", 40.618101545253865],
  ["LC", 96.01447863913778],
  ["MG", 98.7641215426568],
  ["CL", 71.84553954031944],
  ["BH", 79.31372549019608],
  ["AD", 73.74821451759512],
  ["CH", 95.91254382547721],
  ["NO", 90.84209842877549],
  ["MT", 82.96877028957277],
  ["MC", 99.77924944812362],
  ["TV", 86.9312426957538],
  ["TW", 95.91189455914815],
  ["US", 74.75100636281002],
  ["MU", 99.33774834437085],
  ["BZ", 68.2411375146085],
  ["CI", 97.7249707830152],
  ["BM", 63.0418127515907],
  ["CK", 66.30372678872874],
  ["AT", 73.50993377483444],
  ["BO", 82.33995584988962],
  ["LS", 96.34690299961044],
  ["NL", 96.46799116997794],
  ["MW", 98.45474613686534],
  ["YE", 97.35099337748345],
  ["UG", 74.71984157901571],
  ["TT", 77.18607972990522],
  ["NZ", 64.91494611089469],
  ["MA", 72.59544215037008],
  ["MV", 72.84411115439553],
  ["LR", 72.4811712764576],
  ["BY", 92.87852226983509],
  ["BN", 58.5404492922997],
  ["AU", 86.98285936891313],
  ["GE", 90.8989092325672],
  ["GR", 89.81593299571485],
  ["IN", 94.5471367354889],
  ["GBENG", 94.4932476301779],
  ["RS", 82.075704453967],
  ["PL", 73.06843267108167],
  ["SV", 86.48292429554604],
  ["SA", 91.42773665757694],
  ["HK", 68.4044279963641],
  ["KG", 66.43780028567718],
  ["GD", 65.47396442020516],
  ["GQ", 71.24983768341774],
  ["DJ", 93.93520322036099],
  ["KE", 90.32560706401766],
  ["KR", 90.12271133619011],
  ["SC", 86.97279574081288],
  ["ST", 79.51986754966887],
  ["PY", 87.3464485131801],
  ["SB", 88.00999870146734],
  ["IL", 91.81177769120893],
  ["DK", 75.31164783794313],
  ["DO", 83.89624724061811],
  ["RU", 55.84988962472406],
  ["SG", 97.68991040124659],
  ["PK", 95.56616023893001],
  ["GB", 74.555252564602],
  ["GU", 69.31080379171536],
  ["GW", 55.88527463965719],
  ["JP", 96.55499285807038],
  ["SR", 93.8368393715102],
  ["SE", 71.45500584339696],
  ["SD", 94.34716270614206],
  ["SS", 87.44059213089209],
  ["PH", 70.28827425009739],
  ["RW", 97.52597065316193],
  ["KU", 73.4514998052201],
  ["HN", 93.80599922088041],
  ["GA", 82.28022334761718],
  ["DZ", 94.81690689520842],
  ["DM", 86.23620309050773],
  ["FR", 81.8679392286716],
  ["GL", 73.55473315153877],
  ["ES", 49.20562264640956],
  ["HT", 71.37741851707571],
  ["PE", 81.22354239709128],
  ["QA", 96.0566809505259],
  ["SI", 72.20003895597974],
  ["PR", 91.7747695104532],
  ["PS", 94.95844695494091],
  ["IQ", 94.39163744968187],
  ["HU", 95.36423841059603],
  ["KN", 74.5390209063758],
  ["KY", 69.11699779249449],
  ["FI", 82.58213219062459],
  ["ER", 84.39975327879496],
  ["GM", 85.65121412803532],
  ["EE", 99.55849889624724],
  ["EG", 95.30905077262693],
  ["ID", 99.77924944812362],
  ["IS", 81.29463706012207],
  ["SK", 78.2615894039735],
  ["RO", 66.25211011556941],
  ["PG", 88.2047786001818],
  ["KM", 67.6645890144137],
  ["IR", 87.11238800155824],
  ["KZ", 92.64154005973249],
  ["IE", 97.72789248149591],
  ["GN", 74.03259316971823],
  ["FJ", 86.5835605765485],
  ["GY", 80.81223217763926],
  ["JM", 78.58719646799118],
  ["KI", 81.61894559148163],
  ["HR", 91.99584469549409],
  ["SO", 97.84768211920529],
  ["PT", 92.05720036358915],
  ["SY", 95.72458122321777],
  ["SN", 89.22347747045838],
  ["KH", 95.42267238021036],
  ["EC", 5.169458511881573],
  ["ET", 55.930723282690565],
  ["DE", 99.55849889624724],
  ["SL", 97.13024282560706],
  ["PW", 89.27022464614986],
  ["SZ", 83.91280353200882],
  ["PA", 84.06895208414493],
  ["SM", 91.79002726918583],
  ["IT", 89.47863913777432],
  ["JO", 92.89832489287106],
  ["GH", 82.4649396182314],
  ["CG", 80.96740683028179],
  ["BT", 58.02655499285807],
  ["AO", 93.58459940267497],
  ["ML", 74.25042202311388],
  ["TN", 69.2614595507077],
  ["LI", 72.3704713673549],
  ["MM", 40.53726788728736],
  ["MZ", 92.17504220231139],
  ["NA", 79.41208933904687],
  ["CF", 89.72211401116738],
  ["BB", 66.90332424360473],
  ["CD", 85.38988443059343],
  ["BW", 97.57174392935983],
  ["AL", 67.33378781976367],
  ["NC", 89.67763926762758],
  ["MX", 86.74360472665887],
  ["TZ", 91.04077392546422],
  ["VE", 24.25107128944293],
  ["MN", 72.46591351772497],
  ["MY", 76.87021166082327],
  ["AM", 83.44370860927152],
  ["CR", 91.0969354629269],
  ["BA", 57.71360862225685],
  ["AZ", 96.68387222438645],
  ["CV", 96.71114141020647],
  ["BR", 81.33326840670043],
  ["CA", 92.46883521620569],
  ["BE", 91.14076094013764],
  ["TH", 81.01545253863135],
  ["WS", 73.23529411764706],
  ["UZ", 93.88456044669523],
  ["Wales", 79.28483313855344],
  ["NG", 97.58018439163745],
  ["NP", 76.31281651733542],
  ["MK", 74.78152188027529],
  ["BD", 96.15114920140242],
  ["BS", 96.87962602259446],
  ["CU", 83.12004934424101],
  ["BF", 97.11693286586157],
  ["NR", 75.64407219841578],
  ["NE", 95.8518374237112],
  ["ZW", 81.21510193481366],
  ["VC", 74.24490325931697],
  ["UY", 77.5564861706272],
  ["TJ", 92.0471367354889],
  ["VU", 88.23983898195038],
  ["ZA", 85.69439033891703],
  ["BG", 71.7439293598234],
]);

export const agMap = new Map<string, number>([
  ["CY", 89.31135009069708],
  ["AF", 77.94992225965277],
  ["CN", 48.23043534594454],
  ["BJ", 72.57352941176471],
  ["LA", 66.81135009069708],
  ["ME", 48.36777662606893],
  ["OM", 67.85436641617],
  ["NI", 93.52811609225188],
  ["MR", 86.34102098989376],
  ["TG", 81.49423425757968],
  ["TP", 63.21909821197201],
  ["North Korea", 91.08771702513604],
  ["VN", 45.943897382741646],
  ["ZM", 89.77746825602487],
  ["MD", 64.70102358123866],
  ["CO", 70.91539258875356],
  ["AG", 0],
  ["AE", 76.8233350608966],
  ["CZ", 65.25978232702772],
  ["AR", 93.16597564135787],
  ["BI", 75.73918113500908],
  ["CM", 90.88850738533299],
  ["LB", 67.3529411764706],
  ["LU", 90.39841927960612],
  ["TD", 66.28174397512309],
  ["TR", 49.237820678932366],
  ["Scotland", 86.7148224928738],
  ["UA", 89.31037833635656],
  ["LT", 65.69933920704845],
  ["LC", 93.95730759264057],
  ["MG", 96.16027468256026],
  ["CL", 56.37438455558434],
  ["BH", 69.65373153666754],
  ["AD", 65.59600932884166],
  ["CH", 90.72719616480953],
  ["NO", 90.43988079813423],
  ["MT", 68.83778180875875],
  ["MC", 78.6774423425758],
  ["TV", 84.36900751490023],
  ["TW", 89.99481731018399],
  ["US", 66.52435864213527],
  ["MU", 89.89731795802022],
  ["BZ", 78.19188909043794],
  ["CI", 87.6107799948173],
  ["BM", 51.646475770925115],
  ["CK", 83.83842964498575],
  ["AT", 63.957307592640575],
  ["BO", 89.89634620367971],
  ["LS", 90.63552733868877],
  ["NL", 94.460028504794],
  ["MW", 59.151982378854626],
  ["YE", 91.0388053899974],
  ["UG", 75.12438455558434],
  ["TT", 49.2151464109873],
  ["NZ", 83.77980046644208],
  ["MA", 45.55454781031355],
  ["MV", 54.54554288675823],
  ["LR", 67.44331433013734],
  ["BY", 89.8199015288935],
  ["BN", 91.608901269759],
  ["AU", 85.57592640580461],
  ["GE", 89.18275459963722],
  ["GR", 85.13863695257838],
  ["IN", 92.86084477844001],
  ["GBENG", 82.48509976677896],
  ["RS", 87.21948691370821],
  ["PL", 59.57307592640581],
  ["SV", 91.91629955947137],
  ["SA", 89.20899196683078],
  ["HK", 42.49157812904898],
  ["KG", 40.51664939103395],
  ["GD", 60.017167660015545],
  ["GQ", 68.40243586421353],
  ["DJ", 91.8246307333506],
  ["KE", 68.16694739569836],
  ["KR", 86.51690852552476],
  ["SC", 78.3933661570355],
  ["ST", 79.97311479657942],
  ["PY", 88.13779476548329],
  ["SB", 88.82903601969421],
  ["IL", 87.16215340761856],
  ["DK", 56.19266649391034],
  ["DO", 70.3219746048199],
  ["RU", 66.3196423944027],
  ["SG", 80.05927701477067],
  ["PK", 88.12678154962425],
  ["GB", 71.04398807981343],
  ["GU", 76.72097693703031],
  ["GW", 70.89628142005701],
  ["JP", 95.05474216118165],
  ["SR", 71.79288675822752],
  ["SE", 92.09121534076186],
  ["SD", 90.6747214304224],
  ["SS", 60.59989634620367],
  ["PH", 64.29742161181653],
  ["RW", 93.34542627623736],
  ["KU", 70.37412542109355],
  ["HN", 93.61622181912412],
  ["GA", 94.38844260171028],
  ["DZ", 88.18346721948691],
  ["DM", 90.29476548328581],
  ["FR", 64.49177247991707],
  ["GL", 59.3933013734128],
  ["ES", 66.14731795802021],
  ["HT", 51.694091733609746],
  ["PE", 44.49825084218709],
  ["QA", 90.52798652500648],
  ["SI", 67.1796449857476],
  ["PR", 83.47726094843225],
  ["PS", 57.413837781808766],
  ["IQ", 89.2144985747603],
  ["HU", 89.64563358383],
  ["KN", 62.96158331173879],
  ["KY", 84.44383259911893],
  ["FI", 88.69752526561285],
  ["ER", 73.02993003368748],
  ["GM", 87.99235553252139],
  ["EE", 90.95555843482768],
  ["EG", 90.02235034983156],
  ["ID", 78.88248250842187],
  ["IS", 83.39012697590049],
  ["SK", 90.56653278051309],
  ["RO", 66.30344648872764],
  ["PG", 76.83823529411765],
  ["KM", 80.29541331951283],
  ["IR", 87.58745789064525],
  ["KZ", 87.6376651982379],
  ["IE", 87.67232443638248],
  ["GN", 67.66681782845296],
  ["FJ", 88.12062710546775],
  ["GY", 72.23179580202125],
  ["JM", 91.41033946618295],
  ["KI", 72.97324436382483],
  ["HR", 92.27617258357087],
  ["SO", 93.72959315884944],
  ["PT", 88.55921223114797],
  ["SY", 89.66118165327805],
  ["SN", 69.33143301373413],
  ["KH", 63.518074630733345],
  ["EC", 69.1027468256025],
  ["ET", 65.40133454262762],
  ["DE", 71.48224928738016],
  ["SL", 94.41856698626587],
  ["PW", 91.77701477066597],
  ["SZ", 66.63481471883908],
  ["PA", 85.42076962943767],
  ["SM", 91.80325213785954],
  ["IT", 65.4985099766779],
  ["JO", 56.91079295154186],
  ["GH", 89.82378854625551],
  ["CG", 66.72324436382483],
  ["BT", 88.52714433791138],
  ["AO", 82.76690852552476],
  ["ML", 67.56543145892718],
  ["TN", 42.622117128789846],
  ["LI", 52.484451930551955],
  ["MM", 62.155351127235036],
  ["MZ", 81.81880020730759],
  ["NA", 76.17193573464628],
  ["CF", 90.84736978491837],
  ["BB", 87.09283493132935],
  ["CD", 84.97279087846591],
  ["BW", 95.71877429385852],
  ["AL", 44.417595231925375],
  ["NC", 87.05169733091475],
  ["MX", 62.967089919668304],
  ["TZ", 84.04282197460482],
  ["VE", 62.144013993262504],
  ["MN", 56.71287898419279],
  ["MY", 68.02766260689297],
  ["AM", 89.37904897641876],
  ["CR", 72.19098211972013],
  ["BA", 82.45465146410987],
  ["AZ", 77.4316532780513],
  ["CV", 81.36272350349832],
  ["BR", 89.1445322622441],
  ["CA", 89.01399326250325],
  ["BE", 63.10378336356569],
  ["TH", 74.40949727908784],
  ["WS", 48.778504793988084],
  ["UZ", 92.63766519823788],
  ["Wales", 82.15632288157553],
  ["NG", 86.68178284529671],
  ["NP", 70.9270536408396],
  ["MK", 78.47790878465923],
  ["BD", 93.15690593417983],
  ["BS", 92.35423684892459],
  ["CU", 70.29573723762633],
  ["BF", 93.11317698885722],
  ["NR", 88.25570095879762],
  ["NE", 93.76101321585904],
  ["ZW", 84.6793210676341],
  ["VC", 87.38403731536668],
  ["UY", 88.84231666234776],
  ["TJ", 87.94279606115573],
  ["VU", 88.34153925887536],
  ["ZA", 90.63747084736978],
  ["BG", 67.0678932365898],
]);

export const aeMap = new Map<string, number>([
  ["CY", 81.71799307958477],
  ["AF", 91.23096885813149],
  ["CN", 74.75605536332179],
  ["BJ", 91.4757785467128],
  ["LA", 77.78589965397924],
  ["ME", 74.15354671280276],
  ["OM", 76.45371972318338],
  ["NI", 75.01600346020761],
  ["MR", 96.02984429065744],
  ["TG", 83.2128027681661],
  ["TP", 91.30925605536332],
  ["North Korea", 93.39576124567473],
  ["VN", 72.54022491349481],
  ["ZM", 91.2076124567474],
  ["MD", 95.61375432525952],
  ["CO", 89.4757785467128],
  ["AG", 76.84861591695503],
  ["AE", 0],
  ["CZ", 85.23875432525952],
  ["AR", 76.7863321799308],
  ["BI", 79.22534602076125],
  ["CM", 96.5817474048443],
  ["LB", 68.24783737024221],
  ["LU", 66.56487889273356],
  ["TD", 97.23096885813149],
  ["TR", 73.69550173010381],
  ["Scotland", 82.97923875432525],
  ["UA", 98.86548442906575],
  ["LT", 91.25605536332179],
  ["LC", 97.24134948096885],
  ["MG", 97.00475778546713],
  ["CL", 74.71064013840831],
  ["BH", 96.61375432525952],
  ["AD", 95.69896193771626],
  ["CH", 84.81271626297577],
  ["NO", 88.7279411764706],
  ["MT", 90.83996539792388],
  ["MC", 74.82525951557093],
  ["TV", 86.84861591695501],
  ["TW", 96.2257785467128],
  ["US", 73.98010380622837],
  ["MU", 91.88624567474048],
  ["BZ", 79.48918685121107],
  ["CI", 77.60769896193771],
  ["BM", 74.83044982698962],
  ["CK", 86.94809688581316],
  ["AT", 58.55190311418686],
  ["BO", 87.4212802768166],
  ["LS", 77.5333044982699],
  ["NL", 73.5112456747405],
  ["MW", 88.0553633217993],
  ["YE", 41.22231833910035],
  ["UG", 94.87543252595155],
  ["TT", 68.3317474048443],
  ["NZ", 88.51124567474048],
  ["MA", 74.69723183391004],
  ["MV", 75.4515570934256],
  ["LR", 79.91868512110727],
  ["BY", 92.79282006920415],
  ["BN", 78.13235294117648],
  ["AU", 89.07698961937717],
  ["GE", 88.26384083044982],
  ["GR", 83.20328719723183],
  ["IN", 76.75432525951557],
  ["GBENG", 86.530276816609],
  ["RS", 90.7288062283737],
  ["PL", 74.92647058823529],
  ["SV", 75.54195501730105],
  ["SA", 92.3196366782007],
  ["HK", 67.7612456747405],
  ["KG", 67.7863321799308],
  ["GD", 78.23096885813149],
  ["GQ", 67.54108996539793],
  ["DJ", 89.96496539792388],
  ["KE", 93.6340830449827],
  ["KR", 81.81704152249135],
  ["SC", 84.14316608996539],
  ["ST", 59.04065743944636],
  ["PY", 67.47491349480968],
  ["SB", 89.26989619377163],
  ["IL", 77.83477508650519],
  ["DK", 65.44247404844292],
  ["DO", 73.00086505190312],
  ["RU", 91.32482698961938],
  ["SG", 76.20544982698962],
  ["PK", 94.3961937716263],
  ["GB", 75.67863321799308],
  ["GU", 90.31401384083046],
  ["GW", 73.28114186851211],
  ["JP", 86.23096885813149],
  ["SR", 88.31055363321799],
  ["SE", 96.92128027681662],
  ["SD", 44.19723183391003],
  ["SS", 93.54801038062284],
  ["PH", 91.46237024221453],
  ["RW", 95.18771626297577],
  ["KU", 69.3719723183391],
  ["HN", 75.03719723183391],
  ["GA", 70.80233564013841],
  ["DZ", 82.3166089965398],
  ["DM", 91.72837370242215],
  ["FR", 87.90354671280276],
  ["GL", 74.19766435986159],
  ["ES", 81.75389273356402],
  ["HT", 85.05406574394463],
  ["PE", 63.627162629757784],
  ["QA", 95.23096885813149],
  ["SI", 89.62110726643598],
  ["PR", 85.95501730103805],
  ["PS", 59.38321799307958],
  ["IQ", 44.147491349480966],
  ["HU", 66.04584775086505],
  ["KN", 80.76340830449827],
  ["KY", 87.69593425605537],
  ["FI", 96.30060553633218],
  ["ER", 56.94982698961938],
  ["GM", 83.26167820069205],
  ["EE", 99.37716262975779],
  ["EG", 42.837802768166085],
  ["ID", 74.82525951557093],
  ["IS", 87.12413494809688],
  ["SK", 95.58996539792388],
  ["RO", 97.15051903114187],
  ["PG", 73.01384083044982],
  ["KM", 84.01643598615917],
  ["IR", 70.16392733564014],
  ["KZ", 92.2612456747405],
  ["IE", 85.55579584775086],
  ["GN", 62.12932525951557],
  ["FJ", 86.82612456747405],
  ["GY", 55.61937716262976],
  ["JM", 77.3780276816609],
  ["KI", 72.12846020761245],
  ["HR", 72.5],
  ["SO", 94.71237024221453],
  ["PT", 92.90138408304497],
  ["SY", 43.02032871972318],
  ["SN", 93.75821799307958],
  ["KH", 80.06444636678201],
  ["EC", 87.40570934256056],
  ["ET", 63.40700692041522],
  ["DE", 99.42863321799308],
  ["SL", 49.839965397923876],
  ["PW", 96.46712802768165],
  ["SZ", 76.93079584775087],
  ["PA", 85.96712802768167],
  ["SM", 88.93425605536332],
  ["IT", 85.60510380622837],
  ["JO", 60.97794117647059],
  ["GH", 85.81487889273356],
  ["CG", 85.07266435986159],
  ["BT", 91.29022491349481],
  ["AO", 60.77465397923876],
  ["ML", 95.02551903114187],
  ["TN", 67.5553633217993],
  ["LI", 84.7166955017301],
  ["MM", 83.70544982698962],
  ["MZ", 82.94117647058825],
  ["NA", 83.83088235294117],
  ["CF", 87.82006920415225],
  ["BB", 96.03114186851212],
  ["CD", 89.54368512110726],
  ["BW", 93.28114186851211],
  ["AL", 71.36332179930795],
  ["NC", 93.09299307958477],
  ["MX", 91.97837370242215],
  ["TZ", 81.13192041522491],
  ["VE", 89.88624567474048],
  ["MN", 80.27854671280276],
  ["MY", 78.10250865051903],
  ["AM", 90.93425605536332],
  ["CR", 89.70328719723183],
  ["BA", 90.7318339100346],
  ["AZ", 89.44636678200692],
  ["CV", 88.50648788927336],
  ["BR", 73.98832179930795],
  ["CA", 91.57612456747405],
  ["BE", 95.26946366782006],
  ["TH", 91.7318339100346],
  ["WS", 84.4022491349481],
  ["UZ", 71.23961937716263],
  ["Wales", 87.08780276816609],
  ["NG", 74.75648788927336],
  ["NP", 67.70934256055364],
  ["MK", 85.74524221453287],
  ["BD", 96.8901384083045],
  ["BS", 93.55882352941177],
  ["CU", 72.3507785467128],
  ["BF", 97.80622837370242],
  ["NR", 96.18295847750865],
  ["NE", 79.68858131487889],
  ["ZW", 95.13840830449827],
  ["VC", 86.73140138408304],
  ["UY", 87.43944636678201],
  ["TJ", 74.00475778546712],
  ["VU", 92.67603806228374],
  ["ZA", 87.95025951557093],
  ["BG", 91.39792387543253],
]);

export const czMap = new Map<string, number>([
  ["CY", 65.82716530320738],
  ["AF", 82.18445656408258],
  ["CN", 58.36482275029217],
  ["BJ", 66.29236462797039],
  ["LA", 54.94708479418258],
  ["ME", 68.3313855343462],
  ["OM", 57.695753798208024],
  ["NI", 87.7412024412414],
  ["MR", 93.77580833657967],
  ["TG", 94.73542397091286],
  ["TP", 59.433515127905466],
  ["North Korea", 74.12089339046877],
  ["VN", 61.91663420335022],
  ["ZM", 90.1834177379561],
  ["MD", 76.60466173224256],
  ["CO", 64.99675366835476],
  ["AG", 65.38176860148033],
  ["AE", 85.43728087261394],
  ["CZ", 0],
  ["AR", 90.65283729385794],
  ["BI", 67.81781586806909],
  ["CM", 81.33619010518115],
  ["LB", 61.16835475912219],
  ["LU", 87.77041942604856],
  ["TD", 59.836384885079866],
  ["TR", 59.5188936501753],
  ["Scotland", 79.38254772107518],
  ["UA", 98.73977405531748],
  ["LT", 69.32443838462538],
  ["LC", 97.99376704324115],
  ["MG", 92.71620568757304],
  ["CL", 27.59511751720556],
  ["BH", 60.95831710167511],
  ["AD", 93.21094663030776],
  ["CH", 87.66231658226205],
  ["NO", 59.39196208284638],
  ["MT", 62.54122841189456],
  ["MC", 98.73295675886249],
  ["TV", 84.74970783015193],
  ["TW", 92.24061810154525],
  ["US", 76.5955720036359],
  ["MU", 96.89131281651734],
  ["BZ", 61.19205298013245],
  ["CI", 84.26373198285937],
  ["BM", 54.09362420464875],
  ["CK", 85.19250746656279],
  ["AT", 61.35112323074925],
  ["BO", 96.33489157252305],
  ["LS", 86.38196338137905],
  ["NL", 83.0187637969095],
  ["MW", 86.26184911050512],
  ["YE", 88.60115569406571],
  ["UG", 77.33184002077653],
  ["TT", 70.99402674977276],
  ["NZ", 87.57856122581484],
  ["MA", 59.82112712634723],
  ["MV", 69.41890663550188],
  ["LR", 57.09745487599013],
  ["BY", 91.03265809635113],
  ["BN", 81.70529801324503],
  ["AU", 86.4069601350474],
  ["GE", 69.93669653291781],
  ["GR", 81.38650824568238],
  ["IN", 88.39663680041554],
  ["GBENG", 61.96078431372549],
  ["RS", 86.21802363329438],
  ["PL", 59.21049214387742],
  ["SV", 87.42663290481755],
  ["SA", 89.3906635501883],
  ["HK", 60.054863004804574],
  ["KG", 60.14218932606155],
  ["GD", 75.59342942475004],
  ["GQ", 58.797558758602776],
  ["DJ", 95.21945201921828],
  ["KE", 82.71263472276328],
  ["KR", 66.98837813271004],
  ["SC", 73.46740683028177],
  ["ST", 93.19666277106869],
  ["PY", 87.52304895468122],
  ["SB", 88.33235943383976],
  ["IL", 73.66348526165433],
  ["DK", 60.90540189585768],
  ["DO", 70.60641475133099],
  ["RU", 41.37384755226594],
  ["SG", 96.24074795481107],
  ["PK", 92.06791325801844],
  ["GB", 66.54687702895727],
  ["GU", 67.9690949227373],
  ["GW", 87.48149590962213],
  ["JP", 61.700428515777176],
  ["SR", 87.95448643033372],
  ["SE", 82.80515517465264],
  ["SD", 85.54765614855214],
  ["SS", 80.19185820023374],
  ["PH", 59.696792624334506],
  ["RW", 97.10167510712895],
  ["KU", 58.72159459810414],
  ["HN", 87.83534605895338],
  ["GA", 96.12452928191144],
  ["DZ", 69.28775483703416],
  ["DM", 89.28450850538891],
  ["FR", 66.39722114011167],
  ["GL", 37.338981950396054],
  ["ES", 72.50519413063239],
  ["HT", 59.50071419296196],
  ["PE", 60.98266458901441],
  ["QA", 91.47188676795221],
  ["SI", 40.554148811842616],
  ["PR", 59.32930788209323],
  ["PS", 84.64614985066873],
  ["IQ", 87.74087780807687],
  ["HU", 87.98337878197636],
  ["KN", 65.67458771588105],
  ["KY", 86.2183482664589],
  ["FI", 62.5525905726529],
  ["ER", 77.41559537722374],
  ["GM", 92.72562004934424],
  ["EE", 98.19731203739775],
  ["EG", 89.05077262693156],
  ["ID", 98.94883781327101],
  ["IS", 72.75321386832879],
  ["SK", 40.59148162576289],
  ["RO", 59.66952343851448],
  ["PG", 82.29580573951435],
  ["KM", 60.33891702376315],
  ["IR", 59.21990650564861],
  ["KZ", 92.61914037138034],
  ["IE", 82.40877808076873],
  ["GN", 91.72997013374886],
  ["FJ", 86.82508765095443],
  ["GY", 82.89280612907415],
  ["JM", 89.85651214128035],
  ["KI", 84.47052330866121],
  ["HR", 87.19062459420854],
  ["SO", 95.4648746915985],
  ["PT", 91.0566809505259],
  ["SY", 88.1482924295546],
  ["SN", 78.95273341124529],
  ["KH", 84.57927541877677],
  ["EC", 68.28009349435138],
  ["ET", 66.94260485651215],
  ["DE", 88.02363329437736],
  ["SL", 88.20834956499156],
  ["PW", 98.47422412673679],
  ["SZ", 85.25061680301259],
  ["PA", 72.48214517595117],
  ["SM", 62.78178158680691],
  ["IT", 66.18166471886768],
  ["JO", 85.23373587845734],
  ["GH", 95.56810803791716],
  ["CG", 70.69081937410726],
  ["BT", 90.78236592650305],
  ["AO", 94.82177639267627],
  ["ML", 80.12985326580963],
  ["TN", 60.14381249188418],
  ["LI", 49.95292819114401],
  ["MM", 67.5740163615115],
  ["MZ", 89.54648746915986],
  ["NA", 71.13718997532787],
  ["CF", 75.11621867289962],
  ["BB", 74.21471237501623],
  ["CD", 89.7250357096481],
  ["BW", 96.97506817296455],
  ["AL", 60.31392027009479],
  ["NC", 92.09875340864822],
  ["MX", 66.67997662641216],
  ["TZ", 90.36196597844436],
  ["VE", 65.90312946370601],
  ["MN", 69.88572912608753],
  ["MY", 67.70451889365017],
  ["AM", 96.62900921958187],
  ["CR", 71.8179457213349],
  ["BA", 88.93617711985456],
  ["AZ", 86.53583950136345],
  ["CV", 76.57706791325802],
  ["BR", 86.92377613296975],
  ["CA", 78.16906895208415],
  ["BE", 81.57609401376445],
  ["TH", 69.91527074405921],
  ["WS", 48.83716400467472],
  ["UZ", 85.55707051032333],
  ["Wales", 54.17737956109596],
  ["NG", 82.11498506687444],
  ["NP", 60.770679132580185],
  ["MK", 64.10563563173613],
  ["BD", 92.80970003895598],
  ["BS", 93.65699259836386],
  ["CU", 78.38300220750551],
  ["BF", 94.73120373977405],
  ["NR", 77.9366316062849],
  ["NE", 88.56771847811973],
  ["ZW", 84.79677963900792],
  ["VC", 94.15757693805999],
  ["UY", 73.6281002467212],
  ["TJ", 84.61401116738087],
  ["VU", 89.86300480457083],
  ["ZA", 84.97110764835736],
  ["BG", 41.77639267627581],
]);

export const arMap = new Map<string, number>([
  ["CY", 71.42560553633218],
  ["AF", 96.42491349480969],
  ["CN", 96.64809688581315],
  ["BJ", 96.77404844290658],
  ["LA", 94.45778546712803],
  ["ME", 95.18131487889274],
  ["OM", 97.78235294117647],
  ["NI", 67.91280276816609],
  ["MR", 96.15363321799309],
  ["TG", 93.51038062283737],
  ["TP", 92.12076124567474],
  ["North Korea", 94.1522491349481],
  ["VN", 95.90276816608997],
  ["ZM", 96.94429065743945],
  ["MD", 94.38927335640138],
  ["CO", 96.88719723183391],
  ["AG", 93.23044982698961],
  ["AE", 77.23356401384083],
  ["CZ", 90.62283737024221],
  ["AR", 0],
  ["BI", 87.23079584775087],
  ["CM", 96.91730103806229],
  ["LB", 69.84913494809688],
  ["LU", 68.57681660899654],
  ["TD", 94.4750865051903],
  ["TR", 95.52283737024221],
  ["Scotland", 88.48892733564013],
  ["UA", 94.63598615916955],
  ["LT", 98.8809688581315],
  ["LC", 92.74359861591695],
  ["MG", 87.2674740484429],
  ["CL", 89.23356401384083],
  ["BH", 88.20726643598617],
  ["AD", 93.37335640138407],
  ["CH", 87.66401384083045],
  ["NO", 87.1484429065744],
  ["MT", 81.49134948096885],
  ["MC", 83.82768166089966],
  ["TV", 85.13633217993079],
  ["TW", 98.55432525951558],
  ["US", 85.61937716262976],
  ["MU", 94.31626297577854],
  ["BZ", 86.22491349480968],
  ["CI", 90.7235294117647],
  ["BM", 91.4318339100346],
  ["CK", 94.46159169550174],
  ["AT", 68.8121107266436],
  ["BO", 92.31868512110726],
  ["LS", 70.27197231833911],
  ["NL", 68.57681660899654],
  ["MW", 98.7726643598616],
  ["YE", 69.04740484429067],
  ["UG", 96.13944636678201],
  ["TT", 96.23356401384083],
  ["NZ", 95.90588235294118],
  ["MA", 99.01695501730103],
  ["MV", 98.47820069204151],
  ["LR", 85.17854671280277],
  ["BY", 93.88581314878893],
  ["BN", 80.16159169550173],
  ["AU", 95.62318339100347],
  ["GE", 86.57681660899654],
  ["GR", 79.40899653979238],
  ["IN", 69.11211072664359],
  ["GBENG", 87.01384083044982],
  ["RS", 90.23391003460208],
  ["PL", 84.0121107266436],
  ["SV", 68.34740484429066],
  ["SA", 94.95536332179931],
  ["HK", 93.60034602076125],
  ["KG", 91.519723183391],
  ["GD", 89.63910034602077],
  ["GQ", 75.09619377162629],
  ["DJ", 58.06055363321799],
  ["KE", 95.58719723183391],
  ["KR", 76.37474048442907],
  ["SC", 85.15743944636678],
  ["ST", 89.6204152249135],
  ["PY", 68.52456747404845],
  ["SB", 90.32560553633218],
  ["IL", 70.68892733564014],
  ["DK", 84.75674740484429],
  ["DO", 78.44221453287197],
  ["RU", 99.33287197231834],
  ["SG", 79.41730103806228],
  ["PK", 89.27820069204152],
  ["GB", 84.72698961937716],
  ["GU", 96.92941176470589],
  ["GW", 96.37439446366783],
  ["JP", 78.36332179930795],
  ["SR", 96.32352941176471],
  ["SE", 95.80276816608998],
  ["SD", 78.27923875432526],
  ["SS", 95.12698961937717],
  ["PH", 87.47439446366782],
  ["RW", 89.48166089965397],
  ["KU", 76.98823529411764],
  ["HN", 69.38788927335641],
  ["GA", 91.87716262975778],
  ["DZ", 84.34221453287198],
  ["DM", 91.65190311418685],
  ["FR", 90.8128027681661],
  ["GL", 83.75328719723183],
  ["ES", 90.62179930795847],
  ["HT", 97.4515570934256],
  ["PE", 90.91626297577855],
  ["QA", 88.43217993079585],
  ["SI", 97.81730103806228],
  ["PR", 88.9439446366782],
  ["PS", 77.9280276816609],
  ["IQ", 71.27335640138408],
  ["HU", 68.57612456747405],
  ["KN", 88.69792387543252],
  ["KY", 92.82906574394464],
  ["FI", 94.62145328719723],
  ["ER", 89.61764705882352],
  ["GM", 89.1484429065744],
  ["EE", 99.76470588235294],
  ["EG", 68.59100346020762],
  ["ID", 83.84083044982698],
  ["IS", 88.94152249134947],
  ["SK", 96.49653979238755],
  ["RO", 94.62768166089965],
  ["PG", 95.23148788927335],
  ["KM", 83.32941176470588],
  ["IR", 60.93667820069204],
  ["KZ", 87.22698961937716],
  ["IE", 90.73217993079585],
  ["GN", 94.29480968858131],
  ["FJ", 44.07058823529412],
  ["GY", 84.88166089965398],
  ["JM", 82.73806228373702],
  ["KI", 87.08062283737024],
  ["HR", 68.06089965397923],
  ["SO", 96.98892733564014],
  ["PT", 94.51972318339101],
  ["SY", 70.8522491349481],
  ["SN", 95.04878892733564],
  ["KH", 94.76020761245675],
  ["EC", 93.739446366782],
  ["ET", 94.41626297577854],
  ["DE", 98.96747404844291],
  ["SL", 68.8121107266436],
  ["PW", 91.18512110726643],
  ["SZ", 92.64567474048442],
  ["PA", 83.8280276816609],
  ["SM", 51.9598615916955],
  ["IT", 91.01314878892734],
  ["JO", 82.00242214532872],
  ["GH", 94.47889273356401],
  ["CG", 90.22560553633218],
  ["BT", 86.77301038062284],
  ["AO", 95.74671280276816],
  ["ML", 94.19204152249135],
  ["TN", 93.91038062283738],
  ["LI", 97.18166089965398],
  ["MM", 92.65916955017302],
  ["MZ", 95.64948096885814],
  ["NA", 92.93217993079584],
  ["CF", 83.62422145328719],
  ["BB", 95.45363321799309],
  ["CD", 90.84532871972318],
  ["BW", 25.248788927335642],
  ["AL", 99.83079584775086],
  ["NC", 92.42249134948098],
  ["MX", 91.85363321799308],
  ["TZ", 89.01903114186851],
  ["VE", 98.39792387543253],
  ["MN", 96.05778546712803],
  ["MY", 85.85743944636678],
  ["AM", 99.68131487889274],
  ["CR", 96.94429065743945],
  ["BA", 94.05986159169551],
  ["AZ", 97.8401384083045],
  ["CV", 90.2318339100346],
  ["BR", 90.10899653979239],
  ["CA", 92.05259515570934],
  ["BE", 94.57577854671281],
  ["TH", 99.4166089965398],
  ["WS", 98.73010380622837],
  ["UZ", 66.52179930795847],
  ["Wales", 86.87993079584774],
  ["NG", 90.91591695501731],
  ["NP", 84.82318339100347],
  ["MK", 84.60173010380623],
  ["BD", 96.63321799307958],
  ["BS", 90.92664359861593],
  ["CU", 89.54948096885813],
  ["BF", 97.89653979238754],
  ["NR", 97.73391003460208],
  ["NE", 70.17231833910034],
  ["ZW", 90.29723183391003],
  ["VC", 93.04948096885813],
  ["UY", 83.08615916955017],
  ["TJ", 68.13391003460208],
  ["VU", 92.83840830449826],
  ["ZA", 88.80899653979239],
  ["BG", 98.24878892733564],
]);

export const biMap = new Map<string, number>([
  ["CY", 71.42805651672434],
  ["AF", 90.25230680507497],
  ["CN", 64.14684256055364],
  ["BJ", 80.7125865051903],
  ["LA", 61.734429065743946],
  ["ME", 74.11404267589388],
  ["OM", 80.66428777393311],
  ["NI", 82.98514994232987],
  ["MR", 89.2016291810842],
  ["TG", 82.75050461361016],
  ["TP", 62.589388696655135],
  ["North Korea", 85.88956170703575],
  ["VN", 63.625648788927336],
  ["ZM", 88.08282871972318],
  ["MD", 82.58398212226066],
  ["CO", 77.40196078431373],
  ["AG", 75.77638408304497],
  ["AE", 79.67884948096886],
  ["CZ", 68.02479815455594],
  ["AR", 87.67481257208766],
  ["BI", 0],
  ["CM", 74.05457035755478],
  ["LB", 54.669838523644756],
  ["LU", 69.31156286043829],
  ["TD", 85.21337946943484],
  ["TR", 62.53568339100346],
  ["Scotland", 62.4052047289504],
  ["UA", 88.4183967704729],
  ["LT", 80.12326989619378],
  ["LC", 92.51225490196079],
  ["MG", 84.09926470588235],
  ["CL", 68.32900807381776],
  ["BH", 62.26679642445213],
  ["AD", 84.61505190311419],
  ["CH", 80.2479815455594],
  ["NO", 84.75778546712803],
  ["MT", 65.53416955017302],
  ["MC", 65.69744809688581],
  ["TV", 78.72188581314879],
  ["TW", 88.31531141868511],
  ["US", 61.62773933102653],
  ["MU", 75.4628027681661],
  ["BZ", 69.77688869665513],
  ["CI", 79.69975490196079],
  ["BM", 62.0645905420992],
  ["CK", 85.88271337946944],
  ["AT", 56.50735294117647],
  ["BO", 76.55024509803921],
  ["LS", 83.32143886966551],
  ["NL", 82.16767589388697],
  ["MW", 84.83636101499424],
  ["YE", 72.58109861591694],
  ["UG", 87.08405420991926],
  ["TT", 70.02126585928488],
  ["NZ", 86.74524221453287],
  ["MA", 65.38134371395617],
  ["MV", 64.26975201845444],
  ["LR", 62.56163494809689],
  ["BY", 85.03676470588235],
  ["BN", 82.90260957324107],
  ["AU", 86.03517877739331],
  ["GE", 74.64532871972318],
  ["GR", 80.04613610149943],
  ["IN", 85.46929065743944],
  ["GBENG", 67.903330449827],
  ["RS", 79.51052479815456],
  ["PL", 64.99351211072664],
  ["SV", 83.99617935409458],
  ["SA", 85.29772202998846],
  ["HK", 59.3508506343714],
  ["KG", 59.318050749711645],
  ["GD", 67.86908881199538],
  ["GQ", 68.49985582468281],
  ["DJ", 74.95530565167243],
  ["KE", 90.7378171856978],
  ["KR", 79.8367214532872],
  ["SC", 80.03135813148788],
  ["ST", 83.2046568627451],
  ["PY", 72.26931949250289],
  ["SB", 81.33650519031141],
  ["IL", 75.15066320645906],
  ["DK", 64.29750576701268],
  ["DO", 75.33304498269896],
  ["RU", 70.77494232987313],
  ["SG", 64.52566320645904],
  ["PK", 84.66371107266436],
  ["GB", 73.79469434832757],
  ["GU", 86.15736735870819],
  ["GW", 75.56408592848905],
  ["JP", 77.27905132641291],
  ["SR", 84.08196366782008],
  ["SE", 89.36887254901961],
  ["SD", 71.0766291810842],
  ["SS", 87.86908881199538],
  ["PH", 76.25756920415225],
  ["RW", 86.05716551326414],
  ["KU", 70.71294694348327],
  ["HN", 83.29692906574394],
  ["GA", 89.05168685121107],
  ["DZ", 80.02198673587083],
  ["DM", 89.40383506343714],
  ["FR", 75.67401960784314],
  ["GL", 65.32151095732411],
  ["ES", 60.55615628604383],
  ["HT", 78.57951268742791],
  ["PE", 68.6350201845444],
  ["QA", 84.93331891580162],
  ["SI", 69.9758506343714],
  ["PR", 77.9718137254902],
  ["PS", 83.99149365628604],
  ["IQ", 73.6159169550173],
  ["HU", 69.5725201845444],
  ["KN", 69.33210784313727],
  ["KY", 84.23154555940023],
  ["FI", 75.57489907727796],
  ["ER", 74.28525086505189],
  ["GM", 75.31970876585929],
  ["EE", 84.64388696655134],
  ["EG", 73.81992502883506],
  ["ID", 65.69744809688581],
  ["IS", 85.96561418685121],
  ["SK", 80.6603229527105],
  ["RO", 85.23897058823529],
  ["PG", 79.53359284890426],
  ["KM", 80.5503892733564],
  ["IR", 75.04397347174164],
  ["KZ", 85.44009515570934],
  ["IE", 64.76138985005767],
  ["GN", 83.36829584775086],
  ["FJ", 83.99221453287197],
  ["GY", 72.82439446366782],
  ["JM", 82.37636966551327],
  ["KI", 69.49682814302192],
  ["HR", 83.22303921568628],
  ["SO", 87.92207324106113],
  ["PT", 87.26751730103807],
  ["SY", 71.48356401384083],
  ["SN", 83.09616493656286],
  ["KH", 87.34032583621683],
  ["EC", 73.99870242214533],
  ["ET", 75.2642012687428],
  ["DE", 92.43115628604383],
  ["SL", 77.02386101499424],
  ["PW", 90.64518454440599],
  ["SZ", 81.87067474048443],
  ["PA", 73.42380334486735],
  ["SM", 81.68721164936564],
  ["IT", 73.53265570934256],
  ["JO", 86.77768166089965],
  ["GH", 76.69694348327566],
  ["CG", 76.64684256055364],
  ["BT", 83.53589965397924],
  ["AO", 79.2207324106113],
  ["ML", 68.13473183391004],
  ["TN", 57.44737600922723],
  ["LI", 78.76369665513265],
  ["MM", 51.385885236447514],
  ["MZ", 85.47505767012687],
  ["NA", 83.10049019607844],
  ["CF", 65.57021337946944],
  ["BB", 91.21756055363322],
  ["CD", 86.29721741637832],
  ["BW", 93.62240484429066],
  ["AL", 66.7913783160323],
  ["NC", 87.29310841983853],
  ["MX", 76.39850057670127],
  ["TZ", 80.05370530565166],
  ["VE", 76.95681949250289],
  ["MN", 77.11829584775086],
  ["MY", 69.62190023068051],
  ["AM", 81.10005767012687],
  ["CR", 78.98392445213379],
  ["BA", 87.44232987312573],
  ["AZ", 89.56531141868513],
  ["CV", 86.55384948096886],
  ["BR", 65.66068339100346],
  ["CA", 84.21604671280278],
  ["BE", 86.68613033448673],
  ["TH", 62.12550461361015],
  ["WS", 71.33109861591696],
  ["UZ", 75.78863898500576],
  ["Wales", 65.25735294117648],
  ["NG", 79.01672433679354],
  ["NP", 65.43000288350635],
  ["MK", 76.1854815455594],
  ["BD", 88.71972318339101],
  ["BS", 90.9717416378316],
  ["CU", 77.87125144175317],
  ["BF", 74.86807958477509],
  ["NR", 92.27040080738178],
  ["NE", 80.50173010380622],
  ["ZW", 87.6178633217993],
  ["VC", 86.35957324106113],
  ["UY", 74.53972029988466],
  ["TJ", 78.91508073817762],
  ["VU", 76.4071510957324],
  ["ZA", 83.04894752018454],
  ["BG", 69.59630911188005],
]);

export const cmMap = new Map<string, number>([
  ["CY", 93.52520082923037],
  ["AF", 90.41202384037315],
  ["CN", 66.2477325732055],
  ["BJ", 36.1774423425758],
  ["LA", 79.68903861103914],
  ["ME", 75.86550919927443],
  ["OM", 85.8256672713138],
  ["NI", 96.82430681523711],
  ["MR", 94.58927183208084],
  ["TG", 73.95925110132158],
  ["TP", 67.85209898937549],
  ["North Korea", 91.93800207307594],
  ["VN", 71.97428090178802],
  ["ZM", 95.00842187095103],
  ["MD", 94.68741902047162],
  ["CO", 72.82650945840892],
  ["AG", 90.88850738533299],
  ["AE", 96.77345167141746],
  ["CZ", 81.09873024099508],
  ["AR", 96.86479657942472],
  ["BI", 73.66351386369526],
  ["CM", 0],
  ["LB", 77.69564654055455],
  ["LU", 87.30629696812646],
  ["TD", 98.07430681523711],
  ["TR", 68.07495465146411],
  ["Scotland", 90.02170251360457],
  ["UA", 81.58007255765742],
  ["LT", 64.96955169733091],
  ["LC", 99.09756413578647],
  ["MG", 98.02604301632547],
  ["CL", 81.34911894273128],
  ["BH", 67.79508940139932],
  ["AD", 94.58603265094584],
  ["CH", 95.51729722726094],
  ["NO", 92.05234516714175],
  ["MT", 82.97356828193833],
  ["MC", 82.09283493132936],
  ["TV", 83.65573982897124],
  ["TW", 94.76224410469032],
  ["US", 65.13410209898937],
  ["MU", 81.09678673231407],
  ["BZ", 82.78310443119979],
  ["CI", 98.60326509458409],
  ["BM", 67.62665198237885],
  ["CK", 87.46015807203939],
  ["AT", 75.6782845296709],
  ["BO", 75.68379113760041],
  ["LS", 87.48509976677896],
  ["NL", 96.48807981342317],
  ["MW", 83.42640580461259],
  ["YE", 87.13753563099247],
  ["UG", 84.68774293858513],
  ["TT", 78.01664939103394],
  ["NZ", 87.8932365897901],
  ["MA", 67.69337911376005],
  ["MV", 80.13345426276237],
  ["LR", 72.7847240217673],
  ["BY", 91.24287380150298],
  ["BN", 73.10961388960871],
  ["AU", 89.75544182430681],
  ["GE", 90.48717284270536],
  ["GR", 91.16902047162478],
  ["IN", 96.18521637729982],
  ["GBENG", 83.70594713656388],
  ["RS", 90.09782327027727],
  ["PL", 82.8206789323659],
  ["SV", 95.77643171806167],
  ["SA", 90.52895827934698],
  ["HK", 75.01684374190205],
  ["KG", 74.61777662606893],
  ["GD", 68.2534335320031],
  ["GQ", 82.56413578647319],
  ["DJ", 95.95750194350869],
  ["KE", 90.44214822492874],
  ["KR", 89.24235553252137],
  ["SC", 79.9663125161959],
  ["ST", 82.8210028504794],
  ["PY", 85.76088364861363],
  ["SB", 86.14926146670122],
  ["IL", 93.66286602746825],
  ["DK", 78.2446877429386],
  ["DO", 90.08097952837522],
  ["RU", 86.12270018139414],
  ["SG", 78.89511531484841],
  ["PK", 95.58693962166365],
  ["GB", 70.15969162995594],
  ["GU", 88.86758227520083],
  ["GW", 79.282845296709],
  ["JP", 82.90263021508163],
  ["SR", 85.48782067893237],
  ["SE", 90.52928219746047],
  ["SD", 83.44778440010366],
  ["SS", 84.2485099766779],
  ["PH", 81.20400362788287],
  ["RW", 87.34808240476808],
  ["KU", 84.31782845296709],
  ["HN", 95.97110650427571],
  ["GA", 86.11525006478362],
  ["DZ", 91.26263280642654],
  ["DM", 62.50323918113501],
  ["FR", 98.6035890126976],
  ["GL", 81.89848406322882],
  ["ES", 60.97207825861622],
  ["HT", 82.61628660274683],
  ["PE", 98.6035890126976],
  ["QA", 95.4638507385333],
  ["SI", 84.71981083182172],
  ["PR", 89.67122311479659],
  ["PS", 95.0029152630215],
  ["IQ", 85.02461777662606],
  ["HU", 74.93748380409433],
  ["KN", 85.4651464109873],
  ["KY", 87.08830007774034],
  ["FI", 96.38604560767038],
  ["ER", 73.80377040684115],
  ["GM", 84.74248509976678],
  ["EE", 99.23425757968386],
  ["EG", 86.62347758486655],
  ["ID", 82.37723503498316],
  ["IS", 86.30377040684115],
  ["SK", 92.61272350349832],
  ["RO", 98.07268722466961],
  ["PG", 80.90081627364603],
  ["KM", 75.1055973050013],
  ["IR", 89.12412542109355],
  ["KZ", 87.58875356309926],
  ["IE", 98.45102358123866],
  ["GN", 98.07268722466961],
  ["FJ", 85.23872764965017],
  ["GY", 86.59238144597046],
  ["JM", 83.06135009069708],
  ["KI", 77.48348017621144],
  ["HR", 89.83609743456854],
  ["SO", 96.25647836227002],
  ["PT", 91.04366416169992],
  ["SY", 85.52409950764446],
  ["SN", 98.7153407618554],
  ["KH", 88.69234257579683],
  ["EC", 68.43968644726614],
  ["ET", 70.8201606633843],
  ["DE", 86.31219227779218],
  ["SL", 96.31186835967867],
  ["PW", 98.01179061933144],
  ["SZ", 83.6055325213786],
  ["PA", 87.72836227001814],
  ["SM", 93.08272868618813],
  ["IT", 65.80979528375227],
  ["JO", 91.84892459186318],
  ["GH", 65.95782586162218],
  ["CG", 63.55370562321845],
  ["BT", 88.37652241513345],
  ["AO", 84.44933920704845],
  ["ML", 98.07333506089661],
  ["TN", 75.66435605079036],
  ["LI", 80.20212490282456],
  ["MM", 76.76988857216895],
  ["MZ", 75.52345167141746],
  ["NA", 80.60054418243068],
  ["CF", 72.51490023322104],
  ["BB", 98.52779217413837],
  ["CD", 87.23827416429127],
  ["BW", 97.69046385073852],
  ["AL", 75.94422130085515],
  ["NC", 85.34529670899197],
  ["MX", 63.84652759782327],
  ["TZ", 92.82845296708993],
  ["VE", 73.13099248509977],
  ["MN", 93.50511790619332],
  ["MY", 75.64556880020731],
  ["AM", 86.44564654055455],
  ["CR", 85.89239440269499],
  ["BA", 82.86214045089402],
  ["AZ", 90.27468256024876],
  ["CV", 88.9644337911376],
  ["BR", 85.20763151075408],
  ["CA", 91.42977455299301],
  ["BE", 98.80215081627365],
  ["TH", 85.95685410728167],
  ["WS", 72.06594972790879],
  ["UZ", 92.63410209898937],
  ["Wales", 76.429774552993],
  ["NG", 65.66273646022286],
  ["NP", 74.31070225447007],
  ["MK", 75.08842964498574],
  ["BD", 63.15463850738533],
  ["BS", 95.60864213526821],
  ["CU", 88.8909043793729],
  ["BF", 95.03206789323659],
  ["NR", 92.13623995853848],
  ["NE", 95.73594195387406],
  ["ZW", 77.29528375226742],
  ["VC", 87.63734128012437],
  ["UY", 90.94681264576316],
  ["TJ", 95.88462036797097],
  ["VU", 77.2862140450894],
  ["ZA", 78.8186706400622],
  ["BG", 75.39971495206011],
]);

export const lbMap = new Map<string, number>([
  ["CY", 58.87125048694975],
  ["AF", 92.52175042202312],
  ["CN", 45.79567588624854],
  ["BJ", 78.42487988572913],
  ["LA", 49.11797169198805],
  ["ME", 90.44539670172705],
  ["OM", 77.7788598883262],
  ["NI", 70.48857291260875],
  ["MR", 93.50019477989872],
  ["TG", 80.00746656278406],
  ["TP", 47.83761849110505],
  ["North Korea", 81.25308401506298],
  ["VN", 46.16510842747695],
  ["ZM", 88.06908193741071],
  ["MD", 90.54700688222309],
  ["CO", 67.72139981820543],
  ["AG", 67.5821321906246],
  ["AE", 68.62745098039215],
  ["CZ", 61.16835475912219],
  ["AR", 69.95000649266329],
  ["BI", 54.52571094663031],
  ["CM", 77.56395273341124],
  ["LB", 0],
  ["LU", 46.399168939098814],
  ["TD", 93.18919620828464],
  ["TR", 41.14043630697312],
  ["Scotland", 79.34196857550967],
  ["UA", 93.71867289962343],
  ["LT", 94.74483833268407],
  ["LC", 97.01434878587196],
  ["MG", 77.95773276197896],
  ["CL", 57.996688741721854],
  ["BH", 45.6927671730944],
  ["AD", 76.40371380340216],
  ["CH", 86.79619529931178],
  ["NO", 46.49331255681081],
  ["MT", 51.393000908972866],
  ["MC", 51.7601610180496],
  ["TV", 80.33015192832099],
  ["TW", 92.99214387741853],
  ["US", 73.48850798597584],
  ["MU", 70.44799376704324],
  ["BZ", 68.31190754447475],
  ["CI", 89.61563433320347],
  ["BM", 46.8409946760161],
  ["CK", 85.23113881314114],
  ["AT", 22.124724061810156],
  ["BO", 70.55966757563952],
  ["LS", 66.12225684975978],
  ["NL", 71.17549668874173],
  ["MW", 91.33034670821971],
  ["YE", 47.333138553434615],
  ["UG", 90.76029087131542],
  ["TT", 62.7048435268147],
  ["NZ", 87.42825607064017],
  ["MA", 93.61349175431762],
  ["MV", 45.902155564212435],
  ["LR", 74.06083625503182],
  ["BY", 89.65816127775614],
  ["BN", 76.37871704973381],
  ["AU", 91.22581482924296],
  ["GE", 68.06778340475262],
  ["GR", 77.3880015582392],
  ["IN", 71.10440202571094],
  ["GBENG", 64.9217634073497],
  ["RS", 83.66023893000909],
  ["PL", 50.92163355408389],
  ["SV", 70.4973380080509],
  ["SA", 89.38384625373328],
  ["HK", 43.565121412803535],
  ["KG", 43.47097779509155],
  ["GD", 60.45351253084015],
  ["GQ", 53.01811453058044],
  ["DJ", 77.97396442020516],
  ["KE", 86.165757693806],
  ["KR", 63.25087650954422],
  ["SC", 66.0904427996364],
  ["ST", 88.86767952213998],
  ["PY", 45.08278145695364],
  ["SB", 86.52934683807297],
  ["IL", 53.82125697961304],
  ["DK", 82.00785612258147],
  ["DO", 58.868978054798085],
  ["RU", 63.21938709258538],
  ["SG", 51.73613816387482],
  ["PK", 83.25379820802493],
  ["GB", 62.97883391767303],
  ["GU", 91.27515907025062],
  ["GW", 75.9313725490196],
  ["JP", 62.143552785352554],
  ["SR", 86.79132580184393],
  ["SE", 93.96669263731982],
  ["SD", 57.652252954161796],
  ["SS", 86.24918841708869],
  ["PH", 58.31450460979094],
  ["RW", 90.8693676145955],
  ["KU", 57.95221399818205],
  ["HN", 71.70237631476432],
  ["GA", 94.46532917802882],
  ["DZ", 75.32528243085314],
  ["DM", 86.99129983119074],
  ["FR", 73.34404622776263],
  ["GL", 52.00298662511362],
  ["ES", 89.22120503830672],
  ["HT", 71.24107258797558],
  ["PE", 57.199389689650694],
  ["QA", 77.40066225165563],
  ["SI", 62.194195559018304],
  ["PR", 73.02850279184521],
  ["PS", 76.47286066744579],
  ["IQ", 48.124594208544345],
  ["HU", 46.61342682768472],
  ["KN", 76.15277236722504],
  ["KY", 86.42416569276718],
  ["FI", 79.59680560966108],
  ["ER", 75.59667575639527],
  ["GM", 64.52798337878197],
  ["EE", 91.83969614335801],
  ["EG", 46.255356447214645],
  ["ID", 51.78126217374367],
  ["IS", 82.42890533696922],
  ["SK", 62.263991689390984],
  ["RO", 77.7142578885859],
  ["PG", 70.25938189845475],
  ["KM", 78.09927282171147],
  ["IR", 65.72068562524348],
  ["KZ", 90.70315543435918],
  ["IE", 89.62439942864563],
  ["GN", 77.53116478379431],
  ["FJ", 82.6629658485911],
  ["GY", 84.09135177249708],
  ["JM", 87.55064277366576],
  ["KI", 57.69283209972731],
  ["HR", 67.26106999091027],
  ["SO", 95.19542916504349],
  ["PT", 91.59751980262304],
  ["SY", 49.1387482145176],
  ["SN", 76.03006103103493],
  ["KH", 92.46234255291522],
  ["EC", 64.78152188027528],
  ["ET", 67.89507856122582],
  ["DE", 94.31729645500585],
  ["SL", 71.25665497987275],
  ["PW", 92.54317621088171],
  ["SZ", 94.33580054538372],
  ["PA", 63.57258797558759],
  ["SM", 75.80963511232306],
  ["IT", 73.98195039605247],
  ["JO", 76.5903778730035],
  ["GH", 70.68497597714583],
  ["CG", 75.97292559407869],
  ["BT", 88.8585897935333],
  ["AO", 70.32398389819504],
  ["ML", 77.66134268276848],
  ["TN", 42.405856382288015],
  ["LI", 71.19724711076483],
  ["MM", 66.78320997273082],
  ["MZ", 86.2021166082327],
  ["NA", 75.38761199844176],
  ["CF", 65.67166601740034],
  ["BB", 93.5323334631866],
  ["CD", 82.86229061160888],
  ["BW", 91.62219192312686],
  ["AL", 49.5175951175172],
  ["NC", 90.08472925594079],
  ["MX", 71.73678743020388],
  ["TZ", 92.97591221919231],
  ["VE", 70.66484872094533],
  ["MN", 90.89566290092196],
  ["MY", 61.008959875340864],
  ["AM", 70.86969224776003],
  ["CR", 77.97039345539541],
  ["BA", 90.19770159719516],
  ["AZ", 94.60329827295156],
  ["CV", 80.18082067263992],
  ["BR", 92.42273730684327],
  ["CA", 82.055252564602],
  ["BE", 77.40942734709778],
  ["TH", 51.02454226723802],
  ["WS", 58.05966757563953],
  ["UZ", 69.91397221140112],
  ["Wales", 75.07953512530841],
  ["NG", 89.76951045318789],
  ["NP", 53.39436436826386],
  ["MK", 82.92299701337488],
  ["BD", 90.60771328398909],
  ["BS", 93.52064666926373],
  ["CU", 72.77853525516167],
  ["BF", 69.73022984028047],
  ["NR", 95.61420594727957],
  ["NE", 69.01441371250488],
  ["ZW", 82.18575509674069],
  ["VC", 89.66497857421115],
  ["UY", 69.30203869627321],
  ["TJ", 62.852876249837685],
  ["VU", 70.96026490066225],
  ["ZA", 70.52363329437735],
  ["BG", 61.05960264900663],
]);

export const luMap = new Map<string, number>([
  ["CY", 71.52032871972318],
  ["AF", 93.60113898500578],
  ["CN", 96.65549307958477],
  ["BJ", 97.58181949250289],
  ["LA", 66.97988754325259],
  ["ME", 91.51636389850057],
  ["OM", 92.39078719723184],
  ["NI", 67.07107843137254],
  ["MR", 96.68973471741637],
  ["TG", 86.23702422145328],
  ["TP", 67.5544261822376],
  ["North Korea", 81.72649942329872],
  ["VN", 65.28294405997693],
  ["ZM", 95.06235582468281],
  ["MD", 96.1501585928489],
  ["CO", 97.30392156862744],
  ["AG", 90.03928777393311],
  ["AE", 66.68396770472896],
  ["CZ", 87.47260668973472],
  ["AR", 68.68944636678201],
  ["BI", 69.31156286043829],
  ["CM", 87.03972029988466],
  ["LB", 46.283520761245676],
  ["LU", 0],
  ["TD", 98.53409746251441],
  ["TR", 93.34090253748558],
  ["Scotland", 81.5059111880046],
  ["UA", 99.50980392156863],
  ["LT", 99.75490196078431],
  ["LC", 96.82309688581316],
  ["MG", 66.49401672433679],
  ["CL", 87.53496251441753],
  ["BH", 66.57908016147636],
  ["AD", 85.9313725490196],
  ["CH", 83.99113321799308],
  ["NO", 59.97440888119954],
  ["MT", 66.39994232987313],
  ["MC", 50.98039215686274],
  ["TV", 83.2237600922722],
  ["TW", 98.2450259515571],
  ["US", 79.24452133794694],
  ["MU", 73.52941176470588],
  ["BZ", 75.20364763552479],
  ["CI", 88.28467416378317],
  ["BM", 71.1461937716263],
  ["CK", 87.83088235294117],
  ["AT", 33.088235294117645],
  ["BO", 64.70588235294117],
  ["LS", 69.6078431372549],
  ["NL", 66.17647058823529],
  ["MW", 94.33138696655132],
  ["YE", 34.31372549019608],
  ["UG", 93.23132929642445],
  ["TT", 72.72419261822375],
  ["NZ", 89.39734717416378],
  ["MA", 99.47556228373703],
  ["MV", 69.42401960784314],
  ["LR", 84.23514994232987],
  ["BY", 92.71229815455594],
  ["BN", 82.1482122260669],
  ["AU", 93.06516724336792],
  ["GE", 84.42474048442907],
  ["GR", 78.85200403690888],
  ["IN", 68.44182525951558],
  ["GBENG", 84.64568915801614],
  ["RS", 89.47484140715109],
  ["PL", 83.08823529411765],
  ["SV", 67.72563437139561],
  ["SA", 91.84292099192618],
  ["HK", 91.54051326412919],
  ["KG", 60.24726066897347],
  ["GD", 75.55723760092272],
  ["GQ", 71.73118512110726],
  ["DJ", 83.12968569780853],
  ["KE", 93.7157583621684],
  ["KR", 76.48140138408304],
  ["SC", 70.2350057670127],
  ["ST", 89.29390138408304],
  ["PY", 36.059328143021915],
  ["SB", 89.07980103806227],
  ["IL", 69.54909169550173],
  ["DK", 83.08391003460207],
  ["DO", 64.34544405997693],
  ["RU", 99.50980392156863],
  ["SG", 51.36281718569781],
  ["PK", 87.4214244521338],
  ["GB", 74.93476066897348],
  ["GU", 95.65527681660899],
  ["GW", 87.419261822376],
  ["JP", 77.71157727797001],
  ["SR", 97.77933967704729],
  ["SE", 98.5553633217993],
  ["SD", 45.325836216839676],
  ["SS", 93.36757497116494],
  ["PH", 86.53294405997694],
  ["RW", 95.18490484429066],
  ["KU", 72.93973471741639],
  ["HN", 67.07215974625143],
  ["GA", 96.76867070357554],
  ["DZ", 81.82489907727796],
  ["DM", 89.8446510957324],
  ["FR", 77.38393886966551],
  ["GL", 78.28539504036908],
  ["ES", 94.08700980392157],
  ["HT", 97.58614475201846],
  ["PE", 66.70847750865052],
  ["QA", 87.75014417531719],
  ["SI", 97.61317762399078],
  ["PR", 84.12125144175317],
  ["PS", 70.34061418685121],
  ["IQ", 37.259948096885815],
  ["HU", 34.068627450980394],
  ["KN", 92.11252883506343],
  ["KY", 86.8991493656286],
  ["FI", 94.86051038062284],
  ["ER", 85.897130911188],
  ["GM", 56.61764705882353],
  ["EE", 99.75490196078431],
  ["EG", 35.92776816608996],
  ["ID", 50.98039215686274],
  ["IS", 85.9223615916955],
  ["SK", 94.5674740484429],
  ["RO", 87.9646049596309],
  ["PG", 71.28784602076125],
  ["KM", 83.53517877739331],
  ["IR", 58.216911764705884],
  ["KZ", 62.433318915801614],
  ["IE", 88.3437860438293],
  ["GN", 87.65498846597463],
  ["FJ", 85.3236735870819],
  ["GY", 86.88797577854672],
  ["JM", 91.93699538638985],
  ["KI", 58.22303921568628],
  ["HR", 65.31430219146482],
  ["SO", 94.83239619377163],
  ["PT", 92.68995098039215],
  ["SY", 36.12384659746252],
  ["SN", 85.87658592848905],
  ["KH", 92.3669982698962],
  ["EC", 91.69514129181084],
  ["ET", 89.62586505190312],
  ["DE", 100],
  ["SL", 66.17647058823529],
  ["PW", 96.300461361015],
  ["SZ", 88.49408881199538],
  ["PA", 65.32799884659745],
  ["SM", 83.95581026528258],
  ["IT", 77.85719434832757],
  ["JO", 72.39835640138408],
  ["GH", 63.64150807381777],
  ["CG", 91.58052191464822],
  ["BT", 85.1996828143022],
  ["AO", 65.5010092272203],
  ["ML", 87.6924740484429],
  ["TN", 91.36534025374856],
  ["LI", 97.67733564013841],
  ["MM", 90.75151384083044],
  ["MZ", 89.94737600922721],
  ["NA", 81.6079152249135],
  ["CF", 78.20429642445214],
  ["BB", 97.28770184544406],
  ["CD", 88.52364475201846],
  ["BW", 91.17647058823529],
  ["AL", 68.44362745098039],
  ["NC", 94.27732122260669],
  ["MX", 81.5635813148789],
  ["TZ", 74.85870818915802],
  ["VE", 98.3819925028835],
  ["MN", 94.86123125720877],
  ["MY", 76.49617935409458],
  ["AM", 99.50980392156863],
  ["CR", 98.15347462514417],
  ["BA", 94.5696366782007],
  ["AZ", 97.68418396770473],
  ["CV", 90.76773356401384],
  ["BR", 92.96316320645906],
  ["CA", 90.76557093425606],
  ["BE", 87.59010957324107],
  ["TH", 83.57843137254902],
  ["WS", 81.14222895040369],
  ["UZ", 62.948024798154556],
  ["Wales", 80.10092272202999],
  ["NG", 88.39100346020761],
  ["NP", 72.86836793540947],
  ["MK", 84.48565455594003],
  ["BD", 92.54433391003461],
  ["BS", 67.42250576701268],
  ["CU", 83.01722895040369],
  ["BF", 65.48803344867359],
  ["NR", 98.84119088811995],
  ["NE", 71.59025374855824],
  ["ZW", 90.53813437139561],
  ["VC", 95.14057093425605],
  ["UY", 83.81523933102653],
  ["TJ", 66.59566032295271],
  ["VU", 68.82388985005767],
  ["ZA", 68.9395905420992],
  ["BG", 99.75490196078431],
]);

export const tdMap = new Map<string, number>([
  ["CY", 92.80872613946241],
  ["AF", 94.08096351123231],
  ["CN", 96.39689650694714],
  ["BJ", 85.25159070250616],
  ["LA", 64.00337618491105],
  ["ME", 90.23633294377353],
  ["OM", 84.80554473445007],
  ["NI", 93.17393844955201],
  ["MR", 93.58881963381378],
  ["TG", 84.52765874561746],
  ["TP", 60.69017010777821],
  ["North Korea", 94.10401246591353],
  ["VN", 64.15920010388261],
  ["ZM", 95.49636410855733],
  ["MD", 37.90254512400987],
  ["CO", 74.17932736008311],
  ["AG", 66.22971042721724],
  ["AE", 97.50097389949357],
  ["CZ", 59.836384885079866],
  ["AR", 94.22152967147125],
  ["BI", 84.79580573951435],
  ["CM", 98.08433969614336],
  ["LB", 93.18919620828464],
  ["LU", 98.55862874951306],
  ["TD", 0],
  ["TR", 63.10803791715362],
  ["Scotland", 90.94500714192962],
  ["UA", 82.75646019997403],
  ["LT", 76.84359174133229],
  ["LC", 87.73535904427996],
  ["MG", 99.33515127905467],
  ["CL", 81.10148032723023],
  ["BH", 63.70796000519413],
  ["AD", 37.378911829632514],
  ["CH", 96.12290611608883],
  ["NO", 89.53804700688222],
  ["MT", 65.52136086222569],
  ["MC", 81.95559018309311],
  ["TV", 82.13251525775873],
  ["TW", 95.63628100246721],
  ["US", 65.19964939618231],
  ["MU", 82.64348785871964],
  ["BZ", 79.05499285807038],
  ["CI", 96.75918711855603],
  ["BM", 63.845279833787814],
  ["CK", 69.31210232437346],
  ["AT", 97.68504090377873],
  ["BO", 77.4071549149461],
  ["LS", 96.93416439423451],
  ["NL", 86.66147253603428],
  ["MW", 86.24139722114012],
  ["YE", 87.65614855213609],
  ["UG", 81.83450201272562],
  ["TT", 69.45526554992858],
  ["NZ", 70.5944033242436],
  ["MA", 66.17614595507078],
  ["MV", 72.36203090507726],
  ["LR", 63.8530710297364],
  ["BY", 94.94805869367615],
  ["BN", 76.62511362160758],
  ["AU", 87.0916114790287],
  ["GE", 91.97019867549669],
  ["GR", 92.55421373847553],
  ["IN", 94.52278924814959],
  ["GBENG", 90.5418127515907],
  ["RS", 84.36631606284898],
  ["PL", 82.53733281392027],
  ["SV", 93.62907414621478],
  ["SA", 91.45987534086483],
  ["HK", 91.1293987793793],
  ["KG", 89.4624074795481],
  ["GD", 60.96740683028178],
  ["GQ", 81.95851188157383],
  ["DJ", 96.60044150110375],
  ["KE", 94.76788728736528],
  ["KR", 95.9057265290222],
  ["SC", 76.59914296844565],
  ["ST", 84.34943513829373],
  ["PY", 85.17530190884301],
  ["SB", 87.69348136605636],
  ["IL", 94.14134527983379],
  ["DK", 69.55070770029866],
  ["DO", 68.6563433320348],
  ["RU", 87.50032463316452],
  ["SG", 98.05609661082975],
  ["PK", 95.98493702116609],
  ["GB", 67.32080249318271],
  ["GU", 61.960459680560966],
  ["GW", 79.83086612128295],
  ["JP", 96.80820672639918],
  ["SR", 80.89403973509933],
  ["SE", 84.22542526944554],
  ["SD", 83.17231528372938],
  ["SS", 81.64589014413713],
  ["PH", 79.75977145825217],
  ["RW", 86.7371120633684],
  ["KU", 83.42390598623555],
  ["HN", 95.02759381898454],
  ["GA", 88.15640825866771],
  ["DZ", 94.91624464355279],
  ["DM", 93.8667056226464],
  ["FR", 96.17062719127387],
  ["GL", 79.17121153097001],
  ["ES", 66.2034151408908],
  ["HT", 79.84060511621868],
  ["PE", 63.3813790416829],
  ["QA", 97.77593818984548],
  ["SI", 96.18426178418387],
  ["PR", 90.25710946630308],
  ["PS", 94.95974548759901],
  ["IQ", 83.86735488897546],
  ["HU", 86.8773535904428],
  ["KN", 73.03889105310999],
  ["KY", 68.66413452798338],
  ["FI", 86.23912478898845],
  ["ER", 87.22406181015452],
  ["GM", 85.65673289183223],
  ["EE", 98.4583171016751],
  ["EG", 82.82495779768861],
  ["ID", 82.02863264511102],
  ["IS", 91.23003506038177],
  ["SK", 94.34683807297753],
  ["RO", 0],
  ["PG", 71.14238410596026],
  ["KM", 81.18620958317102],
  ["IR", 89.62180236332944],
  ["KZ", 92.53733281392027],
  ["IE", 97.20523308661213],
  ["GN", 65.87650954421504],
  ["FJ", 84.15952473704714],
  ["GY", 78.672574990261],
  ["JM", 84.99285807038048],
  ["KI", 67.54577327619789],
  ["HR", 93.58654720166211],
  ["SO", 96.76373198285937],
  ["PT", 92.73535904427996],
  ["SY", 85.91351772497077],
  ["SN", 64.83930658356057],
  ["KH", 77.15523957927542],
  ["EC", 82.37631476431633],
  ["ET", 85.18893650175302],
  ["DE", 87.001363459291],
  ["SL", 97.87949616932866],
  ["PW", 82.87397740553175],
  ["SZ", 83.85696662771068],
  ["PA", 79.20951824438384],
  ["SM", 90.33631995844695],
  ["IT", 64.26048565121413],
  ["JO", 93.34664329307883],
  ["GH", 79.70490845344762],
  ["CG", 71.56083625503182],
  ["BT", 88.389494870796],
  ["AO", 77.53830671341385],
  ["ML", 33.38235294117647],
  ["TN", 92.13056745877158],
  ["LI", 68.52064666926373],
  ["MM", 85.56226464095572],
  ["MZ", 81.58291131021946],
  ["NA", 66.83482664589015],
  ["CF", 92.72139981820543],
  ["BB", 63.16777041942605],
  ["CD", 87.92299701337488],
  ["BW", 97.91520581742631],
  ["AL", 65.32723022984028],
  ["NC", 89.13809894818854],
  ["MX", 57.92884041033632],
  ["TZ", 91.50824568237891],
  ["VE", 64.90098688482016],
  ["MN", 62.25555122711336],
  ["MY", 73.99266329048176],
  ["AM", 98.27100376574471],
  ["CR", 73.36449811712764],
  ["BA", 78.10446695234386],
  ["AZ", 86.28749513050253],
  ["CV", 90.81125827814569],
  ["BR", 90.12855473315153],
  ["CA", 93.81379041682898],
  ["BE", 98.221010258408],
  ["TH", 97.10459680560966],
  ["WS", 50.67556161537463],
  ["UZ", 96.29463706012206],
  ["Wales", 88.03402155564213],
  ["NG", 96.17062719127387],
  ["NP", 86.92507466562785],
  ["MK", 79.07512011427087],
  ["BD", 98.15575899233866],
  ["BS", 95.6752369822101],
  ["CU", 91.46182313985197],
  ["BF", 97.01337488637839],
  ["NR", 69.6185560316842],
  ["NE", 96.75009738994935],
  ["ZW", 84.76464095572004],
  ["VC", 71.63972211401118],
  ["UY", 92.15718737826256],
  ["TJ", 90.07206856252435],
  ["VU", 73.68523568367745],
  ["ZA", 91.30307752239969],
  ["BG", 98.31450460979093],
]);

export const trMap = new Map<string, number>([
  ["CY", 90.47461368653421],
  ["AF", 89.98279444228022],
  ["CN", 8.661537462667185],
  ["BJ", 67.56265420075314],
  ["LA", 44.75587586027789],
  ["ME", 27.51590702506168],
  ["OM", 53.281392027009474],
  ["NI", 94.12608752110116],
  ["MR", 93.85079859758473],
  ["TG", 79.01830931047917],
  ["TP", 26.89813011297234],
  ["North Korea", 46.76957537982081],
  ["VN", 10.869367614595507],
  ["ZM", 84.99155953772237],
  ["MD", 59.62926892611349],
  ["CO", 70.53726788728737],
  ["AG", 49.186469289702636],
  ["AE", 73.75113621607584],
  ["CZ", 59.5188936501753],
  ["AR", 95.62686664069602],
  ["BI", 62.35651214128035],
  ["CM", 68.1456953642384],
  ["LB", 41.14043630697312],
  ["LU", 93.56317361381639],
  ["TD", 63.10803791715362],
  ["TR", 0],
  ["Scotland", 88.17848331385534],
  ["UA", 95.80444098169069],
  ["LT", 95.81775094143617],
  ["LC", 98.80664848720946],
  ["MG", 93.27002986625114],
  ["CL", 94.30723282690559],
  ["BH", 30.642449032593174],
  ["AD", 93.70276587456175],
  ["CH", 20.184067004285158],
  ["NO", 91.51603687832748],
  ["MT", 45.75087650954421],
  ["MC", 50.00746656278405],
  ["TV", 77.36268017140631],
  ["TW", 24.412738605375925],
  ["US", 86.03330736268016],
  ["MU", 94.73477470458383],
  ["BZ", 72.47597714582523],
  ["CI", 97.24321516686145],
  ["BM", 25.664848720945333],
  ["CK", 81.0365536943254],
  ["AT", 93.72743799506557],
  ["BO", 94.90812881443969],
  ["LS", 94.26243345020127],
  ["NL", 94.32086741981561],
  ["MW", 63.57193870925853],
  ["YE", 63.7991819244254],
  ["UG", 67.57758732632125],
  ["TT", 33.405077262693155],
  ["NZ", 81.3624853915076],
  ["MA", 93.53850149331255],
  ["MV", 28.02330866121283],
  ["LR", 90.75704453967018],
  ["BY", 89.27574340994676],
  ["BN", 90.44572133489157],
  ["AU", 80.98136605635632],
  ["GE", 64.21438774185171],
  ["GR", 94.33320348006752],
  ["IN", 94.14848720945331],
  ["GBENG", 70.53921568627452],
  ["RS", 90.23470977795091],
  ["PL", 95.0201272562005],
  ["SV", 94.07382158161278],
  ["SA", 90.00389559797429],
  ["HK", 12.665562913907285],
  ["KG", 13.014543565770678],
  ["GD", 52.553564472146476],
  ["GQ", 63.630372678872874],
  ["DJ", 94.1118036618621],
  ["KE", 91.01967276977015],
  ["KR", 87.13998182054279],
  ["SC", 63.65277236722504],
  ["ST", 82.33735878457344],
  ["PY", 62.05687573042462],
  ["SB", 86.41182963251526],
  ["IL", 91.0404492922997],
  ["DK", 24.309505259057264],
  ["DO", 63.297623685235685],
  ["RU", 94.8289183222958],
  ["SG", 91.73256719906504],
  ["PK", 92.95026619919491],
  ["GB", 57.85417478249578],
  ["GU", 89.44747435398],
  ["GW", 68.81443968315804],
  ["JP", 91.92410076613426],
  ["SR", 94.42961952993117],
  ["SE", 94.777626282301],
  ["SD", 67.2493831969874],
  ["SS", 75.86579664978575],
  ["PH", 57.04940916764056],
  ["RW", 91.1858849500065],
  ["KU", 68.6332943773536],
  ["HN", 93.99104012465914],
  ["GA", 95.13764446175821],
  ["DZ", 90.73886508245683],
  ["DM", 87.64446175821323],
  ["FR", 95.80995974548759],
  ["GL", 50.51811453058045],
  ["ES", 42.92104921438774],
  ["HT", 50.96740683028178],
  ["PE", 35.008440462277626],
  ["QA", 91.47156213478769],
  ["SI", 62.30522010128555],
  ["PR", 49.26600441501104],
  ["PS", 81.52967147123749],
  ["IQ", 62.738605375925204],
  ["HU", 63.501168679392286],
  ["KN", 66.98221010258408],
  ["KY", 80.65868069081937],
  ["FI", 96.10537592520451],
  ["ER", 79.41858200233736],
  ["GM", 64.19523438514479],
  ["EE", 97.83794312426957],
  ["EG", 64.15887547071809],
  ["ID", 50.349305285027924],
  ["IS", 80.95572003635891],
  ["SK", 59.73769640306453],
  ["RO", 62.82268536553695],
  ["PG", 56.665368134008574],
  ["KM", 77.16952343851447],
  ["IR", 61.667640566160244],
  ["KZ", 86.48714452668484],
  ["IE", 96.14400727178288],
  ["GN", 68.03986495260355],
  ["FJ", 82.94572133489157],
  ["GY", 76.0660953122971],
  ["JM", 91.77282171146605],
  ["KI", 46.08817036748474],
  ["HR", 61.00051941306324],
  ["SO", 94.61693286586159],
  ["PT", 39.28353460589534],
  ["SY", 62.61784183872224],
  ["SN", 62.277626282301],
  ["KH", 61.27418517075704],
  ["EC", 66.53681340085703],
  ["ET", 59.13387871704974],
  ["DE", 69.55427866510843],
  ["SL", 94.663030775224],
  ["PW", 96.78905336969224],
  ["SZ", 86.2573042462018],
  ["PA", 69.68445656408258],
  ["SM", 91.95266848461239],
  ["IT", 95.7034800675237],
  ["JO", 75.57654850019479],
  ["GH", 64.47798987144526],
  ["CG", 61.31281651733541],
  ["BT", 93.79171536164135],
  ["AO", 54.29424750032463],
  ["ML", 62.72139981820543],
  ["TN", 13.631346578366447],
  ["LI", 49.01733541098559],
  ["MM", 93.13498246980912],
  ["MZ", 81.37514608492403],
  ["NA", 67.70581742630827],
  ["CF", 79.42475003246332],
  ["BB", 96.02389300090898],
  ["CD", 76.65173354109855],
  ["BW", 99.05142189326061],
  ["AL", 15.940786910790806],
  ["NC", 91.33326840670043],
  ["MX", 61.417348396312164],
  ["TZ", 93.7209453317751],
  ["VE", 61.15049993507336],
  ["MN", 40.08635242176341],
  ["MY", 60.00584339696143],
  ["AM", 64.87371769900014],
  ["CR", 68.7047136735489],
  ["BA", 88.76769250746656],
  ["AZ", 69.44130632385405],
  ["CV", 85.52233476171925],
  ["BR", 87.28833917673029],
  ["CA", 36.783859239059865],
  ["BE", 96.96143358005453],
  ["TH", 62.571419296195295],
  ["WS", 24.926308271653035],
  ["UZ", 89.33352811323205],
  ["Wales", 74.58933904687703],
  ["NG", 96.2978833917673],
  ["NP", 81.61147902869757],
  ["MK", 34.25983638488508],
  ["BD", 92.01272562004934],
  ["BS", 95.25353850149332],
  ["CU", 77.01856901701078],
  ["BF", 94.27509414361771],
  ["NR", 93.29664978574212],
  ["NE", 93.91085573302168],
  ["ZW", 76.21607583430723],
  ["VC", 93.91832229580574],
  ["UY", 91.54298143098299],
  ["TJ", 66.22386703025582],
  ["VU", 64.96558888456045],
  ["ZA", 92.08024931827036],
  ["BG", 63.94883781327101],
]);

export const scotlandMap = new Map<string, number>([
  ["CY", 65.46027970011534],
  ["AF", 90.59796712802768],
  ["CN", 90.06632064590542],
  ["BJ", 91.06545559400232],
  ["LA", 85.20112456747405],
  ["ME", 88.87038638985005],
  ["OM", 83.0251585928489],
  ["NI", 49.91926182237601],
  ["MR", 90.86829584775087],
  ["TG", 88.89273356401384],
  ["TP", 89.92647058823529],
  ["North Korea", 68.54599192618224],
  ["VN", 90.12975778546712],
  ["ZM", 92.37456747404845],
  ["MD", 73.80334486735872],
  ["CO", 90.98183391003461],
  ["AG", 86.78020472895041],
  ["AE", 83.34090253748559],
  ["CZ", 79.64460784313725],
  ["AR", 88.76153402537486],
  ["BI", 62.4052047289504],
  ["CM", 90.4325259515571],
  ["LB", 79.6100057670127],
  ["LU", 81.5059111880046],
  ["TD", 91.3880478662053],
  ["TR", 88.59933679354096],
  ["Scotland", 0],
  ["UA", 65.72736447520184],
  ["LT", 94.3054354094579],
  ["LC", 96.45941464821223],
  ["MG", 80.7450259515571],
  ["CL", 80.0555074971165],
  ["BH", 80.50353229527106],
  ["AD", 90.1895905420992],
  ["CH", 84.09277681660899],
  ["NO", 84.76643598615917],
  ["MT", 76.24603517877739],
  ["MC", 75.60733852364476],
  ["TV", 80.65599769319492],
  ["TW", 88.85344579008074],
  ["US", 72.99884659746252],
  ["MU", 91.09248846597463],
  ["BZ", 78.31891580161476],
  ["CI", 83.81163494809688],
  ["BM", 82.87269319492503],
  ["CK", 82.68923010380624],
  ["AT", 81.53006055363322],
  ["BO", 91.45400807381776],
  ["LS", 82.3468137254902],
  ["NL", 81.68360726643598],
  ["MW", 91.63530853517877],
  ["YE", 84.23334775086505],
  ["UG", 88.83398212226066],
  ["TT", 87.15037485582468],
  ["NZ", 84.50259515570934],
  ["MA", 92.45566608996539],
  ["MV", 90.90289792387544],
  ["LR", 76.91969434832757],
  ["BY", 86.76038062283737],
  ["BN", 80.41991061130335],
  ["AU", 88.49733275663206],
  ["GE", 67.88170415224913],
  ["GR", 45.927407727797004],
  ["IN", 86.14547289504037],
  ["GBENG", 64.66803633217994],
  ["RS", 76.45977508650519],
  ["PL", 75.7529555940023],
  ["SV", 51.76218281430219],
  ["SA", 86.41219723183391],
  ["HK", 85.91407151095733],
  ["KG", 85.30024509803921],
  ["GD", 82.47476931949251],
  ["GQ", 72.24336793540945],
  ["DJ", 81.3880478662053],
  ["KE", 88.37910899653978],
  ["KR", 73.00245098039215],
  ["SC", 83.87975778546712],
  ["ST", 89.12449538638984],
  ["PY", 83.67178489042676],
  ["SB", 61.11014994232987],
  ["IL", 73.40397923875432],
  ["DK", 85.65852076124567],
  ["DO", 86.9928633217993],
  ["RU", 80.72556228373703],
  ["SG", 73.09147923875432],
  ["PK", 79.98017589388697],
  ["GB", 68.83398212226068],
  ["GU", 89.4950259515571],
  ["GW", 89.51376874279123],
  ["JP", 68.75252306805075],
  ["SR", 83.99113321799308],
  ["SE", 90.85027393310266],
  ["SD", 83.07778258362168],
  ["SS", 79.91457612456747],
  ["PH", 87.33996539792388],
  ["RW", 90.01441753171858],
  ["KU", 83.01831026528258],
  ["HN", 50.100922722029985],
  ["GA", 91.05932814302192],
  ["DZ", 75.38963379469435],
  ["DM", 89.1079152249135],
  ["FR", 81.68432814302192],
  ["GL", 74.45357554786621],
  ["ES", 89.022130911188],
  ["HT", 89.92791234140715],
  ["PE", 81.75173010380622],
  ["QA", 80.3658448673587],
  ["SI", 64.99423298731257],
  ["PR", 79.78950403690888],
  ["PS", 83.08535178777393],
  ["IQ", 85.91299019607843],
  ["HU", 81.8256199538639],
  ["KN", 88.58780276816609],
  ["KY", 82.90441176470588],
  ["FI", 70.31718569780854],
  ["ER", 81.81588811995385],
  ["GM", 88.11418685121107],
  ["EE", 82.84421856978086],
  ["EG", 85.72808535178777],
  ["ID", 75.60733852364476],
  ["IS", 86.78380911188005],
  ["SK", 63.81487889273356],
  ["RO", 91.40102364475202],
  ["PG", 91.61620530565168],
  ["KM", 82.7137399077278],
  ["IR", 77.62831603229527],
  ["KZ", 86.63675028835064],
  ["IE", 83.78243944636678],
  ["GN", 91.28279988465975],
  ["FJ", 87.7025663206459],
  ["GY", 83.61303344867359],
  ["JM", 86.86670991926182],
  ["KI", 77.89972606689734],
  ["HR", 80.83261245674741],
  ["SO", 88.13581314878893],
  ["PT", 87.48017589388697],
  ["SY", 83.07165513264128],
  ["SN", 90.0937139561707],
  ["KH", 86.57295271049597],
  ["EC", 75.45307093425605],
  ["ET", 84.20126874279123],
  ["DE", 93.72765282583622],
  ["SL", 65.28582756632065],
  ["PW", 95.47361591695501],
  ["SZ", 81.64864475201846],
  ["PA", 77.7198673587082],
  ["SM", 79.24740484429066],
  ["IT", 81.64431949250289],
  ["JO", 86.40643021914649],
  ["GH", 90.82324106113033],
  ["CG", 86.90419550173011],
  ["BT", 85.75583910034602],
  ["AO", 93.0150663206459],
  ["ML", 91.17070357554786],
  ["TN", 85.8095444059977],
  ["LI", 90.99877450980392],
  ["MM", 84.2852508650519],
  ["MZ", 87.33888408304499],
  ["NA", 85.34746251441753],
  ["CF", 84.14828431372548],
  ["BB", 89.94232987312573],
  ["CD", 87.47729238754324],
  ["BW", 95.26275951557093],
  ["AL", 90.82540369088812],
  ["NC", 87.86332179930795],
  ["MX", 84.66299019607844],
  ["TZ", 91.3498414071511],
  ["VE", 90.23248269896193],
  ["MN", 89.22145328719722],
  ["MY", 74.63091118800462],
  ["AM", 94.31841118800462],
  ["CR", 78.61988177623991],
  ["BA", 86.08996539792388],
  ["AZ", 90.34025374855824],
  ["CV", 85.33412629757785],
  ["BR", 86.4878892733564],
  ["CA", 81.68685121107266],
  ["BE", 94.03006055363322],
  ["TH", 80.32835928489042],
  ["WS", 90.93966262975779],
  ["UZ", 80.07821510957324],
  ["Wales", 74.52530276816609],
  ["NG", 81.85157151095733],
  ["NP", 68.21583044982698],
  ["MK", 77.9112600922722],
  ["BD", 89.7621107266436],
  ["BS", 92.66291810841983],
  ["CU", 78.12427912341407],
  ["BF", 90.82215974625144],
  ["NR", 90.30493079584775],
  ["NE", 86.75028835063438],
  ["ZW", 88.80226355247981],
  ["VC", 76.08095444059977],
  ["UY", 72.6582324106113],
  ["TJ", 78.92625432525952],
  ["VU", 89.90844867358709],
  ["ZA", 91.41796424452133],
  ["BG", 80.84018166089966],
]);

export const uaMap = new Map<string, number>([
  ["CY", 93.88098948188546],
  ["AF", 91.56505648617063],
  ["CN", 96.18004155304506],
  ["BJ", 99.26308271653032],
  ["LA", 97.43442410076614],
  ["ME", 90.02337358784574],
  ["OM", 95.24834437086092],
  ["NI", 63.65179846773147],
  ["MR", 94.17608102843786],
  ["TG", 76.90786910790807],
  ["TP", 95.61160888196338],
  ["North Korea", 77.01791975068173],
  ["VN", 97.00688222308791],
  ["ZM", 96.33813790416829],
  ["MD", 66.29333852746396],
  ["CO", 99.77924944812362],
  ["AG", 89.12576288793663],
  ["AE", 99.17315932995714],
  ["CZ", 98.73977405531748],
  ["AR", 94.57343202181535],
  ["BI", 88.01649136475783],
  ["CM", 81.56278405401895],
  ["LB", 93.71867289962343],
  ["LU", 99.33774834437085],
  ["TD", 82.75646019997403],
  ["TR", 95.80444098169069],
  ["Scotland", 65.62686664069601],
  ["UA", 0],
  ["LT", 99.33774834437085],
  ["LC", 90.15420075314894],
  ["MG", 99.25269445526556],
  ["CL", 98.52746396571874],
  ["BH", 95.85962861965979],
  ["AD", 81.43487858719647],
  ["CH", 97.19354629268926],
  ["NO", 92.19841579015711],
  ["MT", 98.63491754317621],
  ["MC", 96.68874172185431],
  ["TV", 86.8666406960135],
  ["TW", 95.09089728606675],
  ["US", 75.19510453187898],
  ["MU", 73.73068432671081],
  ["BZ", 60.23113881314115],
  ["CI", 98.15705752499674],
  ["BM", 92.02116608232697],
  ["CK", 90.03928061290742],
  ["AT", 96.68874172185431],
  ["BO", 83.22295805739515],
  ["LS", 96.33651473834567],
  ["NL", 96.90949227373068],
  ["MW", 95.19770159719518],
  ["YE", 99.55849889624724],
  ["UG", 80.37235423970913],
  ["TT", 93.92643812491885],
  ["NZ", 89.84190364887677],
  ["MA", 97.75678483313855],
  ["MV", 97.50162316582262],
  ["LR", 87.14355278535255],
  ["BY", 93.68361251785483],
  ["BN", 69.8516426438125],
  ["AU", 87.87008180755747],
  ["GE", 91.41053109985717],
  ["GR", 61.69263731982859],
  ["IN", 94.53804700688222],
  ["GBENG", 96.11089468900143],
  ["RS", 88.87904168289833],
  ["PL", 99.77924944812362],
  ["SV", 61.315738215816125],
  ["SA", 92.08349564991559],
  ["HK", 93.67095182443839],
  ["KG", 90.47558758602779],
  ["GD", 78.38917023763148],
  ["GQ", 88.61965978444358],
  ["DJ", 96.80496039475392],
  ["KE", 92.49091027139332],
  ["KR", 94.93604726658876],
  ["SC", 89.94383846253733],
  ["ST", 82.15816127775614],
  ["PY", 97.5175301908843],
  ["SB", 61.0135047396442],
  ["IL", 95.3395662900922],
  ["DK", 97.49577976886118],
  ["DO", 96.49039085833009],
  ["RU", 99.55849889624724],
  ["SG", 92.82495779768861],
  ["PK", 95.7304246201792],
  ["GB", 82.18867679522141],
  ["GU", 94.5796000519413],
  ["GW", 98.69302687962602],
  ["JP", 97.36787430203869],
  ["SR", 93.71445266848461],
  ["SE", 83.94169588365148],
  ["SD", 97.25035709648098],
  ["SS", 85.16653681340087],
  ["PH", 92.07213348915725],
  ["RW", 70.54635761589404],
  ["KU", 98.44143617711985],
  ["HN", 63.54629268926113],
  ["GA", 82.90514218932607],
  ["DZ", 94.78541747824958],
  ["DM", 91.62835995325283],
  ["FR", 98.08855992728218],
  ["GL", 95.5538241786781],
  ["ES", 75.58369042981431],
  ["HT", 97.95773276197896],
  ["PE", 98.16062848980653],
  ["QA", 96.50792104921439],
  ["SI", 82.93598233995584],
  ["PR", 90.7950266199195],
  ["PS", 97.26399168939098],
  ["IQ", 96.2089339046877],
  ["HU", 99.11699779249447],
  ["KN", 91.09661082976237],
  ["KY", 89.13907284768212],
  ["FI", 97.41624464355279],
  ["ER", 87.96325152577587],
  ["GM", 95.58498896247241],
  ["EE", 99.55849889624724],
  ["EG", 96.66861446565382],
  ["ID", 96.68874172185431],
  ["IS", 92.86456304376055],
  ["SK", 85.61485521360862],
  ["RO", 82.8288533956629],
  ["PG", 94.57051032333463],
  ["KM", 95.56778340475263],
  ["IR", 90.20581742630827],
  ["KZ", 90.85735618750812],
  ["IE", 98.1616023893001],
  ["GN", 82.6860148032723],
  ["FJ", 87.31560836255032],
  ["GY", 83.64498117127646],
  ["JM", 80.46877028957277],
  ["KI", 85.6265420075315],
  ["HR", 91.73613816387483],
  ["SO", 97.8152188027529],
  ["PT", 92.5201272562005],
  ["SY", 97.61134917543176],
  ["SN", 97.70322036099208],
  ["KH", 93.4596156343332],
  ["EC", 95.37267887287365],
  ["ET", 79.17965199324763],
  ["DE", 65.12141280353201],
  ["SL", 97.35099337748345],
  ["PW", 89.68445656408258],
  ["SZ", 84.55168159979223],
  ["PA", 94.77178288533956],
  ["SM", 92.89053369692247],
  ["IT", 98.1616023893001],
  ["JO", 96.51343981301129],
  ["GH", 84.28613167121154],
  ["CG", 95.34735748604078],
  ["BT", 91.3004804570835],
  ["AO", 94.81755616153747],
  ["ML", 82.61005064277367],
  ["TN", 94.58187248409298],
  ["LI", 97.36819893520322],
  ["MM", 96.77834047526297],
  ["MZ", 66.86858849500065],
  ["NA", 88.65536943254124],
  ["CF", 77.30197376964031],
  ["BB", 98.0392156862745],
  ["CD", 91.33391767302948],
  ["BW", 97.35099337748345],
  ["AL", 95.08180755746008],
  ["NC", 90.65608362550319],
  ["MX", 93.17361381638747],
  ["TZ", 91.26964030645371],
  ["VE", 96.6494611089469],
  ["MN", 92.64511102454227],
  ["MY", 83.15705752499676],
  ["AM", 99.11699779249447],
  ["CR", 95.86319958446956],
  ["BA", 87.47467861316713],
  ["AZ", 96.45403194390339],
  ["CV", 86.54330606414752],
  ["BR", 86.45792754187768],
  ["CA", 93.01162186728996],
  ["BE", 99.25951175172055],
  ["TH", 96.46799116997794],
  ["WS", 96.43163225555122],
  ["UZ", 96.29333852746397],
  ["Wales", 89.58609271523179],
  ["NG", 98.08791066095313],
  ["NP", 88.4070250616803],
  ["MK", 75.61615374626672],
  ["BD", 96.64037138034021],
  ["BS", 97.67692507466563],
  ["CU", 94.00175301908843],
  ["BF", 97.62693156732892],
  ["NR", 94.7750292169848],
  ["NE", 97.31495909622126],
  ["ZW", 82.46883521620568],
  ["VC", 65.148032723023],
  ["UY", 89.79418257369173],
  ["TJ", 94.02967147123749],
  ["VU", 93.02038696273212],
  ["ZA", 90.2197766523828],
  ["BG", 99.33774834437085],
]);

export const ltMap = new Map<string, number>([
  ["CY", 95.76701268742791],
  ["AF", 91.10366205305652],
  ["CN", 63.41407151095733],
  ["BJ", 65.59616493656286],
  ["LA", 72.46647923875432],
  ["ME", 89.94269031141869],
  ["OM", 86.79101787773934],
  ["NI", 99.51448961937717],
  ["MR", 69.35625720876585],
  ["TG", 77.00475778546712],
  ["TP", 68.60258073817762],
  ["North Korea", 95.31898788927336],
  ["VN", 64.21856978085351],
  ["ZM", 93.2097029988466],
  ["MD", 73.53553921568627],
  ["CO", 40.44117647058824],
  ["AG", 65.61058246828144],
  ["AE", 90.97138119953864],
  ["CZ", 69.13278546712803],
  ["AR", 98.9788783160323],
  ["BI", 80.12326989619378],
  ["CM", 64.95025951557093],
  ["LB", 94.72714821222607],
  ["LU", 99.75490196078431],
  ["TD", 76.86526816608996],
  ["TR", 95.49704440599768],
  ["Scotland", 94.3054354094579],
  ["UA", 99.01960784313727],
  ["LT", 0],
  ["LC", 98.6955738177624],
  ["MG", 88.10337370242215],
  ["CL", 65.44946655132642],
  ["BH", 75.72664359861592],
  ["AD", 84.16342272202999],
  ["CH", 96.92185697808536],
  ["NO", 95.17156862745098],
  ["MT", 80.29555940023067],
  ["MC", 99.26470588235294],
  ["TV", 87.67445213379469],
  ["TW", 95.73745674740485],
  ["US", 75.90794405997693],
  ["MU", 98.52941176470588],
  ["BZ", 83.69917820069203],
  ["CI", 99.15801614763552],
  ["BM", 60.107410611303344],
  ["CK", 91.02652825836218],
  ["AT", 99.50980392156863],
  ["BO", 99.50980392156863],
  ["LS", 99.3458044982699],
  ["NL", 99.75490196078431],
  ["MW", 98.48868223760093],
  ["YE", 99.75490196078431],
  ["UG", 94.47123702422145],
  ["TT", 74.02249134948097],
  ["NZ", 90.82864763552479],
  ["MA", 62.28157439446367],
  ["MV", 50.43721164936563],
  ["LR", 81.11807958477509],
  ["BY", 93.24178200692042],
  ["BN", 91.32533160322953],
  ["AU", 90.86469146482122],
  ["GE", 94.33246828143021],
  ["GR", 97.99344002306805],
  ["IN", 97.83484717416378],
  ["GBENG", 95.05622837370242],
  ["RS", 92.71013552479815],
  ["PL", 66.42156862745098],
  ["SV", 98.65844867358709],
  ["SA", 68.80118223760093],
  ["HK", 63.61880046136101],
  ["KG", 90.3316032295271],
  ["GD", 48.23457324106113],
  ["GQ", 96.83787485582468],
  ["DJ", 95.95263840830451],
  ["KE", 93.43389561707036],
  ["KR", 95.38494809688581],
  ["SC", 87.81033737024222],
  ["ST", 90.78287197231833],
  ["PY", 98.18807670126874],
  ["SB", 88.78316032295271],
  ["IL", 96.02580738177625],
  ["DK", 68.97455305651673],
  ["DO", 83.3477508650519],
  ["RU", 65.44117647058823],
  ["SG", 97.79519896193771],
  ["PK", 96.72902249134948],
  ["GB", 85.86469146482122],
  ["GU", 87.28698096885813],
  ["GW", 63.68800461361015],
  ["JP", 97.90333044982698],
  ["SR", 93.87975778546713],
  ["SE", 94.00555074971166],
  ["SD", 89.69903402537486],
  ["SS", 93.76766147635524],
  ["PH", 69.05961649365628],
  ["RW", 95.42856113033449],
  ["KU", 70.61382641291812],
  ["HN", 98.7348615916955],
  ["GA", 99.39878892733563],
  ["DZ", 82.50756920415225],
  ["DM", 84.31660899653978],
  ["FR", 98.90715109573242],
  ["GL", 69.73615916955018],
  ["ES", 63.18303056516724],
  ["HT", 62.58254036908881],
  ["PE", 77.15758362168397],
  ["QA", 96.9597029988466],
  ["SI", 96.87247693194925],
  ["PR", 91.73875432525952],
  ["PS", 94.2650663206459],
  ["IQ", 96.93375144175317],
  ["HU", 99.50980392156863],
  ["KN", 74.09061418685121],
  ["KY", 89.91601787773934],
  ["FI", 98.93778835063436],
  ["ER", 85.18886966551327],
  ["GM", 99.26470588235294],
  ["EE", 99.50980392156863],
  ["EG", 99.75490196078431],
  ["ID", 99.26470588235294],
  ["IS", 94.60423875432527],
  ["SK", 94.20883794694348],
  ["RO", 76.73767301038062],
  ["PG", 87.5785755478662],
  ["KM", 66.34623702422145],
  ["IR", 90.95191753171858],
  ["KZ", 86.57763840830451],
  ["IE", 99.16342272202999],
  ["GN", 76.83679354094579],
  ["FJ", 90.70609861591696],
  ["GY", 88.35568050749711],
  ["JM", 85.04541522491348],
  ["KI", 88.54382929642445],
  ["HR", 95.85459919261822],
  ["SO", 96.96835351787773],
  ["PT", 91.4082324106113],
  ["SY", 98.0767012687428],
  ["SN", 85.5655276816609],
  ["KH", 94.03366493656286],
  ["EC", 91.01355247981544],
  ["ET", 62.265354671280285],
  ["DE", 98.52941176470588],
  ["SL", 99.50980392156863],
  ["PW", 97.05017301038062],
  ["SZ", 87.19939446366783],
  ["PA", 97.54865916955018],
  ["SM", 94.60459919261822],
  ["IT", 88.05867935409458],
  ["JO", 91.13826412918108],
  ["GH", 99.30651672433679],
  ["CG", 77.01989619377163],
  ["BT", 69.38653402537486],
  ["AO", 96.43490484429066],
  ["ML", 76.69009515570934],
  ["TN", 95.17877739331027],
  ["LI", 63.074538638985004],
  ["MM", 63.68836505190312],
  ["MZ", 90.544261822376],
  ["NA", 82.13559688581314],
  ["CF", 91.72253460207612],
  ["BB", 87.66399942329873],
  ["CD", 88.30161476355248],
  ["BW", 99.26470588235294],
  ["AL", 92.58614475201846],
  ["NC", 95.11678200692042],
  ["MX", 74.52097750865052],
  ["TZ", 92.05413783160323],
  ["VE", 28.648356401384085],
  ["MN", 76.14943771626298],
  ["MY", 78.46309111880046],
  ["AM", 98.77450980392157],
  ["CR", 96.68793252595155],
  ["BA", 79.33210784313725],
  ["AZ", 96.07482698961938],
  ["CV", 94.96900230680507],
  ["BR", 89.82482698961938],
  ["CA", 94.94629469434834],
  ["BE", 99.00122549019608],
  ["TH", 98.52941176470588],
  ["WS", 64.30579584775087],
  ["UZ", 96.53907151095731],
  ["Wales", 78.11562860438292],
  ["NG", 77.47981545559401],
  ["NP", 76.24531430219147],
  ["MK", 82.31365340253748],
  ["BD", 76.16926182237601],
  ["BS", 97.63660611303345],
  ["CU", 90.50173010380624],
  ["BF", 96.60503171856978],
  ["NR", 96.23305940023069],
  ["NE", 98.32396193771626],
  ["ZW", 80.9940888119954],
  ["VC", 79.82518742791234],
  ["UY", 94.74949538638985],
  ["TJ", 99.15801614763552],
  ["VU", 95.13083910034602],
  ["ZA", 78.96121683967705],
  ["BG", 65.68627450980392],
]);

export const lcMap = new Map<string, number>([
  ["CY", 92.26773356401384],
  ["AF", 95.50475778546713],
  ["CN", 97.77811418685121],
  ["BJ", 97.09861591695503],
  ["LA", 97.11418685121107],
  ["ME", 93.60596885813149],
  ["OM", 97.58953287197232],
  ["NI", 96.78416955017302],
  ["MR", 95.25475778546712],
  ["TG", 93.15008650519032],
  ["TP", 95.44723183391004],
  ["North Korea", 96.47015570934256],
  ["VN", 97.66046712802769],
  ["ZM", 98.030276816609],
  ["MD", 87.90484429065744],
  ["CO", 96.25994809688582],
  ["AG", 93.87024221453288],
  ["AE", 97.24134948096885],
  ["CZ", 97.844723183391],
  ["AR", 92.96539792387544],
  ["BI", 91.79541522491348],
  ["CM", 99.12153979238755],
  ["LB", 96.83953287197232],
  ["LU", 96.55752595155708],
  ["TD", 87.95891003460208],
  ["TR", 98.76167820069203],
  ["Scotland", 96.30882352941177],
  ["UA", 90.24264705882354],
  ["LT", 98.72188581314879],
  ["LC", 0],
  ["MG", 98.70198961937716],
  ["CL", 97.74524221453287],
  ["BH", 98.98659169550173],
  ["AD", 85.2833044982699],
  ["CH", 96.42863321799308],
  ["NO", 96.58434256055364],
  ["MT", 94.84904844290656],
  ["MC", 96.47707612456747],
  ["TV", 85.72621107266436],
  ["TW", 99.08953287197232],
  ["US", 91.30493079584775],
  ["MU", 95.11894463667821],
  ["BZ", 91.22361591695501],
  ["CI", 94.95804498269896],
  ["BM", 95.80320069204153],
  ["CK", 95.82093425605537],
  ["AT", 97.34083044982698],
  ["BO", 95.97967128027682],
  ["LS", 95.76427335640139],
  ["NL", 97.01816608996539],
  ["MW", 99.28589965397924],
  ["YE", 97.09818339100346],
  ["UG", 91.3961937716263],
  ["TT", 95.08174740484428],
  ["NZ", 96.48010380622839],
  ["MA", 99.07612456747404],
  ["MV", 99.28806228373702],
  ["LR", 92.70501730103805],
  ["BY", 96.09688581314879],
  ["BN", 87.14835640138409],
  ["AU", 96.40570934256056],
  ["GE", 96.06401384083046],
  ["GR", 93.95761245674741],
  ["IN", 96.40138408304499],
  ["GBENG", 96.76384083044984],
  ["RS", 92.00562283737024],
  ["PL", 97.73226643598616],
  ["SV", 97.0674740484429],
  ["SA", 96.91046712802768],
  ["HK", 96.40570934256056],
  ["KG", 92.86634948096886],
  ["GD", 84.9022491349481],
  ["GQ", 97.19939446366782],
  ["DJ", 65.45588235294117],
  ["KE", 96.30449826989619],
  ["KR", 94.99264705882354],
  ["SC", 92.58477508650519],
  ["ST", 91.23572664359861],
  ["PY", 97.05795847750865],
  ["SB", 92.56487889273356],
  ["IL", 94.86029411764706],
  ["DK", 97.10207612456747],
  ["DO", 95.22145328719724],
  ["RU", 97.48096885813149],
  ["SG", 95.57525951557093],
  ["PK", 96.64965397923876],
  ["GB", 95.90311418685121],
  ["GU", 98.42084775086505],
  ["GW", 97.00821799307958],
  ["JP", 96.28416955017302],
  ["SR", 95.4000865051903],
  ["SE", 94.83520761245676],
  ["SD", 97.14186851211073],
  ["SS", 96.7742214532872],
  ["PH", 96.11937716262976],
  ["RW", 88.12586505190312],
  ["KU", 97.4022491349481],
  ["HN", 96.98010380622837],
  ["GA", 95.78373702422145],
  ["DZ", 96.56358131487889],
  ["DM", 96.18901384083046],
  ["FR", 95.02032871972318],
  ["GL", 96.67863321799308],
  ["ES", 90.07223183391004],
  ["HT", 97.94377162629758],
  ["PE", 94.87586505190312],
  ["QA", 97.87889273356402],
  ["SI", 98.37110726643598],
  ["PR", 96.20977508650519],
  ["PS", 96.96280276816609],
  ["IQ", 96.79801038062283],
  ["HU", 97.25562283737024],
  ["KN", 87.48572664359861],
  ["KY", 93.38754325259515],
  ["FI", 95.8083910034602],
  ["ER", 90.60942906574394],
  ["GM", 97.06444636678201],
  ["EE", 94.31141868512111],
  ["EG", 96.81098615916956],
  ["ID", 96.47794117647058],
  ["IS", 96.52724913494811],
  ["SK", 97.58953287197232],
  ["RO", 87.7720588235294],
  ["PG", 94.780276816609],
  ["KM", 96.49913494809688],
  ["IR", 92.04152249134948],
  ["KZ", 85.27768166089965],
  ["IE", 94.7318339100346],
  ["GN", 87.79584775086505],
  ["FJ", 41.53200692041523],
  ["GY", 89.38278546712802],
  ["JM", 88.6613321799308],
  ["KI", 92.18295847750865],
  ["HR", 96.10207612456747],
  ["SO", 96.38235294117648],
  ["PT", 95.30190311418684],
  ["SY", 96.80363321799308],
  ["SN", 92.6794982698962],
  ["KH", 96.27076124567473],
  ["EC", 94.16262975778547],
  ["ET", 96.13884083044982],
  ["DE", 91.75778546712803],
  ["SL", 96.21799307958477],
  ["PW", 88.15397923875432],
  ["SZ", 92.60337370242215],
  ["PA", 95.9303633217993],
  ["SM", 55.67603806228374],
  ["IT", 95.00346020761245],
  ["JO", 97.33996539792388],
  ["GH", 94.62673010380624],
  ["CG", 91.3871107266436],
  ["BT", 90.25735294117648],
  ["AO", 95.3931660899654],
  ["ML", 87.2015570934256],
  ["TN", 97.01167820069205],
  ["LI", 97.28287197231835],
  ["MM", 92.77335640138409],
  ["MZ", 86.68468858131489],
  ["NA", 95.40397923875432],
  ["CF", 95.73961937716263],
  ["BB", 87.090830449827],
  ["CD", 92.58304498269896],
  ["BW", 93.62024221453287],
  ["AL", 97.32482698961937],
  ["NC", 92.44506920415225],
  ["MX", 93.85337370242215],
  ["TZ", 83.83001730103807],
  ["VE", 96.65268166089966],
  ["MN", 95.57050173010381],
  ["MY", 90.8529411764706],
  ["AM", 96.87456747404845],
  ["CR", 95.67171280276816],
  ["BA", 92.17430795847751],
  ["AZ", 98.63797577854672],
  ["CV", 95.14100346020761],
  ["BR", 89.66435986159169],
  ["CA", 96.63365051903115],
  ["BE", 93.01384083044982],
  ["TH", 95.2560553633218],
  ["WS", 99.29152249134948],
  ["UZ", 95.43771626297578],
  ["Wales", 97.5544982698962],
  ["NG", 94.87673010380622],
  ["NP", 96.67776816608996],
  ["MK", 84.46107266435986],
  ["BD", 99.7962802768166],
  ["BS", 92.35510380622839],
  ["CU", 96.34688581314879],
  ["BF", 99.28503460207612],
  ["NR", 97.44031141868513],
  ["NE", 96.91955017301038],
  ["ZW", 88.92647058823529],
  ["VC", 89.68382352941177],
  ["UY", 90.4455017301038],
  ["TJ", 95.2249134948097],
  ["VU", 91.54714532871972],
  ["ZA", 93.7378892733564],
  ["BG", 98.69031141868511],
]);

export const mgMap = new Map<string, number>([
  ["CY", 63.4596156343332],
  ["AF", 89.19880534995454],
  ["CN", 96.61504999350734],
  ["BJ", 96.02746396571874],
  ["LA", 98.2573691728347],
  ["ME", 85.88008050902481],
  ["OM", 93.52616543306064],
  ["NI", 87.9892221789378],
  ["MR", 67.52759381898454],
  ["TG", 76.04726658875471],
  ["TP", 92.52532138683289],
  ["North Korea", 92.89150759641605],
  ["VN", 96.36378392416569],
  ["ZM", 94.91202441241397],
  ["MD", 94.41306323854045],
  ["CO", 98.7641215426568],
  ["AG", 95.9959745487599],
  ["AE", 97.08057395143487],
  ["CZ", 92.71620568757304],
  ["AR", 87.24808466432931],
  ["BI", 83.69984417608103],
  ["CM", 97.93338527463966],
  ["LB", 77.95773276197896],
  ["LU", 66.51084274769511],
  ["TD", 99.33515127905467],
  ["TR", 93.27002986625114],
  ["Scotland", 80.42559407869108],
  ["UA", 99.25269445526556],
  ["LT", 87.95513569666277],
  ["LC", 98.497273081418],
  ["MG", 0],
  ["CL", 98.57972990520712],
  ["BH", 66.9299441630957],
  ["AD", 93.63167121153097],
  ["CH", 94.8182054278665],
  ["NO", 70.80898584599402],
  ["MT", 67.20328528762498],
  ["MC", 83.03856641994545],
  ["TV", 84.16406960135048],
  ["TW", 95.08797558758603],
  ["US", 87.94279963641085],
  ["MU", 82.49902610050643],
  ["BZ", 87.10037657447086],
  ["CI", 99.47766523828075],
  ["BM", 87.5243474873393],
  ["CK", 89.50525905726529],
  ["AT", 66.77801584209843],
  ["BO", 77.07797688611868],
  ["LS", 66.3731982859369],
  ["NL", 88.5174003376185],
  ["MW", 95.91092065965458],
  ["YE", 88.56999091027139],
  ["UG", 95.87618491105052],
  ["TT", 93.42974938319699],
  ["NZ", 91.04564342293209],
  ["MA", 95.91351772497079],
  ["MV", 86.30080509024802],
  ["LR", 87.95546032982729],
  ["BY", 93.45799246851058],
  ["BN", 84.01863394364368],
  ["AU", 90.95344760420726],
  ["GE", 71.29853265809635],
  ["GR", 84.25723931956888],
  ["IN", 87.87365277236722],
  ["GBENG", 72.11660823269706],
  ["RS", 81.59914296844566],
  ["PL", 83.13465783664459],
  ["SV", 87.79152058174263],
  ["SA", 60.40124659135178],
  ["HK", 94.66887417218544],
  ["KG", 89.75684975977146],
  ["GD", 88.46123880015583],
  ["GQ", 94.67504220231139],
  ["DJ", 76.20731073886509],
  ["KE", 90.36229061160888],
  ["KR", 67.37339306583561],
  ["SC", 76.68744318919622],
  ["ST", 97.96325152577587],
  ["PY", 86.78970263602129],
  ["SB", 90.03863134657837],
  ["IL", 74.17186079729905],
  ["DK", 94.02869757174392],
  ["DO", 91.33586547201662],
  ["RU", 87.75159070250616],
  ["SG", 47.6967276977016],
  ["PK", 73.01941306323853],
  ["GB", 80.04220231138814],
  ["GU", 91.5322685365537],
  ["GW", 98.31158291131023],
  ["JP", 65.32008830022075],
  ["SR", 90.85151279054668],
  ["SE", 98.42065965459031],
  ["SD", 98.01064796779639],
  ["SS", 76.28132710037657],
  ["PH", 79.76886118685886],
  ["RW", 78.16322555512271],
  ["KU", 97.17147123750163],
  ["HN", 87.99831190754448],
  ["GA", 98.7034151408908],
  ["DZ", 89.38709258537853],
  ["DM", 68.66738085962862],
  ["FR", 83.12199714322816],
  ["GL", 81.77282171146605],
  ["ES", 95.96675756395273],
  ["HT", 97.18543046357617],
  ["PE", 99.33352811323205],
  ["QA", 66.36281002467213],
  ["SI", 88.87774315024024],
  ["PR", 90.96643293078822],
  ["PS", 76.0066874431892],
  ["IQ", 86.69393585248669],
  ["HU", 66.87573042462019],
  ["KN", 92.61946500454486],
  ["KY", 87.79541617971692],
  ["FI", 79.2991169977925],
  ["ER", 87.59674068302817],
  ["GM", 95.50870016880924],
  ["EE", 88.79431242695753],
  ["EG", 87.6658875470718],
  ["ID", 83.03856641994545],
  ["IS", 91.93903389170237],
  ["SK", 85.2129593559278],
  ["RO", 99.26178418387222],
  ["PG", 92.70386962732113],
  ["KM", 93.51999740293468],
  ["IR", 80.22139981820543],
  ["KZ", 90.05583690429815],
  ["IE", 99.62991819244253],
  ["GN", 99.34002077652254],
  ["FJ", 87.81976366705622],
  ["GY", 91.58356057654849],
  ["JM", 80.86612128295026],
  ["KI", 83.35833008700169],
  ["HR", 82.11823139851967],
  ["SO", 97.53928061290742],
  ["PT", 92.03577457473055],
  ["SY", 88.52551616673159],
  ["SN", 97.43896896506948],
  ["KH", 94.31015452538631],
  ["EC", 91.90267497727568],
  ["ET", 87.5957667835346],
  ["DE", 98.3193741072588],
  ["SL", 88.88261264770809],
  ["PW", 97.87722373717699],
  ["SZ", 90.98558628749514],
  ["PA", 83.13271003765745],
  ["SM", 77.21367354888974],
  ["IT", 99.47896377093885],
  ["JO", 76.40144137125048],
  ["GH", 76.8354759122192],
  ["CG", 95.12595766783535],
  ["BT", 85.34573432021816],
  ["AO", 96.82671081677704],
  ["ML", 99.18809245552525],
  ["TN", 95.24639657187379],
  ["LI", 98.01973769640307],
  ["MM", 96.43358005453837],
  ["MZ", 95.9826645890144],
  ["NA", 65.41260875211012],
  ["CF", 89.21308920919361],
  ["BB", 99.16147253603428],
  ["CD", 93.76639397480847],
  ["BW", 97.09355927801585],
  ["AL", 91.8179457213349],
  ["NC", 67.75386313465783],
  ["MX", 96.45208414491624],
  ["TZ", 93.69919490975198],
  ["VE", 97.4987014673419],
  ["MN", 96.36053759252046],
  ["MY", 82.88176860148033],
  ["AM", 99.07836644591612],
  ["CR", 86.19042981430984],
  ["BA", 93.52032203609922],
  ["AZ", 95.59635112323075],
  ["CV", 92.1685495390209],
  ["BR", 72.86326451110246],
  ["CA", 87.42695753798208],
  ["BE", 82.90384365666796],
  ["TH", 88.06973120373978],
  ["WS", 98.4044279963641],
  ["UZ", 86.74360472665887],
  ["Wales", 68.85729126087521],
  ["NG", 82.97526295286326],
  ["NP", 83.20964809764966],
  ["MK", 83.32489287105571],
  ["BD", 64.28710557070511],
  ["BS", 99.02772367225036],
  ["CU", 94.11927022464614],
  ["BF", 65.6252434748734],
  ["NR", 97.0783015192832],
  ["NE", 87.24094273470978],
  ["ZW", 84.1455655109726],
  ["VC", 96.23393065835606],
  ["UY", 75.63530710297364],
  ["TJ", 85.28697571743929],
  ["VU", 67.75451240098688],
  ["ZA", 67.40131151798468],
  ["BG", 87.86878327489937],
]);

export const clMap = new Map<string, number>([
  ["CY", 69.9311777691209],
  ["AF", 92.48409297493832],
  ["CN", 46.70594727957408],
  ["BJ", 67.45292819114401],
  ["LA", 68.02817815868069],
  ["ME", 91.42481495909622],
  ["OM", 49.21308920919361],
  ["NI", 87.72626931567329],
  ["MR", 95.74211141410206],
  ["TG", 92.74444877288663],
  ["TP", 58.75600571354369],
  ["North Korea", 62.50876509544215],
  ["VN", 50.601220620698605],
  ["ZM", 92.06206986105701],
  ["MD", 79.00045448643034],
  ["CO", 71.84553954031944],
  ["AG", 56.47870406440722],
  ["AE", 74.98896247240619],
  ["CZ", 27.59511751720556],
  ["AR", 89.18971562134787],
  ["BI", 68.23042462017919],
  ["CM", 81.49785742111413],
  ["LB", 57.996688741721854],
  ["LU", 87.7074405921309],
  ["TD", 81.10148032723023],
  ["TR", 94.30723282690559],
  ["Scotland", 79.80262303596936],
  ["UA", 98.52746396571874],
  ["LT", 65.58823529411765],
  ["LC", 98.01032333463186],
  ["MG", 98.57972990520712],
  ["CL", 0],
  ["BH", 63.58037917153616],
  ["AD", 77.55940786910791],
  ["CH", 88.28301519283211],
  ["NO", 61.44039735099338],
  ["MT", 65.50090897286067],
  ["MC", 98.52649006622516],
  ["TV", 85.71451759511751],
  ["TW", 95.62102324373458],
  ["US", 75.01233606025191],
  ["MU", 97.9999350733671],
  ["BZ", 68.08466432930788],
  ["CI", 82.84573432021816],
  ["BM", 43.66770549279315],
  ["CK", 88.14407219841578],
  ["AT", 55.02434748733931],
  ["BO", 97.76522529541619],
  ["LS", 78.2034800675237],
  ["NL", 87.7210751850409],
  ["MW", 80.59115699259837],
  ["YE", 87.71880275288923],
  ["UG", 92.70581742630827],
  ["TT", 60.39442929489677],
  ["NZ", 90.17075704453967],
  ["MA", 48.79041682898325],
  ["MV", 63.47454875990132],
  ["LR", 81.26444617582132],
  ["BY", 90.76321256979612],
  ["BN", 83.27976886118685],
  ["AU", 90.498636540709],
  ["GE", 74.47149720815477],
  ["GR", 83.72711336190105],
  ["IN", 89.3315803142449],
  ["GBENG", 62.13381379041682],
  ["RS", 92.25262952863264],
  ["PL", 16.235229191014152],
  ["SV", 78.69075444747435],
  ["SA", 91.17582132190624],
  ["HK", 48.136281002467214],
  ["KG", 49.186469289702636],
  ["GD", 71.59492273730685],
  ["GQ", 55.63498246980912],
  ["DJ", 93.31158291131023],
  ["KE", 90.69731203739774],
  ["KR", 71.58356057654849],
  ["SC", 71.7533437215946],
  ["ST", 88.51707570445396],
  ["PY", 87.830151928321],
  ["SB", 79.0121412803532],
  ["IL", 72.33346318659915],
  ["DK", 52.67887287365277],
  ["DO", 69.26860148032723],
  ["RU", 37.12375016231658],
  ["SG", 95.85475912219192],
  ["PK", 95.01428385923906],
  ["GB", 64.6510193481366],
  ["GU", 87.20653161927022],
  ["GW", 79.1806258927412],
  ["JP", 73.24957797688612],
  ["SR", 89.53187897675626],
  ["SE", 86.74847422412674],
  ["SD", 84.9042332164654],
  ["SS", 74.44390338917025],
  ["PH", 53.41254382547721],
  ["RW", 99.15335670692119],
  ["KU", 57.830151928320994],
  ["HN", 88.18659914296845],
  ["GA", 97.33735878457344],
  ["DZ", 70.76386183612517],
  ["DM", 90.92260745357746],
  ["FR", 51.459875340864826],
  ["GL", 34.49292299701337],
  ["ES", 93.23009998701467],
  ["HT", 33.86508245682379],
  ["PE", 48.99298792364628],
  ["QA", 96.39267627580833],
  ["SI", 40.49116997792495],
  ["PR", 79.25983638488509],
  ["PS", 77.19484482534736],
  ["IQ", 87.56168030125959],
  ["HU", 88.005453837164],
  ["KN", 66.97247110764836],
  ["KY", 88.87449681859498],
  ["FI", 77.79346838072978],
  ["ER", 84.84190364887677],
  ["GM", 91.3167121153097],
  ["EE", 98.69075444747435],
  ["EG", 87.86716010907675],
  ["ID", 98.59985716140761],
  ["IS", 75.64926632904817],
  ["SK", 36.590377873003504],
  ["RO", 80.27528892351643],
  ["PG", 82.62693156732892],
  ["KM", 61.4218932606155],
  ["IR", 80.90929749383197],
  ["KZ", 91.39494870796],
  ["IE", 81.7708739124789],
  ["GN", 80.55999220880406],
  ["FJ", 88.54239709128684],
  ["GY", 79.35008440462278],
  ["JM", 91.49298792364628],
  ["KI", 87.01305025321386],
  ["HR", 87.47013374886379],
  ["SO", 96.1024542267238],
  ["PT", 91.30437605505779],
  ["SY", 87.3652772367225],
  ["SN", 79.27509414361771],
  ["KH", 86.70951824438384],
  ["EC", 65.77749642903518],
  ["ET", 62.76230359693547],
  ["DE", 97.67562654200754],
  ["SL", 88.91799766264121],
  ["PW", 96.92280223347616],
  ["SZ", 87.14355278535255],
  ["PA", 88.11388131411505],
  ["SM", 66.3868328788469],
  ["IT", 65.19867549668874],
  ["JO", 74.11472536034282],
  ["GH", 97.26723802103623],
  ["CG", 70.06460199974029],
  ["BT", 61.18296325152578],
  ["AO", 96.7978184651344],
  ["ML", 81.39689650694714],
  ["TN", 91.48357356187508],
  ["LI", 46.77963900792105],
  ["MM", 61.34398130112972],
  ["MZ", 84.57343202181535],
  ["NA", 76.46214777301648],
  ["CF", 76.81567328918322],
  ["BB", 95.23373587845735],
  ["CD", 84.84937021166083],
  ["BW", 97.02181534865602],
  ["AL", 51.766329048175564],
  ["NC", 76.97701597195169],
  ["MX", 66.0216205687573],
  ["TZ", 93.27457473055448],
  ["VE", 61.89553304765615],
  ["MN", 68.29535125308401],
  ["MY", 55.52103622906116],
  ["AM", 92.77821062199715],
  ["CR", 70.20841449162447],
  ["BA", 79.58511881573823],
  ["AZ", 95.53564472146473],
  ["CV", 74.57505518763797],
  ["BR", 89.54129333852747],
  ["CA", 82.0445396701727],
  ["BE", 80.8745617452279],
  ["TH", 70.48337878197637],
  ["WS", 46.71536164134528],
  ["UZ", 84.73672250357096],
  ["Wales", 60.65770679132581],
  ["NG", 81.76925074665627],
  ["NP", 47.91260875211012],
  ["MK", 83.03402155564213],
  ["BD", 94.47928840410337],
  ["BS", 95.91871185560316],
  ["CU", 68.10089598753409],
  ["BF", 97.02278924814959],
  ["NR", 94.95195429165044],
  ["NE", 90.4233216465394],
  ["ZW", 84.22704843526814],
  ["VC", 94.48740423321647],
  ["UY", 77.88079470198676],
  ["TJ", 84.30690819374107],
  ["VU", 91.96403064537073],
  ["ZA", 89.77373068432671],
  ["BG", 42.47662641215427],
]);

export const bhMap = new Map<string, number>([
  ["CY", 64.51340830449827],
  ["AF", 89.39950980392157],
  ["CN", 31.673154555940027],
  ["BJ", 66.7549740484429],
  ["LA", 62.30031718569781],
  ["ME", 48.900302768166085],
  ["OM", 72.10604094579008],
  ["NI", 86.83751441753171],
  ["MR", 93.1462658592849],
  ["TG", 91.36714244521337],
  ["TP", 30.792964244521336],
  ["North Korea", 90.68519319492503],
  ["VN", 39.145400807381776],
  ["ZM", 85.4072952710496],
  ["MD", 58.69016724336793],
  ["CO", 79.52962802768167],
  ["AG", 69.60171568627452],
  ["AE", 96.70919838523645],
  ["CZ", 61.024005190311414],
  ["AR", 88.35099480968857],
  ["BI", 62.26679642445213],
  ["CM", 67.87341407151096],
  ["LB", 45.55615628604383],
  ["LU", 66.57908016147636],
  ["TD", 63.76621972318339],
  ["TR", 30.64446366782007],
  ["Scotland", 80.50353229527106],
  ["UA", 95.99517012687427],
  ["LT", 75.72664359861592],
  ["LC", 99.07367358708188],
  ["MG", 67.00259515570934],
  ["CL", 63.68836505190312],
  ["BH", 0],
  ["AD", 61.89950980392157],
  ["CH", 92.40592560553634],
  ["NO", 88.86534025374856],
  ["MT", 18.83326124567474],
  ["MC", 50.072448096885815],
  ["TV", 80.48514994232987],
  ["TW", 92.71193771626297],
  ["US", 57.41926182237601],
  ["MU", 79.33535178777393],
  ["BZ", 76.87391868512111],
  ["CI", 98.19528546712803],
  ["BM", 39.632713379469436],
  ["CK", 82.83520761245676],
  ["AT", 45.329080161476355],
  ["BO", 74.97368800461362],
  ["LS", 84.78842272202999],
  ["NL", 87.02458189158017],
  ["MW", 69.90592560553634],
  ["YE", 67.06783448673586],
  ["UG", 94.12125144175317],
  ["TT", 52.68238177623991],
  ["NZ", 84.14395905420992],
  ["MA", 32.43764417531719],
  ["MV", 54.08592848904268],
  ["LR", 50.26131776239908],
  ["BY", 90.67762399077279],
  ["BN", 82.6946366782007],
  ["AU", 84.33391003460208],
  ["GE", 71.82706170703575],
  ["GR", 83.10121107266436],
  ["IN", 86.59818339100346],
  ["GBENG", 50.98615916955017],
  ["RS", 82.19038350634371],
  ["PL", 50.20328719723184],
  ["SV", 86.48464532871972],
  ["SA", 89.73543829296425],
  ["HK", 40.58246828143022],
  ["KG", 40.82396193771626],
  ["GD", 66.70054786620531],
  ["GQ", 72.25994809688582],
  ["DJ", 76.42877739331027],
  ["KE", 88.30521914648212],
  ["KR", 63.03921568627451],
  ["SC", 63.82857554786621],
  ["ST", 94.72678777393311],
  ["PY", 64.45105247981544],
  ["SB", 87.87990196078431],
  ["IL", 72.67625432525952],
  ["DK", 43.142301038062286],
  ["DO", 70.05118223760091],
  ["RU", 65.10164359861592],
  ["SG", 48.14194059976932],
  ["PK", 68.77450980392157],
  ["GB", 57.01448961937716],
  ["GU", 82.05233564013841],
  ["GW", 95.74826989619378],
  ["JP", 50.902537485582464],
  ["SR", 68.36937716262976],
  ["SE", 95.99480968858131],
  ["SD", 76.39021049596309],
  ["SS", 76.62954152249135],
  ["PH", 46.39561707035755],
  ["RW", 91.64395905420992],
  ["KU", 74.67704728950405],
  ["HN", 86.65657439446368],
  ["GA", 95.89641003460207],
  ["DZ", 90.13011822376009],
  ["DM", 83.52724913494811],
  ["FR", 64.40960207612457],
  ["GL", 50.229599192618224],
  ["ES", 61.53907151095732],
  ["HT", 64.48096885813149],
  ["PE", 63.00317185697809],
  ["QA", 66.93519319492502],
  ["SI", 66.52970011534025],
  ["PR", 89.39770761245674],
  ["PS", 97.52306805074971],
  ["IQ", 65.74646770472896],
  ["HU", 66.30478662053056],
  ["KN", 65.74358419838524],
  ["KY", 82.76852652825836],
  ["FI", 76.59998558246828],
  ["ER", 69.21208189158016],
  ["GM", 71.98529411764706],
  ["EE", 87.81934832756632],
  ["EG", 67.0595444059977],
  ["ID", 50.072448096885815],
  ["IS", 81.94420415224913],
  ["SK", 84.06790657439447],
  ["RO", 63.7752306805075],
  ["PG", 59.60423875432526],
  ["KM", 76.92978662053056],
  ["IR", 79.67055940023067],
  ["KZ", 87.88927335640139],
  ["IE", 97.03143021914649],
  ["GN", 96.01319204152249],
  ["FJ", 85.15210495963092],
  ["GY", 89.64604959630911],
  ["JM", 90.74610726643598],
  ["KI", 57.79087370242214],
  ["HR", 80.44694348327567],
  ["SO", 94.81689734717416],
  ["PT", 91.06365340253748],
  ["SY", 67.10423875432527],
  ["SN", 62.98623125720877],
  ["KH", 71.78020472895041],
  ["EC", 73.75829008073818],
  ["ET", 68.67214532871972],
  ["DE", 96.4446366782007],
  ["SL", 87.2913062283737],
  ["PW", 97.80024509803921],
  ["SZ", 76.60755478662054],
  ["PA", 57.0191753171857],
  ["SM", 78.97527393310266],
  ["IT", 63.802984429065745],
  ["JO", 95.0482987312572],
  ["GH", 74.8205017301038],
  ["CG", 61.42156862745098],
  ["BT", 93.74531430219146],
  ["AO", 67.42683102652826],
  ["ML", 63.65087946943483],
  ["TN", 41.39814013840831],
  ["LI", 62.860798731257205],
  ["MM", 75.42675893886967],
  ["MZ", 92.30932814302192],
  ["NA", 72.26355247981544],
  ["CF", 71.10798731257208],
  ["BB", 96.24855824682814],
  ["CD", 80.28943194925029],
  ["BW", 97.14352652825836],
  ["AL", 43.11346597462514],
  ["NC", 92.71410034602076],
  ["MX", 61.06761822376009],
  ["TZ", 92.19975490196079],
  ["VE", 71.44679930795847],
  ["MN", 59.82338523644752],
  ["MY", 61.19269031141869],
  ["AM", 75.60012975778547],
  ["CR", 65.31322087658593],
  ["BA", 89.87636966551327],
  ["AZ", 76.37074682814303],
  ["CV", 83.78243944636678],
  ["BR", 87.25021626297578],
  ["CA", 88.68584198385237],
  ["BE", 65.7338523644752],
  ["TH", 66.59169550173011],
  ["WS", 38.73702422145329],
  ["UZ", 84.1270184544406],
  ["Wales", 61.91572952710496],
  ["NG", 96.89302191464822],
  ["NP", 67.25273933102652],
  ["MK", 80.6693339100346],
  ["BD", 92.83088235294117],
  ["BS", 97.26283160322953],
  ["CU", 91.65080738177625],
  ["BF", 94.48529411764706],
  ["NR", 94.3508506343714],
  ["NE", 86.11195213379469],
  ["ZW", 64.95242214532871],
  ["VC", 93.528330449827],
  ["UY", 75.04072952710496],
  ["TJ", 84.23947520184545],
  ["VU", 66.9748414071511],
  ["ZA", 92.66255767012687],
  ["BG", 65.21554209919262],
]);

export const adMap = new Map<string, number>([
  ["CY", 89.49919673751853],
  ["AF", 94.37747157686604],
  ["CN", 93.11140632723678],
  ["BJ", 69.1130128521997],
  ["LA", 80.97998022738507],
  ["ME", 89.7655091448344],
  ["OM", 90.6676347009392],
  ["NI", 95.18258774097875],
  ["MR", 92.46601581809195],
  ["TG", 83.4666955017301],
  ["TP", 58.260009886307465],
  ["North Korea", 72.94241225902127],
  ["VN", 62.080449826989614],
  ["ZM", 92.29794859120119],
  ["MD", 39.48004201680672],
  ["CO", 73.5766806722689],
  ["AG", 65.50451062778052],
  ["AE", 95.80727879387048],
  ["CZ", 93.25537567968364],
  ["AR", 93.38729609490855],
  ["BI", 84.33669055857638],
  ["CM", 94.57210825506674],
  ["LB", 76.4569945625309],
  ["LU", 85.91973554127533],
  ["TD", 37.56487889273357],
  ["TR", 93.52879387048937],
  ["Scotland", 89.7435739001483],
  ["UA", 81.44340088976767],
  ["LT", 84.1556475531389],
  ["LC", 85.3568339100346],
  ["MG", 93.9205388037568],
  ["CL", 77.50648788927336],
  ["BH", 61.82711319822046],
  ["AD", 0],
  ["CH", 94.27829955511616],
  ["NO", 71.41343302026692],
  ["MT", 64.00735294117646],
  ["MC", 79.97621107266436],
  ["TV", 80.62407315867523],
  ["TW", 83.30758774097873],
  ["US", 67.2009391992091],
  ["MU", 82.70977508650519],
  ["BZ", 79.47664359861591],
  ["CI", 93.38111715274346],
  ["BM", 65.52551903114187],
  ["CK", 88.57698961937717],
  ["AT", 75.38680177953535],
  ["BO", 79.64378398418191],
  ["LS", 86.64081809194266],
  ["NL", 96.15886060306475],
  ["MW", 84.0821181413742],
  ["YE", 86.2135442412259],
  ["UG", 82.01866040533861],
  ["TT", 70.48566485417696],
  ["NZ", 88.9412382600099],
  ["MA", 63.89983934750371],
  ["MV", 70.86319822046465],
  ["LR", 73.64495798319328],
  ["BY", 91.34670044488384],
  ["BN", 75.50543746910529],
  ["AU", 73.58594908551656],
  ["GE", 89.50105042016807],
  ["GR", 92.17004448838358],
  ["IN", 94.7593302026693],
  ["GBENG", 88.09194265941672],
  ["RS", 91.4081809194266],
  ["PL", 80.06302521008404],
  ["SV", 95.2715645081562],
  ["SA", 90.9963544241226],
  ["HK", 93.82229362333169],
  ["KG", 59.30425111220959],
  ["GD", 60.91510133465151],
  ["GQ", 92.94364804745427],
  ["DJ", 91.97448096885813],
  ["KE", 94.41021997034107],
  ["KR", 92.9785590706871],
  ["SC", 73.59676223430549],
  ["ST", 85.89687345526445],
  ["PY", 85.0571552150272],
  ["SB", 86.57624814631734],
  ["IL", 91.83699950568463],
  ["DK", 67.72769401878398],
  ["DO", 81.5462802768166],
  ["RU", 85.07939940682155],
  ["SG", 79.4905462184874],
  ["PK", 95.39174493326742],
  ["GB", 72.37302273850716],
  ["GU", 88.62796589223925],
  ["GW", 79.19704646564509],
  ["JP", 94.8266806722689],
  ["SR", 80.63921156697972],
  ["SE", 83.97182402372714],
  ["SD", 82.05357142857143],
  ["SS", 88.94772614928324],
  ["PH", 78.155276816609],
  ["RW", 83.9446366782007],
  ["KU", 82.03843302026694],
  ["HN", 94.96478002965893],
  ["GA", 89.62679189322787],
  ["DZ", 94.11702916460702],
  ["DM", 90.43376173999012],
  ["FR", 29.88229115175482],
  ["GL", 77.76940187839841],
  ["ES", 69.17233069698467],
  ["HT", 64.07192288680179],
  ["PE", 61.64977755808205],
  ["QA", 96.00963914977756],
  ["SI", 82.75827978250123],
  ["PR", 87.85899653979239],
  ["PS", 92.70236035590706],
  ["IQ", 84.75407810182897],
  ["HU", 85.51717745921898],
  ["KN", 73.83959466139397],
  ["KY", 87.38970588235294],
  ["FI", 96.18048690064262],
  ["ER", 81.02601334651509],
  ["GM", 79.52978250123579],
  ["EE", 97.76445872466634],
  ["EG", 84.5696366782007],
  ["ID", 80.07847256549678],
  ["IS", 90.19062036579338],
  ["SK", 82.33316856154227],
  ["RO", 37.661888284725656],
  ["PG", 70.04387048937221],
  ["KM", 78.10244686109739],
  ["IR", 90.5542511122096],
  ["KZ", 89.67375185368265],
  ["IE", 93.55381858625803],
  ["GN", 69.13803756796837],
  ["FJ", 81.17801532377656],
  ["GY", 80.98523232822541],
  ["JM", 85.9515570934256],
  ["KI", 69.45748887790411],
  ["HR", 83.43580079090461],
  ["SO", 93.25599357390016],
  ["PT", 92.28311913000495],
  ["SY", 84.74233811171527],
  ["SN", 59.60454770143352],
  ["KH", 61.5827360355907],
  ["EC", 73.68419426594167],
  ["ET", 93.46422392486406],
  ["DE", 84.02650766188829],
  ["SL", 95.7584651507662],
  ["PW", 85.08434256055364],
  ["SZ", 83.81642362827483],
  ["PA", 77.41627533366288],
  ["SM", 90.8724666337123],
  ["IT", 61.77922639644093],
  ["JO", 92.01371725160654],
  ["GH", 80.5094537815126],
  ["CG", 66.66337123084529],
  ["BT", 87.05109985170539],
  ["AO", 77.60565991102322],
  ["ML", 37.818833415719226],
  ["TN", 94.12691547207118],
  ["LI", 78.20563519525457],
  ["MM", 72.19970341077607],
  ["MZ", 80.31389026198715],
  ["NA", 80.01637419673752],
  ["CF", 88.7595773603559],
  ["BB", 92.19599604547702],
  ["CD", 85.32841077607513],
  ["BW", 93.7382600098863],
  ["AL", 66.10881117152744],
  ["NC", 80.24252347998024],
  ["MX", 59.55140879881364],
  ["TZ", 91.51106030647553],
  ["VE", 73.7685368264953],
  ["MN", 61.11746169055857],
  ["MY", 66.22621107266437],
  ["AM", 85.64539050914483],
  ["CR", 80.90614186851212],
  ["BA", 50.438086999505686],
  ["AZ", 84.83502224419179],
  ["CV", 89.01106030647553],
  ["BR", 88.61097380128523],
  ["CA", 91.6735664854177],
  ["BE", 59.7228744438952],
  ["TH", 84.95489372219475],
  ["WS", 61.83112951062778],
  ["UZ", 94.16460701927831],
  ["Wales", 86.73473801285219],
  ["NG", 92.8293376173999],
  ["NP", 84.41578101828966],
  ["MK", 79.75747652001978],
  ["BD", 94.02280029658922],
  ["BS", 89.83934750370737],
  ["CU", 81.58057340583292],
  ["BF", 78.84021255561048],
  ["NR", 93.57390014829461],
  ["NE", 94.41454522985666],
  ["ZW", 83.3017177459219],
  ["VC", 68.8111715274345],
  ["UY", 89.44760257043994],
  ["TJ", 93.46113445378151],
  ["VU", 73.48924864063272],
  ["ZA", 89.39384577360356],
  ["BG", 94.75840336134453],
]);

export const chMap = new Map<string, number>([
  ["CY", 85.81141868512111],
  ["AF", 92.56444636678201],
  ["CN", 94.6260813148789],
  ["BJ", 96.4859429065744],
  ["LA", 88.75670415224913],
  ["ME", 93.88343425605537],
  ["OM", 90.1541955017301],
  ["NI", 85.56487889273356],
  ["MR", 95.1712802768166],
  ["TG", 95.13256920415225],
  ["TP", 94.31379757785467],
  ["North Korea", 94.3265570934256],
  ["VN", 94.8678633217993],
  ["ZM", 93.28698096885813],
  ["MD", 94.409169550173],
  ["CO", 96.66068339100346],
  ["AG", 90.64186851211072],
  ["AE", 85.23010380622837],
  ["CZ", 87.91414359861591],
  ["AR", 87.66414359861592],
  ["BI", 80.30147058823529],
  ["CM", 95.85661764705883],
  ["LB", 87.38927335640139],
  ["LU", 84.56228373702423],
  ["TD", 96.34493944636678],
  ["TR", 20.245242214532873],
  ["Scotland", 84.07482698961938],
  ["UA", 97.54498269896193],
  ["LT", 97.44333910034601],
  ["LC", 96.49870242214533],
  ["MG", 95.25627162629758],
  ["CL", 88.61505190311418],
  ["BH", 92.78135813148789],
  ["AD", 94.50173010380622],
  ["CH", 0],
  ["NO", 90.07525951557093],
  ["MT", 87.99048442906575],
  ["MC", 89.1621972318339],
  ["TV", 84.17019896193771],
  ["TW", 36.28157439446367],
  ["US", 79.99351211072664],
  ["MU", 96.96539792387543],
  ["BZ", 82.78871107266436],
  ["CI", 84.7863321799308],
  ["BM", 88.1996107266436],
  ["CK", 87.39662629757785],
  ["AT", 84.61224048442907],
  ["BO", 96.80060553633218],
  ["LS", 86.69896193771626],
  ["NL", 84.61721453287197],
  ["MW", 93.55601211072664],
  ["YE", 84.88365051903114],
  ["UG", 92.67409169550173],
  ["TT", 91.55514705882352],
  ["NZ", 88.12564878892734],
  ["MA", 97.66479238754326],
  ["MV", 95.23659169550173],
  ["LR", 87.81531141868511],
  ["BY", 91.04952422145328],
  ["BN", 90.60294117647058],
  ["AU", 82.22707612456747],
  ["GE", 79.99632352941177],
  ["GR", 90.73464532871972],
  ["IN", 87.15527681660899],
  ["GBENG", 96.028330449827],
  ["RS", 88.00346020761246],
  ["PL", 88.8345588235294],
  ["SV", 86.1885813148789],
  ["SA", 90.74567474048443],
  ["HK", 89.63300173010381],
  ["KG", 89.42063148788927],
  ["GD", 87.8628892733564],
  ["GQ", 83.00886678200692],
  ["DJ", 92.08607266435986],
  ["KE", 95.20782871972318],
  ["KR", 89.56379757785467],
  ["SC", 86.84039792387543],
  ["ST", 91.87218858131489],
  ["PY", 86.52443771626298],
  ["SB", 87.34710207612457],
  ["IL", 85.5795847750865],
  ["DK", 87.7863321799308],
  ["DO", 81.8579152249135],
  ["RU", 95.05601211072664],
  ["SG", 85.0413062283737],
  ["PK", 91.52811418685121],
  ["GB", 81.90462802768165],
  ["GU", 94.89641003460207],
  ["GW", 95.5886678200692],
  ["JP", 92.97923875432525],
  ["SR", 93.28611591695501],
  ["SE", 95.37737889273356],
  ["SD", 83.98464532871972],
  ["SS", 91.73442906574395],
  ["PH", 91.7882785467128],
  ["RW", 93.32439446366782],
  ["KU", 84.01232698961938],
  ["HN", 85.06379757785467],
  ["GA", 96.54260380622837],
  ["DZ", 89.92625432525952],
  ["DM", 94.21453287197232],
  ["FR", 84.18641868512111],
  ["GL", 87.65311418685121],
  ["ES", 94.19096020761246],
  ["HT", 95.22599480968859],
  ["PE", 84.41955017301038],
  ["QA", 93.4617214532872],
  ["SI", 92.7750865051903],
  ["PR", 49.84688581314879],
  ["PS", 83.92711937716263],
  ["IQ", 87.52270761245676],
  ["HU", 84.68814878892734],
  ["KN", 91.36980968858131],
  ["KY", 87.70501730103805],
  ["FI", 93.04519896193771],
  ["ER", 83.22556228373702],
  ["GM", 95.59904844290658],
  ["EE", 96.70696366782006],
  ["EG", 86.54995674740485],
  ["ID", 89.07158304498269],
  ["IS", 91.5806660899654],
  ["SK", 92.33888408304497],
  ["RO", 96.34450692041523],
  ["PG", 93.61245674740485],
  ["KM", 85.41371107266436],
  ["IR", 78.33499134948096],
  ["KZ", 91.56185121107266],
  ["IE", 84.72664359861591],
  ["GN", 96.46626297577855],
  ["FJ", 89.50756920415225],
  ["GY", 88.1109429065744],
  ["JM", 92.42603806228374],
  ["KI", 83.34731833910035],
  ["HR", 57.72037197231834],
  ["SO", 93.18447231833909],
  ["PT", 49.4132785467128],
  ["SY", 86.66608996539792],
  ["SN", 95.01492214532871],
  ["KH", 90.6492214532872],
  ["EC", 93.61029411764706],
  ["ET", 92.05601211072664],
  ["DE", 96.39554498269896],
  ["SL", 84.6098615916955],
  ["PW", 97.41760380622837],
  ["SZ", 86.07634083044982],
  ["PA", 87.20480103806229],
  ["SM", 91.83153114186851],
  ["IT", 84.27876297577855],
  ["JO", 85.48096885813149],
  ["GH", 95.28092560553634],
  ["CG", 92.81812283737024],
  ["BT", 89.90960207612457],
  ["AO", 95.76773356401385],
  ["ML", 96.2954152249135],
  ["TN", 13.971885813148788],
  ["LI", 95.01016435986159],
  ["MM", 87.0746107266436],
  ["MZ", 91.71907439446368],
  ["NA", 86.7409169550173],
  ["CF", 92.13732698961937],
  ["BB", 95.00627162629758],
  ["CD", 92.01535467128028],
  ["BW", 97.41392733564014],
  ["AL", 94.58153114186851],
  ["NC", 92.7348615916955],
  ["MX", 88.4405276816609],
  ["TZ", 91.97512975778547],
  ["VE", 95.38948961937717],
  ["MN", 93.35748269896193],
  ["MY", 84.59147923875433],
  ["AM", 97.31639273356402],
  ["CR", 91.01189446366782],
  ["BA", 90.42820069204153],
  ["AZ", 95.33088235294119],
  ["CV", 89.03481833910034],
  ["BR", 90.36980968858131],
  ["CA", 45.07698961937716],
  ["BE", 97.06920415224913],
  ["TH", 92.82936851211072],
  ["WS", 94.7549740484429],
  ["UZ", 81.26838235294119],
  ["Wales", 85.64641003460207],
  ["NG", 84.57396193771626],
  ["NP", 84.07569204152249],
  ["MK", 83.63646193771626],
  ["BD", 94.43663494809688],
  ["BS", 94.88019031141869],
  ["CU", 86.08391003460207],
  ["BF", 96.43468858131487],
  ["NR", 95.54282006920415],
  ["NE", 89.99740484429066],
  ["ZW", 92.19485294117648],
  ["VC", 94.04238754325259],
  ["UY", 84.9041955017301],
  ["TJ", 83.28654844290656],
  ["VU", 93.97296712802769],
  ["ZA", 91.91219723183391],
  ["BG", 95.19701557093425],
]);

export const noMap = new Map<string, number>([
  ["CY", 82.07040998217468],
  ["AF", 89.30540701128936],
  ["CN", 38.95959595959596],
  ["BJ", 73.91503267973856],
  ["LA", 82.31164587046939],
  ["ME", 88.11734997029114],
  ["OM", 73.5564468211527],
  ["NI", 86.40879382055853],
  ["MR", 93.0136660724896],
  ["TG", 85.87076648841355],
  ["TP", 48.45513963161022],
  ["North Korea", 65.67825311942958],
  ["VN", 36.968508615567444],
  ["ZM", 87.12240047534165],
  ["MD", 91.13636363636364],
  ["CO", 91.33125371360666],
  ["AG", 90.56030897207367],
  ["AE", 88.79649435531789],
  ["CZ", 59.552287581699346],
  ["AR", 87.29738562091504],
  ["BI", 84.7038027332145],
  ["CM", 92.45781342840166],
  ["LB", 46.799762329174094],
  ["LU", 60.11764705882353],
  ["TD", 89.65121806298278],
  ["TR", 91.53060011883541],
  ["Scotland", 84.8285799168152],
  ["UA", 92.43582887700535],
  ["LT", 95.62388591800357],
  ["LC", 96.74955436720143],
  ["MG", 71.04842543077837],
  ["CL", 61.61200237670826],
  ["BH", 89.31431966726085],
  ["AD", 71.24450386215092],
  ["CH", 89.45603089720737],
  ["NO", 0],
  ["MT", 50.725193107546055],
  ["MC", 88.80154486036838],
  ["TV", 80.31224004753416],
  ["TW", 92.85056446821153],
  ["US", 78.02792632204397],
  ["MU", 65.8149138443256],
  ["BZ", 73.71509209744504],
  ["CI", 90.5513963161022],
  ["BM", 42.852346999405825],
  ["CK", 75.50534759358288],
  ["AT", 33.78104575163399],
  ["BO", 64.99851455733808],
  ["LS", 87.93909685086156],
  ["NL", 86.51455733808675],
  ["MW", 90.46108140225788],
  ["YE", 86.83155080213903],
  ["UG", 88.4640522875817],
  ["TT", 90.42572786690434],
  ["NZ", 75.68983957219253],
  ["MA", 93.77837195484254],
  ["MV", 47.3663101604278],
  ["LR", 93.31134878193701],
  ["BY", 88.87819370172312],
  ["BN", 84.55525846702318],
  ["AU", 90.36512180629828],
  ["GE", 86.63012477718361],
  ["GR", 85.7415329768271],
  ["IN", 87.0840760546643],
  ["GBENG", 88.27272727272727],
  ["RS", 80.79887106357695],
  ["PL", 60.256981580510995],
  ["SV", 86.72727272727273],
  ["SA", 88.756981580511],
  ["HK", 36.24064171122995],
  ["KG", 35.4040404040404],
  ["GD", 84.75638740344623],
  ["GQ", 62.99613784907903],
  ["DJ", 89.90552584670232],
  ["KE", 91.00831847890672],
  ["KR", 81.3781937017231],
  ["SC", 65.7742127153892],
  ["ST", 82.35294117647058],
  ["PY", 85.70142602495544],
  ["SB", 86.34105763517528],
  ["IL", 84.00118835412952],
  ["DK", 93.08556149732621],
  ["DO", 83.98395721925134],
  ["RU", 66.53060011883541],
  ["SG", 61.82501485442662],
  ["PK", 90.4622697563874],
  ["GB", 71.44058229352346],
  ["GU", 78.04218657159834],
  ["GW", 91.64319667260844],
  ["JP", 84.35888294711825],
  ["SR", 92.79114676173499],
  ["SE", 91.01515151515152],
  ["SD", 65.76916221033868],
  ["SS", 89.8850267379679],
  ["PH", 89.47385620915033],
  ["RW", 89.15478312537137],
  ["KU", 87.26084373143198],
  ["HN", 86.70707070707071],
  ["GA", 90.90671420083186],
  ["DZ", 87.5050505050505],
  ["DM", 82.65715983363042],
  ["FR", 68.01485442661914],
  ["GL", 60.45870469399881],
  ["ES", 88.84878193701724],
  ["HT", 66.93048128342247],
  ["PE", 48.100415923945334],
  ["QA", 88.76262626262627],
  ["SI", 65.40582293523471],
  ["PR", 86.11586452762924],
  ["PS", 86.96108140225788],
  ["IQ", 86.35828877005348],
  ["HU", 86.70142602495544],
  ["KN", 90.21657754010695],
  ["KY", 74.54159239453358],
  ["FI", 78.26916221033868],
  ["ER", 83.34610814022578],
  ["GM", 93.99227569815805],
  ["EE", 95.3065953654189],
  ["EG", 87.12477718360071],
  ["ID", 88.80154486036838],
  ["IS", 81.52673796791444],
  ["SK", 63.70617944147355],
  ["RO", 87.77153891859774],
  ["PG", 90.99376114081996],
  ["KM", 86.36838978015449],
  ["IR", 78.43731431966727],
  ["KZ", 88.89661319073083],
  ["IE", 90.55733808674985],
  ["GN", 92.7825311942959],
  ["FJ", 86.781045751634],
  ["GY", 85.34105763517528],
  ["JM", 89.73380867498516],
  ["KI", 81.78877005347593],
  ["HR", 84.4040404040404],
  ["SO", 94.0121806298277],
  ["PT", 89.81907308377896],
  ["SY", 85.88472964943553],
  ["SN", 68.74361259655377],
  ["KH", 91.00089126559715],
  ["EC", 69.50950683303626],
  ["ET", 60.83422459893049],
  ["DE", 94.04188948306596],
  ["SL", 86.89809863339275],
  ["PW", 92.3544266191325],
  ["SZ", 85.10724896019013],
  ["PA", 69.82679738562092],
  ["SM", 88.21152703505645],
  ["IT", 89.59863339275104],
  ["JO", 86.27124183006536],
  ["GH", 89.77421271538918],
  ["CG", 68.30510992275698],
  ["BT", 67.32739156268568],
  ["AO", 91.34194890077242],
  ["ML", 92.56625074272134],
  ["TN", 90.27480689245395],
  ["LI", 84.47712418300654],
  ["MM", 66.59952465834819],
  ["MZ", 80.7688651218063],
  ["NA", 65.89631610219845],
  ["CF", 79.43315508021391],
  ["BB", 86.0187165775401],
  ["CD", 81.09447415329768],
  ["BW", 88.07397504456328],
  ["AL", 35.647058823529406],
  ["NC", 85.3972073677956],
  ["MX", 89.93018419489007],
  ["TZ", 92.1675579322638],
  ["VE", 56.59952465834819],
  ["MN", 91.06179441473559],
  ["MY", 64.86571598336303],
  ["AM", 95.57070707070707],
  ["CR", 87.36066547831254],
  ["BA", 91.91414141414141],
  ["AZ", 93.48692810457516],
  ["CV", 90.13874034462269],
  ["BR", 86.30510992275698],
  ["CA", 86.6402257872846],
  ["BE", 70.4486036838978],
  ["TH", 68.92691622103386],
  ["WS", 86.90255496137848],
  ["UZ", 83.60487225193107],
  ["Wales", 75.49019607843137],
  ["NG", 90.72192513368984],
  ["NP", 64.32560903149138],
  ["MK", 81.90730837789661],
  ["BD", 89.56625074272134],
  ["BS", 91.15210932857993],
  ["CU", 77.58467023172906],
  ["BF", 66.80540701128936],
  ["NR", 84.71122994652407],
  ["NE", 88.73054070112893],
  ["ZW", 82.73559120617944],
  ["VC", 90.69994058229352],
  ["UY", 89.55585264408793],
  ["TJ", 85.51485442661914],
  ["VU", 68.14973262032086],
  ["ZA", 73.1149732620321],
  ["BG", 66.99346405228758],
]);

export const mtMap = new Map<string, number>([
  ["CY", 55.22691858200234],
  ["AF", 93.07330216854955],
  ["CN", 47.73438514478639],
  ["BJ", 73.8680041553045],
  ["LA", 69.03291780288274],
  ["ME", 93.59531229710427],
  ["OM", 73.29080638878068],
  ["NI", 82.54739644202051],
  ["MR", 96.21932216595248],
  ["TG", 95.52103622906117],
  ["TP", 44.17413322945072],
  ["North Korea", 62.19646799116998],
  ["VN", 51.43942345149981],
  ["ZM", 88.13952733411246],
  ["MD", 61.83515127905467],
  ["CO", 82.96877028957277],
  ["AG", 68.82482794442281],
  ["AE", 90.96578366445917],
  ["CZ", 62.54122841189456],
  ["AR", 81.56570575249967],
  ["BI", 65.41325801843917],
  ["CM", 82.97071808855992],
  ["LB", 51.393000908972866],
  ["LU", 66.35209713024283],
  ["TD", 65.52136086222569],
  ["TR", 45.75087650954421],
  ["Scotland", 75.95474613686535],
  ["UA", 98.63491754317621],
  ["LT", 80.3759252045189],
  ["LC", 94.85748604077394],
  ["MG", 67.20328528762498],
  ["CL", 65.50090897286067],
  ["BH", 18.837488637839243],
  ["AD", 63.97935333073627],
  ["CH", 87.70549279314375],
  ["NO", 50.71614076094014],
  ["MT", 0],
  ["MC", 49.40494740942734],
  ["TV", 83.5537592520452],
  ["TW", 95.60089598753409],
  ["US", 62.16432930788209],
  ["MU", 85.69893520322036],
  ["BZ", 75.80866121282949],
  ["CI", 80.83073626801715],
  ["BM", 49.449422152967145],
  ["CK", 85.21198545643422],
  ["AT", 50.25094143617712],
  ["BO", 82.00623295675886],
  ["LS", 81.04337099078042],
  ["NL", 81.9218283339826],
  ["MW", 79.95195429165044],
  ["YE", 65.96123880015583],
  ["UG", 93.24373457992469],
  ["TT", 60.11069990910271],
  ["NZ", 87.1467991169978],
  ["MA", 49.34294247500325],
  ["MV", 64.02869757174393],
  ["LR", 55.499610440202574],
  ["BY", 61.26834177379561],
  ["BN", 82.92494481236203],
  ["AU", 86.75496688741721],
  ["GE", 65.98039215686275],
  ["GR", 78.10122062069861],
  ["IN", 81.19789637709388],
  ["GBENG", 51.07031554343592],
  ["RS", 79.17997662641216],
  ["PL", 51.18101545253864],
  ["SV", 83.06908193741073],
  ["SA", 91.71925723931957],
  ["HK", 49.40364887676925],
  ["KG", 49.96591351772497],
  ["GD", 72.45325282430854],
  ["GQ", 72.93468380729774],
  ["DJ", 73.13563173613817],
  ["KE", 91.23100895987534],
  ["KR", 57.82787949616933],
  ["SC", 68.20120763537203],
  ["ST", 96.88092455525256],
  ["PY", 66.0755096740683],
  ["SB", 90.05356447214648],
  ["IL", 65.83593039864952],
  ["DK", 40.26392676275808],
  ["DO", 66.60011686793924],
  ["RU", 67.30976496558888],
  ["SG", 47.7129593559278],
  ["PK", 71.33489157252305],
  ["GB", 61.399168939098814],
  ["GU", 88.81638748214517],
  ["GW", 98.60699909102713],
  ["JP", 51.19107908063888],
  ["SR", 71.24853915075964],
  ["SE", 98.48136605635631],
  ["SD", 75.43468380729776],
  ["SS", 81.25665497987275],
  ["PH", 54.64647448383327],
  ["RW", 94.03389170237631],
  ["KU", 74.87274379950655],
  ["HN", 82.5944682508765],
  ["GA", 98.03824178678093],
  ["DZ", 97.58440462277626],
  ["DM", 90.20711595896637],
  ["FR", 50.49344241007662],
  ["GL", 51.23457992468511],
  ["ES", 71.07745747305545],
  ["HT", 73.36417348396313],
  ["PE", 49.384170886897806],
  ["QA", 68.76704324113751],
  ["SI", 68.79496169328658],
  ["PR", 87.18283339825996],
  ["PS", 92.17796390079211],
  ["IQ", 66.90592130892092],
  ["HU", 66.2965848591092],
  ["KN", 67.74315024022854],
  ["KY", 85.10161018049604],
  ["FI", 76.9260485651214],
  ["ER", 79.03161927022465],
  ["GM", 76.94292948967667],
  ["EE", 82.0578496299182],
  ["EG", 66.74490325931697],
  ["ID", 49.51564731853006],
  ["IS", 83.2641864692897],
  ["SK", 66.75009738994936],
  ["RO", 65.6294637060122],
  ["PG", 66.19594857810675],
  ["KM", 79.17932736008311],
  ["IR", 75.78853395662901],
  ["KZ", 91.7371120633684],
  ["IE", 82.63959226074536],
  ["GN", 98.22555512271134],
  ["FJ", 86.74555252564602],
  ["GY", 89.69906505648618],
  ["JM", 91.0524607193871],
  ["KI", 60.49409167640566],
  ["HR", 80.54473445007142],
  ["SO", 95.73659265030516],
  ["PT", 94.23224256590053],
  ["SY", 66.2222438644332],
  ["SN", 64.08550837553565],
  ["KH", 75.04447474353981],
  ["EC", 79.01928320997274],
  ["ET", 77.77204259187118],
  ["DE", 98.94559148162575],
  ["SL", 82.27373068432671],
  ["PW", 95.92780158420983],
  ["SZ", 90.6414751330996],
  ["PA", 51.203739774055315],
  ["SM", 74.80197376964031],
  ["IT", 49.44487728866381],
  ["JO", 96.35956369302689],
  ["GH", 81.5579145565511],
  ["CG", 64.96299181924425],
  ["BT", 89.95877158810545],
  ["AO", 74.32898324892872],
  ["ML", 64.81106349824698],
  ["TN", 50.617452278924816],
  ["LI", 72.87170497338008],
  ["MM", 74.31469938968965],
  ["MZ", 95.04512400986886],
  ["NA", 78.03012595766783],
  ["CF", 79.77860018179457],
  ["BB", 97.36300480457083],
  ["CD", 84.11212829502662],
  ["BW", 93.65569406570575],
  ["AL", 54.977275678483316],
  ["NC", 93.83748863783924],
  ["MX", 49.35462926892612],
  ["TZ", 92.44383846253733],
  ["VE", 76.5767432800935],
  ["MN", 63.17491234904558],
  ["MY", 63.0982989222179],
  ["AM", 82.40715491494612],
  ["CR", 65.27236722503571],
  ["BA", 92.09323464485132],
  ["AZ", 81.66017400337618],
  ["CV", 86.07713283989092],
  ["BR", 90.49636410855733],
  ["CA", 78.87904168289832],
  ["BE", 66.01577717179588],
  ["TH", 67.55226593948838],
  ["WS", 48.92513959226075],
  ["UZ", 80.24185170757045],
  ["Wales", 69.97402934683808],
  ["NG", 82.55291520581743],
  ["NP", 71.44786391377743],
  ["MK", 83.06713413842358],
  ["BD", 96.47286066744579],
  ["BS", 98.1320607713284],
  ["CU", 88.12297104272172],
  ["BF", 73.80275288923517],
  ["NR", 96.64199454616283],
  ["NE", 84.24814959096221],
  ["ZW", 72.11044020257108],
  ["VC", 95.59083235943385],
  ["UY", 66.24204648746917],
  ["TJ", 78.4015062978834],
  ["VU", 72.91228411894559],
  ["ZA", 92.39774055317491],
  ["BG", 67.5162316582262],
]);

export const mcMap = new Map<string, number>([
  ["CY", 52.47377811418686],
  ["AF", 92.35321150519032],
  ["CN", 52.504325259515575],
  ["BJ", 99.52908737024222],
  ["LA", 69.62829801038062],
  ["ME", 62.023951124567475],
  ["OM", 76.68198529411765],
  ["NI", 83.93220155709342],
  ["MR", 96.41571150519032],
  ["TG", 83.06012110726644],
  ["TP", 58.99897275086505],
  ["North Korea", 89.65830449826989],
  ["VN", 52.2364294982699],
  ["ZM", 94.77049091695503],
  ["MD", 78.30287629757785],
  ["CO", 100],
  ["AG", 78.62456747404845],
  ["AE", 75.26194852941175],
  ["CZ", 98.68701340830451],
  ["AR", 84.03790008650519],
  ["BI", 65.55606617647058],
  ["CM", 82.29725346020761],
  ["LB", 51.94879974048443],
  ["LU", 51.28676470588235],
  ["TD", 81.96907439446368],
  ["TR", 50.1270544982699],
  ["Scotland", 75.41819852941177],
  ["UA", 96.875],
  ["LT", 99.26470588235294],
  ["LC", 96.54898356401385],
  ["MG", 83.22907655709344],
  ["CL", 98.50346020761246],
  ["BH", 50.16733347750865],
  ["AD", 80.08704584775086],
  ["CH", 88.91354887543253],
  ["NO", 88.70512543252596],
  ["MT", 49.50989403114187],
  ["MC", 0],
  ["TV", 82.1361375432526],
  ["TW", 95.08136894463668],
  ["US", 53.452638408304495],
  ["MU", 71.50735294117648],
  ["BZ", 75.15435769896193],
  ["CI", 83.17149653979239],
  ["BM", 67.18777032871972],
  ["CK", 83.24205233564014],
  ["AT", 51.10294117647059],
  ["BO", 63.970588235294116],
  ["LS", 82.51135380622837],
  ["NL", 83.27205882352942],
  ["MW", 77.11370025951557],
  ["YE", 50.55147058823529],
  ["UG", 96.35164359861592],
  ["TT", 60.21518166089965],
  ["NZ", 83.89841046712803],
  ["MA", 50.064608564013845],
  ["MV", 64.76697664359861],
  ["LR", 56.744431228373706],
  ["BY", 94.32877378892734],
  ["BN", 89.86078070934256],
  ["AU", 87.69166306228374],
  ["GE", 64.42798442906575],
  ["GR", 76.53195285467127],
  ["IN", 83.74702638408304],
  ["GBENG", 50.31898788927336],
  ["RS", 60.76503027681661],
  ["PL", 99.81617647058823],
  ["SV", 84.38959775086505],
  ["SA", 92.10802335640138],
  ["HK", 50.82963884083045],
  ["KG", 50.676362456747405],
  ["GD", 73.90003243944636],
  ["GQ", 85.26086721453288],
  ["DJ", 85.65743944636678],
  ["KE", 92.48648356401384],
  ["KR", 54.06087802768166],
  ["SC", 59.522599480968864],
  ["ST", 89.60964532871972],
  ["PY", 50.57228589965398],
  ["SB", 89.09791306228374],
  ["IL", 66.13673226643598],
  ["DK", 50.56471669550173],
  ["DO", 67.33969506920415],
  ["RU", 99.81617647058823],
  ["SG", 4.83780276816609],
  ["PK", 81.73956531141869],
  ["GB", 61.739294982698965],
  ["GU", 89.27416738754326],
  ["GW", 83.96761461937716],
  ["JP", 50.155439013840834],
  ["SR", 70.90641219723184],
  ["SE", 96.3175821799308],
  ["SD", 57.72437283737024],
  ["SS", 83.90219506920415],
  ["PH", 87.75681228373703],
  ["RW", 92.84223615916956],
  ["KU", 86.99097102076124],
  ["HN", 83.65619593425605],
  ["GA", 96.85905060553634],
  ["DZ", 72.10315743944636],
  ["DM", 86.51870674740483],
  ["FR", 67.20128676470588],
  ["GL", 76.69306877162629],
  ["ES", 70.02027465397924],
  ["HT", 98.24097102076125],
  ["PE", 50.207612456747405],
  ["QA", 82.49621539792388],
  ["SI", 97.45404411764706],
  ["PR", 80.52687067474048],
  ["PS", 79.7923875432526],
  ["IQ", 51.897166955017305],
  ["HU", 50.55147058823529],
  ["KN", 89.1919874567474],
  ["KY", 83.03957612456747],
  ["FI", 69.42365916955018],
  ["ER", 73.09472318339101],
  ["GM", 60.845588235294116],
  ["EE", 66.54411764705883],
  ["EG", 51.46085640138408],
  ["ID", 0],
  ["IS", 82.63948961937716],
  ["SK", 96.4203070934256],
  ["RO", 82.03746756055364],
  ["PG", 66.03346669550173],
  ["KM", 95.3019571799308],
  ["IR", 77.73113105536332],
  ["KZ", 93.48832179930795],
  ["IE", 83.24718858131487],
  ["GN", 82.04314446366782],
  ["FJ", 82.94577205882354],
  ["GY", 80.31925821799308],
  ["JM", 92.24156574394463],
  ["KI", 33.735942906574394],
  ["HR", 79.55584991349481],
  ["SO", 96.94771842560553],
  ["PT", 93.37397275086505],
  ["SY", 50.606617647058826],
  ["SN", 81.315419550173],
  ["KH", 71.36137543252595],
  ["EC", 96.43598615916954],
  ["ET", 91.23323961937716],
  ["DE", 98.34558823529412],
  ["SL", 83.63970588235294],
  ["PW", 97.84953503460207],
  ["SZ", 79.17279411764706],
  ["PA", 50.48361807958478],
  ["SM", 95.98021193771626],
  ["IT", 66.69144679930797],
  ["JO", 77.88413711072664],
  ["GH", 63.298280709342556],
  ["CG", 87.46323529411765],
  ["BT", 91.88392084775087],
  ["AO", 49.46285683391004],
  ["ML", 81.90716911764706],
  ["TN", 50.14543685121108],
  ["LI", 97.2294009515571],
  ["MM", 93.11283520761245],
  ["MZ", 85.65392517301038],
  ["NA", 76.98286115916954],
  ["CF", 90.221669550173],
  ["BB", 97.78627811418686],
  ["CD", 85.47956314878893],
  ["BW", 95.77205882352942],
  ["AL", 57.65760164359861],
  ["NC", 94.60559039792388],
  ["MX", 66.85256271626298],
  ["TZ", 93.3372080449827],
  ["VE", 96.71631704152249],
  ["MN", 66.79741565743946],
  ["MY", 56.36516003460208],
  ["AM", 65.99264705882352],
  ["CR", 67.37321583044982],
  ["BA", 91.96907439446366],
  ["AZ", 83.14067906574395],
  ["CV", 81.00967776816609],
  ["BR", 91.49870242214533],
  ["CA", 79.0554714532872],
  ["BE", 82.62326989619378],
  ["TH", 67.46323529411765],
  ["WS", 71.80931012110726],
  ["UZ", 79.65181660899654],
  ["Wales", 87.54460423875432],
  ["NG", 83.3174740484429],
  ["NP", 59.393923010380625],
  ["MK", 83.22069636678201],
  ["BD", 96.90230320069205],
  ["BS", 96.52222102076125],
  ["CU", 73.33396410034602],
  ["BF", 97.58461288927336],
  ["NR", 94.64722102076124],
  ["NE", 85.42847102076124],
  ["ZW", 80.12435121107266],
  ["VC", 95.74259299307958],
  ["UY", 70.62554065743944],
  ["TJ", 78.99410683391004],
  ["VU", 64.06114835640139],
  ["ZA", 90.78368295847751],
  ["BG", 99.81617647058823],
]);

export const tvMap = new Map<string, number>([
  ["CY", 85.6643598615917],
  ["AF", 85.94593425605537],
  ["CN", 79.1401384083045],
  ["BJ", 85.3205017301038],
  ["LA", 84.9113321799308],
  ["ME", 84.55276816608996],
  ["OM", 79.64273356401385],
  ["NI", 85.64057093425606],
  ["MR", 90.16911764705883],
  ["TG", 80.84558823529412],
  ["TP", 79.08564013840831],
  ["North Korea", 79.07396193771626],
  ["VN", 76.70242214532873],
  ["ZM", 82.45501730103805],
  ["MD", 83.09861591695503],
  ["CO", 86.60207612456747],
  ["AG", 84.41565743944636],
  ["AE", 86.84861591695501],
  ["CZ", 84.61375432525952],
  ["AR", 86.14489619377163],
  ["BI", 78.21453287197232],
  ["CM", 83.57698961937716],
  ["LB", 80.00432525951557],
  ["LU", 83.15311418685121],
  ["TD", 83.58391003460207],
  ["TR", 77.3970588235294],
  ["Scotland", 80.30233564013841],
  ["UA", 86.57266435986159],
  ["LT", 87.79022491349481],
  ["LC", 85.72621107266436],
  ["MG", 84.28719723183391],
  ["CL", 85.5432525951557],
  ["BH", 80.56660899653978],
  ["AD", 80.65787197231835],
  ["CH", 83.77681660899654],
  ["NO", 79.52032871972318],
  ["MT", 83.77465397923875],
  ["MC", 82.07698961937716],
  ["TV", 0],
  ["TW", 83.05882352941177],
  ["US", 81.02595155709342],
  ["MU", 82.78719723183391],
  ["BZ", 83.9294982698962],
  ["CI", 89.02465397923875],
  ["BM", 65.70198961937717],
  ["CK", 74.00216262975778],
  ["AT", 82.81617647058823],
  ["BO", 80.67603806228374],
  ["LS", 87.1734429065744],
  ["NL", 89.0544982698962],
  ["MW", 84.20804498269896],
  ["YE", 84.64446366782006],
  ["UG", 89.14057093425606],
  ["TT", 83.49351211072664],
  ["NZ", 74.31617647058823],
  ["MA", 77.36332179930795],
  ["MV", 78.95112456747405],
  ["LR", 85.16695501730104],
  ["BY", 75.71453287197232],
  ["BN", 83.96237024221453],
  ["AU", 82.30103806228374],
  ["GE", 80.93685121107266],
  ["GR", 84.1704152249135],
  ["IN", 87.98832179930795],
  ["GBENG", 84.8931660899654],
  ["RS", 83.20588235294117],
  ["PL", 85.32698961937716],
  ["SV", 91.51167820069203],
  ["SA", 88.99048442906575],
  ["HK", 75.85164359861591],
  ["KG", 71.8295847750865],
  ["GD", 74.7932525951557],
  ["GQ", 84.31228373702422],
  ["DJ", 82.16435986159169],
  ["KE", 92.4264705882353],
  ["KR", 87.92387543252596],
  ["SC", 77.69506920415225],
  ["ST", 77.22404844290658],
  ["PY", 83.33434256055364],
  ["SB", 88.52335640138408],
  ["IL", 89.41089965397924],
  ["DK", 83.8083910034602],
  ["DO", 87.82396193771626],
  ["RU", 86.89143598615917],
  ["SG", 84.9446366782007],
  ["PK", 92.14749134948097],
  ["GB", 76.66565743944636],
  ["GU", 85.37846020761246],
  ["GW", 79.25043252595155],
  ["JP", 87.96626297577855],
  ["SR", 87.97837370242215],
  ["SE", 89.76903114186851],
  ["SD", 84.56833910034602],
  ["SS", 85.96712802768167],
  ["PH", 87.04065743944636],
  ["RW", 81.98572664359861],
  ["KU", 86.62240484429066],
  ["HN", 85.93295847750865],
  ["GA", 86.24134948096885],
  ["DZ", 87.21237024221453],
  ["DM", 85.49048442906575],
  ["FR", 83.77681660899654],
  ["GL", 81.59256055363322],
  ["ES", 78.4325259515571],
  ["HT", 86.37673010380624],
  ["PE", 81.64835640138408],
  ["QA", 91.44679930795849],
  ["SI", 82.69593425605535],
  ["PR", 86.53503460207612],
  ["PS", 86.11029411764706],
  ["IQ", 82.96929065743944],
  ["HU", 81.39143598615917],
  ["KN", 84.57050173010381],
  ["KY", 71.62240484429066],
  ["FI", 87.42257785467127],
  ["ER", 67.98832179930795],
  ["GM", 79.83564013840831],
  ["EE", 91.87846020761245],
  ["EG", 83.33520761245676],
  ["ID", 81.96280276816609],
  ["IS", 87.31098615916954],
  ["SK", 83.3196366782007],
  ["RO", 83.24826989619378],
  ["PG", 81.68166089965398],
  ["KM", 79.85640138408304],
  ["IR", 85.10337370242215],
  ["KZ", 73.92603806228374],
  ["IE", 84.33780276816609],
  ["GN", 80.46929065743946],
  ["FJ", 60.91479238754325],
  ["GY", 79.01427335640139],
  ["JM", 81.78157439446366],
  ["KI", 79.24653979238754],
  ["HR", 85.08217993079585],
  ["SO", 85.30017301038062],
  ["PT", 79.67820069204153],
  ["SY", 83.23615916955018],
  ["SN", 84.27854671280276],
  ["KH", 85.90960207612457],
  ["EC", 83.13192041522491],
  ["ET", 82.6574394463668],
  ["DE", 90.2612456747405],
  ["SL", 87.49870242214533],
  ["PW", 83.09385813148789],
  ["SZ", 84.41349480968859],
  ["PA", 84.90484429065745],
  ["SM", 76.48442906574394],
  ["IT", 85.8637543252595],
  ["JO", 83.69896193771626],
  ["GH", 82.98745674740485],
  ["CG", 82.47837370242215],
  ["BT", 81.875],
  ["AO", 81.92733564013841],
  ["ML", 86.84775086505189],
  ["TN", 84.00648788927334],
  ["LI", 83.54801038062284],
  ["MM", 80.16955017301038],
  ["MZ", 83.54022491349481],
  ["NA", 75.36159169550173],
  ["CF", 80.9083044982699],
  ["BB", 85.02941176470588],
  ["CD", 81.82266435986159],
  ["BW", 87.64186851211073],
  ["AL", 74.66998269896193],
  ["NC", 83.76686851211073],
  ["MX", 83.70458477508652],
  ["TZ", 83.95934256055362],
  ["VE", 82.69117647058823],
  ["MN", 78.75216262975778],
  ["MY", 76.97102076124568],
  ["AM", 82.88840830449827],
  ["CR", 83.29152249134948],
  ["BA", 85.32698961937716],
  ["AZ", 82.78200692041523],
  ["CV", 86.09385813148789],
  ["BR", 82.01730103806229],
  ["CA", 82.17387543252596],
  ["BE", 87.6461937716263],
  ["TH", 86.71237024221453],
  ["WS", 80.09429065743944],
  ["UZ", 85.10856401384082],
  ["Wales", 71.48572664359861],
  ["NG", 85.82266435986159],
  ["NP", 78.97231833910034],
  ["MK", 88.6189446366782],
  ["BD", 88.11115916955016],
  ["BS", 86.41695501730104],
  ["CU", 85.05579584775087],
  ["BF", 80.31012110726644],
  ["NR", 88.89446366782006],
  ["NE", 86.99480968858131],
  ["ZW", 82.43339100346022],
  ["VC", 82.125],
  ["UY", 88.86505190311419],
  ["TJ", 89.7742214532872],
  ["VU", 79.85596885813149],
  ["ZA", 83.4969723183391],
  ["BG", 84.48832179930797],
]);

export const twMap = new Map<string, number>([
  ["CY", 91.96792624334502],
  ["AF", 86.4582521750422],
  ["CN", 96.88384625373328],
  ["BJ", 97.85222698350864],
  ["LA", 92.39449422152967],
  ["ME", 84.76366705622647],
  ["OM", 92.6295286326451],
  ["NI", 97.59868848201533],
  ["MR", 91.3180106479678],
  ["TG", 91.97766523828075],
  ["TP", 94.06927671730944],
  ["North Korea", 94.55070770029866],
  ["VN", 96.16510842747695],
  ["ZM", 92.73016491364758],
  ["MD", 90.9057265290222],
  ["CO", 95.91189455914815],
  ["AG", 89.94643552785352],
  ["AE", 96.39657187378262],
  ["CZ", 92.24061810154525],
  ["AR", 98.6414102064667],
  ["BI", 88.00999870146734],
  ["CM", 94.79158550837553],
  ["LB", 92.99214387741853],
  ["LU", 98.33950136345929],
  ["TD", 95.63628100246721],
  ["TR", 24.412738605375925],
  ["Scotland", 88.3745617452279],
  ["UA", 95.09089728606675],
  ["LT", 96.12290611608883],
  ["LC", 99.14361771198546],
  ["MG", 95.08797558758603],
  ["CL", 95.62102324373458],
  ["BH", 92.62563303467081],
  ["AD", 83.36027788598884],
  ["CH", 36.28424879885729],
  ["NO", 92.84183872224386],
  ["MT", 95.60089598753409],
  ["MC", 95.1967276977016],
  ["TV", 82.71523178807946],
  ["TW", 0],
  ["US", 88.77743150240228],
  ["MU", 95.75801843916375],
  ["BZ", 87.34742241267368],
  ["CI", 99.34326710816777],
  ["BM", 86.2573042462018],
  ["CK", 85.49539020906376],
  ["AT", 98.44533177509415],
  ["BO", 97.9716919880535],
  ["LS", 95.24834437086092],
  ["NL", 96.14303337228931],
  ["MW", 88.67452278924814],
  ["YE", 96.14141020646669],
  ["UG", 91.57187378262563],
  ["TT", 90.49409167640566],
  ["NZ", 86.58972860667446],
  ["MA", 94.55038306713413],
  ["MV", 92.80937540579146],
  ["LR", 94.8370341514089],
  ["BY", 93.73522919101416],
  ["BN", 89.6442020516816],
  ["AU", 81.22938579405272],
  ["GE", 68.98065186339436],
  ["GR", 93.72613946240747],
  ["IN", 97.4636410855733],
  ["GBENG", 93.36222568497597],
  ["RS", 93.6832878846903],
  ["PL", 95.82424360472666],
  ["SV", 95.36845864173485],
  ["SA", 88.30898584599403],
  ["HK", 94.81236203090508],
  ["KG", 90.3340475262953],
  ["GD", 86.83450201272562],
  ["GQ", 95.8868978054798],
  ["DJ", 95.25840799896118],
  ["KE", 88.28820932346449],
  ["KR", 93.48526165433061],
  ["SC", 87.9460459680561],
  ["ST", 94.21633554083884],
  ["PY", 94.71789378002858],
  ["SB", 85.87131541358265],
  ["IL", 92.70062329567588],
  ["DK", 95.77522399688351],
  ["DO", 95.56745877158811],
  ["RU", 93.61641345279834],
  ["SG", 98.13757953512531],
  ["PK", 92.64705882352942],
  ["GB", 80.61972471107649],
  ["GU", 87.60031164783794],
  ["GW", 93.32456823789119],
  ["JP", 93.52649006622516],
  ["SR", 93.97480846643293],
  ["SE", 95.72295805739515],
  ["SD", 92.97266588754707],
  ["SS", 89.4419555901831],
  ["PH", 92.85612258148292],
  ["RW", 93.98227502921699],
  ["KU", 94.3409946760161],
  ["HN", 98.0375925204519],
  ["GA", 97.85157771717958],
  ["DZ", 90.38761199844177],
  ["DM", 87.52304895468122],
  ["FR", 96.04661732242565],
  ["GL", 94.08583300870018],
  ["ES", 92.94799376704324],
  ["HT", 91.28879366316063],
  ["PE", 96.04694195559019],
  ["QA", 92.52791845214907],
  ["SI", 94.46273211271263],
  ["PR", 54.29327360083106],
  ["PS", 92.86618620958316],
  ["IQ", 94.53350214257888],
  ["HU", 96.47253603428126],
  ["KN", 93.80664848720946],
  ["KY", 85.52850279184521],
  ["FI", 94.13387871704974],
  ["ER", 82.14257888585898],
  ["GM", 89.26860148032722],
  ["EE", 96.7426308271653],
  ["EG", 96.09011816647188],
  ["ID", 95.30710297363979],
  ["IS", 94.13452798337877],
  ["SK", 92.7788598883262],
  ["RO", 95.62199714322816],
  ["PG", 88.61803661862095],
  ["KM", 92.67043241137515],
  ["IR", 90.96416049863653],
  ["KZ", 90.4837034151409],
  ["IE", 98.73620309050773],
  ["GN", 95.68270354499415],
  ["FJ", 88.81963381379042],
  ["GY", 88.82190624594209],
  ["JM", 91.7763926762758],
  ["KI", 85.34476042072458],
  ["HR", 76.0742111414102],
  ["SO", 96.8896896506947],
  ["PT", 39.33839761069991],
  ["SY", 94.91072587975587],
  ["SN", 96.82054278665109],
  ["KH", 89.13517724970784],
  ["EC", 89.47896377093883],
  ["ET", 87.45260355797949],
  ["DE", 92.89313076223867],
  ["SL", 98.6901051811453],
  ["PW", 99.39455914816257],
  ["SZ", 84.555252564602],
  ["PA", 94.87144526684847],
  ["SM", 92.07992468510582],
  ["IT", 98.57486040773925],
  ["JO", 90.94792884041034],
  ["GH", 95.04999350733671],
  ["CG", 94.89741592001039],
  ["BT", 94.93474873393066],
  ["AO", 92.97039345539541],
  ["ML", 98.23951434878587],
  ["TN", 27.77658745617452],
  ["LI", 93.0174652642514],
  ["MM", 94.62861965978445],
  ["MZ", 89.64679911699778],
  ["NA", 85.44539670172705],
  ["CF", 92.63861836125179],
  ["BB", 94.5026619919491],
  ["CD", 92.26723802103622],
  ["BW", 98.9864952603558],
  ["AL", 88.63686534216335],
  ["NC", 91.20471367354888],
  ["MX", 93.52551616673159],
  ["TZ", 93.95013634592911],
  ["VE", 91.5929749383197],
  ["MN", 93.75827814569536],
  ["MY", 86.73321646539411],
  ["AM", 93.22620438904038],
  ["CR", 91.77931437475652],
  ["BA", 88.93552785352551],
  ["AZ", 92.87949616932866],
  ["CV", 91.35372029606545],
  ["BR", 89.69581872484092],
  ["CA", 47.881119335151276],
  ["BE", 96.23880015582392],
  ["TH", 94.0416828983249],
  ["WS", 93.33755356447215],
  ["UZ", 97.02506168030126],
  ["Wales", 73.66380989481885],
  ["NG", 98.66186209583171],
  ["NP", 86.5929749383197],
  ["MK", 80.69471497208156],
  ["BD", 90.72165952473703],
  ["BS", 95.38501493312556],
  ["CU", 88.81216725100636],
  ["BF", 96.46993896896507],
  ["NR", 92.62855473315153],
  ["NE", 97.15361641345281],
  ["ZW", 93.53265809635111],
  ["VC", 95.44312426957538],
  ["UY", 94.939293598234],
  ["TJ", 95.38501493312556],
  ["VU", 88.99006622516556],
  ["ZA", 92.16400467471757],
  ["BG", 95.10063628100247],
]);

export const usMap = new Map<string, number>([
  ["CY", 64.34521853434111],
  ["AF", 88.39015773907329],
  ["CN", 87.08305948077555],
  ["BJ", 79.16365428853106],
  ["LA", 64.15872494249096],
  ["ME", 84.24745317121261],
  ["OM", 77.94898126848506],
  ["NI", 79.44791324350969],
  ["MR", 85.77965823200788],
  ["TG", 78.67359513637857],
  ["TP", 88.05455142951035],
  ["North Korea", 86.50879066710483],
  ["VN", 84.20760762405521],
  ["ZM", 86.15223463687151],
  ["MD", 64.60893854748603],
  ["CO", 74.20883996056523],
  ["AG", 66.3555701610253],
  ["AE", 74.4783108774236],
  ["CZ", 75.65231679263884],
  ["AR", 85.3265691751561],
  ["BI", 61.272182057180416],
  ["CM", 65.3586099244167],
  ["LB", 73.34373973052908],
  ["LU", 78.74260598093986],
  ["TD", 64.90798554058495],
  ["TR", 85.10844561288202],
  ["Scotland", 72.73660860992442],
  ["UA", 74.945777193559],
  ["LT", 74.42778508051265],
  ["LC", 92.02965823200788],
  ["MG", 86.36091028590207],
  ["CL", 73.58774235951364],
  ["BH", 56.43854748603352],
  ["AD", 66.92449884981926],
  ["CH", 78.9853762734144],
  ["NO", 77.13646072954322],
  ["MT", 60.060384488991126],
  ["MC", 53.05126519881696],
  ["TV", 81.63202431810713],
  ["TW", 87.28434111074598],
  ["US", 0],
  ["MU", 78.82311863292803],
  ["BZ", 74.9617975681893],
  ["CI", 83.18928688793953],
  ["BM", 56.03023332237923],
  ["CK", 84.39615511008873],
  ["AT", 78.67523825172526],
  ["BO", 81.11279986855077],
  ["LS", 80.875369700953],
  ["NL", 69.54157081827145],
  ["MW", 77.4408478475189],
  ["YE", 70.68189286887939],
  ["UG", 80.21812356227407],
  ["TT", 61.23603351955307],
  ["NZ", 85.97395662175484],
  ["MA", 48.25583305948077],
  ["MV", 79.87265856063097],
  ["LR", 52.9625369700953],
  ["BY", 63.072625698324025],
  ["BN", 75.20990798554058],
  ["AU", 84.6705553729872],
  ["GE", 71.35023003614855],
  ["GR", 75.16595465001643],
  ["IN", 84.79009201445942],
  ["GBENG", 63.796828787380875],
  ["RS", 61.73759447913244],
  ["PL", 82.0871672691423],
  ["SV", 80.48307591192902],
  ["SA", 82.01363785737759],
  ["HK", 82.02842589549786],
  ["KG", 80.59686164968781],
  ["GD", 65.3984554715741],
  ["GQ", 78.22543542556687],
  ["DJ", 87.19191587249425],
  ["KE", 88.91225764048636],
  ["KR", 65.48389746960237],
  ["SC", 75.04888268156424],
  ["ST", 78.38440683535984],
  ["PY", 71.33420966151823],
  ["SB", 80.9875123233651],
  ["IL", 72.77645415708183],
  ["DK", 52.037873808741374],
  ["DO", 58.86419651659547],
  ["RU", 81.96968452185344],
  ["SG", 66.42786723628],
  ["PK", 84.02891883010187],
  ["GB", 66.81605323693724],
  ["GU", 77.29789681235623],
  ["GW", 73.45177456457444],
  ["JP", 67.93255011501807],
  ["SR", 63.75657246138678],
  ["SE", 84.05561945448571],
  ["SD", 69.95727900098588],
  ["SS", 86.92244495563588],
  ["PH", 68.86501807426882],
  ["RW", 76.95777193558988],
  ["KU", 68.80791981597109],
  ["HN", 79.34932632270785],
  ["GA", 81.05200460072297],
  ["DZ", 65.49950706539599],
  ["DM", 77.31556030233322],
  ["FR", 76.49277029247453],
  ["GL", 57.798636214262245],
  ["ES", 53.70933289516924],
  ["HT", 68.8621426224121],
  ["PE", 75.58371672691423],
  ["QA", 87.31843575418995],
  ["SI", 82.02513966480447],
  ["PR", 72.92556687479461],
  ["PS", 79.27702924745317],
  ["IQ", 71.5860170883996],
  ["HU", 70.0624383831745],
  ["KN", 68.31334209661519],
  ["KY", 84.33659217877096],
  ["FI", 71.928606638186],
  ["ER", 80.02218205718042],
  ["GM", 80.14664804469274],
  ["EE", 80.02670062438384],
  ["EG", 71.3715905356556],
  ["ID", 53.21639829116004],
  ["IS", 79.26347354584293],
  ["SK", 81.6468123562274],
  ["RO", 66.10170883996057],
  ["PG", 72.49589221163326],
  ["KM", 70.51265198816957],
  ["IR", 76.28861321064738],
  ["KZ", 82.339796253697],
  ["IE", 75.68682221491949],
  ["GN", 74.00673677292146],
  ["FJ", 83.3466151823858],
  ["GY", 75.07763720013145],
  ["JM", 82.20752546828788],
  ["KI", 58.198734801183036],
  ["HR", 77.23956621754847],
  ["SO", 84.61222477817942],
  ["PT", 83.61485376273414],
  ["SY", 70.18936904370688],
  ["SN", 81.09061781137036],
  ["KH", 84.17310220177457],
  ["EC", 85.14829116003943],
  ["ET", 78.00977653631284],
  ["DE", 84.40765691751561],
  ["SL", 78.74794610581662],
  ["PW", 90.78581991455799],
  ["SZ", 80.75747617482747],
  ["PA", 71.41061452513966],
  ["SM", 80.44240880709825],
  ["IT", 60.665050936575746],
  ["JO", 76.98529411764706],
  ["GH", 73.40987512323365],
  ["CG", 76.06022017745646],
  ["BT", 88.02538613210648],
  ["AO", 72.86066381860007],
  ["ML", 65.05874137364444],
  ["TN", 81.52398948406177],
  ["LI", 66.76511666118962],
  ["MM", 80.70243181071312],
  ["MZ", 78.94142293789024],
  ["NA", 72.20670391061452],
  ["CF", 79.67630627670063],
  ["BB", 83.0841275057509],
  ["CD", 82.57147551758133],
  ["BW", 91.71787709497207],
  ["AL", 83.60417351298061],
  ["NC", 83.74301675977654],
  ["MX", 61.16291488662504],
  ["TZ", 81.88177785080512],
  ["VE", 69.94783108774236],
  ["MN", 64.60893854748603],
  ["MY", 73.19709168583634],
  ["AM", 87.09004272099902],
  ["CR", 67.79904699309891],
  ["BA", 78.56022017745646],
  ["AZ", 84.08232007886953],
  ["CV", 73.5372165626027],
  ["BR", 79.72477817942818],
  ["CA", 76.24712454814328],
  ["BE", 86.96105816628328],
  ["TH", 76.43033190930004],
  ["WS", 54.206375287545185],
  ["UZ", 74.6463194216234],
  ["Wales", 71.93641143608282],
  ["NG", 76.13169569503779],
  ["NP", 64.74901413079198],
  ["MK", 75.95752546828788],
  ["BD", 84.82459743674006],
  ["BS", 83.97223135064081],
  ["CU", 73.00073940190602],
  ["BF", 84.7358692080184],
  ["NR", 89.00139664804469],
  ["NE", 81.09061781137036],
  ["ZW", 82.8343739730529],
  ["VC", 78.82270785409136],
  ["UY", 75.42556687479461],
  ["TJ", 77.41702267499178],
  ["VU", 76.75525796910944],
  ["ZA", 86.17277357870522],
  ["BG", 83.19339467630627],
]);

export const muMap = new Map<string, number>([
  ["CY", 95.44247500324633],
  ["AF", 90.4216984807168],
  ["CN", 95.25483703415141],
  ["BJ", 89.0767432800935],
  ["LA", 53.272302298402806],
  ["ME", 88.54337099078042],
  ["OM", 91.38748214517595],
  ["NI", 95.3921568627451],
  ["MR", 93.09667575639527],
  ["TG", 73.66478379431243],
  ["TP", 72.99539020906376],
  ["North Korea", 89.76788728736528],
  ["VN", 71.44948707960005],
  ["ZM", 93.3382028308012],
  ["MD", 82.31625762887937],
  ["CO", 99.33774834437085],
  ["AG", 89.8139851967277],
  ["AE", 92.28217114660434],
  ["CZ", 96.89131281651734],
  ["AR", 93.88423581353071],
  ["BI", 75.00681729645501],
  ["CM", 80.76970523308661],
  ["LB", 70.44799376704324],
  ["LU", 73.50993377483444],
  ["TD", 82.64348785871964],
  ["TR", 94.73477470458383],
  ["Scotland", 90.72068562524348],
  ["UA", 73.73068432671081],
  ["LT", 98.89624724061811],
  ["LC", 95.0201272562005],
  ["MG", 82.49902610050643],
  ["CL", 97.9999350733671],
  ["BH", 79.27379561095962],
  ["AD", 82.56460199974029],
  ["CH", 96.25568108037918],
  ["NO", 65.46779639007922],
  ["MT", 85.69893520322036],
  ["MC", 70.86092715231787],
  ["TV", 83.40637579535125],
  ["TW", 95.75801843916375],
  ["US", 79.51272562004934],
  ["MU", 0],
  ["BZ", 79.0553174912349],
  ["CI", 90.05616153746267],
  ["BM", 73.4514998052201],
  ["CK", 77.10751850409038],
  ["AT", 70.6401766004415],
  ["BO", 57.17439293598234],
  ["LS", 70.49798727437995],
  ["NL", 95.80573951434879],
  ["MW", 91.98675496688742],
  ["YE", 74.17218543046357],
  ["UG", 86.68224905856383],
  ["TT", 77.48766393974809],
  ["NZ", 77.47240618101546],
  ["MA", 96.63582651603689],
  ["MV", 72.0701856901701],
  ["LR", 87.52564601999741],
  ["BY", 92.80937540579146],
  ["BN", 89.0809635112323],
  ["AU", 79.81236203090508],
  ["GE", 92.81456953642385],
  ["GR", 93.05057784703286],
  ["IN", 94.17153616413452],
  ["GBENG", 93.94104661732243],
  ["RS", 88.50506427736657],
  ["PL", 99.33774834437085],
  ["SV", 92.93728087261395],
  ["SA", 91.57771717958707],
  ["HK", 92.73568367744448],
  ["KG", 65.33339825996623],
  ["GD", 71.17971691988053],
  ["GQ", 92.31430982989222],
  ["DJ", 95.80151928320997],
  ["KE", 90.50318140501233],
  ["KR", 95.01103752759383],
  ["SC", 75.41001168679392],
  ["ST", 78.23042462017919],
  ["PY", 71.67640566160239],
  ["SB", 87.47662641215427],
  ["IL", 94.93539800025971],
  ["DK", 96.65011037527593],
  ["DO", 80.06654979872744],
  ["RU", 98.89624724061811],
  ["SG", 70.98883261914037],
  ["PK", 95.65997922347748],
  ["GB", 81.65497987274381],
  ["GU", 75.10290871315414],
  ["GW", 72.91423191793274],
  ["JP", 97.25003246331644],
  ["SR", 96.82865861576418],
  ["SE", 86.71763407349695],
  ["SD", 73.82255551227114],
  ["SS", 89.96656278405402],
  ["PH", 91.89423451499805],
  ["RW", 71.96792624334502],
  ["KU", 96.36410855733021],
  ["HN", 95.40059732502273],
  ["GA", 82.27340605116218],
  ["DZ", 94.66010907674328],
  ["DM", 91.43877418517076],
  ["FR", 89.70588235294117],
  ["GL", 93.99266329048176],
  ["ES", 75.66257628879366],
  ["HT", 96.83742371120634],
  ["PE", 81.66277106869238],
  ["QA", 95.67621088170367],
  ["SI", 96.96045968056096],
  ["PR", 90.28502791845216],
  ["PS", 93.443383976107],
  ["IQ", 70.9057265290222],
  ["HU", 49.22737306843267],
  ["KN", 90.35060381768602],
  ["KY", 75.95312297104272],
  ["FI", 82.9139072847682],
  ["ER", 85.51843916374496],
  ["GM", 59.82339955849889],
  ["EE", 96.46799116997794],
  ["EG", 71.28457343202182],
  ["ID", 70.86092715231787],
  ["IS", 90.77522399688353],
  ["SK", 96.32417867809376],
  ["RO", 74.3543046357616],
  ["PG", 73.56122581482924],
  ["KM", 95.7723022984028],
  ["IR", 93.25801843916375],
  ["KZ", 92.33119075444748],
  ["IE", 89.8464485131801],
  ["GN", 73.77645760290872],
  ["FJ", 83.40864822750292],
  ["GY", 64.71497208154786],
  ["JM", 72.71653032073758],
  ["KI", 62.30944033242436],
  ["HR", 91.78872873652773],
  ["SO", 97.62368523568368],
  ["PT", 91.65108427476952],
  ["SY", 72.28444357875601],
  ["SN", 88.19049474094274],
  ["KH", 93.29048175561616],
  ["EC", 95.06557589923386],
  ["ET", 79.56531619270226],
  ["DE", 88.0794701986755],
  ["SL", 96.46799116997794],
  ["PW", 88.97448383326841],
  ["SZ", 90.03700818075575],
  ["PA", 82.59252045188936],
  ["SM", 92.83631995844695],
  ["IT", 81.70399948058694],
  ["JO", 91.42059472795741],
  ["GH", 57.481495909622126],
  ["CG", 91.49298792364628],
  ["BT", 89.82502272432151],
  ["AO", 69.53480067523698],
  ["ML", 74.02869757174393],
  ["TN", 93.51869887027658],
  ["LI", 74.85261654330606],
  ["MM", 97.4204648746916],
  ["MZ", 84.26697831450461],
  ["NA", 60.445072068562524],
  ["CF", 93.61414102064667],
  ["BB", 80.19023503441112],
  ["CD", 89.50590832359434],
  ["BW", 95.80573951434879],
  ["AL", 68.20575249967537],
  ["NC", 66.7156862745098],
  ["MX", 85.16913387871705],
  ["TZ", 92.07862615244773],
  ["VE", 83.31742630827166],
  ["MN", 91.71503700818076],
  ["MY", 79.77827554863005],
  ["AM", 97.35099337748345],
  ["CR", 96.89423451499806],
  ["BA", 89.52441241397221],
  ["AZ", 71.53583950136347],
  ["CV", 83.58622256849759],
  ["BR", 61.05668095052591],
  ["CA", 93.63556680950525],
  ["BE", 90.21393325542138],
  ["TH", 81.67770419426049],
  ["WS", 72.79022204908453],
  ["UZ", 71.60401246591351],
  ["Wales", 64.44747435398],
  ["NG", 82.20588235294117],
  ["NP", 83.12621737436696],
  ["MK", 78.45896636800416],
  ["BD", 94.33937151019349],
  ["BS", 95.6521880275289],
  ["CU", 90.04512400986884],
  ["BF", 47.70744059213089],
  ["NR", 74.80035060381769],
  ["NE", 96.91176470588235],
  ["ZW", 88.4716270614206],
  ["VC", 82.70322036099208],
  ["UY", 93.14537073107388],
  ["TJ", 92.71653032073756],
  ["VU", 47.543176210881704],
  ["ZA", 74.7386703025581],
  ["BG", 99.33774834437085],
]);

export const bzMap = new Map<string, number>([
  ["CY", 81.80973050012956],
  ["AF", 87.63604560767038],
  ["CN", 73.20743715988598],
  ["BJ", 83.40114019175952],
  ["LA", 73.7830396475771],
  ["ME", 85.47194869137083],
  ["OM", 80.37963202902306],
  ["NI", 82.37010883648614],
  ["MR", 88.41118165327805],
  ["TG", 82.49805649131899],
  ["TP", 70.86162218191241],
  ["North Korea", 70.27986525006479],
  ["VN", 73.59581497797357],
  ["ZM", 83.04126716766001],
  ["MD", 57.26030059600933],
  ["CO", 68.52325732054936],
  ["AG", 78.19188909043794],
  ["AE", 79.97570614148744],
  ["CZ", 61.080914744752526],
  ["AR", 86.67400881057269],
  ["BI", 69.4995465146411],
  ["CM", 82.78310443119979],
  ["LB", 68.77170251360457],
  ["LU", 75.75343353200311],
  ["TD", 79.69908007255766],
  ["TR", 72.5391940917336],
  ["Scotland", 78.05681523710805],
  ["UA", 60.573658979010105],
  ["LT", 83.98872764965016],
  ["LC", 91.80519564654055],
  ["MG", 87.3921352682042],
  ["CL", 68.10572687224669],
  ["BH", 76.95095879761597],
  ["AD", 80.03822233739311],
  ["CH", 82.61175174915782],
  ["NO", 73.86045607670381],
  ["MT", 75.76282715729464],
  ["MC", 75.12891940917335],
  ["TV", 84.67284270536408],
  ["TW", 87.18677118424463],
  ["US", 74.93554029541332],
  ["MU", 79.6090308370044],
  ["BZ", 0],
  ["CI", 82.1809406582016],
  ["BM", 70.03206789323659],
  ["CK", 82.68301373412801],
  ["AT", 68.8941435605079],
  ["BO", 79.04055454781032],
  ["LS", 83.70918631769888],
  ["NL", 82.18547551179061],
  ["MW", 86.37244104690335],
  ["YE", 77.40509199274423],
  ["UG", 81.04593158849443],
  ["TT", 78.15884944286084],
  ["NZ", 82.80383519046384],
  ["MA", 74.95594713656388],
  ["MV", 74.04897641876134],
  ["LR", 69.19927442342576],
  ["BY", 83.03932365897901],
  ["BN", 83.97350349831562],
  ["AU", 83.96832080849961],
  ["GE", 80.79683855921223],
  ["GR", 58.640839595750194],
  ["IN", 85.39971495206012],
  ["GBENG", 83.12580979528376],
  ["RS", 81.7481860585644],
  ["PL", 78.3888313034465],
  ["SV", 48.3580590826639],
  ["SA", 87.17543405027209],
  ["HK", 73.9657294635916],
  ["KG", 73.00822752008293],
  ["GD", 67.56705104949468],
  ["GQ", 72.93826120756673],
  ["DJ", 85.81368230111428],
  ["KE", 88.61201088364862],
  ["KR", 79.69454521896864],
  ["SC", 64.03245659497279],
  ["ST", 81.97104172065302],
  ["PY", 59.10307074371599],
  ["SB", 64.63397253174398],
  ["IL", 61.77831044311998],
  ["DK", 70.16390256543146],
  ["DO", 77.16053381705105],
  ["RU", 65.23062969681264],
  ["SG", 72.35261725835709],
  ["PK", 88.5718450375745],
  ["GB", 73.79470069966312],
  ["GU", 78.8219098211972],
  ["GW", 79.66377299818606],
  ["JP", 84.71819124125422],
  ["SR", 85.18690075149003],
  ["SE", 44.486589790101064],
  ["SD", 75.05441824306816],
  ["SS", 72.62276496501684],
  ["PH", 62.840761855402945],
  ["RW", 81.97913967349054],
  ["KU", 72.84691629955947],
  ["HN", 82.73937548587718],
  ["GA", 84.68288416688262],
  ["DZ", 84.48367452707956],
  ["DM", 85.67245400362789],
  ["FR", 76.80584348276756],
  ["GL", 75.05215081627364],
  ["ES", 70.6073464628142],
  ["HT", 82.34257579683856],
  ["PE", 72.4271184244623],
  ["QA", 87.42549883389479],
  ["SI", 63.77947654832858],
  ["PR", 80.79457113241773],
  ["PS", 82.01509458408914],
  ["IQ", 79.41921482249288],
  ["HU", 75.58078517750712],
  ["KN", 79.62878984192795],
  ["KY", 82.84594454521897],
  ["FI", 72.24054159108577],
  ["ER", 77.21074112464369],
  ["GM", 82.0001943508681],
  ["EE", 89.00686706400623],
  ["EG", 77.65224151334542],
  ["ID", 75.20730759264057],
  ["IS", 47.25641357864732],
  ["SK", 64.48529411764706],
  ["RO", 79.90314848406322],
  ["PG", 80.37768852034206],
  ["KM", 80.80688002073076],
  ["IR", 78.32275200829231],
  ["KZ", 86.4275071261985],
  ["IE", 82.31407100285047],
  ["GN", 79.77746825602487],
  ["FJ", 83.41992744234258],
  ["GY", 79.2857605597305],
  ["JM", 79.59380668566986],
  ["KI", 63.827740347240216],
  ["HR", 83.0027209121534],
  ["SO", 87.93210676341022],
  ["PT", 86.35592122311479],
  ["SY", 78.3583830007774],
  ["SN", 81.21760818864992],
  ["KH", 84.12023840373153],
  ["EC", 67.27131381186837],
  ["ET", 79.3939492096398],
  ["DE", 88.26315107540815],
  ["SL", 82.43618813164032],
  ["PW", 88.62043275459963],
  ["SZ", 81.34652759782327],
  ["PA", 65.26949987043275],
  ["SM", 86.80389997408655],
  ["IT", 76.83370044052863],
  ["JO", 83.94208344130604],
  ["GH", 79.41953874060637],
  ["CG", 79.05545478103136],
  ["BT", 85.5331692148225],
  ["AO", 82.34192796061156],
  ["ML", 79.84030837004406],
  ["TN", 73.31303446488727],
  ["LI", 80.9267297227261],
  ["MM", 78.18541072816791],
  ["MZ", 82.99462295931589],
  ["NA", 67.33318217154704],
  ["CF", 71.73911635138637],
  ["BB", 85.86291785436642],
  ["CD", 84.11732314071003],
  ["BW", 92.43262503239181],
  ["AL", 77.7516843741902],
  ["NC", 68.11026172583571],
  ["MX", 77.11842446229593],
  ["TZ", 85.83246955169733],
  ["VE", 82.19778440010366],
  ["MN", 72.30176211453745],
  ["MY", 67.72123607152112],
  ["AM", 68.6408395957502],
  ["CR", 83.86596268463332],
  ["BA", 84.02727390515678],
  ["AZ", 87.73646022285565],
  ["CV", 46.70380927701477],
  ["BR", 84.01302150816274],
  ["CA", 80.87133972531744],
  ["BE", 80.9306167400881],
  ["TH", 72.66552215599896],
  ["WS", 77.0772868618813],
  ["UZ", 79.3926535371858],
  ["Wales", 83.04936512049754],
  ["NG", 81.4990930292822],
  ["NP", 70.73043534594454],
  ["MK", 77.25965275978233],
  ["BD", 88.41215340761856],
  ["BS", 83.26736201088364],
  ["CU", 80.87976159626847],
  ["BF", 80.55681523710805],
  ["NR", 87.93243068152371],
  ["NE", 84.12250583052604],
  ["ZW", 82.22434568541073],
  ["VC", 83.63630474216119],
  ["UY", 62.711842446229596],
  ["TJ", 80.91150557139156],
  ["VU", 72.45076444674787],
  ["ZA", 84.6751101321586],
  ["BG", 80.00906970717803],
]);

export const ciMap = new Map<string, number>([
  ["CY", 74.91169977924945],
  ["AF", 96.30632385404493],
  ["CN", 96.06349824698091],
  ["BJ", 98.92481495909622],
  ["LA", 91.2358784573432],
  ["ME", 98.30346708219712],
  ["OM", 86.8179457213349],
  ["NI", 89.38059992208804],
  ["MR", 96.88774185170756],
  ["TG", 97.78600181794572],
  ["TP", 93.1106349824698],
  ["North Korea", 93.35670692117907],
  ["VN", 97.3357356187508],
  ["ZM", 95.53207375665498],
  ["MD", 93.47130242825608],
  ["CO", 97.7249707830152],
  ["AG", 87.5350603817686],
  ["AE", 77.71036229061161],
  ["CZ", 84.26373198285937],
  ["AR", 90.8719646799117],
  ["BI", 79.25366835475913],
  ["CM", 98.59368913128165],
  ["LB", 89.61563433320347],
  ["LU", 88.31840020776522],
  ["TD", 96.75918711855603],
  ["TR", 97.24321516686145],
  ["Scotland", 83.62550318140501],
  ["UA", 98.15705752499674],
  ["LT", 99.21276457602909],
  ["LC", 94.91851707570446],
  ["MG", 99.47766523828075],
  ["CL", 82.84573432021816],
  ["BH", 98.1414751330996],
  ["AD", 93.3745617452279],
  ["CH", 84.66270614205948],
  ["NO", 90.13017789897417],
  ["MT", 80.83073626801715],
  ["MC", 83.1213478768991],
  ["TV", 88.82255551227112],
  ["TW", 99.34326710816777],
  ["US", 84.02739903908584],
  ["MU", 90.05616153746267],
  ["BZ", 82.25392806129074],
  ["CI", 0],
  ["BM", 90.17432800934944],
  ["CK", 93.81833528113232],
  ["AT", 88.53135956369303],
  ["BO", 98.16549798727438],
  ["LS", 80.03830671341385],
  ["NL", 88.42520451889365],
  ["MW", 98.90728476821192],
  ["YE", 88.52519153356707],
  ["UG", 93.2359433839761],
  ["TT", 93.52064666926373],
  ["NZ", 95.19737696403064],
  ["MA", 99.69127386053759],
  ["MV", 97.74931827035451],
  ["LR", 84.20399948058693],
  ["BY", 92.10005194130633],
  ["BN", 88.7777561355668],
  ["AU", 95.48532658096352],
  ["GE", 81.58420984287756],
  ["GR", 84.70815478509284],
  ["IN", 90.60089598753409],
  ["GBENG", 82.72432151668615],
  ["RS", 82.29061160888196],
  ["PL", 83.26808206726399],
  ["SV", 90.35871964679912],
  ["SA", 93.22620438904038],
  ["HK", 90.93883911180366],
  ["KG", 91.95623944942216],
  ["GD", 89.03032073756656],
  ["GQ", 86.8504090377873],
  ["DJ", 92.64316322555513],
  ["KE", 95.07336709518245],
  ["KR", 79.15725230489547],
  ["SC", 85.75217504220231],
  ["ST", 94.35625243474874],
  ["PY", 90.60122062069861],
  ["SB", 92.06206986105701],
  ["IL", 80.89793533307362],
  ["DK", 85.97682119205298],
  ["DO", 82.61751720555772],
  ["RU", 88.2047786001818],
  ["SG", 79.09719516945852],
  ["PK", 94.06083625503182],
  ["GB", 85.35742111414102],
  ["GU", 98.02882742500974],
  ["GW", 82.01727048435268],
  ["JP", 83.70568757304247],
  ["SR", 91.87021166082326],
  ["SE", 96.448837813271],
  ["SD", 88.59498766393975],
  ["SS", 94.23256719906506],
  ["PH", 96.16056356317361],
  ["RW", 93.78100246721205],
  ["KU", 88.94234514998051],
  ["HN", 89.60362290611609],
  ["GA", 86.60693416439423],
  ["DZ", 85.36261524477341],
  ["DM", 95.35807038047007],
  ["FR", 66.31736138163875],
  ["GL", 83.08823529411765],
  ["ES", 94.29619529931178],
  ["HT", 97.56525126606934],
  ["PE", 66.17030255810934],
  ["QA", 99.15595377223737],
  ["SI", 87.72334761719257],
  ["PR", 85.45124009868849],
  ["PS", 88.54791585508376],
  ["IQ", 90.52330866121284],
  ["HU", 77.89864952603558],
  ["KN", 90.09122191923127],
  ["KY", 91.03493052850278],
  ["FI", 83.83196987404233],
  ["ER", 87.89637709388391],
  ["GM", 95.82262043890404],
  ["EE", 88.69140371380341],
  ["EG", 90.23243734579924],
  ["ID", 83.26808206726399],
  ["IS", 92.14452668484613],
  ["SK", 86.15179846773147],
  ["RO", 96.61212829502662],
  ["PG", 94.88702765874561],
  ["KM", 84.86884820153226],
  ["IR", 82.74315024022854],
  ["KZ", 90.32820412933386],
  ["IE", 66.30697312037398],
  ["GN", 63.52941176470588],
  ["FJ", 91.32612647708089],
  ["GY", 62.80742760680431],
  ["JM", 90.0753148941696],
  ["KI", 86.54882482794443],
  ["HR", 91.40825866770548],
  ["SO", 95.20451889365017],
  ["PT", 94.99545513569666],
  ["SY", 87.97331515387611],
  ["SN", 94.76886118685886],
  ["KH", 92.57661342682768],
  ["EC", 93.82612647708089],
  ["ET", 96.79879236462797],
  ["DE", 98.40897286066745],
  ["SL", 88.72613946240749],
  ["PW", 95.46195299311778],
  ["SZ", 87.96195299311778],
  ["PA", 82.26366705622647],
  ["SM", 85.59018309310478],
  ["IT", 66.16056356317361],
  ["JO", 91.46182313985197],
  ["GH", 96.88839111803662],
  ["CG", 93.97773016491364],
  ["BT", 87.78243085313595],
  ["AO", 95.98591092065966],
  ["ML", 96.16251136216076],
  ["TN", 90.23665757693806],
  ["LI", 97.67887287365278],
  ["MM", 87.15848591092066],
  ["MZ", 94.46467991169978],
  ["NA", 71.34073496948449],
  ["CF", 93.7413972211401],
  ["BB", 94.79840280483054],
  ["CD", 92.00655758992339],
  ["BW", 96.71763407349695],
  ["AL", 97.62336060251914],
  ["NC", 83.55635631736138],
  ["MX", 70.44929229970134],
  ["TZ", 91.36281002467213],
  ["VE", 97.41754317621088],
  ["MN", 92.27405531749123],
  ["MY", 81.41247889884431],
  ["AM", 99.37215945981042],
  ["CR", 86.58518374237111],
  ["BA", 94.29846773146345],
  ["AZ", 86.25568108037918],
  ["CV", 92.29483184002078],
  ["BR", 91.12388001558239],
  ["CA", 79.18906635501884],
  ["BE", 96.61277756135567],
  ["TH", 88.58492403583949],
  ["WS", 99.50460979093624],
  ["UZ", 85.62199714322816],
  ["Wales", 87.42500973899494],
  ["NG", 33.07914556551097],
  ["NP", 82.63050253213868],
  ["MK", 85.31586806908193],
  ["BD", 98.79496169328658],
  ["BS", 96.4231917932736],
  ["CU", 85.83885209713024],
  ["BF", 81.61634852616542],
  ["NR", 98.7777561355668],
  ["NE", 93.88326191403713],
  ["ZW", 91.8948837813271],
  ["VC", 68.39144266978315],
  ["UY", 76.37936631606284],
  ["TJ", 86.21477730164914],
  ["VU", 79.01019348136606],
  ["ZA", 90.53726788728737],
  ["BG", 77.3477470458382],
]);

export const bmMap = new Map<string, number>([
  ["CY", 84.55406574394463],
  ["AF", 83.81141868512111],
  ["CN", 22.039359861591695],
  ["BJ", 65.9212802768166],
  ["LA", 46.25865051903114],
  ["ME", 78.57698961937716],
  ["OM", 57.700692041522494],
  ["NI", 88.40441176470588],
  ["MR", 85.7136678200692],
  ["TG", 82.10726643598616],
  ["TP", 37.429930795847746],
  ["North Korea", 53.90138408304498],
  ["VN", 26.250865051903116],
  ["ZM", 84.8598615916955],
  ["MD", 63.26211072664359],
  ["CO", 62.876297577854665],
  ["AG", 51.597318339100354],
  ["AE", 74.83044982698962],
  ["CZ", 53.99740484429066],
  ["AR", 91.31833910034602],
  ["BI", 61.76254325259516],
  ["CM", 67.4848615916955],
  ["LB", 45.9666955017301],
  ["LU", 70.68468858131489],
  ["TD", 63.793252595155714],
  ["TR", 25.7166955017301],
  ["Scotland", 82.61548442906575],
  ["UA", 91.73096885813149],
  ["LT", 60.28460207612457],
  ["LC", 95.80320069204153],
  ["MG", 87.6743079584775],
  ["CL", 43.54108996539792],
  ["BH", 39.721453287197235],
  ["AD", 65.56877162629758],
  ["CH", 87.72188581314879],
  ["NO", 42.16046712802768],
  ["MT", 49.54757785467128],
  ["MC", 67.01340830449827],
  ["TV", 65.70198961937717],
  ["TW", 86.10164359861592],
  ["US", 56.313148788927336],
  ["MU", 72.9083044982699],
  ["BZ", 69.60640138408306],
  ["CI", 90.4113321799308],
  ["BM", 0],
  ["CK", 64.23096885813149],
  ["AT", 40.10423875432526],
  ["BO", 69.94896193771626],
  ["LS", 87.09256055363322],
  ["NL", 87.75778546712803],
  ["MW", 68.30968858131487],
  ["YE", 70.0795847750865],
  ["UG", 88.41003460207612],
  ["TT", 39.68555363321799],
  ["NZ", 67.37543252595157],
  ["MA", 21.678633217993077],
  ["MV", 37.21842560553633],
  ["LR", 50.726643598615915],
  ["BY", 51.870674740484425],
  ["BN", 83.07742214532871],
  ["AU", 75.45328719723183],
  ["GE", 82.06574394463668],
  ["GR", 86.50259515570934],
  ["IN", 89.71064013840831],
  ["GBENG", 68.91306228373702],
  ["RS", 84.05622837370242],
  ["PL", 41.58693771626298],
  ["SV", 87.61418685121107],
  ["SA", 83.41392733564014],
  ["HK", 27.591262975778548],
  ["KG", 27.38840830449827],
  ["GD", 54.63278546712803],
  ["GQ", 59.72231833910035],
  ["DJ", 87.48529411764706],
  ["KE", 82.9848615916955],
  ["KR", 82.37024221453288],
  ["SC", 60.17733564013841],
  ["ST", 81.81012110726643],
  ["PY", 67.2629757785467],
  ["SB", 81.39100346020761],
  ["IL", 83.95977508650519],
  ["DK", 33.89576124567474],
  ["DO", 56.70544982698962],
  ["RU", 54.36159169550173],
  ["SG", 68.97664359861592],
  ["PK", 85.96756055363322],
  ["GB", 58.589100346020764],
  ["GU", 69.70025951557093],
  ["GW", 67.93555363321799],
  ["JP", 74.56012110726643],
  ["SR", 71.38581314878893],
  ["SE", 88.14835640138409],
  ["SD", 70.22837370242215],
  ["SS", 76.25043252595157],
  ["PH", 55.6734429065744],
  ["RW", 89.51038062283737],
  ["KU", 63.13927335640138],
  ["HN", 88.35510380622837],
  ["GA", 90.32050173010381],
  ["DZ", 84.65657439446368],
  ["DM", 80.2257785467128],
  ["FR", 63.654411764705884],
  ["GL", 49.003892733564015],
  ["ES", 48.57439446366782],
  ["HT", 46.30839100346021],
  ["PE", 44.9818339100346],
  ["QA", 83.89532871972318],
  ["SI", 53.701989619377166],
  ["PR", 82.40441176470588],
  ["PS", 78.80190311418686],
  ["IQ", 69.0272491349481],
  ["HU", 68.82396193771626],
  ["KN", 65.59342560553634],
  ["KY", 68.35553633217994],
  ["FI", 84.76167820069203],
  ["ER", 59.65268166089965],
  ["GM", 66.24826989619376],
  ["EE", 94.3741349480969],
  ["EG", 70.10553633217992],
  ["ID", 67.11548442906575],
  ["IS", 75.56574394463668],
  ["SK", 50.50043252595155],
  ["RO", 63.88624567474048],
  ["PG", 64.1613321799308],
  ["KM", 72.27249134948097],
  ["IR", 82.13148788927336],
  ["KZ", 83.99567474048443],
  ["IE", 90.29714532871972],
  ["GN", 68.02681660899654],
  ["FJ", 70.94593425605537],
  ["GY", 74.35164359861591],
  ["JM", 87.29108996539793],
  ["KI", 61.52335640138409],
  ["HR", 83.34212802768167],
  ["SO", 89.91349480968857],
  ["PT", 82.53460207612456],
  ["SY", 69.38062283737024],
  ["SN", 66.85769896193771],
  ["KH", 66.1924740484429],
  ["EC", 58.34169550173011],
  ["ET", 54.58693771626297],
  ["DE", 91.62283737024222],
  ["SL", 89.3477508650519],
  ["PW", 93.70717993079585],
  ["SZ", 79.8840830449827],
  ["PA", 68.06920415224913],
  ["SM", 83.00605536332179],
  ["IT", 66.59083044982698],
  ["JO", 73.6712802768166],
  ["GH", 69.44377162629758],
  ["CG", 64.60337370242215],
  ["BT", 90.53546712802768],
  ["AO", 66.97534602076125],
  ["ML", 67.80233564013841],
  ["TN", 27.575692041522494],
  ["LI", 39.6522491349481],
  ["MM", 59.05709342560554],
  ["MZ", 79.86851211072664],
  ["NA", 58.06531141868512],
  ["CF", 70.23918685121107],
  ["BB", 85.30190311418686],
  ["CD", 72.71972318339101],
  ["BW", 93.92690311418686],
  ["AL", 28.560553633217996],
  ["NC", 85.66565743944636],
  ["MX", 63.863754325259514],
  ["TZ", 86.74524221453287],
  ["VE", 52.72664359861592],
  ["MN", 50.65051903114187],
  ["MY", 58.0795847750865],
  ["AM", 70.2128027681661],
  ["CR", 68.63062283737025],
  ["BA", 81.44679930795847],
  ["AZ", 73.78892733564014],
  ["CV", 77.64359861591696],
  ["BR", 82.40397923875432],
  ["CA", 85.32352941176471],
  ["BE", 69.4083044982699],
  ["TH", 62.55795847750865],
  ["WS", 21.650951557093425],
  ["UZ", 86.2954152249135],
  ["Wales", 66.75692041522493],
  ["NG", 88.47102076124568],
  ["NP", 57.49005190311419],
  ["MK", 76.16608996539792],
  ["BD", 85.18814878892734],
  ["BS", 90.07742214532873],
  ["CU", 72.23745674740485],
  ["BF", 66.7288062283737],
  ["NR", 82.83044982698962],
  ["NE", 89.17863321799308],
  ["ZW", 77.74610726643598],
  ["VC", 87.16522491349481],
  ["UY", 85.0371972318339],
  ["TJ", 86.70891003460207],
  ["VU", 68.7348615916955],
  ["ZA", 84.8992214532872],
  ["BG", 55.2318339100346],
]);

export const ckMap = new Map<string, number>([
  ["CY", 86.61937716262976],
  ["AF", 84.42776816608996],
  ["CN", 82.89835640138409],
  ["BJ", 89.06012110726643],
  ["LA", 56.73140138408305],
  ["ME", 83.31185121107266],
  ["OM", 81.58953287197231],
  ["NI", 88.60034602076125],
  ["MR", 86.21626297577855],
  ["TG", 84.20112456747405],
  ["TP", 82.8689446366782],
  ["North Korea", 81.10164359861591],
  ["VN", 80.98096885813149],
  ["ZM", 86.22923875432525],
  ["MD", 86.32612456747405],
  ["CO", 66.2923875432526],
  ["AG", 83.66349480968857],
  ["AE", 86.94809688581316],
  ["CZ", 84.93339100346022],
  ["AR", 94.6470588235294],
  ["BI", 85.530276816609],
  ["CM", 87.34558823529412],
  ["LB", 85.44636678200692],
  ["LU", 87.39662629757785],
  ["TD", 69.37932525951557],
  ["TR", 80.87932525951557],
  ["Scotland", 82.35164359861592],
  ["UA", 89.76124567474048],
  ["LT", 91.24178200692042],
  ["LC", 95.82093425605537],
  ["MG", 89.340830449827],
  ["CL", 88.11721453287197],
  ["BH", 82.76557093425606],
  ["AD", 88.7742214532872],
  ["CH", 86.79974048442907],
  ["NO", 75.21885813148789],
  ["MT", 85.25129757785467],
  ["MC", 83.0735294117647],
  ["TV", 74.00216262975778],
  ["TW", 85.32698961937716],
  ["US", 83.85813148788928],
  ["MU", 76.69982698961938],
  ["BZ", 82.51427335640138],
  ["CI", 93.86332179930797],
  ["BM", 64.23096885813149],
  ["CK", 0],
  ["AT", 86.95588235294117],
  ["BO", 83.93901384083044],
  ["LS", 87.07828719723183],
  ["NL", 88.66955017301038],
  ["MW", 85.28849480968859],
  ["YE", 83.125],
  ["UG", 81.90700692041523],
  ["TT", 83.58996539792388],
  ["NZ", 6.132785467128028],
  ["MA", 79.99307958477509],
  ["MV", 82.38148788927336],
  ["LR", 82.70847750865052],
  ["BY", 83.28806228373702],
  ["BN", 79.7136678200692],
  ["AU", 73.8075259515571],
  ["GE", 82.43209342560553],
  ["GR", 87.17387543252595],
  ["IN", 91.69809688581316],
  ["GBENG", 85.88624567474048],
  ["RS", 67.73832179930795],
  ["PL", 86.1916089965398],
  ["SV", 88.68512110726644],
  ["SA", 83.54757785467129],
  ["HK", 79.99221453287197],
  ["KG", 76.78762975778547],
  ["GD", 79.53762975778547],
  ["GQ", 88.91608996539793],
  ["DJ", 93.08477508650519],
  ["KE", 83.54152249134948],
  ["KR", 79.31271626297578],
  ["SC", 76.30276816608996],
  ["ST", 88.68771626297578],
  ["PY", 82.07266435986159],
  ["SB", 82.0462802768166],
  ["IL", 85.52378892733564],
  ["DK", 83.36548442906575],
  ["DO", 73.7621107266436],
  ["RU", 85.7166955017301],
  ["SG", 87.05709342560553],
  ["PK", 84.81314878892734],
  ["GB", 60.19679930795848],
  ["GU", 33.57006920415225],
  ["GW", 84.71972318339101],
  ["JP", 84.72707612456747],
  ["SR", 88.68685121107266],
  ["SE", 88.40181660899654],
  ["SD", 82.39835640138409],
  ["SS", 85.02595155709342],
  ["PH", 86.40484429065744],
  ["RW", 93.40700692041523],
  ["KU", 87.41306228373702],
  ["HN", 88.34039792387543],
  ["GA", 93.77032871972318],
  ["DZ", 87.33823529411765],
  ["DM", 81.49307958477509],
  ["FR", 71.70501730103807],
  ["GL", 83.25951557093425],
  ["ES", 83.93425605536332],
  ["HT", 86.9113321799308],
  ["PE", 83.51600346020761],
  ["QA", 84.42647058823529],
  ["SI", 84.30190311418684],
  ["PR", 83.69290657439447],
  ["PS", 86.07915224913495],
  ["IQ", 82.11202422145328],
  ["HU", 82.91608996539792],
  ["KN", 83.69031141868513],
  ["KY", 9.789792387543253],
  ["FI", 61.99437716262975],
  ["ER", 81.27249134948097],
  ["GM", 67.49956747404843],
  ["EE", 92.07179930795849],
  ["EG", 83.2590830449827],
  ["ID", 83.2863321799308],
  ["IS", 41.47318339100346],
  ["SK", 81.15354671280276],
  ["RO", 69.44377162629758],
  ["PG", 81.62846020761245],
  ["KM", 88.36764705882352],
  ["IR", 85.83434256055364],
  ["KZ", 94.99005190311419],
  ["IE", 92.58131487889273],
  ["GN", 85.20761245674741],
  ["FJ", 71.2288062283737],
  ["GY", 85.29671280276817],
  ["JM", 84.51730103806229],
  ["KI", 57.6712802768166],
  ["HR", 56.95588235294118],
  ["SO", 95.66522491349481],
  ["PT", 82.84818339100346],
  ["SY", 81.9303633217993],
  ["SN", 88.1500865051903],
  ["KH", 82.16782006920415],
  ["EC", 84.41695501730105],
  ["ET", 81.23529411764706],
  ["DE", 90.00389273356402],
  ["SL", 91.3317474048443],
  ["PW", 96.21972318339101],
  ["SZ", 77.67214532871972],
  ["PA", 84.41782006920415],
  ["SM", 86.50605536332179],
  ["IT", 88.69377162629758],
  ["JO", 84.19290657439447],
  ["GH", 82.10856401384083],
  ["CG", 86.51989619377163],
  ["BT", 96.24437716262976],
  ["AO", 76.7136678200692],
  ["ML", 90.06920415224914],
  ["TN", 79.93901384083046],
  ["LI", 62.98702422145329],
  ["MM", 90.3719723183391],
  ["MZ", 82.55882352941177],
  ["NA", 70.19723183391004],
  ["CF", 77.64359861591696],
  ["BB", 41.63840830449827],
  ["CD", 91.40441176470588],
  ["BW", 95.50173010380622],
  ["AL", 72.89446366782006],
  ["NC", 86.54887543252595],
  ["MX", 87.31271626297577],
  ["TZ", 89.1500865051903],
  ["VE", 65.19723183391002],
  ["MN", 81.36245674740485],
  ["MY", 80.21323529411765],
  ["AM", 83.73053633217992],
  ["CR", 59.975778546712796],
  ["BA", 38.07742214532872],
  ["AZ", 89.93685121107266],
  ["CV", 42.8295847750865],
  ["BR", 80.34299307958477],
  ["CA", 84.49264705882354],
  ["BE", 93.14100346020761],
  ["TH", 86.84515570934256],
  ["WS", 82.280276816609],
  ["UZ", 91.40527681660899],
  ["Wales", 71.36591695501731],
  ["NG", 88.55493079584775],
  ["NP", 71.24524221453287],
  ["MK", 77.63235294117648],
  ["BD", 87.4818339100346],
  ["BS", 92.64489619377163],
  ["CU", 47.943771626297575],
  ["BF", 88.82396193771626],
  ["NR", 29.195934256055363],
  ["NE", 93.8862456747405],
  ["ZW", 84.6461937716263],
  ["VC", 91.51297577854672],
  ["UY", 85.53762975778547],
  ["TJ", 87.41695501730104],
  ["VU", 77.53070934256056],
  ["ZA", 69.77378892733564],
  ["BG", 87.96020761245674],
]);

export const atMap = new Map<string, number>([
  ["CY", 71.78385923905986],
  ["AF", 93.66023893000909],
  ["CN", 97.00201272562005],
  ["BJ", 78.1456953642384],
  ["LA", 43.428450850538894],
  ["ME", 92.8733281392027],
  ["OM", 84.5727827554863],
  ["NI", 67.10459680560966],
  ["MR", 96.61569925983639],
  ["TG", 83.18757304246202],
  ["TP", 41.25211011556941],
  ["North Korea", 69.87241916634204],
  ["VN", 33.17328918322296],
  ["ZM", 95.54798078171666],
  ["MD", 95.96967926243344],
  ["CO", 73.50993377483444],
  ["AG", 63.940072717828855],
  ["AE", 58.91864692897026],
  ["CZ", 61.35112323074925],
  ["AR", 68.70698610570057],
  ["BI", 56.51376444617582],
  ["CM", 75.68919620828464],
  ["LB", 22.124724061810156],
  ["LU", 33.11258278145696],
  ["TD", 97.68504090377873],
  ["TR", 93.72743799506557],
  ["Scotland", 81.36216075834307],
  ["UA", 96.68874172185431],
  ["LT", 99.55849889624724],
  ["LC", 97.23282690559668],
  ["MG", 66.77801584209843],
  ["CL", 55.02434748733931],
  ["BH", 45.61290741462148],
  ["AD", 75.34670821971173],
  ["CH", 84.21471237501623],
  ["NO", 33.587521101155694],
  ["MT", 50.25094143617712],
  ["MC", 50.993377483443716],
  ["TV", 83.2440592130892],
  ["TW", 98.44533177509415],
  ["US", 79.61271263472275],
  ["MU", 70.6401766004415],
  ["BZ", 68.75438254772106],
  ["CI", 88.53135956369303],
  ["BM", 40.477860018179456],
  ["CK", 87.41754317621087],
  ["AT", 0],
  ["BO", 60.9271523178808],
  ["LS", 69.61108946890015],
  ["NL", 66.22516556291392],
  ["MW", 94.28872873652773],
  ["YE", 33.554083885209714],
  ["UG", 93.72743799506557],
  ["TT", 51.81664718867679],
  ["NZ", 89.34034540968705],
  ["MA", 99.7438644331905],
  ["MV", 42.40390858330087],
  ["LR", 85.25159070250616],
  ["BY", 93.30444098169069],
  ["BN", 81.60336319958446],
  ["AU", 93.07038047006883],
  ["GE", 84.7789248149591],
  ["GR", 79.1887417218543],
  ["IN", 68.4151408907934],
  ["GBENG", 81.29333852746396],
  ["RS", 90.07369172834697],
  ["PL", 50.331125827814574],
  ["SV", 68.19893520322036],
  ["SA", 92.13251525775873],
  ["HK", 91.86891312816518],
  ["KG", 30.759966238150888],
  ["GD", 61.437151019348136],
  ["GQ", 72.17569146864044],
  ["DJ", 83.59531229710427],
  ["KE", 93.93780028567718],
  ["KR", 76.31508894948708],
  ["SC", 67.20718088559927],
  ["ST", 85.3207375665498],
  ["PY", 36.46571873782626],
  ["SB", 89.3127515907025],
  ["IL", 69.82567199065056],
  ["DK", 83.22685365536944],
  ["DO", 50.26749772756784],
  ["RU", 66.66666666666666],
  ["SG", 51.42611349175432],
  ["PK", 86.90105181145306],
  ["GB", 71.30534995455136],
  ["GU", 94.98669004025452],
  ["GW", 77.06531619270226],
  ["JP", 78.10414231917933],
  ["SR", 98.30314244903259],
  ["SE", 98.62550318140502],
  ["SD", 45.094792884041034],
  ["SS", 93.62420464874691],
  ["PH", 59.62602259446825],
  ["RW", 92.76684846123881],
  ["KU", 46.6819244253993],
  ["HN", 67.54317621088171],
  ["GA", 93.34794182573691],
  ["DZ", 81.69653291780288],
  ["DM", 90.20549279314375],
  ["FR", 66.61082976236852],
  ["GL", 50.754772107518505],
  ["ES", 91.9864303337229],
  ["HT", 64.75003246331646],
  ["PE", 45.06232956758863],
  ["QA", 87.24743539800026],
  ["SI", 64.74808466432931],
  ["PR", 84.74711076483572],
  ["PS", 67.55161667315933],
  ["IQ", 36.898130112972346],
  ["HU", 33.99558498896248],
  ["KN", 74.06245942085444],
  ["KY", 86.56278405401896],
  ["FI", 95.19218283339826],
  ["ER", 86.18491105051292],
  ["GM", 57.17439293598234],
  ["EE", 99.77924944812362],
  ["EG", 35.24996753668355],
  ["ID", 50.993377483443716],
  ["IS", 83.90079210492144],
  ["SK", 63.16290092195819],
  ["RO", 76.29431242695753],
  ["PG", 66.70335021425788],
  ["KM", 78.26873133359304],
  ["IR", 58.7965848591092],
  ["KZ", 91.75918711855603],
  ["IE", 88.63751460849241],
  ["GN", 75.9946760161018],
  ["FJ", 85.68952084144917],
  ["GY", 83.16225165562913],
  ["JM", 91.3504739644202],
  ["KI", 54.51272562004934],
  ["HR", 65.2467212050383],
  ["SO", 95.11394624074796],
  ["PT", 93.05090248019738],
  ["SY", 35.80087001688093],
  ["SN", 74.41468640436307],
  ["KH", 92.21627061420594],
  ["EC", 68.38495000649266],
  ["ET", 59.788988443059345],
  ["DE", 99.77924944812362],
  ["SL", 66.66666666666666],
  ["PW", 95.68530061031035],
  ["SZ", 88.81379041682898],
  ["PA", 65.26717309440332],
  ["SM", 84.52798337878198],
  ["IT", 67.23380080509025],
  ["JO", 68.05707051032334],
  ["GH", 60.62491884170886],
  ["CG", 73.54531878976756],
  ["BT", 62.80807687313336],
  ["AO", 65.4216984807168],
  ["ML", 76.17874302038696],
  ["TN", 91.67283469679262],
  ["LI", 64.89416958836514],
  ["MM", 60.81028437865213],
  ["MZ", 86.3855343461888],
  ["NA", 74.30496039475393],
  ["CF", 73.36612128295027],
  ["BB", 96.55012336060253],
  ["CD", 83.52551616673159],
  ["BW", 91.83222958057395],
  ["AL", 37.15199324763018],
  ["NC", 93.92059472795741],
  ["MX", 71.02291910141541],
  ["TZ", 93.53525516166732],
  ["VE", 65.25710946630308],
  ["MN", 94.88605375925204],
  ["MY", 66.17095182443839],
  ["AM", 99.77924944812362],
  ["CR", 97.52889235164265],
  ["BA", 92.52759381898454],
  ["AZ", 97.75938189845475],
  ["CV", 89.15303207375665],
  ["BR", 92.59057265290222],
  ["CA", 90.98948188546942],
  ["BE", 75.87618491105052],
  ["TH", 66.88741721854305],
  ["WS", 48.35670692117907],
  ["UZ", 62.860018179457214],
  ["Wales", 79.9285807038047],
  ["NG", 88.63783924165692],
  ["NP", 57.98467731463446],
  ["MK", 85.07077002986625],
  ["BD", 89.59615634333204],
  ["BS", 92.7587326321257],
  ["CU", 78.83456693935852],
  ["BF", 65.75185040903779],
  ["NR", 97.82528243085314],
  ["NE", 71.86566679651993],
  ["ZW", 90.33209972730815],
  ["VC", 94.53999480586937],
  ["UY", 84.1374496818595],
  ["TJ", 66.51279054668225],
  ["VU", 68.4596156343332],
  ["ZA", 68.58946890014283],
  ["BG", 99.77924944812362],
]);

export const boMap = new Map<string, number>([
  ["CY", 93.81495362723923],
  ["AF", 92.30688603735231],
  ["CN", 67.58035827722017],
  ["BJ", 75.86869521026554],
  ["LA", 71.04561046880956],
  ["ME", 88.09617583534494],
  ["OM", 91.96798373777156],
  ["NI", 90.95889975860754],
  ["MR", 63.040909668403],
  ["TG", 57.19794181171388],
  ["TP", 68.35916656079279],
  ["North Korea", 82.93609452420277],
  ["VN", 60.13435395756574],
  ["ZM", 94.18847668657095],
  ["MD", 91.28763816541735],
  ["CO", 82.07343412526998],
  ["AG", 89.98189556600178],
  ["AE", 87.2392326260958],
  ["CZ", 95.98526235548215],
  ["AR", 92.65341125651125],
  ["BI", 75.89315207724559],
  ["CM", 75.47833820353195],
  ["LB", 70.50755939524838],
  ["LU", 63.93088552915766],
  ["TD", 77.17666116122474],
  ["TR", 94.80783890229958],
  ["Scotland", 90.7486342269089],
  ["UA", 82.72138228941685],
  ["LT", 99.35205183585313],
  ["LC", 96.09071274298057],
  ["MG", 76.77804599161479],
  ["CL", 97.20842332613391],
  ["BH", 74.92599415576166],
  ["AD", 79.37079151314954],
  ["CH", 95.80548850209631],
  ["NO", 64.00330326515056],
  ["MT", 81.93844492440606],
  ["MC", 63.498920086393085],
  ["TV", 80.27855418625333],
  ["TW", 97.71344174818955],
  ["US", 81.05069241519503],
  ["MU", 57.01943844492441],
  ["BZ", 78.04599161478846],
  ["CI", 97.6095794689366],
  ["BM", 69.81323847033414],
  ["CK", 83.35726083089824],
  ["AT", 60.475161987041034],
  ["BO", 0],
  ["LS", 68.74857070257909],
  ["NL", 93.30453563714903],
  ["MW", 93.90992250031762],
  ["YE", 63.93088552915766],
  ["UG", 96.27398043450641],
  ["TT", 72.75282683267692],
  ["NZ", 84.56708169228816],
  ["MA", 66.30669546436285],
  ["MV", 64.30282048024394],
  ["LR", 89.52801422944988],
  ["BY", 92.29195781984501],
  ["BN", 86.98005336043705],
  ["AU", 91.15646042434253],
  ["GE", 93.86259687460297],
  ["GR", 89.58582136958455],
  ["IN", 90.81025282683267],
  ["GBENG", 92.40249015372888],
  ["RS", 61.81203150806759],
  ["PL", 98.27213822894169],
  ["SV", 88.48907381527124],
  ["SA", 60.2305933172405],
  ["HK", 60.413543387117265],
  ["KG", 59.32187777918942],
  ["GD", 72.0311904459408],
  ["GQ", 88.33407445051455],
  ["DJ", 94.66999110659383],
  ["KE", 93.59420658112056],
  ["KR", 95.61872697243045],
  ["SC", 61.4902807775378],
  ["ST", 70.6282556219032],
  ["PY", 61.44898996315589],
  ["SB", 87.62196671325117],
  ["IL", 96.30351924787193],
  ["DK", 67.90528522424088],
  ["DO", 80.04287892262737],
  ["RU", 99.35205183585313],
  ["SG", 64.32124253589124],
  ["PK", 95.04637276076737],
  ["GB", 74.97204929487994],
  ["GU", 86.82156015754033],
  ["GW", 76.8193368059967],
  ["JP", 96.96480752128065],
  ["SR", 95.33699656968619],
  ["SE", 76.94511497903697],
  ["SD", 61.339728115868375],
  ["SS", 68.08569432092492],
  ["PH", 89.03125397027061],
  ["RW", 77.56130097827469],
  ["KU", 93.42904332359294],
  ["HN", 90.26299072544785],
  ["GA", 65.3703468428408],
  ["DZ", 79.17100749587091],
  ["DM", 60.25600304916783],
  ["FR", 87.08296277474273],
  ["GL", 91.54554694447974],
  ["ES", 93.57864312031509],
  ["HT", 96.49186888578325],
  ["PE", 76.60430694956169],
  ["QA", 95.87822385973827],
  ["SI", 97.32149663321053],
  ["PR", 91.17361199339346],
  ["PS", 63.577690255367806],
  ["IQ", 59.014737644517844],
  ["HU", 28.725701943844495],
  ["KN", 91.28446194892645],
  ["KY", 84.11383559903443],
  ["FI", 96.64877398043451],
  ["ER", 85.05844238343285],
  ["GM", 59.827213822894166],
  ["EE", 98.92008639308855],
  ["EG", 59.613136831406436],
  ["ID", 63.498920086393085],
  ["IS", 88.78319146233007],
  ["SK", 94.251048151442],
  ["RO", 76.80536145343667],
  ["PG", 70.76991487739804],
  ["KM", 91.52680726718333],
  ["IR", 85.73688222589251],
  ["KZ", 87.40407826197433],
  ["IE", 97.42694702070894],
  ["GN", 76.66465506288908],
  ["FJ", 82.07788082835727],
  ["GY", 77.87225257273536],
  ["JM", 84.61154872316096],
  ["KI", 55.4764324736374],
  ["HR", 85.61015118790498],
  ["SO", 95.44498793037734],
  ["PT", 91.55634608054885],
  ["SY", 61.54427645788337],
  ["SN", 75.34716046245713],
  ["KH", 92.67437428535129],
  ["EC", 80.23758099352052],
  ["ET", 68.28420785160716],
  ["DE", 99.78401727861771],
  ["SL", 94.16846652267819],
  ["PW", 85.1175200101639],
  ["SZ", 91.3311523313429],
  ["PA", 78.77143946131369],
  ["SM", 91.66211408969636],
  ["IT", 76.1799644263753],
  ["JO", 63.11110405285224],
  ["GH", 2.6353068225130225],
  ["CG", 88.69902172532079],
  ["BT", 84.92472366916529],
  ["AO", 64.22341506797103],
  ["ML", 76.56746283826705],
  ["TN", 93.22068352178886],
  ["LI", 96.73326133909286],
  ["MM", 96.45438953119044],
  ["MZ", 90.52820480243933],
  ["NA", 60.69146233007242],
  ["CF", 90.59998729513403],
  ["BB", 96.20442129335535],
  ["CD", 85.62889086520137],
  ["BW", 94.38444924406048],
  ["AL", 68.19527378986152],
  ["NC", 59.26788209884386],
  ["MX", 71.53252445686698],
  ["TZ", 91.34353957565747],
  ["VE", 97.87574641087537],
  ["MN", 72.66039893279125],
  ["MY", 79.02267818574515],
  ["AM", 98.92008639308855],
  ["CR", 96.91081184093507],
  ["BA", 83.72729005208996],
  ["AZ", 96.85395756574768],
  ["CV", 91.33686952102656],
  ["BR", 82.99866598907381],
  ["CA", 93.3204167196036],
  ["BE", 88.05170880447211],
  ["TH", 83.15334773218143],
  ["WS", 79.97967221445815],
  ["UZ", 90.44212933553551],
  ["Wales", 85.15595222970398],
  ["NG", 76.41309871680853],
  ["NP", 82.55621903188921],
  ["MK", 75.1286367678821],
  ["BD", 66.97592427899886],
  ["BS", 95.32302121712615],
  ["CU", 88.48939143692034],
  ["BF", 63.81336551899377],
  ["NR", 90.29697624190065],
  ["NE", 93.4004573751747],
  ["ZW", 88.84671579214839],
  ["VC", 85.64000762291958],
  ["UY", 91.70054630923644],
  ["TJ", 92.69152585440223],
  ["VU", 39.13861008766358],
  ["ZA", 63.52909414305679],
  ["BG", 99.13606911447084],
]);

export const lsMap = new Map<string, number>([
  ["CY", 69.24425399298792],
  ["AF", 94.17121153097],
  ["CN", 96.91923126866641],
  ["BJ", 85.70964809764966],
  ["LA", 93.22133489157252],
  ["ME", 94.01376444617581],
  ["OM", 94.61628359953252],
  ["NI", 69.58868978054798],
  ["MR", 96.88611868588495],
  ["TG", 92.52661991949097],
  ["TP", 93.20770029866252],
  ["North Korea", 94.05239579275418],
  ["VN", 97.22958057395144],
  ["ZM", 96.59005323983898],
  ["MD", 95.7843137254902],
  ["CO", 96.34690299961044],
  ["AG", 90.37560057135437],
  ["AE", 77.85514868198935],
  ["CZ", 86.38196338137905],
  ["AR", 70.35936891312816],
  ["BI", 82.62108817036749],
  ["CM", 87.34482534735749],
  ["LB", 66.12225684975978],
  ["LU", 69.40202571094663],
  ["TD", 96.93416439423451],
  ["TR", 94.26243345020127],
  ["Scotland", 81.85008440462278],
  ["UA", 96.33651473834567],
  ["LT", 99.40105181145306],
  ["LC", 95.85151279054668],
  ["MG", 66.3731982859369],
  ["CL", 78.2034800675237],
  ["BH", 84.55362939877938],
  ["AD", 86.65465523957927],
  ["CH", 86.22841189455914],
  ["NO", 87.5876509544215],
  ["MT", 81.04337099078042],
  ["MC", 82.29028697571744],
  ["TV", 87.4110505129204],
  ["TW", 95.24834437086092],
  ["US", 81.32288014543566],
  ["MU", 70.49798727437995],
  ["BZ", 83.79561095961563],
  ["CI", 80.03830671341385],
  ["BM", 87.39741592001039],
  ["CK", 87.0890144137125],
  ["AT", 69.61108946890015],
  ["BO", 69.06765355148681],
  ["LS", 0],
  ["NL", 69.60427217244514],
  ["MW", 91.83125568108038],
  ["YE", 69.49162446435528],
  ["UG", 87.40325931697183],
  ["TT", 90.8193741072588],
  ["NZ", 88.40053239838981],
  ["MA", 98.73880015582391],
  ["MV", 94.6117387352292],
  ["LR", 79.8354109855863],
  ["BY", 94.278989741592],
  ["BN", 78.37586027788599],
  ["AU", 87.78243085313595],
  ["GE", 79.23743669653291],
  ["GR", 80.36293987793793],
  ["IN", 69.92143877418518],
  ["GBENG", 82.60193481366056],
  ["RS", 89.54226723802104],
  ["PL", 80.68659914296845],
  ["SV", 70.07596416049864],
  ["SA", 92.30099987014674],
  ["HK", 93.30898584599403],
  ["KG", 91.67932736008311],
  ["GD", 87.09063757953513],
  ["GQ", 75.12628230099988],
  ["DJ", 59.503311258278146],
  ["KE", 86.7520451889365],
  ["KR", 70.85638228801454],
  ["SC", 59.7669133878717],
  ["ST", 64.55005843396961],
  ["PY", 70.03148941695883],
  ["SB", 89.83119075444748],
  ["IL", 49.553304765614854],
  ["DK", 83.58946890014283],
  ["DO", 78.58330087001688],
  ["RU", 95.60544085183741],
  ["SG", 80.55317491234905],
  ["PK", 85.80801194650046],
  ["GB", 73.05739514348785],
  ["GU", 94.67958706661473],
  ["GW", 77.8476821192053],
  ["JP", 72.60550577847033],
  ["SR", 98.34988962472406],
  ["SE", 97.83274899363718],
  ["SD", 76.7156862745098],
  ["SS", 63.26126477080899],
  ["PH", 59.45753798208025],
  ["RW", 95.73237241916634],
  ["KU", 75.80736268017141],
  ["HN", 70.32560706401766],
  ["GA", 98.31710167510714],
  ["DZ", 80.67718478119724],
  ["DM", 90.22886638098949],
  ["FR", 79.83606025191533],
  ["GL", 80.86612128295026],
  ["ES", 91.89131281651733],
  ["HT", 67.30359693546293],
  ["PE", 89.75068172964549],
  ["QA", 84.30560966108298],
  ["SI", 94.67893780028568],
  ["PR", 82.1078431372549],
  ["PS", 49.85488897545773],
  ["IQ", 71.99454616283599],
  ["HU", 39.783794312426956],
  ["KN", 88.24827944422802],
  ["KY", 88.16939358524867],
  ["FI", 85.06070640176601],
  ["ER", 88.68556031684197],
  ["GM", 89.60946630307753],
  ["EE", 93.83229450720685],
  ["EG", 70.21847811972471],
  ["ID", 82.29093624204648],
  ["IS", 63.79074146214777],
  ["SK", 93.88423581353071],
  ["RO", 96.82249058563823],
  ["PG", 90.6103103493053],
  ["KM", 83.12102324373458],
  ["IR", 64.36826386183613],
  ["KZ", 95.23276197896378],
  ["IE", 79.8516426438125],
  ["GN", 87.33638488507987],
  ["FJ", 87.9973380080509],
  ["GY", 68.41481625762887],
  ["JM", 76.98578106739384],
  ["KI", 81.88287235423971],
  ["HR", 68.59498766393975],
  ["SO", 97.4474094273471],
  ["PT", 94.6078431372549],
  ["SY", 71.2965848591092],
  ["SN", 86.56278405401896],
  ["KH", 68.76509544215037],
  ["EC", 93.13141150499935],
  ["ET", 92.64900662251657],
  ["DE", 96.4056616023893],
  ["SL", 69.6117387352292],
  ["PW", 96.14465653811195],
  ["SZ", 88.43786521231009],
  ["PA", 80.21360862225684],
  ["SM", 81.97506817296455],
  ["IT", 79.7600960914167],
  ["JO", 55.19023503441112],
  ["GH", 96.63128165173353],
  ["CG", 91.35404492922997],
  ["BT", 91.23880015582392],
  ["AO", 95.61550447993767],
  ["ML", 96.97409427347098],
  ["TN", 92.62108817036749],
  ["LI", 70.7034800675237],
  ["MM", 91.97019867549669],
  ["MZ", 87.90416828983248],
  ["NA", 56.038176860148035],
  ["CF", 62.104921438774184],
  ["BB", 93.60862225684976],
  ["CD", 94.74808466432931],
  ["BW", 91.26217374366965],
  ["AL", 92.38767692507467],
  ["NC", 40.50188287235424],
  ["MX", 91.16640696013505],
  ["TZ", 90.79600051941307],
  ["VE", 95.71743929359823],
  ["MN", 94.24457862615245],
  ["MY", 70.8881963381379],
  ["AM", 98.63881314115051],
  ["CR", 90.65803142449033],
  ["BA", 77.33670951824439],
  ["AZ", 98.167121153097],
  ["CV", 57.176990001298535],
  ["BR", 65.6820542786651],
  ["CA", 88.67549668874172],
  ["BE", 97.08349564991559],
  ["TH", 92.78080768731334],
  ["WS", 96.16186209583171],
  ["UZ", 70.32560706401766],
  ["Wales", 55.24509803921569],
  ["NG", 69.94546162835995],
  ["NP", 74.2627580833658],
  ["MK", 85.21133619010519],
  ["BD", 96.44851318010647],
  ["BS", 96.05083755356448],
  ["CU", 65.33631995844695],
  ["BF", 69.19653291780288],
  ["NR", 67.71523178807946],
  ["NE", 42.73243734579925],
  ["ZW", 88.30346708219712],
  ["VC", 87.60063628100247],
  ["UY", 71.72152967147125],
  ["TJ", 62.71166082326971],
  ["VU", 66.38878067783405],
  ["ZA", 84.77470458382028],
  ["BG", 96.29496169328658],
]);

export const nlMap = new Map<string, number>([
  ["CY", 71.85527853525517],
  ["AF", 93.4622126996494],
  ["CN", 96.90267497727568],
  ["BJ", 97.82398389819504],
  ["LA", 89.91527074405921],
  ["ME", 72.3302168549539],
  ["OM", 96.50305155174652],
  ["NI", 67.10394753928061],
  ["MR", 96.39884430593429],
  ["TG", 92.84183872224386],
  ["TP", 93.83846253733282],
  ["North Korea", 78.58881963381378],
  ["VN", 96.8598233995585],
  ["ZM", 96.34008570315544],
  ["MD", 85.44409816906895],
  ["CO", 96.46799116997794],
  ["AG", 93.98357356187508],
  ["AE", 73.56219971432282],
  ["CZ", 83.0187637969095],
  ["AR", 68.92773665757694],
  ["BI", 81.69783145046098],
  ["CM", 96.19042981430982],
  ["LB", 71.17549668874173],
  ["LU", 66.22516556291392],
  ["TD", 86.66147253603428],
  ["TR", 94.32086741981561],
  ["Scotland", 81.36118685884949],
  ["UA", 96.90949227373068],
  ["LT", 99.77924944812362],
  ["LC", 97.13900792104921],
  ["MG", 88.5174003376185],
  ["CL", 87.7210751850409],
  ["BH", 86.70172704843527],
  ["AD", 96.09823399558499],
  ["CH", 84.00337618491105],
  ["NO", 86.27450980392157],
  ["MT", 81.9218283339826],
  ["MC", 82.560706401766],
  ["TV", 89.39033891702375],
  ["TW", 96.14303337228931],
  ["US", 70.006817296455],
  ["MU", 95.80573951434879],
  ["BZ", 82.129593559278],
  ["CI", 88.42520451889365],
  ["BM", 87.87819763667056],
  ["CK", 88.89429944163095],
  ["AT", 66.22516556291392],
  ["BO", 94.26048565121413],
  ["LS", 69.60427217244514],
  ["NL", 0],
  ["MW", 95.15939488378132],
  ["YE", 65.78366445916114],
  ["UG", 93.72678872873654],
  ["TT", 92.17536683547591],
  ["NZ", 90.03798208024932],
  ["MA", 66.44591611479028],
  ["MV", 96.6656927671731],
  ["LR", 71.12030905077262],
  ["BY", 64.70393455395403],
  ["BN", 81.55629139072848],
  ["AU", 89.08972860667446],
  ["GE", 84.68932606155045],
  ["GR", 78.36190105181146],
  ["IN", 68.4151408907934],
  ["GBENG", 87.69315673289184],
  ["RS", 62.425009738994945],
  ["PL", 83.22295805739515],
  ["SV", 67.55875860277885],
  ["SA", 92.18218413193092],
  ["HK", 91.72055577197766],
  ["KG", 90.52882742500974],
  ["GD", 87.42273730684327],
  ["GQ", 71.79067653551486],
  ["DJ", 84.00240228541747],
  ["KE", 93.5904427996364],
  ["KR", 74.72178937800285],
  ["SC", 83.44046227762628],
  ["ST", 90.13959226074536],
  ["PY", 67.37079600051942],
  ["SB", 89.34359174133229],
  ["IL", 69.83216465394105],
  ["DK", 53.75373328139202],
  ["DO", 77.07473055447345],
  ["RU", 99.55849889624724],
  ["SG", 79.5201921828334],
  ["PK", 86.71536164134528],
  ["GB", 73.38624853915076],
  ["GU", 89.19977924944811],
  ["GW", 97.30262303596936],
  ["JP", 75.44864303337229],
  ["SR", 95.26619919490975],
  ["SE", 97.96162835995325],
  ["SD", 72.63894299441631],
  ["SS", 93.36417348396311],
  ["PH", 86.63972211401116],
  ["RW", 93.44922737306844],
  ["KU", 72.6454356577068],
  ["HN", 67.12504869497468],
  ["GA", 93.95727827554863],
  ["DZ", 82.0753798208025],
  ["DM", 90.24152707440592],
  ["FR", 88.40085703155435],
  ["GL", 81.41604986365407],
  ["ES", 68.52876249837684],
  ["HT", 97.64186469289703],
  ["PE", 88.52194520192182],
  ["QA", 86.94844825347357],
  ["SI", 97.67270484352682],
  ["PR", 84.90358395013634],
  ["PS", 72.83664459161147],
  ["IQ", 68.46545903129464],
  ["HU", 65.34216335540839],
  ["KN", 91.91436177119854],
  ["KY", 89.97922347747046],
  ["FI", 94.70003895597975],
  ["ER", 86.37384755226594],
  ["GM", 88.0794701986755],
  ["EE", 99.77924944812362],
  ["EG", 67.43994286456304],
  ["ID", 82.560706401766],
  ["IS", 88.01714063108687],
  ["SK", 95.68432671081678],
  ["RO", 97.10492143877418],
  ["PG", 93.04733151538761],
  ["KM", 83.22263342423062],
  ["IR", 59.56986105700558],
  ["KZ", 92.68276847162707],
  ["IE", 88.52843786521231],
  ["GN", 97.3019737696403],
  ["FJ", 88.20640176600442],
  ["GY", 88.26386183612517],
  ["JM", 91.35469419555902],
  ["KI", 83.15932995714842],
  ["HR", 64.49552006232958],
  ["SO", 94.93474873393066],
  ["PT", 93.11128424879885],
  ["SY", 67.5889494870796],
  ["SN", 96.22678872873652],
  ["KH", 67.84930528502791],
  ["EC", 85.99045578496299],
  ["ET", 87.76619919490976],
  ["DE", 100],
  ["SL", 66.22516556291392],
  ["PW", 97.51298532658096],
  ["SZ", 83.37131541358265],
  ["PA", 81.26996493961823],
  ["SM", 84.60135047396442],
  ["IT", 88.09115699259836],
  ["JO", 76.32774964290351],
  ["GH", 92.58278145695364],
  ["CG", 94.11472536034282],
  ["BT", 90.63173613816387],
  ["AO", 95.88722243864434],
  ["ML", 97.06239449422152],
  ["TN", 91.53129463706013],
  ["LI", 97.50032463316452],
  ["MM", 90.92001038826126],
  ["MZ", 93.76249837683417],
  ["NA", 89.18095052590573],
  ["CF", 83.45929100116868],
  ["BB", 96.41345279833787],
  ["CD", 93.5904427996364],
  ["BW", 91.16997792494482],
  ["AL", 95.43857940527204],
  ["NC", 93.52584079989612],
  ["MX", 91.74620179197507],
  ["TZ", 93.34826645890143],
  ["VE", 98.14894169588365],
  ["MN", 74.53544994156603],
  ["MY", 82.44708479418257],
  ["AM", 99.33774834437085],
  ["CR", 97.08739124788988],
  ["BA", 90.77360083106089],
  ["AZ", 97.75938189845475],
  ["CV", 89.19166342033502],
  ["BR", 91.84034540968705],
  ["CA", 90.9112452928191],
  ["BE", 97.66231658226204],
  ["TH", 100],
  ["WS", 96.41604986365408],
  ["UZ", 62.53148941695884],
  ["Wales", 78.4300740163615],
  ["NG", 88.61901051811452],
  ["NP", 75.76905596675756],
  ["MK", 85.02726918582002],
  ["BD", 95.57102973639788],
  ["BS", 92.87495130502532],
  ["CU", 85.98883261914038],
  ["BF", 98.61543955330477],
  ["NR", 96.93416439423451],
  ["NE", 71.64459161147903],
  ["ZW", 90.32138683287884],
  ["VC", 94.73477470458383],
  ["UY", 82.33930658356059],
  ["TJ", 66.36021295935592],
  ["VU", 93.1508894948708],
  ["ZA", 88.35833008700169],
  ["BG", 99.77924944812362],
]);

export const mwMap = new Map<string, number>([
  ["CY", 95.55804440981692],
  ["AF", 72.25944682508765],
  ["CN", 61.24626671860798],
  ["BJ", 88.03954031943904],
  ["LA", 90.54278665108427],
  ["ME", 58.18822230879107],
  ["OM", 66.08492403583949],
  ["NI", 95.54538371640047],
  ["MR", 86.2439942864563],
  ["TG", 86.61797169198805],
  ["TP", 69.33937151019349],
  ["North Korea", 88.9838981950396],
  ["VN", 66.18263861836125],
  ["ZM", 90.52915205817426],
  ["MD", 81.12160758343072],
  ["CO", 98.45474613686534],
  ["AG", 59.030320737566555],
  ["AE", 87.96519932476302],
  ["CZ", 86.26184911050512],
  ["AR", 98.83391767302948],
  ["BI", 84.68023633294376],
  ["CM", 83.42130892091936],
  ["LB", 91.33034670821971],
  ["LU", 94.28872873652773],
  ["TD", 86.24139722114012],
  ["TR", 63.57193870925853],
  ["Scotland", 91.3475522659395],
  ["UA", 95.19770159719518],
  ["LT", 98.64303337228931],
  ["LC", 98.93812491884171],
  ["MG", 95.91092065965458],
  ["CL", 80.59115699259837],
  ["BH", 69.8678743020387],
  ["AD", 83.92578885858978],
  ["CH", 93.04408518374238],
  ["NO", 89.52213998182054],
  ["MT", 79.95195429165044],
  ["MC", 76.9838332684067],
  ["TV", 83.85794052720425],
  ["TW", 88.67452278924814],
  ["US", 78.4258537852227],
  ["MU", 91.98675496688742],
  ["BZ", 86.22549019607844],
  ["CI", 98.90728476821192],
  ["BM", 68.18400207765225],
  ["CK", 85.26035579794832],
  ["AT", 94.28872873652773],
  ["BO", 93.87092585378522],
  ["LS", 91.83125568108038],
  ["NL", 95.15939488378132],
  ["MW", 0],
  ["YE", 93.611868588495],
  ["UG", 80.03668354759122],
  ["TT", 64.95714842228281],
  ["NZ", 83.70309050772627],
  ["MA", 60.47461368653422],
  ["MV", 81.20568757304247],
  ["LR", 79.07349694844825],
  ["BY", 88.69400077911959],
  ["BN", 93.22490585638229],
  ["AU", 85.0350603817686],
  ["GE", 93.56901701077783],
  ["GR", 96.90332424360473],
  ["IN", 97.85157771717958],
  ["GBENG", 75.69633813790418],
  ["RS", 90.8086612128295],
  ["PL", 83.00220750551877],
  ["SV", 91.55304505908323],
  ["SA", 88.55051292039995],
  ["HK", 67.31625762887936],
  ["KG", 65.60609011816648],
  ["GD", 84.39001428385924],
  ["GQ", 93.71315413582651],
  ["DJ", 96.41897156213479],
  ["KE", 68.39631216725101],
  ["KR", 88.79885729126087],
  ["SC", 77.44805869367615],
  ["ST", 87.28898844305934],
  ["PY", 91.9676016101805],
  ["SB", 87.54609790936242],
  ["IL", 91.21282950266199],
  ["DK", 77.43442410076614],
  ["DO", 87.66199194909751],
  ["RU", 98.85729126087521],
  ["SG", 79.91364757823659],
  ["PK", 88.51447863913778],
  ["GB", 65.74795481106351],
  ["GU", 82.16075834307233],
  ["GW", 88.44370860927152],
  ["JP", 83.4959745487599],
  ["SR", 65.46032982729515],
  ["SE", 92.5915465523958],
  ["SD", 91.97214647448384],
  ["SS", 54.63446305674587],
  ["PH", 77.08674198156083],
  ["RW", 94.73769640306455],
  ["KU", 90.63822880145436],
  ["HN", 95.25775873263213],
  ["GA", 96.15796649785742],
  ["DZ", 87.89183222958057],
  ["DM", 84.18906635501882],
  ["FR", 87.47273081417998],
  ["GL", 79.90910271393325],
  ["ES", 90.70055836904298],
  ["HT", 72.8019088430074],
  ["PE", 76.68030125957668],
  ["QA", 88.33722893130762],
  ["SI", 97.44286456304377],
  ["PR", 89.1549798727438],
  ["PS", 64.16147253603428],
  ["IQ", 91.8906635501883],
  ["HU", 93.39111803661862],
  ["KN", 77.14095572003636],
  ["KY", 83.2641864692897],
  ["FI", 95.89793533307362],
  ["ER", 64.60070120763537],
  ["GM", 92.94961693286587],
  ["EE", 96.82216595247371],
  ["EG", 93.59401376444617],
  ["ID", 77.20458382028308],
  ["IS", 78.78392416569277],
  ["SK", 93.9783794312427],
  ["RO", 86.34820153226853],
  ["PG", 73.84041033631996],
  ["KM", 80.00811582911311],
  ["IR", 90.13829372808726],
  ["KZ", 86.5536943254123],
  ["IE", 98.98454746136865],
  ["GN", 87.23087910660952],
  ["FJ", 87.7736657576938],
  ["GY", 79.13128165173354],
  ["JM", 87.84735748604078],
  ["KI", 72.82171146604337],
  ["HR", 91.75951175172055],
  ["SO", 94.57927541877679],
  ["PT", 85.8976106999091],
  ["SY", 92.33184002077653],
  ["SN", 86.2426957537982],
  ["KH", 62.493831969874044],
  ["EC", 91.26444617582132],
  ["ET", 85.96610829762369],
  ["DE", 70.09609141669912],
  ["SL", 96.664394234515],
  ["PW", 98.6806908193741],
  ["SZ", 71.61667315932996],
  ["PA", 86.86274509803921],
  ["SM", 94.05207115958967],
  ["IT", 87.52272432151669],
  ["JO", 60.866445916114785],
  ["GH", 93.18075574600701],
  ["CG", 83.77937930138944],
  ["BT", 95.29346838072978],
  ["AO", 78.17004285157772],
  ["ML", 76.39137774315023],
  ["TN", 67.12017919750681],
  ["LI", 71.63809894818854],
  ["MM", 96.66828983248928],
  ["MZ", 86.16608232697052],
  ["NA", 75.0834307232827],
  ["CF", 78.39111803661862],
  ["BB", 96.09174133229452],
  ["CD", 84.98928710557071],
  ["BW", 99.55849889624724],
  ["AL", 68.00220750551877],
  ["NC", 88.25347357486041],
  ["MX", 84.51175172055578],
  ["TZ", 84.29554603298273],
  ["VE", 95.1697831450461],
  ["MN", 71.45597974289053],
  ["MY", 76.81502402285417],
  ["AM", 90.35190235034412],
  ["CR", 64.7587975587586],
  ["BA", 92.50357096480977],
  ["AZ", 65.02110115569405],
  ["CV", 79.48480716790027],
  ["BR", 88.45344760420724],
  ["CA", 94.8233995584989],
  ["BE", 78.05609661082975],
  ["TH", 97.44805869367615],
  ["WS", 64.22315283729387],
  ["UZ", 63.08596286196598],
  ["Wales", 73.9582521750422],
  ["NG", 98.45214907154914],
  ["NP", 77.29580573951435],
  ["MK", 77.67822360732373],
  ["BD", 90.12206206986106],
  ["BS", 90.99987014673418],
  ["CU", 81.37417218543047],
  ["BF", 92.19906505648618],
  ["NR", 87.56557589923386],
  ["NE", 95.370406440722],
  ["ZW", 80.45124009868849],
  ["VC", 96.38520971302428],
  ["UY", 94.0942734709778],
  ["TJ", 91.50986884820153],
  ["VU", 81.8370990780418],
  ["ZA", 92.64673419036488],
  ["BG", 98.85729126087521],
]);

export const yeMap = new Map<string, number>([
  ["CY", 73.36384885079859],
  ["AF", 82.84898065186339],
  ["CN", 68.08466432930788],
  ["BJ", 98.04538371640047],
  ["LA", 65.6885469419556],
  ["ME", 73.12459420854435],
  ["OM", 92.87138034021555],
  ["NI", 67.10557070510323],
  ["MR", 97.0107778210622],
  ["TG", 86.55758992338657],
  ["TP", 64.88540449292299],
  ["North Korea", 91.81080379171536],
  ["VN", 65.31749123490455],
  ["ZM", 92.76132969744188],
  ["MD", 85.71419296195299],
  ["CO", 97.35099337748345],
  ["AG", 90.97422412673679],
  ["AE", 41.343332034800675],
  ["CZ", 88.60115569406571],
  ["AR", 68.92773665757694],
  ["BI", 72.55875860277887],
  ["CM", 86.95721334891573],
  ["LB", 47.333138553434615],
  ["LU", 34.216335540838855],
  ["TD", 87.65614855213609],
  ["TR", 63.7991819244254],
  ["Scotland", 84.08745617452279],
  ["UA", 99.55849889624724],
  ["LT", 99.77924944812362],
  ["LC", 97.17991169977925],
  ["MG", 88.56999091027139],
  ["CL", 87.71880275288923],
  ["BH", 67.09648097649657],
  ["AD", 86.13783924165693],
  ["CH", 84.48448253473575],
  ["NO", 86.22743799506559],
  ["MT", 65.96123880015583],
  ["MC", 50.11037527593819],
  ["TV", 85.05648617062718],
  ["TW", 96.14141020646669],
  ["US", 70.64212439942864],
  ["MU", 74.17218543046357],
  ["BZ", 77.26399168939099],
  ["CI", 88.52519153356707],
  ["BM", 70.23373587845735],
  ["CK", 83.4326710816777],
  ["AT", 33.554083885209714],
  ["BO", 64.23841059602648],
  ["LS", 69.49162446435528],
  ["NL", 65.78366445916114],
  ["MW", 93.611868588495],
  ["YE", 0],
  ["UG", 95.71451759511753],
  ["TT", 66.57057524996753],
  ["NZ", 85.49279314374758],
  ["MA", 66.44591611479028],
  ["MV", 70.00486949746787],
  ["LR", 71.86599142968446],
  ["BY", 95.7843137254902],
  ["BN", 77.53538501493313],
  ["AU", 88.79755875860278],
  ["GE", 86.62478898844306],
  ["GR", 79.68543046357615],
  ["IN", 68.4151408907934],
  ["GBENG", 84.30593429424749],
  ["RS", 92.28866380989483],
  ["PL", 83.22295805739515],
  ["SV", 68.00025970653162],
  ["SA", 92.93143747565252],
  ["HK", 60.911569925983635],
  ["KG", 62.79476691338787],
  ["GD", 78.37748344370861],
  ["GQ", 73.21256979613038],
  ["DJ", 84.91721854304636],
  ["KE", 93.97805479807818],
  ["KR", 72.27210751850409],
  ["SC", 73.71055707051032],
  ["ST", 91.56083625503182],
  ["PY", 35.14121542656798],
  ["SB", 91.44689001428385],
  ["IL", 70.0594078691079],
  ["DK", 53.70958317101675],
  ["DO", 63.56804311128425],
  ["RU", 99.77924944812362],
  ["SG", 52.059148162576285],
  ["PK", 86.78613167121154],
  ["GB", 73.90663550188286],
  ["GU", 90.32495779768861],
  ["GW", 87.20296065446045],
  ["JP", 75.59927282171147],
  ["SR", 95.63141150499935],
  ["SE", 98.82125697961304],
  ["SD", 15.785612258148292],
  ["SS", 95.29833787819764],
  ["PH", 87.62725620049345],
  ["RW", 96.48876769250747],
  ["KU", 69.76756265420076],
  ["HN", 67.34579924685106],
  ["GA", 97.11920529801324],
  ["DZ", 82.61069990910272],
  ["DM", 90.51259576678353],
  ["FR", 77.95318789767562],
  ["GL", 78.79593559278015],
  ["ES", 71.13361901051812],
  ["HT", 97.64186469289703],
  ["PE", 66.61764705882352],
  ["QA", 87.06791325801844],
  ["SI", 97.66751071289444],
  ["PR", 87.17666536813401],
  ["PS", 71.94747435398],
  ["IQ", 2.902545124009869],
  ["HU", 33.33333333333333],
  ["KN", 84.21243994286456],
  ["KY", 85.25353850149331],
  ["FI", 95.0756395273341],
  ["ER", 84.91429684456564],
  ["GM", 55.84988962472406],
  ["EE", 99.77924944812362],
  ["EG", 1.7072458122321776],
  ["ID", 50.11037527593819],
  ["IS", 86.17062719127387],
  ["SK", 96.58291131021946],
  ["RO", 87.70224646149852],
  ["PG", 44.55590183093105],
  ["KM", 86.24853915075964],
  ["IR", 63.746915984937026],
  ["KZ", 95.21717958706661],
  ["IE", 88.57778210621997],
  ["GN", 87.72821711466044],
  ["FJ", 84.09622126996494],
  ["GY", 86.84196857550968],
  ["JM", 88.73393065835606],
  ["KI", 54.95812232177639],
  ["HR", 64.6831580314245],
  ["SO", 95.41975068172964],
  ["PT", 93.89689650694714],
  ["SY", 1.8052850279184522],
  ["SN", 86.58291131021946],
  ["KH", 85.28567718478119],
  ["EC", 94.40721984157902],
  ["ET", 92.8126217374367],
  ["DE", 100],
  ["SL", 66.44591611479028],
  ["PW", 98.28463835865472],
  ["SZ", 84.51791975068173],
  ["PA", 65.9771458252175],
  ["SM", 84.81625762887937],
  ["IT", 77.5727178288534],
  ["JO", 74.28126217374367],
  ["GH", 62.970068822230886],
  ["CG", 93.26840670042851],
  ["BT", 92.0727827554863],
  ["AO", 32.991819244253996],
  ["ML", 87.75386313465783],
  ["TN", 61.19172834696792],
  ["LI", 97.55031814050123],
  ["MM", 92.21594598104143],
  ["MZ", 90.94241007661343],
  ["NA", 83.79853265809635],
  ["CF", 79.0403843656668],
  ["BB", 96.95364238410596],
  ["CD", 90.16783534605895],
  ["BW", 91.3907284768212],
  ["AL", 68.47747045838203],
  ["NC", 94.35365536943254],
  ["MX", 81.36995195429165],
  ["TZ", 85.7411375146085],
  ["VE", 98.17393844955201],
  ["MN", 76.74360472665887],
  ["MY", 76.95136995195429],
  ["AM", 66.66666666666666],
  ["CR", 97.9703934553954],
  ["BA", 91.36832878846903],
  ["AZ", 97.75970653161927],
  ["CV", 89.50688222308791],
  ["BR", 93.43494351382937],
  ["CA", 92.11238800155824],
  ["BE", 77.20101285547332],
  ["TH", 84.10596026490066],
  ["WS", 80.51551746526425],
  ["UZ", 63.60764835735618],
  ["Wales", 83.9030645370731],
  ["NG", 88.62290611608881],
  ["NP", 73.60862225684977],
  ["MK", 87.02084144916245],
  ["BD", 97.60615504479938],
  ["BS", 91.50240228541749],
  ["CU", 83.37358784573432],
  ["BF", 97.96909492273731],
  ["NR", 97.5727178288534],
  ["NE", 71.86566679651993],
  ["ZW", 88.29859758472925],
  ["VC", 96.21477730164914],
  ["UY", 84.33222958057395],
  ["TJ", 66.39689650694714],
  ["VU", 64.47766523828075],
  ["ZA", 88.79755875860278],
  ["BG", 99.77924944812362],
]);

export const ugMap = new Map<string, number>([
  ["CY", 93.82937280872613],
  ["AF", 75.39410466173224],
  ["CN", 92.63277496429035],
  ["BJ", 85.71516686144656],
  ["LA", 87.3409297493832],
  ["ME", 66.41150499935074],
  ["OM", 77.60615504479938],
  ["NI", 94.51662121802363],
  ["MR", 85.96026490066225],
  ["TG", 72.34450071419296],
  ["TP", 88.24990261005064],
  ["North Korea", 87.00818075574601],
  ["VN", 93.14472146474483],
  ["ZM", 89.35625243474873],
  ["MD", 82.9096870536294],
  ["CO", 74.71984157901571],
  ["AG", 75.10453187897676],
  ["AE", 94.66627710686923],
  ["CZ", 77.33184002077653],
  ["AR", 95.79145565510973],
  ["BI", 86.72412673678743],
  ["CM", 84.76853655369433],
  ["LB", 90.76029087131542],
  ["LU", 93.72743799506557],
  ["TD", 81.83450201272562],
  ["TR", 67.57758732632125],
  ["Scotland", 88.41903648876769],
  ["UA", 80.37235423970913],
  ["LT", 94.69906505648618],
  ["LC", 91.37514608492403],
  ["MG", 95.87618491105052],
  ["CL", 92.70581742630827],
  ["BH", 93.92416569276718],
  ["AD", 81.64946110894688],
  ["CH", 91.20276587456175],
  ["NO", 88.13855343461888],
  ["MT", 93.24373457992469],
  ["MC", 95.68952084144917],
  ["TV", 89.63933255421374],
  ["TW", 91.57187378262563],
  ["US", 80.71906245942085],
  ["MU", 86.68224905856383],
  ["BZ", 80.21360862225684],
  ["CI", 93.2359433839761],
  ["BM", 88.09992208804051],
  ["CK", 81.47415920010388],
  ["AT", 93.72743799506557],
  ["BO", 95.64894169588365],
  ["LS", 87.40325931697183],
  ["NL", 93.72678872873654],
  ["MW", 80.03668354759122],
  ["YE", 95.71451759511753],
  ["UG", 0],
  ["TT", 87.3152837293858],
  ["NZ", 80.68562524347487],
  ["MA", 95.25353850149332],
  ["MV", 94.55233086612128],
  ["LR", 88.5592780158421],
  ["BY", 92.43442410076614],
  ["BN", 73.04246201791975],
  ["AU", 79.32378911829633],
  ["GE", 91.29950655758992],
  ["GR", 89.41890663550188],
  ["IN", 94.6065446045968],
  ["GBENG", 97.00915465523958],
  ["RS", 86.97149720815477],
  ["PL", 95.26977015971951],
  ["SV", 94.09492273730685],
  ["SA", 89.29457213348915],
  ["HK", 91.11543955330477],
  ["KG", 91.25373328139203],
  ["GD", 74.50460979093624],
  ["GQ", 93.33365796649787],
  ["DJ", 93.10738865082456],
  ["KE", 59.3705362939878],
  ["KR", 91.56408258667706],
  ["SC", 87.51655629139073],
  ["ST", 85.43987793793015],
  ["PY", 95.03960524607193],
  ["SB", 88.18659914296845],
  ["IL", 90.49214387741851],
  ["DK", 93.26743280093494],
  ["DO", 90.08927412024413],
  ["RU", 88.44143617711985],
  ["SG", 93.68978054798079],
  ["PK", 87.1455005843397],
  ["GB", 84.22574990261005],
  ["GU", 85.72587975587585],
  ["GW", 82.87495130502532],
  ["JP", 93.59206596545903],
  ["SR", 85.7051032333463],
  ["SE", 89.67374366965329],
  ["SD", 94.40851837423712],
  ["SS", 69.87339306583561],
  ["PH", 90.17692507466563],
  ["RW", 84.07706791325802],
  ["KU", 88.28204129333854],
  ["HN", 93.40345409687055],
  ["GA", 95.58791066095313],
  ["DZ", 90.61777691208934],
  ["DM", 88.74042332164655],
  ["FR", 89.8516426438125],
  ["GL", 92.668484612388],
  ["ES", 62.3302168549539],
  ["HT", 90.94241007661343],
  ["PE", 92.35975847292559],
  ["QA", 88.61901051811452],
  ["SI", 93.06810803791716],
  ["PR", 72.8694325412284],
  ["PS", 76.85365536943254],
  ["IQ", 96.04012465913517],
  ["HU", 93.72678872873654],
  ["KN", 83.47649655888846],
  ["KY", 80.99500064926633],
  ["FI", 93.20542786651085],
  ["ER", 88.52129593559278],
  ["GM", 93.24340994676015],
  ["EE", 84.12706142059473],
  ["EG", 96.71179067653551],
  ["ID", 95.8625503181405],
  ["IS", 88.09115699259836],
  ["SK", 92.13316452408779],
  ["RO", 81.82378911829633],
  ["PG", 84.58674198156083],
  ["KM", 84.62764576029087],
  ["IR", 73.74626671860798],
  ["KZ", 91.15114920140242],
  ["IE", 93.2398389819504],
  ["GN", 84.42020516815998],
  ["FJ", 87.17536683547591],
  ["GY", 83.22652902220491],
  ["JM", 71.71114141020647],
  ["KI", 85.56778340475263],
  ["HR", 93.9864952603558],
  ["SO", 93.16095312297105],
  ["PT", 89.00435008440462],
  ["SY", 95.02272432151668],
  ["SN", 94.79710427217245],
  ["KH", 90.19185820023374],
  ["EC", 77.92884041033632],
  ["ET", 78.6251785482405],
  ["DE", 51.43844955200624],
  ["SL", 93.72743799506557],
  ["PW", 89.16049863654071],
  ["SZ", 70.42916504350084],
  ["PA", 92.79898714452669],
  ["SM", 93.25704453967018],
  ["IT", 92.55194130632385],
  ["JO", 77.51330995974548],
  ["GH", 95.46844565640826],
  ["CG", 92.56168030125957],
  ["BT", 88.46188806648487],
  ["AO", 81.0943383976107],
  ["ML", 84.37410725879757],
  ["TN", 66.27710686923776],
  ["LI", 90.4554603298273],
  ["MM", 75.11654330606414],
  ["MZ", 74.55427866510843],
  ["NA", 84.25009738994935],
  ["CF", 85.33534605895339],
  ["BB", 88.3677444487729],
  ["CD", 87.92948967666537],
  ["BW", 90.2142578885859],
  ["AL", 92.06499155953772],
  ["NC", 86.64589014413713],
  ["MX", 93.34079989611739],
  ["TZ", 83.61479028697572],
  ["VE", 72.18315803142448],
  ["MN", 90.02467212050384],
  ["MY", 83.7290611608882],
  ["AM", 90.89176730294767],
  ["CR", 85.95085053889106],
  ["BA", 77.48311907544475],
  ["AZ", 93.58849500064926],
  ["CV", 85.18406700428515],
  ["BR", 85.24704583820282],
  ["CA", 92.15816127775614],
  ["BE", 85.09122191923126],
  ["TH", 84.93896896506948],
  ["WS", 92.22730814179977],
  ["UZ", 92.78859888326193],
  ["Wales", 87.97266588754708],
  ["NG", 92.64543565770678],
  ["NP", 85.94273470977795],
  ["MK", 65.14868198935203],
  ["BD", 94.14264381249188],
  ["BS", 88.24957797688612],
  ["CU", 85.53694325412285],
  ["BF", 94.24457862615245],
  ["NR", 88.4028048305415],
  ["NE", 96.47026360212959],
  ["ZW", 66.87475652512661],
  ["VC", 80.76158940397352],
  ["UY", 80.90540189585768],
  ["TJ", 85.94241007661343],
  ["VU", 82.25977145825217],
  ["ZA", 87.91617971691988],
  ["BG", 93.65861576418646],
]);

export const ttMap = new Map<string, number>([
  ["CY", 88.66996675820205],
  ["AF", 85.1206821795057],
  ["CN", 30.819482584188467],
  ["BJ", 81.80264489088019],
  ["LA", 59.348171701112875],
  ["ME", 41.55080213903744],
  ["OM", 61.68232403526521],
  ["NI", 93.68297441826854],
  ["MR", 92.07508310449487],
  ["TG", 84.03092932504698],
  ["TP", 48.70176326058679],
  ["North Korea", 90.34000578118226],
  ["VN", 30.467191790721206],
  ["ZM", 88.24432721491546],
  ["MD", 68.18470877294406],
  ["CO", 77.55636652695476],
  ["AG", 49.24627836392542],
  ["AE", 68.40656164185576],
  ["CZ", 71.03519294695765],
  ["AR", 96.3173869056222],
  ["BI", 69.94616274028039],
  ["CM", 78.27142650672062],
  ["LB", 62.93611793611794],
  ["LU", 73.05174158115335],
  ["TD", 69.80849833791011],
  ["TR", 33.46654140771788],
  ["Scotland", 87.0064315652551],
  ["UA", 94.31673652261887],
  ["LT", 74.15558606735078],
  ["LC", 95.27099291805175],
  ["MG", 93.69453678277208],
  ["CL", 60.41624512212748],
  ["BH", 52.751842751842744],
  ["AD", 70.80177771354242],
  ["CH", 91.43987570458158],
  ["NO", 90.74721780604133],
  ["MT", 60.1145396733632],
  ["MC", 60.16548634195693],
  ["TV", 83.6248012718601],
  ["TW", 90.72300910536205],
  ["US", 61.877438936262465],
  ["MU", 77.95779736956207],
  ["BZ", 78.2013296719179],
  ["CI", 93.61251625957509],
  ["BM", 39.60290504408152],
  ["CK", 83.86182974418269],
  ["AT", 52.221419280242806],
  ["BO", 72.9458736811678],
  ["LS", 91.0438647203353],
  ["NL", 92.80206677265501],
  ["MW", 65.12790865732042],
  ["YE", 66.6750975574505],
  ["UG", 87.12892036421448],
  ["TT", 0],
  ["NZ", 84.59748518572047],
  ["MA", 29.490533314062727],
  ["MV", 45.696632461338346],
  ["LR", 60.7884087295852],
  ["BY", 89.51112877583466],
  ["BN", 88.17423038011273],
  ["AU", 85.01842751842752],
  ["GE", 88.92253215782627],
  ["GR", 92.60550657609481],
  ["IN", 94.33769330828154],
  ["GBENG", 73.34405260875849],
  ["RS", 89.7759791877439],
  ["PL", 60.353013441248734],
  ["SV", 92.92274895216072],
  ["SA", 91.26318832201186],
  ["HK", 30.639904610492845],
  ["KG", 31.56886833357422],
  ["GD", 62.21130221130221],
  ["GQ", 72.8699956641133],
  ["DJ", 92.3529411764706],
  ["KE", 83.95685792744617],
  ["KR", 87.49710940887412],
  ["SC", 66.06662812545166],
  ["ST", 79.50787686081804],
  ["PY", 71.41205376499494],
  ["SB", 87.44507876860817],
  ["IL", 89.75502240208122],
  ["DK", 43.7556005203064],
  ["DO", 58.850267379679146],
  ["RU", 71.30799248446307],
  ["SG", 62.343546755311465],
  ["PK", 90.75083104494868],
  ["GB", 64.62747506865153],
  ["GU", 81.55947391241509],
  ["GW", 71.8250469721058],
  ["JP", 86.070241364359],
  ["SR", 67.94876427229369],
  ["SE", 93.60673507732331],
  ["SD", 68.0340367105073],
  ["SS", 75.57053042347161],
  ["PH", 71.36580430698078],
  ["RW", 90.3801127330539],
  ["KU", 76.62523486052898],
  ["HN", 93.48930481283423],
  ["GA", 94.34961699667582],
  ["DZ", 90.16657031362914],
  ["DM", 92.75798525798525],
  ["FR", 68.60637375343258],
  ["GL", 66.5605578840873],
  ["ES", 59.67986703280821],
  ["HT", 61.175025292672345],
  ["PE", 44.54220263043793],
  ["QA", 91.15262321144674],
  ["SI", 72.07002457002457],
  ["PR", 87.89637230813702],
  ["PS", 70.91234282410753],
  ["IQ", 66.65956063014886],
  ["HU", 72.19323601676543],
  ["KN", 73.84954473189768],
  ["KY", 84.54979043214337],
  ["FI", 91.25921375921376],
  ["ER", 65.69193525075877],
  ["GM", 72.69150166208989],
  ["EE", 88.28877005347594],
  ["EG", 66.86479260008672],
  ["ID", 60.33639254227489],
  ["IS", 82.59466685937275],
  ["SK", 90.65544153779447],
  ["RO", 69.84788264200029],
  ["PG", 59.79693597340656],
  ["KM", 81.10167654285301],
  ["IR", 87.65139471021824],
  ["KZ", 90.48706460471166],
  ["IE", 93.74187021245845],
  ["GN", 70.12646336175747],
  ["FJ", 86.62957074721781],
  ["GY", 73.14749241219829],
  ["JM", 91.90489955195838],
  ["KI", 54.984824396589104],
  ["HR", 90.64532446885389],
  ["SO", 94.76441682324035],
  ["PT", 90.6283422459893],
  ["SY", 65.63303945656887],
  ["SN", 70.258346581876],
  ["KH", 62.61779158837982],
  ["EC", 76.94609047550223],
  ["ET", 71.03121838415956],
  ["DE", 88.13809799103916],
  ["SL", 93.75704581586935],
  ["PW", 97.35041190923543],
  ["SZ", 62.47253938430409],
  ["PA", 68.24649515825986],
  ["SM", 92.8110998699234],
  ["IT", 68.54169677699089],
  ["JO", 68.51748807631161],
  ["GH", 70.52500361323891],
  ["CG", 73.23204220263044],
  ["BT", 93.19265789854026],
  ["AO", 57.445078768608184],
  ["ML", 70.07154213036566],
  ["TN", 31.716288480994365],
  ["LI", 60.48634195693019],
  ["MM", 71.48287324757912],
  ["MZ", 74.74309871368695],
  ["NA", 67.57334874981935],
  ["CF", 84.5815869345281],
  ["BB", 91.84239051886111],
  ["CD", 77.48554704437058],
  ["BW", 95.32085561497327],
  ["AL", 27.707761237173003],
  ["NC", 91.05072987425929],
  ["MX", 68.84520884520884],
  ["TZ", 88.42679577973695],
  ["VE", 69.89774533892181],
  ["MN", 52.40388784506431],
  ["MY", 63.996603555427086],
  ["AM", 73.59625668449198],
  ["CR", 72.69619887266946],
  ["BA", 87.3135568723804],
  ["AZ", 73.25372163607457],
  ["CV", 85.75155369273017],
  ["BR", 87.72691140338199],
  ["CA", 91.12733053909524],
  ["BE", 66.45432866021102],
  ["TH", 72.79122705593294],
  ["WS", 37.50903309726839],
  ["UZ", 90.2352218528689],
  ["Wales", 77.7836392542275],
  ["NG", 92.95020956785662],
  ["NP", 65.68109553403671],
  ["MK", 81.98402948402949],
  ["BD", 91.88611070964012],
  ["BS", 94.68817748229513],
  ["CU", 74.89088018499783],
  ["BF", 94.45801416389652],
  ["NR", 91.60138748374042],
  ["NE", 94.8966613672496],
  ["ZW", 79.19822228645758],
  ["VC", 93.80690851279087],
  ["UY", 89.6827576239341],
  ["TJ", 91.97138314785374],
  ["VU", 68.54711663535194],
  ["ZA", 91.5128631305102],
  ["BG", 71.61764705882354],
]);

export const nzMap = new Map<string, number>([
  ["CY", 89.49134948096886],
  ["AF", 84.61159169550173],
  ["CN", 83.27205882352942],
  ["BJ", 90.18425605536332],
  ["LA", 55.77032871972318],
  ["ME", 83.43944636678201],
  ["OM", 83.090830449827],
  ["NI", 90.44939446366782],
  ["MR", 85.8961937716263],
  ["TG", 84.86807958477509],
  ["TP", 83.42690311418686],
  ["North Korea", 81.96020761245674],
  ["VN", 81.40960207612457],
  ["ZM", 84.75865051903114],
  ["MD", 86.09688581314879],
  ["CO", 64.87759515570934],
  ["AG", 83.780276816609],
  ["AE", 88.51124567474048],
  ["CZ", 87.29022491349481],
  ["AR", 96.27292387543253],
  ["BI", 86.54628027681662],
  ["CM", 87.8992214532872],
  ["LB", 87.75951557093425],
  ["LU", 89.22361591695501],
  ["TD", 70.66306228373702],
  ["TR", 81.4446366782007],
  ["Scotland", 84.25173010380624],
  ["UA", 89.7166955017301],
  ["LT", 91.26167820069205],
  ["LC", 96.48010380622839],
  ["MG", 90.94420415224913],
  ["CL", 90.09904844290656],
  ["BH", 84.19074394463668],
  ["AD", 89.22188581314879],
  ["CH", 87.6371107266436],
  ["NO", 75.33001730103807],
  ["MT", 87.30190311418686],
  ["MC", 83.82439446366782],
  ["TV", 74.31617647058823],
  ["TW", 86.6219723183391],
  ["US", 85.63192041522491],
  ["MU", 77.17171280276817],
  ["BZ", 82.82309688581316],
  ["CI", 95.34558823529412],
  ["BM", 67.37543252595157],
  ["CK", 6.132785467128028],
  ["AT", 89.09948096885813],
  ["BO", 84.905276816609],
  ["LS", 88.27465397923876],
  ["NL", 89.82006920415225],
  ["MW", 83.86115916955018],
  ["YE", 85.37975778546712],
  ["UG", 81.17214532871972],
  ["TT", 84.41219723183391],
  ["NZ", 0],
  ["MA", 80.33217993079585],
  ["MV", 82.60337370242215],
  ["LR", 84.83607266435986],
  ["BY", 82.54801038062284],
  ["BN", 80.79195501730105],
  ["AU", 76.37456747404843],
  ["GE", 84.06358131487889],
  ["GR", 89.20934256055362],
  ["IN", 94.18685121107266],
  ["GBENG", 88.15570934256056],
  ["RS", 67.18555363321799],
  ["PL", 89.72015570934256],
  ["SV", 90.33823529411765],
  ["SA", 83.97491349480968],
  ["HK", 81.23269896193773],
  ["KG", 77.62716262975779],
  ["GD", 79.90441176470588],
  ["GQ", 90.30579584775087],
  ["DJ", 93.35856401384083],
  ["KE", 83.46712802768165],
  ["KR", 80.60423875432527],
  ["SC", 76.42517301038062],
  ["ST", 88.56358131487889],
  ["PY", 84.16955017301038],
  ["SB", 82.70544982698962],
  ["IL", 87.27076124567475],
  ["DK", 84.60207612456747],
  ["DO", 73.05233564013841],
  ["RU", 87.2257785467128],
  ["SG", 89.36375432525952],
  ["PK", 85.9991349480969],
  ["GB", 59.43858131487889],
  ["GU", 29.408737024221455],
  ["GW", 85.00216262975778],
  ["JP", 87.27378892733564],
  ["SR", 88.65181660899654],
  ["SE", 88.55406574394463],
  ["SD", 84.05233564013841],
  ["SS", 86.05363321799308],
  ["PH", 87.340830449827],
  ["RW", 94.19852941176471],
  ["KU", 89.03979238754324],
  ["HN", 90.08304498269895],
  ["GA", 93.92603806228374],
  ["DZ", 88.48053633217992],
  ["DM", 80.94463667820068],
  ["FR", 73.00389273356402],
  ["GL", 85.72707612456747],
  ["ES", 84.67776816608996],
  ["HT", 86.85899653979239],
  ["PE", 85.08693771626298],
  ["QA", 85.70285467128028],
  ["SI", 85.94723183391004],
  ["PR", 84.08823529411765],
  ["PS", 87.88538062283737],
  ["IQ", 84.11332179930795],
  ["HU", 85.10553633217994],
  ["KN", 84.6219723183391],
  ["KY", 7.123269896193772],
  ["FI", 63.70588235294118],
  ["ER", 80.18814878892734],
  ["GM", 67.90397923875433],
  ["EE", 92.61764705882352],
  ["EG", 85.45934256055364],
  ["ID", 84.05320069204151],
  ["IS", 37.74870242214533],
  ["SK", 83.21237024221453],
  ["RO", 70.68641868512111],
  ["PG", 82.35856401384083],
  ["KM", 90.61634948096886],
  ["IR", 87.39532871972318],
  ["KZ", 94.65484429065744],
  ["IE", 93.38970588235294],
  ["GN", 84.87067474048443],
  ["FJ", 71.36245674740485],
  ["GY", 85.8083910034602],
  ["JM", 85.7932525951557],
  ["KI", 57.13797577854671],
  ["HR", 55.82093425605537],
  ["SO", 95.03027681660899],
  ["PT", 83.26643598615917],
  ["SY", 84.15484429065744],
  ["SN", 88.17647058823529],
  ["KH", 82.42041522491348],
  ["EC", 84.82785467128028],
  ["ET", 80.94290657439447],
  ["DE", 90.32396193771626],
  ["SL", 93.25],
  ["PW", 96.44939446366782],
  ["SZ", 78.14359861591696],
  ["PA", 86.10553633217994],
  ["SM", 88.2590830449827],
  ["IT", 89.49826989619378],
  ["JO", 85.70717993079585],
  ["GH", 83.29195501730104],
  ["CG", 87.24524221453287],
  ["BT", 97.55882352941177],
  ["AO", 76.66306228373702],
  ["ML", 91.29757785467129],
  ["TN", 80.82569204152249],
  ["LI", 62.91262975778547],
  ["MM", 91.07093425605535],
  ["MZ", 83.43901384083044],
  ["NA", 71.1038062283737],
  ["CF", 77.69506920415225],
  ["BB", 38.55709342560554],
  ["CD", 90.86980968858131],
  ["BW", 95.91998269896193],
  ["AL", 72.50735294117648],
  ["NC", 86.46626297577855],
  ["MX", 88.08996539792388],
  ["TZ", 88.30406574394463],
  ["VE", 64.66262975778547],
  ["MN", 81.52897923875433],
  ["MY", 81.31228373702423],
  ["AM", 83.12153979238755],
  ["CR", 61.17690311418686],
  ["BA", 36.42603806228374],
  ["AZ", 88.84126297577855],
  ["CV", 41.03849480968858],
  ["BR", 80.19679930795847],
  ["CA", 85.6219723183391],
  ["BE", 93.04195501730104],
  ["TH", 87.57569204152249],
  ["WS", 83.7651384083045],
  ["UZ", 92.18987889273357],
  ["Wales", 71.08910034602076],
  ["NG", 89.64662629757785],
  ["NP", 72.67863321799308],
  ["MK", 77.74740484429066],
  ["BD", 87.34818339100346],
  ["BS", 92.65916955017302],
  ["CU", 47.66306228373702],
  ["BF", 89.36678200692042],
  ["NR", 25.298010380622838],
  ["NE", 94.44420415224913],
  ["ZW", 86.16089965397924],
  ["VC", 91.17647058823529],
  ["UY", 87.56314878892734],
  ["TJ", 89.28546712802769],
  ["VU", 78.49524221453287],
  ["ZA", 67.56660899653978],
  ["BG", 90.16522491349481],
]);

export const maMap = new Map<string, number>([
  ["CY", 94.25366835475913],
  ["AF", 89.57927541877679],
  ["CN", 2.6778989741592003],
  ["BJ", 97.11368653421634],
  ["LA", 47.62855473315154],
  ["ME", 87.62368523568368],
  ["OM", 51.597844435787565],
  ["NI", 99.2238021036229],
  ["MR", 93.31482924295545],
  ["TG", 75.83073626801713],
  ["TP", 24.679587066614726],
  ["North Korea", 93.40767432800935],
  ["VN", 6.788728736527724],
  ["ZM", 85.20322036099208],
  ["MD", 61.46377093883911],
  ["CO", 72.59544215037008],
  ["AG", 45.498961173873525],
  ["AE", 74.74970783015192],
  ["CZ", 59.82112712634723],
  ["AR", 99.06570575249968],
  ["BI", 65.03311258278146],
  ["CM", 67.68017140631088],
  ["LB", 93.61349175431762],
  ["LU", 99.52830801194649],
  ["TD", 66.17614595507078],
  ["TR", 93.53850149331255],
  ["Scotland", 91.93643682638618],
  ["UA", 97.75678483313855],
  ["LT", 62.453902090637584],
  ["LC", 99.12511362160758],
  ["MG", 95.91351772497079],
  ["CL", 48.79041682898325],
  ["BH", 32.47467861316712],
  ["AD", 63.851772497078294],
  ["CH", 97.13381379041684],
  ["NO", 93.23464485131801],
  ["MT", 49.34294247500325],
  ["MC", 49.96753668354759],
  ["TV", 77.1185560316842],
  ["TW", 94.55038306713413],
  ["US", 49.60037657447085],
  ["MU", 96.63582651603689],
  ["BZ", 74.75295416179716],
  ["CI", 99.69127386053759],
  ["BM", 21.406960135047395],
  ["CK", 79.75684975977146],
  ["AT", 99.7438644331905],
  ["BO", 66.22516556291392],
  ["LS", 98.73880015582391],
  ["NL", 66.44591611479028],
  ["MW", 60.47461368653422],
  ["YE", 66.44591611479028],
  ["UG", 95.25353850149332],
  ["TT", 29.510777821062202],
  ["NZ", 80.09544215037008],
  ["MA", 0],
  ["MV", 29.132255551227114],
  ["LR", 55.11492014024153],
  ["BY", 34.77015971951695],
  ["BN", 93.08823529411765],
  ["AU", 92.88436566679653],
  ["GE", 89.784443578756],
  ["GR", 96.86177119854564],
  ["IN", 98.7589274120244],
  ["GBENG", 70.36488767692506],
  ["RS", 60.55869367614596],
  ["PL", 50.12563303467083],
  ["SV", 98.82580184391637],
  ["SA", 90.05064277366576],
  ["HK", 8.784898065186338],
  ["KG", 90.08862485391508],
  ["GD", 54.22964550058435],
  ["GQ", 95.63271003765745],
  ["DJ", 96.5929749383197],
  ["KE", 92.12245162965849],
  ["KR", 89.66400467471757],
  ["SC", 62.96877028957278],
  ["ST", 84.66887417218543],
  ["PY", 64.83248928710556],
  ["SB", 87.56914686404363],
  ["IL", 95.56680950525906],
  ["DK", 23.801454356577068],
  ["DO", 64.86625113621608],
  ["RU", 62.539605246071936],
  ["SG", 94.05207115958967],
  ["PK", 95.44637060122062],
  ["GB", 56.4407219841579],
  ["GU", 78.97318530061031],
  ["GW", 66.01512790546681],
  ["JP", 94.3302817815868],
  ["SR", 62.83794312426958],
  ["SE", 98.36417348396313],
  ["SD", 68.04863004804571],
  ["SS", 75.53337228931308],
  ["PH", 57.79216984807169],
  ["RW", 93.24243604726658],
  ["KU", 70.11362160758343],
  ["HN", 99.13907284768212],
  ["GA", 98.86605635631737],
  ["DZ", 90.75931697182185],
  ["DM", 88.38040514218932],
  ["FR", 99.40527204259187],
  ["GL", 50.277885988832615],
  ["ES", 45.68984547461369],
  ["HT", 49.60979093624205],
  ["PE", 33.52811323204779],
  ["QA", 93.36417348396311],
  ["SI", 93.08044409816907],
  ["PR", 87.86748474224126],
  ["PS", 82.30002597065315],
  ["IQ", 64.5296065446046],
  ["HU", 66.88222308791066],
  ["KN", 68.18854694195558],
  ["KY", 79.30820672639916],
  ["FI", 96.16673159329957],
  ["ER", 78.27587326321257],
  ["GM", 63.03824178678094],
  ["EE", 98.71477730164914],
  ["EG", 65.94663030775224],
  ["ID", 50.18828723542397],
  ["IS", 81.13167121153097],
  ["SK", 92.41754317621088],
  ["RO", 66.17614595507078],
  ["PG", 54.71302428256071],
  ["KM", 76.72315283729387],
  ["IR", 90.21360862225684],
  ["KZ", 88.67419815608363],
  ["IE", 99.69874042332164],
  ["GN", 66.32320477860019],
  ["FJ", 82.32956758862485],
  ["GY", 75.67166601740034],
  ["JM", 94.35203220360992],
  ["KI", 45.48305414881184],
  ["HR", 92.16335540838853],
  ["SO", 98.14472146474483],
  ["PT", 90.74730554473444],
  ["SY", 65.12303596935463],
  ["SN", 96.52123100895987],
  ["KH", 93.4677314634463],
  ["EC", 91.76600441501104],
  ["ET", 59.39683158031425],
  ["DE", 95.23763147643163],
  ["SL", 99.97013374886379],
  ["PW", 97.31755616153747],
  ["SZ", 86.34560446695234],
  ["PA", 72.24483833268407],
  ["SM", 93.8316452408778],
  ["IT", 66.61667315932995],
  ["JO", 73.96149850668743],
  ["GH", 65.08537852226983],
  ["CG", 64.65621347876899],
  ["BT", 94.57602908713154],
  ["AO", 50.92910011686794],
  ["ML", 66.17679522139981],
  ["TN", 95.03213868328788],
  ["LI", 45.78366445916115],
  ["MM", 96.50499935073367],
  ["MZ", 79.93864433190495],
  ["NA", 65.48857291260876],
  ["CF", 81.51830931047915],
  ["BB", 98.42260745357746],
  ["CD", 76.60823269705233],
  ["BW", 99.34489027399039],
  ["AL", 90.29444228022335],
  ["NC", 93.5969354629269],
  ["MX", 63.36482275029217],
  ["TZ", 94.07706791325802],
  ["VE", 59.96883521620568],
  ["MN", 35.844046227762625],
  ["MY", 58.70244124139722],
  ["AM", 95.19088430074017],
  ["CR", 68.0499285807038],
  ["BA", 90.84274769510453],
  ["AZ", 94.40527204259187],
  ["CV", 86.74977275678484],
  ["BR", 88.2573691728347],
  ["CA", 91.69848071679003],
  ["BE", 99.25983638488508],
  ["TH", 95.87293857940527],
  ["WS", 21.1576418646929],
  ["UZ", 93.1456953642384],
  ["Wales", 73.23464485131801],
  ["NG", 99.6980911569926],
  ["NP", 56.97636670562265],
  ["MK", 82.25587586027788],
  ["BD", 90.65835605765486],
  ["BS", 97.49805220101285],
  ["CU", 74.67796390079211],
  ["BF", 97.61751720555772],
  ["NR", 94.71269964939619],
  ["NE", 95.7008830022075],
  ["ZW", 76.41540059732502],
  ["VC", 95.73172315283729],
  ["UY", 95.27334112452928],
  ["TJ", 98.93844955200623],
  ["VU", 65.28600181794573],
  ["ZA", 71.06155044799377],
  ["BG", 62.76035579794832],
]);

export const mvMap = new Map<string, number>([
  ["CY", 94.07683337652242],
  ["AF", 89.19603524229075],
  ["CN", 93.73024099507644],
  ["BJ", 66.76988857216895],
  ["LA", 45.48684892459186],
  ["ME", 87.36589790101063],
  ["OM", 70.8933661570355],
  ["NI", 96.77895827934698],
  ["MR", 72.30467737755896],
  ["TG", 75.06154444156518],
  ["TP", 44.67996890386111],
  ["North Korea", 68.3593547551179],
  ["VN", 28.964109873024103],
  ["ZM", 93.47337393107023],
  ["MD", 69.04865250064783],
  ["CO", 73.17601710287639],
  ["AG", 54.54554288675823],
  ["AE", 75.36732314071003],
  ["CZ", 69.34017880279866],
  ["AR", 98.48114796579425],
  ["BI", 63.94694221300855],
  ["CM", 80.13345426276237],
  ["LB", 45.88202902306297],
  ["LU", 70.00485877170252],
  ["TD", 72.4293858512568],
  ["TR", 28.00855143819642],
  ["Scotland", 90.48782067893237],
  ["UA", 97.61887794765484],
  ["LT", 50.44473956983675],
  ["LC", 99.28057786991448],
  ["MG", 86.42200051826899],
  ["CL", 63.428997149520605],
  ["BH", 54.255636175174914],
  ["AD", 70.94908007255766],
  ["CH", 94.7489634620368],
  ["NO", 47.030318735423684],
  ["MT", 64.09497279087847],
  ["MC", 64.8244363824825],
  ["TV", 79.01885203420575],
  ["TW", 92.68754858771703],
  ["US", 81.97428090178803],
  ["MU", 72.31504275719098],
  ["BZ", 74.04897641876134],
  ["CI", 97.76269759004923],
  ["BM", 37.445581756931844],
  ["CK", 82.76075408136822],
  ["AT", 42.62600414615185],
  ["BO", 64.59121534076185],
  ["LS", 94.80921223114797],
  ["NL", 97.2175434050272],
  ["MW", 81.27656128530707],
  ["YE", 70.29055454781032],
  ["UG", 94.51347499352164],
  ["TT", 45.543210676341026],
  ["NZ", 82.76949987043275],
  ["MA", 29.219357346462814],
  ["MV", 0],
  ["LR", 64.15457372376264],
  ["BY", 90.21961648095362],
  ["BN", 93.94564654055455],
  ["AU", 87.81128530707437],
  ["GE", 92.43327286861881],
  ["GR", 95.59341798393366],
  ["IN", 97.51490023322104],
  ["GBENG", 85.9659238144597],
  ["RS", 89.25693184762892],
  ["PL", 65.20212490282456],
  ["SV", 97.13300077740348],
  ["SA", 73.03025395180099],
  ["HK", 92.75913449080072],
  ["KG", 24.83642135268204],
  ["GD", 54.57339984451931],
  ["GQ", 69.28090178802798],
  ["DJ", 95.88623995853848],
  ["KE", 92.34905415910858],
  ["KR", 94.5403601969422],
  ["SC", 76.56128530707437],
  ["ST", 83.90904379372894],
  ["PY", 69.01561285307073],
  ["SB", 86.95063487950246],
  ["IL", 96.5389349572428],
  ["DK", 41.40904379372895],
  ["DO", 70.9270536408396],
  ["RU", 69.75997667789584],
  ["SG", 66.04949468774294],
  ["PK", 94.36479657942472],
  ["GB", 71.55189168178285],
  ["GU", 77.94214822492874],
  ["GW", 71.66331951282716],
  ["JP", 95.67731277533039],
  ["SR", 85.05053122570614],
  ["SE", 96.40450894013993],
  ["SD", 68.0036926664939],
  ["SS", 84.23717284270536],
  ["PH", 69.16299559471366],
  ["RW", 93.20160663384296],
  ["KU", 68.05551956465405],
  ["HN", 96.40645244882093],
  ["GA", 96.86414874319772],
  ["DZ", 84.79560767038092],
  ["DM", 78.85041461518529],
  ["FR", 72.79897641876134],
  ["GL", 63.71112982637989],
  ["ES", 44.99060637470848],
  ["HT", 62.116480953614925],
  ["PE", 48.03284529670899],
  ["QA", 94.80824047680746],
  ["SI", 67.51651982378854],
  ["PR", 88.72343871469293],
  ["PS", 78.62950246177766],
  ["IQ", 68.38364861363047],
  ["HU", 64.5704845814978],
  ["KN", 72.66228297486396],
  ["KY", 82.90133454262762],
  ["FI", 97.76075408136823],
  ["ER", 65.0738533298782],
  ["GM", 69.34471365638767],
  ["EE", 98.82514900233221],
  ["EG", 70.29800466442083],
  ["ID", 65.02591344908008],
  ["IS", 88.1436252915263],
  ["SK", 66.63772998186059],
  ["RO", 72.43424462295933],
  ["PG", 62.87250583052604],
  ["KM", 86.48613630474216],
  ["IR", 88.9300984711065],
  ["KZ", 87.7487691111687],
  ["IE", 90.0343353200311],
  ["GN", 72.60268204197978],
  ["FJ", 85.3958279346981],
  ["GY", 79.48464628142005],
  ["JM", 91.15995076444675],
  ["KI", 55.98471106504276],
  ["HR", 91.10261725835709],
  ["SO", 95.40975641357865],
  ["PT", 89.72985229334024],
  ["SY", 68.58868877947654],
  ["SN", 63.479204457113234],
  ["KH", 76.43366157035501],
  ["EC", 68.75097175434051],
  ["ET", 59.36835967867323],
  ["DE", 97.0733998445193],
  ["SL", 96.82592640580461],
  ["PW", 98.72667789582793],
  ["SZ", 86.80875874578906],
  ["PA", 79.46974604819901],
  ["SM", 94.09238144597046],
  ["IT", 64.67025136045608],
  ["JO", 77.15761855402954],
  ["GH", 64.7836227001814],
  ["CG", 62.93761337133973],
  ["BT", 94.27442342575797],
  ["AO", 62.97648354495984],
  ["ML", 72.43683596786732],
  ["TN", 27.833311738792432],
  ["LI", 61.72227260948432],
  ["MM", 68.08629178543664],
  ["MZ", 84.0722985229334],
  ["NA", 69.820225447007],
  ["CF", 87.33512568022805],
  ["BB", 97.36460222855662],
  ["CD", 85.00421093547551],
  ["BW", 99.17141746566467],
  ["AL", 26.55772220782586],
  ["NC", 90.38708214563358],
  ["MX", 62.991383778180875],
  ["TZ", 94.4279606115574],
  ["VE", 66.50395180098471],
  ["MN", 50.11045607670381],
  ["MY", 68.1076703809277],
  ["AM", 96.67465664679969],
  ["CR", 84.68256024876911],
  ["BA", 89.80726872246696],
  ["AZ", 83.49313293599378],
  ["CV", 91.33810572687224],
  ["BR", 84.69260171028765],
  ["CA", 93.19318476289195],
  ["BE", 73.3564394920964],
  ["TH", 65.47745529930033],
  ["WS", 44.57696294376782],
  ["UZ", 92.2888053899974],
  ["Wales", 75.77060119201866],
  ["NG", 81.43301373412801],
  ["NP", 65.6452448820938],
  ["MK", 80.88526820419798],
  ["BD", 78.35222855662089],
  ["BS", 95.34464887276496],
  ["CU", 81.73879243327286],
  ["BF", 61.01904638507385],
  ["NR", 94.83739310702255],
  ["NE", 96.56646799689038],
  ["ZW", 94.27571909821198],
  ["VC", 95.16714174656646],
  ["UY", 95.65593417983933],
  ["TJ", 96.67076962943769],
  ["VU", 65.23354495983415],
  ["ZA", 78.90450894013993],
  ["BG", 97.38209380668566],
]);

export const lrMap = new Map<string, number>([
  ["CY", 62.495468775745586],
  ["AF", 90.23026857801945],
  ["CN", 89.95592354588894],
  ["BJ", 88.1726808370407],
  ["LA", 60.38062283737025],
  ["ME", 90.04119294776734],
  ["OM", 65.98904267589388],
  ["NI", 83.36917119789092],
  ["MR", 91.96819904432361],
  ["TG", 81.48747734387872],
  ["TP", 58.76297577854671],
  ["North Korea", 89.3660405338606],
  ["VN", 56.81990443236118],
  ["ZM", 93.86884165430878],
  ["MD", 70.42881858625803],
  ["CO", 70.99769319492502],
  ["AG", 66.1299225572582],
  ["AE", 79.62761575218322],
  ["CZ", 56.062778052397434],
  ["AR", 85.13717251606525],
  ["BI", 61.950898006261326],
  ["CM", 71.36389850057671],
  ["LB", 72.87691547207118],
  ["LU", 83.79222277146152],
  ["TD", 62.87897511945955],
  ["TR", 88.35681331356072],
  ["Scotland", 76.29222277146152],
  ["UA", 85.34272532542428],
  ["LT", 80.36455758774098],
  ["LC", 93.2859614434009],
  ["MG", 85.95032130499258],
  ["CL", 79.91761410446531],
  ["BH", 49.79032789586423],
  ["AD", 72.08848245180425],
  ["CH", 85.30606360191135],
  ["NO", 91.46111385730762],
  ["MT", 54.227632229362335],
  ["MC", 55.873702422145335],
  ["TV", 85.20060965562696],
  ["TW", 92.97495468775746],
  ["US", 53.706129510627775],
  ["MU", 85.87946943483276],
  ["BZ", 68.22829131652661],
  ["CI", 83.26866040533861],
  ["BM", 50.004531224254414],
  ["CK", 81.85944966221783],
  ["AT", 83.78521997034107],
  ["BO", 88.11707035755478],
  ["LS", 78.46927006096557],
  ["NL", 70.00535508320975],
  ["MW", 77.77022573735375],
  ["YE", 71.22219476025704],
  ["UG", 87.40072499588071],
  ["TT", 60.46589223924864],
  ["NZ", 83.82476520019773],
  ["MA", 53.84247816773768],
  ["MV", 62.75004119294777],
  ["LR", 0],
  ["BY", 92.63511286867688],
  ["BN", 74.57241720217499],
  ["AU", 84.86488713132312],
  ["GE", 68.536826495304],
  ["GR", 78.34074806393146],
  ["IN", 83.79510627780525],
  ["GBENG", 56.781183061459885],
  ["RS", 74.91761410446531],
  ["PL", 57.02792881858626],
  ["SV", 83.02726973142198],
  ["SA", 88.36216839677047],
  ["HK", 86.43639808864722],
  ["KG", 85.96968199044323],
  ["GD", 65.3023562366123],
  ["GQ", 84.17737683308617],
  ["DJ", 85.82221123743615],
  ["KE", 84.74789915966386],
  ["KR", 64.41835557752513],
  ["SC", 60.254160487724505],
  ["ST", 83.58625803262481],
  ["PY", 70.46506838029329],
  ["SB", 85.98368759268413],
  ["IL", 72.2800296589224],
  ["DK", 48.444554292305156],
  ["DO", 59.18973471741638],
  ["RU", 84.25152413906739],
  ["SG", 73.65669797330698],
  ["PK", 85.37485582468281],
  ["GB", 61.37296094908552],
  ["GU", 75.3068874608667],
  ["GW", 77.38919097050585],
  ["JP", 58.10841983852364],
  ["SR", 76.79971988795519],
  ["SE", 87.9959630911188],
  ["SD", 72.28579667160983],
  ["SS", 74.44801449991762],
  ["PH", 67.77805239742956],
  ["RW", 88.12242544076454],
  ["KU", 69.51680672268907],
  ["HN", 82.70720052726973],
  ["GA", 88.83259186027352],
  ["DZ", 73.11047948591202],
  ["DM", 90.10874938210578],
  ["FR", 81.51466468940517],
  ["GL", 54.10528917449333],
  ["ES", 56.05865875762069],
  ["HT", 70.23768330861756],
  ["PE", 55.615422639644095],
  ["QA", 85.35384742132148],
  ["SI", 83.62621519195915],
  ["PR", 77.56796836381612],
  ["PS", 81.07307628933927],
  ["IQ", 71.56862745098039],
  ["HU", 70.4757785467128],
  ["KN", 74.99958807052232],
  ["KY", 82.38919097050585],
  ["FI", 69.05009062448508],
  ["ER", 84.61443400889767],
  ["GM", 78.12736859449663],
  ["EE", 82.68330861756468],
  ["EG", 72.0023891909705],
  ["ID", 55.87411435162301],
  ["IS", 88.1323117482287],
  ["SK", 82.1651013346515],
  ["RO", 63.13313560718405],
  ["PG", 71.89158016147636],
  ["KM", 76.82649530400396],
  ["IR", 77.32451804251113],
  ["KZ", 90.26404679518866],
  ["IE", 82.70266930301531],
  ["GN", 78.10800790904597],
  ["FJ", 82.42626462349646],
  ["GY", 79.34462020102158],
  ["JM", 85.27393310265282],
  ["KI", 59.43442082715439],
  ["HR", 80.52191464821222],
  ["SO", 94.57200527269731],
  ["PT", 90.06426099851706],
  ["SY", 72.21741637831603],
  ["SN", 87.30062613280606],
  ["KH", 86.02858790575053],
  ["EC", 82.31627945295766],
  ["ET", 71.159169550173],
  ["DE", 88.95205140879881],
  ["SL", 83.54836052067886],
  ["PW", 93.45814796506838],
  ["SZ", 85.72581973966057],
  ["PA", 66.05824682814301],
  ["SM", 77.88309441423628],
  ["IT", 80.72870324600429],
  ["JO", 79.57900807381776],
  ["GH", 76.42609985170539],
  ["CG", 71.91176470588235],
  ["BT", 88.82517712967541],
  ["AO", 76.56327236777064],
  ["ML", 72.52018454440599],
  ["TN", 88.5553633217993],
  ["LI", 62.345526445872466],
  ["MM", 84.84923381117153],
  ["MZ", 78.85030482781347],
  ["NA", 69.96622178283079],
  ["CF", 71.08255066732575],
  ["BB", 79.62885154061624],
  ["CD", 83.39182731916296],
  ["BW", 97.94817927170868],
  ["AL", 89.07563025210085],
  ["NC", 89.68240237271378],
  ["MX", 65.365793376174],
  ["TZ", 90.42881858625803],
  ["VE", 68.76833086175647],
  ["MN", 68.65875762069533],
  ["MY", 61.20983687592684],
  ["AM", 84.45584115999341],
  ["CR", 66.32105783489867],
  ["BA", 83.60108749382105],
  ["AZ", 92.70184544405997],
  ["CV", 78.96605701103971],
  ["BR", 86.34494974460372],
  ["CA", 78.43796342066238],
  ["BE", 89.07563025210085],
  ["TH", 83.45608831768001],
  ["WS", 48.9553468446202],
  ["UZ", 84.03526116328884],
  ["Wales", 70.46753995715933],
  ["NG", 81.63700774427419],
  ["NP", 59.09334321964079],
  ["MK", 76.10561871807546],
  ["BD", 92.83201515900478],
  ["BS", 89.86323941341242],
  ["CU", 74.76767177459219],
  ["BF", 94.8805404514747],
  ["NR", 80.85351787773934],
  ["NE", 84.65397923875433],
  ["ZW", 83.02438622507826],
  ["VC", 84.23216345361674],
  ["UY", 72.77310924369748],
  ["TJ", 74.56912176635359],
  ["VU", 74.08469270060965],
  ["ZA", 87.55066732575384],
  ["BG", 72.09960454770143],
]);

export const byMap = new Map<string, number>([
  ["CY", 90.0008650519031],
  ["AF", 89.85640138408304],
  ["CN", 89.23529411764706],
  ["BJ", 91.64403114186851],
  ["LA", 92.05709342560554],
  ["ME", 82.23918685121107],
  ["OM", 88.29801038062284],
  ["NI", 92.80666089965398],
  ["MR", 89.55925605536332],
  ["TG", 90.35164359861592],
  ["TP", 93.73096885813149],
  ["North Korea", 88.2720588235294],
  ["VN", 89.64230103806229],
  ["ZM", 87.6985294117647],
  ["MD", 88.4446366782007],
  ["CO", 92.69723183391004],
  ["AG", 89.17690311418686],
  ["AE", 92.79282006920415],
  ["CZ", 90.49913494809688],
  ["AR", 93.35164359861592],
  ["BI", 84.25821799307958],
  ["CM", 90.98356401384083],
  ["LB", 89.24524221453287],
  ["LU", 92.54411764705883],
  ["TD", 95.0151384083045],
  ["TR", 88.78979238754326],
  ["Scotland", 86.09126297577855],
  ["UA", 93.58217993079585],
  ["LT", 93.01946366782006],
  ["LC", 96.09688581314879],
  ["MG", 93.02162629757785],
  ["CL", 90.37716262975779],
  ["BH", 90.43901384083046],
  ["AD", 91.43685121107266],
  ["CH", 90.12543252595155],
  ["NO", 88.33737024221453],
  ["MT", 61.107698961937714],
  ["MC", 94.18814878892734],
  ["TV", 75.71453287197232],
  ["TW", 93.27162629757785],
  ["US", 61.86159169550173],
  ["MU", 92.26427335640139],
  ["BZ", 81.9537197231834],
  ["CI", 91.97837370242215],
  ["BM", 51.870674740484425],
  ["CK", 83.28806228373702],
  ["AT", 93.1137543252595],
  ["BO", 92.0181660899654],
  ["LS", 94.10726643598616],
  ["NL", 64.48226643598616],
  ["MW", 89.41695501730104],
  ["YE", 95.6682525951557],
  ["UG", 92.48226643598616],
  ["TT", 88.93598615916956],
  ["NZ", 82.54801038062284],
  ["MA", 34.489619377162626],
  ["MV", 89.51254325259515],
  ["LR", 92.95977508650519],
  ["BY", 0],
  ["BN", 89.58564013840831],
  ["AU", 93.50432525951558],
  ["GE", 87.67906574394463],
  ["GR", 92.19593425605537],
  ["IN", 94.14143598615917],
  ["GBENG", 92.83650519031141],
  ["RS", 59.46669550173011],
  ["PL", 79.60640138408304],
  ["SV", 92.61721453287197],
  ["SA", 84.07828719723184],
  ["HK", 87.58477508650519],
  ["KG", 82.72923875432527],
  ["GD", 81.8741349480969],
  ["GQ", 91.00562283737024],
  ["DJ", 91.72448096885813],
  ["KE", 91.56617647058823],
  ["KR", 90.7136678200692],
  ["SC", 61.03373702422146],
  ["ST", 91.21064013840831],
  ["PY", 92.02465397923876],
  ["SB", 84.8317474048443],
  ["IL", 91.05622837370242],
  ["DK", 92.09126297577855],
  ["DO", 91.60337370242215],
  ["RU", 92.86548442906575],
  ["SG", 90.32439446366783],
  ["PK", 92.17430795847751],
  ["GB", 64.33044982698962],
  ["GU", 80.63754325259515],
  ["GW", 90.5765570934256],
  ["JP", 92.93122837370242],
  ["SR", 90.4446366782007],
  ["SE", 92.06228373702422],
  ["SD", 92.40138408304497],
  ["SS", 89.67084775086505],
  ["PH", 90.90051903114187],
  ["RW", 88.18814878892734],
  ["KU", 97.4727508650519],
  ["HN", 92.4113321799308],
  ["GA", 92.56358131487889],
  ["DZ", 89.20891003460207],
  ["DM", 81.90873702422145],
  ["FR", 93.98832179930795],
  ["GL", 90.8832179930796],
  ["ES", 88.18728373702423],
  ["HT", 89.83001730103807],
  ["PE", 92.55666089965398],
  ["QA", 92.04887543252596],
  ["SI", 90.2863321799308],
  ["PR", 84.55622837370242],
  ["PS", 92.1825259515571],
  ["IQ", 93.77465397923875],
  ["HU", 93.3196366782007],
  ["KN", 89.8235294117647],
  ["KY", 81.18166089965399],
  ["FI", 93.9515570934256],
  ["ER", 76.44506920415225],
  ["GM", 92.98313148788927],
  ["EE", 95.45717993079585],
  ["EG", 94.50346020761245],
  ["ID", 94.18771626297577],
  ["IS", 78.79282006920415],
  ["SK", 87.89792387543253],
  ["RO", 93.6137543252595],
  ["PG", 92.82698961937716],
  ["KM", 86.42603806228374],
  ["IR", 85.56228373702423],
  ["KZ", 84.14186851211073],
  ["IE", 92.74783737024221],
  ["GN", 92.67171280276817],
  ["FJ", 86.0787197231834],
  ["GY", 88.07612456747404],
  ["JM", 92.78114186851211],
  ["KI", 87.5272491349481],
  ["HR", 87.86807958477509],
  ["SO", 90.16046712802768],
  ["PT", 84.85640138408304],
  ["SY", 94.2833044982699],
  ["SN", 69.76643598615917],
  ["KH", 87.67690311418686],
  ["EC", 86.00951557093425],
  ["ET", 82.13581314878893],
  ["DE", 94.07742214532871],
  ["SL", 93.10034602076125],
  ["PW", 89.19550173010381],
  ["SZ", 81.89186851211072],
  ["PA", 92.05795847750865],
  ["SM", 88.62283737024221],
  ["IT", 70.95198961937716],
  ["JO", 90.05320069204153],
  ["GH", 92.1340830449827],
  ["CG", 89.07698961937717],
  ["BT", 77.55622837370242],
  ["AO", 93.98832179930795],
  ["ML", 91.77249134948097],
  ["TN", 88.55925605536332],
  ["LI", 90.10553633217994],
  ["MM", 89.5817474048443],
  ["MZ", 89.72967128027682],
  ["NA", 82.84212802768167],
  ["CF", 89.6924740484429],
  ["BB", 94.08780276816609],
  ["CD", 87.23053633217994],
  ["BW", 93.91262975778547],
  ["AL", 44.73529411764706],
  ["NC", 88.90657439446366],
  ["MX", 89.17906574394465],
  ["TZ", 89.50216262975779],
  ["VE", 89.16176470588235],
  ["MN", 57.8931660899654],
  ["MY", 76.76427335640138],
  ["AM", 93.17084775086505],
  ["CR", 90.98399653979239],
  ["BA", 89.36764705882354],
  ["AZ", 89.00519031141869],
  ["CV", 85.3317474048443],
  ["BR", 83.80406574394463],
  ["CA", 88.26254325259517],
  ["BE", 98.67949826989619],
  ["TH", 93.80103806228374],
  ["WS", 89.27508650519032],
  ["UZ", 88.12716262975778],
  ["Wales", 71.87673010380622],
  ["NG", 92.71885813148789],
  ["NP", 72.06704152249135],
  ["MK", 80.76254325259515],
  ["BD", 87.01254325259515],
  ["BS", 97.16911764705883],
  ["CU", 83.57396193771626],
  ["BF", 89.75216262975778],
  ["NR", 92.98183391003461],
  ["NE", 92.0432525951557],
  ["ZW", 89.43295847750865],
  ["VC", 90.04195501730104],
  ["UY", 91.17128027681662],
  ["TJ", 94.79152249134948],
  ["VU", 94.10207612456747],
  ["ZA", 71.95242214532873],
  ["BG", 92.66608996539793],
]);

export const bnMap = new Map<string, number>([
  ["CY", 73.46107266435986],
  ["AF", 86.17474048442907],
  ["CN", 92.23269896193771],
  ["BJ", 69.90051903114187],
  ["LA", 90.69723183391002],
  ["ME", 89.87370242214533],
  ["OM", 88.12629757785467],
  ["NI", 82.30017301038063],
  ["MR", 89.52595155709344],
  ["TG", 76.36115916955018],
  ["TP", 85.61115916955018],
  ["North Korea", 87.1401384083045],
  ["VN", 93.85553633217994],
  ["ZM", 90.28719723183391],
  ["MD", 76.36894463667821],
  ["CO", 58.599913494809684],
  ["AG", 91.73615916955016],
  ["AE", 78.13235294117648],
  ["CZ", 81.79671280276817],
  ["AR", 80.46064013840831],
  ["BI", 82.71064013840831],
  ["CM", 73.25346020761245],
  ["LB", 76.5211937716263],
  ["LU", 82.03503460207612],
  ["TD", 76.56877162629758],
  ["TR", 90.57742214532873],
  ["Scotland", 80.31574394463668],
  ["UA", 69.90614186851211],
  ["LT", 91.77768166089966],
  ["LC", 87.14835640138409],
  ["MG", 84.2954152249135],
  ["CL", 83.26859861591696],
  ["BH", 82.8265570934256],
  ["AD", 75.52205882352942],
  ["CH", 90.7439446366782],
  ["NO", 84.40614186851211],
  ["MT", 83.10553633217992],
  ["MC", 89.9515570934256],
  ["TV", 83.96237024221453],
  ["TW", 89.76643598615917],
  ["US", 74.46107266435986],
  ["MU", 89.01903114186851],
  ["BZ", 83.56271626297578],
  ["CI", 89.02768166089965],
  ["BM", 83.07742214532871],
  ["CK", 79.7136678200692],
  ["AT", 81.55579584775087],
  ["BO", 87.05709342560553],
  ["LS", 78.25648788927336],
  ["NL", 81.44766435986159],
  ["MW", 93.43295847750865],
  ["YE", 77.53546712802768],
  ["UG", 73.14359861591696],
  ["TT", 88.05968858131487],
  ["NZ", 80.79195501730105],
  ["MA", 93.25562283737024],
  ["MV", 94.15268166089965],
  ["LR", 73.81055363321799],
  ["BY", 89.58564013840831],
  ["BN", 0],
  ["AU", 79.8719723183391],
  ["GE", 75.33347750865052],
  ["GR", 75.89489619377161],
  ["IN", 79.64100346020761],
  ["GBENG", 77.82136678200692],
  ["RS", 88.81185121107266],
  ["PL", 77.08520761245676],
  ["SV", 81.10726643598616],
  ["SA", 90.14965397923875],
  ["HK", 93.72967128027682],
  ["KG", 93.33564013840831],
  ["GD", 78.09861591695501],
  ["GQ", 82.74221453287197],
  ["DJ", 82.0514705882353],
  ["KE", 84.30493079584775],
  ["KR", 70.62629757785467],
  ["SC", 81.17906574394463],
  ["ST", 85.14489619377163],
  ["PY", 80.68987889273357],
  ["SB", 84.67257785467129],
  ["IL", 72.25086505190312],
  ["DK", 85.16349480968857],
  ["DO", 82.96237024221453],
  ["RU", 79.94290657439447],
  ["SG", 87.55320069204153],
  ["PK", 82.965830449827],
  ["GB", 73.10553633217994],
  ["GU", 90.5916955017301],
  ["GW", 67.08693771626298],
  ["JP", 70.96064013840831],
  ["SR", 84.22318339100346],
  ["SE", 82.53762975778547],
  ["SD", 78.02551903114187],
  ["SS", 90.4446366782007],
  ["PH", 84.06963667820068],
  ["RW", 88.17128027681662],
  ["KU", 77.95242214532871],
  ["HN", 81.60467128027682],
  ["GA", 86.8871107266436],
  ["DZ", 81.06877162629758],
  ["DM", 82.5121107266436],
  ["FR", 88.83650519031143],
  ["GL", 83.12543252595155],
  ["ES", 79.5583910034602],
  ["HT", 92.46626297577855],
  ["PE", 90.49307958477509],
  ["QA", 78.9515570934256],
  ["SI", 85.16479238754326],
  ["PR", 88.30925605536332],
  ["PS", 84.18555363321799],
  ["IQ", 77.75562283737024],
  ["HU", 81.52551903114187],
  ["KN", 85.88148788927336],
  ["KY", 80.63062283737025],
  ["FI", 79.99134948096885],
  ["ER", 90.90570934256056],
  ["GM", 87.1682525951557],
  ["EE", 86.56098615916954],
  ["EG", 78.19723183391002],
  ["ID", 89.95588235294117],
  ["IS", 83.9545847750865],
  ["SK", 84.1833910034602],
  ["RO", 76.73313148788927],
  ["PG", 80.5583910034602],
  ["KM", 62.68209342560554],
  ["IR", 77.99480968858131],
  ["KZ", 91.5220588235294],
  ["IE", 89.01903114186851],
  ["GN", 78.16349480968859],
  ["FJ", 83.12326989619378],
  ["GY", 83.32871972318338],
  ["JM", 66.86548442906575],
  ["KI", 83.38192041522491],
  ["HR", 80.09645328719724],
  ["SO", 93.63365051903114],
  ["PT", 91.34558823529412],
  ["SY", 78.33347750865052],
  ["SN", 93.66046712802768],
  ["KH", 94.05622837370242],
  ["EC", 61.06833910034602],
  ["ET", 85.11980968858131],
  ["DE", 70.63754325259517],
  ["SL", 81.32915224913495],
  ["PW", 88.8810553633218],
  ["SZ", 79.50865051903115],
  ["PA", 77.28070934256056],
  ["SM", 77.46929065743944],
  ["IT", 90.49740484429066],
  ["JO", 85.4848615916955],
  ["GH", 86.49524221453288],
  ["CG", 90.36202422145328],
  ["BT", 84.60553633217994],
  ["AO", 84.56487889273356],
  ["ML", 78.16695501730105],
  ["TN", 93.62846020761245],
  ["LI", 91.07352941176471],
  ["MM", 68.13667820069205],
  ["MZ", 72.57309688581314],
  ["NA", 85.88321799307958],
  ["CF", 64.82698961937716],
  ["BB", 74.70198961937716],
  ["CD", 86.94809688581316],
  ["BW", 84.3810553633218],
  ["AL", 94.57266435986159],
  ["NC", 89.840830449827],
  ["MX", 90.57179930795847],
  ["TZ", 86.42214532871972],
  ["VE", 63.260380622837374],
  ["MN", 88.10467128027682],
  ["MY", 77.10640138408304],
  ["AM", 89.96885813148789],
  ["CR", 81.73832179930795],
  ["BA", 72.30493079584775],
  ["AZ", 93.48096885813149],
  ["CV", 86.76600346020761],
  ["BR", 85.10250865051903],
  ["CA", 84.51903114186851],
  ["BE", 86.74351211072664],
  ["TH", 79.63667820069205],
  ["WS", 91.6401384083045],
  ["UZ", 81.24437716262976],
  ["Wales", 80.22318339100346],
  ["NG", 90.26946366782008],
  ["NP", 81.53070934256056],
  ["MK", 72.73183391003461],
  ["BD", 93.88105536332179],
  ["BS", 84.38365051903114],
  ["CU", 84.03287197231833],
  ["BF", 93.77681660899654],
  ["NR", 87.25043252595157],
  ["NE", 81.83953287197232],
  ["ZW", 72.05276816608996],
  ["VC", 67.59385813148789],
  ["UY", 71.53503460207612],
  ["TJ", 77.2720588235294],
  ["VU", 84.46280276816609],
  ["ZA", 80.44031141868511],
  ["BG", 83.59558823529412],
]);

export const auMap = new Map<string, number>([
  ["CY", 87.99524221453288],
  ["AF", 86.56314878892734],
  ["CN", 93.30839100346022],
  ["BJ", 92.45025951557093],
  ["LA", 84.25389273356402],
  ["ME", 82.09256055363322],
  ["OM", 88.37326989619378],
  ["NI", 92.1068339100346],
  ["MR", 83.47621107266437],
  ["TG", 86.03200692041523],
  ["TP", 91.5696366782007],
  ["North Korea", 89.38148788927336],
  ["VN", 93.31012110726643],
  ["ZM", 90.72404844290658],
  ["MD", 84.00173010380622],
  ["CO", 86.64230103806229],
  ["AG", 85.44290657439446],
  ["AE", 89.07698961937717],
  ["CZ", 85.69679930795849],
  ["AR", 96.04671280276817],
  ["BI", 85.56401384083046],
  ["CM", 89.33910034602076],
  ["LB", 91.17647058823529],
  ["LU", 92.71020761245676],
  ["TD", 86.84602076124568],
  ["TR", 81.08823529411765],
  ["Scotland", 88.11764705882354],
  ["UA", 87.50519031141869],
  ["LT", 91.33434256055364],
  ["LC", 96.40570934256056],
  ["MG", 90.59256055363322],
  ["CL", 90.25173010380622],
  ["BH", 84.25346020761245],
  ["AD", 73.35813148788928],
  ["CH", 82.0090830449827],
  ["NO", 89.88019031141869],
  ["MT", 86.82871972318338],
  ["MC", 87.19290657439447],
  ["TV", 82.30103806228374],
  ["TW", 80.840830449827],
  ["US", 84.22707612456747],
  ["MU", 78.9446366782007],
  ["BZ", 83.52595155709342],
  ["CI", 95.70198961937716],
  ["BM", 75.45328719723183],
  ["CK", 73.8075259515571],
  ["AT", 92.70328719723183],
  ["BO", 91.0575259515571],
  ["LS", 87.1068339100346],
  ["NL", 88.73356401384083],
  ["MW", 85.15441176470588],
  ["YE", 88.55276816608996],
  ["UG", 79.75389273356402],
  ["TT", 84.7076124567474],
  ["NZ", 76.37456747404843],
  ["MA", 92.40181660899654],
  ["MV", 87.4991349480969],
  ["LR", 85.8326124567474],
  ["BY", 93.50432525951558],
  ["BN", 79.8719723183391],
  ["AU", 0],
  ["GE", 82.42171280276817],
  ["GR", 87.11159169550173],
  ["IN", 94.70847750865052],
  ["GBENG", 86.41435986159169],
  ["RS", 88.65051903114187],
  ["PL", 90.33304498269896],
  ["SV", 88.2772491349481],
  ["SA", 81.51730103806229],
  ["HK", 93.30622837370242],
  ["KG", 91.96020761245674],
  ["GD", 84.64273356401384],
  ["GQ", 92.6159169550173],
  ["DJ", 92.9727508650519],
  ["KE", 82.60856401384083],
  ["KR", 85.06098615916954],
  ["SC", 88.30233564013841],
  ["ST", 88.16565743944636],
  ["PY", 87.67517301038062],
  ["SB", 80.7923875432526],
  ["IL", 85.23183391003461],
  ["DK", 87.40743944636678],
  ["DO", 88.12586505190312],
  ["RU", 87.5250865051903],
  ["SG", 90.95631487889273],
  ["PK", 83.84904844290656],
  ["GB", 77.215830449827],
  ["GU", 84.39359861591696],
  ["GW", 87.18858131487889],
  ["JP", 86.16955017301038],
  ["SR", 89.56098615916956],
  ["SE", 86.93209342560554],
  ["SD", 85.70761245674741],
  ["SS", 87.41955017301038],
  ["PH", 86.23053633217992],
  ["RW", 92.05103806228374],
  ["KU", 87.86332179930795],
  ["HN", 92.77032871972318],
  ["GA", 92.54152249134948],
  ["DZ", 85.33391003460208],
  ["DM", 83.60726643598616],
  ["FR", 89.72361591695501],
  ["GL", 86.08131487889274],
  ["ES", 85.83953287197232],
  ["HT", 84.00648788927334],
  ["PE", 87.3326124567474],
  ["QA", 84.32093425605537],
  ["SI", 86.784169550173],
  ["PR", 81.69117647058823],
  ["PS", 88.92430795847751],
  ["IQ", 88.01643598615917],
  ["HU", 90.08434256055364],
  ["KN", 82.54801038062284],
  ["KY", 74.90181660899654],
  ["FI", 86.60596885813149],
  ["ER", 83.98745674740485],
  ["GM", 72.94074394463668],
  ["EE", 89.1038062283737],
  ["EG", 88.64316608996539],
  ["ID", 87.42301038062283],
  ["IS", 88.40138408304499],
  ["SK", 86.67733564013841],
  ["RO", 86.83823529411765],
  ["PG", 83.85510380622837],
  ["KM", 89.70242214532873],
  ["IR", 85.8317474048443],
  ["KZ", 93.55579584775087],
  ["IE", 92.80709342560553],
  ["GN", 89.36202422145328],
  ["FJ", 79.99005190311419],
  ["GY", 87.71929065743944],
  ["JM", 86.32958477508652],
  ["KI", 80.09126297577855],
  ["HR", 53.32915224913495],
  ["SO", 93.6734429065744],
  ["PT", 81.5886678200692],
  ["SY", 87.83434256055364],
  ["SN", 92.65008650519032],
  ["KH", 83.55363321799308],
  ["EC", 86.30709342560554],
  ["ET", 83.09385813148789],
  ["DE", 88.53200692041523],
  ["SL", 92.6007785467128],
  ["PW", 96.57828719723184],
  ["SZ", 81.19160899653978],
  ["PA", 85.73096885813149],
  ["SM", 88.61634948096886],
  ["IT", 92.72923875432527],
  ["JO", 87.8871107266436],
  ["GH", 86.55839100346022],
  ["CG", 92.22318339100346],
  ["BT", 97.21453287197231],
  ["AO", 80.1007785467128],
  ["ML", 89.90397923875433],
  ["TN", 79.52292387543253],
  ["LI", 85.24826989619378],
  ["MM", 91.20112456747404],
  ["MZ", 83.56228373702422],
  ["NA", 83.12846020761245],
  ["CF", 86.97534602076125],
  ["BB", 84.70242214532871],
  ["CD", 89.79152249134948],
  ["BW", 95.50216262975778],
  ["AL", 86.39749134948097],
  ["NC", 87.70934256055364],
  ["MX", 86.20112456747405],
  ["TZ", 86.61764705882354],
  ["VE", 83.48659169550173],
  ["MN", 90.03200692041521],
  ["MY", 87.26773356401384],
  ["AM", 86.94593425605537],
  ["CR", 85.0614186851211],
  ["BA", 82.65570934256056],
  ["AZ", 88.32223183391004],
  ["CV", 85.38970588235294],
  ["BR", 88.18295847750865],
  ["CA", 81.35250865051903],
  ["BE", 92.68382352941177],
  ["TH", 87.81877162629758],
  ["WS", 85.27335640138408],
  ["UZ", 91.05233564013841],
  ["Wales", 72.42474048442907],
  ["NG", 92.6310553633218],
  ["NP", 84.54541522491348],
  ["MK", 75.94290657439447],
  ["BD", 85.98442906574394],
  ["BS", 91.74826989619376],
  ["CU", 84.49264705882354],
  ["BF", 93.9757785467128],
  ["NR", 85.32525951557093],
  ["NE", 92.89143598615917],
  ["ZW", 88.09212802768165],
  ["VC", 91.72534602076125],
  ["UY", 86.36159169550173],
  ["TJ", 86.94852941176471],
  ["VU", 82.98269896193771],
  ["ZA", 88.11807958477509],
  ["BG", 91.8780276816609],
]);

export const geMap = new Map<string, number>([
  ["CY", 37.391897156213474],
  ["AF", 89.78249577976885],
  ["CN", 91.72380210362292],
  ["BJ", 91.17160109076742],
  ["LA", 92.49642903519023],
  ["ME", 88.20055836904298],
  ["OM", 73.45474613686534],
  ["NI", 84.28385923905986],
  ["MR", 90.63011297234127],
  ["TG", 87.76847162706142],
  ["TP", 91.1914037138034],
  ["North Korea", 86.95136995195429],
  ["VN", 91.34430593429424],
  ["ZM", 93.47065316192702],
  ["MD", 87.66264121542658],
  ["CO", 90.8989092325672],
  ["AG", 88.54402025710947],
  ["AE", 88.63718997532789],
  ["CZ", 69.93669653291781],
  ["AR", 86.85657706791325],
  ["BI", 74.36014803272303],
  ["CM", 90.08830022075055],
  ["LB", 68.06778340475262],
  ["LU", 84.53739774055317],
  ["TD", 91.97019867549669],
  ["TR", 64.21438774185171],
  ["Scotland", 67.53148941695883],
  ["UA", 91.41053109985717],
  ["LT", 94.13972211401116],
  ["LC", 96.18783274899364],
  ["MG", 71.29853265809635],
  ["CL", 74.47149720815477],
  ["BH", 71.63550188287235],
  ["AD", 89.49227373068432],
  ["CH", 79.75490196078432],
  ["NO", 86.53811193351513],
  ["MT", 65.98039215686275],
  ["MC", 64.06505648617062],
  ["TV", 81.2102324373458],
  ["TW", 68.98065186339436],
  ["US", 72.07570445396702],
  ["MU", 92.81456953642385],
  ["BZ", 80.49961044020257],
  ["CI", 81.58420984287756],
  ["BM", 82.34807167900273],
  ["CK", 83.07654850019478],
  ["AT", 84.7789248149591],
  ["BO", 94.29554603298273],
  ["LS", 79.23743669653291],
  ["NL", 84.68932606155045],
  ["MW", 93.56901701077783],
  ["YE", 86.62478898844306],
  ["UG", 91.29950655758992],
  ["TT", 88.57875600571354],
  ["NZ", 84.55298013245033],
  ["MA", 89.784443578756],
  ["MV", 91.6725100636281],
  ["LR", 68.66510842747695],
  ["BY", 88.02817815868069],
  ["BN", 75.25094143617711],
  ["AU", 82.43345020127256],
  ["GE", 0],
  ["GR", 70.92487988572913],
  ["IN", 84.62602259446825],
  ["GBENG", 32.34320218153486],
  ["RS", 67.47630177898975],
  ["PL", 64.92403583950136],
  ["SV", 83.9595507077003],
  ["SA", 86.77963900792105],
  ["HK", 88.95760290871316],
  ["KG", 86.3718997532788],
  ["GD", 84.32703544994156],
  ["GQ", 85.43143747565252],
  ["DJ", 79.16212180236333],
  ["KE", 82.69055966757564],
  ["KR", 41.22159459810415],
  ["SC", 80.26977015971953],
  ["ST", 91.60433709907804],
  ["PY", 83.07460070120763],
  ["SB", 84.77957408128815],
  ["IL", 53.138553434618885],
  ["DK", 84.8422282820413],
  ["DO", 94.69516945851188],
  ["RU", 68.63946240747954],
  ["SG", 61.39429944163096],
  ["PK", 74.5753798208025],
  ["GB", 66.62738605375925],
  ["GU", 89.11504999350733],
  ["GW", 90.45610959615634],
  ["JP", 39.683482664589015],
  ["SR", 78.80437605505779],
  ["SE", 94.68543046357615],
  ["SD", 88.8180106479678],
  ["SS", 86.93903389170238],
  ["PH", 80.35352551616674],
  ["RW", 92.51006362810024],
  ["KU", 88.25120114270874],
  ["HN", 84.35495390209063],
  ["GA", 93.74009868848202],
  ["DZ", 61.19562394494221],
  ["DM", 87.01402415270744],
  ["FR", 79.54811063498248],
  ["GL", 63.826126477080905],
  ["ES", 90.15614855213609],
  ["HT", 90.68887157512012],
  ["PE", 79.25951175172055],
  ["QA", 72.29970133748864],
  ["SI", 67.83859239059863],
  ["PR", 59.66595247370471],
  ["PS", 88.67192572393195],
  ["IQ", 85.07499026100507],
  ["HU", 84.87501623165824],
  ["KN", 86.62024412413972],
  ["KY", 83.60180496039476],
  ["FI", 50.23698221010259],
  ["ER", 85.72295805739515],
  ["GM", 84.47052330866121],
  ["EE", 71.81242695753798],
  ["EG", 86.5416828983249],
  ["ID", 64.28580703804701],
  ["IS", 88.45247370471367],
  ["SK", 66.69328658615764],
  ["RO", 92.05947279574082],
  ["PG", 91.8153486560187],
  ["KM", 80.73561875081158],
  ["IR", 78.59596156343332],
  ["KZ", 86.42156862745098],
  ["IE", 81.07843137254902],
  ["GN", 91.70886897805481],
  ["FJ", 86.95883651473835],
  ["GY", 86.49655888845604],
  ["JM", 88.61868588495001],
  ["KI", 79.71334891572523],
  ["HR", 77.32112712634722],
  ["SO", 91.4608492403584],
  ["PT", 72.53830671341385],
  ["SY", 85.35125308401507],
  ["SN", 90.57784703285287],
  ["KH", 88.46740683028177],
  ["EC", 85.15679781846514],
  ["ET", 88.01519283209973],
  ["DE", 92.21269964939619],
  ["SL", 84.69192312686664],
  ["PW", 96.84943513829373],
  ["SZ", 86.15569406570576],
  ["PA", 65.04707180885599],
  ["SM", 63.8248279444228],
  ["IT", 79.3315803142449],
  ["JO", 88.51999740293468],
  ["GH", 94.11959485781067],
  ["CG", 88.59239059862357],
  ["BT", 89.05596675756395],
  ["AO", 93.15381119335152],
  ["ML", 91.63939748084664],
  ["TN", 68.9770808985846],
  ["LI", 91.50175301908843],
  ["MM", 88.4703285287625],
  ["MZ", 87.45779768861188],
  ["NA", 84.92436047266588],
  ["CF", 81.44591611479028],
  ["BB", 91.66244643552785],
  ["CD", 88.6644591611479],
  ["BW", 95.0337618491105],
  ["AL", 88.84300740163616],
  ["NC", 89.63706012206207],
  ["MX", 76.91403713803402],
  ["TZ", 89.29846773146345],
  ["VE", 88.52681469938969],
  ["MN", 90.46032982729515],
  ["MY", 66.6806258927412],
  ["AM", 94.6510193481366],
  ["CR", 69.47571743929359],
  ["BA", 85.93429424750032],
  ["AZ", 90.79210492143878],
  ["CV", 85.23406051162186],
  ["BR", 86.27515907025062],
  ["CA", 47.82690559667576],
  ["BE", 94.37118556031685],
  ["TH", 71.4676665368134],
  ["WS", 91.59329957148422],
  ["UZ", 84.66205687573043],
  ["Wales", 60.41747824957797],
  ["NG", 79.40072717828853],
  ["NP", 56.93286586157642],
  ["MK", 81.4326061550448],
  ["BD", 91.30762238670303],
  ["BS", 94.01928320997273],
  ["CU", 70.46747175691469],
  ["BF", 90.92423061940008],
  ["NR", 91.09239059862355],
  ["NE", 83.36677054927931],
  ["ZW", 80.89598753408647],
  ["VC", 89.00077911959485],
  ["UY", 54.54161797169199],
  ["TJ", 77.13868328788469],
  ["VU", 91.24788988443059],
  ["ZA", 83.08823529411765],
  ["BG", 68.69854564342293],
]);

export const grMap = new Map<string, number>([
  ["CY", 58.47369784918372],
  ["AF", 91.9318476289194],
  ["CN", 94.88954392329619],
  ["BJ", 92.22175434050273],
  ["LA", 91.55318735423685],
  ["ME", 91.41033946618295],
  ["OM", 87.69273127753304],
  ["NI", 38.22298522933402],
  ["MR", 94.51477066597565],
  ["TG", 88.12580979528374],
  ["TP", 93.70432754599636],
  ["North Korea", 67.36719357346462],
  ["VN", 94.3900621922778],
  ["ZM", 96.55480694480435],
  ["MD", 72.79346981083182],
  ["CO", 89.81698626587199],
  ["AG", 85.13863695257838],
  ["AE", 82.8912930811091],
  ["CZ", 80.44959834153926],
  ["AR", 79.285436641617],
  ["BI", 79.6119461000259],
  ["CM", 91.16902047162478],
  ["LB", 77.4271184244623],
  ["LU", 78.43936252915263],
  ["TD", 92.37010883648612],
  ["TR", 93.47240217672972],
  ["Scotland", 45.87166364343094],
  ["UA", 61.94350868100544],
  ["LT", 98.08078517750712],
  ["LC", 94.51379891163513],
  ["MG", 83.72797356828194],
  ["CL", 82.8611686965535],
  ["BH", 82.80027209121535],
  ["AD", 91.79126716766002],
  ["CH", 89.41306037833637],
  ["NO", 84.7149520601192],
  ["MT", 77.66876133713397],
  ["MC", 76.2146929256284],
  ["TV", 83.66480953614926],
  ["TW", 92.7157294635916],
  ["US", 75.1422000518269],
  ["MU", 92.75654314589272],
  ["BZ", 58.640839595750194],
  ["CI", 84.3521637729982],
  ["BM", 86.41325472920445],
  ["CK", 86.45536408395958],
  ["AT", 78.6090956206271],
  ["BO", 90.17394402695],
  ["LS", 80.2873153666753],
  ["NL", 77.73160145115315],
  ["MW", 96.13371339725317],
  ["YE", 79.05286343612335],
  ["UG", 90.37509717543405],
  ["TT", 91.8230111427831],
  ["NZ", 88.31238662866028],
  ["MA", 96.10941953874061],
  ["MV", 95.59341798393366],
  ["LR", 79.34827675563618],
  ["BY", 92.12295931588494],
  ["BN", 75.58305260430164],
  ["AU", 86.61181653278052],
  ["GE", 70.8927183208085],
  ["GR", 0],
  ["IN", 79.0726224410469],
  ["GBENG", 68.70691889090438],
  ["RS", 81.2130733350609],
  ["PL", 78.11674008810573],
  ["SV", 37.46825602487691],
  ["SA", 90.15774812127493],
  ["HK", 91.08512568022805],
  ["KG", 89.37095102358124],
  ["GD", 84.03634361233479],
  ["GQ", 75.4952707955429],
  ["DJ", 84.49080072557658],
  ["KE", 91.63449080072557],
  ["KR", 63.82709251101322],
  ["SC", 81.93541072816792],
  ["ST", 87.9667660015548],
  ["PY", 78.60229334024359],
  ["SB", 69.88176988857217],
  ["IL", 76.77766260689299],
  ["DK", 91.1823011142783],
  ["DO", 84.1079295154185],
  ["RU", 85.62127494169474],
  ["SG", 72.80934179839336],
  ["PK", 82.92141746566469],
  ["GB", 71.91046903342834],
  ["GU", 92.69273127753304],
  ["GW", 91.38053899974086],
  ["JP", 62.99397512308889],
  ["SR", 89.8529411764706],
  ["SE", 57.47214304223892],
  ["SD", 81.44013993262503],
  ["SS", 83.79534853589013],
  ["PH", 87.10093288416688],
  ["RW", 91.47803835190463],
  ["KU", 82.18677118424462],
  ["HN", 41.280124384555585],
  ["GA", 90.19791396734905],
  ["DZ", 73.90483285825343],
  ["DM", 85.66824306815238],
  ["FR", 84.43735423684893],
  ["GL", 76.76794506348796],
  ["ES", 87.26872246696036],
  ["HT", 95.44797875097176],
  ["PE", 84.24170769629438],
  ["QA", 82.74229074889868],
  ["SI", 74.65211194610004],
  ["PR", 82.63183467219487],
  ["PS", 81.45763151075408],
  ["IQ", 79.54619072298523],
  ["HU", 78.66157035501425],
  ["KN", 91.15833117387925],
  ["KY", 87.59814718839077],
  ["FI", 74.64498574760302],
  ["ER", 86.73231407100285],
  ["GM", 88.83907748121274],
  ["EE", 88.1099378077222],
  ["EG", 79.54781031355273],
  ["ID", 76.41293081109094],
  ["IS", 85.5344648872765],
  ["SK", 75.26431718061673],
  ["RO", 92.26483544959834],
  ["PG", 93.09341798393366],
  ["KM", 81.83953096657164],
  ["IR", 71.00414615185281],
  ["KZ", 89.91318994558176],
  ["IE", 84.22939880798134],
  ["GN", 92.1401269759005],
  ["FJ", 87.0332339984452],
  ["GY", 85.68217154703291],
  ["JM", 87.38760041461518],
  ["KI", 80.2471495206012],
  ["HR", 77.09736978491837],
  ["SO", 94.26600155480695],
  ["PT", 91.31316403213268],
  ["SY", 78.11285307074371],
  ["SN", 91.16934438973827],
  ["KH", 91.608253433532],
  ["EC", 76.00770925110132],
  ["ET", 82.51651982378854],
  ["DE", 94.48723762632807],
  ["SL", 57.15697071780254],
  ["PW", 94.58927183208084],
  ["SZ", 83.27060119201866],
  ["PA", 66.32255765742421],
  ["SM", 77.2836227001814],
  ["IT", 84.29094324954652],
  ["JO", 82.25447006996632],
  ["GH", 89.79690334283494],
  ["CG", 88.91422648354495],
  ["BT", 90.77286861881316],
  ["AO", 95.30286343612335],
  ["ML", 91.98140710028505],
  ["TN", 91.26328064265353],
  ["LI", 95.35436641617],
  ["MM", 87.97583570873283],
  ["MZ", 88.69687742938585],
  ["NA", 87.77921741383778],
  ["CF", 83.10507903601969],
  ["BB", 92.18450375745012],
  ["CD", 91.1411635138637],
  ["BW", 91.2503239181135],
  ["AL", 93.81089660533817],
  ["NC", 92.78472402176729],
  ["MX", 85.50790360196943],
  ["TZ", 91.94674786214046],
  ["VE", 91.4987691111687],
  ["MN", 76.25518268981601],
  ["MY", 77.20491059860068],
  ["AM", 98.15399067115833],
  ["CR", 83.390450894014],
  ["BA", 87.34905415910858],
  ["AZ", 96.16448561803576],
  ["CV", 93.1452448820938],
  ["BR", 88.72279087846593],
  ["CA", 81.88131640321326],
  ["BE", 92.80545478103136],
  ["TH", 85.4282197460482],
  ["WS", 95.80202124902824],
  ["UZ", 76.5369914485618],
  ["Wales", 73.54269240735943],
  ["NG", 84.41467996890385],
  ["NP", 69.04023062969681],
  ["MK", 82.0040813682301],
  ["BD", 95.45769629437679],
  ["BS", 90.83214563358382],
  ["CU", 81.52435864213527],
  ["BF", 95.94551697330915],
  ["NR", 95.92187095102358],
  ["NE", 80.86324177247992],
  ["ZW", 86.15768333765224],
  ["VC", 76.20497538222337],
  ["UY", 92.95996372117129],
  ["TJ", 77.48736719357346],
  ["VU", 93.65735941953875],
  ["ZA", 86.83078517750712],
  ["BG", 85.84931329359938],
]);

export const inMap = new Map<string, number>([
  ["CY", 71.70172704843527],
  ["AF", 83.73912478898843],
  ["CN", 97.22406181015452],
  ["BJ", 96.13556680950526],
  ["LA", 93.32229580573951],
  ["ME", 94.90877808076873],
  ["OM", 72.35553824178677],
  ["NI", 68.60829762368523],
  ["MR", 95.23957927541878],
  ["TG", 92.01240098688483],
  ["TP", 92.88111933515127],
  ["North Korea", 94.48578106739384],
  ["VN", 95.24834437086092],
  ["ZM", 75.98720945331775],
  ["MD", 94.61563433320347],
  ["CO", 94.5471367354889],
  ["AG", 92.6834177379561],
  ["AE", 76.80528502791846],
  ["CZ", 88.39663680041554],
  ["AR", 69.28288533956629],
  ["BI", 84.95033112582782],
  ["CM", 95.99207895078561],
  ["LB", 71.10440202571094],
  ["LU", 68.4151408907934],
  ["TD", 94.52278924814959],
  ["TR", 94.14848720945331],
  ["Scotland", 85.84761719257239],
  ["UA", 94.53804700688222],
  ["LT", 97.42630827165303],
  ["LC", 96.3907284768212],
  ["MG", 87.87365277236722],
  ["CL", 89.3315803142449],
  ["BH", 86.51636151149201],
  ["AD", 94.75133099597454],
  ["CH", 86.81729645500585],
  ["NO", 86.86956239449422],
  ["MT", 81.19789637709388],
  ["MC", 83.56609531229711],
  ["TV", 87.92656797818465],
  ["TW", 97.4636410855733],
  ["US", 85.39605246071939],
  ["MU", 94.17153616413452],
  ["BZ", 85.10258407998961],
  ["CI", 90.60089598753409],
  ["BM", 89.59258537852227],
  ["CK", 91.48032723022985],
  ["AT", 68.4151408907934],
  ["BO", 91.1375146084924],
  ["LS", 69.92143877418518],
  ["NL", 68.4151408907934],
  ["MW", 97.85157771717958],
  ["YE", 68.4151408907934],
  ["UG", 94.6065446045968],
  ["TT", 93.95013634592911],
  ["NZ", 93.70990780418128],
  ["MA", 98.7589274120244],
  ["MV", 97.27665238280743],
  ["LR", 84.98863783924165],
  ["BY", 94.26990001298533],
  ["BN", 79.45591481625763],
  ["AU", 94.13874821451759],
  ["GE", 84.62602259446825],
  ["GR", 79.5338267757434],
  ["IN", 0],
  ["GBENG", 86.6092065965459],
  ["RS", 91.41150499935074],
  ["PL", 83.98714452668484],
  ["SV", 69.13517724970784],
  ["SA", 93.49532528243085],
  ["HK", 93.22036099207895],
  ["KG", 91.14465653811193],
  ["GD", 89.16471886767953],
  ["GQ", 74.06213478768991],
  ["DJ", 84.34034540968706],
  ["KE", 93.24990261005064],
  ["KR", 75.08862485391508],
  ["SC", 82.90936242046487],
  ["ST", 88.30963511232306],
  ["PY", 68.96604337099077],
  ["SB", 90.50350603817687],
  ["IL", 70.17952213998183],
  ["DK", 83.9394234514998],
  ["DO", 78.86345929100116],
  ["RU", 97.722373717699],
  ["SG", 82.7006232956759],
  ["PK", 87.54804570834956],
  ["GB", 78.3326840670043],
  ["GU", 96.2978833917673],
  ["GW", 96.0365536943254],
  ["JP", 76.93838462537332],
  ["SR", 96.45468121023244],
  ["SE", 95.75542137384755],
  ["SD", 76.90137644461758],
  ["SS", 93.09505259057266],
  ["PH", 85.85605765485002],
  ["RW", 90.18276847162706],
  ["KU", 76.00376574470849],
  ["HN", 69.30496039475392],
  ["GA", 91.17452278924814],
  ["DZ", 82.87689910401247],
  ["DM", 90.7077002986625],
  ["FR", 90.63628100246721],
  ["GL", 82.63245033112582],
  ["ES", 90.24412413972212],
  ["HT", 97.86358914426698],
  ["PE", 90.6106349824698],
  ["QA", 86.33716400467472],
  ["SI", 96.85495390209063],
  ["PR", 86.75399298792364],
  ["PS", 76.03622906116088],
  ["IQ", 70.44929229970134],
  ["HU", 68.4151408907934],
  ["KN", 89.78249577976885],
  ["KY", 91.18296325152578],
  ["FI", 93.56934164394235],
  ["ER", 87.25847292559408],
  ["GM", 88.39858459940267],
  ["EE", 98.62777561355668],
  ["EG", 69.13550188287235],
  ["ID", 83.5686923776133],
  ["IS", 88.61414102064667],
  ["SK", 95.37624983768342],
  ["RO", 94.58771588105441],
  ["PG", 93.17296455005844],
  ["KM", 83.76249837683419],
  ["IR", 59.072198415790155],
  ["KZ", 89.46305674587715],
  ["IE", 90.60673938449551],
  ["GN", 94.47766523828074],
  ["FJ", 90.7950266199195],
  ["GY", 87.05914816257629],
  ["JM", 88.08726139462408],
  ["KI", 83.58362550318141],
  ["HR", 67.03155434359174],
  ["SO", 96.4663680041553],
  ["PT", 93.49759771458253],
  ["SY", 70.22042591871185],
  ["SN", 94.97630177898975],
  ["KH", 93.26191403713804],
  ["EC", 91.06349824698091],
  ["ET", 89.45299311777691],
  ["DE", 98.32651603687833],
  ["SL", 68.63589144266979],
  ["PW", 91.91566030385664],
  ["SZ", 90.6885469419556],
  ["PA", 82.58862485391506],
  ["SM", 84.24847422412674],
  ["IT", 90.65965459031294],
  ["JO", 78.99071549149461],
  ["GH", 91.85625243474873],
  ["CG", 90.86579664978575],
  ["BT", 65.88040514218932],
  ["AO", 96.07843137254902],
  ["ML", 94.2776912089339],
  ["TN", 92.52791845214907],
  ["LI", 95.9771458252175],
  ["MM", 92.12310089598753],
  ["MZ", 94.31112842487988],
  ["NA", 90.54538371640047],
  ["CF", 83.10316841968576],
  ["BB", 94.72308791066095],
  ["CD", 91.15601869887028],
  ["BW", 91.1683547591222],
  ["AL", 97.52759381898454],
  ["NC", 92.80385664199454],
  ["MX", 92.20588235294119],
  ["TZ", 89.89384495520062],
  ["VE", 96.90754447474353],
  ["MN", 94.18971562134789],
  ["MY", 84.99383196987405],
  ["AM", 98.18010647967796],
  ["CR", 95.82197117257499],
  ["BA", 91.44948707960006],
  ["AZ", 97.72432151668615],
  ["CV", 89.29327360083106],
  ["BR", 90.70023373587846],
  ["CA", 90.31684196857552],
  ["BE", 94.65491494611089],
  ["TH", 98.2359433839761],
  ["WS", 97.61134917543176],
  ["UZ", 65.9096221269965],
  ["Wales", 82.5714192961953],
  ["NG", 90.6103103493053],
  ["NP", 80.3330736268017],
  ["MK", 84.01408907934035],
  ["BD", 97.10719387092585],
  ["BS", 90.04934424100766],
  ["CU", 86.98967666536814],
  ["BF", 98.37358784573432],
  ["NR", 95.81093364498116],
  ["NE", 71.25665497987275],
  ["ZW", 88.09732502272432],
  ["VC", 94.36144656538113],
  ["UY", 82.76490066225165],
  ["TJ", 68.4352681469939],
  ["VU", 92.72659394883782],
  ["ZA", 88.85664199454617],
  ["BG", 98.9124788988443],
]);

export const gbengMap = new Map<string, number>([
  ["CY", 32.52991637831603],
  ["AF", 92.56163494809688],
  ["CN", 69.02068915801614],
  ["BJ", 87.67985870818916],
  ["LA", 92.04801038062284],
  ["ME", 78.25836216839677],
  ["OM", 56.10978950403691],
  ["NI", 87.75951557093425],
  ["MR", 95.7742214532872],
  ["TG", 86.01138985005767],
  ["TP", 75.76052479815456],
  ["North Korea", 88.43353517877739],
  ["VN", 75.53777393310264],
  ["ZM", 96.612961361015],
  ["MD", 86.15160034602076],
  ["CO", 94.58153114186851],
  ["AG", 82.14496828143022],
  ["AE", 86.66162053056516],
  ["CZ", 61.98493367935409],
  ["AR", 87.21200980392156],
  ["BI", 67.903330449827],
  ["CM", 83.42596597462514],
  ["LB", 64.86663783160323],
  ["LU", 84.64568915801614],
  ["TD", 90.51326412918108],
  ["TR", 70.58499134948096],
  ["Scotland", 64.66803633217994],
  ["UA", 95.83297289504037],
  ["LT", 95.05622837370242],
  ["LC", 97.19759227220301],
  ["MG", 72.0674740484429],
  ["CL", 62.100634371395614],
  ["BH", 50.98615916955017],
  ["AD", 88.10914071510958],
  ["CH", 95.36728662053056],
  ["NO", 88.07922433679354],
  ["MT", 51.09465109573242],
  ["MC", 50],
  ["TV", 84.85258073817762],
  ["TW", 93.2136678200692],
  ["US", 63.29440599769319],
  ["MU", 93.66097174163784],
  ["BZ", 82.95343137254902],
  ["CI", 82.6946366782007],
  ["BM", 68.7932525951557],
  ["CK", 86.00958765859285],
  ["AT", 81.0705017301038],
  ["BO", 92.38934544405998],
  ["LS", 82.80781430219146],
  ["NL", 87.73644752018454],
  ["MW", 75.70321510957324],
  ["YE", 84.31228373702422],
  ["UG", 97.49170991926182],
  ["TT", 72.88747116493657],
  ["NZ", 88.10914071510958],
  ["MA", 70.33052191464822],
  ["MV", 85.34061418685121],
  ["LR", 56.945645905420996],
  ["BY", 92.32626874279123],
  ["BN", 77.61353806228374],
  ["AU", 86.42301038062283],
  ["GE", 32.72995963091119],
  ["GR", 68.70494521337946],
  ["IN", 86.64756343713957],
  ["GBENG", 0],
  ["RS", 67.83196366782008],
  ["PL", 50.245098039215684],
  ["SV", 87.4163783160323],
  ["SA", 91.6875720876586],
  ["HK", 74.93908592848905],
  ["KG", 74.96792099192618],
  ["GD", 84.3115628604383],
  ["GQ", 85.76881487889273],
  ["DJ", 79.24271914648212],
  ["KE", 86.85121107266436],
  ["KR", 34.85654555940023],
  ["SC", 75.15066320645906],
  ["ST", 91.38011822376009],
  ["PY", 82.61678200692042],
  ["SB", 88.89237312572088],
  ["IL", 54.03294405997693],
  ["DK", 76.54519896193773],
  ["DO", 96.49978373702423],
  ["RU", 65.8437860438293],
  ["SG", 49.59090253748558],
  ["PK", 75.95624279123415],
  ["GB", 40],
  ["GU", 93.05507497116494],
  ["GW", 92.41926182237601],
  ["JP", 23.470299884659745],
  ["SR", 62.97685986159169],
  ["SE", 97.71446078431373],
  ["SD", 87.17164071510958],
  ["SS", 78.66277393310264],
  ["PH", 74.31732987312573],
  ["RW", 96.21720011534025],
  ["KU", 87.721669550173],
  ["HN", 87.65787197231833],
  ["GA", 95.7590830449827],
  ["DZ", 59.68750000000001],
  ["DM", 88.10012975778547],
  ["FR", 76.9294261822376],
  ["GL", 50.20220588235295],
  ["ES", 87.48197808535178],
  ["HT", 84.71885813148788],
  ["PE", 69.72102076124568],
  ["QA", 72.97397635524798],
  ["SI", 66.71965109573242],
  ["PR", 73.81740196078431],
  ["PS", 85.6058967704729],
  ["IQ", 82.83845155709344],
  ["HU", 84.26758938869665],
  ["KN", 82.77537485582468],
  ["KY", 86.19088811995387],
  ["FI", 47.468641868512115],
  ["ER", 76.51348039215686],
  ["GM", 85.87081891580162],
  ["EE", 70.55363321799308],
  ["EG", 84.32525951557093],
  ["ID", 50],
  ["IS", 80.47109284890426],
  ["SK", 66.89338235294117],
  ["RO", 90.62319780853518],
  ["PG", 83.82857554786621],
  ["KM", 79.59955305651673],
  ["IR", 81.875],
  ["KZ", 92.2833765859285],
  ["IE", 82.72419261822375],
  ["GN", 90.3820645905421],
  ["FJ", 86.17502883506344],
  ["GY", 81.45076412918108],
  ["JM", 90.66969434832757],
  ["KI", 73.24646770472894],
  ["HR", 84.37572087658593],
  ["SO", 97.46756055363322],
  ["PT", 93.25043252595155],
  ["SY", 82.98226643598616],
  ["SN", 89.51088523644752],
  ["KH", 79.47988754325259],
  ["EC", 88.74783737024221],
  ["ET", 88.50129757785467],
  ["DE", 98.14158016147636],
  ["SL", 88.12572087658593],
  ["PW", 96.98132929642446],
  ["SZ", 86.22801326412917],
  ["PA", 59.3890570934256],
  ["SM", 61.005262399077274],
  ["IT", 76.20746828143022],
  ["JO", 84.65506055363322],
  ["GH", 92.23111303344868],
  ["CG", 85.32835928489042],
  ["BT", 90.625],
  ["AO", 84.61865628604383],
  ["ML", 90.34746251441753],
  ["TN", 74.85906862745098],
  ["LI", 82.87521626297578],
  ["MM", 92.44124855824683],
  ["MZ", 89.10971741637832],
  ["NA", 84.22145328719724],
  ["CF", 73.45624279123413],
  ["BB", 96.48608708189158],
  ["CD", 89.44132064590542],
  ["BW", 94.97837370242215],
  ["AL", 78.32612456747405],
  ["NC", 94.21244232987313],
  ["MX", 73.86101499423299],
  ["TZ", 92.65462802768167],
  ["VE", 91.8267012687428],
  ["MN", 86.02364475201846],
  ["MY", 59.759227220299884],
  ["AM", 95.46027970011534],
  ["CR", 50.019103229527104],
  ["BA", 91.66702710495963],
  ["AZ", 79.69651095732411],
  ["CV", 87.8982843137255],
  ["BR", 90.45847750865052],
  ["CA", 69.09133506343714],
  ["BE", 91.1220444059977],
  ["TH", 66.8166089965398],
  ["WS", 76.20206170703575],
  ["UZ", 88.0604815455594],
  ["Wales", 59.003748558246826],
  ["NG", 82.75230680507497],
  ["NP", 44.522058823529406],
  ["MK", 84.6611880046136],
  ["BD", 96.87932525951557],
  ["BS", 96.87211649365629],
  ["CU", 67.66652249134948],
  ["BF", 95.36404267589388],
  ["NR", 96.71388408304499],
  ["NE", 86.96547001153402],
  ["ZW", 81.16745963091118],
  ["VC", 94.09025374855825],
  ["UY", 55.67401960784314],
  ["TJ", 80.27970011534026],
  ["VU", 89.76751730103805],
  ["ZA", 87.7559111880046],
  ["BG", 65.86937716262976],
]);

export const rsMap = new Map<string, number>([
  ["CY", 64.25563617517491],
  ["AF", 89.7133324695517],
  ["CN", 89.06452448820939],
  ["BJ", 91.09905415910858],
  ["LA", 71.43366157035501],
  ["ME", 86.20108836486136],
  ["OM", 89.9659885980824],
  ["NI", 90.1409043793729],
  ["MR", 89.14939103394663],
  ["TG", 89.3563747084737],
  ["TP", 68.35903083700441],
  ["North Korea", 88.01276237367193],
  ["VN", 88.64958538481473],
  ["ZM", 84.92258357087327],
  ["MD", 88.09503757450116],
  ["CO", 82.20620627105468],
  ["AG", 87.21948691370821],
  ["AE", 90.55778699144857],
  ["CZ", 85.85222855662089],
  ["AR", 90.25589530966572],
  ["BI", 79.03245659497279],
  ["CM", 90.09782327027727],
  ["LB", 83.74222596527598],
  ["LU", 89.52934698108318],
  ["TD", 84.42245400362788],
  ["TR", 89.66474475252656],
  ["Scotland", 76.01127235034983],
  ["UA", 88.63241772479917],
  ["LT", 92.8171158331174],
  ["LC", 92.54146151852811],
  ["MG", 81.37341280124384],
  ["CL", 92.04651464109872],
  ["BH", 81.87419020471624],
  ["AD", 91.36855402954133],
  ["CH", 87.14012697590049],
  ["NO", 80.3942083441306],
  ["MT", 78.7503239181135],
  ["MC", 60.16455040165846],
  ["TV", 83.21812645763151],
  ["TW", 93.14427312775331],
  ["US", 61.389608706918885],
  ["MU", 88.2524617776626],
  ["BZ", 81.7481860585644],
  ["CI", 82.1057916558694],
  ["BM", 84.2523969940399],
  ["CK", 67.68236589790101],
  ["AT", 89.70782586162218],
  ["BO", 61.83758745789064],
  ["LS", 89.70069966312516],
  ["NL", 62.365250064783616],
  ["MW", 90.84186317698885],
  ["YE", 91.61764705882352],
  ["UG", 87.73743197719617],
  ["TT", 89.06711583311738],
  ["NZ", 67.44946877429386],
  ["MA", 60.51081886499093],
  ["MV", 89.25693184762892],
  ["LR", 75.75958797615962],
  ["BY", 59.81601451153149],
  ["BN", 88.92102876392848],
  ["AU", 88.55402954133194],
  ["GE", 67.94830266908527],
  ["GR", 81.2130733350609],
  ["IN", 91.3186706400622],
  ["GBENG", 68.12580979528376],
  ["RS", 0],
  ["PL", 94.47849183726355],
  ["SV", 90.0330396475771],
  ["SA", 87.1427183208085],
  ["HK", 87.66357864731796],
  ["KG", 86.29275719098212],
  ["GD", 83.16889090437938],
  ["GQ", 87.41416169992226],
  ["DJ", 85.53187354236849],
  ["KE", 89.66960352422907],
  ["KR", 63.52682041979788],
  ["SC", 62.11032650945841],
  ["ST", 86.30765742420317],
  ["PY", 89.74701995335579],
  ["SB", 82.43910339466183],
  ["IL", 75.23646022285565],
  ["DK", 88.96670121793211],
  ["DO", 82.69273127753304],
  ["RU", 94.38390774812127],
  ["SG", 57.35034983156259],
  ["PK", 80.01846333246955],
  ["GB", 77.88837781808759],
  ["GU", 63.56050790360197],
  ["GW", 89.23555325213786],
  ["JP", 64.65632288157553],
  ["SR", 83.11220523451671],
  ["SE", 81.93217154703291],
  ["SD", 90.20115314848405],
  ["SS", 90.37898419279607],
  ["PH", 85.17912671676599],
  ["RW", 85.3190593417984],
  ["KU", 89.76580720393883],
  ["HN", 89.70912153407619],
  ["GA", 89.08979010106245],
  ["DZ", 74.34179839336615],
  ["DM", 84.18890904379373],
  ["FR", 76.46637729981862],
  ["GL", 89.30908266390256],
  ["ES", 90.76574242031614],
  ["HT", 89.74410469033428],
  ["PE", 82.87930811090956],
  ["QA", 82.53433532003109],
  ["SI", 92.40444415651723],
  ["PR", 80.09134490800726],
  ["PS", 92.93307851775072],
  ["IQ", 91.48160145115315],
  ["HU", 90.05247473438715],
  ["KN", 87.0001943508681],
  ["KY", 68.56957761078],
  ["FI", 46.823658979010105],
  ["ER", 82.20102358123866],
  ["GM", 91.79126716766002],
  ["EE", 62.06368230111428],
  ["EG", 91.86188131640321],
  ["ID", 60.309989634620365],
  ["IS", 85.90275978232704],
  ["SK", 92.0724280901788],
  ["RO", 83.17310183985488],
  ["PG", 89.43735423684892],
  ["KM", 89.53971236071521],
  ["IR", 85.05700958797617],
  ["KZ", 86.1816532780513],
  ["IE", 82.68528116092251],
  ["GN", 90.04372894532263],
  ["FJ", 85.02850479398808],
  ["GY", 86.54638507385333],
  ["JM", 85.65949727908784],
  ["KI", 71.91241254210935],
  ["HR", 86.92180616740089],
  ["SO", 88.53912930811092],
  ["PT", 88.12419020471624],
  ["SY", 91.12626328064265],
  ["SN", 79.3566986265872],
  ["KH", 87.68463332469551],
  ["EC", 90.4269240735942],
  ["ET", 85.94065820160664],
  ["DE", 92.72641876133714],
  ["SL", 89.33823529411765],
  ["PW", 88.64926146670122],
  ["SZ", 84.27442342575797],
  ["PA", 74.04735682819383],
  ["SM", 92.5022674267945],
  ["IT", 71.81426535371858],
  ["JO", 92.79508940139932],
  ["GH", 89.30908266390256],
  ["CG", 82.98976418761337],
  ["BT", 85.67504534853589],
  ["AO", 88.13909043793728],
  ["ML", 89.50408136823012],
  ["TN", 88.50479398807981],
  ["LI", 79.07391811350091],
  ["MM", 91.3170510494947],
  ["MZ", 85.77157294635917],
  ["NA", 78.23885721689557],
  ["CF", 89.9653407618554],
  ["BB", 71.07476030059601],
  ["CD", 86.13436123348018],
  ["BW", 91.33486654573724],
  ["AL", 62.04457113241772],
  ["NC", 89.91610520860327],
  ["MX", 81.1392200051827],
  ["TZ", 88.72926924073595],
  ["VE", 70.93126457631512],
  ["MN", 63.70076444674786],
  ["MY", 64.55590826639026],
  ["AM", 91.50751490023322],
  ["CR", 79.83091474475252],
  ["BA", 75.6786084477844],
  ["AZ", 87.97583570873283],
  ["CV", 72.90263021508163],
  ["BR", 82.16247732573206],
  ["CA", 78.61201088364861],
  ["BE", 93.51256802280383],
  ["TH", 81.03556620886239],
  ["WS", 86.12010883648612],
  ["UZ", 87.02772739051568],
  ["Wales", 83.71048199015289],
  ["NG", 82.74941694739569],
  ["NP", 74.39265353718581],
  ["MK", 78.6427831044312],
  ["BD", 88.39725317439752],
  ["BS", 89.88144597045867],
  ["CU", 73.65444415651723],
  ["BF", 88.26995335579166],
  ["NR", 73.17439751230889],
  ["NE", 89.15424980564913],
  ["ZW", 83.3940139932625],
  ["VC", 88.79761596268463],
  ["UY", 72.30370562321845],
  ["TJ", 87.74552993003368],
  ["VU", 86.02941176470588],
  ["ZA", 69.84970199533558],
  ["BG", 93.80862917854367],
]);

export const plMap = new Map<string, number>([
  ["CY", 55.28442906574394],
  ["AF", 93.7636678200692],
  ["CN", 98.37197231833909],
  ["BJ", 68.97474048442906],
  ["LA", 69.6560553633218],
  ["ME", 94.18927335640139],
  ["OM", 72.32837370242214],
  ["NI", 83.34429065743944],
  ["MR", 97.22941176470589],
  ["TG", 93.24186851211073],
  ["TP", 59.79688581314879],
  ["North Korea", 67.8006920415225],
  ["VN", 52.588235294117645],
  ["ZM", 99.40484429065744],
  ["MD", 79.02456747404844],
  ["CO", 73.1764705882353],
  ["AG", 59.62525951557094],
  ["AE", 74.99861591695502],
  ["CZ", 59.30830449826989],
  ["AR", 84.0121107266436],
  ["BI", 64.89688581314878],
  ["CM", 82.84256055363322],
  ["LB", 50.61660899653979],
  ["LU", 83.52941176470588],
  ["TD", 82.58823529411765],
  ["TR", 94.89515570934256],
  ["Scotland", 75.51245674740484],
  ["UA", 99.76470588235294],
  ["LT", 66.3529411764706],
  ["LC", 98.2083044982699],
  ["MG", 83.12249134948097],
  ["CL", 16.205190311418686],
  ["BH", 50.19204152249135],
  ["AD", 79.98892733564014],
  ["CH", 88.539446366782],
  ["NO", 60.12491349480968],
  ["MT", 51.20415224913495],
  ["MC", 99.76470588235294],
  ["TV", 85.38823529411765],
  ["TW", 95.89307958477508],
  ["US", 83.1038062283737],
  ["MU", 99.52941176470588],
  ["BZ", 78.46539792387543],
  ["CI", 83.27024221453287],
  ["BM", 41.63910034602076],
  ["CK", 86.14809688581315],
  ["AT", 50.35294117647059],
  ["BO", 98.3529411764706],
  ["LS", 80.96539792387544],
  ["NL", 83.52941176470588],
  ["MW", 83.05882352941177],
  ["YE", 83.29411764705881],
  ["UG", 95.11868512110728],
  ["TT", 60.33494809688581],
  ["NZ", 89.64117647058823],
  ["MA", 50.12698961937716],
  ["MV", 65.07231833910035],
  ["LR", 58.443944636678204],
  ["BY", 79.91522491349481],
  ["BN", 76.76228373702422],
  ["AU", 90.41418685121107],
  ["GE", 64.7840830449827],
  ["GR", 78.66989619377162],
  ["IN", 84.06851211072664],
  ["GBENG", 50.117647058823536],
  ["RS", 94.73494809688582],
  ["PL", 0],
  ["SV", 83.07474048442907],
  ["SA", 92.60622837370242],
  ["HK", 93.02525951557094],
  ["KG", 50.75051903114187],
  ["GD", 73.38892733564015],
  ["GQ", 85.68235294117646],
  ["DJ", 84.00934256055363],
  ["KE", 90.54117647058824],
  ["KR", 58.44186851211073],
  ["SC", 82.53737024221454],
  ["ST", 90.2878892733564],
  ["PY", 83.40968858131488],
  ["SB", 90.03391003460207],
  ["IL", 65.88546712802768],
  ["DK", 50.2401384083045],
  ["DO", 66.819723183391],
  ["RU", 33.411764705882355],
  ["SG", 96.9878892733564],
  ["PK", 83.72768166089966],
  ["GB", 61.331141868512105],
  ["GU", 89.38892733564013],
  ["GW", 82.48615916955018],
  ["JP", 58.028373702422144],
  ["SR", 87.9484429065744],
  ["SE", 97.82906574394462],
  ["SD", 86.73217993079585],
  ["SS", 91.92975778546713],
  ["PH", 50.94740484429066],
  ["RW", 99.29411764705883],
  ["KU", 58.906574394463675],
  ["HN", 83.60726643598616],
  ["GA", 98.42941176470589],
  ["DZ", 72.25709342560553],
  ["DM", 92.60657439446366],
  ["FR", 66.77162629757785],
  ["GL", 23.218685121107267],
  ["ES", 71.23667820069204],
  ["HT", 49.26332179930796],
  ["PE", 50.11833910034602],
  ["QA", 82.37716262975778],
  ["SI", 33.8083044982699],
  ["PR", 80.99238754325259],
  ["PS", 78.9598615916955],
  ["IQ", 83.07820069204152],
  ["HU", 83.52941176470588],
  ["KN", 68.43287197231834],
  ["KY", 87.1401384083045],
  ["FI", 69.58754325259515],
  ["ER", 72.78373702422145],
  ["GM", 94.35294117647058],
  ["EE", 99.52941176470588],
  ["EG", 83.53840830449826],
  ["ID", 100],
  ["IS", 82.69584775086504],
  ["SK", 31.164359861591695],
  ["RO", 82.659169550173],
  ["PG", 84.81487889273357],
  ["KM", 62.483737024221455],
  ["IR", 76.0152249134948],
  ["KZ", 91.42560553633217],
  ["IE", 83.27093425605537],
  ["GN", 82.66297577854671],
  ["FJ", 87.69896193771626],
  ["GY", 80.87681660899653],
  ["JM", 92.54809688581315],
  ["KI", 88.38304498269896],
  ["HR", 82.57854671280278],
  ["SO", 96.53667820069204],
  ["PT", 93.11522491349481],
  ["SY", 83.659169550173],
  ["SN", 81.59653979238755],
  ["KH", 79.85640138408304],
  ["EC", 66.98581314878894],
  ["ET", 90.87474048442907],
  ["DE", 99.76470588235294],
  ["SL", 83.52941176470588],
  ["PW", 97.46228373702422],
  ["SZ", 90.00034602076124],
  ["PA", 74.80657439446367],
  ["SM", 51.34429065743945],
  ["IT", 66.76851211072665],
  ["JO", 76.9363321799308],
  ["GH", 98.00865051903114],
  ["CG", 71.38892733564013],
  ["BT", 90.5432525951557],
  ["AO", 98.1166089965398],
  ["ML", 82.58615916955017],
  ["TN", 92.9],
  ["LI", 48.78927335640139],
  ["MM", 62.88892733564014],
  ["MZ", 87.80657439446367],
  ["NA", 78.64290657439447],
  ["CF", 69.98166089965397],
  ["BB", 97.5515570934256],
  ["CD", 85.78996539792388],
  ["BW", 95.52941176470588],
  ["AL", 53.90899653979238],
  ["NC", 95.1439446366782],
  ["MX", 67.68235294117648],
  ["TZ", 93.47404844290658],
  ["VE", 64.09896193771625],
  ["MN", 69.85536332179932],
  ["MY", 71.47231833910035],
  ["AM", 99.29411764705883],
  ["CR", 66.6204152249135],
  ["BA", 91.9757785467128],
  ["AZ", 83.34429065743944],
  ["CV", 89.50553633217993],
  ["BR", 90.62352941176471],
  ["CA", 79.81799307958477],
  ["BE", 82.81038062283737],
  ["TH", 66.82352941176471],
  ["WS", 49.48719723183391],
  ["UZ", 81.05363321799308],
  ["Wales", 49.471972318339105],
  ["NG", 83.27128027681661],
  ["NP", 47.747750865051906],
  ["MK", 83.4961937716263],
  ["BD", 88.09342560553634],
  ["BS", 97.29100346020762],
  ["CU", 73.10346020761246],
  ["BF", 98.46505190311419],
  ["NR", 96.00553633217993],
  ["NE", 85.65709342560554],
  ["ZW", 90.75432525951557],
  ["VC", 96.49584775086505],
  ["UY", 66.53806228373702],
  ["TJ", 78.77854671280276],
  ["VU", 94.24359861591695],
  ["ZA", 90.85432525951556],
  ["BG", 66.11764705882352],
]);

export const svMap = new Map<string, number>([
  ["CY", 72.60443864229765],
  ["AF", 93.13277530333282],
  ["CN", 95.47650130548303],
  ["BJ", 94.56074335739518],
  ["LA", 91.70557518046382],
  ["ME", 92.29457840577484],
  ["OM", 95.2726155736446],
  ["NI", 66.84341882967286],
  ["MR", 95.24497005068346],
  ["TG", 89.42750729534635],
  ["TP", 91.93326677929657],
  ["North Korea", 59.923590846260176],
  ["VN", 94.32114882506526],
  ["ZM", 94.78958685301797],
  ["MD", 73.79127630164336],
  ["CO", 85.84472431270159],
  ["AG", 91.88603901090463],
  ["AE", 75.62893564736599],
  ["CZ", 87.29035478421133],
  ["AR", 68.13930271847643],
  ["BI", 83.15773306711719],
  ["CM", 95.40393180771002],
  ["LB", 70.68729841806174],
  ["LU", 67.8605436952849],
  ["TD", 93.4211334664414],
  ["TR", 94.01090462294577],
  ["Scotland", 51.24635232683151],
  ["UA", 60.67616341575794],
  ["LT", 98.12855168176931],
  ["LC", 96.1960528336661],
  ["MG", 87.40592842881277],
  ["CL", 78.42458915681155],
  ["BH", 86.34695131316235],
  ["AD", 95.37705421594225],
  ["CH", 85.99946244816465],
  ["NO", 86.38150821686376],
  ["MT", 82.97803716786976],
  ["MC", 84.29388726770082],
  ["TV", 91.39725080632776],
  ["TW", 95.28336661035172],
  ["US", 80.169328828137],
  ["MU", 92.78605436952849],
  ["BZ", 48.30517585624328],
  ["CI", 89.9715865458455],
  ["BM", 87.6228689909384],
  ["CK", 88.58009522346798],
  ["AT", 67.86131162647827],
  ["BO", 88.94179081554293],
  ["LS", 69.55421594225157],
  ["NL", 67.60059898633082],
  ["MW", 91.5873137766856],
  ["YE", 67.86131162647827],
  ["UG", 93.87613269851022],
  ["TT", 92.60482260789433],
  ["NZ", 90.13937951159576],
  ["MA", 98.33550913838121],
  ["MV", 96.9217478113961],
  ["LR", 83.46759330364],
  ["BY", 92.38903394255875],
  ["BN", 81.14421747811396],
  ["AU", 88.19958531715558],
  ["GE", 83.69221317769929],
  ["GR", 37.607126401474424],
  ["IN", 68.7993395791737],
  ["GBENG", 87.18821993549378],
  ["RS", 90.64698203041007],
  ["PL", 82.89318077100292],
  ["SV", 0],
  ["SA", 91.64222085701121],
  ["HK", 91.76739364152972],
  ["KG", 90.23805866994317],
  ["GD", 86.78851174934726],
  ["GQ", 72.74036246352327],
  ["DJ", 84.47204730456151],
  ["KE", 92.49385655045307],
  ["KR", 74.07848256796191],
  ["SC", 81.9348026416833],
  ["ST", 86.75126708646906],
  ["PY", 34.931270158193826],
  ["SB", 65.19044693595454],
  ["IL", 39.66940562125634],
  ["DK", 84.35301796958993],
  ["DO", 78.46221778528644],
  ["RU", 98.50944555367839],
  ["SG", 80.0637382890493],
  ["PK", 87.20818614652127],
  ["GB", 72.451620334818],
  ["GU", 93.85693441867609],
  ["GW", 94.49508524036247],
  ["JP", 76.96628782061127],
  ["SR", 96.5281830747965],
  ["SE", 39.02626324681309],
  ["SD", 74.3929503916449],
  ["SS", 84.53232990324067],
  ["PH", 58.51443710643527],
  ["RW", 90.03878052526494],
  ["KU", 73.74251267086468],
  ["HN", 68.62617109506988],
  ["GA", 88.64575334050069],
  ["DZ", 81.97435109814161],
  ["DM", 89.44248195361696],
  ["FR", 90.04645983719858],
  ["GL", 82.51497465827062],
  ["ES", 88.41575794808784],
  ["HT", 97.1548149285824],
  ["PE", 90.51182614037782],
  ["QA", 86.2839809553064],
  ["SI", 95.79749654430964],
  ["PR", 83.87805252649363],
  ["PS", 74.31270158193826],
  ["IQ", 70.19236676393795],
  ["HU", 67.59944708954077],
  ["KN", 90.90270311780064],
  ["KY", 89.79304254338811],
  ["FI", 94.03087083397328],
  ["ER", 85.26647212409767],
  ["GM", 88.05559821839964],
  ["EE", 99.27315312547995],
  ["EG", 68.1684841038243],
  ["ID", 84.29465519889418],
  ["IS", 32.61979726616495],
  ["SK", 94.29734295807096],
  ["RO", 93.31247120258026],
  ["PG", 92.33412686223315],
  ["KM", 82.67086469052373],
  ["IR", 60.19543848871142],
  ["KZ", 91.22216249424051],
  ["IE", 90.07372139456305],
  ["GN", 93.38580863154662],
  ["FJ", 88.13277530333282],
  ["GY", 84.94470895407773],
  ["JM", 87.49040086008294],
  ["KI", 83.64728920288742],
  ["HR", 64.42558746736292],
  ["SO", 96.12271540469975],
  ["PT", 93.2410536015973],
  ["SY", 69.68898786668714],
  ["SN", 93.90224235908462],
  ["KH", 43.59545384733528],
  ["EC", 84.04776532022731],
  ["ET", 83.12624788818923],
  ["DE", 96.84725848563968],
  ["SL", 67.86131162647827],
  ["PW", 93.30018430348642],
  ["SZ", 89.19213638457994],
  ["PA", 65.27607126401475],
  ["SM", 84.03663031792351],
  ["IT", 89.96966671786208],
  ["JO", 77.43549377975734],
  ["GH", 90.14974658270619],
  ["CG", 89.98080172016587],
  ["BT", 90.14053140838581],
  ["AO", 96.31047458147751],
  ["ML", 93.28290585163569],
  ["TN", 92.4846413761327],
  ["LI", 96.26094301950545],
  ["MM", 92.12102595607433],
  ["MZ", 93.38312087236984],
  ["NA", 87.7814467823683],
  ["CF", 82.5284134541545],
  ["BB", 95.16164951620335],
  ["CD", 92.24619874059285],
  ["BW", 90.9741207187836],
  ["AL", 93.56742435877746],
  ["NC", 62.29956995853172],
  ["MX", 92.33950238058671],
  ["TZ", 92.08186146521273],
  ["VE", 97.29073874980801],
  ["MN", 72.17209338043311],
  ["MY", 71.93211488250653],
  ["AM", 98.53056366149593],
  ["CR", 96.23713715251114],
  ["BA", 89.43557057287667],
  ["AZ", 97.00775610505299],
  ["CV", 34.778067885117494],
  ["BR", 90.7433573951774],
  ["CA", 90.10635847028107],
  ["BE", 93.52173245277223],
  ["TH", 98.6104285056059],
  ["WS", 96.07356780832438],
  ["UZ", 65.25456919060052],
  ["Wales", 76.03670711104286],
  ["NG", 90.1647212409768],
  ["NP", 72.84864076178775],
  ["MK", 84.18791276301644],
  ["BD", 94.61027491936721],
  ["BS", 87.92313008754415],
  ["CU", 50.92420519121487],
  ["BF", 97.6363077868223],
  ["NR", 95.8182306865305],
  ["NE", 70.56366149592996],
  ["ZW", 89.69052372907387],
  ["VC", 92.84787283059438],
  ["UY", 66.57694670557518],
  ["TJ", 67.42397481185685],
  ["VU", 91.44524650591308],
  ["ZA", 87.33489479342651],
  ["BG", 98.26332360620489],
]);

export const saMap = new Map<string, number>([
  ["CY", 92.58635242176341],
  ["AF", 88.5524607193871],
  ["CN", 89.32930788209323],
  ["BJ", 56.44137125048695],
  ["LA", 90.75542137384755],
  ["ME", 86.54135826516037],
  ["OM", 87.78762498376834],
  ["NI", 92.00460979093624],
  ["MR", 11.208609271523178],
  ["TG", 49.14978574211141],
  ["TP", 89.38254772107518],
  ["North Korea", 90.22399688352162],
  ["VN", 90.62686664069601],
  ["ZM", 88.21354369562394],
  ["MD", 90.22951564731852],
  ["CO", 91.42773665757694],
  ["AG", 89.13680041553044],
  ["AE", 92.38962472406182],
  ["CZ", 89.3906635501883],
  ["AR", 95.07077002986625],
  ["BI", 84.8464485131801],
  ["CM", 90.62394494221529],
  ["LB", 89.38384625373328],
  ["LU", 92.01142708739125],
  ["TD", 91.45987534086483],
  ["TR", 90.00389559797429],
  ["Scotland", 86.08459940267498],
  ["UA", 92.08349564991559],
  ["LT", 69.05012336060253],
  ["LC", 97.07895078561226],
  ["MG", 60.40124659135178],
  ["CL", 91.17582132190624],
  ["BH", 89.77113361901053],
  ["AD", 91.2790546682249],
  ["CH", 90.15485001947799],
  ["NO", 88.64205947279574],
  ["MT", 91.71925723931957],
  ["MC", 92.07765225295415],
  ["TV", 88.93260615504481],
  ["TW", 88.30898584599403],
  ["US", 82.89702636021296],
  ["MU", 91.57771717958707],
  ["BZ", 87.10524607193871],
  ["CI", 93.22620438904038],
  ["BM", 83.55992728217115],
  ["CK", 83.64627970393455],
  ["AT", 92.13251525775873],
  ["BO", 60.40481755616154],
  ["LS", 92.30099987014674],
  ["NL", 92.18218413193092],
  ["MW", 88.55051292039995],
  ["YE", 92.93143747565252],
  ["UG", 89.29457213348915],
  ["TT", 91.1306973120374],
  ["NZ", 84.04200753148942],
  ["MA", 90.05064277366576],
  ["MV", 73.01064796779639],
  ["LR", 89.85131801064796],
  ["BY", 84.7750292169848],
  ["BN", 90.01395922607453],
  ["AU", 81.84229320867419],
  ["GE", 86.77963900792105],
  ["GR", 91.05343461888067],
  ["IN", 93.49532528243085],
  ["GBENG", 91.86371899753279],
  ["RS", 87.58018439163745],
  ["PL", 92.64446175821321],
  ["SV", 91.71049214387742],
  ["SA", 0],
  ["HK", 89.60589533826776],
  ["KG", 87.2669133878717],
  ["GD", 82.75288923516426],
  ["GQ", 88.78262563303467],
  ["DJ", 93.65147383456693],
  ["KE", 89.93247630177899],
  ["KR", 90.8330087001688],
  ["SC", 69.03324243604727],
  ["ST", 88.95175951175172],
  ["PY", 91.44656538111934],
  ["SB", 83.37586027788599],
  ["IL", 90.11232307492534],
  ["DK", 92.22795740812882],
  ["DO", 91.51960784313725],
  ["RU", 91.75074665627841],
  ["SG", 88.73977405531748],
  ["PK", 90.37819763667056],
  ["GB", 79.0741462147773],
  ["GU", 87.37761329697442],
  ["GW", 89.67893780028568],
  ["JP", 91.69912998311908],
  ["SR", 91.35631736138163],
  ["SE", 91.62900921958187],
  ["SD", 76.03687832748993],
  ["SS", 65.12336060251916],
  ["PH", 89.81366056356318],
  ["RW", 86.79457213348915],
  ["KU", 67.45455135696663],
  ["HN", 92.02993117776911],
  ["GA", 91.36021295935592],
  ["DZ", 50.68075574600701],
  ["DM", 30.646344630567455],
  ["FR", 92.09128684586418],
  ["GL", 90.77100376574471],
  ["ES", 88.95597974289053],
  ["HT", 90.38793663160628],
  ["PE", 92.43280093494351],
  ["QA", 90.24574730554474],
  ["SI", 90.50285677184782],
  ["PR", 84.24100766134268],
  ["PS", 64.9191663420335],
  ["IQ", 91.02843786521231],
  ["HU", 61.195299311777696],
  ["KN", 90.82748993637189],
  ["KY", 84.10888196338138],
  ["FI", 92.28346967926242],
  ["ER", 77.80547980781716],
  ["GM", 91.8286586157642],
  ["EE", 95.69601350473964],
  ["EG", 93.12134787689911],
  ["ID", 92.26756265420076],
  ["IS", 90.60803791715361],
  ["SK", 88.89592260745358],
  ["RO", 91.47188676795221],
  ["PG", 89.64744838332685],
  ["KM", 85.05648617062718],
  ["IR", 86.41150499935073],
  ["KZ", 84.24587715881054],
  ["IE", 93.28528762498377],
  ["GN", 91.45273341124529],
  ["FJ", 87.29126087521101],
  ["GY", 86.46442020516815],
  ["JM", 89.26990001298533],
  ["KI", 82.69705233086611],
  ["HR", 89.0351902350344],
  ["SO", 90.37982080249319],
  ["PT", 88.15283729385794],
  ["SY", 92.0997273081418],
  ["SN", 60.359368913128165],
  ["KH", 89.42832099727308],
  ["EC", 88.40312946370601],
  ["ET", 58.79528632645111],
  ["DE", 93.46026490066225],
  ["SL", 92.30846643293079],
  ["PW", 94.43059342942475],
  ["SZ", 85.6820542786651],
  ["PA", 90.64861706271913],
  ["SM", 91.41669912998312],
  ["IT", 91.9744189066355],
  ["JO", 65.60219452019219],
  ["GH", 60.86871834826646],
  ["CG", 88.69400077911959],
  ["BT", 92.21919231268666],
  ["AO", 91.74944812362031],
  ["ML", 91.2897675626542],
  ["TN", 89.49292299701338],
  ["LI", 89.93702116608233],
  ["MM", 90.07401636151148],
  ["MZ", 88.8800155823919],
  ["NA", 83.97902869757175],
  ["CF", 89.79872743799507],
  ["BB", 92.69510453187898],
  ["CD", 87.61329697441892],
  ["BW", 97.81976366705622],
  ["AL", 89.61563433320347],
  ["NC", 88.5186988702766],
  ["MX", 61.01642643812492],
  ["TZ", 91.50240228541749],
  ["VE", 90.57297753538501],
  ["MN", 87.08122321776392],
  ["MY", 83.32781456953643],
  ["AM", 91.91630957018569],
  ["CR", 90.32106219971432],
  ["BA", 86.30307752239969],
  ["AZ", 89.57180885599273],
  ["CV", 89.48091156992598],
  ["BR", 86.32190624594209],
  ["CA", 90.41066095312297],
  ["BE", 93.03012595766783],
  ["TH", 92.40358395013635],
  ["WS", 89.52668484612389],
  ["UZ", 88.6456304376055],
  ["Wales", 76.39981820542788],
  ["NG", 31.14465653811193],
  ["NP", 82.22665887547072],
  ["MK", 79.71042721724451],
  ["BD", 15.816127775613555],
  ["BS", 90.60316841968576],
  ["CU", 86.48681989352032],
  ["BF", 90.3882612647708],
  ["NR", 90.08310609011816],
  ["NE", 91.47286066744579],
  ["ZW", 90.1873133359304],
  ["VC", 90.21003765744709],
  ["UY", 90.0970653161927],
  ["TJ", 91.76827684716271],
  ["VU", 90.83885209713024],
  ["ZA", 67.32534735748604],
  ["BG", 91.84424100766134],
]);

export const hkMap = new Map<string, number>([
  ["CY", 90.21588105440853],
  ["AF", 91.31086871834827],
  ["CN", 10.828139202700948],
  ["BJ", 69.16991299831191],
  ["LA", 41.878327489936375],
  ["ME", 89.89806518633944],
  ["OM", 56.089793533307365],
  ["NI", 91.8140501233606],
  ["MR", 92.2763277496429],
  ["TG", 89.97273081417998],
  ["TP", 30.248344370860924],
  ["North Korea", 51.35826516036879],
  ["VN", 6.128100246721205],
  ["ZM", 83.60180496039476],
  ["MD", 92.33443708609272],
  ["CO", 68.4044279963641],
  ["AG", 42.490261005064276],
  ["AE", 67.8314504609791],
  ["CZ", 60.054863004804574],
  ["AR", 93.64530580444098],
  ["BI", 59.05856382288015],
  ["CM", 75.02824308531359],
  ["LB", 43.565121412803535],
  ["LU", 91.66666666666666],
  ["TD", 91.1293987793793],
  ["TR", 12.665562913907285],
  ["Scotland", 85.4999350733671],
  ["UA", 93.67095182443839],
  ["LT", 63.7897675626542],
  ["LC", 96.48032723022985],
  ["MG", 94.66887417218544],
  ["CL", 48.136281002467214],
  ["BH", 40.56258927412024],
  ["AD", 94.0176600441501],
  ["CH", 89.22055577197766],
  ["NO", 36.17322425659005],
  ["MT", 49.40364887676925],
  ["MC", 50.703804700688224],
  ["TV", 75.79275418776781],
  ["TW", 94.81236203090508],
  ["US", 82.58635242176341],
  ["MU", 92.73568367744448],
  ["BZ", 73.74626671860798],
  ["CI", 90.93883911180366],
  ["BM", 27.33898195039605],
  ["CK", 79.59063757953513],
  ["AT", 91.86891312816518],
  ["BO", 60.65478509284509],
  ["LS", 93.30898584599403],
  ["NL", 91.72055577197766],
  ["MW", 67.31625762887936],
  ["YE", 60.911569925983635],
  ["UG", 91.11543955330477],
  ["TT", 30.577197766523827],
  ["NZ", 81.00181794572133],
  ["MA", 8.784898065186338],
  ["MV", 92.86878327489937],
  ["LR", 88.02720425918712],
  ["BY", 88.02947669133879],
  ["BN", 93.61154395533048],
  ["AU", 93.5712894429295],
  ["GE", 88.95760290871316],
  ["GR", 91.72023113881315],
  ["IN", 93.22036099207895],
  ["GBENG", 75.05940786910791],
  ["RS", 88.03726788728736],
  ["PL", 93.10349305285028],
  ["SV", 92.25295416179718],
  ["SA", 89.60589533826776],
  ["HK", 0],
  ["KG", 8.035320088300221],
  ["GD", 51.39494870796001],
  ["GQ", 61.39949357226334],
  ["DJ", 93.03954031943903],
  ["KE", 92.70808985845994],
  ["KR", 94.18452149071548],
  ["SC", 67.73470977795093],
  ["ST", 88.99136475782366],
  ["PY", 60.35255161667315],
  ["SB", 85.54246201791975],
  ["IL", 92.45747305544735],
  ["DK", 91.70562264640955],
  ["DO", 59.23386573172316],
  ["RU", 63.26353720296065],
  ["SG", 89.69484482534736],
  ["PK", 92.40196078431373],
  ["GB", 61.30015582391897],
  ["GU", 91.7952213998182],
  ["GW", 61.952343851447864],
  ["JP", 94.23126866640696],
  ["SR", 94.25301908843008],
  ["SE", 93.35670692117907],
  ["SD", 89.68543046357615],
  ["SS", 79.30301259576679],
  ["PH", 60.7479548110635],
  ["RW", 88.80275288923517],
  ["KU", 64.37053629398778],
  ["HN", 91.85657706791326],
  ["GA", 91.80853135956369],
  ["DZ", 92.41137514608494],
  ["DM", 93.43689131281651],
  ["FR", 90.70834956499156],
  ["GL", 91.00116867939228],
  ["ES", 40.59894818854694],
  ["HT", 93.58102843786521],
  ["PE", 25.39800025970653],
  ["QA", 92.75905726529022],
  ["SI", 61.06025191533567],
  ["PR", 84.49681859498767],
  ["PS", 74.94286456304377],
  ["IQ", 60.98201532268537],
  ["HU", 60.546682249058556],
  ["KN", 68.0148681989352],
  ["KY", 79.26081028437865],
  ["FI", 94.16374496818595],
  ["ER", 78.36709518244383],
  ["GM", 62.37079600051941],
  ["EE", 97.9109855862875],
  ["EG", 61.39170237631476],
  ["ID", 51.02908713154136],
  ["IS", 80.46032982729515],
  ["SK", 58.82417867809375],
  ["RO", 59.09687053629399],
  ["PG", 58.232372419166346],
  ["KM", 76.89715621347877],
  ["IR", 84.57960005194131],
  ["KZ", 87.91455655109726],
  ["IE", 90.94922737306842],
  ["GN", 59.106609531229715],
  ["FJ", 82.8814439683158],
  ["GY", 70.60024672120504],
  ["JM", 89.45494091676406],
  ["KI", 43.413842358135305],
  ["HR", 89.34164394234514],
  ["SO", 93.57226334242306],
  ["PT", 90.25483703415141],
  ["SY", 59.6912738605376],
  ["SN", 59.031294637060114],
  ["KH", 90.62004934424101],
  ["EC", 68.31710167510712],
  ["ET", 60.99629918192443],
  ["DE", 94.73152837293858],
  ["SL", 92.03058044409816],
  ["PW", 90.28957278275549],
  ["SZ", 84.19653291780288],
  ["PA", 91.63420335021426],
  ["SM", 93.96117387352292],
  ["IT", 90.81840020776522],
  ["JO", 70.07628879366315],
  ["GH", 61.96597844435787],
  ["CG", 58.91182963251526],
  ["BT", 55.9784443578756],
  ["AO", 53.22847682119205],
  ["ML", 59.08680690819374],
  ["TN", 90.7843137254902],
  ["LI", 49.4055966757564],
  ["MM", 88.99233865731723],
  ["MZ", 91.72704843526814],
  ["NA", 85.45026619919491],
  ["CF", 91.80366186209584],
  ["BB", 93.84463056745878],
  ["CD", 77.03187897675626],
  ["BW", 97.62725620049343],
  ["AL", 15.119465004544864],
  ["NC", 90.2197766523828],
  ["MX", 59.39975327879496],
  ["TZ", 93.02330866121284],
  ["VE", 61.85365536943254],
  ["MN", 89.15303207375665],
  ["MY", 58.43137254901961],
  ["AM", 95.23535904427996],
  ["CR", 71.77022464614986],
  ["BA", 87.8100246721205],
  ["AZ", 92.92786651084275],
  ["CV", 86.16283599532528],
  ["BR", 89.97630177898974],
  ["CA", 93.37391247889884],
  ["BE", 92.06466692637319],
  ["TH", 61.76470588235294],
  ["WS", 27.36819893520322],
  ["UZ", 86.54070899883132],
  ["Wales", 82.08868978054798],
  ["NG", 90.98818335281132],
  ["NP", 83.4070250616803],
  ["MK", 81.20309050772627],
  ["BD", 90.24509803921569],
  ["BS", 90.70899883132061],
  ["CU", 72.3733930658356],
  ["BF", 52.06271912738606],
  ["NR", 92.2357486040774],
  ["NE", 93.47682119205298],
  ["ZW", 79.7062069861057],
  ["VC", 92.69413063238541],
  ["UY", 91.15407089988314],
  ["TJ", 90.84826645890143],
  ["VU", 93.62193221659525],
  ["ZA", 88.72678872873652],
  ["BG", 63.09440332424361],
]);

export const kgMap = new Map<string, number>([
  ["CY", 88.83217993079585],
  ["AF", 87.38141580161476],
  ["CN", 10.73024798154556],
  ["BJ", 67.49675605536332],
  ["LA", 40.445862168396765],
  ["ME", 85.96597462514417],
  ["OM", 54.09710207612457],
  ["NI", 90.04361303344868],
  ["MR", 88.53301614763552],
  ["TG", 71.57691753171856],
  ["TP", 30.211937716262977],
  ["North Korea", 50.95119665513265],
  ["VN", 7.794478085351788],
  ["ZM", 79.69723183391004],
  ["MD", 58.709270472895035],
  ["CO", 66.48140138408306],
  ["AG", 40.39323817762399],
  ["AE", 67.8557525951557],
  ["CZ", 59.97152537485583],
  ["AR", 91.54736159169549],
  ["BI", 59.318050749711645],
  ["CM", 74.63992214532873],
  ["LB", 43.32071799307958],
  ["LU", 60.24726066897347],
  ["TD", 89.52494232987313],
  ["TR", 13.030204728950403],
  ["Scotland", 85.30024509803921],
  ["UA", 90.54065743944636],
  ["LT", 90.3316032295271],
  ["LC", 92.94117647058823],
  ["MG", 89.95350346020761],
  ["CL", 49.0625],
  ["BH", 40.82396193771626],
  ["AD", 59.3407583621684],
  ["CH", 88.90534890426758],
  ["NO", 34.82843137254902],
  ["MT", 49.96756055363322],
  ["MC", 50.60733852364475],
  ["TV", 71.86743079584775],
  ["TW", 90.3045703575548],
  ["US", 80.65167243367935],
  ["MU", 65.23320357554788],
  ["BZ", 72.66147635524798],
  ["CI", 91.89374279123415],
  ["BM", 27.274365628604386],
  ["CK", 76.75281141868511],
  ["AT", 30.53561130334487],
  ["BO", 59.28777393310265],
  ["LS", 91.82165513264128],
  ["NL", 90.54822664359861],
  ["MW", 65.34674163783161],
  ["YE", 62.72311130334487],
  ["UG", 91.14222895040369],
  ["TT", 31.47419261822376],
  ["NZ", 77.35402249134948],
  ["MA", 90.27753748558247],
  ["MV", 24.783737024221452],
  ["LR", 86.9737600922722],
  ["BY", 82.97397635524798],
  ["BN", 93.1491493656286],
  ["AU", 92.13343425605537],
  ["GE", 86.5787197231834],
  ["GR", 89.52133794694348],
  ["IN", 91.05211937716263],
  ["GBENG", 74.96792099192618],
  ["RS", 86.54411764705883],
  ["PL", 50.812427912341406],
  ["SV", 90.64121972318338],
  ["SA", 87.1683967704729],
  ["HK", 7.999207035755479],
  ["KG", 0],
  ["GD", 48.93959054209919],
  ["GQ", 60.534890426758935],
  ["DJ", 88.23709630911189],
  ["KE", 90.98183391003461],
  ["KR", 93.58131487889273],
  ["SC", 66.69405997693195],
  ["ST", 75.4728950403691],
  ["PY", 60.644824106113035],
  ["SB", 81.67495674740485],
  ["IL", 90.7803489042676],
  ["DK", 89.1320645905421],
  ["DO", 59.62910899653979],
  ["RU", 60.96813725490197],
  ["SG", 53.46345155709342],
  ["PK", 88.86570069204153],
  ["GB", 60.243295847750865],
  ["GU", 87.00619953863898],
  ["GW", 58.84191176470588],
  ["JP", 90.34061418685121],
  ["SR", 91.85661764705883],
  ["SE", 90.27609573241061],
  ["SD", 64.17856113033449],
  ["SS", 76.65152825836216],
  ["PH", 59.15657439446367],
  ["RW", 84.5876585928489],
  ["KU", 64.42474048442907],
  ["HN", 90.55074971164937],
  ["GA", 88.9738321799308],
  ["DZ", 91.0867214532872],
  ["DM", 91.72109284890426],
  ["FR", 58.83145905420992],
  ["GL", 50.81675317185697],
  ["ES", 86.52032871972318],
  ["HT", 49.85978950403691],
  ["PE", 26.17286620530565],
  ["QA", 88.59537197231833],
  ["SI", 58.35243656286045],
  ["PR", 83.22015570934256],
  ["PS", 75.00252306805075],
  ["IQ", 62.0685553633218],
  ["HU", 60.90902537485583],
  ["KN", 66.8155276816609],
  ["KY", 75.8416234140715],
  ["FI", 91.01463379469435],
  ["ER", 56.819492502883506],
  ["GM", 59.858708189158016],
  ["EE", 96.66378316032296],
  ["EG", 62.397635524798154],
  ["ID", 50.591479238754324],
  ["IS", 78.44615051903115],
  ["SK", 57.29274798154555],
  ["RO", 57.25706459054209],
  ["PG", 57.91774798154557],
  ["KM", 76.62665801614763],
  ["IR", 84.14648212226066],
  ["KZ", 84.88069492502883],
  ["IE", 91.7319059976932],
  ["GN", 57.350418108419845],
  ["FJ", 75.95588235294117],
  ["GY", 69.14576124567475],
  ["JM", 87.31797866205305],
  ["KI", 42.227869088812],
  ["HR", 88.64475201845444],
  ["SO", 89.31913206459055],
  ["PT", 87.26030853517878],
  ["SY", 61.38768742791234],
  ["SN", 57.90405132641292],
  ["KH", 56.20170126874279],
  ["EC", 65.70898212226068],
  ["ET", 59.76138985005767],
  ["DE", 92.18029123414071],
  ["SL", 90.50389273356402],
  ["PW", 88.54923587081892],
  ["SZ", 84.58837946943484],
  ["PA", 68.81812283737024],
  ["SM", 92.08729815455594],
  ["IT", 59.05889561707036],
  ["JO", 69.37968569780854],
  ["GH", 60.72015570934256],
  ["CG", 57.91270184544406],
  ["BT", 87.33095444059977],
  ["AO", 52.86908881199539],
  ["ML", 57.276888696655135],
  ["TN", 7.795559400230681],
  ["LI", 47.81177912341407],
  ["MM", 59.92466839677048],
  ["MZ", 77.68094002306805],
  ["NA", 66.05500288350635],
  ["CF", 82.45278258362168],
  ["BB", 92.237961361015],
  ["CD", 74.08232410611303],
  ["BW", 93.94391580161476],
  ["AL", 14.683535178777394],
  ["NC", 87.28193483275663],
  ["MX", 58.86209630911188],
  ["TZ", 90.85459919261822],
  ["VE", 60.202926758938865],
  ["MN", 84.93980680507497],
  ["MY", 58.074899077277976],
  ["AM", 60.65996251441753],
  ["CR", 72.22931084198385],
  ["BA", 86.31343713956171],
  ["AZ", 68.66061130334487],
  ["CV", 83.43930219146483],
  ["BR", 89.78698096885813],
  ["CA", 91.68937427912341],
  ["BE", 58.38884083044983],
  ["TH", 59.49070069204152],
  ["WS", 26.454368512110726],
  ["UZ", 85.59436274509804],
  ["Wales", 78.87074682814303],
  ["NG", 91.69333910034602],
  ["NP", 60.67257785467128],
  ["MK", 78.92012687427913],
  ["BD", 83.32179930795847],
  ["BS", 88.03164648212226],
  ["CU", 71.32713379469435],
  ["BF", 48.591046712802765],
  ["NR", 88.2641291810842],
  ["NE", 90.03964821222607],
  ["ZW", 80.9183967704729],
  ["VC", 91.06365340253748],
  ["UY", 88.8083910034602],
  ["TJ", 89.62262110726643],
  ["VU", 65.12471164936564],
  ["ZA", 86.14331026528258],
  ["BG", 60.5756199538639],
]);

export const gdMap = new Map<string, number>([
  ["CY", 86.87527099291805],
  ["AF", 88.4383581442405],
  ["CN", 52.86421448186154],
  ["BJ", 60.3121838415956],
  ["LA", 62.314640844052605],
  ["ME", 67.63513513513514],
  ["OM", 72.58454979043213],
  ["NI", 88.01994507876861],
  ["MR", 84.62169388639977],
  ["TG", 58.501228501228496],
  ["TP", 62.821216938864],
  ["North Korea", 82.0440092498916],
  ["VN", 53.67972250325192],
  ["ZM", 86.12841451076744],
  ["MD", 62.76232114467408],
  ["CO", 65.36385315797081],
  ["AG", 60.00867177337766],
  ["AE", 78.65406850700968],
  ["CZ", 75.65652550946669],
  ["AR", 89.71094088741147],
  ["BI", 67.58129787541553],
  ["CM", 68.19048995519583],
  ["LB", 60.318687671628844],
  ["LU", 75.68073421014597],
  ["TD", 61.12624656742304],
  ["TR", 52.70414799826565],
  ["Scotland", 82.30922098569158],
  ["UA", 78.61612949848244],
  ["LT", 48.33140627258275],
  ["LC", 85.08491111432288],
  ["MG", 88.76499494146553],
  ["CL", 71.69605434311318],
  ["BH", 66.79650238473768],
  ["AD", 60.71072409307704],
  ["CH", 87.64091631738691],
  ["NO", 84.73912415088886],
  ["MT", 72.6813845931493],
  ["MC", 73.94637953461482],
  ["TV", 75.1712675242087],
  ["TW", 87.13686948981066],
  ["US", 65.55174158115335],
  ["MU", 71.41277641277641],
  ["BZ", 67.0945945945946],
  ["CI", 89.27590692296575],
  ["BM", 54.68564821505998],
  ["CK", 79.51907790143085],
  ["AT", 61.29245555716144],
  ["BO", 72.42918051741582],
  ["LS", 87.02702702702703],
  ["NL", 88.01235727706316],
  ["MW", 84.66071686659922],
  ["YE", 78.6096256684492],
  ["UG", 74.13029339499928],
  ["TT", 62.21130221130221],
  ["NZ", 79.80633039456568],
  ["MA", 54.49017199017199],
  ["MV", 54.630365659777425],
  ["LR", 65.83249024425496],
  ["BY", 82.5],
  ["BN", 77.65789854025148],
  ["AU", 84.8804017921665],
  ["GE", 84.76224887989594],
  ["GR", 84.58122561063738],
  ["IN", 89.33371874548345],
  ["GBENG", 84.80885966180084],
  ["RS", 83.58361034831623],
  ["PL", 73.72633328515683],
  ["SV", 87.45555716143951],
  ["SA", 82.83856048561931],
  ["HK", 51.520812256106375],
  ["KG", 49.11042058100882],
  ["GD", 0],
  ["GQ", 72.86674374909668],
  ["DJ", 87.04726116490822],
  ["KE", 88.59878595172714],
  ["KR", 88.15291227055933],
  ["SC", 75.68651539239775],
  ["ST", 74.44103194103194],
  ["PY", 74.44067061714121],
  ["SB", 78.93951438069085],
  ["IL", 87.56937418702124],
  ["DK", 60.36240786240786],
  ["DO", 74.52485908368261],
  ["RU", 75.06684491978609],
  ["SG", 73.32092787975141],
  ["PK", 87.36920075155369],
  ["GB", 69.5833935539818],
  ["GU", 73.80293394999278],
  ["GW", 65.57414366237896],
  ["JP", 89.38647203353086],
  ["SR", 77.80170544876427],
  ["SE", 83.8173869056222],
  ["SD", 77.7139037433155],
  ["SS", 82.0591848533025],
  ["PH", 75.64568579274462],
  ["RW", 81.08180372886255],
  ["KU", 77.79989882931059],
  ["HN", 88.02825552825553],
  ["GA", 86.21043503396444],
  ["DZ", 87.34643734643734],
  ["DM", 79.9017199017199],
  ["FR", 76.18116779881485],
  ["GL", 71.26029773088597],
  ["ES", 52.103266367972246],
  ["HT", 71.4471021823963],
  ["PE", 62.643806908512786],
  ["QA", 87.355109119815],
  ["SI", 74.02153490388784],
  ["PR", 81.07674519439226],
  ["PS", 83.3928313340078],
  ["IQ", 76.54682757623934],
  ["HU", 71.65739268680446],
  ["KN", 74.92520595461772],
  ["KY", 78.24143662378957],
  ["FI", 89.76008093655152],
  ["ER", 68.95505130799249],
  ["GM", 72.52709929180517],
  ["EE", 93.92939731175025],
  ["EG", 77.29404538228067],
  ["ID", 74.12776412776412],
  ["IS", 83.36934528110999],
  ["SK", 85.86681601387484],
  ["RO", 61.02543720190779],
  ["PG", 72.3410174880763],
  ["KM", 75.26810232692586],
  ["IR", 82.31066628125451],
  ["KZ", 81.48215059979765],
  ["IE", 89.65891024714554],
  ["GN", 50.08129787541552],
  ["FJ", 80.64929903165198],
  ["GY", 68.02970082381847],
  ["JM", 78.9998554704437],
  ["KI", 63.85279664691429],
  ["HR", 86.58946379534615],
  ["SO", 87.81651972828443],
  ["PT", 84.57688972394854],
  ["SY", 77.21961266078912],
  ["SN", 75.38733921086862],
  ["KH", 77.5617863853158],
  ["EC", 65.88849544731897],
  ["ET", 72.95562942621766],
  ["DE", 81.99920508744039],
  ["SL", 88.42932504697211],
  ["PW", 80.28291660644602],
  ["SZ", 67.68933371874549],
  ["PA", 80.29303367538661],
  ["SM", 86.13166642578408],
  ["IT", 66.21766151177916],
  ["JO", 82.54010695187166],
  ["GH", 70.81406272582743],
  ["CG", 64.91978609625669],
  ["BT", 74.44392253215783],
  ["AO", 74.49486920075155],
  ["ML", 60.5643879173291],
  ["TN", 51.70617141205377],
  ["LI", 70.14850411909237],
  ["MM", 63.61757479404538],
  ["MZ", 71.22597196126608],
  ["NA", 66.20067928891459],
  ["CF", 79.20870067928891],
  ["BB", 76.86732186732186],
  ["CD", 78.27540106951871],
  ["BW", 92.89745627980922],
  ["AL", 54.3525075878017],
  ["NC", 80.42961410608468],
  ["MX", 63.46220552102905],
  ["TZ", 86.44854747795925],
  ["VE", 63.41956930192224],
  ["MN", 59.734788264200034],
  ["MY", 68.879173290938],
  ["AM", 74.91761815291227],
  ["CR", 77.51915016620899],
  ["BA", 76.86985113455702],
  ["AZ", 81.94645179939297],
  ["CV", 83.98901575372165],
  ["BR", 79.46451799392976],
  ["CA", 85.11851423616129],
  ["BE", 80.1083971672207],
  ["TH", 63.96191646191646],
  ["WS", 61.48793178204943],
  ["UZ", 86.16635351929469],
  ["Wales", 82.00787686081804],
  ["NG", 68.74873536638243],
  ["NP", 72.72691140338199],
  ["MK", 72.2658621188033],
  ["BD", 69.46198872669461],
  ["BS", 89.64662523486054],
  ["CU", 74.24266512501806],
  ["BF", 86.96560196560196],
  ["NR", 86.38784506431566],
  ["NE", 87.11663535192947],
  ["ZW", 68.00440815146698],
  ["VC", 70.60738546032664],
  ["UY", 84.91942477236594],
  ["TJ", 84.95736378089319],
  ["VU", 75.79599653129064],
  ["ZA", 74.73912415088886],
  ["BG", 58.05643879173291],
]);

export const gqMap = new Map<string, number>([
  ["CY", 78.28291008033169],
  ["AF", 93.40470329100803],
  ["CN", 63.58026690852553],
  ["BJ", 73.55694480435346],
  ["LA", 66.23671935734646],
  ["ME", 69.1416169992226],
  ["OM", 88.60067374967609],
  ["NI", 67.67264835449598],
  ["MR", 92.51263280642654],
  ["TG", 89.88306556102617],
  ["TP", 68.212619849702],
  ["North Korea", 80.90275978232702],
  ["VN", 63.856569059341794],
  ["ZM", 87.74196683078517],
  ["MD", 82.24766778958279],
  ["CO", 71.16286602746825],
  ["AG", 68.40243586421353],
  ["AE", 67.56089660533817],
  ["CZ", 58.62496760818865],
  ["AR", 75.1049494687743],
  ["BI", 68.03576055973049],
  ["CM", 82.56413578647319],
  ["LB", 53.2871210158072],
  ["LU", 71.76405804612594],
  ["TD", 82.24540036278829],
  ["TR", 63.7130733350609],
  ["Scotland", 71.76664939103394],
  ["UA", 88.83745789064524],
  ["LT", 96.68405027209121],
  ["LC", 97.2845944545219],
  ["MG", 94.52092511013215],
  ["CL", 55.538675822752005],
  ["BH", 72.16150557139154],
  ["AD", 93.11414874319772],
  ["CH", 82.8948561803576],
  ["NO", 62.90263021508162],
  ["MT", 72.85177507126198],
  ["MC", 85.31679191500389],
  ["TV", 84.21806167400881],
  ["TW", 95.54774552993003],
  ["US", 78.5371857994299],
  ["MU", 92.74099507644468],
  ["BZ", 72.93826120756673],
  ["CI", 86.79159108577352],
  ["BM", 59.96598859808241],
  ["CK", 88.90288934957242],
  ["AT", 71.93443897382743],
  ["BO", 89.61518528116092],
  ["LS", 75.23678414096916],
  ["NL", 71.76308629178544],
  ["MW", 93.68456854107282],
  ["YE", 73.2492225965276],
  ["UG", 93.69622959315885],
  ["TT", 72.75848665457373],
  ["NZ", 90.09004923555325],
  ["MA", 95.73594195387406],
  ["MV", 69.28090178802798],
  ["LR", 84.91318994558176],
  ["BY", 91.25842187095103],
  ["BN", 82.60300596009328],
  ["AU", 92.74455817569319],
  ["GE", 85.49591863176988],
  ["GR", 75.4952707955429],
  ["IN", 73.84296449857476],
  ["GBENG", 85.82728686188132],
  ["RS", 87.41416169992226],
  ["PL", 85.29444156517233],
  ["SV", 72.86894273127753],
  ["SA", 88.72181912412542],
  ["HK", 61.535371857994306],
  ["KG", 60.69480435345944],
  ["GD", 72.87671676600156],
  ["GQ", 0],
  ["DJ", 88.63727649650168],
  ["KE", 92.05040165846074],
  ["KR", 82.16798393366157],
  ["SC", 78.44875615444415],
  ["ST", 87.48445193055196],
  ["PY", 72.76755636175174],
  ["SB", 86.83920704845815],
  ["IL", 75.53187354236849],
  ["DK", 58.38785954910599],
  ["DO", 70.19078776885203],
  ["RU", 67.23341539258875],
  ["SG", 82.05234516714175],
  ["PK", 94.16785436641617],
  ["GB", 72.97810313552733],
  ["GU", 92.23341539258875],
  ["GW", 84.7505830526043],
  ["JP", 83.83454262762373],
  ["SR", 95.24585384814719],
  ["SE", 93.33862399585385],
  ["SD", 72.62632806426535],
  ["SS", 90.27306296968126],
  ["PH", 64.56983674527079],
  ["RW", 88.71663643430941],
  ["KU", 45.89725317439751],
  ["HN", 68.45458668048717],
  ["GA", 89.42407359419539],
  ["DZ", 78.9268592899715],
  ["DM", 87.89453226224411],
  ["FR", 87.12004405286343],
  ["GL", 58.77364602228556],
  ["ES", 66.9972790878466],
  ["HT", 65.31128530707437],
  ["PE", 68.16241254210935],
  ["QA", 93.00919927442342],
  ["SI", 65.07126198497019],
  ["PR", 82.9998056491319],
  ["PS", 72.04359937807722],
  ["IQ", 75.89952060119202],
  ["HU", 71.55286343612335],
  ["KN", 72.7118424462296],
  ["KY", 88.66480953614926],
  ["FI", 94.62749416947396],
  ["ER", 83.0357605597305],
  ["GM", 87.498056491319],
  ["EE", 98.59970199533558],
  ["EG", 74.109549105986],
  ["ID", 85.36149261466701],
  ["IS", 84.68353200310962],
  ["SK", 65.40943249546515],
  ["RO", 82.16215340761856],
  ["PG", 88.77882871210157],
  ["KM", 76.9635916040425],
  ["IR", 63.864667012179325],
  ["KZ", 87.85080331692147],
  ["IE", 86.83499611298264],
  ["GN", 84.70199533557916],
  ["FJ", 88.2861492614667],
  ["GY", 84.94072298522933],
  ["JM", 89.8888960870692],
  ["KI", 81.36887794765482],
  ["HR", 68.98322104172065],
  ["SO", 92.56672713138119],
  ["PT", 89.65535112723504],
  ["SY", 75.0732054936512],
  ["SN", 81.20789064524489],
  ["KH", 89.37807722207826],
  ["EC", 68.30849961129827],
  ["ET", 59.96857994299041],
  ["DE", 98.35158072039388],
  ["SL", 69.40139932625033],
  ["PW", 90.36829489505052],
  ["SZ", 85.18042238922],
  ["PA", 83.4655998963462],
  ["SM", 87.76043016325474],
  ["IT", 76.07573205493651],
  ["JO", 73.69817310183986],
  ["GH", 89.9306815237108],
  ["CG", 71.69830266908525],
  ["BT", 66.48969940399067],
  ["AO", 95.27824565949727],
  ["ML", 81.39446747862141],
  ["TN", 88.97998186058564],
  ["LI", 65.61835967867323],
  ["MM", 89.69227779217414],
  ["MZ", 90.63390774812127],
  ["NA", 80.9273775589531],
  ["CF", 80.74274423425759],
  ["BB", 93.48827416429127],
  ["CD", 87.27811609225188],
  ["BW", 92.41318994558175],
  ["AL", 65.05215081627365],
  ["NC", 89.67187095102358],
  ["MX", 78.16532780513086],
  ["TZ", 92.02805130862917],
  ["VE", 66.3559212231148],
  ["MN", 76.3594843223633],
  ["MY", 69.10469033428349],
  ["AM", 97.14012697590049],
  ["CR", 95.51794506348796],
  ["BA", 89.9983804094325],
  ["AZ", 94.20737237626328],
  ["CV", 88.53977714433792],
  ["BR", 88.63306556102617],
  ["CA", 87.06335838300078],
  ["BE", 95.11887794765484],
  ["TH", 81.89848406322882],
  ["WS", 66.0342705364084],
  ["UZ", 69.02565431458927],
  ["Wales", 80.84445452189686],
  ["NG", 86.64323658979009],
  ["NP", 74.30422389220006],
  ["MK", 81.10034983156258],
  ["BD", 91.73879243327286],
  ["BS", 90.73756154444158],
  ["CU", 83.61589790101063],
  ["BF", 94.68320808499612],
  ["NR", 94.03375226742679],
  ["NE", 75.36699922259653],
  ["ZW", 77.77111946100025],
  ["VC", 80.49300336874839],
  ["UY", 86.97590049235554],
  ["TJ", 72.26127235034984],
  ["VU", 94.59186317698885],
  ["ZA", 86.78122570614148],
  ["BG", 67.05947136563877],
]);

export const djMap = new Map<string, number>([
  ["CY", 73.77873003506038],
  ["AF", 93.73003506038177],
  ["CN", 95.0659005323984],
  ["BJ", 96.20698610570055],
  ["LA", 94.57765225295415],
  ["ME", 88.4852616543306],
  ["OM", 94.14394234514998],
  ["NI", 84.52246461498507],
  ["MR", 94.80651863394365],
  ["TG", 92.59252045188936],
  ["TP", 92.6778989741592],
  ["North Korea", 91.99552006232958],
  ["VN", 94.4825347357486],
  ["ZM", 95.3019088430074],
  ["MD", 92.9489676665368],
  ["CO", 93.93520322036099],
  ["AG", 91.83060641475133],
  ["AE", 90.34248798857291],
  ["CZ", 95.21945201921828],
  ["AR", 58.274250097389945],
  ["BI", 74.73282690559667],
  ["CM", 96.04531878976756],
  ["LB", 77.97396442020516],
  ["LU", 83.38040514218933],
  ["TD", 96.60044150110375],
  ["TR", 94.1118036618621],
  ["Scotland", 81.11381638748215],
  ["UA", 96.80496039475392],
  ["LT", 96.1806908193741],
  ["LC", 65.38793663160628],
  ["MG", 76.20731073886509],
  ["CL", 93.31158291131023],
  ["BH", 76.38163874821451],
  ["AD", 91.84164394234516],
  ["CH", 91.94357875600572],
  ["NO", 89.49065056486171],
  ["MT", 73.13563173613817],
  ["MC", 85.5875860277886],
  ["TV", 82.16822490585638],
  ["TW", 95.25840799896118],
  ["US", 88.28269055966757],
  ["MU", 95.80151928320997],
  ["BZ", 85.39702636021296],
  ["CI", 92.64316322555513],
  ["BM", 87.5363589144267],
  ["CK", 92.90124659135176],
  ["AT", 83.59531229710427],
  ["BO", 94.95195429165044],
  ["LS", 59.503311258278146],
  ["NL", 84.00240228541747],
  ["MW", 96.41897156213479],
  ["YE", 84.91721854304636],
  ["UG", 93.10738865082456],
  ["TT", 92.24094273470978],
  ["NZ", 93.21419296195299],
  ["MA", 96.5929749383197],
  ["MV", 95.94825347357487],
  ["LR", 86.69523438514479],
  ["BY", 92.0714842228282],
  ["BN", 81.85690170107777],
  ["AU", 92.81327100376573],
  ["GE", 79.16212180236333],
  ["GR", 85.02175042202312],
  ["IN", 84.34034540968706],
  ["GBENG", 79.28418387222439],
  ["RS", 85.74048824827945],
  ["PL", 84.1858200233736],
  ["SV", 84.89254642254252],
  ["SA", 93.65147383456693],
  ["HK", 93.03954031943903],
  ["KG", 88.17361381638749],
  ["GD", 87.01175172055578],
  ["GQ", 88.8637839241657],
  ["DJ", 0],
  ["KE", 90.07921049214389],
  ["KR", 78.84852616543306],
  ["SC", 89.57797688611868],
  ["ST", 69.82177639267627],
  ["PY", 84.95357745747305],
  ["SB", 87.98337878197636],
  ["IL", 78.03077522399688],
  ["DK", 89.50688222308791],
  ["DO", 89.6617322425659],
  ["RU", 90.68627450980392],
  ["SG", 80.92812621737437],
  ["PK", 79.63186599142969],
  ["GB", 83.29957148422282],
  ["GU", 94.82469809115699],
  ["GW", 66.39397480846644],
  ["JP", 76.92767173094404],
  ["SR", 89.62017919750681],
  ["SE", 94.80814179976626],
  ["SD", 91.98188546941955],
  ["SS", 95.25516166731593],
  ["PH", 78.97675626542008],
  ["RW", 89.55460329827295],
  ["KU", 91.51473834566939],
  ["HN", 84.57310738865083],
  ["GA", 93.81476431632255],
  ["DZ", 96.05830411634852],
  ["DM", 91.61699779249449],
  ["FR", 93.27425009738995],
  ["GL", 84.08096351123231],
  ["ES", 93.53460589533826],
  ["HT", 95.31456953642385],
  ["PE", 92.176665368134],
  ["QA", 76.63647578236593],
  ["SI", 90.98720945331775],
  ["PR", 95.07109466303078],
  ["PS", 90.49928580703805],
  ["IQ", 86.03200883002208],
  ["HU", 84.45104531878977],
  ["KN", 89.00759641604986],
  ["KY", 89.9837683417738],
  ["FI", 85.397675626542],
  ["ER", 85.38306713413843],
  ["GM", 93.34015062978834],
  ["EE", 92.38215816127776],
  ["EG", 85.16264121542658],
  ["ID", 85.61420594727957],
  ["IS", 92.0890144137125],
  ["SK", 88.4164394234515],
  ["RO", 96.62576288793663],
  ["PG", 93.50181794572133],
  ["KM", 91.20536293987794],
  ["IR", 75.76905596675756],
  ["KZ", 86.20146734190365],
  ["IE", 88.27457473055448],
  ["GN", 95.19348136605636],
  ["FJ", 69.72406181015452],
  ["GY", 65.12043890403844],
  ["JM", 71.75594078691078],
  ["KI", 81.66828983248928],
  ["HR", 83.47649655888846],
  ["SO", 92.91682898324893],
  ["PT", 92.21756914686404],
  ["SY", 85.16491364757823],
  ["SN", 95.41325801843917],
  ["KH", 92.87560057135437],
  ["EC", 87.69737696403065],
  ["ET", 90.06849759771458],
  ["DE", 96.69750681729646],
  ["SL", 83.62582781456953],
  ["PW", 91.79197506817296],
  ["SZ", 92.02895727827554],
  ["PA", 84.4676016101805],
  ["SM", 80.46519932476302],
  ["IT", 88.81865991429684],
  ["JO", 95.0188287235424],
  ["GH", 95.05875860277885],
  ["CG", 91.98610570055837],
  ["BT", 87.3396312167251],
  ["AO", 96.93383976106999],
  ["ML", 91.09790936242047],
  ["TN", 93.14731853006103],
  ["LI", 95.45675886248539],
  ["MM", 89.1455655109726],
  ["MZ", 95.53856641994545],
  ["NA", 64.83313855343462],
  ["CF", 76.93221659524737],
  ["BB", 95.88884560446695],
  ["CD", 90.28535255161667],
  ["BW", 65.75899233865732],
  ["AL", 94.66432930788208],
  ["NC", 67.92104921438774],
  ["MX", 92.44903259316972],
  ["TZ", 88.94753928061292],
  ["VE", 95.2386053759252],
  ["MN", 94.69581872484093],
  ["MY", 87.95740812881444],
  ["AM", 97.76717309440332],
  ["CR", 86.99487079600053],
  ["BA", 95.31456953642385],
  ["AZ", 93.99948058693676],
  ["CV", 89.68900142838592],
  ["BR", 67.4636410855733],
  ["CA", 91.82119205298012],
  ["BE", 97.06499155953773],
  ["TH", 88.55148681989353],
  ["WS", 96.49233865731723],
  ["UZ", 56.10212959355928],
  ["Wales", 57.43442410076614],
  ["NG", 92.69380599922088],
  ["NP", 86.94682508765096],
  ["MK", 82.40877808076873],
  ["BD", 93.88066484872094],
  ["BS", 94.79028697571744],
  ["CU", 93.46967926243344],
  ["BF", 61.48032723022984],
  ["NR", 95.88430074016362],
  ["NE", 59.06051162186729],
  ["ZW", 82.82008830022075],
  ["VC", 92.49675366835476],
  ["UY", 80.22854174782495],
  ["TJ", 80.68659914296845],
  ["VU", 62.566549798727436],
  ["ZA", 95.84859109206596],
  ["BG", 90.23568367744448],
]);

export const keMap = new Map<string, number>([
  ["CY", 89.62764576029087],
  ["AF", 77.71815348656018],
  ["CN", 92.32664589014414],
  ["BJ", 92.55323983898195],
  ["LA", 93.29340345409688],
  ["ME", 70.81645240877809],
  ["OM", 69.46305674587717],
  ["NI", 93.56349824698091],
  ["MR", 90.43857940527205],
  ["TG", 88.7764576029087],
  ["TP", 86.8140501233606],
  ["North Korea", 90.67588624853914],
  ["VN", 93.11680301259577],
  ["ZM", 90.3113232047786],
  ["MD", 92.72139981820543],
  ["CO", 90.32560706401766],
  ["AG", 68.0418127515907],
  ["AE", 94.14718867679522],
  ["CZ", 82.71263472276328],
  ["AR", 96.3124918841709],
  ["BI", 90.48824827944422],
  ["CM", 90.30807687313336],
  ["LB", 86.165757693806],
  ["LU", 94.03811193351513],
  ["TD", 94.76788728736528],
  ["TR", 91.01967276977015],
  ["Scotland", 88.02460719387093],
  ["UA", 92.49091027139332],
  ["LT", 93.94137125048695],
  ["LC", 96.48811842617842],
  ["MG", 90.36229061160888],
  ["CL", 90.69731203739774],
  ["BH", 88.32911310219453],
  ["AD", 94.36112193221659],
  ["CH", 94.24425399298792],
  ["NO", 90.76061550447993],
  ["MT", 91.23100895987534],
  ["MC", 92.54804570834956],
  ["TV", 92.59479288404103],
  ["TW", 88.28820932346449],
  ["US", 88.92091936112193],
  ["MU", 90.50318140501233],
  ["BZ", 88.68296325152578],
  ["CI", 95.07336709518245],
  ["BM", 83.6293987793793],
  ["CK", 84.18744318919622],
  ["AT", 93.93780028567718],
  ["BO", 93.63978704064408],
  ["LS", 86.7520451889365],
  ["NL", 93.5904427996364],
  ["MW", 68.39631216725101],
  ["YE", 93.97805479807818],
  ["UG", 59.3705362939878],
  ["TT", 84.02545124009869],
  ["NZ", 84.01084274769511],
  ["MA", 92.12245162965849],
  ["MV", 92.44351382937282],
  ["LR", 86.54298143098299],
  ["BY", 91.92020516815998],
  ["BN", 84.57115958966368],
  ["AU", 82.87787300350604],
  ["GE", 82.69055966757564],
  ["GR", 92.25262952863264],
  ["IN", 93.24990261005064],
  ["GBENG", 86.64199454616283],
  ["RS", 89.93702116608233],
  ["PL", 90.42364627970395],
  ["SV", 92.64933125568108],
  ["SA", 89.93247630177899],
  ["HK", 92.70808985845994],
  ["KG", 91.1388131411505],
  ["GD", 88.69075444747435],
  ["GQ", 92.2896377093884],
  ["DJ", 90.07921049214389],
  ["KE", 0],
  ["KR", 83.02493182703546],
  ["SC", 85.62491884170886],
  ["ST", 87.50649266329049],
  ["PY", 92.74185170757045],
  ["SB", 87.8356706921179],
  ["IL", 85.99110505129204],
  ["DK", 93.02103622906117],
  ["DO", 95.07985975847294],
  ["RU", 89.42669783145047],
  ["SG", 91.22451629658485],
  ["PK", 88.87774315024024],
  ["GB", 82.1321906245942],
  ["GU", 90.14673419036488],
  ["GW", 89.50233735878457],
  ["JP", 85.17660044150111],
  ["SR", 94.15920010388261],
  ["SE", 93.03986495260355],
  ["SD", 92.38215816127776],
  ["SS", 58.11355668095053],
  ["PH", 87.41267367874302],
  ["RW", 91.47318530061031],
  ["KU", 88.90890793403454],
  ["HN", 93.2641864692897],
  ["GA", 93.66478379431243],
  ["DZ", 88.97902869757175],
  ["DM", 88.64238410596028],
  ["FR", 94.57310738865083],
  ["GL", 90.39897415920011],
  ["ES", 87.72919101415401],
  ["HT", 92.03058044409816],
  ["PE", 94.86495260355798],
  ["QA", 86.62413972211401],
  ["SI", 89.64128035320088],
  ["PR", 85.67913258018439],
  ["PS", 66.49980522010128],
  ["IQ", 92.55356447214648],
  ["HU", 93.6832878846903],
  ["KN", 77.78989741592001],
  ["KY", 83.71997143228153],
  ["FI", 87.51136216075834],
  ["ER", 84.91040124659135],
  ["GM", 91.04726658875471],
  ["EE", 90.78820932346449],
  ["EG", 94.07706791325802],
  ["ID", 92.55226593948838],
  ["IS", 91.90267497727568],
  ["SK", 88.43234644851317],
  ["RO", 94.95779768861186],
  ["PG", 82.53668354759121],
  ["KM", 86.41897156213479],
  ["IR", 88.9946110894689],
  ["KZ", 97.73406051162186],
  ["IE", 95.23341124529281],
  ["GN", 95.03181405012336],
  ["FJ", 90.12465913517725],
  ["GY", 86.63452798337879],
  ["JM", 83.35216205687573],
  ["KI", 85.71679002726918],
  ["HR", 92.30489546812103],
  ["SO", 97.84248798857291],
  ["PT", 78.28853395662901],
  ["SY", 92.72789248149591],
  ["SN", 94.23094403324244],
  ["KH", 89.94838332684067],
  ["EC", 89.13744968185951],
  ["ET", 87.52402285417479],
  ["DE", 63.164524087780805],
  ["SL", 94.41825736917284],
  ["PW", 96.52512660693417],
  ["SZ", 73.2547721075185],
  ["PA", 90.6538111933515],
  ["SM", 90.67134138423582],
  ["IT", 94.7789248149591],
  ["JO", 67.11888066484872],
  ["GH", 92.25392806129075],
  ["CG", 91.99259836384886],
  ["BT", 92.47013374886379],
  ["AO", 89.0351902350344],
  ["ML", 95.03084015062979],
  ["TN", 92.82787949616933],
  ["LI", 88.79690949227374],
  ["MM", 94.37086092715232],
  ["MZ", 84.40592130892092],
  ["NA", 85.54733151538761],
  ["CF", 87.98305414881185],
  ["BB", 93.61089468900144],
  ["CD", 90.04057914556552],
  ["BW", 96.29593559278015],
  ["AL", 84.34002077652252],
  ["NC", 87.82723022984028],
  ["MX", 93.89981820542786],
  ["TZ", 81.31898454746137],
  ["VE", 89.11375146084924],
  ["MN", 87.62206206986106],
  ["MY", 81.52902220490846],
  ["AM", 93.53038566419946],
  ["CR", 87.10751850409038],
  ["BA", 86.95753798208024],
  ["AZ", 93.00480457083495],
  ["CV", 87.58148292429556],
  ["BR", 87.64446175821323],
  ["CA", 88.95792754187768],
  ["BE", 84.73282690559668],
  ["TH", 88.20640176600442],
  ["WS", 89.55103233346318],
  ["UZ", 95.498636540709],
  ["Wales", 73.84203350214258],
  ["NG", 94.94903259316972],
  ["NP", 79.77178288533958],
  ["MK", 72.32567199065056],
  ["BD", 90.57038047006883],
  ["BS", 89.38839111803661],
  ["CU", 84.93409946760161],
  ["BF", 92.60583041163486],
  ["NR", 89.36599142968446],
  ["NE", 92.55323983898195],
  ["ZW", 86.04239709128684],
  ["VC", 91.502726918582],
  ["UY", 86.95981041423192],
  ["TJ", 60.70412933385274],
  ["VU", 79.46727697701597],
  ["ZA", 89.06408258667705],
  ["BG", 90.33469679262434],
]);

export const krMap = new Map<string, number>([
  ["CY", 24.806843267108167],
  ["AF", 87.9070899883132],
  ["CN", 94.40721984157902],
  ["BJ", 95.45740812881444],
  ["LA", 94.15465523957927],
  ["ME", 91.20179197506818],
  ["OM", 70.57362680171406],
  ["NI", 75.91481625762889],
  ["MR", 92.13219062459422],
  ["TG", 92.08024931827036],
  ["TP", 87.91390728476821],
  ["North Korea", 87.93955330476561],
  ["VN", 94.09102713933255],
  ["ZM", 94.85943383976107],
  ["MD", 91.37449681859499],
  ["CO", 90.12271133619011],
  ["AG", 86.70724581223219],
  ["AE", 82.13413842358135],
  ["CZ", 66.98837813271004],
  ["AR", 76.63160628489807],
  ["BI", 79.9259836384885],
  ["CM", 89.7036099207895],
  ["LB", 63.25087650954422],
  ["LU", 76.61472536034282],
  ["TD", 95.9057265290222],
  ["TR", 87.13998182054279],
  ["Scotland", 73.02201012855474],
  ["UA", 94.93604726658876],
  ["LT", 95.70185690170108],
  ["LC", 95.2425009738995],
  ["MG", 67.37339306583561],
  ["CL", 71.58356057654849],
  ["BH", 63.21516686144657],
  ["AD", 93.22750292169847],
  ["CH", 89.71432281521881],
  ["NO", 81.25762887936632],
  ["MT", 57.82787949616933],
  ["MC", 54.079664978574215],
  ["TV", 87.7142578885859],
  ["TW", 93.48526165433061],
  ["US", 65.92098428775483],
  ["MU", 95.01103752759383],
  ["BZ", 79.78411894559149],
  ["CI", 79.15725230489547],
  ["BM", 82.62076353720296],
  ["CK", 79.4676016101805],
  ["AT", 76.31508894948708],
  ["BO", 95.99013115179847],
  ["LS", 70.85638228801454],
  ["NL", 74.72178937800285],
  ["MW", 88.79885729126087],
  ["YE", 72.27210751850409],
  ["UG", 91.56408258667706],
  ["TT", 87.63764446175821],
  ["NZ", 80.74243604726658],
  ["MA", 89.66400467471757],
  ["MV", 94.84417608102844],
  ["LR", 65.09771458252175],
  ["BY", 91.04629268926114],
  ["BN", 70.85540838852097],
  ["AU", 85.3006103103493],
  ["GE", 41.22159459810415],
  ["GR", 64.33222958057395],
  ["IN", 75.08862485391508],
  ["GBENG", 34.84190364887677],
  ["RS", 63.6738735229191],
  ["PL", 58.535904427996364],
  ["SV", 74.17478249577977],
  ["SA", 90.8330087001688],
  ["HK", 94.18452149071548],
  ["KG", 93.76866640696014],
  ["GD", 88.60148032723023],
  ["GQ", 82.53473574860408],
  ["DJ", 78.84852616543306],
  ["KE", 83.02493182703546],
  ["KR", 0],
  ["SC", 74.63121672510063],
  ["ST", 94.2871055707051],
  ["PY", 72.82528243085314],
  ["SB", 88.87449681859498],
  ["IL", 44.129009219581874],
  ["DK", 78.39241656927672],
  ["DO", 74.05953772237372],
  ["RU", 67.94961693286587],
  ["SG", 55.95572003635891],
  ["PK", 69.43481366056356],
  ["GB", 67.0283080119465],
  ["GU", 89.29976626412154],
  ["GW", 95.74698091156992],
  ["JP", 18.98974159200104],
  ["SR", 77.73341124529281],
  ["SE", 92.73957927541878],
  ["SD", 81.55986235553824],
  ["SS", 85.4178028827425],
  ["PH", 76.91825736917284],
  ["RW", 96.14627970393455],
  ["KU", 83.45539540319439],
  ["HN", 75.66939358524867],
  ["GA", 96.51668614465653],
  ["DZ", 53.5336319958447],
  ["DM", 80.32658096351123],
  ["FR", 78.18140501233606],
  ["GL", 54.51921828333982],
  ["ES", 94.39131281651734],
  ["HT", 93.31580314244903],
  ["PE", 79.16049863654071],
  ["QA", 66.99129983119076],
  ["SI", 68.20737566549798],
  ["PR", 76.48032723022983],
  ["PS", 82.74704583820282],
  ["IQ", 71.5040254512401],
  ["HU", 75.47786001817946],
  ["KN", 85.01785482404883],
  ["KY", 79.54648746915986],
  ["FI", 43.50993377483444],
  ["ER", 92.52597065316192],
  ["GM", 85.92877548370342],
  ["EE", 68.88520971302428],
  ["EG", 72.21269964939619],
  ["ID", 54.11602389300091],
  ["IS", 79.96656278405402],
  ["SK", 66.80268796260226],
  ["RO", 96.04596805609661],
  ["PG", 87.24548759901312],
  ["KM", 83.61414102064667],
  ["IR", 71.39884430593429],
  ["KZ", 95.18082067263992],
  ["IE", 79.15205817426309],
  ["GN", 96.94909751980262],
  ["FJ", 87.71945201921828],
  ["GY", 87.4042332164654],
  ["JM", 88.7777561355668],
  ["KI", 78.35638228801454],
  ["HR", 72.58862485391508],
  ["SO", 97.34190364887677],
  ["PT", 90.68822230879107],
  ["SY", 73.81411504999352],
  ["SN", 95.56096610829763],
  ["KH", 91.28002856771847],
  ["EC", 90.43793013894299],
  ["ET", 90.25840799896118],
  ["DE", 91.36897805479808],
  ["SL", 76.388131411505],
  ["PW", 96.61569925983639],
  ["SZ", 91.43552785352551],
  ["PA", 54.490001298532654],
  ["SM", 57.25717439293598],
  ["IT", 79.1832229580574],
  ["JO", 82.80385664199454],
  ["GH", 94.4393585248669],
  ["CG", 93.05414881184262],
  ["BT", 92.60485651214128],
  ["AO", 88.5498636540709],
  ["ML", 97.00201272562005],
  ["TN", 96.44981171276457],
  ["LI", 93.21192052980133],
  ["MM", 94.93604726658876],
  ["MZ", 90.22659394883782],
  ["NA", 84.5026619919491],
  ["CF", 75.6158291131022],
  ["BB", 93.10414231917933],
  ["CD", 90.43306064147514],
  ["BW", 92.6860148032723],
  ["AL", 92.05168159979223],
  ["NC", 92.42241267367875],
  ["MX", 76.68809245552526],
  ["TZ", 87.661667315933],
  ["VE", 89.0014283859239],
  ["MN", 88.74951305025321],
  ["MY", 64.82275029216986],
  ["AM", 95.0753148941696],
  ["CR", 68.08985845994027],
  ["BA", 87.48214517595117],
  ["AZ", 91.86404363069731],
  ["CV", 83.07719776652382],
  ["BR", 84.84677314634463],
  ["CA", 64.41501103752759],
  ["BE", 93.39988313206076],
  ["TH", 72.91877678223607],
  ["WS", 91.93189196208284],
  ["UZ", 76.06090118166472],
  ["Wales", 56.11543955330477],
  ["NG", 79.20107778210622],
  ["NP", 50.64991559537722],
  ["MK", 82.89572782755486],
  ["BD", 95.32723022984028],
  ["BS", 95.60706401766005],
  ["CU", 74.41468640436307],
  ["BF", 94.9243604726659],
  ["NR", 89.36761459550708],
  ["NE", 75.4703934553954],
  ["ZW", 80.72263342423062],
  ["VC", 93.05479807817166],
  ["UY", 48.299896117387355],
  ["TJ", 69.7493831969874],
  ["VU", 87.60940137644462],
  ["ZA", 86.77314634463058],
  ["BG", 68.3018439163745],
]);

export const scMap = new Map<string, number>([
  ["CY", 77.57828719723183],
  ["AF", 86.88148788927336],
  ["CN", 61.06617647058824],
  ["BJ", 85.56055363321799],
  ["LA", 72.07915224913495],
  ["ME", 83.7621107266436],
  ["OM", 75.32785467128028],
  ["NI", 83.09948096885813],
  ["MR", 73.38321799307958],
  ["TG", 75.1038062283737],
  ["TP", 65.76038062283736],
  ["North Korea", 65.05622837370242],
  ["VN", 68.23961937716263],
  ["ZM", 85.375],
  ["MD", 72.51038062283737],
  ["CO", 87.15570934256056],
  ["AG", 78.17733564013841],
  ["AE", 84.14316608996539],
  ["CZ", 73.44723183391004],
  ["AR", 85.40441176470588],
  ["BI", 79.87024221453287],
  ["CM", 80.12197231833909],
  ["LB", 66.02465397923876],
  ["LU", 70.35596885813149],
  ["TD", 76.70415224913495],
  ["TR", 63.66479238754326],
  ["Scotland", 83.66046712802768],
  ["UA", 90.00778546712803],
  ["LT", 87.9204152249135],
  ["LC", 92.58477508650519],
  ["MG", 76.85164359861591],
  ["CL", 71.66176470588236],
  ["BH", 63.81487889273356],
  ["AD", 73.57742214532873],
  ["CH", 86.67690311418686],
  ["NO", 65.8386678200692],
  ["MT", 68.35899653979239],
  ["MC", 59.43858131487889],
  ["TV", 77.69506920415225],
  ["TW", 87.91738754325259],
  ["US", 75.05925605536332],
  ["MU", 75.49307958477509],
  ["BZ", 63.586505190311414],
  ["CI", 86.34688581314879],
  ["BM", 60.17733564013841],
  ["CK", 76.30276816608996],
  ["AT", 67.29368512110727],
  ["BO", 61.845155709342556],
  ["LS", 59.84558823529412],
  ["NL", 83.51254325259515],
  ["MW", 77.46237024221453],
  ["YE", 73.92776816608998],
  ["UG", 87.81228373702422],
  ["TT", 65.81833910034602],
  ["NZ", 76.42517301038062],
  ["MA", 62.98010380622837],
  ["MV", 76.4636678200692],
  ["LR", 61.16349480968858],
  ["BY", 61.03373702422146],
  ["BN", 81.17906574394463],
  ["AU", 88.30233564013841],
  ["GE", 80.30320069204151],
  ["GR", 82.18598615916956],
  ["IN", 83.14057093425605],
  ["GBENG", 75.16868512110727],
  ["RS", 62.23702422145328],
  ["PL", 82.66176470588236],
  ["SV", 82.39013840830451],
  ["SA", 69.01038062283737],
  ["HK", 67.82352941176471],
  ["KG", 66.80839100346022],
  ["GD", 75.50216262975779],
  ["GQ", 78.44420415224913],
  ["DJ", 89.67301038062284],
  ["KE", 85.53114186851211],
  ["KR", 74.56271626297578],
  ["SC", 0],
  ["ST", 87.9325259515571],
  ["PY", 68.22231833910034],
  ["SB", 83.31185121107266],
  ["IL", 76.99437716262976],
  ["DK", 64.84645328719724],
  ["DO", 64.37456747404843],
  ["RU", 83.48572664359861],
  ["SG", 56.870674740484425],
  ["PK", 87.42603806228374],
  ["GB", 61.99913494809689],
  ["GU", 72.31055363321799],
  ["GW", 86.92517301038062],
  ["JP", 80.39835640138409],
  ["SR", 85.66868512110727],
  ["SE", 78.1362456747405],
  ["SD", 72.31271626297577],
  ["SS", 61.66565743944636],
  ["PH", 82.0363321799308],
  ["RW", 86.11937716262976],
  ["KU", 80.16306228373702],
  ["HN", 82.43641868512111],
  ["GA", 90.06444636678201],
  ["DZ", 73.76124567474048],
  ["DM", 66.34904844290658],
  ["FR", 58.094723183391004],
  ["GL", 82.33737024221453],
  ["ES", 75.97058823529412],
  ["HT", 72.5],
  ["PE", 69.94377162629758],
  ["QA", 89.00865051903114],
  ["SI", 82.06141868512111],
  ["PR", 76.88494809688581],
  ["PS", 68.6219723183391],
  ["IQ", 72.11721453287197],
  ["HU", 54.5514705882353],
  ["KN", 89.89230103806229],
  ["KY", 76.40484429065744],
  ["FI", 81.56833910034602],
  ["ER", 82.50605536332179],
  ["GM", 73.60164359861592],
  ["EE", 88.34429065743944],
  ["EG", 72.90095155709344],
  ["ID", 59.40268166089966],
  ["IS", 69.48875432525952],
  ["SK", 79.91262975778547],
  ["RO", 62.028546712802765],
  ["PG", 76.58044982698962],
  ["KM", 80.99394463667821],
  ["IR", 77.04108996539793],
  ["KZ", 87.77032871972318],
  ["IE", 86.55276816608996],
  ["GN", 87.63451557093425],
  ["FJ", 82.43901384083046],
  ["GY", 81.2681660899654],
  ["JM", 88.46496539792388],
  ["KI", 62.294982698961945],
  ["HR", 79.5756920415225],
  ["SO", 91.22058823529412],
  ["PT", 85.03027681660899],
  ["SY", 72.99870242214533],
  ["SN", 66.95977508650519],
  ["KH", 78.76989619377163],
  ["EC", 81.44939446366783],
  ["ET", 82.2750865051903],
  ["DE", 89.83477508650519],
  ["SL", 83.90095155709344],
  ["PW", 91.54065743944638],
  ["SZ", 85.02724913494811],
  ["PA", 58.67906574394463],
  ["SM", 87.51340830449827],
  ["IT", 71.23486159169549],
  ["JO", 64.4424740484429],
  ["GH", 61.612889273356394],
  ["CG", 86.87067474048443],
  ["BT", 81.63278546712803],
  ["AO", 74.92474048442907],
  ["ML", 78.03546712802768],
  ["TN", 86.8196366782007],
  ["LI", 72.73961937716264],
  ["MM", 86.3871107266436],
  ["MZ", 84.31531141868513],
  ["NA", 40.442474048442904],
  ["CF", 76.56574394463668],
  ["BB", 75.2742214532872],
  ["CD", 73.39186851211072],
  ["BW", 93.05709342560554],
  ["AL", 71.50778546712803],
  ["NC", 66.42863321799308],
  ["MX", 68.40224913494811],
  ["TZ", 91.46972318339101],
  ["VE", 81.04498269896195],
  ["MN", 72.65743944636678],
  ["MY", 66.38235294117646],
  ["AM", 88.47621107266436],
  ["CR", 67.84904844290658],
  ["BA", 71.84731833910034],
  ["AZ", 87.77551903114187],
  ["CV", 69.2621107266436],
  ["BR", 82.42690311418686],
  ["CA", 82.20242214532873],
  ["BE", 72.04974048442907],
  ["TH", 79.57309688581314],
  ["WS", 52.85899653979239],
  ["UZ", 80.94506920415225],
  ["Wales", 76.3538062283737],
  ["NG", 76.18166089965398],
  ["NP", 66.3287197231834],
  ["MK", 77.98140138408304],
  ["BD", 65.10467128027682],
  ["BS", 89.80406574394463],
  ["CU", 75.04065743944636],
  ["BF", 70.07482698961938],
  ["NR", 76.65484429065744],
  ["NE", 82.62110726643598],
  ["ZW", 78.43685121107266],
  ["VC", 88.02292387543253],
  ["UY", 80.63581314878893],
  ["TJ", 81.090830449827],
  ["VU", 53.8992214532872],
  ["ZA", 68.01773356401384],
  ["BG", 87.82439446366782],
]);

export const stMap = new Map<string, number>([
  ["CY", 89.5605485595998],
  ["AF", 92.86441262722097],
  ["CN", 93.24952561669829],
  ["BJ", 84.60539934448853],
  ["LA", 90.29023632913577],
  ["ME", 84.49844747283078],
  ["OM", 83.3228393996895],
  ["NI", 88.48154217698809],
  ["MR", 89.23753665689149],
  ["TG", 71.58659651543901],
  ["TP", 94.49197860962568],
  ["North Korea", 81.81041918233569],
  ["VN", 81.35630498533725],
  ["ZM", 91.35156115232017],
  ["MD", 86.0492496118682],
  ["CO", 79.11505951354148],
  ["AG", 79.7804899085734],
  ["AE", 59.52691047093324],
  ["CZ", 92.71692254614456],
  ["AR", 88.67345178540624],
  ["BI", 82.43574262549595],
  ["CM", 82.85664999137485],
  ["LB", 88.46860445057789],
  ["LU", 89.2263239606693],
  ["TD", 84.40745213041228],
  ["TR", 82.12049335863377],
  ["Scotland", 88.4392789373814],
  ["UA", 81.96567189925824],
  ["LT", 90.76763843367259],
  ["LC", 91.40676211833707],
  ["MG", 97.8958944281525],
  ["CL", 88.00672761773332],
  ["BH", 94.40098326720717],
  ["AD", 85.73572537519406],
  ["CH", 90.93108504398828],
  ["NO", 81.4697257202001],
  ["MT", 96.71166120407108],
  ["MC", 89.32076936346385],
  ["TV", 77.11445575297567],
  ["TW", 93.99258237019149],
  ["US", 77.07391754355702],
  ["MU", 78.37717785061238],
  ["BZ", 80.97377954114197],
  ["CI", 94.23624288425047],
  ["BM", 81.53096429187511],
  ["CK", 88.49102984302225],
  ["AT", 84.57521131619802],
  ["BO", 70.20182853199931],
  ["LS", 63.993013627738485],
  ["NL", 88.87657409004657],
  ["MW", 87.07132999827498],
  ["YE", 90.94445402794548],
  ["UG", 85.27557357253752],
  ["TT", 78.8903743315508],
  ["NZ", 88.35561497326204],
  ["MA", 84.50750388131793],
  ["MV", 83.48326720717613],
  ["LR", 83.67215801276522],
  ["BY", 91.32827324478178],
  ["BN", 84.8956356736243],
  ["AU", 88.14473003277557],
  ["GE", 91.00138002415042],
  ["GR", 86.9691219596343],
  ["IN", 87.5789201311023],
  ["GBENG", 90.9578230119027],
  ["RS", 85.74219423839918],
  ["PL", 89.7498706227359],
  ["SV", 86.41366223908918],
  ["SA", 88.71053993444885],
  ["HK", 88.69889598067967],
  ["KG", 75.28290495083664],
  ["GD", 74.23969294462654],
  ["GQ", 86.27177850612385],
  ["DJ", 69.34060721062619],
  ["KE", 86.89278937381404],
  ["KR", 93.85156115232016],
  ["SC", 87.81568052440917],
  ["ST", 0],
  ["PY", 88.88692427117475],
  ["SB", 86.26358461273072],
  ["IL", 92.63929618768329],
  ["DK", 85.1099706744868],
  ["DO", 88.37760910815939],
  ["RU", 91.31317923063654],
  ["SG", 87.42366741417975],
  ["PK", 94.55278592375367],
  ["GB", 77.70959116784543],
  ["GU", 90.4817146800069],
  ["GW", 56.35803001552527],
  ["JP", 94.18406072106262],
  ["SR", 86.27652233914094],
  ["SE", 80.17595307917888],
  ["SD", 89.22804899085735],
  ["SS", 87.59272037260652],
  ["PH", 92.6160082801449],
  ["RW", 76.82853199930999],
  ["KU", 90.90564084871485],
  ["HN", 86.94367776436087],
  ["GA", 72.3714852509919],
  ["DZ", 91.11091944109022],
  ["DM", 88.24219423839918],
  ["FR", 94.99741245471796],
  ["GL", 87.05278592375366],
  ["ES", 65.66844919786097],
  ["HT", 88.75452820424357],
  ["PE", 82.71692254614456],
  ["QA", 93.60531309297913],
  ["SI", 91.93332758323271],
  ["PR", 88.30645161290323],
  ["PS", 80.39416939796446],
  ["IQ", 87.6099706744868],
  ["HU", 87.14162497843712],
  ["KN", 75.97895463170605],
  ["KY", 87.9088321545627],
  ["FI", 93.81533551837157],
  ["ER", 57.77643608763153],
  ["GM", 85.74780058651027],
  ["EE", 96.07728135242367],
  ["EG", 88.4457478005865],
  ["ID", 89.35872002760048],
  ["IS", 87.59530791788856],
  ["SK", 89.96506813869243],
  ["RO", 84.54071071243747],
  ["PG", 91.96135932378816],
  ["KM", 88.86967396929447],
  ["IR", 57.80274279799896],
  ["KZ", 86.7500431257547],
  ["IE", 79.01716405037088],
  ["GN", 72.10841814731758],
  ["FJ", 82.41978609625669],
  ["GY", 32.51638778678627],
  ["JM", 55.20484733482836],
  ["KI", 75.80213903743316],
  ["HR", 85.81680179403139],
  ["SO", 92.87864412627222],
  ["PT", 89.86717267552183],
  ["SY", 89.36777643608764],
  ["SN", 93.78557874762808],
  ["KH", 81.76729342763498],
  ["EC", 78.83991719855098],
  ["ET", 73.30429532516818],
  ["DE", 93.94859410039675],
  ["SL", 63.63032603070554],
  ["PW", 79.38718302570294],
  ["SZ", 85.7197688459548],
  ["PA", 92.07564257374504],
  ["SM", 87.58107641883733],
  ["IT", 80.59901673279283],
  ["JO", 80.02760048300846],
  ["GH", 70.80774538554425],
  ["CG", 77.73934793858892],
  ["BT", 86.23425909953424],
  ["AO", 88.02311540451959],
  ["ML", 69.47041573227531],
  ["TN", 78.97662584095222],
  ["LI", 88.72865275142316],
  ["MM", 86.71511126444713],
  ["MZ", 80.50155252716922],
  ["NA", 69.14611005692599],
  ["CF", 93.50051750905641],
  ["BB", 93.43367258927032],
  ["CD", 87.17914438502675],
  ["BW", 89.80205278592376],
  ["AL", 81.98464723132655],
  ["NC", 62.53493186130758],
  ["MX", 89.51440400207004],
  ["TZ", 74.84302225288943],
  ["VE", 87.57374504053821],
  ["MN", 84.32594445402795],
  ["MY", 79.91504226323961],
  ["AM", 94.56011730205279],
  ["CR", 86.78670001725031],
  ["BA", 85.89830947041574],
  ["AZ", 87.89071933758841],
  ["CV", 86.97300327755735],
  ["BR", 38.96498188718303],
  ["CA", 90.03148180093152],
  ["BE", 97.37019147835088],
  ["TH", 91.96653441435225],
  ["WS", 90.2199413489736],
  ["UZ", 62.18130067276177],
  ["Wales", 68.1404174573055],
  ["NG", 93.5790063826117],
  ["NP", 79.24831809556667],
  ["MK", 76.98249094359151],
  ["BD", 92.75702949801622],
  ["BS", 92.85578747628084],
  ["CU", 80.63179230636536],
  ["BF", 65.50112126962222],
  ["NR", 92.17267552182163],
  ["NE", 65.00819389339313],
  ["ZW", 92.20027600483009],
  ["VC", 80.46446437812662],
  ["UY", 88.4996549939624],
  ["TJ", 83.5677936863895],
  ["VU", 68.57469380714163],
  ["ZA", 87.28437122649646],
  ["BG", 94.9085734000345],
]);

export const pyMap = new Map<string, number>([
  ["CY", 71.05211937716263],
  ["AF", 92.99380046136102],
  ["CN", 66.40102364475202],
  ["BJ", 95.75728085351788],
  ["LA", 67.05738177623991],
  ["ME", 71.61440311418686],
  ["OM", 90.54245963091118],
  ["NI", 68.23565455594003],
  ["MR", 95.15498846597463],
  ["TG", 84.34328143021914],
  ["TP", 65.1603950403691],
  ["North Korea", 73.27458189158016],
  ["VN", 63.37694636678201],
  ["ZM", 93.58924452133795],
  ["MD", 73.7853229527105],
  ["CO", 87.35546424452134],
  ["AG", 87.9808246828143],
  ["AE", 68.00028835063438],
  ["CZ", 87.31401384083046],
  ["AR", 68.6541234140715],
  ["BI", 72.26931949250289],
  ["CM", 85.88956170703575],
  ["LB", 45.001081314878896],
  ["LU", 36.059328143021915],
  ["TD", 85.19824106113033],
  ["TR", 62.00547866205306],
  ["Scotland", 83.67178489042676],
  ["UA", 97.75194636678201],
  ["LT", 98.18807670126874],
  ["LC", 97.57677335640138],
  ["MG", 86.78957612456747],
  ["CL", 87.67877739331027],
  ["BH", 64.45105247981544],
  ["AD", 85.09010957324105],
  ["CH", 85.87766724336794],
  ["NO", 85.72736447520185],
  ["MT", 66.08203575547866],
  ["MC", 50.24005190311419],
  ["TV", 83.85849192618224],
  ["TW", 94.35697808535178],
  ["US", 71.27811418685121],
  ["MU", 72.14965397923876],
  ["BZ", 59.05420991926182],
  ["CI", 90.54714532871972],
  ["BM", 67.60813148788928],
  ["CK", 82.2722029988466],
  ["AT", 36.304426182237606],
  ["BO", 62.18533737024221],
  ["LS", 69.98702422145328],
  ["NL", 67.43187716262976],
  ["MW", 91.925461361015],
  ["YE", 35.07893598615917],
  ["UG", 95.04505478662053],
  ["TT", 71.2258506343714],
  ["NZ", 84.18504901960785],
  ["MA", 64.85222029988465],
  ["MV", 68.7741493656286],
  ["LR", 70.63329008073818],
  ["BY", 92.23615916955016],
  ["BN", 80.94579008073818],
  ["AU", 87.8871107266436],
  ["GE", 83.00605536332179],
  ["GR", 78.87723471741637],
  ["IN", 68.91508073817762],
  ["GBENG", 82.61678200692042],
  ["RS", 90.35358996539793],
  ["PL", 83.22267877739331],
  ["SV", 35.10020184544406],
  ["SA", 91.3296568627451],
  ["HK", 60.293396770472896],
  ["KG", 60.644824106113035],
  ["GD", 74.2924596309112],
  ["GQ", 73.12139561707036],
  ["DJ", 84.85618512110726],
  ["KE", 92.72527393310264],
  ["KR", 72.75627162629758],
  ["SC", 68.16861303344868],
  ["ST", 89.00194636678201],
  ["PY", 0],
  ["SB", 85.77890715109572],
  ["IL", 54.303633217993074],
  ["DK", 54.552696078431374],
  ["DO", 64.03979238754324],
  ["RU", 98.03056516724337],
  ["SG", 52.302119377162626],
  ["PK", 86.79750576701268],
  ["GB", 68.2086216839677],
  ["GU", 88.11130334486735],
  ["GW", 84.83996539792388],
  ["JP", 74.02285178777393],
  ["SR", 94.90952998846598],
  ["SE", 68.27169838523645],
  ["SD", 46.00994809688581],
  ["SS", 87.89071510957324],
  ["PH", 85.10272491349481],
  ["RW", 94.02429354094579],
  ["KU", 74.13638985005767],
  ["HN", 69.1107987312572],
  ["GA", 94.9225057670127],
  ["DZ", 81.31668108419838],
  ["DM", 87.80420991926182],
  ["FR", 68.87182814302192],
  ["GL", 78.07309688581316],
  ["ES", 68.52869088811995],
  ["HT", 96.84544405997694],
  ["PE", 68.58924452133795],
  ["QA", 85.40188869665513],
  ["SI", 96.06905997693195],
  ["PR", 83.25872260668974],
  ["PS", 72.26751730103807],
  ["IQ", 37.320862168396765],
  ["HU", 35.56913206459054],
  ["KN", 90.21301903114187],
  ["KY", 83.63862456747405],
  ["FI", 93.33513552479815],
  ["ER", 80.42964244521337],
  ["GM", 54.462226066897344],
  ["EE", 99.42618223760093],
  ["EG", 35.28654844290657],
  ["ID", 50.237528835063436],
  ["IS", 57.7389705882353],
  ["SK", 94.052407727797],
  ["RO", 85.28474625144176],
  ["PG", 68.69845732410612],
  ["KM", 83.417315455594],
  ["IR", 60.62499999999999],
  ["KZ", 94.0170847750865],
  ["IE", 90.65059111880046],
  ["GN", 85.29015282583622],
  ["FJ", 83.57735005767013],
  ["GY", 83.69160899653978],
  ["JM", 89.40527681660899],
  ["KI", 54.54008073817762],
  ["HR", 64.42113610149943],
  ["SO", 97.05161476355248],
  ["PT", 92.52991637831603],
  ["SY", 36.889057093425606],
  ["SN", 84.88574106113033],
  ["KH", 60.145256632064594],
  ["EC", 84.49790945790082],
  ["ET", 87.73536620530565],
  ["DE", 98.44182525951557],
  ["SL", 68.65736735870819],
  ["PW", 96.46265859284891],
  ["SZ", 84.7520184544406],
  ["PA", 48.786043829296425],
  ["SM", 84.68641868512111],
  ["IT", 79.59955305651673],
  ["JO", 73.35423875432527],
  ["GH", 62.427551903114185],
  ["CG", 89.6431660899654],
  ["BT", 90.9234429065744],
  ["AO", 62.68598615916955],
  ["ML", 85.46748846597463],
  ["TN", 61.39381487889274],
  ["LI", 95.98327566320646],
  ["MM", 92.81177912341407],
  ["MZ", 89.54945213379469],
  ["NA", 79.83203575547866],
  ["CF", 76.59205594002306],
  ["BB", 95.8275663206459],
  ["CD", 87.30067762399078],
  ["BW", 91.82093425605537],
  ["AL", 66.94744809688581],
  ["NC", 91.77083333333333],
  ["MX", 80.75475778546712],
  ["TZ", 93.22267877739331],
  ["VE", 97.31617647058823],
  ["MN", 62.54000865051903],
  ["MY", 75.37485582468281],
  ["AM", 65.46532583621683],
  ["CR", 96.06797866205305],
  ["BA", 64.24560265282584],
  ["AZ", 96.83823529411765],
  ["CV", 66.9575403690888],
  ["BR", 91.02040080738178],
  ["CA", 89.15585351787773],
  ["BE", 85.7479094579008],
  ["TH", 82.72094867358707],
  ["WS", 78.82280853517878],
  ["UZ", 64.79779411764706],
  ["Wales", 75.84090253748558],
  ["NG", 90.57778258362168],
  ["NP", 67.65787197231833],
  ["MK", 84.15981833910034],
  ["BD", 93.89201268742792],
  ["BS", 93.11923298731257],
  ["CU", 63.81668108419839],
  ["BF", 97.33131487889274],
  ["NR", 95.95840542099192],
  ["NE", 70.1178633217993],
  ["ZW", 86.40643021914649],
  ["VC", 94.48132929642445],
  ["UY", 73.63826412918108],
  ["TJ", 68.56113033448673],
  ["VU", 65.1441753171857],
  ["ZA", 66.63747116493657],
  ["BG", 98.03849480968859],
]);

export const sbMap = new Map<string, number>([
  ["CY", 89.3356401384083],
  ["AF", 88.18771626297578],
  ["CN", 86.86202422145328],
  ["BJ", 86.79757785467129],
  ["LA", 86.72448096885813],
  ["ME", 80.27076124567473],
  ["OM", 85.42560553633218],
  ["NI", 65.26557093425606],
  ["MR", 86.32871972318338],
  ["TG", 84.50389273356402],
  ["TP", 89.88494809688581],
  ["North Korea", 74.33693771626298],
  ["VN", 86.92301038062283],
  ["ZM", 87.12586505190312],
  ["MD", 68.30709342560553],
  ["CO", 87.99221453287197],
  ["AG", 88.66435986159169],
  ["AE", 89.26989619377163],
  ["CZ", 88.30406574394463],
  ["AR", 90.6310553633218],
  ["BI", 80.87370242214533],
  ["CM", 86.42387543252596],
  ["LB", 86.54022491349481],
  ["LU", 89.56920415224914],
  ["TD", 88.03503460207612],
  ["TR", 86.56790657439446],
  ["Scotland", 61.00475778546712],
  ["UA", 60.813581314878896],
  ["LT", 88.67517301038063],
  ["LC", 92.56487889273356],
  ["MG", 90.10510380622837],
  ["CL", 79.0432525951557],
  ["BH", 87.85726643598616],
  ["AD", 87.02941176470588],
  ["CH", 87.08996539792388],
  ["NO", 85.93987889273357],
  ["MT", 90.17820069204151],
  ["MC", 89.26730103806229],
  ["TV", 88.52335640138408],
  ["TW", 85.98572664359861],
  ["US", 81.02811418685121],
  ["MU", 87.47058823529412],
  ["BZ", 64.43425605536332],
  ["CI", 92.36202422145328],
  ["BM", 81.39100346020761],
  ["CK", 82.0462802768166],
  ["AT", 89.53200692041523],
  ["BO", 88.37153979238754],
  ["LS", 89.9234429065744],
  ["NL", 89.46020761245674],
  ["MW", 87.69723183391004],
  ["YE", 91.50648788927336],
  ["UG", 88.46669550173011],
  ["TT", 87.40181660899654],
  ["NZ", 82.70544982698962],
  ["MA", 87.95285467128028],
  ["MV", 87.15138408304497],
  ["LR", 86.73053633217992],
  ["BY", 84.8317474048443],
  ["BN", 84.67257785467129],
  ["AU", 80.7923875432526],
  ["GE", 84.68079584775087],
  ["GR", 70.0514705882353],
  ["IN", 90.86851211072664],
  ["GBENG", 88.98832179930795],
  ["RS", 82.95242214532873],
  ["PL", 90.07871972318338],
  ["SV", 65.36159169550173],
  ["SA", 83.48096885813149],
  ["HK", 85.77897923875433],
  ["KG", 81.95025951557093],
  ["GD", 78.79368512110727],
  ["GQ", 87.14878892733564],
  ["DJ", 88.0817474048443],
  ["KE", 87.41782006920415],
  ["KR", 88.7962802768166],
  ["SC", 83.31185121107266],
  ["ST", 86.67214532871972],
  ["PY", 85.95588235294119],
  ["SB", 0],
  ["IL", 76.8538062283737],
  ["DK", 88.7962802768166],
  ["DO", 89.56228373702422],
  ["RU", 78.72404844290656],
  ["SG", 87.08217993079585],
  ["PK", 88.18901384083046],
  ["GB", 80.49740484429066],
  ["GU", 82.6310553633218],
  ["GW", 86.10164359861592],
  ["JP", 88.67171280276817],
  ["SR", 87.4325259515571],
  ["SE", 87.2279411764706],
  ["SD", 88.93728373702422],
  ["SS", 78.05666089965399],
  ["PH", 70.3044982698962],
  ["RW", 66.05666089965398],
  ["KU", 91.29195501730104],
  ["HN", 88.90181660899654],
  ["GA", 88.17560553633218],
  ["DZ", 85.07828719723184],
  ["DM", 83.39143598615917],
  ["FR", 89.23356401384083],
  ["GL", 87.62975778546713],
  ["ES", 86.1401384083045],
  ["HT", 87.48053633217992],
  ["PE", 89.16262975778547],
  ["QA", 88.65873702422145],
  ["SI", 78.10813148788928],
  ["PR", 84.17214532871972],
  ["PS", 90.22015570934256],
  ["IQ", 89.71626297577855],
  ["HU", 89.43858131487889],
  ["KN", 88.90960207612457],
  ["KY", 81.6128892733564],
  ["FI", 89.08044982698962],
  ["ER", 76.125],
  ["GM", 64.39532871972318],
  ["EE", 92.84602076124567],
  ["EG", 90.94679930795849],
  ["ID", 89.2590830449827],
  ["IS", 87.34645328719724],
  ["SK", 80.82352941176471],
  ["RO", 87.97750865051903],
  ["PG", 89.20112456747404],
  ["KM", 84.31141868512111],
  ["IR", 82.74697231833909],
  ["KZ", 81.38754325259515],
  ["IE", 88.68079584775086],
  ["GN", 87.88192041522491],
  ["FJ", 81.9567474048443],
  ["GY", 83.84299307958477],
  ["JM", 83.91652249134948],
  ["KI", 80.28589965397924],
  ["HR", 83.62067474048442],
  ["SO", 87.36072664359862],
  ["PT", 84.03243944636678],
  ["SY", 90.51643598615917],
  ["SN", 88.45198961937716],
  ["KH", 84.58780276816609],
  ["EC", 77.84429065743944],
  ["ET", 78.97102076124567],
  ["DE", 90.9506920415225],
  ["SL", 89.24567474048443],
  ["PW", 90.81271626297578],
  ["SZ", 79.21626297577855],
  ["PA", 89.01124567474048],
  ["SM", 86.37413494809688],
  ["IT", 89.08520761245676],
  ["JO", 89.54974048442907],
  ["GH", 89.06704152249135],
  ["CG", 84.21323529411765],
  ["BT", 87.72750865051903],
  ["AO", 90.81012110726644],
  ["ML", 87.53806228373703],
  ["TN", 85.91089965397923],
  ["LI", 87.73183391003461],
  ["MM", 86.17430795847751],
  ["MZ", 85.78676470588236],
  ["NA", 79.71799307958477],
  ["CF", 86.41306228373702],
  ["BB", 88.8719723183391],
  ["CD", 86.53503460207612],
  ["BW", 93.4143598615917],
  ["AL", 82.66392733564014],
  ["NC", 68.24956747404845],
  ["MX", 87.9870242214533],
  ["TZ", 90.74307958477509],
  ["VE", 85.48269896193771],
  ["MN", 85.9083044982699],
  ["MY", 66.57093425605535],
  ["AM", 78.04195501730104],
  ["CR", 88.07309688581316],
  ["BA", 82.49221453287198],
  ["AZ", 86.18295847750865],
  ["CV", 85.70631487889273],
  ["BR", 82.23140138408304],
  ["CA", 85.51730103806229],
  ["BE", 94.78935986159169],
  ["TH", 89.42387543252596],
  ["WS", 87.50648788927336],
  ["UZ", 86.59645328719722],
  ["Wales", 69.090830449827],
  ["NG", 89.12110726643598],
  ["NP", 78.45501730103807],
  ["MK", 76.01557093425606],
  ["BD", 84.76946366782006],
  ["BS", 91.67128027681662],
  ["CU", 85.86159169550173],
  ["BF", 86.969723183391],
  ["NR", 87.42430795847751],
  ["NE", 88.87543252595155],
  ["ZW", 87.45588235294117],
  ["VC", 86.14792387543253],
  ["UY", 78.0553633217993],
  ["TJ", 88.56574394463668],
  ["VU", 90.30752595155708],
  ["ZA", 85.65484429065744],
  ["BG", 89.16089965397924],
]);

export const ilMap = new Map<string, number>([
  ["CY", 42.901286020481066],
  ["AF", 93.72261252679209],
  ["CN", 93.69135508454394],
  ["BJ", 93.70653727077875],
  ["LA", 94.7913193617528],
  ["ME", 96.31459871397952],
  ["OM", 81.76976661109788],
  ["NI", 70.41825434627292],
  ["MR", 94.43587758990236],
  ["TG", 91.2610145272684],
  ["TP", 90.50666825434627],
  ["North Korea", 78.71159799952369],
  ["VN", 95.52036199095024],
  ["ZM", 93.37133841390808],
  ["MD", 83.09002143367469],
  ["CO", 92.48094784472494],
  ["AG", 86.99571326506312],
  ["AE", 77.94564181948083],
  ["CZ", 73.55203619909503],
  ["AR", 71.138068587759],
  ["BI", 75.27834008097166],
  ["CM", 93.75565610859728],
  ["LB", 53.84913074541557],
  ["LU", 69.71243153131698],
  ["TD", 94.54870207192188],
  ["TR", 91.2443438914027],
  ["Scotland", 73.52286258633008],
  ["UA", 95.58525839485593],
  ["LT", 96.32293403191235],
  ["LC", 95.71237199333174],
  ["MG", 74.14622529173613],
  ["CL", 72.30620385806145],
  ["BH", 72.98969992855442],
  ["AD", 92.03947368421052],
  ["CH", 84.86752798285306],
  ["NO", 83.3755060728745],
  ["MT", 65.92313646106216],
  ["MC", 66.1478923553227],
  ["TV", 89.9538580614432],
  ["TW", 92.82150512026674],
  ["US", 72.88908073350798],
  ["MU", 95.41884972612526],
  ["BZ", 61.985591807573236],
  ["CI", 80.76268159085497],
  ["BM", 84.60645391759944],
  ["CK", 86.30001190759705],
  ["AT", 69.62610145272684],
  ["BO", 96.55840676351512],
  ["LS", 49.73148368659205],
  ["NL", 69.64574898785425],
  ["MW", 91.04251012145748],
  ["YE", 70.05090497737557],
  ["UG", 91.68700881162182],
  ["TT", 89.73059061681353],
  ["NZ", 88.03018575851394],
  ["MA", 95.71386044296261],
  ["MV", 96.19849964277208],
  ["LR", 72.99833293641343],
  ["BY", 91.36937366039534],
  ["BN", 72.93581805191712],
  ["AU", 85.81209811859966],
  ["GE", 53.213265063110256],
  ["GR", 77.36395570373898],
  ["IN", 70.40336984996428],
  ["GBENG", 54.573410335794236],
  ["RS", 75.39443915217909],
  ["PL", 66.11544415336985],
  ["SV", 40.19022386282448],
  ["SA", 90.03780662062397],
  ["HK", 92.56876637294594],
  ["KG", 90.99160514408192],
  ["GD", 87.54108120981185],
  ["GQ", 75.84722552988808],
  ["DJ", 77.92212431531317],
  ["KE", 85.66861157418433],
  ["KR", 44.17391045487021],
  ["SC", 77.42260061919505],
  ["ST", 92.91230054774945],
  ["PY", 54.70260776375328],
  ["SB", 77.0969278399619],
  ["IL", 0],
  ["DK", 79.51268159085497],
  ["DO", 76.33216241962371],
  ["RU", 78.44873779471303],
  ["SG", 62.60984758275781],
  ["PK", 76.39884496308646],
  ["GB", 67.64944034293879],
  ["GU", 95.9237318409145],
  ["GW", 93.22487497023101],
  ["JP", 45.09823767563705],
  ["SR", 87.06507501786139],
  ["SE", 93.84466539652298],
  ["SD", 77.95189330793046],
  ["SS", 83.28292450583473],
  ["PH", 66.68552036199095],
  ["RW", 96.00410812098119],
  ["KU", 76.69326030007144],
  ["HN", 70.6019290307216],
  ["GA", 95.81001428911645],
  ["DZ", 66.5917480352465],
  ["DM", 87.24904739223625],
  ["FR", 80.21106215765658],
  ["GL", 64.83269826149083],
  ["ES", 92.82656584901167],
  ["HT", 81.6983210288164],
  ["PE", 80.98713979518934],
  ["QA", 73.18081686115741],
  ["SI", 78.6115741843296],
  ["PR", 74.22600619195046],
  ["PS", 77.87925696594428],
  ["IQ", 71.44201000238152],
  ["HU", 69.84609430816862],
  ["KN", 85.12770897832817],
  ["KY", 87.15587044534414],
  ["FI", 63.72320790664444],
  ["ER", 88.19123600857347],
  ["GM", 86.535782329126],
  ["EE", 79.50464396284829],
  ["EG", 70.7513693736604],
  ["ID", 66.1478923553227],
  ["IS", 83.42313646106216],
  ["SK", 78.15491783758037],
  ["RO", 94.73684210526315],
  ["PG", 90.23249583234103],
  ["KM", 76.09788044772566],
  ["IR", 64.93391283638962],
  ["KZ", 96.2324362943558],
  ["IE", 80.80912121933794],
  ["GN", 94.33615146463443],
  ["FJ", 87.98434150988331],
  ["GY", 83.9982733984282],
  ["JM", 87.22850678733032],
  ["KI", 80.278935460824],
  ["HR", 67.4297451774232],
  ["SO", 96.73404381995712],
  ["PT", 94.02923315075017],
  ["SY", 71.86115741843297],
  ["SN", 94.67789949988092],
  ["KH", 63.31090735889497],
  ["EC", 90.54953560371517],
  ["ET", 90.91867111216956],
  ["DE", 95.56025244105739],
  ["SL", 69.91069302214812],
  ["PW", 94.9791617051679],
  ["SZ", 92.09067635151227],
  ["PA", 65.79453441295546],
  ["SM", 67.85960943081686],
  ["IT", 80.89753512741129],
  ["JO", 80.11669445105977],
  ["GH", 96.107108835437],
  ["CG", 91.14699928554417],
  ["BT", 88.79643962848297],
  ["AO", 93.09121219337938],
  ["ML", 94.53322219576089],
  ["TN", 90.8314479638009],
  ["LI", 93.72052869730888],
  ["MM", 88.34246249106931],
  ["MZ", 88.37461300309597],
  ["NA", 87.43480590616814],
  ["CF", 75.24410573946177],
  ["BB", 92.79292688735413],
  ["CD", 91.34317694689211],
  ["BW", 90.85496546796857],
  ["AL", 93.19331983805668],
  ["NC", 77.54852345796618],
  ["MX", 81.81799237913789],
  ["TZ", 89.58918790188139],
  ["VE", 91.77333889021196],
  ["MN", 91.6048463919981],
  ["MY", 64.2233269826149],
  ["AM", 95.37389854727316],
  ["CR", 79.08668730650155],
  ["BA", 88.6401524172422],
  ["AZ", 95.95588235294117],
  ["CV", 78.78244820195285],
  ["BR", 89.75083353179329],
  ["CA", 74.02327935222672],
  ["BE", 94.3310907358895],
  ["TH", 80.73946177661348],
  ["WS", 94.28852107644677],
  ["UZ", 69.53441295546558],
  ["Wales", 65.04108120981186],
  ["NG", 80.98713979518934],
  ["NP", 61.2884020004763],
  ["MK", 83.6842105263158],
  ["BD", 93.22725648964038],
  ["BS", 94.92944748749701],
  ["CU", 73.87026673017385],
  ["BF", 95.16849249821387],
  ["NR", 94.23076923076923],
  ["NE", 69.62282686353893],
  ["ZW", 83.70504882114788],
  ["VC", 91.25386996904025],
  ["UY", 32.0924624910693],
  ["TJ", 60.6239580852584],
  ["VU", 88.91611097880447],
  ["ZA", 85.43224577280306],
  ["BG", 78.89199809478448],
]);

export const dkMap = new Map<string, number>([
  ["CY", 79.8009708737864],
  ["AF", 93.09137635636779],
  ["CN", 94.88092518560822],
  ["BJ", 97.54968589377498],
  ["LA", 49.32609937178755],
  ["ME", 92.34408909194745],
  ["OM", 64.28897772701313],
  ["NI", 84.36379211878926],
  ["MR", 96.46430611079383],
  ["TG", 85.8432324386065],
  ["TP", 38.22529982866933],
  ["North Korea", 94.30154197601371],
  ["VN", 25.35750999428898],
  ["ZM", 94.92061679040549],
  ["MD", 66.82010279840091],
  ["CO", 75.58537978298115],
  ["AG", 56.00628212450028],
  ["AE", 65.37921187892633],
  ["CZ", 60.95402627070246],
  ["AR", 84.96230725299829],
  ["BI", 64.14191890348371],
  ["CM", 78.36550542547116],
  ["LB", 81.9965733866362],
  ["LU", 83.57167332952599],
  ["TD", 69.51713306681896],
  ["TR", 24.39891490576813],
  ["Scotland", 85.31324957167334],
  ["UA", 97.68617932609938],
  ["LT", 69.15305539691605],
  ["LC", 96.94745859508852],
  ["MG", 94.11564820102798],
  ["CL", 52.579383209594525],
  ["BH", 43.15962307252998],
  ["AD", 67.77127355796686],
  ["CH", 87.44031981724729],
  ["NO", 93.37064534551685],
  ["MT", 40.259851513420905],
  ["MC", 50.16019417475728],
  ["TV", 82.11193603655055],
  ["TW", 95.69474585950886],
  ["US", 52.67161621930325],
  ["MU", 97.51941747572815],
  ["BZ", 69.99571673329525],
  ["CI", 86.19446030839521],
  ["BM", 33.97715591090805],
  ["CK", 83.39663049685893],
  ["AT", 83.59051970302684],
  ["BO", 68.23272415762422],
  ["LS", 84.10308395202742],
  ["NL", 54.40548258138207],
  ["MW", 77.60993717875499],
  ["YE", 54.187321530553966],
  ["UG", 94.14848657909765],
  ["TT", 43.53797829811536],
  ["NZ", 84.59794403198173],
  ["MA", 23.66419189034837],
  ["MV", 41.027412906910335],
  ["LR", 48.95145631067962],
  ["BY", 92.49914334665905],
  ["BN", 84.97315819531697],
  ["AU", 87.78212450028555],
  ["GE", 85.0682467161622],
  ["GR", 92.31896059394631],
  ["IN", 84.31182181610508],
  ["GBENG", 76.86693318103941],
  ["RS", 89.6741861793261],
  ["PL", 50.22015990862364],
  ["SV", 84.52912621359224],
  ["SA", 92.37121644774415],
  ["HK", 91.92632781267847],
  ["KG", 88.88663620788121],
  ["GD", 59.90691033695031],
  ["GQ", 58.35950885208452],
  ["DJ", 89.59908623643632],
  ["KE", 93.44802969731582],
  ["KR", 78.39463163906339],
  ["SC", 64.92975442604227],
  ["ST", 85.63934894346087],
  ["PY", 54.75528269560252],
  ["SB", 88.76898914905769],
  ["IL", 79.79954311821817],
  ["DK", 0],
  ["DO", 56.197886921759],
  ["RU", 66.66333523700743],
  ["SG", 84.27412906910337],
  ["PK", 92.40091376356368],
  ["GB", 69.06796116504854],
  ["GU", 82.98001142204454],
  ["GW", 71.31067961165049],
  ["JP", 76.03740719588806],
  ["SR", 75.82067390062821],
  ["SE", 95.18560822387208],
  ["SD", 60.01227869788692],
  ["SS", 84.92718446601941],
  ["PH", 63.32010279840091],
  ["RW", 93.17618503712164],
  ["KU", 60.76927470017133],
  ["HN", 84.01256424900058],
  ["GA", 96.73757852655626],
  ["DZ", 90.88977727013136],
  ["DM", 90.10536836093661],
  ["FR", 86.35836664762992],
  ["GL", 50.22844089091948],
  ["ES", 50.50742432895488],
  ["HT", 60.03312392918332],
  ["PE", 30.784408909194745],
  ["QA", 93.88577955454026],
  ["SI", 92.87549971444888],
  ["PR", 87.81096516276413],
  ["PS", 74.8049685893775],
  ["IQ", 56.247572815533985],
  ["HU", 54.30725299828669],
  ["KN", 71.46601941747572],
  ["KY", 83.47829811536265],
  ["FI", 98.9825813820674],
  ["ER", 84.34523129640206],
  ["GM", 68.66276413478013],
  ["EE", 96.3303826384923],
  ["EG", 55.341233580810965],
  ["ID", 50.16019417475728],
  ["IS", 93.74557395773844],
  ["SK", 92.04368932038834],
  ["RO", 69.70759565962307],
  ["PG", 64.59137635636779],
  ["KM", 77.3929183323815],
  ["IR", 76.45659623072531],
  ["KZ", 90.36122215876642],
  ["IE", 86.2384351798972],
  ["GN", 70.27984009137636],
  ["FJ", 83.90062821245003],
  ["GY", 77.55796687607082],
  ["JM", 93.03455168475156],
  ["KI", 51.047401484865794],
  ["HR", 80.26299257567105],
  ["SO", 95.55711022272988],
  ["PT", 93.29268989149058],
  ["SY", 55.81210736721873],
  ["SN", 69.2806967447173],
  ["KH", 68.241861793261],
  ["EC", 91.72415762421473],
  ["ET", 64.27355796687607],
  ["DE", 97.68703597944032],
  ["SL", 83.63735008566533],
  ["PW", 97.75042832667047],
  ["SZ", 89.44802969731582],
  ["PA", 64.77298686464877],
  ["SM", 86.95973729297545],
  ["IT", 58.77641347801257],
  ["JO", 72.434037692747],
  ["GH", 66.9965733866362],
  ["CG", 68.14905768132495],
  ["BT", 90.79697315819531],
  ["AO", 61.796973158195314],
  ["ML", 69.30868075385494],
  ["TN", 90.86065105653913],
  ["LI", 59.30810965162764],
  ["MM", 91.20474014848658],
  ["MZ", 82.45288406624786],
  ["NA", 70.66219303255282],
  ["CF", 77.46287835522558],
  ["BB", 97.00485436893203],
  ["CD", 80.73415191319246],
  ["BW", 98.92033123929184],
  ["AL", 30.5422615648201],
  ["NC", 93.04483152484295],
  ["MX", 58.65419760137065],
  ["TZ", 93.8495145631068],
  ["VE", 66.68189605939463],
  ["MN", 47.749857224443176],
  ["MY", 61.7187321530554],
  ["AM", 97.90862364363221],
  ["CR", 78.99371787549971],
  ["BA", 91.05396916047972],
  ["AZ", 82.8066818960594],
  ["CV", 83.13335237007425],
  ["BR", 90.49229011993147],
  ["CA", 86.80525414049114],
  ["BE", 97.7864077669903],
  ["TH", 95.7587093089663],
  ["WS", 37.62621359223301],
  ["UZ", 77.76813249571674],
  ["Wales", 77.3263849229012],
  ["NG", 86.48429468874929],
  ["NP", 63.10565391205025],
  ["MK", 82.5682467161622],
  ["BD", 95.21130782410052],
  ["BS", 95.22215876641918],
  ["CU", 77.46116504854369],
  ["BF", 97.97001713306682],
  ["NR", 97.79897201599086],
  ["NE", 86.33380925185608],
  ["ZW", 90.14334665905197],
  ["VC", 94.63563677898344],
  ["UY", 80.5225585379783],
  ["TJ", 81.934037692747],
  ["VU", 68.17390062821245],
  ["ZA", 93.1093660765277],
  ["BG", 95.07966876070817],
]);

export const doMap = new Map<string, number>([
  ["CY", 72.39757785467128],
  ["AF", 93.16435986159169],
  ["CN", 61.80934256055364],
  ["BJ", 94.17647058823529],
  ["LA", 59.11799307958477],
  ["ME", 71.17439446366782],
  ["OM", 76.67508650519032],
  ["NI", 77.80103806228374],
  ["MR", 95.21730103806229],
  ["TG", 94.77854671280276],
  ["TP", 67.70795847750864],
  ["North Korea", 91.7477508650519],
  ["VN", 62.52629757785467],
  ["ZM", 93.07439446366782],
  ["MD", 80.73460207612456],
  ["CO", 83.75640138408305],
  ["AG", 69.84948096885813],
  ["AE", 72.99965397923876],
  ["CZ", 70.49584775086505],
  ["AR", 78.44221453287197],
  ["BI", 75.00519031141869],
  ["CM", 89.77750865051904],
  ["LB", 58.76124567474048],
  ["LU", 64.22214532871973],
  ["TD", 68.63598615916955],
  ["TR", 63.21038062283737],
  ["Scotland", 86.36055363321799],
  ["UA", 96.34913494809688],
  ["LT", 83.04429065743945],
  ["LC", 95.37231833910035],
  ["MG", 91.26401384083044],
  ["CL", 69.09100346020762],
  ["BH", 69.71314878892734],
  ["AD", 81.53114186851211],
  ["CH", 81.56505190311418],
  ["NO", 82.96678200692041],
  ["MT", 66.50519031141869],
  ["MC", 66.9159169550173],
  ["TV", 87.74740484429066],
  ["TW", 95.28512110726643],
  ["US", 59.15121107266435],
  ["MU", 80.39100346020761],
  ["BZ", 76.9325259515571],
  ["CI", 82.56366782006921],
  ["BM", 56.71038062283738],
  ["CK", 73.7484429065744],
  ["AT", 50.32249134948097],
  ["BO", 80.32491349480969],
  ["LS", 78.58200692041522],
  ["NL", 77.35121107266436],
  ["MW", 87.63737024221453],
  ["YE", 63.536332179930795],
  ["UG", 89.89792387543253],
  ["TT", 58.61937716262976],
  ["NZ", 73.0121107266436],
  ["MA", 64.75397923875433],
  ["MV", 70.41557093425605],
  ["LR", 59.8955017301038],
  ["BY", 91.51903114186851],
  ["BN", 83.0145328719723],
  ["AU", 88.32076124567475],
  ["GE", 94.54948096885813],
  ["GR", 84.41176470588235],
  ["IN", 78.84117647058824],
  ["GBENG", 96.11626297577854],
  ["RS", 83.13252595155708],
  ["PL", 66.819723183391],
  ["SV", 78.24359861591695],
  ["SA", 91.40484429065744],
  ["HK", 59.13702422145328],
  ["KG", 59.49515570934256],
  ["GD", 74.36401384083044],
  ["GQ", 70.13252595155708],
  ["DJ", 89.35916955017301],
  ["KE", 94.84532871972318],
  ["KR", 73.90207612456747],
  ["SC", 64.08685121107266],
  ["ST", 88.7204152249135],
  ["PY", 63.968512110726635],
  ["SB", 88.86124567474049],
  ["IL", 75.88927335640139],
  ["DK", 55.63598615916955],
  ["DO", 0],
  ["RU", 79.00899653979239],
  ["SG", 63.87716262975779],
  ["PK", 89.01003460207613],
  ["GB", 69.0273356401384],
  ["GU", 63.73806228373703],
  ["GW", 81.84083044982698],
  ["JP", 76.86470588235295],
  ["SR", 87.03529411764706],
  ["SE", 95.01245674740485],
  ["SD", 67.32110726643599],
  ["SS", 88.9757785467128],
  ["PH", 80.04567474048443],
  ["RW", 92.85051903114187],
  ["KU", 71.4204152249135],
  ["HN", 78.27128027681661],
  ["GA", 94.42110726643598],
  ["DZ", 83.20553633217993],
  ["DM", 86.92837370242215],
  ["FR", 69.92491349480969],
  ["GL", 70.39411764705882],
  ["ES", 71.46401384083046],
  ["HT", 79.89273356401384],
  ["PE", 56.60346020761246],
  ["QA", 89.47508650519032],
  ["SI", 77.46124567474048],
  ["PR", 84.78927335640138],
  ["PS", 76.52076124567473],
  ["IQ", 65.84671280276817],
  ["HU", 63.53910034602076],
  ["KN", 90.85363321799308],
  ["KY", 75.29273356401383],
  ["FI", 82.75916955017301],
  ["ER", 79.00968858131488],
  ["GM", 82.59965397923875],
  ["EE", 94.00346020761245],
  ["EG", 64.34913494809689],
  ["ID", 66.92560553633217],
  ["IS", 84.92387543252595],
  ["SK", 90.26020761245674],
  ["RO", 68.95536332179931],
  ["PG", 79.69861591695502],
  ["KM", 84.57889273356402],
  ["IR", 72.16228373702423],
  ["KZ", 94.06228373702422],
  ["IE", 82.56678200692042],
  ["GN", 81.56505190311418],
  ["FJ", 86.86470588235295],
  ["GY", 81.26782006920415],
  ["JM", 90.35570934256056],
  ["KI", 65.60242214532872],
  ["HR", 76.9515570934256],
  ["SO", 96.11314878892733],
  ["PT", 92.19446366782007],
  ["SY", 65.33979238754326],
  ["SN", 81.0961937716263],
  ["KH", 82.75017301038062],
  ["EC", 82.06262975778546],
  ["ET", 77.63114186851212],
  ["DE", 95.18269896193772],
  ["SL", 77.78166089965399],
  ["PW", 95.29653979238755],
  ["SZ", 79.67024221453288],
  ["PA", 65.43979238754325],
  ["SM", 85.47681660899654],
  ["IT", 69.5249134948097],
  ["JO", 75.960553633218],
  ["GH", 80.5204152249135],
  ["CG", 87.57820069204152],
  ["BT", 91.26159169550174],
  ["AO", 79.20934256055364],
  ["ML", 81.62006920415224],
  ["TN", 59.70934256055364],
  ["LI", 67.66574394463667],
  ["MM", 76.3287197231834],
  ["MZ", 87.27128027681661],
  ["NA", 59.37612456747405],
  ["CF", 86.62837370242215],
  ["BB", 68.84740484429066],
  ["CD", 80.88858131487889],
  ["BW", 94.37474048442907],
  ["AL", 64.2598615916955],
  ["NC", 92.0446366782007],
  ["MX", 71.95051903114187],
  ["TZ", 93.43391003460208],
  ["VE", 77.41176470588236],
  ["MN", 73.09307958477508],
  ["MY", 68.8477508650519],
  ["AM", 83.2114186851211],
  ["CR", 73.72283737024222],
  ["BA", 89.66055363321799],
  ["AZ", 91.48062283737023],
  ["CV", 85.639446366782],
  ["BR", 90.00553633217993],
  ["CA", 86.77681660899654],
  ["BE", 82.03044982698961],
  ["TH", 80.20726643598616],
  ["WS", 48.95051903114187],
  ["UZ", 75.68754325259516],
  ["Wales", 77.85051903114187],
  ["NG", 82.44567474048444],
  ["NP", 71.91903114186852],
  ["MK", 83.41487889273357],
  ["BD", 95.41868512110727],
  ["BS", 93.19342560553633],
  ["CU", 81.8128027681661],
  ["BF", 97.3636678200692],
  ["NR", 64.22525951557093],
  ["NE", 81.03875432525952],
  ["ZW", 84.8560553633218],
  ["VC", 93.34152249134948],
  ["UY", 79.41349480968857],
  ["TJ", 76.2795847750865],
  ["VU", 73.79757785467127],
  ["ZA", 91.578892733564],
  ["BG", 78.75778546712803],
]);

export const ruMap = new Map<string, number>([
  ["CY", 66.8409946760161],
  ["AF", 91.3193091806259],
  ["CN", 63.78846902999611],
  ["BJ", 77.6347227632775],
  ["LA", 72.19874042332165],
  ["ME", 91.82930788209323],
  ["OM", 62.40650564861706],
  ["NI", 99.42442539929878],
  ["MR", 95.76483573561875],
  ["TG", 94.90715491494612],
  ["TP", 68.22587975587587],
  ["North Korea", 81.04369562394494],
  ["VN", 64.45072068562524],
  ["ZM", 93.17815868069081],
  ["MD", 82.7642513959226],
  ["CO", 55.84988962472406],
  ["AG", 66.21282950266199],
  ["AE", 91.02389300090897],
  ["CZ", 41.37384755226594],
  ["AR", 99.51824438384625],
  ["BI", 70.76743280093496],
  ["CM", 86.02876249837684],
  ["LB", 63.21938709258538],
  ["LU", 99.55849889624724],
  ["TD", 87.50032463316452],
  ["TR", 94.8289183222958],
  ["Scotland", 80.44961693286587],
  ["UA", 99.55849889624724],
  ["LT", 65.56291390728477],
  ["LC", 97.79963641085573],
  ["MG", 87.75159070250616],
  ["CL", 37.12375016231658],
  ["BH", 65.10550577847033],
  ["AD", 85.0915465523958],
  ["CH", 94.23289183222958],
  ["NO", 66.25081158291131],
  ["MT", 67.30976496558888],
  ["MC", 99.77924944812362],
  ["TV", 86.71990650564861],
  ["TW", 93.61641345279834],
  ["US", 83.19309180625892],
  ["MU", 98.89624724061811],
  ["BZ", 65.17757434099467],
  ["CI", 88.2047786001818],
  ["BM", 54.43091806258927],
  ["CK", 85.43143747565252],
  ["AT", 66.66666666666666],
  ["BO", 99.55849889624724],
  ["LS", 95.60544085183741],
  ["NL", 99.55849889624724],
  ["MW", 98.85729126087521],
  ["YE", 99.77924944812362],
  ["UG", 88.44143617711985],
  ["TT", 71.0699909102714],
  ["NZ", 86.81307622386703],
  ["MA", 62.539605246071936],
  ["MV", 69.62180236332944],
  ["LR", 85.64309829892221],
  ["BY", 93.2979483184002],
  ["BN", 79.80619400077912],
  ["AU", 87.53246331645241],
  ["GE", 68.63946240747954],
  ["GR", 86.4757823659265],
  ["IN", 97.722373717699],
  ["GBENG", 66.05570705103233],
  ["RS", 94.29100116867939],
  ["PL", 32.89183222958057],
  ["SV", 98.60440202571095],
  ["SA", 91.75074665627841],
  ["HK", 63.26353720296065],
  ["KG", 61.05116218672899],
  ["GD", 74.8733930658356],
  ["GQ", 67.43896896506946],
  ["DJ", 90.68627450980392],
  ["KE", 89.42669783145047],
  ["KR", 67.94961693286587],
  ["SC", 83.38559927282171],
  ["ST", 91.84553954031944],
  ["PY", 98.12297104272173],
  ["SB", 78.63037267887287],
  ["IL", 78.34729255940786],
  ["DK", 65.89728606674457],
  ["DO", 78.8962472406181],
  ["RU", 0],
  ["SG", 96.5215556421244],
  ["PK", 88.29794831840022],
  ["GB", 77.31658226204388],
  ["GU", 87.76392676275808],
  ["GW", 83.63394364368264],
  ["JP", 67.95935592780158],
  ["SR", 87.1104402025711],
  ["SE", 87.8382677574341],
  ["SD", 98.0687573042462],
  ["SS", 84.11018049603948],
  ["PH", 55.129853265809636],
  ["RW", 99.32573691728346],
  ["KU", 70.54798078171666],
  ["HN", 98.84657836644591],
  ["GA", 99.44779898714454],
  ["DZ", 78.99655888845605],
  ["DM", 88.96214777301648],
  ["FR", 66.34885079859758],
  ["GL", 41.39365017530191],
  ["ES", 93.91864692897026],
  ["HT", 49.729255940786906],
  ["PE", 66.32190624594209],
  ["QA", 86.54200753148942],
  ["SI", 33.3167770419426],
  ["PR", 83.10641475133099],
  ["PS", 94.13809894818854],
  ["IQ", 98.15153876119984],
  ["HU", 99.55849889624724],
  ["KN", 72.10329827295156],
  ["KY", 85.72490585638228],
  ["FI", 71.20828463835865],
  ["ER", 86.69620828463836],
  ["GM", 78.36644591611478],
  ["EE", 97.13024282560706],
  ["EG", 99.77924944812362],
  ["ID", 99.77924944812362],
  ["IS", 83.94429294896767],
  ["SK", 8.32359433839761],
  ["RO", 87.11563433320349],
  ["PG", 87.68114530580445],
  ["KM", 80.89176730294767],
  ["IR", 91.67121153097001],
  ["KZ", 95.72003635891443],
  ["IE", 88.25671990650565],
  ["GN", 87.26496558888456],
  ["FJ", 88.38365147383456],
  ["GY", 85.70120763537203],
  ["JM", 91.0135047396442],
  ["KI", 86.29333852746397],
  ["HR", 96.5579145565511],
  ["SO", 98.97188676795221],
  ["PT", 92.02668484612389],
  ["SY", 98.54272172445137],
  ["SN", 86.24983768341774],
  ["KH", 94.54324113751461],
  ["EC", 55.22464614985066],
  ["ET", 59.82469809115699],
  ["DE", 98.45474613686534],
  ["SL", 99.33774834437085],
  ["PW", 97.50357096480975],
  ["SZ", 87.12050383067134],
  ["PA", 74.67341903648877],
  ["SM", 63.996883521620575],
  ["IT", 77.06174522789249],
  ["JO", 91.10894689001428],
  ["GH", 97.82106219971432],
  ["CG", 76.8854694195559],
  ["BT", 69.8652772367225],
  ["AO", 93.52584079989612],
  ["ML", 87.43215166861447],
  ["TN", 94.24912349045579],
  ["LI", 62.94669523438514],
  ["MM", 65.75217504220231],
  ["MZ", 88.03012595766783],
  ["NA", 81.46961433580054],
  ["CF", 85.56908193741073],
  ["BB", 94.40592130892091],
  ["CD", 88.6537462667186],
  ["BW", 99.33774834437085],
  ["AL", 57.30262303596936],
  ["NC", 94.45266848461239],
  ["MX", 75.21620568757305],
  ["TZ", 93.56609531229711],
  ["VE", 60.59115699259836],
  ["MN", 65.77327619789638],
  ["MY", 65.21068692377612],
  ["AM", 66.44591611479028],
  ["CR", 80.48143098298922],
  ["BA", 70.60544085183741],
  ["AZ", 96.03687832748994],
  ["CV", 80.72425659005324],
  ["BR", 89.99318270354499],
  ["CA", 80.88722243864433],
  ["BE", 87.43280093494351],
  ["TH", 66.44591611479028],
  ["WS", 63.87254901960784],
  ["UZ", 96.7426308271653],
  ["Wales", 51.589079340345414],
  ["NG", 88.08596286196598],
  ["NP", 51.630632385404496],
  ["MK", 82.9139072847682],
  ["BD", 95.10355797948318],
  ["BS", 97.63861836125179],
  ["CU", 69.32281521880276],
  ["BF", 96.6699129983119],
  ["NR", 93.72776262823011],
  ["NE", 99.42799636410857],
  ["ZW", 86.36865342163355],
  ["VC", 95.65868069081938],
  ["UY", 56.61829632515257],
  ["TJ", 95.07791195948579],
  ["VU", 88.5391507596416],
  ["ZA", 92.56362810024672],
  ["BG", 32.671081677704194],
]);

export const sgMap = new Map<string, number>([
  ["CY", 49.24879885729126],
  ["AF", 92.0270094792884],
  ["CN", 93.6819893520322],
  ["BJ", 95.49798727437995],
  ["LA", 72.27437995065576],
  ["ME", 88.76931567328919],
  ["OM", 78.5391507596416],
  ["NI", 79.9340994676016],
  ["MR", 92.60453187897676],
  ["TG", 84.83995584988962],
  ["TP", 59.43838462537333],
  ["North Korea", 67.22925594078691],
  ["VN", 55.36910790806389],
  ["ZM", 91.50824568237891],
  ["MD", 91.19302687962603],
  ["CO", 97.68991040124659],
  ["AG", 79.84060511621868],
  ["AE", 76.54395533047656],
  ["CZ", 96.24074795481107],
  ["AR", 79.85748604077394],
  ["BI", 64.35560316841969],
  ["CM", 79.1494611089469],
  ["LB", 51.73613816387482],
  ["LU", 51.25211011556941],
  ["TD", 98.05609661082975],
  ["TR", 91.73256719906504],
  ["Scotland", 72.78892351642644],
  ["UA", 92.82495779768861],
  ["LT", 97.77139332554214],
  ["LC", 95.91903648876769],
  ["MG", 47.6967276977016],
  ["CL", 95.85475912219192],
  ["BH", 47.85385014933126],
  ["AD", 79.43741072587976],
  ["CH", 84.92760680431113],
  ["NO", 61.58907934034541],
  ["MT", 47.7129593559278],
  ["MC", 4.825671990650565],
  ["TV", 85.19185820023374],
  ["TW", 98.13757953512531],
  ["US", 66.93838462537333],
  ["MU", 70.98883261914037],
  ["BZ", 72.3341124529282],
  ["CI", 79.09719516945852],
  ["BM", 69.01863394364368],
  ["CK", 87.26074535774575],
  ["AT", 51.42611349175432],
  ["BO", 64.84125438254772],
  ["LS", 80.55317491234905],
  ["NL", 79.5201921828334],
  ["MW", 79.91364757823659],
  ["YE", 52.059148162576285],
  ["UG", 93.68978054798079],
  ["TT", 62.32112712634723],
  ["NZ", 89.64842228282042],
  ["MA", 94.05207115958967],
  ["MV", 65.86482275029218],
  ["LR", 75.10453187897676],
  ["BY", 90.62978833917673],
  ["BN", 87.37826256330347],
  ["AU", 91.28587196467991],
  ["GE", 61.39429944163096],
  ["GR", 72.82041293338527],
  ["IN", 82.7006232956759],
  ["GBENG", 49.26308271653032],
  ["RS", 57.62173743669653],
  ["PL", 96.98902739903909],
  ["SV", 80.67945721334891],
  ["SA", 88.73977405531748],
  ["HK", 89.69484482534736],
  ["KG", 53.361576418646926],
  ["GD", 73.34534476042073],
  ["GQ", 82.24808466432931],
  ["DJ", 80.92812621737437],
  ["KE", 91.22451629658485],
  ["KR", 55.95572003635891],
  ["SC", 56.90657057524997],
  ["ST", 87.72854174782496],
  ["PY", 52.41949097519802],
  ["SB", 86.75074665627841],
  ["IL", 62.80807687313336],
  ["DK", 84.38027528892351],
  ["DO", 64.40592130892092],
  ["RU", 96.5215556421244],
  ["SG", 0],
  ["PK", 78.06518633943644],
  ["GB", 64.7951564731853],
  ["GU", 90.69731203739774],
  ["GW", 87.34255291520581],
  ["JP", 55.462277626282294],
  ["SR", 84.32833398259966],
  ["SE", 92.78080768731334],
  ["SD", 58.05707051032334],
  ["SS", 83.87125048694975],
  ["PH", 84.41695883651474],
  ["RW", 87.90222049084534],
  ["KU", 84.68543046357617],
  ["HN", 79.6523178807947],
  ["GA", 92.39968835216206],
  ["DZ", 68.19309180625893],
  ["DM", 83.21062199714324],
  ["FR", 63.80307752239969],
  ["GL", 75.26619919490976],
  ["ES", 90.77002986625114],
  ["HT", 97.93143747565252],
  ["PE", 52.94344890273991],
  ["QA", 79.73217763926763],
  ["SI", 95.11978963770939],
  ["PR", 77.58310609011816],
  ["PS", 81.03200883002208],
  ["IQ", 53.35475912219192],
  ["HU", 52.10979093624205],
  ["KN", 85.29411764705883],
  ["KY", 85.33080119465004],
  ["FI", 65.82164653941047],
  ["ER", 86.95461628359953],
  ["GM", 63.77483443708609],
  ["EE", 63.77775613556681],
  ["EG", 52.46266718607973],
  ["ID", 3.5011686793922867],
  ["IS", 80.67426308271654],
  ["SK", 91.45630437605506],
  ["RO", 79.05044799376705],
  ["PG", 67.32664589014414],
  ["KM", 93.18367744448773],
  ["IR", 74.11082976236852],
  ["KZ", 89.03097000389559],
  ["IE", 79.31827035449942],
  ["GN", 84.81788079470198],
  ["FJ", 86.09888326191404],
  ["GY", 81.00051941306324],
  ["JM", 90.53045059083236],
  ["KI", 36.458252175042205],
  ["HR", 76.08817036748474],
  ["SO", 92.97104272172444],
  ["PT", 90.00714192961952],
  ["SY", 51.98902739903909],
  ["SN", 77.48798857291261],
  ["KH", 87.75061680301259],
  ["EC", 93.66640696013505],
  ["ET", 87.32307492533438],
  ["DE", 97.85807038047007],
  ["SL", 79.5701856901701],
  ["PW", 92.63764446175821],
  ["SZ", 87.16205687573041],
  ["PA", 47.823659265030514],
  ["SM", 92.74996753668356],
  ["IT", 63.26353720296065],
  ["JO", 80.04285157771717],
  ["GH", 65.22756784833138],
  ["CG", 83.55862874951305],
  ["BT", 83.28333982599662],
  ["AO", 52.998636540709],
  ["ML", 78.41871185560318],
  ["TN", 89.21958187248408],
  ["LI", 96.05343461888066],
  ["MM", 90.42624334502013],
  ["MZ", 85.57330216854953],
  ["NA", 75.55187637969095],
  ["CF", 87.5012985326581],
  ["BB", 97.19354629268926],
  ["CD", 82.07245812232178],
  ["BW", 92.3354109855863],
  ["AL", 61.028762498376835],
  ["NC", 82.76100506427737],
  ["MX", 63.43559278015842],
  ["TZ", 89.3114530580444],
  ["VE", 96.99129983119076],
  ["MN", 93.01162186728996],
  ["MY", 56.79587066614725],
  ["AM", 95.47234125438254],
  ["CR", 66.45240877808078],
  ["BA", 91.81340085703155],
  ["AZ", 92.70451889365017],
  ["CV", 77.49513050253213],
  ["BR", 89.49714322815218],
  ["CA", 76.24626671860797],
  ["BE", 81.8559278015842],
  ["TH", 65.8881963381379],
  ["WS", 70.78333982599662],
  ["UZ", 77.49675366835476],
  ["Wales", 87.56590053239839],
  ["NG", 79.43513829372809],
  ["NP", 60.62816517335411],
  ["MK", 80.32625633034671],
  ["BD", 85.19705233086611],
  ["BS", 94.26503051551747],
  ["CU", 73.53655369432542],
  ["BF", 53.40670042851578],
  ["NR", 90.7197117257499],
  ["NE", 81.59200103882613],
  ["ZW", 87.95124009868847],
  ["VC", 92.6847162706142],
  ["UY", 68.77126347227632],
  ["TJ", 75.00292169848072],
  ["VU", 64.23743669653291],
  ["ZA", 68.94526684846124],
  ["BG", 96.46279703934553],
]);

export const pkMap = new Map<string, number>([
  ["CY", 69.57797688611869],
  ["AF", 89.28385923905986],
  ["CN", 94.15725230489547],
  ["BJ", 96.45987534086483],
  ["LA", 92.85352551616674],
  ["ME", 91.13102194520192],
  ["OM", 94.2871055707051],
  ["NI", 86.9218283339826],
  ["MR", 93.5066874431892],
  ["TG", 92.65030515517465],
  ["TP", 91.26671860797299],
  ["North Korea", 92.54999350733671],
  ["VN", 94.11277756135567],
  ["ZM", 92.29158550837553],
  ["MD", 94.13842358135307],
  ["CO", 95.56616023893001],
  ["AG", 88.15478509284509],
  ["AE", 94.86982210102583],
  ["CZ", 92.06791325801844],
  ["AR", 89.41468640436308],
  ["BI", 84.44942215296714],
  ["CM", 95.71256979613038],
  ["LB", 83.25379820802493],
  ["LU", 87.70192182833398],
  ["TD", 95.98493702116609],
  ["TR", 92.95026619919491],
  ["Scotland", 79.86949746786132],
  ["UA", 95.7304246201792],
  ["LT", 97.09875340864824],
  ["LC", 96.7439293598234],
  ["MG", 73.01941306323853],
  ["CL", 95.01428385923906],
  ["BH", 69.01051811453058],
  ["AD", 95.6778340475263],
  ["CH", 91.1670562264641],
  ["NO", 90.6577067913258],
  ["MT", 71.33489157252305],
  ["MC", 81.95429165043501],
  ["TV", 92.36592650305155],
  ["TW", 92.64705882352942],
  ["US", 84.07122451629658],
  ["MU", 95.65997922347748],
  ["BZ", 88.80957018569016],
  ["CI", 94.06083625503182],
  ["BM", 86.24237112063369],
  ["CK", 85.12855473315155],
  ["AT", 86.90105181145306],
  ["BO", 95.83365796649785],
  ["LS", 85.80801194650046],
  ["NL", 86.71536164134528],
  ["MW", 88.51447863913778],
  ["YE", 86.78613167121154],
  ["UG", 87.1455005843397],
  ["TT", 90.72977535385014],
  ["NZ", 86.23815088949488],
  ["MA", 95.44637060122062],
  ["MV", 94.663030775224],
  ["LR", 87.23834566939358],
  ["BY", 92.45065575899234],
  ["BN", 83.21354369562395],
  ["AU", 84.3747565251266],
  ["GE", 74.5753798208025],
  ["GR", 83.71250486949748],
  ["IN", 87.54804570834956],
  ["GBENG", 76.08979353330736],
  ["RS", 80.44864303337229],
  ["PL", 83.93098298922219],
  ["SV", 87.48733930658355],
  ["SA", 90.37819763667056],
  ["HK", 92.40196078431373],
  ["KG", 89.02090637579535],
  ["GD", 87.38248279444228],
  ["GQ", 94.60492143877418],
  ["DJ", 79.63186599142969],
  ["KE", 88.87774315024024],
  ["KR", 69.43481366056356],
  ["SC", 87.446110894689],
  ["ST", 94.66497857421115],
  ["PY", 87.07245812232178],
  ["SB", 88.25314894169588],
  ["IL", 76.3988443059343],
  ["DK", 92.38085962861966],
  ["DO", 89.18614465653812],
  ["RU", 88.29794831840022],
  ["SG", 78.06518633943644],
  ["PK", 0],
  ["GB", 78.19374107258797],
  ["GU", 91.97961303726788],
  ["GW", 96.2534086482275],
  ["JP", 69.95260355797949],
  ["SR", 89.53220360992078],
  ["SE", 95.92747695104532],
  ["SD", 94.60167510712894],
  ["SS", 93.36547201662123],
  ["PH", 78.88520971302428],
  ["RW", 97.28866380989481],
  ["KU", 95.13861836125179],
  ["HN", 86.33943643682639],
  ["GA", 96.39300090897287],
  ["DZ", 92.04908453447604],
  ["DM", 87.76360212959355],
  ["FR", 93.80534995455136],
  ["GL", 81.62706142059473],
  ["ES", 92.77171795870666],
  ["HT", 95.17692507466563],
  ["PE", 93.80729775353849],
  ["QA", 69.39423451499806],
  ["SI", 87.91553045059082],
  ["PR", 87.47338008050902],
  ["PS", 94.39293598233995],
  ["IQ", 85.98396312167252],
  ["HU", 86.90040254512401],
  ["KN", 88.38430074016361],
  ["KY", 86.6010907674328],
  ["FI", 76.86469289702636],
  ["ER", 89.57115958966368],
  ["GM", 93.50311647837944],
  ["EE", 86.38034021555642],
  ["EG", 87.66361511492013],
  ["ID", 81.95461628359953],
  ["IS", 92.19744189066354],
  ["SK", 85.32658096351123],
  ["RO", 96.13296974418907],
  ["PG", 89.69679262433449],
  ["KM", 92.05460329827295],
  ["IR", 84.21730944033243],
  ["KZ", 97.68309310479158],
  ["IE", 94.98604077392547],
  ["GN", 96.11771198545644],
  ["FJ", 89.08291131021944],
  ["GY", 87.67692507466562],
  ["JM", 87.75970653161927],
  ["KI", 83.14894169588365],
  ["HR", 84.18971562134787],
  ["SO", 97.88436566679653],
  ["PT", 90.92942475003247],
  ["SY", 85.60479158550838],
  ["SN", 95.55577197766524],
  ["KH", 92.16368004155304],
  ["EC", 92.77788598883262],
  ["ET", 87.62206206986106],
  ["DE", 94.49941566030385],
  ["SL", 87.52077652252954],
  ["PW", 98.03045059083236],
  ["SZ", 87.16692637319828],
  ["PA", 81.9971432281522],
  ["SM", 82.40618101545255],
  ["IT", 93.79301389429943],
  ["JO", 93.72159459810415],
  ["GH", 94.88994935722633],
  ["CG", 92.34157901571226],
  ["BT", 96.45695364238411],
  ["AO", 91.75691468640437],
  ["ML", 96.43098298922219],
  ["TN", 92.53863134657837],
  ["LI", 94.65848591092066],
  ["MM", 93.75795351253085],
  ["MZ", 91.67121153097001],
  ["NA", 88.02071159589664],
  ["CF", 88.92643812491883],
  ["BB", 94.3409946760161],
  ["CD", 94.89384495520062],
  ["BW", 96.30015582391897],
  ["AL", 84.1361511492014],
  ["NC", 91.2222438644332],
  ["MX", 94.10303856641995],
  ["TZ", 93.1590053239839],
  ["VE", 93.03402155564213],
  ["MN", 93.16777041942605],
  ["MY", 83.46383586547202],
  ["AM", 97.28444357875601],
  ["CR", 85.07921049214387],
  ["BA", 90.14478639137774],
  ["AZ", 95.41520581742631],
  ["CV", 88.07232826905597],
  ["BR", 89.59226074535775],
  ["CA", 92.83469679262434],
  ["BE", 95.58336579664979],
  ["TH", 87.0701856901701],
  ["WS", 94.91981560836254],
  ["UZ", 87.30911569925983],
  ["Wales", 68.88423581353071],
  ["NG", 93.79853265809636],
  ["NP", 83.16355018828723],
  ["MK", 82.06661472536034],
  ["BD", 92.53570964809765],
  ["BS", 97.00363589144267],
  ["CU", 91.02194520192182],
  ["BF", 95.47948318400208],
  ["NR", 94.1819244253993],
  ["NE", 90.27399039085833],
  ["ZW", 79.9243604726659],
  ["VC", 95.11654330606414],
  ["UY", 77.04875990131151],
  ["TJ", 84.14783794312427],
  ["VU", 86.11608881963382],
  ["ZA", 92.19192312686664],
  ["BG", 88.65147383456694],
]);

export const gbMap = new Map<string, number>([
  ["CY", 66.70847750865052],
  ["AF", 81.52162629757785],
  ["CN", 56.890570934256054],
  ["BJ", 71.75129757785467],
  ["LA", 72.65743944636678],
  ["ME", 79.19377162629758],
  ["OM", 60.31660899653979],
  ["NI", 73.52162629757785],
  ["MR", 82.40224913494811],
  ["TG", 71.2590830449827],
  ["TP", 64.70501730103805],
  ["North Korea", 62.845155709342556],
  ["VN", 61.83650519031142],
  ["ZM", 83.65051903114187],
  ["MD", 70.93771626297578],
  ["CO", 73.95977508650519],
  ["AG", 70.93901384083044],
  ["AE", 75.67863321799308],
  ["CZ", 66.3295847750865],
  ["AR", 85.07223183391004],
  ["BI", 73.81920415224914],
  ["CM", 70.16306228373702],
  ["LB", 63.36851211072665],
  ["LU", 75.11375432525952],
  ["TD", 67.07093425605535],
  ["TR", 58.10034602076125],
  ["Scotland", 68.68382352941177],
  ["UA", 81.91306228373702],
  ["LT", 86.3174740484429],
  ["LC", 95.90311418685121],
  ["MG", 79.96496539792388],
  ["CL", 64.55666089965398],
  ["BH", 57.30709342560554],
  ["AD", 72.54368512110727],
  ["CH", 81.30579584775086],
  ["NO", 71.27595155709344],
  ["MT", 61.581747404844286],
  ["MC", 61.58866782006921],
  ["TV", 76.66565743944636],
  ["TW", 80.57871972318338],
  ["US", 66.97318339100346],
  ["MU", 80.6258650519031],
  ["BZ", 73.69766435986159],
  ["CI", 85.65311418685121],
  ["BM", 58.589100346020764],
  ["CK", 60.19679930795848],
  ["AT", 71.1219723183391],
  ["BO", 74.80709342560553],
  ["LS", 73.57482698961938],
  ["NL", 73.83953287197231],
  ["MW", 66.12889273356402],
  ["YE", 74.12846020761246],
  ["UG", 84.75865051903114],
  ["TT", 64.98615916955018],
  ["NZ", 59.43858131487889],
  ["MA", 57.03373702422145],
  ["MV", 71.52638408304497],
  ["LR", 62.39965397923876],
  ["BY", 64.33044982698962],
  ["BN", 73.10553633217994],
  ["AU", 77.215830449827],
  ["GE", 66.7045847750865],
  ["GR", 72.08564013840831],
  ["IN", 78.68468858131487],
  ["GBENG", 40.03892733564014],
  ["RS", 77.76297577854672],
  ["PL", 61.54887543252595],
  ["SV", 73.09385813148789],
  ["SA", 79.1704152249135],
  ["HK", 61.75389273356401],
  ["KG", 60.647058823529406],
  ["GD", 69.99351211072664],
  ["GQ", 73.17084775086505],
  ["DJ", 83.51038062283736],
  ["KE", 81.7681660899654],
  ["KR", 66.89965397923875],
  ["SC", 61.99913494809689],
  ["ST", 78.43555363321799],
  ["PY", 68.35726643598616],
  ["SB", 80.49740484429066],
  ["IL", 66.98140138408306],
  ["DK", 69.14100346020761],
  ["DO", 69.6461937716263],
  ["RU", 76.97707612456747],
  ["SG", 64.62889273356402],
  ["PK", 77.96496539792388],
  ["GB", 0],
  ["GU", 62.93122837370242],
  ["GW", 76.67084775086505],
  ["JP", 60.140570934256054],
  ["SR", 66.54887543252596],
  ["SE", 84.8075259515571],
  ["SD", 76.71193771626298],
  ["SS", 70.31314878892734],
  ["PH", 69.32915224913495],
  ["RW", 84.76859861591696],
  ["KU", 73.62283737024221],
  ["HN", 73.29671280276817],
  ["GA", 83.9515570934256],
  ["DZ", 71.2984429065744],
  ["DM", 73.91176470588235],
  ["FR", 66.78070934256056],
  ["GL", 62.04974048442906],
  ["ES", 70.50865051903115],
  ["HT", 70.8719723183391],
  ["PE", 63.90873702422145],
  ["QA", 77.76340830449827],
  ["SI", 76.28806228373702],
  ["PR", 71.64878892733564],
  ["PS", 74.59212802768165],
  ["IQ", 72.52595155709342],
  ["HU", 69.88105536332179],
  ["KN", 76.2720588235294],
  ["KY", 58.96193771626298],
  ["FI", 69.7045847750865],
  ["ER", 66.53503460207612],
  ["GM", 71.55017301038062],
  ["EE", 84.65441176470588],
  ["EG", 74.05147058823529],
  ["ID", 61.58866782006921],
  ["IS", 53.208044982698965],
  ["SK", 72.23356401384083],
  ["RO", 67.05795847750865],
  ["PG", 74.45501730103807],
  ["KM", 71.51081314878893],
  ["IR", 73.0423875432526],
  ["KZ", 84.04498269896193],
  ["IE", 85.05017301038063],
  ["GN", 74.33520761245674],
  ["FJ", 83.23096885813149],
  ["GY", 76.56487889273357],
  ["JM", 81.44636678200692],
  ["KI", 62.48918685121107],
  ["HR", 59.45847750865052],
  ["SO", 88.54368512110726],
  ["PT", 79.9416089965398],
  ["SY", 72.77595155709342],
  ["SN", 72.94377162629758],
  ["KH", 65.59039792387543],
  ["EC", 71.90657439446366],
  ["ET", 70.94420415224914],
  ["DE", 87.66782006920415],
  ["SL", 76.40008650519032],
  ["PW", 94.35423875432527],
  ["SZ", 76.16262975778547],
  ["PA", 67.75778546712803],
  ["SM", 78.30233564013841],
  ["IT", 71.03243944636678],
  ["JO", 72.14316608996539],
  ["GH", 74.55406574394465],
  ["CG", 73.20588235294117],
  ["BT", 89.5060553633218],
  ["AO", 74.93122837370242],
  ["ML", 76.12629757785467],
  ["TN", 60.73659169550173],
  ["LI", 59.32698961937716],
  ["MM", 80.53762975778547],
  ["MZ", 77.45977508650519],
  ["NA", 65.38581314878893],
  ["CF", 60.503460207612456],
  ["BB", 67.05925605536332],
  ["CD", 81.89532871972318],
  ["BW", 93.20025951557093],
  ["AL", 63.53416955017301],
  ["NC", 80.85510380622839],
  ["MX", 68.88840830449827],
  ["TZ", 87.5008650519031],
  ["VE", 77.27378892733564],
  ["MN", 71.16046712802768],
  ["MY", 64.7530276816609],
  ["AM", 87.4182525951557],
  ["CR", 48.98832179930796],
  ["BA", 64.63192041522491],
  ["AZ", 71.58217993079586],
  ["CV", 61.55190311418686],
  ["BR", 78.76816608996539],
  ["CA", 75.54974048442907],
  ["BE", 80.36029411764706],
  ["TH", 77.62110726643598],
  ["WS", 57.9606401384083],
  ["UZ", 79.13754325259515],
  ["Wales", 68.3295847750865],
  ["NG", 78.37543252595157],
  ["NP", 55.18641868512111],
  ["MK", 76.86072664359862],
  ["BD", 82.62067474048443],
  ["BS", 85.84558823529412],
  ["CU", 52.951557093425606],
  ["BF", 77.0795847750865],
  ["NR", 61.516435986159166],
  ["NE", 78.03373702422145],
  ["ZW", 77.20631487889274],
  ["VC", 79.95112456747405],
  ["UY", 76.89489619377163],
  ["TJ", 72.70847750865052],
  ["VU", 78.64576124567473],
  ["ZA", 72.5090830449827],
  ["BG", 77.13235294117648],
]);

export const guMap = new Map<string, number>([
  ["CY", 93.22884770346495],
  ["AF", 88.27276390008058],
  ["CN", 87.8529411764706],
  ["BJ", 91.44842868654311],
  ["LA", 46.79613215149073],
  ["ME", 87.93714746172442],
  ["OM", 83.80016116035456],
  ["NI", 94.00362610797744],
  ["MR", 89.72360999194197],
  ["TG", 87.57453666398067],
  ["TP", 77.32312651087832],
  ["North Korea", 88.66035455278002],
  ["VN", 80.05237711522966],
  ["ZM", 86.1986301369863],
  ["MD", 88.07574536663981],
  ["CO", 69.21676067687349],
  ["AG", 76.3058017727639],
  ["AE", 90.58340048348107],
  ["CZ", 68.2050765511684],
  ["AR", 96.87429492344883],
  ["BI", 85.74214343271555],
  ["CM", 88.86825141015309],
  ["LB", 91.02941176470588],
  ["LU", 95.70265914585012],
  ["TD", 61.94762288477035],
  ["TR", 89.18896051571313],
  ["Scotland", 88.95366639806608],
  ["UA", 94.37429492344883],
  ["LT", 86.99476228847703],
  ["LC", 98.41418211120065],
  ["MG", 91.49395648670428],
  ["CL", 87.46132151490733],
  ["BH", 81.90209508460919],
  ["AD", 88.58178887993554],
  ["CH", 93.39564867042708],
  ["NO", 78.19097502014505],
  ["MT", 88.84689766317486],
  ["MC", 89.00644641418212],
  ["TV", 85.54915390813859],
  ["TW", 87.2074939564867],
  ["US", 76.40451248992748],
  ["MU", 74.82634971796938],
  ["BZ", 78.36986301369862],
  ["CI", 97.8436744560838],
  ["BM", 69.96937953263497],
  ["CK", 33.769137792103145],
  ["AT", 94.85495568090249],
  ["BO", 87.08823529411764],
  ["LS", 94.52377115229653],
  ["NL", 89.29411764705883],
  ["MW", 82.15995165189364],
  ["YE", 90.31506849315069],
  ["UG", 85.64786462530218],
  ["TT", 81.25503626107977],
  ["NZ", 29.517324738114425],
  ["MA", 79.06526994359388],
  ["MV", 77.78203062046737],
  ["LR", 76.51248992747784],
  ["BY", 81.05237711522966],
  ["BN", 90.67526188557615],
  ["AU", 84.47502014504433],
  ["GE", 88.09065269943594],
  ["GR", 93.69057211925866],
  ["IN", 96.28162771958098],
  ["GBENG", 93.08984689766318],
  ["RS", 63.74496373892023],
  ["PL", 89.42828364222402],
  ["SV", 93.82272360999194],
  ["SA", 86.94601128122483],
  ["HK", 91.5378726833199],
  ["KG", 86.41095890410959],
  ["GD", 73.41216760676873],
  ["GQ", 92.06768734891216],
  ["DJ", 94.7344883158743],
  ["KE", 90.13295729250605],
  ["KR", 89.33118452860597],
  ["SC", 72.2659145850121],
  ["ST", 90.48589846897663],
  ["PY", 88.36946011281225],
  ["SB", 82.50765511684126],
  ["IL", 95.20225624496373],
  ["DK", 82.6784850926672],
  ["DO", 63.64423851732474],
  ["RU", 88.20225624496373],
  ["SG", 89.82191780821918],
  ["PK", 91.6720386784851],
  ["GB", 62.933924254633354],
  ["GU", 0],
  ["GW", 84.75060435132957],
  ["JP", 92.48992747784045],
  ["SR", 90.47300564061241],
  ["SE", 91.65874294923448],
  ["SD", 87.37630942788074],
  ["SS", 85.69943593875907],
  ["PH", 84.62771958098307],
  ["RW", 92.48952457695407],
  ["KU", 87.25624496373892],
  ["HN", 94.33642224012893],
  ["GA", 94.0668815471394],
  ["DZ", 92.27639000805802],
  ["DM", 87.05116841257052],
  ["FR", 69.50725221595488],
  ["GL", 87.3908138597905],
  ["ES", 80.26269137792103],
  ["HT", 84.29492344883158],
  ["PE", 87.53102336825141],
  ["QA", 90.01651893634165],
  ["SI", 90.593875906527],
  ["PR", 85.3456889605157],
  ["PS", 90.07211925866237],
  ["IQ", 88.65793714746172],
  ["HU", 89.46454472199838],
  ["KN", 83.57695406929895],
  ["KY", 34.948025785656725],
  ["FI", 65.64504431909751],
  ["ER", 80.36140209508461],
  ["GM", 71.28605962933119],
  ["EE", 96.25785656728445],
  ["EG", 88.84931506849315],
  ["ID", 89.03384367445608],
  ["IS", 43.74456083803384],
  ["SK", 89.69137792103142],
  ["RO", 62.08178887993554],
  ["PG", 80.48912167606768],
  ["KM", 92.96575342465754],
  ["IR", 92.27316680096696],
  ["KZ", 93.39161966156325],
  ["IE", 97.40451248992747],
  ["GN", 89.2074939564867],
  ["FJ", 85.10314262691378],
  ["GY", 83.37832393231265],
  ["JM", 84.88517324738115],
  ["KI", 65.73368251410153],
  ["HR", 66.50040290088639],
  ["SO", 95.20306204673649],
  ["PT", 87.47582594681708],
  ["SY", 89.05318291700242],
  ["SN", 90.20427074939565],
  ["KH", 87.9601128122482],
  ["EC", 90.2836422240129],
  ["ET", 79.20668815471394],
  ["DE", 91.44520547945206],
  ["SL", 95.91337630942787],
  ["PW", 95.87308622078969],
  ["SZ", 83.31506849315069],
  ["PA", 90.56567284448026],
  ["SM", 93.80660757453666],
  ["IT", 92.13859790491539],
  ["JO", 88.27437550362612],
  ["GH", 88.05519742143433],
  ["CG", 86.10112812248187],
  ["BT", 89.94238517324739],
  ["AO", 83.43029814665593],
  ["ML", 89.95568090249797],
  ["TN", 91.6809024979855],
  ["LI", 49.97260273972603],
  ["MM", 95.76269137792103],
  ["MZ", 86.36543110394842],
  ["NA", 63.18211120064464],
  ["CF", 77.27961321514907],
  ["BB", 39.597904915390814],
  ["CD", 90.93553585817888],
  ["BW", 97.31990330378727],
  ["AL", 71.69500402900887],
  ["NC", 85.92586623690572],
  ["MX", 89.85777598710717],
  ["TZ", 91.86301369863014],
  ["VE", 64.97340854149878],
  ["MN", 77.36099919419823],
  ["MY", 81.70870265914584],
  ["AM", 91.83843674456084],
  ["CR", 67.80177276390008],
  ["BA", 39.120467365028205],
  ["AZ", 92.52820306204673],
  ["CV", 37.41619661563255],
  ["BR", 84.5068493150685],
  ["CA", 89.42707493956486],
  ["BE", 94.02256244963739],
  ["TH", 93.49435938759065],
  ["WS", 64.10636583400483],
  ["UZ", 95.48549556809024],
  ["Wales", 76.67526188557615],
  ["NG", 96.90773569701854],
  ["NP", 80.5560032232071],
  ["MK", 79.24858984689766],
  ["BD", 87.36462530217567],
  ["BS", 91.68211120064464],
  ["CU", 55.29371474617244],
  ["BF", 90.21474617244158],
  ["NR", 24.077356970185335],
  ["NE", 92.91861402095086],
  ["ZW", 87.86905721192588],
  ["VC", 92.70225624496375],
  ["UY", 93.56849315068493],
  ["TJ", 94.38275584206285],
  ["VU", 74.91821112006446],
  ["ZA", 69.45688960515713],
  ["BG", 91.20064464141822],
]);

export const gwMap = new Map<string, number>([
  ["CY", 93.15634218289085],
  ["AF", 90.95653305570016],
  ["CN", 68.9658164150616],
  ["BJ", 63.75021690091967],
  ["LA", 78.18670831164324],
  ["ME", 63.09387471802881],
  ["OM", 72.96416796807218],
  ["NI", 94.57964601769912],
  ["MR", 92.59543640465036],
  ["TG", 69.45427728613569],
  ["TP", 85.05986465382614],
  ["North Korea", 90.79906298802707],
  ["VN", 65.17048412285268],
  ["ZM", 95.8676036786396],
  ["MD", 80.91879229567934],
  ["CO", 55.49062988027069],
  ["AG", 70.73572791948638],
  ["AE", 72.86439354502863],
  ["CZ", 87.02108276939094],
  ["AR", 96.13786222453584],
  ["BI", 74.9826479264272],
  ["CM", 79.31893111226792],
  ["LB", 75.83289953149401],
  ["LU", 87.14688530279369],
  ["TD", 79.83862571577303],
  ["TR", 68.61747353808781],
  ["Scotland", 88.87818844351901],
  ["UA", 98.64870727051883],
  ["LT", 63.58276939094222],
  ["LC", 96.82804095089364],
  ["MG", 98.16545202151657],
  ["CL", 78.77711261495749],
  ["BH", 95.6068887732084],
  ["AD", 78.98316848863439],
  ["CH", 94.7106541731737],
  ["NO", 90.92269651223322],
  ["MT", 98.4947076175603],
  ["MC", 83.85519694603505],
  ["TV", 79.0512753774076],
  ["TW", 92.88868644803054],
  ["US", 72.42234947076176],
  ["MU", 72.72427555092834],
  ["BZ", 78.83567586326566],
  ["CI", 82.04537567239285],
  ["BM", 67.41107062293943],
  ["CK", 84.68983168488634],
  ["AT", 76.42330383480827],
  ["BO", 76.80201284053445],
  ["LS", 77.74986985944821],
  ["NL", 96.89050841575568],
  ["MW", 88.27954190525767],
  ["YE", 86.89007461391637],
  ["UG", 83.3806177338192],
  ["TT", 71.37992365087628],
  ["NZ", 85.00130140551796],
  ["MA", 65.90274162762451],
  ["MV", 71.68401874023947],
  ["LR", 77.13040083289953],
  ["BY", 90.83853895540517],
  ["BN", 66.58511192087454],
  ["AU", 87.03886864480306],
  ["GE", 90.04771820232517],
  ["GR", 91.28795766094048],
  ["IN", 95.5084157556828],
  ["GBENG", 92.45098039215686],
  ["RS", 89.14367516918271],
  ["PL", 82.37983689050841],
  ["SV", 94.22566371681415],
  ["SA", 89.33151136560818],
  ["HK", 61.646277980218635],
  ["KG", 58.74934929724102],
  ["GD", 65.30409508936317],
  ["GQ", 84.7345132743363],
  ["DJ", 65.9443866041992],
  ["KE", 88.95844178379316],
  ["KR", 95.13664757938574],
  ["SC", 86.45800798195384],
  ["ST", 55.72401526982475],
  ["PY", 84.498091271907],
  ["SB", 85.59560992538609],
  ["IL", 92.54294638209267],
  ["DK", 70.9092486552143],
  ["DO", 81.52785007808433],
  ["RU", 83.28474752732951],
  ["SG", 87.22844004858581],
  ["PK", 95.85719243449591],
  ["GB", 76.14263404476836],
  ["GU", 84.68853027936838],
  ["GW", 0],
  ["JP", 96.35649835155301],
  ["SR", 81.40768696859276],
  ["SE", 87.36291861877494],
  ["SD", 85.4689397883047],
  ["SS", 71.0098906819365],
  ["PH", 90.97431893111226],
  ["RW", 96.07322575047719],
  ["KU", 86.70310602116953],
  ["HN", 94.6230262016311],
  ["GA", 87.74032621898317],
  ["DZ", 91.97813638729828],
  ["DM", 87.46095783446121],
  ["FR", 95.09023078257852],
  ["GL", 81.33090404303314],
  ["ES", 63.143328127711264],
  ["HT", 78.07305222974146],
  ["PE", 66.46061079298975],
  ["QA", 97.00546590317542],
  ["SI", 83.4335415582162],
  ["PR", 88.7883914627798],
  ["PS", 82.92469200069408],
  ["IQ", 83.6920874544508],
  ["HU", 63.309908034010064],
  ["KN", 90.15356585111921],
  ["KY", 83.19148013187576],
  ["FI", 94.89415235120597],
  ["ER", 71.10315807739025],
  ["GM", 83.99488113829602],
  ["EE", 97.84574006593787],
  ["EG", 84.27555092833595],
  ["ID", 83.92677424952282],
  ["IS", 91.60333159812598],
  ["SK", 90.12189831684886],
  ["RO", 79.83862571577303],
  ["PG", 84.87853548499046],
  ["KM", 70.26158250910984],
  ["IR", 87.50130140551796],
  ["KZ", 85.6441957313899],
  ["IE", 96.25759153218809],
  ["GN", 34.946208571924345],
  ["FJ", 82.6006420267222],
  ["GY", 48.956706576435884],
  ["JM", 71.43978830470242],
  ["KI", 69.29377060558737],
  ["HR", 92.95028630921395],
  ["SO", 94.7961131355197],
  ["PT", 89.08988374110707],
  ["SY", 85.33012319972237],
  ["SN", 95.49496789866389],
  ["KH", 79.35710567412806],
  ["EC", 53.888599687662676],
  ["ET", 74.79611313551969],
  ["DE", 96.55865000867604],
  ["SL", 97.41627624501128],
  ["PW", 89.99089016137428],
  ["SZ", 74.37489154954017],
  ["PA", 95.98082595870207],
  ["SM", 90.82639250390422],
  ["IT", 96.44195731389901],
  ["JO", 76.20119729307653],
  ["GH", 77.88348082595871],
  ["CG", 93.14983515530106],
  ["BT", 73.83610966510497],
  ["AO", 80.94178379316328],
  ["ML", 81.0450286309214],
  ["TN", 62.114783966684016],
  ["LI", 79.41002949852506],
  ["MM", 61.38729828214472],
  ["MZ", 80.52403262189831],
  ["NA", 53.06177338191914],
  ["CF", 93.12380704494187],
  ["BB", 82.02758979698073],
  ["CD", 84.03522470935276],
  ["BW", 96.81459309387472],
  ["AL", 64.50503210133611],
  ["NC", 71.72306090577824],
  ["MX", 92.41931285788651],
  ["TZ", 90.16918271733472],
  ["VE", 61.48837411070623],
  ["MN", 73.03834808259587],
  ["MY", 80.94091618948465],
  ["AM", 84.72366822835329],
  ["CR", 85.9179246920007],
  ["BA", 72.5897969807392],
  ["AZ", 65.13447857018913],
  ["CV", 91.78769737983689],
  ["BR", 69.23824397015443],
  ["CA", 91.1829776158251],
  ["BE", 97.11651917404129],
  ["TH", 84.33628318584071],
  ["WS", 79.08424431719591],
  ["UZ", 71.40074613916363],
  ["Wales", 61.60853722019781],
  ["NG", 79.98828735033837],
  ["NP", 74.7635779975707],
  ["MK", 76.35649835155301],
  ["BD", 93.54936664931459],
  ["BS", 94.25299323269131],
  ["CU", 74.12198507721672],
  ["BF", 63.985337497831],
  ["NR", 92.19677251431546],
  ["NE", 74.35320145757419],
  ["ZW", 85.19521082769391],
  ["VC", 63.06177338191914],
  ["UY", 90.02342529932326],
  ["TJ", 92.78414020475448],
  ["VU", 63.90117994100295],
  ["ZA", 89.92798889467292],
  ["BG", 84.10636821100121],
]);

export const jpMap = new Map<string, number>([
  ["CY", 20.70445396701727],
  ["AF", 89.82729515647318],
  ["CN", 95.74925334372159],
  ["BJ", 97.08836514738346],
  ["LA", 95.35092845085053],
  ["ME", 91.4313076223867],
  ["OM", 64.11797169198805],
  ["NI", 77.58213219062459],
  ["MR", 95.09511751720557],
  ["TG", 93.49110505129204],
  ["TP", 92.43604726658874],
  ["North Korea", 88.74334502012725],
  ["VN", 95.41650435008441],
  ["ZM", 97.57693805999222],
  ["MD", 92.29061160888196],
  ["CO", 96.55499285807038],
  ["AG", 95.15809635112323],
  ["AE", 86.56732891832229],
  ["CZ", 61.700428515777176],
  ["AR", 78.63134657836645],
  ["BI", 77.31788079470199],
  ["CM", 82.91715361641346],
  ["LB", 62.143552785352554],
  ["LU", 77.89020906375795],
  ["TD", 96.80820672639918],
  ["TR", 91.92410076613426],
  ["Scotland", 68.72581482924296],
  ["UA", 97.36787430203869],
  ["LT", 98.04798078171666],
  ["LC", 96.36345929100116],
  ["MG", 65.32008830022075],
  ["CL", 73.24957797688612],
  ["BH", 50.92065965459032],
  ["AD", 95.02954161797169],
  ["CH", 92.9054668224906],
  ["NO", 84.23289183222958],
  ["MT", 51.19107908063888],
  ["MC", 50.058433969614335],
  ["TV", 88.160303856642],
  ["TW", 93.52649006622516],
  ["US", 68.1590053239839],
  ["MU", 97.25003246331644],
  ["BZ", 84.89579275418777],
  ["CI", 83.70568757304247],
  ["BM", 74.65231788079471],
  ["CK", 84.88021036229061],
  ["AT", 78.10414231917933],
  ["BO", 97.32632125697961],
  ["LS", 72.60550577847033],
  ["NL", 75.44864303337229],
  ["MW", 83.4959745487599],
  ["YE", 75.59927282171147],
  ["UG", 93.59206596545903],
  ["TT", 86.11121932216595],
  ["NZ", 87.43150240228542],
  ["MA", 94.3302817815868],
  ["MV", 95.64894169588365],
  ["LR", 58.74788988443059],
  ["BY", 93.07362680171406],
  ["BN", 70.97779509154655],
  ["AU", 86.40209063757953],
  ["GE", 39.683482664589015],
  ["GR", 63.39468900142838],
  ["IN", 76.93838462537332],
  ["GBENG", 23.446954940916765],
  ["RS", 64.92955460329827],
  ["PL", 58.15251266069341],
  ["SV", 77.21269964939617],
  ["SA", 91.69912998311908],
  ["HK", 94.23126866640696],
  ["KG", 90.3733281392027],
  ["GD", 89.42637319828593],
  ["GQ", 84.2452278924815],
  ["DJ", 76.92767173094404],
  ["KE", 85.17660044150111],
  ["KR", 18.98974159200104],
  ["SC", 80.28600181794572],
  ["ST", 94.52960654460459],
  ["PY", 73.98617062719127],
  ["SB", 88.72419166342034],
  ["IL", 45.131801064796775],
  ["DK", 75.86579664978575],
  ["DO", 76.98578106739384],
  ["RU", 67.95935592780158],
  ["SG", 55.462277626282294],
  ["PK", 69.95260355797949],
  ["GB", 60.09674068302818],
  ["GU", 92.59057265290222],
  ["GW", 96.6858200233736],
  ["JP", 0],
  ["SR", 72.52110115569407],
  ["SE", 97.3558628749513],
  ["SD", 84.80164913647579],
  ["SS", 84.39390988183352],
  ["PH", 71.47740553174913],
  ["RW", 97.43020386962732],
  ["KU", 84.11862095831711],
  ["HN", 77.36722503570965],
  ["GA", 96.94844825347357],
  ["DZ", 50.79502661991949],
  ["DM", 80.17270484352682],
  ["FR", 83.40994676016102],
  ["GL", 50.284378652123095],
  ["ES", 94.35105830411635],
  ["HT", 89.79970133748864],
  ["PE", 82.86391377743149],
  ["QA", 65.98071679002727],
  ["SI", 69.07934034540969],
  ["PR", 78.450201272562],
  ["PS", 87.03739774055317],
  ["IQ", 74.44260485651213],
  ["HU", 75.71808855992728],
  ["KN", 87.01824438384625],
  ["KY", 85.98558628749512],
  ["FI", 44.11569925983638],
  ["ER", 91.58907934034542],
  ["GM", 88.74302038696274],
  ["EE", 68.34437086092716],
  ["EG", 75.53986495260355],
  ["ID", 50.22984028048305],
  ["IS", 86.42027009479288],
  ["SK", 66.03330736268018],
  ["RO", 96.98610570055837],
  ["PG", 87.15686274509804],
  ["KM", 78.61836125178549],
  ["IR", 72.03837164004675],
  ["KZ", 96.55694065705752],
  ["IE", 83.7465913517725],
  ["GN", 96.81340085703155],
  ["FJ", 87.15848591092066],
  ["GY", 85.95182443838463],
  ["JM", 89.47831450460978],
  ["KI", 75.7047786001818],
  ["HR", 73.69659784443579],
  ["SO", 96.72445136995196],
  ["PT", 91.70432411375145],
  ["SY", 75.89371510193482],
  ["SN", 96.17452278924816],
  ["KH", 93.90858330087002],
  ["EC", 91.0904427996364],
  ["ET", 89.99026100506427],
  ["DE", 96.77217244513699],
  ["SL", 78.12459420854434],
  ["PW", 97.71523178807946],
  ["SZ", 91.13361901051812],
  ["PA", 56.72737306843268],
  ["SM", 54.95909622126997],
  ["IT", 83.44500714192962],
  ["JO", 84.74938319698741],
  ["GH", 93.24763017789898],
  ["CG", 93.7816517335411],
  ["BT", 92.32307492533437],
  ["AO", 88.29048175561616],
  ["ML", 96.83872224386442],
  ["TN", 93.5553824178678],
  ["LI", 88.08693676145955],
  ["MM", 96.74652642513959],
  ["MZ", 90.41228411894559],
  ["NA", 81.12615244773406],
  ["CF", 72.48084664329309],
  ["BB", 93.52713933255421],
  ["CD", 87.21756914686405],
  ["BW", 94.43611219322166],
  ["AL", 86.56570575249968],
  ["NC", 93.12297104272173],
  ["MX", 80.7317231528373],
  ["TZ", 90.18893650175302],
  ["VE", 91.88449552006233],
  ["MN", 95.20127256200493],
  ["MY", 65.7723022984028],
  ["AM", 95.75671990650565],
  ["CR", 60.75639527334113],
  ["BA", 90.97909362420464],
  ["AZ", 94.58836514738346],
  ["CV", 84.74191663420335],
  ["BR", 89.06245942085444],
  ["CA", 66.07908063887807],
  ["BE", 97.12050383067134],
  ["TH", 72.20718088559927],
  ["WS", 84.51694585118815],
  ["UZ", 76.66634203350215],
  ["Wales", 48.63004804570835],
  ["NG", 83.44857810673938],
  ["NP", 50.422672380210365],
  ["MK", 83.64757823659265],
  ["BD", 96.14985066874432],
  ["BS", 97.15361641345281],
  ["CU", 77.56784833138553],
  ["BF", 96.06934164394234],
  ["NR", 95.1295286326451],
  ["NE", 76.80333722893131],
  ["ZW", 81.38391118036618],
  ["VC", 94.10531099857161],
  ["UY", 49.06830281781587],
  ["TJ", 71.87118556031685],
  ["VU", 85.6953642384106],
  ["ZA", 88.79203999480588],
  ["BG", 68.06486170627191],
]);

export const srMap = new Map<string, number>([
  ["CY", 79.88454592363261],
  ["AF", 91.15486326109391],
  ["CN", 94.39241486068111],
  ["BJ", 94.43982198142415],
  ["LA", 97.62803147574819],
  ["ME", 91.7156862745098],
  ["OM", 85.7749613003096],
  ["NI", 96.25193498452013],
  ["MR", 95.2889576883385],
  ["TG", 81.19969040247678],
  ["TP", 93.47619969040247],
  ["North Korea", 95.24122807017544],
  ["VN", 95.72884416924664],
  ["ZM", 93.1827270381837],
  ["MD", 79.79134416924664],
  ["CO", 94.1905314757482],
  ["AG", 71.57314241486068],
  ["AE", 88.54972910216719],
  ["CZ", 87.7828302373581],
  ["AR", 96.75438596491229],
  ["BI", 83.83771929824562],
  ["CM", 85.34797471620227],
  ["LB", 86.84887770897834],
  ["LU", 97.69317595459236],
  ["TD", 81.125193498452],
  ["TR", 93.98413312693499],
  ["Scotland", 83.83384932920536],
  ["UA", 93.39944530443756],
  ["LT", 94.20085139318886],
  ["LC", 95.90589525283798],
  ["MG", 90.87138802889577],
  ["CL", 89.56075851393189],
  ["BH", 68.2936661506708],
  ["AD", 80.87880546955624],
  ["CH", 92.53547471620227],
  ["NO", 92.41647316821465],
  ["MT", 71.09197626418988],
  ["MC", 70.59629772961816],
  ["TV", 88.16273219814241],
  ["TW", 93.5358617131063],
  ["US", 64.87938596491229],
  ["MU", 96.750193498452],
  ["BZ", 85.35539215686273],
  ["CI", 91.76889834881321],
  ["BM", 71.6579592363261],
  ["CK", 88.7796697626419],
  ["AT", 98.3110810113519],
  ["BO", 95.56114551083591],
  ["LS", 98.34881320949432],
  ["NL", 95.29444014447884],
  ["MW", 65.17543859649123],
  ["YE", 95.4563338493292],
  ["UG", 86.89563983488132],
  ["TT", 67.69543343653251],
  ["NZ", 88.9499484004128],
  ["MA", 63.3078560371517],
  ["MV", 84.63944788441692],
  ["LR", 77.60610165118679],
  ["BY", 91.01618937048504],
  ["BN", 84.82101393188854],
  ["AU", 89.6968524251806],
  ["GE", 79.76747936016511],
  ["GR", 90.57952786377709],
  ["IN", 96.63312693498453],
  ["GBENG", 63.35848813209495],
  ["RS", 83.75612745098039],
  ["PL", 88.05243808049535],
  ["SV", 97.50193498452012],
  ["SA", 91.31740196078432],
  ["HK", 94.08539731682146],
  ["KG", 91.84791021671826],
  ["GD", 77.67608359133126],
  ["GQ", 95.04547213622291],
  ["DJ", 89.60913312693498],
  ["KE", 94.02928276573788],
  ["KR", 77.95633384932921],
  ["SC", 85.82333591331269],
  ["ST", 86.7156862745098],
  ["PY", 94.73974458204334],
  ["SB", 87.65092879256966],
  ["IL", 86.6844040247678],
  ["DK", 75.87138802889577],
  ["DO", 87.06366099071208],
  ["RU", 87.29166666666667],
  ["SG", 84.36210010319918],
  ["PK", 89.4453044375645],
  ["GB", 66.27837977296181],
  ["GU", 90.63660990712074],
  ["GW", 82.1875],
  ["JP", 72.72252321981424],
  ["SR", 0],
  ["SE", 95.21897574819401],
  ["SD", 94.14796181630547],
  ["SS", 93.05050309597523],
  ["PH", 82.17395510835914],
  ["RW", 74.23858359133126],
  ["KU", 95.37990196078432],
  ["HN", 97.14718782249741],
  ["GA", 95.25864293085655],
  ["DZ", 84.66363519091847],
  ["DM", 91.13648090815273],
  ["FR", 91.89531733746131],
  ["GL", 70.53631320949432],
  ["ES", 91.5889447884417],
  ["HT", 81.43221104231166],
  ["PE", 91.86693756449948],
  ["QA", 89.61203560371517],
  ["SI", 86.91885964912281],
  ["PR", 77.11010061919504],
  ["PS", 86.25128998968007],
  ["IQ", 94.1482843137255],
  ["HU", 95.50374097007224],
  ["KN", 82.14138286893706],
  ["KY", 87.37487100103199],
  ["FI", 81.87983746130031],
  ["ER", 83.21336429308566],
  ["GM", 75.17608359133126],
  ["EE", 88.89351135190918],
  ["EG", 93.5858488132095],
  ["ID", 70.99554953560371],
  ["IS", 94.39983230134159],
  ["SK", 85.36796955624355],
  ["RO", 80.82204592363261],
  ["PG", 81.0171568627451],
  ["KM", 75.35571465428276],
  ["IR", 92.77444530443756],
  ["KZ", 90.37925696594428],
  ["IE", 91.90886222910216],
  ["GN", 80.76657636738906],
  ["FJ", 87.45614035087719],
  ["GY", 74.74264705882354],
  ["JM", 87.75832043343654],
  ["KI", 69.77296181630547],
  ["HR", 94.41692466460269],
  ["SO", 96.13970588235294],
  ["PT", 92.2126547987616],
  ["SY", 94.125064499484],
  ["SN", 95.64209236326109],
  ["KH", 94.56624097007223],
  ["EC", 92.08720330237358],
  ["ET", 91.03231424148606],
  ["DE", 91.62087203302374],
  ["SL", 98.30721104231166],
  ["PW", 94.35436016511868],
  ["SZ", 83.81417698658412],
  ["PA", 80.4282765737874],
  ["SM", 84.2750257997936],
  ["IT", 91.33159184726523],
  ["JO", 81.24419504643963],
  ["GH", 95.89654282765738],
  ["CG", 92.00109649122807],
  ["BT", 90.47826367389061],
  ["AO", 78.80095459236325],
  ["ML", 80.58468782249743],
  ["TN", 94.3485552115583],
  ["LI", 78.8702915376677],
  ["MM", 91.43156604747162],
  ["MZ", 83.69001547987615],
  ["NA", 81.49800051599587],
  ["CF", 88.18788699690403],
  ["BB", 94.40241228070175],
  ["CD", 91.95691434468525],
  ["BW", 98.0485681114551],
  ["AL", 90.61629256965944],
  ["NC", 91.70149638802889],
  ["MX", 76.9298245614035],
  ["TZ", 92.09816821465428],
  ["VE", 88.9560758513932],
  ["MN", 73.53231424148606],
  ["MY", 82.65028379772961],
  ["AM", 96.89176986584107],
  ["CR", 52.33552631578947],
  ["BA", 89.51367389060889],
  ["AZ", 94.76393188854489],
  ["CV", 83.69453044375645],
  ["BR", 89.20568885448917],
  ["CA", 86.69020897832817],
  ["BE", 95.54179566563468],
  ["TH", 78.48716460268318],
  ["WS", 69.8374613003096],
  ["UZ", 92.26844685242519],
  ["Wales", 74.23793859649123],
  ["NG", 92.0404411764706],
  ["NP", 76.1451883384933],
  ["MK", 80.6266124871001],
  ["BD", 94.27857327141382],
  ["BS", 95.61306759545923],
  ["CU", 68.31849845201239],
  ["BF", 94.76973684210527],
  ["NR", 94.26341589267287],
  ["NE", 97.16234520123838],
  ["ZW", 85.5392156862745],
  ["VC", 92.18621001031991],
  ["UY", 81.62474200206398],
  ["TJ", 95.15866873065015],
  ["VU", 84.6142930856553],
  ["ZA", 91.94143446852425],
  ["BG", 86.97852167182663],
]);

export const seMap = new Map<string, number>([
  ["CY", 93.35155709342561],
  ["AF", 92.38477508650519],
  ["CN", 96.19134948096885],
  ["BJ", 91.30968858131487],
  ["LA", 95.82387543252595],
  ["ME", 89.23010380622839],
  ["OM", 93.38650519031142],
  ["NI", 94.76955017301037],
  ["MR", 94.77058823529411],
  ["TG", 93.98581314878894],
  ["TP", 93.76401384083046],
  ["North Korea", 65.93321799307958],
  ["VN", 96.09273356401384],
  ["ZM", 94.38512110726643],
  ["MD", 61.115570934256056],
  ["CO", 71.4318339100346],
  ["AG", 92.23979238754325],
  ["AE", 97.36193771626299],
  ["CZ", 82.56539792387542],
  ["AR", 95.80276816608998],
  ["BI", 88.82283737024221],
  ["CM", 90.44705882352942],
  ["LB", 93.53356401384083],
  ["LU", 98.54913494809688],
  ["TD", 84.02145328719723],
  ["TR", 94.41799307958478],
  ["Scotland", 90.09377162629758],
  ["UA", 83.50103806228374],
  ["LT", 93.62456747404845],
  ["LC", 94.84290657439446],
  ["MG", 98.25916955017301],
  ["CL", 86.55951557093425],
  ["BH", 95.66055363321799],
  ["AD", 83.81764705882352],
  ["CH", 94.75743944636679],
  ["NO", 89.9121107266436],
  ["MT", 98.19826989619376],
  ["MC", 96.12629757785467],
  ["TV", 89.74878892733564],
  ["TW", 95.40692041522492],
  ["US", 83.6878892733564],
  ["MU", 86.45882352941176],
  ["BZ", 44.193425605536326],
  ["CI", 96.41349480968859],
  ["BM", 88.27024221453287],
  ["CK", 88.26782006920415],
  ["AT", 98.1712802768166],
  ["BO", 77.56193771626297],
  ["LS", 97.32595155709343],
  ["NL", 97.85397923875433],
  ["MW", 92.78581314878893],
  ["YE", 98.59861591695501],
  ["UG", 89.95121107266436],
  ["TT", 93.15674740484428],
  ["NZ", 88.61072664359861],
  ["MA", 98.10034602076124],
  ["MV", 96.18754325259515],
  ["LR", 89.2439446366782],
  ["BY", 92.5401384083045],
  ["BN", 82.55813148788927],
  ["AU", 87.17785467128027],
  ["GE", 94.89584775086504],
  ["GR", 57.60034602076125],
  ["IN", 95.53840830449826],
  ["GBENG", 98.0515570934256],
  ["RS", 82.39480968858132],
  ["PL", 97.82906574394462],
  ["SV", 38.24878892733564],
  ["SA", 91.31764705882352],
  ["HK", 92.92179930795848],
  ["KG", 90.01038062283737],
  ["GD", 83.49377162629757],
  ["GQ", 93.35640138408304],
  ["DJ", 94.5],
  ["KE", 92.64290657439447],
  ["KR", 92.35155709342561],
  ["SC", 77.98581314878894],
  ["ST", 80.41107266435986],
  ["PY", 68.0083044982699],
  ["SB", 86.81314878892734],
  ["IL", 93.49238754325259],
  ["DK", 94.8128027681661],
  ["DO", 95.01245674740485],
  ["RU", 87.8688581314879],
  ["SG", 91.94913494809688],
  ["PK", 95.32491349480969],
  ["GB", 84.27543252595156],
  ["GU", 91.91487889273357],
  ["GW", 87.40865051903114],
  ["JP", 96.88373702422145],
  ["SR", 94.45190311418685],
  ["SE", 0],
  ["SD", 94.46470588235294],
  ["SS", 81.62525951557093],
  ["PH", 64.92906574394463],
  ["RW", 83.24186851211073],
  ["KU", 95.37266435986159],
  ["HN", 95.51660899653979],
  ["GA", 77.20968858131488],
  ["DZ", 95.95121107266435],
  ["DM", 88.98546712802768],
  ["FR", 96.6567474048443],
  ["GL", 94.18650519031142],
  ["ES", 77.85743944636678],
  ["HT", 96.11868512110726],
  ["PE", 96.62456747404843],
  ["QA", 96.98131487889273],
  ["SI", 86.319723183391],
  ["PR", 91.37923875432527],
  ["PS", 94.71176470588235],
  ["IQ", 94.52249134948096],
  ["HU", 98.04532871972317],
  ["KN", 89.91799307958478],
  ["KY", 88.65328719723183],
  ["FI", 91.85847750865051],
  ["ER", 84.88685121107267],
  ["GM", 97.2878892733564],
  ["EE", 99.6477508650519],
  ["EG", 95.12664359861591],
  ["ID", 95.65570934256056],
  ["IS", 27.323183391003457],
  ["SK", 85.87681660899655],
  ["RO", 84.0553633217993],
  ["PG", 92.54740484429065],
  ["KM", 87.6470588235294],
  ["IR", 88.98200692041523],
  ["KZ", 88.7892733564014],
  ["IE", 96.6712802768166],
  ["GN", 83.88512110726644],
  ["FJ", 87.1598615916955],
  ["GY", 78.66885813148788],
  ["JM", 84.59342560553634],
  ["KI", 68.46470588235294],
  ["HR", 91.24532871972319],
  ["SO", 95.95847750865052],
  ["PT", 92.19377162629758],
  ["SY", 96.46539792387543],
  ["SN", 96.86020761245675],
  ["KH", 92.09792387543253],
  ["EC", 70.54567474048443],
  ["ET", 74.88442906574394],
  ["DE", 92.20692041522491],
  ["SL", 98.29584775086505],
  ["PW", 86.72491349480968],
  ["SZ", 88.83321799307959],
  ["PA", 80.10692041522492],
  ["SM", 90.38131487889274],
  ["IT", 96.68546712802768],
  ["JO", 93.81003460207612],
  ["GH", 79.24083044982699],
  ["CG", 94.38166089965398],
  ["BT", 88.86297577854671],
  ["AO", 95.07785467128028],
  ["ML", 83.64429065743944],
  ["TN", 93.38235294117648],
  ["LI", 96.26955017301037],
  ["MM", 91.39342560553632],
  ["MZ", 89.28858131487888],
  ["NA", 74.23529411764706],
  ["CF", 92.84290657439446],
  ["BB", 96.10588235294118],
  ["CD", 91.65397923875432],
  ["BW", 98.2121107266436],
  ["AL", 92.50588235294117],
  ["NC", 60.03252595155709],
  ["MX", 92.55017301038062],
  ["TZ", 90.29377162629758],
  ["VE", 92.17404844290658],
  ["MN", 75.86712802768166],
  ["MY", 78.63806228373701],
  ["AM", 89.23287197231834],
  ["CR", 95.02041522491349],
  ["BA", 84.77128027681661],
  ["AZ", 95.82076124567475],
  ["CV", 35.937716262975776],
  ["BR", 83.3044982698962],
  ["CA", 93.53667820069204],
  ["BE", 98.7757785467128],
  ["TH", 97.74394463667821],
  ["WS", 96.08650519031143],
  ["UZ", 94.69307958477509],
  ["Wales", 82.21557093425605],
  ["NG", 96.47750865051903],
  ["NP", 83.08166089965397],
  ["MK", 73.92595155709343],
  ["BD", 95.13217993079584],
  ["BS", 94.42110726643598],
  ["CU", 92.880276816609],
  ["BF", 96.38961937716263],
  ["NR", 90.06193771626297],
  ["NE", 97.2228373702422],
  ["ZW", 88.29896193771626],
  ["VC", 81.36955017301038],
  ["UY", 66.8477508650519],
  ["TJ", 93.4681660899654],
  ["VU", 87.61730103806228],
  ["ZA", 91.73079584775087],
  ["BG", 97.96124567474048],
]);

export const sdMap = new Map<string, number>([
  ["CY", 81.89013840830451],
  ["AF", 86.53935986159169],
  ["CN", 94.26600346020761],
  ["BJ", 93.61894463667821],
  ["LA", 64.61591695501731],
  ["ME", 90.0583910034602],
  ["OM", 92.45804498269896],
  ["NI", 74.05233564013841],
  ["MR", 78.80060553633218],
  ["TG", 85.96842560553634],
  ["TP", 69.83780276816609],
  ["North Korea", 82.7954152249135],
  ["VN", 66.92603806228374],
  ["ZM", 89.62846020761246],
  ["MD", 81.6128892733564],
  ["CO", 94.02984429065744],
  ["AG", 90.7136678200692],
  ["AE", 44.19723183391003],
  ["CZ", 85.63192041522491],
  ["AR", 78.0432525951557],
  ["BI", 70.96626297577855],
  ["CM", 83.3174740484429],
  ["LB", 57.52681660899655],
  ["LU", 45.01167820069204],
  ["TD", 83.03373702422145],
  ["TR", 67.21323529411765],
  ["Scotland", 82.85207612456747],
  ["UA", 97.02854671280276],
  ["LT", 90.03935986159169],
  ["LC", 97.14186851211073],
  ["MG", 97.94420415224914],
  ["CL", 84.88970588235294],
  ["BH", 76.45371972318338],
  ["AD", 82.13754325259515],
  ["CH", 83.63278546712803],
  ["NO", 65.30276816608996],
  ["MT", 75.44420415224914],
  ["MC", 57.403546712802765],
  ["TV", 84.56833910034602],
  ["TW", 92.89230103806229],
  ["US", 69.99351211072664],
  ["MU", 73.52292387543253],
  ["BZ", 74.98269896193773],
  ["CI", 88.38451557093425],
  ["BM", 70.22837370242215],
  ["CK", 82.39835640138409],
  ["AT", 44.75865051903114],
  ["BO", 61.10899653979239],
  ["LS", 76.6371107266436],
  ["NL", 72.77551903114187],
  ["MW", 92.22923875432527],
  ["YE", 15.79022491349481],
  ["UG", 94.52508650519032],
  ["TT", 68.12024221453287],
  ["NZ", 84.05233564013841],
  ["MA", 68.24221453287197],
  ["MV", 67.87370242214533],
  ["LR", 72.3637543252595],
  ["BY", 92.40138408304497],
  ["BN", 78.02551903114187],
  ["AU", 85.70761245674741],
  ["GE", 88.6098615916955],
  ["GR", 81.83607266435986],
  ["IN", 77.04887543252596],
  ["GBENG", 87.02076124567473],
  ["RS", 90.74956747404845],
  ["PL", 86.67906574394463],
  ["SV", 74.58693771626298],
  ["SA", 75.7984429065744],
  ["HK", 89.81228373702422],
  ["KG", 64.2621107266436],
  ["GD", 77.38667820069203],
  ["GQ", 72.37370242214533],
  ["DJ", 91.88019031141869],
  ["KE", 91.909169550173],
  ["KR", 81.3862456747405],
  ["SC", 72.31271626297577],
  ["ST", 89.14965397923875],
  ["PY", 45.62067474048443],
  ["SB", 88.93728373702422],
  ["IL", 77.7621107266436],
  ["DK", 59.99048442906575],
  ["DO", 67.50951557093425],
  ["RU", 98.31877162629758],
  ["SG", 57.93555363321799],
  ["PK", 94.16089965397923],
  ["GB", 76.71193771626298],
  ["GU", 87.21626297577855],
  ["GW", 85.29455017301039],
  ["JP", 84.56314878892734],
  ["SR", 93.73399653979239],
  ["SE", 94.24264705882352],
  ["SD", 0],
  ["SS", 92.41176470588235],
  ["PH", 96.11245674740483],
  ["RW", 93.16349480968859],
  ["KU", 74.27811418685121],
  ["HN", 74.08996539792388],
  ["GA", 92.66435986159169],
  ["DZ", 64.62716262975778],
  ["DM", 82.21539792387543],
  ["FR", 74.05709342560553],
  ["GL", 81.90181660899654],
  ["ES", 70.08391003460208],
  ["HT", 94.46280276816609],
  ["PE", 68.280276816609],
  ["QA", 96.1470588235294],
  ["SI", 96.14576124567475],
  ["PR", 85.034169550173],
  ["PS", 73.25519031141869],
  ["IQ", 18.58650519031142],
  ["HU", 44.66306228373703],
  ["KN", 84.93555363321799],
  ["KY", 84.18944636678201],
  ["FI", 95.7257785467128],
  ["ER", 85.17776816608996],
  ["GM", 60.542387543252595],
  ["EE", 97.88451557093425],
  ["EG", 17.465397923875432],
  ["ID", 57.403546712802765],
  ["IS", 84.63667820069205],
  ["SK", 93.74870242214533],
  ["RO", 83.16262975778547],
  ["PG", 48.61461937716263],
  ["KM", 83.91998269896193],
  ["IR", 70.35250865051903],
  ["KZ", 91.03373702422145],
  ["IE", 85.65181660899654],
  ["GN", 88.2469723183391],
  ["FJ", 85.08607266435986],
  ["GY", 87.68252595155708],
  ["JM", 90.90960207612456],
  ["KI", 57.80795847750865],
  ["HR", 70.82006920415225],
  ["SO", 92.25994809688581],
  ["PT", 90.3416955017301],
  ["SY", 16.46885813148789],
  ["SN", 68.30493079584775],
  ["KH", 83.50778546712803],
  ["EC", 91.73010380622837],
  ["ET", 86.09731833910035],
  ["DE", 98.67387543252595],
  ["SL", 73.1825259515571],
  ["PW", 96.86937716262976],
  ["SZ", 83.6128892733564],
  ["PA", 68.90095155709342],
  ["SM", 89.39316608996539],
  ["IT", 74.7863321799308],
  ["JO", 75.66262975778547],
  ["GH", 59.601643598615915],
  ["CG", 89.47058823529412],
  ["BT", 91.91003460207612],
  ["AO", 39.19593425605537],
  ["ML", 83.99653979238755],
  ["TN", 62.61721453287197],
  ["LI", 94.97231833910035],
  ["MM", 90.28027681660899],
  ["MZ", 91.16089965397924],
  ["NA", 81.44636678200692],
  ["CF", 81.87629757785467],
  ["BB", 92.66435986159169],
  ["CD", 87.83520761245674],
  ["BW", 93.45631487889273],
  ["AL", 70.14186851211073],
  ["NC", 91.74437716262975],
  ["MX", 64.10164359861592],
  ["TZ", 85.91349480968859],
  ["VE", 96.85769896193773],
  ["MN", 77.22318339100346],
  ["MY", 73.99480968858131],
  ["AM", 97.2257785467128],
  ["CR", 95.57050173010381],
  ["BA", 86.9212802768166],
  ["AZ", 94.87283737024221],
  ["CV", 88.00346020761246],
  ["BR", 90.09731833910034],
  ["CA", 90.52205882352942],
  ["BE", 79.51167820069203],
  ["TH", 82.71107266435986],
  ["WS", 77.84429065743944],
  ["UZ", 71.06358131487889],
  ["Wales", 84.74307958477509],
  ["NG", 85.80320069204153],
  ["NP", 74.72102076124567],
  ["MK", 84.0666089965398],
  ["BD", 94.06790657439447],
  ["BS", 93.40311418685121],
  ["CU", 84.47837370242215],
  ["BF", 66.73875432525952],
  ["NR", 93.57266435986159],
  ["NE", 78.75216262975778],
  ["ZW", 95.82266435986159],
  ["VC", 91.9757785467128],
  ["UY", 87.8326124567474],
  ["TJ", 73.0220588235294],
  ["VU", 68.40441176470588],
  ["ZA", 86.84602076124568],
  ["BG", 97.89230103806229],
]);

export const ssMap = new Map<string, number>([
  ["CY", 89.85207612456747],
  ["AF", 83.45112456747404],
  ["CN", 74.9446366782007],
  ["BJ", 83.39705882352942],
  ["LA", 90.66089965397924],
  ["ME", 74.1924740484429],
  ["OM", 71.62932525951558],
  ["NI", 92.90181660899654],
  ["MR", 89.11115916955016],
  ["TG", 73.40095155709344],
  ["TP", 78.80579584775087],
  ["North Korea", 73.41955017301038],
  ["VN", 80.07482698961937],
  ["ZM", 89.05579584775086],
  ["MD", 63.14489619377163],
  ["CO", 87.07439446366782],
  ["AG", 60.48010380622837],
  ["AE", 93.54801038062284],
  ["CZ", 80.12413494809688],
  ["AR", 95.09731833910034],
  ["BI", 87.44160899653978],
  ["CM", 84.49221453287197],
  ["LB", 85.97491349480968],
  ["LU", 93.54887543252595],
  ["TD", 81.72794117647058],
  ["TR", 75.55406574394463],
  ["Scotland", 79.48140138408306],
  ["UA", 84.55752595155708],
  ["LT", 93.83217993079585],
  ["LC", 96.7742214532872],
  ["MG", 76.09818339100346],
  ["CL", 74.28762975778547],
  ["BH", 76.24610726643598],
  ["AD", 89.0575259515571],
  ["CH", 91.12240484429066],
  ["NO", 89.18641868512111],
  ["MT", 81.02292387543253],
  ["MC", 83.67863321799308],
  ["TV", 85.96712802768167],
  ["TW", 88.95242214532873],
  ["US", 86.54714532871972],
  ["MU", 89.2530276816609],
  ["BZ", 72.54022491349481],
  ["CI", 94.18685121107266],
  ["BM", 76.25043252595157],
  ["CK", 85.02595155709342],
  ["AT", 93.55017301038062],
  ["BO", 68.63754325259515],
  ["LS", 63.01038062283737],
  ["NL", 93.5856401384083],
  ["MW", 54.69636678200692],
  ["YE", 95.39143598615917],
  ["UG", 70.30406574394463],
  ["TT", 75.42301038062284],
  ["NZ", 86.05363321799308],
  ["MA", 75.8235294117647],
  ["MV", 84.19896193771626],
  ["LR", 75.41782006920415],
  ["BY", 89.67084775086505],
  ["BN", 90.4446366782007],
  ["AU", 87.41955017301038],
  ["GE", 86.47837370242215],
  ["GR", 83.68901384083046],
  ["IN", 93.06055363321799],
  ["GBENG", 78.59602076124568],
  ["RS", 90.58737024221453],
  ["PL", 91.38667820069205],
  ["SV", 84.62024221453287],
  ["SA", 65.01946366782006],
  ["HK", 79.31531141868511],
  ["KG", 76.90960207612457],
  ["GD", 82.05103806228374],
  ["GQ", 90.42474048442907],
  ["DJ", 95.08737024221453],
  ["KE", 58.07828719723184],
  ["KR", 85.08693771626298],
  ["SC", 61.66565743944636],
  ["ST", 88.42387543252596],
  ["PY", 87.68858131487889],
  ["SB", 78.05666089965399],
  ["IL", 83.01384083044982],
  ["DK", 84.6029411764706],
  ["DO", 89.16392733564014],
  ["RU", 83.8499134948097],
  ["SG", 83.72534602076125],
  ["PK", 92.66003460207612],
  ["GB", 70.31314878892734],
  ["GU", 85.4022491349481],
  ["GW", 71.70198961937716],
  ["JP", 84.19809688581314],
  ["SR", 93.03503460207612],
  ["SE", 81.3840830449827],
  ["SD", 92.41176470588235],
  ["SS", 0],
  ["PH", 86.01946366782008],
  ["RW", 89.74913494809688],
  ["KU", 93.9666955017301],
  ["HN", 92.66176470588235],
  ["GA", 93.41003460207612],
  ["DZ", 84.55666089965398],
  ["DM", 64.58953287197232],
  ["FR", 81.72534602076125],
  ["GL", 82.22058823529412],
  ["ES", 87.74567474048442],
  ["HT", 83.34602076124568],
  ["PE", 81.69031141868513],
  ["QA", 94.29757785467127],
  ["SI", 84.44809688581316],
  ["PR", 74.22145328719724],
  ["PS", 39.936851211072664],
  ["IQ", 93.24221453287197],
  ["HU", 93.18598615916956],
  ["KN", 76.64965397923875],
  ["KY", 85.1682525951557],
  ["FI", 90.26946366782008],
  ["ER", 72.57785467128028],
  ["GM", 91.70198961937717],
  ["EE", 96.87673010380622],
  ["EG", 95.25043252595155],
  ["ID", 83.67820069204153],
  ["IS", 73.89965397923875],
  ["SK", 84.35467128027682],
  ["RO", 81.59731833910034],
  ["PG", 83.40743944636678],
  ["KM", 77.76816608996539],
  ["IR", 86.83304498269896],
  ["KZ", 87.04195501730105],
  ["IE", 86.4325259515571],
  ["GN", 92.38797577854672],
  ["FJ", 88.530276816609],
  ["GY", 68.72750865051903],
  ["JM", 77.86245674740483],
  ["KI", 78.89100346020761],
  ["HR", 90.15138408304499],
  ["SO", 92.71756055363322],
  ["PT", 87.28200692041523],
  ["SY", 94.0363321799308],
  ["SN", 76.35856401384083],
  ["KH", 72.46929065743944],
  ["EC", 82.02941176470588],
  ["ET", 85.84731833910034],
  ["DE", 49.11159169550173],
  ["SL", 93.5211937716263],
  ["PW", 94.7076124567474],
  ["SZ", 83.5817474048443],
  ["PA", 75.72750865051903],
  ["SM", 90.49437716262976],
  ["IT", 76.8628892733564],
  ["JO", 40.06531141868513],
  ["GH", 68.1280276816609],
  ["CG", 82.71583044982698],
  ["BT", 92.60596885813149],
  ["AO", 87.61807958477509],
  ["ML", 82.59429065743944],
  ["TN", 79.375],
  ["LI", 81.63840830449827],
  ["MM", 90.75389273356402],
  ["MZ", 86.80363321799308],
  ["NA", 60.6401384083045],
  ["CF", 86.15873702422145],
  ["BB", 90.99134948096885],
  ["CD", 85.22448096885813],
  ["BW", 99.39230103806229],
  ["AL", 78.3053633217993],
  ["NC", 63.92387543252595],
  ["MX", 79.7288062283737],
  ["TZ", 84.33217993079585],
  ["VE", 90.67214532871972],
  ["MN", 81.22318339100346],
  ["MY", 68.72880622837371],
  ["AM", 86.7288062283737],
  ["CR", 70.65268166089965],
  ["BA", 84.45242214532873],
  ["AZ", 77.05968858131487],
  ["CV", 74.14489619377163],
  ["BR", 65.50692041522491],
  ["CA", 88.09385813148789],
  ["BE", 81.34212802768165],
  ["TH", 90.21929065743944],
  ["WS", 73.66262975778547],
  ["UZ", 93.88538062283736],
  ["Wales", 72.65743944636678],
  ["NG", 91.54238754325259],
  ["NP", 77.2015570934256],
  ["MK", 76.16089965397924],
  ["BD", 68.77119377162629],
  ["BS", 94.92517301038063],
  ["CU", 84.64316608996539],
  ["BF", 59.43468858131487],
  ["NR", 90.5575259515571],
  ["NE", 92.02595155709342],
  ["ZW", 82.55493079584775],
  ["VC", 89.84256055363322],
  ["UY", 85.78157439446366],
  ["TJ", 87.66911764705883],
  ["VU", 66.10510380622837],
  ["ZA", 85.4969723183391],
  ["BG", 91.84039792387543],
]);

export const phMap = new Map<string, number>([
  ["CY", 78.6137543252595],
  ["AF", 89.04671280276817],
  ["CN", 56.96712802768166],
  ["BJ", 67.79714532871972],
  ["LA", 72.93382352941177],
  ["ME", 63.73096885813149],
  ["OM", 80.48961937716263],
  ["NI", 86.29065743944638],
  ["MR", 93.14576124567473],
  ["TG", 91.56055363321799],
  ["TP", 55.81574394463667],
  ["North Korea", 77.56012110726644],
  ["VN", 60.955449826989614],
  ["ZM", 87.05709342560553],
  ["MD", 70.36461937716263],
  ["CO", 70.20977508650519],
  ["AG", 64.03114186851211],
  ["AE", 91.46237024221453],
  ["CZ", 59.63581314878893],
  ["AR", 87.65441176470588],
  ["BI", 75.9727508650519],
  ["CM", 81.29974048442907],
  ["LB", 57.82309688581315],
  ["LU", 86.77162629757785],
  ["TD", 79.88927335640138],
  ["TR", 57.13192041522491],
  ["Scotland", 86.96064013840831],
  ["UA", 91.91176470588235],
  ["LT", 68.99178200692042],
  ["LC", 96.11937716262976],
  ["MG", 79.52897923875433],
  ["CL", 53.37456747404844],
  ["BH", 46.47318339100346],
  ["AD", 78.55622837370242],
  ["CH", 91.42949826989619],
  ["NO", 89.13192041522493],
  ["MT", 54.70458477508651],
  ["MC", 87.81833910034602],
  ["TV", 87.04065743944636],
  ["TW", 92.8386678200692],
  ["US", 69.0916955017301],
  ["MU", 91.78330449826989],
  ["BZ", 62.50519031141869],
  ["CI", 96.23486159169549],
  ["BM", 55.6734429065744],
  ["CK", 86.40484429065744],
  ["AT", 59.56963667820069],
  ["BO", 89.67690311418686],
  ["LS", 59.44982698961938],
  ["NL", 86.75475778546713],
  ["MW", 77.07352941176471],
  ["YE", 87.70242214532871],
  ["UG", 90.65354671280276],
  ["TT", 71.30233564013841],
  ["NZ", 87.340830449827],
  ["MA", 57.90268166089966],
  ["MV", 69.04065743944636],
  ["LR", 68.8659169550173],
  ["BY", 90.90051903114187],
  ["BN", 84.06963667820068],
  ["AU", 86.23053633217992],
  ["GE", 80.12716262975779],
  ["GR", 87.61980968858131],
  ["IN", 86.14662629757785],
  ["GBENG", 74.37024221453287],
  ["RS", 85.60640138408304],
  ["PL", 50.95631487889274],
  ["SV", 58.76470588235294],
  ["SA", 89.77854671280276],
  ["HK", 60.84731833910034],
  ["KG", 59.24740484429065],
  ["GD", 75.38148788927336],
  ["GQ", 64.75778546712803],
  ["DJ", 78.76643598615917],
  ["KE", 86.99091695501731],
  ["KR", 76.78114186851211],
  ["SC", 82.0363321799308],
  ["ST", 92.74048442906575],
  ["PY", 85.1189446366782],
  ["SB", 70.3044982698962],
  ["IL", 66.34731833910035],
  ["DK", 63.36072664359862],
  ["DO", 80.22015570934255],
  ["RU", 55.28287197231834],
  ["SG", 84.47318339100346],
  ["PK", 78.58001730103807],
  ["GB", 69.32915224913495],
  ["GU", 84.62240484429066],
  ["GW", 91.07569204152249],
  ["JP", 71.50043252595155],
  ["SR", 82.25692041522491],
  ["SE", 64.97664359861591],
  ["SD", 96.11245674740483],
  ["SS", 86.01946366782008],
  ["PH", 0],
  ["RW", 93.87067474048443],
  ["KU", 66.72318339100346],
  ["HN", 86.17084775086505],
  ["GA", 89.61548442906575],
  ["DZ", 92.14705882352942],
  ["DM", 82.07482698961938],
  ["FR", 75.31617647058823],
  ["GL", 56.97967128027681],
  ["ES", 67.38538062283736],
  ["HT", 24.422577854671278],
  ["PE", 74.71193771626298],
  ["QA", 77.43901384083046],
  ["SI", 64.49048442906575],
  ["PR", 87.00043252595155],
  ["PS", 94.99048442906575],
  ["IQ", 87.16262975778547],
  ["HU", 86.60423875432525],
  ["KN", 66.41565743944636],
  ["KY", 86.4325259515571],
  ["FI", 86.36678200692042],
  ["ER", 73.3871107266436],
  ["GM", 88.1098615916955],
  ["EE", 92.93512110726644],
  ["EG", 87.6371107266436],
  ["ID", 87.79714532871972],
  ["IS", 61.0242214532872],
  ["SK", 81.14835640138408],
  ["RO", 79.78330449826989],
  ["PG", 80.9204152249135],
  ["KM", 77.23745674740485],
  ["IR", 79.58044982698962],
  ["KZ", 92.1137543252595],
  ["IE", 96.84688581314879],
  ["GN", 90.8538062283737],
  ["FJ", 86.90916955017302],
  ["GY", 94.09385813148788],
  ["JM", 88.98096885813149],
  ["KI", 84.32612456747405],
  ["HR", 80.17906574394463],
  ["SO", 97.15743944636678],
  ["PT", 90.0242214532872],
  ["SY", 87.92257785467127],
  ["SN", 79.02811418685121],
  ["KH", 62.655276816608996],
  ["EC", 65.54455017301038],
  ["ET", 54.673010380622834],
  ["DE", 92.68944636678201],
  ["SL", 87.65138408304497],
  ["PW", 95.66652249134948],
  ["SZ", 79.46280276816609],
  ["PA", 86.19809688581314],
  ["SM", 84.27205882352942],
  ["IT", 80.25562283737024],
  ["JO", 94.1734429065744],
  ["GH", 88.49783737024222],
  ["CG", 69.29584775086505],
  ["BT", 90.44852941176471],
  ["AO", 95.6280276816609],
  ["ML", 80.64576124567473],
  ["TN", 61.659602076124564],
  ["LI", 57.01903114186852],
  ["MM", 69.13148788927336],
  ["MZ", 95.35207612456747],
  ["NA", 80.47491349480968],
  ["CF", 79.40311418685121],
  ["BB", 93.4788062283737],
  ["CD", 87.94896193771626],
  ["BW", 95.5242214532872],
  ["AL", 55.044982698961945],
  ["NC", 66.28027681660899],
  ["MX", 77.72318339100346],
  ["TZ", 92.93122837370242],
  ["VE", 64.67733564013841],
  ["MN", 55.22491349480969],
  ["MY", 63.564446366782],
  ["AM", 86.76038062283737],
  ["CR", 78.34429065743944],
  ["BA", 76.08910034602077],
  ["AZ", 85.86807958477507],
  ["CV", 47.812716262975776],
  ["BR", 86.13840830449827],
  ["CA", 90.76254325259515],
  ["BE", 81.16262975778547],
  ["TH", 76.4022491349481],
  ["WS", 57.199826989619375],
  ["UZ", 83.625],
  ["Wales", 65.58823529411765],
  ["NG", 96.51470588235294],
  ["NP", 71.15354671280278],
  ["MK", 81.24567474048443],
  ["BD", 92.14100346020761],
  ["BS", 98.29714532871972],
  ["CU", 73.81358131487889],
  ["BF", 95.37802768166091],
  ["NR", 92.18512110726643],
  ["NE", 87.09299307958477],
  ["ZW", 70.74697231833909],
  ["VC", 93.45242214532871],
  ["UY", 70.30795847750865],
  ["TJ", 84.34126297577855],
  ["VU", 87.61807958477509],
  ["ZA", 91.98399653979239],
  ["BG", 65.12110726643598],
]);

export const rwMap = new Map<string, number>([
  ["CY", 93.7384755226594],
  ["AF", 86.30405142189326],
  ["CN", 91.3693026879626],
  ["BJ", 96.87118556031685],
  ["LA", 96.42935982339957],
  ["ME", 81.78353460589534],
  ["OM", 93.54953902090638],
  ["NI", 92.94377353590443],
  ["MR", 89.04395533047656],
  ["TG", 76.8046357615894],
  ["TP", 90.95474613686534],
  ["North Korea", 91.44656538111934],
  ["VN", 92.18802752889235],
  ["ZM", 93.42390598623555],
  ["MD", 86.24204648746915],
  ["CO", 97.52597065316193],
  ["AG", 93.4352681469939],
  ["AE", 95.61517984677315],
  ["CZ", 97.10167510712895],
  ["AR", 89.26048565121413],
  ["BI", 85.51681599792235],
  ["CM", 87.28282041293338],
  ["LB", 90.8693676145955],
  ["LU", 95.24477340605117],
  ["TD", 86.7371120633684],
  ["TR", 91.1858849500065],
  ["Scotland", 89.6292689261135],
  ["UA", 70.54635761589404],
  ["LT", 95.35287624983768],
  ["LC", 88.38137904168289],
  ["MG", 78.16322555512271],
  ["CL", 99.15335670692119],
  ["BH", 91.63615114920141],
  ["AD", 83.66348526165433],
  ["CH", 92.88469029996105],
  ["NO", 89.10433709907805],
  ["MT", 94.03389170237631],
  ["MC", 92.58862485391508],
  ["TV", 82.33054148811843],
  ["TW", 93.98227502921699],
  ["US", 77.22568497597715],
  ["MU", 71.96792624334502],
  ["BZ", 81.38391118036618],
  ["CI", 93.78100246721205],
  ["BM", 90.06654979872744],
  ["CK", 93.49922088040515],
  ["AT", 92.76684846123881],
  ["BO", 78.04408518374237],
  ["LS", 95.73237241916634],
  ["NL", 93.44922737306844],
  ["MW", 94.73769640306455],
  ["YE", 96.48876769250747],
  ["UG", 84.07706791325802],
  ["TT", 90.13309959745487],
  ["NZ", 94.13420335021426],
  ["MA", 93.24243604726658],
  ["MV", 93.22782755486301],
  ["LR", 90.46422542526943],
  ["BY", 88.83521620568757],
  ["BN", 88.0619400077912],
  ["AU", 92.13900792104921],
  ["GE", 92.51006362810024],
  ["GR", 91.97247110764836],
  ["IN", 90.18276847162706],
  ["GBENG", 96.49493572263343],
  ["RS", 85.31554343591742],
  ["PL", 99.33742371120634],
  ["SV", 90.86092715231788],
  ["SA", 86.79457213348915],
  ["HK", 88.80275288923517],
  ["KG", 84.74029346838073],
  ["GD", 81.02584079989612],
  ["GQ", 88.80664848720944],
  ["DJ", 89.55460329827295],
  ["KE", 91.47318530061031],
  ["KR", 96.14627970393455],
  ["SC", 86.35534346188807],
  ["ST", 76.81340085703155],
  ["PY", 93.96409557200364],
  ["SB", 65.89274120244124],
  ["IL", 95.63822880145436],
  ["DK", 93.2034800675237],
  ["DO", 92.85514868198935],
  ["RU", 99.32573691728346],
  ["SG", 87.90222049084534],
  ["PK", 97.28866380989481],
  ["GB", 85.24639657187379],
  ["GU", 92.60096091416699],
  ["GW", 96.2965848591092],
  ["JP", 97.43020386962732],
  ["SR", 74.14621477730165],
  ["SE", 83.49954551356966],
  ["SD", 93.39566290092196],
  ["SS", 89.96461498506687],
  ["PH", 93.97253603428126],
  ["RW", 0],
  ["KU", 94.36566679651993],
  ["HN", 92.8275548630048],
  ["GA", 78.40312946370601],
  ["DZ", 94.49649396182313],
  ["DM", 69.04655239579276],
  ["FR", 93.95403194390339],
  ["GL", 95.33859239059862],
  ["ES", 71.34235813530711],
  ["HT", 97.95773276197896],
  ["PE", 94.43286586157642],
  ["QA", 96.71406310868718],
  ["SI", 95.60446695234384],
  ["PR", 89.68672899623425],
  ["PS", 94.88021036229061],
  ["IQ", 93.12913907284768],
  ["HU", 95.55739514348785],
  ["KN", 91.8948837813271],
  ["KY", 91.35794052720426],
  ["FI", 98.1427736657577],
  ["ER", 79.93572263342423],
  ["GM", 67.76360212959356],
  ["EE", 95.51649136475783],
  ["EG", 93.00383067134138],
  ["ID", 92.58862485391508],
  ["IS", 93.45344760420724],
  ["SK", 94.83833268406701],
  ["RO", 86.68776782236073],
  ["PG", 91.16380989481885],
  ["KM", 92.80742760680431],
  ["IR", 86.73354109855863],
  ["KZ", 48.84917543176211],
  ["IE", 92.7035449941566],
  ["GN", 86.04759122191923],
  ["FJ", 85.18991040124659],
  ["GY", 80.84534476042073],
  ["JM", 85.89955849889624],
  ["KI", 80.22432151668615],
  ["HR", 88.16744578626152],
  ["SO", 91.3355408388521],
  ["PT", 87.22536034281262],
  ["SY", 94.55914816257629],
  ["SN", 92.93793013894299],
  ["KH", 91.20309050772627],
  ["EC", 92.36300480457083],
  ["ET", 76.29171536164134],
  ["DE", 89.31242695753798],
  ["SL", 92.43604726658874],
  ["PW", 83.20640176600442],
  ["SZ", 89.1562784054019],
  ["PA", 91.24009868848202],
  ["SM", 91.48487209453317],
  ["IT", 94.09329957148422],
  ["JO", 93.75795351253085],
  ["GH", 79.57635372029607],
  ["CG", 92.51785482404883],
  ["BT", 86.53032073756654],
  ["AO", 90.7047786001818],
  ["ML", 86.25081158291131],
  ["TN", 90.0512920399948],
  ["LI", 98.4703285287625],
  ["MM", 92.8720296065446],
  ["MZ", 85.7424360472666],
  ["NA", 89.22899623425529],
  ["CF", 98.54531878976756],
  ["BB", 97.87722373717699],
  ["CD", 88.86605635631736],
  ["BW", 91.92020516815998],
  ["AL", 90.92228282041293],
  ["NC", 90.12595766783534],
  ["MX", 88.80015582391897],
  ["TZ", 89.36242046487469],
  ["VE", 96.62413972211401],
  ["MN", 88.08109336449812],
  ["MY", 88.38105440851838],
  ["AM", 96.89001428385924],
  ["CR", 95.49214387741851],
  ["BA", 91.07972990520712],
  ["AZ", 62.960329827295155],
  ["CV", 86.96954940916764],
  ["BR", 84.79028697571745],
  ["CA", 90.71451759511751],
  ["BE", 94.38709258537853],
  ["TH", 98.221010258408],
  ["WS", 94.86138163874821],
  ["UZ", 91.88352162056876],
  ["Wales", 87.10264900662251],
  ["NG", 94.19426048565121],
  ["NP", 89.84060511621867],
  ["MK", 76.35729126087521],
  ["BD", 90.5229840280483],
  ["BS", 68.79723412543825],
  ["CU", 93.77678223607325],
  ["BF", 92.70516815997922],
  ["NR", 94.50460979093624],
  ["NE", 93.00090897286066],
  ["ZW", 87.17536683547591],
  ["VC", 84.58446954940916],
  ["UY", 93.11647837943124],
  ["TJ", 91.21510193481366],
  ["VU", 88.96701727048435],
  ["ZA", 86.33456693935852],
  ["BG", 97.84281262173744],
]);

export const kuMap = new Map<string, number>([
  ["CY", 79.96453287197231],
  ["AF", 76.69593425605537],
  ["CN", 67.9113321799308],
  ["BJ", 67.08088235294117],
  ["LA", 65.53892733564014],
  ["ME", 71.37629757785467],
  ["OM", 93.61937716262976],
  ["NI", 73.5575259515571],
  ["MR", 66.63019031141869],
  ["TG", 81.66695501730104],
  ["TP", 55.714965397923876],
  ["North Korea", 91.22491349480968],
  ["VN", 68.09515570934256],
  ["ZM", 90.59688581314879],
  ["MD", 81.47923875432525],
  ["CO", 72.6492214532872],
  ["AG", 70.18987889273356],
  ["AE", 69.3719723183391],
  ["CZ", 58.27854671280277],
  ["AR", 77.1068339100346],
  ["BI", 70.07439446366782],
  ["CM", 84.06833910034602],
  ["LB", 57.14143598615917],
  ["LU", 72.780276816609],
  ["TD", 82.84342560553634],
  ["TR", 68.24567474048443],
  ["Scotland", 82.46150519031143],
  ["UA", 98.02076124567473],
  ["LT", 70.87846020761246],
  ["LC", 97.4022491349481],
  ["MG", 96.73183391003461],
  ["CL", 57.267733564013845],
  ["BH", 74.32742214532871],
  ["AD", 81.96885813148789],
  ["CH", 83.31358131487889],
  ["NO", 86.93166089965399],
  ["MT", 74.5151384083045],
  ["MC", 86.38321799307958],
  ["TV", 86.62240484429066],
  ["TW", 93.89965397923875],
  ["US", 68.7387543252595],
  ["MU", 95.78589965397923],
  ["BZ", 72.22231833910034],
  ["CI", 88.52205882352942],
  ["BM", 63.13927335640138],
  ["CK", 87.41306228373702],
  ["AT", 46.07871972318339],
  ["BO", 93.77119377162629],
  ["LS", 75.33996539792388],
  ["NL", 72.41565743944636],
  ["MW", 90.71842560553634],
  ["YE", 69.39749134948097],
  ["UG", 89.1734429065744],
  ["TT", 76.4576124567474],
  ["NZ", 89.03979238754324],
  ["MA", 69.83001730103805],
  ["MV", 67.76730103806229],
  ["LR", 70.02897923875433],
  ["BY", 97.4727508650519],
  ["BN", 77.95242214532871],
  ["AU", 87.86332179930795],
  ["GE", 87.67257785467129],
  ["GR", 82.42084775086505],
  ["IN", 75.72318339100346],
  ["GBENG", 87.33910034602076],
  ["RS", 90.03719723183391],
  ["PL", 58.30147058823529],
  ["SV", 73.61418685121107],
  ["SA", 67.21712802768167],
  ["HK", 64.02162629757785],
  ["KG", 64.07266435986159],
  ["GD", 77.2227508650519],
  ["GQ", 45.918252595155714],
  ["DJ", 91.17776816608996],
  ["KE", 88.47318339100346],
  ["KR", 82.93166089965398],
  ["SC", 80.16306228373702],
  ["ST", 91.1916089965398],
  ["PY", 73.64100346020761],
  ["SB", 91.29195501730104],
  ["IL", 76.05968858131487],
  ["DK", 60.686418685121104],
  ["DO", 71.32006920415225],
  ["RU", 70.65397923875433],
  ["SG", 84.17820069204153],
  ["PK", 94.50432525951558],
  ["GB", 73.62283737024221],
  ["GU", 86.94982698961937],
  ["GW", 86.9545847750865],
  ["JP", 83.71626297577855],
  ["SR", 94.97015570934256],
  ["SE", 94.98832179930797],
  ["SD", 74.27811418685121],
  ["SS", 93.9666955017301],
  ["PH", 66.72318339100346],
  ["RW", 93.7750865051903],
  ["KU", 0],
  ["HN", 73.61115916955018],
  ["GA", 93.75475778546712],
  ["DZ", 67.43901384083046],
  ["DM", 64.56790657439446],
  ["FR", 74.6362456747405],
  ["GL", 61.68987889273356],
  ["ES", 70.82266435986159],
  ["HT", 66.73313148788928],
  ["PE", 69.81271626297578],
  ["QA", 95.1159169550173],
  ["SI", 70.04498269896193],
  ["PR", 84.84256055363322],
  ["PS", 74.19117647058823],
  ["IQ", 72.32352941176471],
  ["HU", 72.67690311418686],
  ["KN", 70.72145328719724],
  ["KY", 88.54584775086505],
  ["FI", 96.03849480968859],
  ["ER", 84.82785467128028],
  ["GM", 89.8970588235294],
  ["EE", 90.74740484429066],
  ["EG", 71.01427335640138],
  ["ID", 86.38321799307958],
  ["IS", 84.45285467128028],
  ["SK", 94.59602076124567],
  ["RO", 82.88451557093425],
  ["PG", 73.07525951557093],
  ["KM", 81.10164359861591],
  ["IR", 67.70631487889274],
  ["KZ", 92.90484429065744],
  ["IE", 87.07698961937716],
  ["GN", 89.76946366782008],
  ["FJ", 90.06055363321799],
  ["GY", 88.71150519031143],
  ["JM", 77.70934256055362],
  ["KI", 83.7067474048443],
  ["HR", 69.93901384083046],
  ["SO", 93.32828719723183],
  ["PT", 89.28287197231833],
  ["SY", 71.21150519031141],
  ["SN", 77.59385813148789],
  ["KH", 85.63105536332179],
  ["EC", 70.10899653979239],
  ["ET", 40.304930795847746],
  ["DE", 95.73053633217992],
  ["SL", 72.75519031141869],
  ["PW", 97.39489619377163],
  ["SZ", 82.00778546712803],
  ["PA", 83.83564013840831],
  ["SM", 88.73053633217994],
  ["IT", 69.85510380622837],
  ["JO", 76.4325259515571],
  ["GH", 92.28157439446368],
  ["CG", 63.27897923875433],
  ["BT", 90.29455017301038],
  ["AO", 87.48269896193773],
  ["ML", 84.96453287197232],
  ["TN", 63.7166955017301],
  ["LI", 67.06790657439447],
  ["MM", 65.33564013840831],
  ["MZ", 94.2387543252595],
  ["NA", 82.41176470588235],
  ["CF", 81.28979238754324],
  ["BB", 92.72707612456747],
  ["CD", 92.00519031141869],
  ["BW", 89.48529411764706],
  ["AL", 67.75475778546712],
  ["NC", 92.3174740484429],
  ["MX", 73.3538062283737],
  ["TZ", 90.60250865051903],
  ["VE", 69.11634948096885],
  ["MN", 81.12846020761245],
  ["MY", 70.34645328719724],
  ["AM", 98.45112456747404],
  ["CR", 96.46929065743944],
  ["BA", 86.79887543252596],
  ["AZ", 96.33607266435986],
  ["CV", 87.89230103806229],
  ["BR", 90.50519031141869],
  ["CA", 89.23269896193771],
  ["BE", 70.51643598615917],
  ["TH", 84.70717993079585],
  ["WS", 66.5826124567474],
  ["UZ", 70.57266435986159],
  ["Wales", 80.70112456747405],
  ["NG", 69.83953287197232],
  ["NP", 66.88840830449827],
  ["MK", 82.94896193771626],
  ["BD", 70.58650519031143],
  ["BS", 76.18555363321799],
  ["CU", 87.65960207612457],
  ["BF", 96.91435986159169],
  ["NR", 93.93252595155708],
  ["NE", 78.21453287197232],
  ["ZW", 92.77811418685121],
  ["VC", 92.6704152249135],
  ["UY", 87.33088235294117],
  ["TJ", 71.48832179930797],
  ["VU", 83.66782006920415],
  ["ZA", 75.06574394463668],
  ["BG", 70.88235294117648],
]);

export const hnMap = new Map<string, number>([
  ["CY", 71.0583910034602],
  ["AF", 93.0060553633218],
  ["CN", 95.83434256055364],
  ["BJ", 94.95242214532873],
  ["LA", 89.09515570934255],
  ["ME", 91.28373702422145],
  ["OM", 95.35250865051903],
  ["NI", 1.7361591695501732],
  ["MR", 95.67301038062284],
  ["TG", 91.89489619377163],
  ["TP", 92.74610726643598],
  ["North Korea", 92.64057093425605],
  ["VN", 95.42993079584775],
  ["ZM", 94.12889273356402],
  ["MD", 93.75735294117648],
  ["CO", 92.90743944636678],
  ["AG", 92.98572664359862],
  ["AE", 75.03719723183391],
  ["CZ", 87.08044982698962],
  ["AR", 69.32179930795847],
  ["BI", 82.36375432525952],
  ["CM", 95.30276816608996],
  ["LB", 71.13494809688581],
  ["LU", 66.78806228373702],
  ["TD", 94.48140138408304],
  ["TR", 93.40527681660899],
  ["Scotland", 49.89965397923876],
  ["UA", 63.35640138408305],
  ["LT", 98.9234429065744],
  ["LC", 96.98010380622837],
  ["MG", 87.50519031141869],
  ["CL", 87.47404844290656],
  ["BH", 86.12932525951557],
  ["AD", 94.69636678200692],
  ["CH", 84.21193771626298],
  ["NO", 86.44506920415225],
  ["MT", 82.08607266435986],
  ["MC", 82.98140138408304],
  ["TV", 85.93295847750865],
  ["TW", 97.52768166089966],
  ["US", 78.83347750865052],
  ["MU", 94.90354671280276],
  ["BZ", 81.74740484429066],
  ["CI", 88.96107266435986],
  ["BM", 88.35510380622837],
  ["CK", 88.34039792387543],
  ["AT", 66.78806228373702],
  ["BO", 90.84126297577855],
  ["LS", 69.8166089965398],
  ["NL", 66.78806228373702],
  ["MW", 95.31055363321799],
  ["YE", 67.08217993079585],
  ["UG", 94.37543252595155],
  ["TT", 92.75519031141869],
  ["NZ", 90.08304498269895],
  ["MA", 98.57482698961938],
  ["MV", 95.54022491349481],
  ["LR", 83.1159169550173],
  ["BY", 92.4113321799308],
  ["BN", 81.60467128027682],
  ["AU", 92.77032871972318],
  ["GE", 83.75043252595155],
  ["GR", 42.54757785467128],
  ["IN", 68.8659169550173],
  ["GBENG", 87.3416955017301],
  ["RS", 90.07352941176471],
  ["PL", 83.00778546712803],
  ["SV", 68.22102076124568],
  ["SA", 91.37586505190312],
  ["HK", 91.31012110726644],
  ["KG", 90.02508650519032],
  ["GD", 87.15527681660899],
  ["GQ", 68.1280276816609],
  ["DJ", 84.12326989619378],
  ["KE", 92.84299307958477],
  ["KR", 75.14576124567473],
  ["SC", 82.43641868512111],
  ["ST", 86.5977508650519],
  ["PY", 68.61851211072664],
  ["SB", 88.90181660899654],
  ["IL", 69.87975778546712],
  ["DK", 83.49870242214533],
  ["DO", 77.94766435986159],
  ["RU", 99],
  ["SG", 79.21410034602077],
  ["PK", 85.60899653979239],
  ["GB", 73.29671280276817],
  ["GU", 93.62283737024222],
  ["GW", 94.65960207612457],
  ["JP", 76.81963667820068],
  ["SR", 96.35769896193771],
  ["SE", 94.96020761245674],
  ["SD", 74.08996539792388],
  ["SS", 92.66176470588235],
  ["PH", 86.17084775086505],
  ["RW", 92.24480968858131],
  ["KU", 73.61115916955018],
  ["HN", 0],
  ["GA", 90.55968858131487],
  ["DZ", 81.96539792387543],
  ["DM", 89.61029411764706],
  ["FR", 89.06877162629758],
  ["GL", 81.19593425605537],
  ["ES", 89.67214532871972],
  ["HT", 96.64965397923876],
  ["PE", 89.16349480968859],
  ["QA", 85.98615916955016],
  ["SI", 96.81098615916956],
  ["PR", 83.0787197231834],
  ["PS", 73.96193771626297],
  ["IQ", 69.49437716262976],
  ["HU", 66.78806228373702],
  ["KN", 92.09429065743944],
  ["KY", 89.20328719723183],
  ["FI", 94.1029411764706],
  ["ER", 85.72058823529412],
  ["GM", 88.24956747404843],
  ["EE", 99.70588235294117],
  ["EG", 68.60683391003461],
  ["ID", 82.98053633217994],
  ["IS", 87.39489619377163],
  ["SK", 95.41868512110727],
  ["RO", 94.49870242214533],
  ["PG", 93.09861591695501],
  ["KM", 82.65787197231835],
  ["IR", 59.46626297577855],
  ["KZ", 90.5242214532872],
  ["IE", 88.97015570934256],
  ["GN", 94.59429065743944],
  ["FJ", 89.02032871972318],
  ["GY", 87.0333044982699],
  ["JM", 90.02595155709344],
  ["KI", 83.07309688581314],
  ["HR", 64.91608996539793],
  ["SO", 94.34429065743944],
  ["PT", 92.41955017301038],
  ["SY", 68.91652249134948],
  ["SN", 93.69766435986159],
  ["KH", 91.50173010380624],
  ["EC", 89.97750865051903],
  ["ET", 86.57352941176471],
  ["DE", 99.2279411764706],
  ["SL", 33.846885813148795],
  ["PW", 92.74524221453288],
  ["SZ", 88.01081314878893],
  ["PA", 81.30839100346022],
  ["SM", 83.9757785467128],
  ["IT", 89.06833910034602],
  ["JO", 76.82396193771626],
  ["GH", 89.97923875432527],
  ["CG", 91.39057093425606],
  ["BT", 90.08693771626297],
  ["AO", 96.625],
  ["ML", 94.49134948096885],
  ["TN", 91.91522491349481],
  ["LI", 96.61634948096885],
  ["MM", 90.25475778546713],
  ["MZ", 93.00086505190312],
  ["NA", 87.89835640138408],
  ["CF", 82.98615916955018],
  ["BB", 94.41911764705883],
  ["CD", 92.14878892733564],
  ["BW", 91.48010380622837],
  ["AL", 95.3871107266436],
  ["NC", 92.8568339100346],
  ["MX", 92.47750865051904],
  ["TZ", 93.13927335640139],
  ["VE", 97.70328719723183],
  ["MN", 94.24394463667821],
  ["MY", 80.36505190311418],
  ["AM", 99.00951557093425],
  ["CR", 97.3075259515571],
  ["BA", 89.04584775086505],
  ["AZ", 97.03546712802769],
  ["CV", 88.7439446366782],
  ["BR", 90.70588235294117],
  ["CA", 89.98659169550173],
  ["BE", 94.80320069204153],
  ["TH", 99.35250865051903],
  ["WS", 96.0817474048443],
  ["UZ", 63.94031141868513],
  ["Wales", 79.15787197231833],
  ["NG", 89.16349480968859],
  ["NP", 76.53849480968859],
  ["MK", 83.80363321799308],
  ["BD", 94.57785467128028],
  ["BS", 90.9182525951557],
  ["CU", 85.27465397923876],
  ["BF", 97.25346020761245],
  ["NR", 95.50951557093425],
  ["NE", 71.84861591695501],
  ["ZW", 89.04195501730104],
  ["VC", 75.8529411764706],
  ["UY", 83.28287197231833],
  ["TJ", 66.84515570934256],
  ["VU", 93.35294117647058],
  ["ZA", 87.21280276816609],
  ["BG", 98.57828719723183],
]);

export const gaMap = new Map<string, number>([
  ["CY", 93.46799307958477],
  ["AF", 92.23558246828144],
  ["CN", 96.09948096885813],
  ["BJ", 75.909169550173],
  ["LA", 97.91349480968859],
  ["ME", 87.46828143021914],
  ["OM", 96.00576701268743],
  ["NI", 91.40974625144175],
  ["MR", 93.87514417531719],
  ["TG", 86.7136678200692],
  ["TP", 92.09602076124568],
  ["North Korea", 94.85928489042675],
  ["VN", 96.33996539792388],
  ["ZM", 94.49509803921569],
  ["MD", 91.2136678200692],
  ["CO", 82.49423298731257],
  ["AG", 94.2863321799308],
  ["AE", 71.26095732410612],
  ["CZ", 96.01355247981546],
  ["AR", 91.89677047289504],
  ["BI", 88.34861591695503],
  ["CM", 85.9850057670127],
  ["LB", 94.8558246828143],
  ["LU", 96.790369088812],
  ["TD", 88.24740484429066],
  ["TR", 95.29613610149941],
  ["Scotland", 90.54382929642445],
  ["UA", 82.85178777393311],
  ["LT", 99.50144175317186],
  ["LC", 95.81286043829297],
  ["MG", 98.80911188004615],
  ["CL", 97.28921568627452],
  ["BH", 95.84602076124568],
  ["AD", 89.51038062283737],
  ["CH", 96.2148212226067],
  ["NO", 91.12053056516724],
  ["MT", 98.10841983852364],
  ["MC", 96.2883506343714],
  ["TV", 86.4475201845444],
  ["TW", 98.08333333333333],
  ["US", 82.11043829296425],
  ["MU", 82.68829296424452],
  ["BZ", 84.24365628604383],
  ["CI", 86.71136101499424],
  ["BM", 90.48039215686275],
  ["CK", 93.92675893886967],
  ["AT", 93.67820069204153],
  ["BO", 66.19405997693195],
  ["LS", 98.27825836216839],
  ["NL", 94.02306805074971],
  ["MW", 96.2358708189158],
  ["YE", 97.23241061130335],
  ["UG", 95.59342560553632],
  ["TT", 94.01297577854672],
  ["NZ", 94.02825836216839],
  ["MA", 98.97347174163784],
  ["MV", 96.5928489042676],
  ["LR", 90.66061130334487],
  ["BY", 92.87226066897347],
  ["BN", 86.89186851211073],
  ["AU", 92.98010380622837],
  ["GE", 93.76326412918108],
  ["GR", 90.8255478662053],
  ["IN", 91.39388696655134],
  ["GBENG", 96.1542675893887],
  ["RS", 89.08621683967705],
  ["PL", 98.0896770472895],
  ["SV", 89.11562860438293],
  ["SA", 91.39850057670127],
  ["HK", 91.73615916955016],
  ["KG", 88.76153402537486],
  ["GD", 86.04094579008074],
  ["GQ", 89.37745098039215],
  ["DJ", 93.57122260668973],
  ["KE", 93.59515570934256],
  ["KR", 96.4083044982699],
  ["SC", 89.72722029988466],
  ["ST", 72.66753171856978],
  ["PY", 94.83852364475202],
  ["SB", 87.87283737024222],
  ["IL", 95.67848904267589],
  ["DK", 96.52998846597463],
  ["DO", 94.7419261822376],
  ["RU", 99.50144175317186],
  ["SG", 92.00749711649365],
  ["PK", 96.38552479815455],
  ["GB", 84.040369088812],
  ["GU", 94.10207612456747],
  ["GW", 87.6732987312572],
  ["JP", 96.91291810841984],
  ["SR", 95.75865051903114],
  ["SE", 77.33708189158016],
  ["SD", 93.13119953863898],
  ["SS", 93.13379469434832],
  ["PH", 89.42214532871972],
  ["RW", 78.32151095732411],
  ["KU", 94.00461361014995],
  ["HN", 90.61822376009228],
  ["GA", 0],
  ["DZ", 94.83073817762399],
  ["DM", 88.74221453287198],
  ["FR", 98.13696655132641],
  ["GL", 95.06487889273356],
  ["ES", 69.11159169550173],
  ["HT", 97.01182237600922],
  ["PE", 97.91897347174164],
  ["QA", 96.20934256055364],
  ["SI", 97.25720876585927],
  ["PR", 91.58391003460208],
  ["PS", 94.68944636678201],
  ["IQ", 92.57179930795849],
  ["HU", 94.06891580161476],
  ["KN", 73.4728950403691],
  ["KY", 91.90109573241061],
  ["FI", 96.46539792387543],
  ["ER", 65.15253748558247],
  ["GM", 92.14734717416378],
  ["EE", 99.31141868512111],
  ["EG", 92.32843137254902],
  ["ID", 96.2883506343714],
  ["IS", 91.45501730103807],
  ["SK", 95.48904267589388],
  ["RO", 87.98212226066897],
  ["PG", 93.5888119953864],
  ["KM", 70.54440599769319],
  ["IR", 85.03056516724337],
  ["KZ", 86.77306805074971],
  ["IE", 87.10092272202998],
  ["GN", 76.81689734717416],
  ["FJ", 87.43829296424452],
  ["GY", 60.343425605536325],
  ["JM", 84.52104959630911],
  ["KI", 83.93021914648212],
  ["HR", 87.06805074971165],
  ["SO", 63.66349480968858],
  ["PT", 91.48644752018454],
  ["SY", 94.87802768166091],
  ["SN", 96.3941753171857],
  ["KH", 92.73731257208766],
  ["EC", 79.77277970011534],
  ["ET", 68.88725490196079],
  ["DE", 99.74740484429066],
  ["SL", 94.4636678200692],
  ["PW", 84.1522491349481],
  ["SZ", 74.44319492502883],
  ["PA", 94.94059976931949],
  ["SM", 90.45040369088811],
  ["IT", 87.29382929642445],
  ["JO", 93.70530565167243],
  ["GH", 68.5363321799308],
  ["CG", 77.85640138408304],
  ["BT", 76.4212802768166],
  ["AO", 95.96078431372548],
  ["ML", 87.28690888119954],
  ["TN", 93.52047289504037],
  ["LI", 97.90657439446366],
  ["MM", 96.54901960784314],
  ["MZ", 94.13581314878893],
  ["NA", 87.57179930795847],
  ["CF", 95.49711649365629],
  ["BB", 92.16753171856979],
  ["CD", 90.60380622837371],
  ["BW", 93.89878892733564],
  ["AL", 95.61880046136102],
  ["NC", 88.09688581314879],
  ["MX", 93.00144175317186],
  ["TZ", 91.27277970011534],
  ["VE", 98.00144175317186],
  ["MN", 92.66118800461362],
  ["MY", 87.00490196078431],
  ["AM", 99.21222606689734],
  ["CR", 97.22001153402537],
  ["BA", 89.19146482122261],
  ["AZ", 97.33304498269895],
  ["CV", 91.54642445213379],
  ["BR", 84.25461361014995],
  ["CA", 93.47289504036908],
  ["BE", 98.79267589388697],
  ["TH", 99.6874279123414],
  ["WS", 98.01384083044982],
  ["UZ", 91.00980392156863],
  ["Wales", 86.80478662053056],
  ["NG", 76.1300461361015],
  ["NP", 88.24971164936562],
  ["MK", 75.25288350634372],
  ["BD", 95.02479815455594],
  ["BS", 95.45242214532873],
  ["CU", 92.28950403690888],
  ["BF", 96.88783160322953],
  ["NR", 90.65801614763552],
  ["NE", 93.82439446366782],
  ["ZW", 91.89244521337947],
  ["VC", 77.57727797001154],
  ["UY", 92.30709342560553],
  ["TJ", 92.62024221453288],
  ["VU", 90.30017301038062],
  ["ZA", 89.49942329873126],
  ["BG", 99.30565167243368],
]);

export const dzMap = new Map<string, number>([
  ["CY", 54.01917595231925],
  ["AF", 90.66338429644986],
  ["CN", 92.09380668566986],
  ["BJ", 93.39952060119202],
  ["LA", 92.83979010106245],
  ["ME", 92.43327286861881],
  ["OM", 74.38455558434828],
  ["NI", 82.2871857994299],
  ["MR", 51.37406063747084],
  ["TG", 79.5018139414356],
  ["TP", 89.00783881834671],
  ["North Korea", 89.33046125939363],
  ["VN", 92.68171806167402],
  ["ZM", 95.11142783104431],
  ["MD", 92.51846333246955],
  ["CO", 95.14770665975641],
  ["AG", 88.18346721948691],
  ["AE", 82.40152889349572],
  ["CZ", 69.10695776107801],
  ["AR", 84.43022803835191],
  ["BI", 79.66701217932108],
  ["CM", 91.26263280642654],
  ["LB", 75.65852552474735],
  ["LU", 82.06303446488728],
  ["TD", 95.02591344908006],
  ["TR", 90.69156517232445],
  ["Scotland", 75.29346981083182],
  ["UA", 94.90541591085774],
  ["LT", 82.6078647317958],
  ["LC", 96.62347758486655],
  ["MG", 89.68774293858513],
  ["CL", 70.6471883907748],
  ["BH", 90.05312257061415],
  ["AD", 94.23944026949988],
  ["CH", 89.83706918890904],
  ["NO", 87.45367970976937],
  ["MT", 97.56640321326768],
  ["MC", 72.1048199015289],
  ["TV", 87.43489245918632],
  ["TW", 90.3887017362011],
  ["US", 65.11531484840633],
  ["MU", 94.9261466701218],
  ["BZ", 84.48367452707956],
  ["CI", 85.34076185540296],
  ["BM", 85.03012438455558],
  ["CK", 87.68819642394404],
  ["AT", 81.68890904379373],
  ["BO", 79.51768592899715],
  ["LS", 80.8185410728168],
  ["NL", 82.54955947136564],
  ["MW", 88.0011013215859],
  ["YE", 82.90133454262762],
  ["UG", 91.19039906711583],
  ["TT", 90.10073853329878],
  ["NZ", 88.6806815237108],
  ["MA", 90.82825861622183],
  ["MV", 84.79560767038092],
  ["LR", 73.88248250842187],
  ["BY", 89.641098730241],
  ["BN", 81.08674527079555],
  ["AU", 85.80526043016326],
  ["GE", 61.76794506348795],
  ["GR", 73.90483285825343],
  ["IN", 83.12742938585126],
  ["GBENG", 60.00906970717802],
  ["RS", 74.34179839336615],
  ["PL", 72.23438714692925],
  ["SV", 82.14692925628401],
  ["SA", 50.84251101321586],
  ["HK", 92.4271184244623],
  ["KG", 91.03232702772739],
  ["GD", 87.30597305001295],
  ["GQ", 78.9268592899715],
  ["DJ", 96.11719357346463],
  ["KE", 88.9608706918891],
  ["KR", 53.63209380668567],
  ["SC", 73.74740865509199],
  ["ST", 91.31737496760819],
  ["PY", 81.57748121274942],
  ["SB", 85.07676859289971],
  ["IL", 66.79709769370304],
  ["DK", 90.93547551179061],
  ["DO", 83.54981860585644],
  ["RU", 79.29580202124903],
  ["SG", 68.11965535112724],
  ["PK", 92.23535890126976],
  ["GB", 71.41552215599897],
  ["GU", 92.44720134749936],
  ["GW", 92.27325732054936],
  ["JP", 50.80882352941176],
  ["SR", 84.63040943249547],
  ["SE", 96.11654573723763],
  ["SD", 64.74280901788028],
  ["SS", 84.4622959315885],
  ["PH", 92.14790101062451],
  ["RW", 94.7204586680487],
  ["KU", 67.69823788546255],
  ["HN", 82.58195128271572],
  ["GA", 95.10235812386628],
  ["DZ", 0],
  ["DM", 54.64725317439751],
  ["FR", 85.12470847369785],
  ["GL", 70.43145892718321],
  ["ES", 92.45044052863436],
  ["HT", 92.85047939880798],
  ["PE", 85.27176729722726],
  ["QA", 92.87542109354754],
  ["SI", 76.98658979010106],
  ["PR", 72.43132935993782],
  ["PS", 68.16662347758486],
  ["IQ", 82.34419538740606],
  ["HU", 81.93119979269241],
  ["KN", 88.90580461259394],
  ["KY", 87.90230629696812],
  ["FI", 60.97045866804872],
  ["ER", 82.01865768333765],
  ["GM", 89.98736719357346],
  ["EE", 81.47641876133713],
  ["EG", 83.2161829489505],
  ["ID", 72.20976937030319],
  ["IS", 87.84302928219746],
  ["SK", 75.177831044312],
  ["RO", 95.0375745011661],
  ["PG", 90.4968903861104],
  ["KM", 76.99274423425759],
  ["IR", 77.02189686447267],
  ["KZ", 91.2150168437419],
  ["IE", 85.27176729722726],
  ["GN", 95.0375745011661],
  ["FJ", 89.21320290230629],
  ["GY", 83.217154703291],
  ["JM", 87.53077222078258],
  ["KI", 81.61959056750453],
  ["HR", 80.15224151334543],
  ["SO", 93.98160145115315],
  ["PT", 90.3265094584089],
  ["SY", 82.97680746307333],
  ["SN", 94.40301891681783],
  ["KH", 91.27461777662606],
  ["EC", 92.16895568800207],
  ["ET", 90.1800984711065],
  ["DE", 94.37872505830526],
  ["SL", 82.14757709251101],
  ["PW", 97.61142783104432],
  ["SZ", 85.9630085514382],
  ["PA", 73.83681005441825],
  ["SM", 74.26600155480695],
  ["IT", 85.27176729722726],
  ["JO", 67.29787509717544],
  ["GH", 78.79664420834413],
  ["CG", 92.30564913189946],
  ["BT", 90.69480435345945],
  ["AO", 91.47155998963463],
  ["ML", 95.0712619849702],
  ["TN", 92.20912153407619],
  ["LI", 90.29508940139934],
  ["MM", 92.5330396475771],
  ["MZ", 86.01256802280383],
  ["NA", 84.32333506089661],
  ["CF", 83.26120756672714],
  ["BB", 94.36803576055974],
  ["CD", 89.3968644726613],
  ["BW", 95.29379372894532],
  ["AL", 91.91532780513086],
  ["NC", 91.96812645763151],
  ["MX", 52.79994817310184],
  ["TZ", 90.68573464628142],
  ["VE", 92.56964239440269],
  ["MN", 90.55325213785956],
  ["MY", 65.72071780253953],
  ["AM", 95.52409950764446],
  ["CR", 78.25667271313812],
  ["BA", 88.2855014252397],
  ["AZ", 92.7844001036538],
  ["CV", 85.93061674008811],
  ["BR", 89.22389220005182],
  ["CA", 76.52921741383778],
  ["BE", 94.81212749416947],
  ["TH", 81.3931718061674],
  ["WS", 90.12503239181136],
  ["UZ", 79.68482767556362],
  ["Wales", 64.71462814200571],
  ["NG", 85.16131122052344],
  ["NP", 52.679126716766],
  ["MK", 81.88066856698627],
  ["BD", 57.58292303705623],
  ["BS", 90.78323399844518],
  ["CU", 73.07916558693962],
  ["BF", 92.88125161959057],
  ["NR", 92.87185799429905],
  ["NE", 82.14952060119202],
  ["ZW", 91.16189427312776],
  ["VC", 93.1779606115574],
  ["UY", 70.66824306815236],
  ["TJ", 78.97965794247214],
  ["VU", 85.09847110650428],
  ["ZA", 69.37289453226224],
  ["BG", 79.826703809277],
]);

export const dmMap = new Map<string, number>([
  ["CY", 84.86634948096886],
  ["AF", 85.97102076124568],
  ["CN", 87.29887543252595],
  ["BJ", 59.2318339100346],
  ["LA", 93.15138408304499],
  ["ME", 86.17993079584775],
  ["OM", 82.95285467128028],
  ["NI", 89.41868512110727],
  ["MR", 27.00735294117647],
  ["TG", 63.42906574394463],
  ["TP", 83.78243944636678],
  ["North Korea", 85.62889273356402],
  ["VN", 93.01340830449827],
  ["ZM", 85.33564013840831],
  ["MD", 88.51643598615917],
  ["CO", 86.01038062283737],
  ["AG", 90.12716262975779],
  ["AE", 91.72837370242215],
  ["CZ", 88.56833910034602],
  ["AR", 91.42257785467129],
  ["BI", 88.17647058823529],
  ["CM", 62.094723183391],
  ["LB", 86.48226643598616],
  ["LU", 89.94031141868513],
  ["TD", 93.24005190311418],
  ["TR", 87.1068339100346],
  ["Scotland", 88.6682525951557],
  ["UA", 91.17430795847751],
  ["LT", 84.54801038062284],
  ["LC", 96.18901384083046],
  ["MG", 68.35726643598616],
  ["CL", 90.25389273356402],
  ["BH", 83.18728373702422],
  ["AD", 89.94290657439447],
  ["CH", 93.2871972318339],
  ["NO", 82.02811418685121],
  ["MT", 89.73659169550173],
  ["MC", 86.1470588235294],
  ["TV", 85.49048442906575],
  ["TW", 86.93295847750865],
  ["US", 76.73226643598616],
  ["MU", 90.38667820069205],
  ["BZ", 84.65051903114187],
  ["CI", 94.84299307958477],
  ["BM", 80.2257785467128],
  ["CK", 81.49307958477509],
  ["AT", 89.77681660899654],
  ["BO", 60.17733564013841],
  ["LS", 89.85942906574394],
  ["NL", 89.7863321799308],
  ["MW", 83.76254325259515],
  ["YE", 90.02292387543253],
  ["UG", 88.26773356401384],
  ["TT", 92.11505190311419],
  ["NZ", 80.94463667820068],
  ["MA", 87.8901384083045],
  ["MV", 78.66955017301038],
  ["LR", 90.3507785467128],
  ["BY", 81.90873702422145],
  ["BN", 82.5121107266436],
  ["AU", 83.60726643598616],
  ["GE", 86.7962802768166],
  ["GR", 86.10164359861592],
  ["IN", 90.48140138408304],
  ["GBENG", 88.37586505190312],
  ["RS", 84.06790657439447],
  ["PL", 92.375],
  ["SV", 89.00129757785467],
  ["SA", 30.996107266435985],
  ["HK", 92.87716262975778],
  ["KG", 91.3689446366782],
  ["GD", 79.02854671280278],
  ["GQ", 87.80882352941177],
  ["DJ", 91.17993079584775],
  ["KE", 87.3961937716263],
  ["KR", 79.9424740484429],
  ["SC", 66.34904844290658],
  ["ST", 88.02378892733564],
  ["PY", 87.63321799307958],
  ["SB", 83.39143598615917],
  ["IL", 86.52681660899654],
  ["DK", 89.53460207612457],
  ["DO", 86.31617647058823],
  ["RU", 88.12456747404845],
  ["SG", 82.35423875432527],
  ["PK", 87.09991349480968],
  ["GB", 73.91176470588235],
  ["GU", 86.6038062283737],
  ["GW", 87.47188581314879],
  ["JP", 79.71842560553634],
  ["SR", 90.30839100346022],
  ["SE", 88.56401384083044],
  ["SD", 82.21539792387543],
  ["SS", 64.58953287197232],
  ["PH", 82.07482698961938],
  ["RW", 68.68382352941177],
  ["KU", 64.56790657439446],
  ["HN", 89.61029411764706],
  ["GA", 88.2530276816609],
  ["DZ", 54.576557093425606],
  ["DM", 0],
  ["FR", 94.29887543252595],
  ["GL", 89.11678200692042],
  ["ES", 89.31271626297578],
  ["HT", 89.17776816608996],
  ["PE", 95.28589965397923],
  ["QA", 83.69420415224913],
  ["SI", 87.26859861591696],
  ["PR", 85.33044982698962],
  ["PS", 65.75951557093425],
  ["IQ", 89.18079584775086],
  ["HU", 61.48615916955017],
  ["KN", 91.07871972318338],
  ["KY", 81.08434256055364],
  ["FI", 89.20242214532873],
  ["ER", 75.6682525951557],
  ["GM", 90.15787197231833],
  ["EE", 89.64403114186851],
  ["EG", 89.15570934256056],
  ["ID", 86.1492214532872],
  ["IS", 84.18728373702422],
  ["SK", 86.88624567474048],
  ["RO", 93.0060553633218],
  ["PG", 84.94031141868513],
  ["KM", 85.11115916955018],
  ["IR", 83.4727508650519],
  ["KZ", 88.48442906574394],
  ["IE", 94.9173875432526],
  ["GN", 93.39057093425606],
  ["FJ", 87.63321799307958],
  ["GY", 89.43771626297577],
  ["JM", 84.23745674740485],
  ["KI", 84.98053633217994],
  ["HR", 85.44420415224914],
  ["SO", 92.40008650519032],
  ["PT", 87.55795847750865],
  ["SY", 89.85250865051904],
  ["SN", 69.98356401384083],
  ["KH", 89.56098615916956],
  ["EC", 84.13927335640139],
  ["ET", 54.562716262975776],
  ["DE", 88.2045847750865],
  ["SL", 89.84991349480968],
  ["PW", 96.13624567474048],
  ["SZ", 85.87889273356402],
  ["PA", 87.70761245674741],
  ["SM", 92.95761245674741],
  ["IT", 95.09256055363322],
  ["JO", 66.7166955017301],
  ["GH", 59.333477508650525],
  ["CG", 93.49870242214533],
  ["BT", 89.22967128027682],
  ["AO", 88.84731833910034],
  ["ML", 93.39792387543253],
  ["TN", 92.76427335640139],
  ["LI", 85.10769896193771],
  ["MM", 92.51989619377163],
  ["MZ", 86.78979238754326],
  ["NA", 80.41262975778547],
  ["CF", 86.94204152249135],
  ["BB", 92.00951557093425],
  ["CD", 85.47448096885813],
  ["BW", 88.27032871972318],
  ["AL", 90.87629757785467],
  ["NC", 87.40441176470588],
  ["MX", 68.0583910034602],
  ["TZ", 91.71842560553634],
  ["VE", 86.6864186851211],
  ["MN", 85.2469723183391],
  ["MY", 79.52465397923876],
  ["AM", 88.98053633217992],
  ["CR", 86.52897923875432],
  ["BA", 84.52335640138409],
  ["AZ", 88.49480968858131],
  ["CV", 84.77465397923876],
  ["BR", 82.1682525951557],
  ["CA", 85.81055363321799],
  ["BE", 93.34342560553634],
  ["TH", 90.05666089965399],
  ["WS", 84.82266435986159],
  ["UZ", 85.76167820069203],
  ["Wales", 75.95198961937716],
  ["NG", 46.79282006920415],
  ["NP", 77.55449826989619],
  ["MK", 81.51859861591696],
  ["BD", 30.570934256055367],
  ["BS", 89.6362456747405],
  ["CU", 86.98226643598616],
  ["BF", 89.93166089965398],
  ["NR", 88.99134948096886],
  ["NE", 86.7348615916955],
  ["ZW", 81.3166089965398],
  ["VC", 88.37413494809688],
  ["UY", 87.56704152249135],
  ["TJ", 90.02638408304499],
  ["VU", 84.91782006920415],
  ["ZA", 70.83304498269896],
  ["BG", 88.90397923875433],
]);

export const frMap = new Map<string, number>([
  ["CY", 75.65932995714843],
  ["AF", 95.7184131930918],
  ["CN", 97.17406830281782],
  ["BJ", 82.16368004155305],
  ["LA", 75.00714192961952],
  ["ME", 97.3665757693806],
  ["OM", 74.62991819244253],
  ["NI", 89.2815868069082],
  ["MR", 96.68744318919622],
  ["TG", 96.46019997402935],
  ["TP", 60.91579015712245],
  ["North Korea", 73.38202830801195],
  ["VN", 64.87858719646799],
  ["ZM", 95.37624983768342],
  ["MD", 93.32326970523309],
  ["CO", 81.8679392286716],
  ["AG", 64.41208933904687],
  ["AE", 88.06356317361382],
  ["CZ", 66.39722114011167],
  ["AR", 90.88300220750551],
  ["BI", 75.44507206856252],
  ["CM", 98.59401376444617],
  ["LB", 73.34404622776263],
  ["LU", 77.47305544734449],
  ["TD", 96.17062719127387],
  ["TR", 95.80995974548759],
  ["Scotland", 81.334242306194],
  ["UA", 98.08855992728218],
  ["LT", 98.97253603428126],
  ["LC", 94.97695104531878],
  ["MG", 83.12199714322816],
  ["CL", 51.459875340864826],
  ["BH", 64.37800285677184],
  ["AD", 29.876314764316326],
  ["CH", 83.98357356187508],
  ["NO", 67.81911440072717],
  ["MT", 50.49344241007662],
  ["MC", 67.33248928710557],
  ["TV", 83.7709388391118],
  ["TW", 96.04661732242565],
  ["US", 76.79392286716012],
  ["MU", 89.70588235294117],
  ["BZ", 76.92832099727308],
  ["CI", 66.31736138163875],
  ["BM", 63.6105700558369],
  ["CK", 71.97734060511621],
  ["AT", 66.61082976236852],
  ["BO", 87.55323983898195],
  ["LS", 79.83606025191533],
  ["NL", 88.40085703155435],
  ["MW", 87.47273081417998],
  ["YE", 77.95318789767562],
  ["UG", 89.8516426438125],
  ["TT", 68.49662381508895],
  ["NZ", 73.2047786001818],
  ["MA", 99.40527204259187],
  ["MV", 72.79606544604597],
  ["LR", 82.69835086352421],
  ["BY", 94.10531099857161],
  ["BN", 88.86995195429165],
  ["AU", 90.11556940657057],
  ["GE", 79.54811063498248],
  ["GR", 85.01720555771978],
  ["IN", 90.63628100246721],
  ["GBENG", 77.02408778080768],
  ["RS", 76.86631606284898],
  ["PL", 66.76178418387222],
  ["SV", 90.26035579794832],
  ["SA", 92.09128684586418],
  ["HK", 90.70834956499156],
  ["KG", 58.89592260745358],
  ["GD", 76.02746396571874],
  ["GQ", 87.203285287625],
  ["DJ", 93.27425009738995],
  ["KE", 94.57310738865083],
  ["KR", 78.18140501233606],
  ["SC", 58.032398389819505],
  ["ST", 95.03278794961693],
  ["PY", 68.97188676795221],
  ["SB", 88.87254901960785],
  ["IL", 80.15971951694584],
  ["DK", 86.1576418646929],
  ["DO", 70.03311258278146],
  ["RU", 66.34885079859758],
  ["SG", 63.80307752239969],
  ["PK", 93.80534995455136],
  ["GB", 67.12699649396183],
  ["GU", 69.71854304635762],
  ["GW", 95.15874561745228],
  ["JP", 83.40994676016102],
  ["SR", 91.8530061031035],
  ["SE", 96.92799636410857],
  ["SD", 74.11537462667187],
  ["SS", 81.87767822360732],
  ["PH", 75.26782236073237],
  ["RW", 93.95403194390339],
  ["KU", 75.10453187897676],
  ["HN", 89.58739124788988],
  ["GA", 98.14796779639008],
  ["DZ", 85.1428385923906],
  ["DM", 95.1279054668225],
  ["FR", 0],
  ["GL", 66.40338917023763],
  ["ES", 95.15517465264251],
  ["HT", 64.54746136865343],
  ["PE", 33.08726139462407],
  ["QA", 98.15121412803532],
  ["SI", 74.89384495520063],
  ["PR", 81.10083106090119],
  ["PS", 84.66627710686923],
  ["IQ", 79.77340605116218],
  ["HU", 77.99928580703805],
  ["KN", 73.40410336319958],
  ["KY", 69.31989352032204],
  ["FI", 71.11251785482405],
  ["ER", 87.44805869367615],
  ["GM", 78.08693676145954],
  ["EE", 87.77106869237763],
  ["EG", 79.6604337099078],
  ["ID", 67.05427866510843],
  ["IS", 67.48669004025452],
  ["SK", 74.45039605246072],
  ["RO", 31.172574990261005],
  ["PG", 72.05038306713413],
  ["KM", 83.74788988443059],
  ["IR", 83.88261264770809],
  ["KZ", 97.01791975068173],
  ["IE", 66.31638748214517],
  ["GN", 96.31703674847422],
  ["FJ", 88.21094663030775],
  ["GY", 85.97422412673679],
  ["JM", 91.2534086482275],
  ["KI", 61.670886897805474],
  ["HR", 80.35482404882482],
  ["SO", 95.2249707830152],
  ["PT", 94.20789507856122],
  ["SY", 76.89520841449162],
  ["SN", 61.9838332684067],
  ["KH", 75.47461368653423],
  ["EC", 85.80476561485521],
  ["ET", 86.56570575249968],
  ["DE", 97.36560186988703],
  ["SL", 88.68750811582912],
  ["PW", 97.23672250357096],
  ["SZ", 85.10031164783794],
  ["PA", 65.06395273341124],
  ["SM", 85.7843137254902],
  ["IT", 33.81995844695494],
  ["JO", 86.91403713803402],
  ["GH", 86.38618361251785],
  ["CG", 70.64796779639008],
  ["BT", 66.60985586287495],
  ["AO", 79.0579145565511],
  ["ML", 64.40786910790807],
  ["TN", 90.00908972860667],
  ["LI", 69.05434359174133],
  ["MM", 76.98058693676147],
  ["MZ", 90.21133619010519],
  ["NA", 67.0175951175172],
  ["CF", 87.33346318659915],
  ["BB", 61.271588105440856],
  ["CD", 89.76042072458122],
  ["BW", 96.8504090377873],
  ["AL", 65.69861057005583],
  ["NC", 74.5783015192832],
  ["MX", 37.36625113621608],
  ["TZ", 93.32489287105571],
  ["VE", 75.84826645890143],
  ["MN", 95.29606544604596],
  ["MY", 57.963251525775874],
  ["AM", 88.18400207765225],
  ["CR", 65.1051811453058],
  ["BA", 62.68763796909492],
  ["AZ", 97.09648097649655],
  ["CV", 66.70432411375145],
  ["BR", 92.50064926632905],
  ["CA", 78.30379171536164],
  ["BE", 63.08791066095313],
  ["TH", 77.54350084404624],
  ["WS", 51.40501233606025],
  ["UZ", 87.6321256979613],
  ["Wales", 83.32684067004286],
  ["NG", 66.16932865861577],
  ["NP", 72.4662381508895],
  ["MK", 84.45851188157381],
  ["BD", 98.46318659914297],
  ["BS", 95.50025970653162],
  ["CU", 72.74607193870925],
  ["BF", 81.77217244513699],
  ["NR", 71.20373977405532],
  ["NE", 93.96799116997792],
  ["ZW", 94.13290481755617],
  ["VC", 93.24990261005064],
  ["UY", 71.37579535125307],
  ["TJ", 85.6833528113232],
  ["VU", 75.19120893390469],
  ["ZA", 78.73035969354629],
  ["BG", 88.21062199714322],
]);

export const glMap = new Map<string, number>([
  ["CY", 53.24860407739255],
  ["AF", 91.86728996234255],
  ["CN", 94.52506168030126],
  ["BJ", 72.84411115439553],
  ["LA", 68.84625373328139],
  ["ME", 92.74834437086092],
  ["OM", 52.46656278405402],
  ["NI", 82.06271912738605],
  ["MR", 94.97695104531878],
  ["TG", 89.28937800285676],
  ["TP", 59.14946110894689],
  ["North Korea", 67.86878327489937],
  ["VN", 52.147123750162315],
  ["ZM", 96.58615764186469],
  ["MD", 76.19627321127126],
  ["CO", 73.55473315153877],
  ["AG", 59.29262433450201],
  ["AE", 74.51369951954293],
  ["CZ", 37.338981950396054],
  ["AR", 83.8462537332814],
  ["BI", 65.22464614985067],
  ["CM", 81.93870925853786],
  ["LB", 52.00298662511362],
  ["LU", 78.35833008700169],
  ["TD", 79.17121153097001],
  ["TR", 50.51811453058045],
  ["Scotland", 74.37637969094922],
  ["UA", 95.5538241786781],
  ["LT", 69.96396571873782],
  ["LC", 96.75594078691078],
  ["MG", 81.77282171146605],
  ["CL", 34.49292299701337],
  ["BH", 50.256135566809505],
  ["AD", 77.87657447084794],
  ["CH", 87.53733281392027],
  ["NO", 60.37852226983509],
  ["MT", 51.23457992468511],
  ["MC", 76.66147253603428],
  ["TV", 81.67900272691858],
  ["TW", 94.08583300870018],
  ["US", 59.405272042591875],
  ["MU", 93.99266329048176],
  ["BZ", 75.22724321516687],
  ["CI", 83.08823529411765],
  ["BM", 49.01181664718868],
  ["CK", 83.41936112193221],
  ["AT", 50.754772107518505],
  ["BO", 91.84975977145825],
  ["LS", 80.86612128295026],
  ["NL", 81.41604986365407],
  ["MW", 79.90910271393325],
  ["YE", 78.79593559278015],
  ["UG", 92.668484612388],
  ["TT", 66.53681340085703],
  ["NZ", 85.83593039864952],
  ["MA", 50.277885988832615],
  ["MV", 63.67939228671601],
  ["LR", 55.20646669263732],
  ["BY", 91.2829502661992],
  ["BN", 83.0067523698221],
  ["AU", 86.29626022594469],
  ["GE", 63.826126477080905],
  ["GR", 77.52110115569405],
  ["IN", 82.63245033112582],
  ["GBENG", 50.16653681340085],
  ["RS", 89.56986105700558],
  ["PL", 23.215166861446566],
  ["SV", 82.76295286326452],
  ["SA", 90.77100376574471],
  ["HK", 91.00116867939228],
  ["KG", 50.9164394234515],
  ["GD", 71.26249837683419],
  ["GQ", 59.046877028957276],
  ["DJ", 84.08096351123231],
  ["KE", 90.39897415920011],
  ["KR", 54.51921828333982],
  ["SC", 82.41981560836254],
  ["ST", 87.50097389949357],
  ["PY", 78.22165952473703],
  ["SB", 87.90676535514869],
  ["IL", 65],
  ["DK", 50.22984028048305],
  ["DO", 70.56096610829763],
  ["RU", 41.39365017530191],
  ["SG", 75.26619919490976],
  ["PK", 81.62706142059473],
  ["GB", 62.066614725360346],
  ["GU", 87.41592001038826],
  ["GW", 81.6280353200883],
  ["JP", 50.284378652123095],
  ["SR", 70.7736008310609],
  ["SE", 94.7396442020517],
  ["SD", 82.0188936501753],
  ["SS", 82.52402285417479],
  ["PH", 57.081872484092976],
  ["RW", 95.33859239059862],
  ["KU", 62.19516945851188],
  ["HN", 81.95981041423191],
  ["GA", 94.85878457343202],
  ["DZ", 70.58369042981431],
  ["DM", 89.6831580314245],
  ["FR", 66.40338917023763],
  ["GL", 0],
  ["ES", 69.66497857421115],
  ["HT", 59.19783145046098],
  ["PE", 50.30905077262693],
  ["QA", 81.40501233606025],
  ["SI", 40.977145825217505],
  ["PR", 80.32203609920789],
  ["PS", 78.24730554473444],
  ["IQ", 80.04317621088171],
  ["HU", 78.32651603687833],
  ["KN", 65.50058433969615],
  ["KY", 83.99883132060772],
  ["FI", 68.61219322165952],
  ["ER", 72.39124788988444],
  ["GM", 88.59076743280093],
  ["EE", 94.46565381119335],
  ["EG", 79.74061810154525],
  ["ID", 76.6617971691988],
  ["IS", 81.51701077782107],
  ["SK", 35.88884560446695],
  ["RO", 79.22964550058434],
  ["PG", 78.88098948188546],
  ["KM", 71.50889494870796],
  ["IR", 75.78074276068043],
  ["KZ", 91.3141150499935],
  ["IE", 82.16790027269187],
  ["GN", 80.2976886118686],
  ["FJ", 85.12530840150629],
  ["GY", 79.70555771977665],
  ["JM", 90.47169198805351],
  ["KI", 78.31872484092976],
  ["HR", 80.11849110505129],
  ["SO", 94.69549409167641],
  ["PT", 91.18361251785483],
  ["SY", 79.05272042591871],
  ["SN", 78.60472665887546],
  ["KH", 72.72951564731854],
  ["EC", 70.33794312426957],
  ["ET", 64.20270094792883],
  ["DE", 97.52239968835215],
  ["SL", 81.89585768082067],
  ["PW", 96.97474353980002],
  ["SZ", 87.45455135696662],
  ["PA", 77.54544864303338],
  ["SM", 58.825801843916366],
  ["IT", 66.20016880924555],
  ["JO", 76.5053239838982],
  ["GH", 91.34138423581352],
  ["CG", 68.98584599402675],
  ["BT", 89.0134398130113],
  ["AO", 86.96403064537073],
  ["ML", 79.02610050642774],
  ["TN", 50.9420854434489],
  ["LI", 59.22217893780029],
  ["MM", 63.41416699129984],
  ["MZ", 85.89274120244124],
  ["NA", 80.69114400727179],
  ["CF", 74.75230489546813],
  ["BB", 95.08310609011816],
  ["CD", 86.75236982210103],
  ["BW", 95.77717179587066],
  ["AL", 56.43877418517076],
  ["NC", 93.42293208674198],
  ["MX", 65.88852097130243],
  ["TZ", 91.53973509933775],
  ["VE", 66.10764835735618],
  ["MN", 67.47175691468641],
  ["MY", 65.27658745617451],
  ["AM", 97.71133619010519],
  ["CR", 67.35910920659654],
  ["BA", 90.11264770808985],
  ["AZ", 81.72055577197767],
  ["CV", 84.15822620438904],
  ["BR", 89.79548110634983],
  ["CA", 78.43332034800675],
  ["BE", 80.79405272042592],
  ["TH", 66.52317880794702],
  ["WS", 57.0364238410596],
  ["UZ", 79.27736657576938],
  ["Wales", 51.84521490715491],
  ["NG", 82.3571614076094],
  ["NP", 44.301064796779634],
  ["MK", 82.14322815218803],
  ["BD", 94.91624464355279],
  ["BS", 95.42169848071678],
  ["CU", 72.57044539670173],
  ["BF", 87.74801973769641],
  ["NR", 93.03304765614855],
  ["NE", 83.53038566419946],
  ["ZW", 87.57985975847292],
  ["VC", 93.8624853915076],
  ["UY", 66.28944292948967],
  ["TJ", 77.9246201791975],
  ["VU", 89.29002726918583],
  ["ZA", 89.64485131801064],
  ["BG", 69.16244643552785],
]);

export const esMap = new Map<string, number>([
  ["CY", 90.66953874060637],
  ["AF", 76.89880798134232],
  ["CN", 46.251943508681],
  ["BJ", 79.49144856180358],
  ["LA", 45.584348276755634],
  ["ME", 53.6790619331433],
  ["OM", 83.31497797356829],
  ["NI", 90.05798134231667],
  ["MR", 91.99339207048457],
  ["TG", 63.08532003109614],
  ["TP", 51.43528116092252],
  ["North Korea", 93.88507385332989],
  ["VN", 43.545607670380925],
  ["ZM", 88.87794765483285],
  ["MD", 69.95076444674785],
  ["CO", 49.61777662606893],
  ["AG", 66.14731795802021],
  ["AE", 82.09769370303187],
  ["CZ", 72.20458668048717],
  ["AR", 89.91351386369526],
  ["BI", 60.26982378854625],
  ["CM", 60.97207825861622],
  ["LB", 88.21521119460999],
  ["LU", 94.01949987043275],
  ["TD", 66.06083182171547],
  ["TR", 42.548263798911634],
  ["Scotland", 88.36065042757191],
  ["UA", 75.44506348795025],
  ["LT", 63.09698108318217],
  ["LC", 89.9653407618554],
  ["MG", 95.85060896605339],
  ["CL", 92.91558693962166],
  ["BH", 61.36239958538482],
  ["AD", 68.72926924073595],
  ["CH", 93.17536926664938],
  ["NO", 87.98911635138637],
  ["MT", 70.76412282974864],
  ["MC", 69.41986265871988],
  ["TV", 78.6411635138637],
  ["TW", 92.44882093806686],
  ["US", 55.154832858253435],
  ["MU", 75.40198237885463],
  ["BZ", 70.6073464628142],
  ["CI", 94.33532003109613],
  ["BM", 48.463656387665196],
  ["CK", 83.82482508421872],
  ["AT", 91.74721430422389],
  ["BO", 93.02766260689297],
  ["LS", 91.83337652241514],
  ["NL", 67.91234775848666],
  ["MW", 90.83797615962685],
  ["YE", 70.8240476807463],
  ["UG", 62.71702513604561],
  ["TT", 59.44545218968644],
  ["NZ", 84.64077481212749],
  ["MA", 45.95167141746567],
  ["MV", 44.99060637470848],
  ["LR", 57.019305519564654],
  ["BY", 88.42154703291007],
  ["BN", 79.55590826639025],
  ["AU", 85.54483026690852],
  ["GE", 89.89828971236071],
  ["GR", 87.26872246696036],
  ["IN", 90.09847110650428],
  ["GBENG", 87.55085514381963],
  ["RS", 90.76574242031614],
  ["PL", 70.96592381445971],
  ["SV", 88.9673490541591],
  ["SA", 88.52455299300337],
  ["HK", 40.619331433013734],
  ["KG", 86.33810572687224],
  ["GD", 52.20555843482767],
  ["GQ", 66.9972790878466],
  ["DJ", 92.97421611816533],
  ["KE", 88.03802798652501],
  ["KR", 93.88960870691889],
  ["SC", 75.99378077222079],
  ["ST", 65.88138118683597],
  ["PY", 67.94862658719876],
  ["SB", 85.93741902047162],
  ["IL", 92.24151334542627],
  ["DK", 50.06802280383519],
  ["DO", 71.45180098471107],
  ["RU", 93.48276755636175],
  ["SG", 90.37444933920705],
  ["PK", 92.55668566986266],
  ["GB", 70.6795802021249],
  ["GU", 80.12373671935734],
  ["GW", 63.13164032132676],
  ["JP", 94.28867582275201],
  ["SR", 91.60436641617],
  ["SE", 78.02118424462296],
  ["SD", 70.44441565172325],
  ["SS", 87.6441435605079],
  ["PH", 67.33933661570354],
  ["RW", 71.1829489505053],
  ["KU", 70.97337393107023],
  ["HN", 90.37606892977456],
  ["GA", 68.97674267945064],
  ["DZ", 92.45044052863436],
  ["DM", 89.53550142523969],
  ["FR", 95.01230888831303],
  ["GL", 69.40172324436382],
  ["ES", 0],
  ["HT", 69.02630215081628],
  ["PE", 62.25479398807982],
  ["QA", 94.461324177248],
  ["SI", 93.62334801762114],
  ["PR", 87.75298004664421],
  ["PS", 90.97790878465923],
  ["IQ", 67.46825602487692],
  ["HU", 67.4297097693703],
  ["KN", 74.96177766260689],
  ["KY", 84.37969681264576],
  ["FI", 93.56277533039648],
  ["ER", 85.26788027986525],
  ["GM", 66.10261725835709],
  ["EE", 97.6091604042498],
  ["EG", 66.83629178543664],
  ["ID", 69.74345685410728],
  ["IS", 84.87043275459963],
  ["SK", 92.6438196423944],
  ["RO", 66.09451930551955],
  ["PG", 69.60093288416688],
  ["KM", 86.09257579683856],
  ["IR", 61.68081109095621],
  ["KZ", 81.19072298522934],
  ["IE", 94.52286861881316],
  ["GN", 65.64589271832081],
  ["FJ", 79.23879243327286],
  ["GY", 72.45076444674787],
  ["JM", 80.83214563358383],
  ["KI", 58.64667012179321],
  ["HR", 86.72777921741384],
  ["SO", 91.9266649391034],
  ["PT", 92.00343353200311],
  ["SY", 68.92459186317699],
  ["SN", 77.2136563876652],
  ["KH", 91.4611946100026],
  ["EC", 73.9624902824566],
  ["ET", 49.431523710805905],
  ["DE", 88.72117128789841],
  ["SL", 91.75045348535889],
  ["PW", 84.0735941953874],
  ["SZ", 93.59646281420056],
  ["PA", 79.53874060637472],
  ["SM", 87.37431977196165],
  ["IT", 93.81510754081368],
  ["JO", 86.95031096138896],
  ["GH", 46.64647577092511],
  ["CG", 75.23095361492615],
  ["BT", 84.05059600932884],
  ["AO", 68.55986006737497],
  ["ML", 65.17264835449599],
  ["TN", 89.83253433532003],
  ["LI", 68.37166364343094],
  ["MM", 84.02500647836227],
  ["MZ", 81.97168955688002],
  ["NA", 74.90833117387923],
  ["CF", 82.17025136045608],
  ["BB", 92.72933402435865],
  ["CD", 79.58797615962685],
  ["BW", 92.23309147447524],
  ["AL", 47.78213267685929],
  ["NC", 90.43728945322623],
  ["MX", 74.16169992225964],
  ["TZ", 89.37030318735424],
  ["VE", 63.02409950764447],
  ["MN", 59.83933661570355],
  ["MY", 69.13999740865509],
  ["AM", 64.61162218191241],
  ["CR", 93.2168307851775],
  ["BA", 78.61848924591864],
  ["AZ", 93.81186835967867],
  ["CV", 85.17621145374449],
  ["BR", 83.44681264576315],
  ["CA", 91.12950246177766],
  ["BE", 95.85449598341539],
  ["TH", 94.59575019435087],
  ["WS", 56.792562840114016],
  ["UZ", 90.73205493651206],
  ["Wales", 84.97538222337393],
  ["NG", 94.54683855921223],
  ["NP", 72.9968903861104],
  ["MK", 55.30059600932884],
  ["BD", 91.56193314330137],
  ["BS", 93.6800336874838],
  ["CU", 85.05377040684115],
  ["BF", 92.18353200310962],
  ["NR", 89.45257838818347],
  ["NE", 92.48704327545997],
  ["ZW", 84.69648872764965],
  ["VC", 80.7809665716507],
  ["UY", 87.21916299559471],
  ["TJ", 61.831109095620626],
  ["VU", 69.04638507385333],
  ["ZA", 85.73788546255507],
  ["BG", 68.12678154962425],
]);

export const htMap = new Map<string, number>([
  ["CY", 96.29613610149941],
  ["AF", 90.47541810841983],
  ["CN", 92.88422722029989],
  ["BJ", 68.3942474048443],
  ["LA", 69.35697808535178],
  ["ME", 88.56293252595155],
  ["OM", 69.99170991926182],
  ["NI", 96.98565455594003],
  ["MR", 94.05276816608996],
  ["TG", 90.3871107266436],
  ["TP", 58.95076412918109],
  ["North Korea", 70.24077277970011],
  ["VN", 49.901600346020764],
  ["ZM", 92.2430074971165],
  ["MD", 78.83650519031143],
  ["CO", 71.03481833910035],
  ["AG", 51.4345444059977],
  ["AE", 84.7671568627451],
  ["CZ", 59.21784890426759],
  ["AR", 97.50756920415225],
  ["BI", 78.57951268742791],
  ["CM", 82.5937139561707],
  ["LB", 71.49834198385237],
  ["LU", 97.58614475201846],
  ["TD", 79.59522779700116],
  ["TR", 50.81026528258362],
  ["Scotland", 89.92791234140715],
  ["UA", 97.73897058823529],
  ["LT", 62.58254036908881],
  ["LC", 97.90801614763552],
  ["MG", 97.20335928489042],
  ["CL", 34.02141003460208],
  ["BH", 64.48096885813149],
  ["AD", 64.12125144175317],
  ["CH", 94.63343425605537],
  ["NO", 66.32929642445214],
  ["MT", 73.31459054209918],
  ["MC", 98.14698673587083],
  ["TV", 86.30622837370242],
  ["TW", 90.9829152249135],
  ["US", 69.6712802768166],
  ["MU", 96.66450403690888],
  ["BZ", 81.91969434832757],
  ["CI", 97.43692329873126],
  ["BM", 46.367863321799305],
  ["CK", 86.62125144175317],
  ["AT", 64.7430074971165],
  ["BO", 96.85914071510958],
  ["LS", 67.53820645905421],
  ["NL", 97.58614475201846],
  ["MW", 72.50973183391002],
  ["YE", 97.58614475201846],
  ["UG", 91.02076124567475],
  ["TT", 60.77710495963091],
  ["NZ", 86.15160034602076],
  ["MA", 49.511606113033444],
  ["MV", 61.860582468281436],
  ["LR", 71.18620242214533],
  ["BY", 89.43483275663206],
  ["BN", 92.31185121107266],
  ["AU", 84.0776384083045],
  ["GE", 90.88992214532871],
  ["GR", 95.67834486735872],
  ["IN", 97.79195501730104],
  ["GBENG", 84.71885813148788],
  ["RS", 89.98305940023069],
  ["PL", 49.204512687427915],
  ["SV", 97.09630911188005],
  ["SA", 90.0353229527105],
  ["HK", 93.17582179930795],
  ["KG", 49.85978950403691],
  ["GD", 71.04310841983852],
  ["GQ", 65.16688292964244],
  ["DJ", 95.13083910034602],
  ["KE", 91.49041234140715],
  ["KR", 93.10769896193771],
  ["SC", 72.2469723183391],
  ["ST", 88.71647923875432],
  ["PY", 96.84544405997694],
  ["SB", 86.89806805074971],
  ["IL", 81.34515570934256],
  ["DK", 60.04541522491349],
  ["DO", 80.03640426758939],
  ["RU", 49.50439734717416],
  ["SG", 96.69838523644752],
  ["PK", 94.67236159169549],
  ["GB", 70.42964244521337],
  ["GU", 84.47051614763552],
  ["GW", 78.20177335640139],
  ["JP", 89.66010668973472],
  ["SR", 81.85193194925029],
  ["SE", 96.30767012687429],
  ["SD", 94.16882929642445],
  ["SS", 83.25151384083046],
  ["PH", 24.428705305651672],
  ["RW", 97.89467993079585],
  ["KU", 66.96330738177623],
  ["HN", 97.16659457900806],
  ["GA", 96.88220876585929],
  ["DZ", 92.68995098039215],
  ["DM", 89.40527681660899],
  ["FR", 64.42906574394463],
  ["GL", 59.0170847750865],
  ["ES", 69.05312860438293],
  ["HT", 0],
  ["PE", 64.19478085351787],
  ["QA", 94.12161188004615],
  ["SI", 60.45919838523645],
  ["PR", 88.52472606689736],
  ["PS", 86.18872549019608],
  ["IQ", 96.53871107266437],
  ["HU", 97.83124279123415],
  ["KN", 68.9475201845444],
  ["KY", 86.49942329873126],
  ["FI", 81.89013840830451],
  ["ER", 68.73378027681662],
  ["GM", 88.63321799307958],
  ["EE", 96.93987889273356],
  ["EG", 97.55839100346022],
  ["ID", 98.34414648212227],
  ["IS", 55.6996107266436],
  ["SK", 60.495602652825845],
  ["RO", 79.40383506343714],
  ["PG", 81.56394175317186],
  ["KM", 79.81725778546713],
  ["IR", 92.73428489042675],
  ["KZ", 92.12262110726644],
  ["IE", 97.35942906574394],
  ["GN", 79.87420703575548],
  ["FJ", 88.49336793540947],
  ["GY", 82.09306516724337],
  ["JM", 89.85762687427913],
  ["KI", 85.95696366782008],
  ["HR", 93.46813725490196],
  ["SO", 97.27580738177623],
  ["PT", 90.6945645905421],
  ["SY", 96.23918685121107],
  ["SN", 79.84429065743944],
  ["KH", 53.44146482122261],
  ["EC", 69.12954152249135],
  ["ET", 60.325836216839676],
  ["DE", 93.28395328719724],
  ["SL", 97.58614475201846],
  ["PW", 97.55586793540945],
  ["SZ", 85.53056516724337],
  ["PA", 95.23680795847751],
  ["SM", 94.4928633217993],
  ["IT", 80.58246828143022],
  ["JO", 82.19326701268743],
  ["GH", 96.74055651672434],
  ["CG", 69.23406862745098],
  ["BT", 94.87060265282584],
  ["AO", 96.46734429065744],
  ["ML", 80.10741061130334],
  ["TN", 52.15758362168397],
  ["LI", 7.103157439446367],
  ["MM", 65.57634083044984],
  ["MZ", 84.7357987312572],
  ["NA", 56.809760668973475],
  ["CF", 70.53849480968857],
  ["BB", 95.11317762399078],
  ["CD", 86.78849480968857],
  ["BW", 97.85863610149941],
  ["AL", 44.43807670126874],
  ["NC", 63.63321799307958],
  ["MX", 79.81905997693195],
  ["TZ", 93.62420703575548],
  ["VE", 59.40167243367935],
  ["MN", 65.95588235294117],
  ["MY", 56.01138985005767],
  ["AM", 82.3749279123414],
  ["CR", 82.1622693194925],
  ["BA", 65.58210784313725],
  ["AZ", 81.85950115340253],
  ["CV", 42.15974625144175],
  ["BR", 89.84104671280276],
  ["CA", 92.40412341407152],
  ["BE", 80.31538350634372],
  ["TH", 80.34602076124567],
  ["WS", 46.009227220299884],
  ["UZ", 94.87204440599768],
  ["Wales", 73.07237600922723],
  ["NG", 97.17091983852364],
  ["NP", 65.72808535178778],
  ["MK", 82.27977220299884],
  ["BD", 92.21345155709344],
  ["BS", 94.66983852364476],
  ["CU", 61.961505190311414],
  ["BF", 96.98132929642446],
  ["NR", 53.51030853517877],
  ["NE", 97.23760092272204],
  ["ZW", 91.11555651672434],
  ["VC", 95.80774221453288],
  ["UY", 82.85971741637832],
  ["TJ", 97.14604959630911],
  ["VU", 89.28056516724337],
  ["ZA", 91.49401672433679],
  ["BG", 96.17611014994233],
]);

export const peMap = new Map<string, number>([
  ["CY", 75.21133619010519],
  ["AF", 96.30632385404493],
  ["CN", 34.739644202051686],
  ["BJ", 82.16108297623686],
  ["LA", 57.934359174133235],
  ["ME", 45.36423841059602],
  ["OM", 51.2478898844306],
  ["NI", 89.37800285677184],
  ["MR", 97.08382028308013],
  ["TG", 80.86514738345669],
  ["TP", 53.80405142189326],
  ["North Korea", 57.953187897675626],
  ["VN", 31.293338527463966],
  ["ZM", 90.46390079210492],
  ["MD", 60.387611998441756],
  ["CO", 81.22354239709128],
  ["AG", 44.49779249448124],
  ["AE", 63.76444617582132],
  ["CZ", 60.98266458901441],
  ["AR", 90.9852616543306],
  ["BI", 68.38624853915076],
  ["CM", 98.59401376444617],
  ["LB", 57.199389689650694],
  ["LU", 66.70724581223217],
  ["TD", 63.3813790416829],
  ["TR", 35.008440462277626],
  ["Scotland", 81.37417218543047],
  ["UA", 98.16062848980653],
  ["LT", 77.20685625243475],
  ["LC", 94.83313855343461],
  ["MG", 99.33352811323205],
  ["CL", 48.99298792364628],
  ["BH", 63.024931827035445],
  ["AD", 61.642319179327366],
  ["CH", 84.25691468640436],
  ["NO", 48.09862355538242],
  ["MT", 49.384170886897806],
  ["MC", 50.10972600960915],
  ["TV", 81.53681340085703],
  ["TW", 96.04694195559019],
  ["US", 76.17647058823529],
  ["MU", 81.66277106869238],
  ["BZ", 72.50941436177119],
  ["CI", 66.17030255810934],
  ["BM", 44.82826905596676],
  ["CK", 83.52194520192184],
  ["AT", 45.06232956758863],
  ["BO", 76.61894559148162],
  ["LS", 89.75068172964549],
  ["NL", 88.52194520192182],
  ["MW", 76.68030125957668],
  ["YE", 66.61764705882352],
  ["UG", 92.35975847292559],
  ["TT", 44.5296065446046],
  ["NZ", 84.97922347747046],
  ["MA", 33.52811323204779],
  ["MV", 47.998311907544476],
  ["LR", 57.09291001168679],
  ["BY", 92.6535514868199],
  ["BN", 90.32560706401766],
  ["AU", 87.26593948837814],
  ["GE", 79.25951175172055],
  ["GR", 84.88021036229061],
  ["IN", 90.6106349824698],
  ["GBENG", 69.72471107648357],
  ["RS", 83.26970523308661],
  ["PL", 50.25516166731593],
  ["SV", 90.55187637969095],
  ["SA", 92.43280093494351],
  ["HK", 25.39800025970653],
  ["KG", 26.245617452278925],
  ["GD", 62.35813530710297],
  ["GQ", 68.12167251006362],
  ["DJ", 92.176665368134],
  ["KE", 94.86495260355798],
  ["KR", 79.16049863654071],
  ["SC", 69.87891182963251],
  ["ST", 82.79606544604596],
  ["PY", 68.64303337228931],
  ["SB", 88.77808076873134],
  ["IL", 80.98753408648227],
  ["DK", 30.733346318659915],
  ["DO", 56.71925723931957],
  ["RU", 66.32190624594209],
  ["SG", 52.94344890273991],
  ["PK", 93.80729775353849],
  ["GB", 63.779379301389426],
  ["GU", 87.52045188936502],
  ["GW", 66.62706142059473],
  ["JP", 82.86391377743149],
  ["SR", 92.11790676535514],
  ["SE", 96.89650694714972],
  ["SD", 68.25022724321516],
  ["SS", 81.60044150110375],
  ["PH", 74.6467991169978],
  ["RW", 94.43286586157642],
  ["KU", 70.25159070250618],
  ["HN", 89.68575509674068],
  ["GA", 97.82560706401766],
  ["DZ", 85.28989741592001],
  ["DM", 95.80281781586807],
  ["FR", 33.08726139462407],
  ["GL", 50.30905077262693],
  ["ES", 62.51298532658096],
  ["HT", 64.33352811323205],
  ["PE", 0],
  ["QA", 98.12784054018958],
  ["SI", 63.925464225425266],
  ["PR", 81.06641994546163],
  ["PS", 71.23263212569796],
  ["IQ", 68.42358135307103],
  ["HU", 67.02213998182054],
  ["KN", 74.05499285807038],
  ["KY", 82.9609790936242],
  ["FI", 83.80145435657707],
  ["ER", 66.52512660693417],
  ["GM", 73.77126347227633],
  ["EE", 88.59823399558499],
  ["EG", 68.32489287105571],
  ["ID", 50.40189585768082],
  ["IS", 85.32917802882743],
  ["SK", 62.02506168030126],
  ["RO", 63.3813790416829],
  ["PG", 68.58135307102974],
  ["KM", 77.31820542786652],
  ["IR", 82.60583041163486],
  ["KZ", 93.91540059732503],
  ["IE", 66.32255551227114],
  ["GN", 63.376184911050515],
  ["FJ", 83.55473315153876],
  ["GY", 72.5012985326581],
  ["JM", 93.09862355538242],
  ["KI", 50.761914037138034],
  ["HR", 91.30729775353849],
  ["SO", 95.36034281262174],
  ["PT", 93.88098948188546],
  ["SY", 65.61615374626672],
  ["SN", 61.9838332684067],
  ["KH", 60.599272821711466],
  ["EC", 78.05999220880405],
  ["ET", 75.14738345669394],
  ["DE", 97.95286326451111],
  ["SL", 88.78489806518634],
  ["PW", 97.37501623165824],
  ["SZ", 85.21847811972472],
  ["PA", 64.89027399039085],
  ["SM", 85.76678353460589],
  ["IT", 33.08726139462407],
  ["JO", 68.63913777431503],
  ["GH", 75.17238021036229],
  ["CG", 70.43046357615894],
  ["BT", 88.8585897935333],
  ["AO", 63.20737566549799],
  ["ML", 63.23496948448254],
  ["TN", 24.489027399039088],
  ["LI", 64.18776782236073],
  ["MM", 65.61258278145695],
  ["MZ", 79.16244643552785],
  ["NA", 73.61901051811454],
  ["CF", 93.4784443578756],
  ["BB", 94.20821971172575],
  ["CD", 82.39644202051682],
  ["BW", 97.13543695623945],
  ["AL", 34.3140501233606],
  ["NC", 95.01298532658097],
  ["MX", 37.36625113621608],
  ["TZ", 93.63978704064408],
  ["VE", 74.19198805349954],
  ["MN", 39.86982210102584],
  ["MY", 60.85800545383716],
  ["AM", 77.30294766913389],
  ["CR", 67.69023503441112],
  ["BA", 91.69101415400598],
  ["AZ", 75.62102324373457],
  ["CV", 87.36982210102585],
  ["BR", 92.6873133359304],
  ["CA", 78.6131671211531],
  ["BE", 63.969289702636026],
  ["TH", 66.69101415400598],
  ["WS", 47.29418257369173],
  ["UZ", 84.33872224386442],
  ["Wales", 82.82560706401766],
  ["NG", 66.46279703934555],
  ["NP", 62.34482534735749],
  ["MK", 84.25074665627841],
  ["BD", 95.95182443838462],
  ["BS", 96.34495520062329],
  ["CU", 65.92163355408388],
  ["BF", 65.62556810803791],
  ["NR", 96.80009089728607],
  ["NE", 93.99071549149461],
  ["ZW", 84.52311388131412],
  ["VC", 93.10349305285028],
  ["UY", 78.21516686144656],
  ["TJ", 85.498636540709],
  ["VU", 78.15186339436437],
  ["ZA", 91.04694195559019],
  ["BG", 66.27515907025062],
]);

export const qaMap = new Map<string, number>([
  ["CY", 66.94536241462878],
  ["AF", 88.74366600572814],
  ["CN", 94.8512888301388],
  ["BJ", 96.5273187926856],
  ["LA", 94.49548358669311],
  ["ME", 88.38510685172946],
  ["OM", 91.91121392377175],
  ["NI", 86.56642432253801],
  ["MR", 93.59385327164573],
  ["TG", 92.92244987882793],
  ["TP", 91.88918263934788],
  ["North Korea", 91.76911213923772],
  ["VN", 94.4596827495043],
  ["ZM", 91.38631857237277],
  ["MD", 93.30799735624586],
  ["CO", 95.61136814276271],
  ["AG", 90.0561797752809],
  ["AE", 95.46375853712271],
  ["CZ", 91.19244326944262],
  ["AR", 88.80039656311963],
  ["BI", 84.85349195858119],
  ["CM", 95.50066093853272],
  ["LB", 77.59032826613792],
  ["LU", 87.67955496805465],
  ["TD", 97.7236175369024],
  ["TR", 91.43533818021591],
  ["Scotland", 79.99118748623044],
  ["UA", 96.29819343467723],
  ["LT", 96.756444150694],
  ["LC", 97.99460233531615],
  ["MG", 66.18638466622603],
  ["CL", 96.46232650363517],
  ["BH", 66.92333113020489],
  ["AD", 96.20235734743335],
  ["CH", 92.88885217008152],
  ["NO", 88.5464860101344],
  ["MT", 68.7128222075347],
  ["MC", 82.62667988543733],
  ["TV", 91.5857016964089],
  ["TW", 92.39204670632299],
  ["US", 87.27858559154],
  ["MU", 95.48909451421018],
  ["BZ", 86.94481163251817],
  ["CI", 99.23000660938533],
  ["BM", 83.8064551663362],
  ["CK", 84.23220973782772],
  ["AT", 86.74763163692444],
  ["BO", 95.85150914298303],
  ["LS", 83.70896673276052],
  ["NL", 86.80160828376295],
  ["MW", 88.04582507160168],
  ["YE", 86.89083498567966],
  ["UG", 87.84919585811852],
  ["TT", 90.63780568407138],
  ["NZ", 85.48689138576779],
  ["MA", 93.28982154659616],
  ["MV", 94.63703458911654],
  ["LR", 85.39325842696628],
  ["BY", 92.35018726591761],
  ["BN", 78.6191892487332],
  ["AU", 84.1022251597268],
  ["GE", 72.29731218330029],
  ["GR", 82.97807887199824],
  ["IN", 86.27946684291695],
  ["GBENG", 72.71425424102225],
  ["RS", 82.57325402070941],
  ["PL", 82.46640229125359],
  ["SV", 85.83718880810751],
  ["SA", 89.9074686054197],
  ["HK", 92.77704340163031],
  ["KG", 88.6373650583829],
  ["GD", 86.82198722185504],
  ["GQ", 93.01277814496585],
  ["DJ", 76.57083057942278],
  ["KE", 86.01619299405155],
  ["KR", 66.48766248072263],
  ["SC", 88.68693544833664],
  ["ST", 93.27660277594184],
  ["PY", 85.34864507600794],
  ["SB", 88.5993610927517],
  ["IL", 72.48733201145626],
  ["DK", 93.5255562899317],
  ["DO", 89.36329588014982],
  ["RU", 86.32132628332232],
  ["SG", 79.88103106411104],
  ["PK", 68.91991628111919],
  ["GB", 77.12161269001983],
  ["GU", 89.81328486450761],
  ["GW", 97.23397224058162],
  ["JP", 65.79477858559154],
  ["SR", 88.46772416831902],
  ["SE", 96.57633840052875],
  ["SD", 96.11368142762723],
  ["SS", 94.66016743776163],
  ["PH", 77.2202026878167],
  ["RW", 96.56862745098039],
  ["KU", 95.12062128222075],
  ["HN", 86.05199383124035],
  ["GA", 96.40339281780128],
  ["DZ", 92.5727032385988],
  ["DM", 82.95825071601675],
  ["FR", 98.08713372989646],
  ["GL", 81.29929499889843],
  ["ES", 94.10112359550563],
  ["HT", 94.05595946243666],
  ["PE", 98.10200484688258],
  ["QA", 0],
  ["SI", 87.12381581846222],
  ["PR", 89.50264375413086],
  ["PS", 96.11643533818022],
  ["IQ", 85.48964529632077],
  ["HU", 86.77847543511787],
  ["KN", 87.67184401850628],
  ["KY", 84.69596827495043],
  ["FI", 76.29599030623486],
  ["ER", 88.58724388631856],
  ["GM", 93.40273187926856],
  ["EE", 86.31857237276932],
  ["EG", 86.73000660938533],
  ["ID", 82.62612910332673],
  ["IS", 90.22802379378719],
  ["SK", 84.45417492839833],
  ["RO", 97.95714915179555],
  ["PG", 88.71337298964531],
  ["KM", 90.98424763163693],
  ["IR", 80.45164133068958],
  ["KZ", 98.3223176911214],
  ["IE", 98.21932143643974],
  ["GN", 97.80953954615555],
  ["FJ", 87.63879709187046],
  ["GY", 88.73705662040096],
  ["JM", 87.79081295439524],
  ["KI", 83.32672394800616],
  ["HR", 79.9994492178894],
  ["SO", 99.48171403392818],
  ["PT", 91.09275170742454],
  ["SY", 86.6975104648601],
  ["SN", 96.68208856576338],
  ["KH", 92.65201586252478],
  ["EC", 90.1674377616215],
  ["ET", 85.20764485569508],
  ["DE", 93.70951751487112],
  ["SL", 87.78255122273629],
  ["PW", 98.01553205551883],
  ["SZ", 87.0142101784534],
  ["PA", 82.52643754130867],
  ["SM", 79.37926856135714],
  ["IT", 98.07226261291034],
  ["JO", 95.58437981934347],
  ["GH", 94.02621722846442],
  ["CG", 92.54516413306895],
  ["BT", 94.18429169420577],
  ["AO", 89.79896452963207],
  ["ML", 98.6004626569729],
  ["TN", 92.99845781009033],
  ["LI", 94.93170301828596],
  ["MM", 96.11588455606962],
  ["MZ", 92.59858999779688],
  ["NA", 87.59638686935448],
  ["CF", 87.58592200925314],
  ["BB", 93.992619519718],
  ["CD", 91.9596827495043],
  ["BW", 95.94293897334215],
  ["AL", 82.4619960343688],
  ["NC", 92.07699933906147],
  ["MX", 95.9693765146508],
  ["TZ", 92.60740251156643],
  ["VE", 92.81449658515092],
  ["MN", 94.04990085922009],
  ["MY", 82.59638686935449],
  ["AM", 96.7173386208416],
  ["CR", 85.49405155320555],
  ["BA", 90.07160167437762],
  ["AZ", 93.2286847323199],
  ["CV", 88.72714254241022],
  ["BR", 87.84809429389733],
  ["CA", 88.82683410442829],
  ["BE", 97.68451200705],
  ["TH", 87.02026878166997],
  ["WS", 95.74741132408019],
  ["UZ", 86.04813835646618],
  ["Wales", 61.75644415069399],
  ["NG", 98.08162590879049],
  ["NP", 80.76944260850408],
  ["MK", 80.95615774399647],
  ["BD", 93.03756333994272],
  ["BS", 98.48094293897334],
  ["CU", 92.83157083057942],
  ["BF", 94.82319894249834],
  ["NR", 94.41562018065653],
  ["NE", 86.25413086582948],
  ["ZW", 77.724168319013],
  ["VC", 93.52941176470588],
  ["UY", 75.99306014540647],
  ["TJ", 84.05926415510024],
  ["VU", 85.9324741132408],
  ["ZA", 92.61731658955718],
  ["BG", 86.52676801057501],
]);

export const siMap = new Map<string, number>([
  ["CY", 66.31444636678201],
  ["AF", 91.23572664359861],
  ["CN", 61.71453287197232],
  ["BJ", 75.61678200692042],
  ["LA", 70.88105536332179],
  ["ME", 88.35596885813149],
  ["OM", 61.048442906574394],
  ["NI", 97.34083044982698],
  ["MR", 93.58304498269896],
  ["TG", 94.22923875432527],
  ["TP", 68.68425605536332],
  ["North Korea", 78.95112456747405],
  ["VN", 61.826557093425606],
  ["ZM", 91.68468858131487],
  ["MD", 81.42517301038062],
  ["CO", 71.5765570934256],
  ["AG", 67.04887543252596],
  ["AE", 89.62110726643598],
  ["CZ", 40.12024221453287],
  ["AR", 98.10034602076124],
  ["BI", 69.64532871972318],
  ["CM", 84.41176470588235],
  ["LB", 62.03460207612457],
  ["LU", 97.58910034602076],
  ["TD", 95.96842560553634],
  ["TR", 61.75086505190311],
  ["Scotland", 64.75692041522491],
  ["UA", 82.62283737024222],
  ["LT", 97.13624567474048],
  ["LC", 98.37110726643598],
  ["MG", 88.39057093425605],
  ["CL", 40.253460207612456],
  ["BH", 66.38019031141869],
  ["AD", 82.71064013840831],
  ["CH", 91.90527681660899],
  ["NO", 64.16349480968859],
  ["MT", 68.74956747404845],
  ["MC", 97.10640138408304],
  ["TV", 82.69593425605535],
  ["TW", 94.00778546712803],
  ["US", 81.99956747404843],
  ["MU", 96.49221453287197],
  ["BZ", 63.560553633218],
  ["CI", 87.59429065743944],
  ["BM", 53.701989619377166],
  ["CK", 84.30190311418684],
  ["AT", 64.39532871972318],
  ["BO", 97.43858131487889],
  ["LS", 94.64965397923876],
  ["NL", 97.37673010380622],
  ["MW", 97.6401384083045],
  ["YE", 97.6704152249135],
  ["UG", 93.48183391003461],
  ["TT", 71.94031141868513],
  ["NZ", 85.94723183391004],
  ["MA", 92.76167820069205],
  ["MV", 66.85813148788927],
  ["LR", 84.10510380622837],
  ["BY", 90.2863321799308],
  ["BN", 85.16479238754326],
  ["AU", 86.784169550173],
  ["GE", 67.63148788927336],
  ["GR", 74.66782006920415],
  ["IN", 96.9909169550173],
  ["GBENG", 66.67863321799308],
  ["RS", 92.45198961937716],
  ["PL", 33.52465397923876],
  ["SV", 95.71799307958477],
  ["SA", 90.1621972318339],
  ["HK", 60.62456747404844],
  ["KG", 58.220155709342556],
  ["GD", 73.54714532871972],
  ["GQ", 64.80103806228374],
  ["DJ", 90.6613321799308],
  ["KE", 89.07915224913495],
  ["KR", 67.95631487889273],
  ["SC", 82.06141868512111],
  ["ST", 91.86245674740483],
  ["PY", 95.73010380622837],
  ["SB", 78.10813148788928],
  ["IL", 77.98356401384083],
  ["DK", 92.55406574394463],
  ["DO", 77.67560553633218],
  ["RU", 33.28849480968858],
  ["SG", 94.84126297577855],
  ["PK", 87.51470588235294],
  ["GB", 76.28806228373702],
  ["GU", 90.3970588235294],
  ["GW", 83.89186851211072],
  ["JP", 68.97231833910034],
  ["SR", 86.93122837370242],
  ["SE", 86.03892733564014],
  ["SD", 96.14576124567475],
  ["SS", 84.44809688581316],
  ["PH", 64.49048442906575],
  ["RW", 95.2015570934256],
  ["KU", 70.04498269896193],
  ["HN", 96.81098615916956],
  ["GA", 97.2962802768166],
  ["DZ", 76.59256055363322],
  ["DM", 87.26859861591696],
  ["FR", 74.69204152249135],
  ["GL", 40.71280276816609],
  ["ES", 93.26254325259515],
  ["HT", 60.733564013840834],
  ["PE", 63.81574394463668],
  ["QA", 87.4515570934256],
  ["SI", 0],
  ["PR", 81.81704152249135],
  ["PS", 93.54714532871972],
  ["IQ", 96.13667820069203],
  ["HU", 97.61851211072664],
  ["KN", 72.53546712802769],
  ["KY", 84.47664359861592],
  ["FI", 71.13321799307958],
  ["ER", 77.29801038062284],
  ["GM", 96.31358131487889],
  ["EE", 98.3961937716263],
  ["EG", 97.66955017301038],
  ["ID", 97.10640138408304],
  ["IS", 90.37846020761245],
  ["SK", 8.646626297577855],
  ["RO", 85.75173010380622],
  ["PG", 88.7378892733564],
  ["KM", 79.60813148788928],
  ["IR", 89.69074394463668],
  ["KZ", 87.19290657439447],
  ["IE", 85.71150519031143],
  ["GN", 85.52335640138409],
  ["FJ", 86.9515570934256],
  ["GY", 86.87456747404845],
  ["JM", 93.14489619377163],
  ["KI", 83.62153979238755],
  ["HR", 94.30968858131487],
  ["SO", 94.6159169550173],
  ["PT", 89.62846020761246],
  ["SY", 96.30363321799308],
  ["SN", 84.06228373702422],
  ["KH", 86.21280276816609],
  ["EC", 53.088235294117645],
  ["ET", 57.60596885813148],
  ["DE", 97.15138408304497],
  ["SL", 97.55925605536332],
  ["PW", 97.19333910034602],
  ["SZ", 87.33477508650519],
  ["PA", 73.31617647058823],
  ["SM", 64.29238754325259],
  ["IT", 75.00519031141869],
  ["JO", 89.40657439446366],
  ["GH", 97.19852941176471],
  ["CG", 74.50389273356402],
  ["BT", 92.21756055363322],
  ["AO", 94.33693771626298],
  ["ML", 85.45847750865052],
  ["TN", 60.83823529411765],
  ["LI", 63.958477508650525],
  ["MM", 62.74913494809689],
  ["MZ", 88.01989619377163],
  ["NA", 80.07828719723183],
  ["CF", 84.43339100346022],
  ["BB", 95.59039792387544],
  ["CD", 86.32958477508652],
  ["BW", 99.27032871972318],
  ["AL", 59.03287197231833],
  ["NC", 93.95501730103805],
  ["MX", 72.77811418685121],
  ["TZ", 91.21323529411765],
  ["VE", 59.96669550173011],
  ["MN", 81.8970588235294],
  ["MY", 70.58910034602076],
  ["AM", 96.71410034602076],
  ["CR", 81.5635813148789],
  ["BA", 89.55276816608998],
  ["AZ", 93.5211937716263],
  ["CV", 93.16652249134948],
  ["BR", 87.8386678200692],
  ["CA", 79.77465397923875],
  ["BE", 88.0242214532872],
  ["TH", 66.83996539792388],
  ["WS", 63.41565743944637],
  ["UZ", 94.36634948096886],
  ["Wales", 53.05536332179931],
  ["NG", 85.5916955017301],
  ["NP", 51.26167820069204],
  ["MK", 81.48313148788927],
  ["BD", 90.05233564013841],
  ["BS", 97.42820069204153],
  ["CU", 80.43901384083046],
  ["BF", 93.83607266435986],
  ["NR", 94.41998269896193],
  ["NE", 96.35856401384083],
  ["ZW", 87.60510380622837],
  ["VC", 93.15787197231835],
  ["UY", 73.64878892733564],
  ["TJ", 93.66608996539793],
  ["VU", 94.55579584775087],
  ["ZA", 91.5575259515571],
  ["BG", 33.246972318339104],
]);

export const prMap = new Map<string, number>([
  ["CY", 74.16374496818595],
  ["AF", 89.70133748863785],
  ["CN", 87.14939618231399],
  ["BJ", 89.82956758862485],
  ["LA", 88.2115958966368],
  ["ME", 86.56797818465134],
  ["OM", 69.01376444617581],
  ["NI", 84.32638618361253],
  ["MR", 87.45942085443448],
  ["TG", 84.76821192052981],
  ["TP", 89.30333722893131],
  ["North Korea", 89.74224126736787],
  ["VN", 88.64368263861836],
  ["ZM", 88.01162186728996],
  ["MD", 86.3731982859369],
  ["CO", 91.7747695104532],
  ["AG", 83.16939358524867],
  ["AE", 86.62478898844306],
  ["CZ", 59.32930788209323],
  ["AR", 89.33385274639657],
  ["BI", 77.77106869237761],
  ["CM", 89.82826905596676],
  ["LB", 73.02850279184521],
  ["LU", 84.54389040384366],
  ["TD", 90.25710946630308],
  ["TR", 49.26600441501104],
  ["Scotland", 79.66497857421115],
  ["UA", 90.7950266199195],
  ["LT", 91.91663420335021],
  ["LC", 96.39624724061811],
  ["MG", 90.96643293078822],
  ["CL", 79.25983638488509],
  ["BH", 89.34618880664848],
  ["AD", 87.7129593559278],
  ["CH", 49.59875340864823],
  ["NO", 85.81353071029736],
  ["MT", 87.18283339825996],
  ["MC", 80.3989741592001],
  ["TV", 86.06317361381639],
  ["TW", 54.29327360083106],
  ["US", 74.11699779249447],
  ["MU", 90.28502791845216],
  ["BZ", 80.72263342423062],
  ["CI", 85.45124009868849],
  ["BM", 83.03434618880665],
  ["CK", 83.77028957278276],
  ["AT", 84.74711076483572],
  ["BO", 91.66926373198287],
  ["LS", 82.1078431372549],
  ["NL", 84.90358395013634],
  ["MW", 89.1549798727438],
  ["YE", 87.17666536813401],
  ["UG", 72.8694325412284],
  ["TT", 87.92754187767822],
  ["NZ", 84.15335670692117],
  ["MA", 87.86748474224126],
  ["MV", 88.66251136216076],
  ["LR", 78.2950266199195],
  ["BY", 84.79580573951435],
  ["BN", 88.15608362550319],
  ["AU", 81.59492273730685],
  ["GE", 59.66595247370471],
  ["GR", 83.3732632125698],
  ["IN", 86.75399298792364],
  ["GBENG", 73.74983768341774],
  ["RS", 79.92014024152706],
  ["PL", 81.09953252824309],
  ["SV", 84.46273211271263],
  ["SA", 84.24100766134268],
  ["HK", 84.49681859498767],
  ["KG", 83.55408388520972],
  ["GD", 80.56908193741073],
  ["GQ", 82.91780288274249],
  ["DJ", 95.07109466303078],
  ["KE", 85.67913258018439],
  ["KR", 76.48032723022983],
  ["SC", 77.09907804181275],
  ["ST", 88.89429944163095],
  ["PY", 83.61803661862096],
  ["SB", 84.33450201272562],
  ["IL", 73.96961433580054],
  ["DK", 87.65160368783275],
  ["DO", 85.2249707830152],
  ["RU", 83.10641475133099],
  ["SG", 77.58310609011816],
  ["PK", 87.47338008050902],
  ["GB", 71.27483443708608],
  ["GU", 86.30534995455136],
  ["GW", 89.56531619270226],
  ["JP", 78.450201272562],
  ["SR", 76.75107128944293],
  ["SE", 91.73061940007791],
  ["SD", 85.56226464095572],
  ["SS", 74.45169458511882],
  ["PH", 87.50454486430334],
  ["RW", 89.68672899623425],
  ["KU", 86.26444617582132],
  ["HN", 84.21503700818076],
  ["GA", 91.16218672899623],
  ["DZ", 72.46104402025712],
  ["DM", 86.52220490845345],
  ["FR", 81.10083106090119],
  ["GL", 80.32203609920789],
  ["ES", 88.17621088170368],
  ["HT", 88.92351642643813],
  ["PE", 81.06641994546163],
  ["QA", 89.68153486560188],
  ["SI", 81.80301259576679],
  ["PR", 0],
  ["PS", 85.97811972471108],
  ["IQ", 85.4635761589404],
  ["HU", 85.02045188936502],
  ["KN", 87.70127256200495],
  ["KY", 83.78002856771847],
  ["FI", 75.98818335281132],
  ["ER", 80.52752889235164],
  ["GM", 84.15660303856643],
  ["EE", 87.65582391897156],
  ["EG", 87.39546812102324],
  ["ID", 80.59278015842098],
  ["IS", 88.83326840670043],
  ["SK", 81.63322945072069],
  ["RO", 90.2600311647838],
  ["PG", 89.70068822230878],
  ["KM", 78.21256979613037],
  ["IR", 62.12212699649397],
  ["KZ", 85.01006362810026],
  ["IE", 83.1495909622127],
  ["GN", 90.13309959745487],
  ["FJ", 89.49519542916504],
  ["GY", 83.55603168419685],
  ["JM", 91.22062069861056],
  ["KI", 80.93948837813271],
  ["HR", 68.60083106090119],
  ["SO", 89.26340734969484],
  ["PT", 57.10297363978704],
  ["SY", 85.97649655888846],
  ["SN", 88.7761329697442],
  ["KH", 85.97130242825607],
  ["EC", 88.00090897286067],
  ["ET", 70.30742760680431],
  ["DE", 85.14803272302298],
  ["SL", 84.83898195039605],
  ["PW", 96.68159979223478],
  ["SZ", 82.88209323464486],
  ["PA", 80.90637579535125],
  ["SM", 83.71510193481366],
  ["IT", 81.0904427996364],
  ["JO", 85.3694325412284],
  ["GH", 91.27158810544086],
  ["CG", 88.45994026749773],
  ["BT", 88.58167770419426],
  ["AO", 91.30210362290612],
  ["ML", 90.09998701467342],
  ["TN", 48.20445396701727],
  ["LI", 87.84476042072458],
  ["MM", 86.9312426957538],
  ["MZ", 84.85618750811584],
  ["NA", 81.36313465783664],
  ["CF", 82.97396442020516],
  ["BB", 89.4744189066355],
  ["CD", 85.92520451889365],
  ["BW", 96.58420984287754],
  ["AL", 87.11790676535514],
  ["NC", 86.76178418387222],
  ["MX", 81.0362290611609],
  ["TZ", 89.45331775094144],
  ["VE", 88.834242306194],
  ["MN", 86.35891442669784],
  ["MY", 74.72146474483833],
  ["AM", 75.93494351382938],
  ["CR", 72.4837683417738],
  ["BA", 84.28353460589534],
  ["AZ", 87.84605895338268],
  ["CV", 82.43507336709519],
  ["BR", 83.21614076094014],
  ["CA", 61.31314115049994],
  ["BE", 94.91494611089469],
  ["TH", 74.7331515387612],
  ["WS", 87.3880015582392],
  ["UZ", 82.9596805609661],
  ["Wales", 77.44383846253733],
  ["NG", 81.09888326191404],
  ["NP", 68.75405791455655],
  ["MK", 62.644786391377735],
  ["BD", 87.46786131671212],
  ["BS", 91.8854694195559],
  ["CU", 59.94481236203091],
  ["BF", 88.92156862745098],
  ["NR", 88.49110505129204],
  ["NE", 83.22847682119206],
  ["ZW", 87.54285157771719],
  ["VC", 87.6860148032723],
  ["UY", 75.14056616023893],
  ["TJ", 79.19101415400598],
  ["VU", 93.23107388650824],
  ["ZA", 78.83748863783924],
  ["BG", 83.20705103233347],
]);

export const psMap = new Map<string, number>([
  ["CY", 81.08001730103807],
  ["AF", 86.5787197231834],
  ["CN", 82.89359861591696],
  ["BJ", 87.0363321799308],
  ["LA", 83.55320069204151],
  ["ME", 78.39965397923876],
  ["OM", 82.47058823529412],
  ["NI", 73.99437716262976],
  ["MR", 65.20328719723183],
  ["TG", 69.76340830449827],
  ["TP", 96.67214532871972],
  ["North Korea", 91.74264705882354],
  ["VN", 80.02854671280278],
  ["ZM", 95.89878892733564],
  ["MD", 92.99264705882354],
  ["CO", 94.71712802768167],
  ["AG", 57.33477508650518],
  ["AE", 59.38321799307958],
  ["CZ", 84.35813148788928],
  ["AR", 78.04411764705883],
  ["BI", 83.67906574394463],
  ["CM", 95.04584775086505],
  ["LB", 76.12153979238754],
  ["LU", 70.44550173010381],
  ["TD", 94.88927335640139],
  ["TR", 81.42776816608996],
  ["Scotland", 82.78849480968859],
  ["UA", 97.23961937716264],
  ["LT", 94.21237024221453],
  ["LC", 96.96280276816609],
  ["MG", 76.02984429065745],
  ["CL", 76.75519031141869],
  ["BH", 97.43122837370242],
  ["AD", 92.66479238754324],
  ["CH", 83.66176470588236],
  ["NO", 86.94160899653978],
  ["MT", 92.02162629757785],
  ["MC", 79.50865051903115],
  ["TV", 86.11029411764706],
  ["TW", 92.64576124567473],
  ["US", 78.875],
  ["MU", 93.03892733564014],
  ["BZ", 81.88754325259515],
  ["CI", 88.38494809688582],
  ["BM", 78.80190311418686],
  ["CK", 86.07915224913495],
  ["AT", 67.55622837370242],
  ["BO", 63.86245674740484],
  ["LS", 49.6492214532872],
  ["NL", 72.88754325259515],
  ["MW", 64.15484429065744],
  ["YE", 71.7508650519031],
  ["UG", 77.86418685121107],
  ["TT", 70.67084775086505],
  ["NZ", 87.88538062283737],
  ["MA", 82.25259515570934],
  ["MV", 78.73140138408304],
  ["LR", 81.80320069204153],
  ["BY", 92.1825259515571],
  ["BN", 84.18555363321799],
  ["AU", 88.92430795847751],
  ["GE", 88.24870242214533],
  ["GR", 81.82482698961938],
  ["IN", 75.97188581314879],
  ["GBENG", 85.28373702422145],
  ["RS", 93.21842560553634],
  ["PL", 78.51340830449827],
  ["SV", 74.27335640138408],
  ["SA", 65.04801038062283],
  ["HK", 74.90484429065744],
  ["KG", 74.99783737024221],
  ["GD", 83.01600346020761],
  ["GQ", 72.24480968858131],
  ["DJ", 90.2590830449827],
  ["KE", 66.07439446366782],
  ["KR", 82.50389273356402],
  ["SC", 68.6219723183391],
  ["ST", 80.28287197231833],
  ["PY", 72.20198961937716],
  ["SB", 90.22015570934256],
  ["IL", 77.46323529411765],
  ["DK", 74.72621107266436],
  ["DO", 76.48918685121107],
  ["RU", 94.29195501730105],
  ["SG", 80.97534602076124],
  ["PK", 94.06055363321799],
  ["GB", 74.59212802768165],
  ["GU", 89.59515570934255],
  ["GW", 83.2136678200692],
  ["JP", 86.72923875432525],
  ["SR", 86.09039792387543],
  ["SE", 94.43425605536332],
  ["SD", 73.25519031141869],
  ["SS", 39.936851211072664],
  ["PH", 94.99048442906575],
  ["RW", 94.71453287197232],
  ["KU", 74.19117647058823],
  ["HN", 73.96193771626297],
  ["GA", 95.09558823529412],
  ["DZ", 68.05882352941175],
  ["DM", 65.75951557093425],
  ["FR", 84.61548442906575],
  ["GL", 77.90095155709342],
  ["ES", 90.9506920415225],
  ["HT", 86.10769896193771],
  ["PE", 71.0916955017301],
  ["QA", 96.13235294117646],
  ["SI", 93.54714532871972],
  ["PR", 85.2560553633218],
  ["PS", 0],
  ["IQ", 74.54584775086505],
  ["HU", 41.667387543252595],
  ["KN", 83.57569204152249],
  ["KY", 87.24913494809688],
  ["FI", 95.4939446366782],
  ["ER", 79.50259515570934],
  ["GM", 85.72015570934256],
  ["EE", 99.15657439446368],
  ["EG", 73.42647058823529],
  ["ID", 79.50821799307958],
  ["IS", 83.92430795847751],
  ["SK", 93.71237024221453],
  ["RO", 94.9243079584775],
  ["PG", 94.66608996539793],
  ["KM", 82.06055363321799],
  ["IR", 69.65743944636678],
  ["KZ", 91.08001730103807],
  ["IE", 85.45717993079585],
  ["GN", 81.50389273356402],
  ["FJ", 89.52378892733563],
  ["GY", 74.78070934256056],
  ["JM", 91.79411764705883],
  ["KI", 77.52508650519032],
  ["HR", 74.60034602076124],
  ["SO", 92.1613321799308],
  ["PT", 90.08520761245676],
  ["SY", 72.5432525951557],
  ["SN", 87.8287197231834],
  ["KH", 77.42430795847751],
  ["EC", 90.46885813148789],
  ["ET", 87.23010380622837],
  ["DE", 69.22188581314879],
  ["SL", 73.12543252595157],
  ["PW", 98.12110726643598],
  ["SZ", 76.0060553633218],
  ["PA", 84.78373702422145],
  ["SM", 89.56574394463668],
  ["IT", 78.95761245674741],
  ["JO", 7.163494809688581],
  ["GH", 62.2772491349481],
  ["CG", 94.51643598615917],
  ["BT", 91.10380622837371],
  ["AO", 88.98399653979239],
  ["ML", 95.81228373702422],
  ["TN", 74.73053633217994],
  ["LI", 86.8871107266436],
  ["MM", 85.36505190311419],
  ["MZ", 84.18814878892734],
  ["NA", 67.14489619377163],
  ["CF", 86.57612456747404],
  ["BB", 92.5817474048443],
  ["CD", 91.93166089965398],
  ["BW", 93.45025951557093],
  ["AL", 78.2136678200692],
  ["NC", 66.45025951557093],
  ["MX", 82.7067474048443],
  ["TZ", 84.79498269896195],
  ["VE", 92.86980968858131],
  ["MN", 86.35769896193771],
  ["MY", 79.0756920415225],
  ["AM", 95.00778546712803],
  ["CR", 88.57482698961938],
  ["BA", 87.85683391003461],
  ["AZ", 86.5493079584775],
  ["CV", 86.00994809688581],
  ["BR", 90.47058823529412],
  ["CA", 90.0432525951557],
  ["BE", 91.56141868512111],
  ["TH", 97.00346020761246],
  ["WS", 86.36505190311419],
  ["UZ", 72.48399653979239],
  ["Wales", 80.73140138408304],
  ["NG", 68.05406574394463],
  ["NP", 69.87802768166091],
  ["MK", 83.99437716262976],
  ["BD", 70.5575259515571],
  ["BS", 95.73442906574394],
  ["CU", 72.53892733564014],
  ["BF", 95.24264705882352],
  ["NR", 93.24524221453288],
  ["NE", 78.63970588235294],
  ["ZW", 95.60769896193771],
  ["VC", 92.75692041522491],
  ["UY", 88.22015570934256],
  ["TJ", 72.6583044982699],
  ["VU", 68.48745674740483],
  ["ZA", 83.69896193771626],
  ["BG", 94.32136678200692],
]);

export const iqMap = new Map<string, number>([
  ["CY", 73.66478379431243],
  ["AF", 81.92215296714713],
  ["CN", 66.30242825607064],
  ["BJ", 95.11524477340605],
  ["LA", 66.54200753148942],
  ["ME", 71.62381508894948],
  ["OM", 91.13134657836645],
  ["NI", 69.6899753278795],
  ["MR", 95.05031814050123],
  ["TG", 83.54953902090637],
  ["TP", 63.25347357486041],
  ["North Korea", 91.1306973120374],
  ["VN", 62.92137384755227],
  ["ZM", 90.98818335281132],
  ["MD", 83.58265160368784],
  ["CO", 94.39163744968187],
  ["AG", 89.2033502142579],
  ["AE", 44.24587715881054],
  ["CZ", 87.74087780807687],
  ["AR", 70.87553564472147],
  ["BI", 73.57648357356187],
  ["CM", 84.95260355797947],
  ["LB", 48.124594208544345],
  ["LU", 37.11888066484872],
  ["TD", 83.86735488897546],
  ["TR", 62.738605375925204],
  ["Scotland", 85.7089988313206],
  ["UA", 96.2089339046877],
  ["LT", 96.97279574081288],
  ["LC", 96.79587066614725],
  ["MG", 86.69393585248669],
  ["CL", 87.56168030125959],
  ["BH", 65.79600051941307],
  ["AD", 84.6724451369952],
  ["CH", 87.05882352941177],
  ["NO", 86.02551616673159],
  ["MT", 66.90592130892092],
  ["MC", 51.41799766264121],
  ["TV", 83.30151928320997],
  ["TW", 94.53350214257888],
  ["US", 71.61115439553305],
  ["MU", 70.9057265290222],
  ["BZ", 79.37313335930398],
  ["CI", 90.52330866121284],
  ["BM", 69.36501753019088],
  ["CK", 82.4730554473445],
  ["AT", 36.898130112972346],
  ["BO", 59.57992468510584],
  ["LS", 71.99454616283599],
  ["NL", 68.46545903129464],
  ["MW", 91.8906635501883],
  ["YE", 2.902545124009869],
  ["UG", 96.04012465913517],
  ["TT", 66.54752629528633],
  ["NZ", 84.23905986235553],
  ["MA", 64.5296065446046],
  ["MV", 68.06486170627191],
  ["LR", 72.12602259446825],
  ["BY", 93.98487209453317],
  ["BN", 77.88533956629009],
  ["AU", 88.25347357486041],
  ["GE", 85.07499026100507],
  ["GR", 80.07985975847292],
  ["IN", 70.44929229970134],
  ["GBENG", 82.86780937540578],
  ["RS", 92.0890144137125],
  ["PL", 83.03142449032593],
  ["SV", 70.3181405012336],
  ["SA", 91.02843786521231],
  ["HK", 60.98201532268537],
  ["KG", 62.1347876899104],
  ["GD", 76.27061420594728],
  ["GQ", 75.81288144396832],
  ["DJ", 86.03200883002208],
  ["KE", 92.55356447214648],
  ["KR", 71.5040254512401],
  ["SC", 72.06012206206987],
  ["ST", 88.1103103493053],
  ["PY", 37.328918322295806],
  ["SB", 89.68640436306973],
  ["IL", 71.43228152188027],
  ["DK", 55.761264770808985],
  ["DO", 65.79567588624855],
  ["RU", 98.15153876119984],
  ["SG", 53.35475912219192],
  ["PK", 85.98396312167252],
  ["GB", 72.45455135696662],
  ["GU", 88.69075444747435],
  ["GW", 84.10238930009089],
  ["JP", 74.44260485651213],
  ["SR", 94.35527853525517],
  ["SE", 94.9610440202571],
  ["SD", 18.561550447993767],
  ["SS", 93.46675756395273],
  ["PH", 87.35066874431891],
  ["RW", 93.12913907284768],
  ["KU", 72.67010777821062],
  ["HN", 69.74224126736787],
  ["GA", 92.4340994676016],
  ["DZ", 82.12115309700039],
  ["DM", 89.64160498636541],
  ["FR", 79.77340605116218],
  ["GL", 80.04317621088171],
  ["ES", 67.76814699389689],
  ["HT", 96.60725879755876],
  ["PE", 68.42358135307103],
  ["QA", 85.96870536293989],
  ["SI", 95.95539540319439],
  ["PR", 85.4635761589404],
  ["PS", 74.72276327749643],
  ["IQ", 0],
  ["HU", 36.235878457343205],
  ["KN", 84.26535514868199],
  ["KY", 84.09589663680042],
  ["FI", 93.4677314634463],
  ["ER", 83.4514998052201],
  ["GM", 54.654265679781844],
  ["EE", 99.77924944812362],
  ["EG", 4.043630697312038],
  ["ID", 51.44039735099337],
  ["IS", 86.25373328139203],
  ["SK", 95.12693156732892],
  ["RO", 83.89722114011168],
  ["PG", 43.86216075834307],
  ["KM", 85.24412413972212],
  ["IR", 66.22581482924296],
  ["KZ", 93.29567588624855],
  ["IE", 90.4285157771718],
  ["GN", 83.91118036618622],
  ["FJ", 83.58589793533308],
  ["GY", 83.98454746136865],
  ["JM", 86.78548240488249],
  ["KI", 55.72393195688872],
  ["HR", 66.47221140111674],
  ["SO", 94.6117387352292],
  ["PT", 93.24503311258279],
  ["SY", 4.110180496039476],
  ["SN", 83.91864692897026],
  ["KH", 85.0889494870796],
  ["EC", 92.63602129593559],
  ["ET", 90.76191403713804],
  ["DE", 98.3086612128295],
  ["SL", 69.34846123880016],
  ["PW", 95.12855473315153],
  ["SZ", 85.43955330476561],
  ["PA", 66.23035969354629],
  ["SM", 83.9933125568108],
  ["IT", 79.3504090377873],
  ["JO", 74.86852356836773],
  ["GH", 59.81917932736008],
  ["CG", 90.49116997792494],
  ["BT", 92.49967536683548],
  ["AO", 32.77950915465524],
  ["ML", 83.91832229580574],
  ["TN", 61.893585248669005],
  ["LI", 95.84209842877549],
  ["MM", 92.6886118685885],
  ["MZ", 90.24542267238022],
  ["NA", 82.75938189845475],
  ["CF", 78.34794182573692],
  ["BB", 95.2574340994676],
  ["CD", 88.46773146344631],
  ["BW", 91.80268796260226],
  ["AL", 67.18770289572782],
  ["NC", 92.4029346838073],
  ["MX", 82.25262952863265],
  ["TZ", 85.65283729385794],
  ["VE", 97.01305025321388],
  ["MN", 75.03181405012336],
  ["MY", 76.3488507985976],
  ["AM", 65.27269185820023],
  ["CR", 96.56765355148681],
  ["BA", 88.93033372289312],
  ["AZ", 96.50986884820153],
  ["CV", 90.87878197636671],
  ["BR", 91.46799116997792],
  ["CA", 91.16121282950266],
  ["BE", 73.70244124139722],
  ["TH", 82.57726269315673],
  ["WS", 78.81736138163875],
  ["UZ", 66.51993247630178],
  ["Wales", 82.2399688352162],
  ["NG", 90.27918452149072],
  ["NP", 73.4125438254772],
  ["MK", 84.92533437215945],
  ["BD", 95.42656797818465],
  ["BS", 88.3677444487729],
  ["CU", 81.6858200233736],
  ["BF", 96.29139072847683],
  ["NR", 95.12108817036749],
  ["NE", 72.77821062199715],
  ["ZW", 87.89929879236463],
  ["VC", 93.43072328269056],
  ["UY", 85.0782365926503],
  ["TJ", 69.15530450590832],
  ["VU", 64.25139592260746],
  ["ZA", 86.37611998441761],
  ["BG", 97.93403454096871],
]);

export const huMap = new Map<string, number>([
  ["CY", 71.54498269896193],
  ["AF", 93.43209342560553],
  ["CN", 68.11072664359862],
  ["BJ", 83.15614186851211],
  ["LA", 64.6825259515571],
  ["ME", 71.09385813148789],
  ["OM", 92.1038062283737],
  ["NI", 66.76730103806229],
  ["MR", 96.5121107266436],
  ["TG", 64.1371107266436],
  ["TP", 67.35337370242215],
  ["North Korea", 91.14489619377163],
  ["VN", 64.46323529411765],
  ["ZM", 95.95285467128028],
  ["MD", 84.85380622837371],
  ["CO", 94.41176470588235],
  ["AG", 89.2984429065744],
  ["AE", 66.04584775086505],
  ["CZ", 87.37629757785467],
  ["AR", 68.72448096885813],
  ["BI", 68.84083044982698],
  ["CM", 74.63451557093425],
  ["LB", 46.083044982698965],
  ["LU", 33.82352941176471],
  ["TD", 86.23140138408304],
  ["TR", 62.865051903114185],
  ["Scotland", 81.16911764705883],
  ["UA", 98.52941176470588],
  ["LT", 99.70588235294117],
  ["LC", 97.25562283737024],
  ["MG", 66.46020761245674],
  ["CL", 87.42474048442907],
  ["BH", 65.94117647058823],
  ["AD", 85.3174740484429],
  ["CH", 83.76730103806229],
  ["NO", 86.46064013840831],
  ["MT", 65.76686851211073],
  ["MC", 50],
  ["TV", 81.39143598615917],
  ["TW", 95.80103806228374],
  ["US", 69.96410034602076],
  ["MU", 48.23529411764706],
  ["BZ", 74.82482698961938],
  ["CI", 77.36029411764706],
  ["BM", 68.82396193771626],
  ["CK", 82.91608996539792],
  ["AT", 33.52941176470588],
  ["BO", 28.52941176470588],
  ["LS", 39.3871107266436],
  ["NL", 65.29411764705883],
  ["MW", 93.49567474048442],
  ["YE", 33.23529411764706],
  ["UG", 94.70891003460208],
  ["TT", 71.75562283737025],
  ["NZ", 85.10553633217994],
  ["MA", 66.47058823529412],
  ["MV", 64.14057093425606],
  ["LR", 70.52162629757785],
  ["BY", 93.3196366782007],
  ["BN", 81.52551903114187],
  ["AU", 90.08434256055364],
  ["GE", 84.26254325259515],
  ["GR", 78.90873702422145],
  ["IN", 68.18295847750865],
  ["GBENG", 83.95588235294117],
  ["RS", 90.64835640138408],
  ["PL", 82.94117647058825],
  ["SV", 67.44204152249135],
  ["SA", 60.79757785467128],
  ["HK", 59.97015570934256],
  ["KG", 60.40311418685121],
  ["GD", 71.0333044982699],
  ["GQ", 71.47015570934256],
  ["DJ", 83.84688581314879],
  ["KE", 93.03806228373702],
  ["KR", 75.01859861591696],
  ["SC", 54.5514705882353],
  ["ST", 87.00562283737024],
  ["PY", 34.97794117647059],
  ["SB", 89.43858131487889],
  ["IL", 69.12543252595155],
  ["DK", 53.8598615916955],
  ["DO", 63.40138408304499],
  ["RU", 99.70588235294117],
  ["SG", 51.51557093425606],
  ["PK", 86.17214532871972],
  ["GB", 69.88105536332179],
  ["GU", 88.98572664359861],
  ["GW", 63.50865051903114],
  ["JP", 75.27768166089966],
  ["SR", 94.8741349480969],
  ["SE", 97.59299307958477],
  ["SD", 44.66306228373703],
  ["SS", 93.18598615916956],
  ["PH", 86.60423875432525],
  ["RW", 94.74567474048443],
  ["KU", 72.67690311418686],
  ["HN", 66.78806228373702],
  ["GA", 93.8477508650519],
  ["DZ", 81.64100346020761],
  ["DM", 61.48615916955017],
  ["FR", 77.46064013840831],
  ["GL", 77.68468858131487],
  ["ES", 67.28806228373702],
  ["HT", 97.29584775086505],
  ["PE", 66.46539792387543],
  ["QA", 86.72318339100346],
  ["SI", 97.61851211072664],
  ["PR", 83.71712802768167],
  ["PS", 41.667387543252595],
  ["IQ", 36.160467128027676],
  ["HU", 0],
  ["KN", 91.8477508650519],
  ["KY", 84.0454152249135],
  ["FI", 94.79714532871972],
  ["ER", 79.5333044982699],
  ["GM", 55.88235294117647],
  ["EE", 99.70588235294117],
  ["EG", 34.91046712802768],
  ["ID", 50],
  ["IS", 85.24524221453287],
  ["SK", 95.7128027681661],
  ["RO", 86.27249134948097],
  ["PG", 70.68814878892734],
  ["KM", 83.01730103806229],
  ["IR", 58.94896193771626],
  ["KZ", 91.7166955017301],
  ["IE", 77.41435986159169],
  ["GN", 75.48010380622837],
  ["FJ", 83.85121107266436],
  ["GY", 64.89186851211073],
  ["JM", 77.60250865051903],
  ["KI", 54.36937716262976],
  ["HR", 64.23010380622839],
  ["SO", 94.76470588235294],
  ["PT", 92.75648788927336],
  ["SY", 35.07266435986159],
  ["SN", 74.23442906574394],
  ["KH", 84.22491349480968],
  ["EC", 90.9385813148789],
  ["ET", 87.47231833910034],
  ["DE", 100],
  ["SL", 65.88235294117646],
  ["PW", 96.31358131487889],
  ["SZ", 83.27205882352942],
  ["PA", 64.89186851211073],
  ["SM", 84.51643598615917],
  ["IT", 66.4182525951557],
  ["JO", 45.63970588235294],
  ["GH", 26.797145328719722],
  ["CG", 87.70501730103805],
  ["BT", 90.12543252595155],
  ["AO", 64.72318339100346],
  ["ML", 86.23226643598616],
  ["TN", 60.256055363321806],
  ["LI", 97.2037197231834],
  ["MM", 90.83088235294117],
  ["MZ", 89.38840830449827],
  ["NA", 61.20198961937716],
  ["CF", 77.89186851211073],
  ["BB", 96.31444636678201],
  ["CD", 88.21020761245674],
  ["BW", 91.17647058823529],
  ["AL", 68.13278546712803],
  ["NC", 65.09385813148789],
  ["MX", 70.375],
  ["TZ", 93.3507785467128],
  ["VE", 98.33607266435986],
  ["MN", 74.79714532871972],
  ["MY", 74.46929065743944],
  ["AM", 66.76470588235294],
  ["CR", 97.82093425605535],
  ["BA", 90.3901384083045],
  ["AZ", 97.8205017301038],
  ["CV", 88.86332179930795],
  ["BR", 64.43598615916954],
  ["CA", 90.71150519031141],
  ["BE", 86.71712802768165],
  ["TH", 84.11764705882354],
  ["WS", 80.4878892733564],
  ["UZ", 62.50692041522491],
  ["Wales", 78.40527681660899],
  ["NG", 66.46539792387543],
  ["NP", 70.96020761245676],
  ["MK", 85.0696366782007],
  ["BD", 67.51816608996539],
  ["BS", 92.9666955017301],
  ["CU", 81.99437716262976],
  ["BF", 65.48486159169549],
  ["NR", 96.8196366782007],
  ["NE", 71.29801038062283],
  ["ZW", 86.89273356401384],
  ["VC", 85.24610726643598],
  ["UY", 83.29368512110726],
  ["TJ", 65.97621107266436],
  ["VU", 42.35467128027682],
  ["ZA", 81.97145328719722],
  ["BG", 99.70588235294117],
]);

export const knMap = new Map<string, number>([
  ["CY", 88.79275719098212],
  ["AF", 80.65658201606634],
  ["CN", 66.03038351904638],
  ["BJ", 48.53265094584089],
  ["LA", 77.08927183208085],
  ["ME", 65.59860067374967],
  ["OM", 82.5353070743716],
  ["NI", 92.5372505830526],
  ["MR", 87.74682560248769],
  ["TG", 88.72117128789841],
  ["TP", 65.14997408655093],
  ["North Korea", 92.34451930551957],
  ["VN", 68.57735164550401],
  ["ZM", 89.42439751230889],
  ["MD", 70.83635656905935],
  ["CO", 74.95886239958539],
  ["AG", 62.96158331173879],
  ["AE", 80.91150557139156],
  ["CZ", 65.7851775071262],
  ["AR", 89.19150038870174],
  ["BI", 69.40982119720135],
  ["CM", 85.4651464109873],
  ["LB", 76.35430163254729],
  ["LU", 92.60818864990931],
  ["TD", 73.14135786473179],
  ["TR", 67.16733609743457],
  ["Scotland", 88.62270018139414],
  ["UA", 91.33324695516973],
  ["LT", 74.27377558953097],
  ["LC", 88.01891681782845],
  ["MG", 93.03414096916299],
  ["CL", 67.16863176988858],
  ["BH", 65.90794247214305],
  ["AD", 73.93366157035501],
  ["CH", 91.42621145374449],
  ["NO", 90.16584607411247],
  ["MT", 68.04774552993004],
  ["MC", 89.3554029541332],
  ["TV", 84.79495983415393],
  ["TW", 94.0677636693444],
  ["US", 68.02539518009847],
  ["MU", 90.8612982637989],
  ["BZ", 79.62878984192795],
  ["CI", 90.51179061933144],
  ["BM", 66.13047421611816],
  ["CK", 84.30454781031355],
  ["AT", 74.36058564394921],
  ["BO", 91.70996372117128],
  ["LS", 88.70756672713138],
  ["NL", 92.34905415910858],
  ["MW", 77.32994299041202],
  ["YE", 84.51865768333765],
  ["UG", 83.72959315884944],
  ["TT", 73.90515677636694],
  ["NZ", 85.01911116869655],
  ["MA", 68.37878984192795],
  ["MV", 72.66228297486396],
  ["LR", 76.16059860067375],
  ["BY", 90.26852811609226],
  ["BN", 85.81109095620627],
  ["AU", 83.12580979528376],
  ["GE", 87.18158849442861],
  ["GR", 91.15833117387925],
  ["IN", 90.31193314330137],
  ["GBENG", 83.15431458927182],
  ["RS", 87.0001943508681],
  ["PL", 68.74578906452449],
  ["SV", 91.18748380409433],
  ["SA", 91.00965275978233],
  ["HK", 68.21456335838299],
  ["KG", 66.92601710287639],
  ["GD", 75.08616221819125],
  ["GQ", 72.7118424462296],
  ["DJ", 89.37872505830526],
  ["KE", 78.04094324954652],
  ["KR", 85.1457631510754],
  ["SC", 90.19402694998703],
  ["ST", 76.39803057786992],
  ["PY", 90.59665716506868],
  ["SB", 89.2504534853589],
  ["IL", 85.38546255506608],
  ["DK", 71.71806167400881],
  ["DO", 91.37762373671936],
  ["RU", 72.50421093547551],
  ["SG", 85.25589530966572],
  ["PK", 88.50835708732832],
  ["GB", 76.4628142005701],
  ["GU", 84.08946618294894],
  ["GW", 90.3527468256025],
  ["JP", 87.2139803057787],
  ["SR", 82.30046644208345],
  ["SE", 89.93877947654832],
  ["SD", 85.08162736460223],
  ["SS", 76.8554029541332],
  ["PH", 66.7183855921223],
  ["RW", 92.10967867323141],
  ["KU", 71.08059082663902],
  ["HN", 92.70860326509458],
  ["GA", 73.60132158590308],
  ["DZ", 88.90580461259394],
  ["DM", 91.60080331692149],
  ["FR", 73.70886239958539],
  ["GL", 65.6054029541332],
  ["ES", 74.96177766260689],
  ["HT", 69.36803576055974],
  ["PE", 74.29839336615703],
  ["QA", 88.38494428608448],
  ["SI", 72.97259652759782],
  ["PR", 87.84335320031096],
  ["PS", 83.87859549105985],
  ["IQ", 84.52027727390515],
  ["HU", 92.37917854366417],
  ["KN", 0],
  ["KY", 84.56238662866028],
  ["FI", 89.13287121015807],
  ["ER", 75.80104949468775],
  ["GM", 92.4261466701218],
  ["EE", 84.0350479398808],
  ["EG", 84.5031096138896],
  ["ID", 89.54327545996372],
  ["IS", 86.43301373412801],
  ["SK", 91.04593158849443],
  ["RO", 72.3545607670381],
  ["PG", 69.35572687224669],
  ["KM", 79.27960611557398],
  ["IR", 86.98302669085255],
  ["KZ", 90.29346981083182],
  ["IE", 73.19869137082145],
  ["GN", 91.61440787768852],
  ["FJ", 85.22285566208862],
  ["GY", 78.79923555325213],
  ["JM", 78.49280901788028],
  ["KI", 82.05720393884425],
  ["HR", 88.9984451930552],
  ["SO", 94.51671417465664],
  ["PT", 90.0693184762892],
  ["SY", 84.77325732054936],
  ["SN", 56.79871728427054],
  ["KH", 80.898872764965],
  ["EC", 74.55493651204975],
  ["ET", 56.23315625809795],
  ["DE", 83.59516714174656],
  ["SL", 74.43055195646541],
  ["PW", 87.52785695776107],
  ["SZ", 71.90593417983933],
  ["PA", 88.92362010883649],
  ["SM", 88.45847369784919],
  ["IT", 56.331627364602234],
  ["JO", 84.01431718061674],
  ["GH", 89.04735682819384],
  ["CG", 41.232832339984455],
  ["BT", 89.14679968903862],
  ["AO", 80.14803057786992],
  ["ML", 55.522479917076964],
  ["TN", 67.60009069707178],
  ["LI", 67.04845814977973],
  ["MM", 73.95244882093806],
  ["MZ", 81.8230111427831],
  ["NA", 89.23134231666235],
  ["CF", 85.5338170510495],
  ["BB", 87.40541591085773],
  ["CD", 91.56290489764187],
  ["BW", 85.19888572168955],
  ["AL", 61.087717025136044],
  ["NC", 88.69914485618035],
  ["MX", 73.44584089142265],
  ["TZ", 33.80085514381964],
  ["VE", 71.0391293081109],
  ["MN", 71.88228815755377],
  ["MY", 74.79074889867842],
  ["AM", 90.65334283493132],
  ["CR", 82.18353200310962],
  ["BA", 83.90969162995596],
  ["AZ", 87.34549105986007],
  ["CV", 85.25362788287121],
  ["BR", 66.89135786473179],
  ["CA", 89.7496112982638],
  ["BE", 67.19810831821715],
  ["TH", 80.26528893495724],
  ["WS", 66.08868877947654],
  ["UZ", 88.85980824047681],
  ["Wales", 80.05798134231667],
  ["NG", 74.37613371339725],
  ["NP", 75.95523451671417],
  ["MK", 79.0033039647577],
  ["BD", 92.24863954392329],
  ["BS", 88.27578388183467],
  ["CU", 85.05798134231665],
  ["BF", 92.28167919150039],
  ["NR", 88.40049235553252],
  ["NE", 92.44525783881835],
  ["ZW", 78.41766001554808],
  ["VC", 90.4311350090697],
  ["UY", 86.52176729722726],
  ["TJ", 88.24209639803058],
  ["VU", 84.57080849961129],
  ["ZA", 91.52014770665976],
  ["BG", 74.46164809536148],
]);

export const kyMap = new Map<string, number>([
  ["CY", 87.03157439446368],
  ["AF", 82.67690311418686],
  ["CN", 82.26859861591696],
  ["BJ", 89.16695501730104],
  ["LA", 61.43425605536332],
  ["ME", 81.84212802768165],
  ["OM", 81.9325259515571],
  ["NI", 89.72923875432525],
  ["MR", 86.40051903114187],
  ["TG", 83.47361591695501],
  ["TP", 82.36505190311418],
  ["North Korea", 80.95891003460207],
  ["VN", 80.1825259515571],
  ["ZM", 86.3083910034602],
  ["MD", 84.89792387543253],
  ["CO", 68.94377162629758],
  ["AG", 84.23615916955018],
  ["AE", 87.69593425605537],
  ["CZ", 85.96193771626297],
  ["AR", 92.9969723183391],
  ["BI", 83.93728373702423],
  ["CM", 87.03892733564014],
  ["LB", 86.38884083044984],
  ["LU", 86.35467128027682],
  ["TD", 68.52854671280278],
  ["TR", 80.465830449827],
  ["Scotland", 82.51903114186851],
  ["UA", 88.86721453287197],
  ["LT", 90.05147058823529],
  ["LC", 93.38754325259515],
  ["MG", 87.70631487889273],
  ["CL", 88.784169550173],
  ["BH", 82.68468858131487],
  ["AD", 87.5030276816609],
  ["CH", 87.20025951557093],
  ["NO", 74.13927335640138],
  ["MT", 85.1280276816609],
  ["MC", 82.7772491349481],
  ["TV", 71.62240484429066],
  ["TW", 85.24351211072666],
  ["US", 83.72275086505189],
  ["MU", 75.48961937716263],
  ["BZ", 82.51730103806229],
  ["CI", 91.1825259515571],
  ["BM", 68.35553633217994],
  ["CK", 9.789792387543253],
  ["AT", 86.23313148788927],
  ["BO", 84.39878892733564],
  ["LS", 88.02162629757785],
  ["NL", 89.41738754325259],
  ["MW", 83.31098615916956],
  ["YE", 84.94204152249135],
  ["UG", 81.32352941176471],
  ["TT", 84.2136678200692],
  ["NZ", 7.123269896193772],
  ["MA", 79.46929065743944],
  ["MV", 82.6029411764706],
  ["LR", 83.11461937716264],
  ["BY", 81.18166089965399],
  ["BN", 80.63062283737025],
  ["AU", 74.90181660899654],
  ["GE", 83.06055363321799],
  ["GR", 88.28027681660899],
  ["IN", 91.15484429065745],
  ["GBENG", 86.06574394463668],
  ["RS", 68.53243944636678],
  ["PL", 87.12975778546713],
  ["SV", 89.63581314878893],
  ["SA", 83.79974048442907],
  ["HK", 79.37716262975778],
  ["KG", 75.93512110726644],
  ["GD", 78.34385813148789],
  ["GQ", 88.51859861591696],
  ["DJ", 90.22404844290658],
  ["KE", 83.0635813148789],
  ["KR", 79.32006920415225],
  ["SC", 76.40484429065744],
  ["ST", 88.18166089965398],
  ["PY", 83.2590830449827],
  ["SB", 81.6128892733564],
  ["IL", 86.26167820069203],
  ["DK", 83.30233564013841],
  ["DO", 75.11548442906575],
  ["RU", 85.97923875432527],
  ["SG", 85.18425605536332],
  ["PK", 86.27465397923876],
  ["GB", 58.96193771626298],
  ["GU", 34.66306228373702],
  ["GW", 83.49307958477509],
  ["JP", 85.77984429065744],
  ["SR", 87.03849480968859],
  ["SE", 88.53373702422145],
  ["SD", 84.18944636678201],
  ["SS", 85.1682525951557],
  ["PH", 86.4325259515571],
  ["RW", 91.12067474048443],
  ["KU", 88.54584775086505],
  ["HN", 89.20328719723183],
  ["GA", 91.75562283737024],
  ["DZ", 87.33650519031141],
  ["DM", 81.08434256055364],
  ["FR", 69.27897923875432],
  ["GL", 83.8265570934256],
  ["ES", 84.34385813148789],
  ["HT", 86.51859861591696],
  ["PE", 83.02335640138409],
  ["QA", 84.59818339100346],
  ["SI", 84.47664359861592],
  ["PR", 83.3992214532872],
  ["PS", 87.24913494809688],
  ["IQ", 83.6712802768166],
  ["HU", 84.0454152249135],
  ["KN", 83.79714532871972],
  ["KY", 0],
  ["FI", 63.85034602076125],
  ["ER", 78.72188581314879],
  ["GM", 69.47145328719722],
  ["EE", 91.72448096885813],
  ["EG", 85.00173010380622],
  ["ID", 82.95934256055362],
  ["IS", 41.25043252595156],
  ["SK", 81.2772491349481],
  ["RO", 68.58996539792388],
  ["PG", 83.60899653979239],
  ["KM", 88.07050173010381],
  ["IR", 86.17560553633218],
  ["KZ", 91.79195501730104],
  ["IE", 91.49740484429066],
  ["GN", 83.73269896193771],
  ["FJ", 69.51903114186851],
  ["GY", 83.39576124567473],
  ["JM", 85.0696366782007],
  ["KI", 58.07093425605536],
  ["HR", 57.720155709342556],
  ["SO", 92.20025951557093],
  ["PT", 82.44896193771626],
  ["SY", 84.2015570934256],
  ["SN", 87.31012110726644],
  ["KH", 81.97923875432525],
  ["EC", 83.50173010380622],
  ["ET", 80.89316608996539],
  ["DE", 88.89835640138408],
  ["SL", 91.00173010380624],
  ["PW", 94.49178200692042],
  ["SZ", 77.44852941176471],
  ["PA", 84.33650519031141],
  ["SM", 85.74134948096885],
  ["IT", 87.78287197231835],
  ["JO", 85.33044982698962],
  ["GH", 82.7681660899654],
  ["CG", 86.25216262975779],
  ["BT", 93.84429065743944],
  ["AO", 77.59904844290656],
  ["ML", 89.10423875432527],
  ["TN", 79.39662629757785],
  ["LI", 62.10553633217993],
  ["MM", 89.02292387543253],
  ["MZ", 83.42863321799308],
  ["NA", 69.43901384083046],
  ["CF", 75.9325259515571],
  ["BB", 44.02984429065744],
  ["CD", 88.86980968858131],
  ["BW", 94.21323529411765],
  ["AL", 72.05622837370242],
  ["NC", 85.39965397923875],
  ["MX", 86.3659169550173],
  ["TZ", 87.07396193771626],
  ["VE", 67.07612456747405],
  ["MN", 81.0635813148789],
  ["MY", 80.05363321799308],
  ["AM", 82.11029411764706],
  ["CR", 58.17733564013841],
  ["BA", 36.77984429065744],
  ["AZ", 86.80925605536332],
  ["CV", 40.6643598615917],
  ["BR", 77.8235294117647],
  ["CA", 85.01903114186852],
  ["BE", 90.49307958477509],
  ["TH", 86.83434256055364],
  ["WS", 81.97967128027682],
  ["UZ", 89.79455017301038],
  ["Wales", 71.49610726643598],
  ["NG", 88.0008650519031],
  ["NP", 71.61418685121107],
  ["MK", 75.55709342560554],
  ["BD", 86.22102076124567],
  ["BS", 90.70198961937717],
  ["CU", 47.32179930795848],
  ["BF", 85.54887543252595],
  ["NR", 29.152681660899653],
  ["NE", 92.07612456747405],
  ["ZW", 84.16695501730104],
  ["VC", 89.0886678200692],
  ["UY", 85.0363321799308],
  ["TJ", 88.6362456747405],
  ["VU", 78.01038062283737],
  ["ZA", 69.409169550173],
  ["BG", 87.11937716262976],
]);

export const fiMap = new Map<string, number>([
  ["CY", 42.78669400452489],
  ["AF", 91.6848133484163],
  ["CN", 96.06723699095022],
  ["BJ", 97.54348133484163],
  ["LA", 74.5895786199095],
  ["ME", 94.56695418552036],
  ["OM", 78.08752828054298],
  ["NI", 94.8985435520362],
  ["MR", 95.55889423076923],
  ["TG", 93.24448529411765],
  ["TP", 94.36899038461539],
  ["North Korea", 91.49038461538461],
  ["VN", 96.66501696832579],
  ["ZM", 97.95885180995475],
  ["MD", 93.75282805429865],
  ["CO", 82.47808257918552],
  ["AG", 88.45305429864253],
  ["AE", 96.40165441176471],
  ["CZ", 62.205882352941174],
  ["AR", 94.43226809954751],
  ["BI", 75.48889988687783],
  ["CM", 96.29136029411765],
  ["LB", 79.29899604072398],
  ["LU", 94.99469739819004],
  ["TD", 86.00501979638008],
  ["TR", 95.5910633484163],
  ["Scotland", 70.18983314479638],
  ["UA", 96.8622737556561],
  ["LT", 98.93134898190046],
  ["LC", 95.59848699095022],
  ["MG", 78.80656108597285],
  ["CL", 77.52439196832579],
  ["BH", 76.4366515837104],
  ["AD", 96.20333710407239],
  ["CH", 92.27340214932127],
  ["NO", 77.59544683257919],
  ["MT", 76.65830033936652],
  ["MC", 69.10845588235294],
  ["TV", 87.7541713800905],
  ["TW", 93.47426470588235],
  ["US", 71.21111425339367],
  ["MU", 82.79765271493213],
  ["BZ", 72.59509332579186],
  ["CI", 83.50608031674209],
  ["BM", 84.92470305429865],
  ["CK", 62.257140837104075],
  ["AT", 94.98833427601811],
  ["BO", 96.65617929864253],
  ["LS", 84.89925056561086],
  ["NL", 94.74830316742081],
  ["MW", 95.53450226244344],
  ["YE", 95.04807692307692],
  ["UG", 93.03132070135747],
  ["TT", 91.14819004524887],
  ["NZ", 63.97942590497737],
  ["MA", 95.70241798642533],
  ["MV", 97.65660350678733],
  ["LR", 70.15271493212668],
  ["BY", 94.13249434389141],
  ["BN", 80.08519513574662],
  ["AU", 87.0743778280543],
  ["GE", 50.63489819004525],
  ["GR", 74.75996889140272],
  ["IN", 93.42335972850678],
  ["GBENG", 47.40031108597285],
  ["RS", 46.94181278280543],
  ["PL", 69.34848699095024],
  ["SV", 94.24632352941177],
  ["SA", 91.81101527149322],
  ["HK", 93.78923925339366],
  ["KG", 90.99830316742081],
  ["GD", 89.66240101809954],
  ["GQ", 94.82324660633485],
  ["DJ", 85.1014564479638],
  ["KE", 87.14295814479638],
  ["KR", 43.352658371040725],
  ["SC", 81.44902432126698],
  ["ST", 94.07734728506787],
  ["PY", 93.5516826923077],
  ["SB", 88.95468042986425],
  ["IL", 63.14727092760181],
  ["DK", 98.48981900452489],
  ["DO", 83.0772765837104],
  ["RU", 71.22525452488688],
  ["SG", 65.68651018099547],
  ["PK", 76.73430429864253],
  ["GB", 70.03216911764706],
  ["GU", 65.81660067873302],
  ["GW", 95.0339366515837],
  ["JP", 43.84403280542987],
  ["SR", 81.50205033936652],
  ["SE", 91.5462386877828],
  ["SD", 95.80210690045249],
  ["SS", 90.53839083710407],
  ["PH", 86.34756787330316],
  ["RW", 97.90794683257919],
  ["KU", 96.43947963800905],
  ["HN", 94.29581447963801],
  ["GA", 96.58371040723982],
  ["DZ", 60.80387443438914],
  ["DM", 89.71613404977376],
  ["FR", 70.92335972850678],
  ["GL", 68.2614536199095],
  ["ES", 93.54425904977376],
  ["HT", 81.6954185520362],
  ["PE", 83.48098133484163],
  ["QA", 76.37690893665159],
  ["SI", 71.289592760181],
  ["PR", 76.22348699095024],
  ["PS", 95.61192024886877],
  ["IQ", 93.44633766968326],
  ["HU", 94.94980203619909],
  ["KN", 88.80514705882354],
  ["KY", 64.14592760180996],
  ["FI", 0],
  ["ER", 90.88871606334841],
  ["GM", 73.06879242081448],
  ["EE", 71.73748585972851],
  ["EG", 95.13256504524887],
  ["ID", 69.34884049773756],
  ["IS", 94.49413178733032],
  ["SK", 66.90610859728507],
  ["RO", 85.6787330316742],
  ["PG", 92.75982748868779],
  ["KM", 87.09134615384615],
  ["IR", 89.06462104072398],
  ["KZ", 97.61913178733032],
  ["IE", 83.44138857466064],
  ["GN", 97.92526866515837],
  ["FJ", 87.10053733031674],
  ["GY", 87.99809106334841],
  ["JM", 89.81122737556561],
  ["KI", 77.33915441176471],
  ["HR", 91.66749151583711],
  ["SO", 99.05048076923076],
  ["PT", 92.50318156108597],
  ["SY", 93.78181561085974],
  ["SN", 96.98317307692308],
  ["KH", 93.82352941176471],
  ["EC", 91.72299208144796],
  ["ET", 90.45390271493213],
  ["DE", 98.01329185520362],
  ["SL", 94.9929298642534],
  ["PW", 98.12782805429863],
  ["SZ", 87.52651300904978],
  ["PA", 58.63440328054299],
  ["SM", 67.460407239819],
  ["IT", 83.40179581447964],
  ["JO", 94.36616233031674],
  ["GH", 95.14882635746606],
  ["CG", 93.50855486425338],
  ["BT", 92.45086255656109],
  ["AO", 92.49929298642535],
  ["ML", 98.36255656108597],
  ["TN", 94.09148755656108],
  ["LI", 79.0112415158371],
  ["MM", 95.36446549773756],
  ["MZ", 89.67265271493213],
  ["NA", 77.17300622171945],
  ["CF", 86.5929015837104],
  ["BB", 73.68035916289593],
  ["CD", 94.76138291855204],
  ["BW", 98.42406674208145],
  ["AL", 88.82211538461539],
  ["NC", 93.86559671945702],
  ["MX", 82.52085690045249],
  ["TZ", 92.97369909502262],
  ["VE", 72.65377545248869],
  ["MN", 78.92887443438914],
  ["MY", 68.88574660633483],
  ["AM", 97.4335407239819],
  ["CR", 64.70694287330316],
  ["BA", 69.03315893665159],
  ["AZ", 96.76576640271493],
  ["CV", 69.51993778280543],
  ["BR", 86.04779411764706],
  ["CA", 76.88030260180996],
  ["BE", 97.83264988687783],
  ["TH", 72.32642816742081],
  ["WS", 86.51901866515837],
  ["UZ", 94.88016119909503],
  ["Wales", 57.51767533936651],
  ["NG", 83.48098133484163],
  ["NP", 57.26633201357466],
  ["MK", 84.08194287330318],
  ["BD", 95.90497737556561],
  ["BS", 96.3723133484163],
  ["CU", 59.52029128959276],
  ["BF", 96.93615667420814],
  ["NR", 68.49653563348417],
  ["NE", 95.00565610859728],
  ["ZW", 85.30755090497738],
  ["VC", 94.2933399321267],
  ["UY", 60.457791289592755],
  ["TJ", 87.26703902714932],
  ["VU", 87.71387160633483],
  ["ZA", 88.57466063348416],
  ["BG", 71.29383484162895],
]);

export const erMap = new Map<string, number>([
  ["CY", 89.9335863377609],
  ["AF", 85.66284284974988],
  ["CN", 81.04924961186822],
  ["BJ", 77.23693289632568],
  ["LA", 75.36441262722097],
  ["ME", 74.25478695877177],
  ["OM", 60.348887355528724],
  ["NI", 86.3666551664654],
  ["MR", 80.46748318095567],
  ["TG", 77.19639468690703],
  ["TP", 83.33707089874073],
  ["North Korea", 58.35604623080904],
  ["VN", 80.04485078488874],
  ["ZM", 79.56960496808694],
  ["MD", 76.89494566154907],
  ["CO", 84.36346386061756],
  ["AG", 73.02354666206658],
  ["AE", 57.32102811799207],
  ["CZ", 77.5491633603588],
  ["AR", 89.90771088494049],
  ["BI", 74.27117474555804],
  ["CM", 74.31947559082283],
  ["LB", 75.40667586682767],
  ["LU", 86.64611005692599],
  ["TD", 87.6953596687942],
  ["TR", 80.02156287735035],
  ["Scotland", 81.7500431257547],
  ["UA", 88.33620838364672],
  ["LT", 85.61799206486114],
  ["LC", 90.72063136104882],
  ["MG", 87.93125754700706],
  ["CL", 85.45023287907539],
  ["BH", 69.35138864930136],
  ["AD", 81.33387959289287],
  ["CH", 83.29437640158702],
  ["NO", 83.1956184233224],
  ["MT", 79.16982922201139],
  ["MC", 73.11411074693808],
  ["TV", 68.52725547697085],
  ["TW", 82.64317750560635],
  ["US", 80.77195100914267],
  ["MU", 86.03113679489391],
  ["BZ", 77.2140762463343],
  ["CI", 88.29135759875798],
  ["BM", 59.59720545109539],
  ["CK", 81.45420044850785],
  ["AT", 86.79101259272038],
  ["BO", 85.70855614973262],
  ["LS", 88.9140934966362],
  ["NL", 86.7435742625496],
  ["MW", 65.03924443677764],
  ["YE", 84.82706572365016],
  ["UG", 88.11324823184405],
  ["TT", 65.68440572710023],
  ["NZ", 80.3773503536312],
  ["MA", 79.06028980507159],
  ["MV", 65.32775573572538],
  ["LR", 85.15050888390547],
  ["BY", 77.69104709332413],
  ["BN", 90.65335518371572],
  ["AU", 84.42211488701052],
  ["GE", 85.25185440745213],
  ["GR", 87.19811971709504],
  ["IN", 87.43488011040193],
  ["GBENG", 76.50897015697775],
  ["RS", 82.8838192168363],
  ["PL", 72.85190615835776],
  ["SV", 85.63782991202345],
  ["SA", 78.25642573745041],
  ["HK", 79.0671899258237],
  ["KG", 57.01656028980507],
  ["GD", 68.8601863032603],
  ["GQ", 83.57253751940658],
  ["DJ", 85.6831119544592],
  ["KE", 85.34500603760566],
  ["KR", 92.24081421424874],
  ["SC", 82.51035018112816],
  ["ST", 57.77643608763153],
  ["PY", 80.53432810074176],
  ["SB", 76.67327928238744],
  ["IL", 87.9756770743488],
  ["DK", 84.92409867172675],
  ["DO", 79.33974469553218],
  ["RU", 87.15801276522339],
  ["SG", 87.02173538036917],
  ["PK", 89.61402449542867],
  ["GB", 66.0725375194066],
  ["GU", 81.03501811281697],
  ["GW", 71.585734000345],
  ["JP", 91.60729687769535],
  ["SR", 83.24003795066413],
  ["SE", 85.66025530446782],
  ["SD", 85.46791443850267],
  ["SS", 72.85664999137484],
  ["PH", 73.84854234949113],
  ["RW", 80.10824564429878],
  ["KU", 85.47352078661376],
  ["HN", 86.79834397101949],
  ["GA", 65.54122822149388],
  ["DZ", 82.5133689839572],
  ["DM", 76.25237191650854],
  ["FR", 87.92263239606693],
  ["GL", 72.58064516129032],
  ["ES", 85.4510953941694],
  ["HT", 69.25780576160084],
  ["PE", 66.75909953424186],
  ["QA", 88.9460065551147],
  ["SI", 78.0671036743143],
  ["PR", 80.27686734517854],
  ["PS", 79.83051578402622],
  ["IQ", 83.5250991892358],
  ["HU", 80.18500948766604],
  ["KN", 75.92504743833017],
  ["KY", 78.7316715542522],
  ["FI", 91.09927548732102],
  ["ER", 0],
  ["GM", 78.32197688459547],
  ["EE", 94.18362946351562],
  ["EG", 84.69035708124892],
  ["ID", 73.54666206658617],
  ["IS", 85.5300155252717],
  ["SK", 74.9271174745558],
  ["RO", 83.50914265999656],
  ["PG", 80.3601000517509],
  ["KM", 59.72097636708642],
  ["IR", 62.409867172675526],
  ["KZ", 73.08090391581852],
  ["IE", 85.27126099706744],
  ["GN", 70.7443505261342],
  ["FJ", 76.89106434362601],
  ["GY", 56.594790408832154],
  ["JM", 77.58064516129032],
  ["KI", 69.47386579265137],
  ["HR", 83.84466103156805],
  ["SO", 58.64067621183371],
  ["PT", 78.27281352423667],
  ["SY", 83.4996549939624],
  ["SN", 80.33163705364844],
  ["KH", 67.80705537346904],
  ["EC", 77.2011385199241],
  ["ET", 72.04717957564257],
  ["DE", 90.26306710367432],
  ["SL", 66.36751768155943],
  ["PW", 87.97308952906675],
  ["SZ", 78.353458685527],
  ["PA", 82.91961359323788],
  ["SM", 86.73063653613939],
  ["IT", 83.95204416077281],
  ["JO", 75.91555977229602],
  ["GH", 78.74417802311541],
  ["CG", 76.45635673624288],
  ["BT", 83.41081593927893],
  ["AO", 77.08297395204417],
  ["ML", 78.95031913058479],
  ["TN", 80.09573917543557],
  ["LI", 68.91323098154217],
  ["MM", 83.83603588062792],
  ["MZ", 79.36950146627566],
  ["NA", 65.72063136104882],
  ["CF", 79.83094704157322],
  ["BB", 89.1827669484216],
  ["CD", 71.82206313610489],
  ["BW", 91.24892185613248],
  ["AL", 59.374676556839745],
  ["NC", 82.15499396239434],
  ["MX", 79.21511126444713],
  ["TZ", 76.82810074176298],
  ["VE", 77.8234431602553],
  ["MN", 80.1707779886148],
  ["MY", 76.1160945316543],
  ["AM", 85.40753838192168],
  ["CR", 71.341210971192],
  ["BA", 86.17517681559427],
  ["AZ", 66.03674314300501],
  ["CV", 81.85570122477144],
  ["BR", 59.69811971709505],
  ["CA", 84.5859927548732],
  ["BE", 94.89347938588925],
  ["TH", 84.48249094359151],
  ["WS", 62.22658271519752],
  ["UZ", 83.13222356391236],
  ["Wales", 70.30834914611006],
  ["NG", 85.77022597895463],
  ["NP", 67.83681214421252],
  ["MK", 74.24486803519062],
  ["BD", 79.57823011902708],
  ["BS", 89.24529929273763],
  ["CU", 77.15456270484734],
  ["BF", 80.30058651026393],
  ["NR", 87.12739347938589],
  ["NE", 85.74133172330517],
  ["ZW", 86.70519234086596],
  ["VC", 85.05606348111093],
  ["UY", 89.13015352768674],
  ["TJ", 88.94169397964464],
  ["VU", 82.39304812834224],
  ["ZA", 85.58262894600655],
  ["BG", 85.92289115059513],
]);

export const gmMap = new Map<string, number>([
  ["CY", 88.12904897641877],
  ["AF", 91.45115314848407],
  ["CN", 67.66455040165846],
  ["BJ", 97.2884814718839],
  ["LA", 69.70944545218968],
  ["ME", 69.62328323399845],
  ["OM", 91.80519564654055],
  ["NI", 88.71015807203939],
  ["MR", 95.06640321326768],
  ["TG", 80.82955428867582],
  ["TP", 67.89874319771961],
  ["North Korea", 92.44007514900233],
  ["VN", 63.74384555584348],
  ["ZM", 95.001295672454],
  ["MD", 83.2116480953615],
  ["CO", 85.90308370044053],
  ["AG", 87.99235553252139],
  ["AE", 82.99105986006737],
  ["CZ", 92.47505830526043],
  ["AR", 89.02273905156777],
  ["BI", 75.08551438196423],
  ["CM", 84.74248509976678],
  ["LB", 64.49954651464111],
  ["LU", 56.82819383259912],
  ["TD", 85.54126716766002],
  ["TR", 63.90612853070744],
  ["Scotland", 87.7102228556621],
  ["UA", 95.15418502202643],
  ["LT", 99.33920704845815],
  ["LC", 97.56284011401918],
  ["MG", 95.37380150298004],
  ["CL", 91.32514900233221],
  ["BH", 71.97071780253953],
  ["AD", 79.58570873283234],
  ["CH", 95.12179321067634],
  ["NO", 93.5034983156258],
  ["MT", 76.76341020989894],
  ["MC", 60.57268722466961],
  ["TV", 80.39679968903862],
  ["TW", 89.02630215081628],
  ["US", 80.08195128271572],
  ["MU", 60.13215859030837],
  ["BZ", 82.0001943508681],
  ["CI", 95.83894791396736],
  ["BM", 66.58590308370044],
  ["CK", 67.39569836745271],
  ["AT", 56.6079295154185],
  ["BO", 59.471365638766514],
  ["LS", 89.43897382741642],
  ["NL", 88.54625550660793],
  ["MW", 92.91753044830267],
  ["YE", 56.167400881057276],
  ["UG", 93.33894791396735],
  ["TT", 72.41448561803577],
  ["NZ", 67.70244882093806],
  ["MA", 63.22849183726355],
  ["MV", 69.34471365638767],
  ["LR", 78.98030577869915],
  ["BY", 92.90457372376262],
  ["BN", 87.36913708214563],
  ["AU", 73.05681523710807],
  ["GE", 84.39265353718581],
  ["GR", 88.83907748121274],
  ["IN", 88.06556102617257],
  ["GBENG", 85.87943767815496],
  ["RS", 91.79126716766002],
  ["PL", 94.27312775330397],
  ["SV", 88.31692148224928],
  ["SA", 91.49455817569319],
  ["HK", 62.34419538740607],
  ["KG", 60.00226742679451],
  ["GD", 72.503239181135],
  ["GQ", 87.498056491319],
  ["DJ", 93.35125680228037],
  ["KE", 90.69318476289195],
  ["KR", 85.45996372117128],
  ["SC", 73.66124643690075],
  ["ST", 86.54735682819383],
  ["PY", 54.82767556361752],
  ["SB", 63.99488209380668],
  ["IL", 86.32903601969421],
  ["DK", 68.33020212490283],
  ["DO", 82.66519823788546],
  ["RU", 77.97356828193833],
  ["SG", 63.3233998445193],
  ["PK", 93.1066986265872],
  ["GB", 71.20594713656388],
  ["GU", 71.30992485099766],
  ["GW", 84.6061155739829],
  ["JP", 88.34736978491837],
  ["SR", 75.94357346462814],
  ["SE", 97.77500647836227],
  ["SD", 60.28407618554029],
  ["SS", 91.52111946100025],
  ["PH", 87.72155998963463],
  ["RW", 67.323464628142],
  ["KU", 89.77390515677637],
  ["HN", 88.64019175952319],
  ["GA", 92.50323918113502],
  ["DZ", 89.98736719357346],
  ["DM", 90.45283752267427],
  ["FR", 77.92562840114019],
  ["GL", 88.40794247214305],
  ["ES", 66.10261725835709],
  ["HT", 88.931070225447],
  ["PE", 73.69104690334284],
  ["QA", 93.29910598600674],
  ["SI", 96.76438196423945],
  ["PR", 83.85948432236331],
  ["PS", 85.59730500129568],
  ["IQ", 54.79625550660793],
  ["HU", 55.947136563876654],
  ["KN", 92.4261466701218],
  ["KY", 69.39816014511531],
  ["FI", 73.035436641617],
  ["ER", 78.19707178025396],
  ["GM", 0],
  ["EE", 99.11894273127754],
  ["EG", 56.325472920445705],
  ["ID", 60.792951541850215],
  ["IS", 94.66992744234257],
  ["SK", 94.42601710287639],
  ["RO", 78.57378854625551],
  ["PG", 69.10015548069448],
  ["KM", 86.81264576315108],
  ["IR", 79.34730500129568],
  ["KZ", 93.54657942472143],
  ["IE", 95.86518528116092],
  ["GN", 85.85222855662089],
  ["FJ", 84.38941435605079],
  ["GY", 86.97168955688002],
  ["JM", 90.82663902565432],
  ["KI", 59.449663125161955],
  ["HR", 83.48406322881576],
  ["SO", 98.35805908266391],
  ["PT", 92.44590567504535],
  ["SY", 54.9284140969163],
  ["SN", 84.8613630474216],
  ["KH", 85.535436641617],
  ["EC", 91.5758616221819],
  ["ET", 84.72952837522675],
  ["DE", 99.11894273127754],
  ["SL", 88.76651982378854],
  ["PW", 96.54670899196684],
  ["SZ", 81.54606115573982],
  ["PA", 75.90049235553252],
  ["SM", 91.84892459186318],
  ["IT", 84.65729463591603],
  ["JO", 83.5005830526043],
  ["GH", 58.16111686965535],
  ["CG", 90.41753044830267],
  ["BT", 94.88436123348018],
  ["AO", 61.11103912930811],
  ["ML", 85.81400621922778],
  ["TN", 63.080785177507124],
  ["LI", 86.31737496760819],
  ["MM", 96.60404249805649],
  ["MZ", 87.06530189168178],
  ["NA", 75.64556880020731],
  ["CF", 86.23769111168697],
  ["BB", 80.44247214304224],
  ["CD", 88.97771443379114],
  ["BW", 96.47577092511013],
  ["AL", 61.44694221300855],
  ["NC", 93.79534853589013],
  ["MX", 84.13708214563358],
  ["TZ", 94.30519564654055],
  ["VE", 78.82547292044572],
  ["MN", 73.62820678932366],
  ["MY", 78.13034464887276],
  ["AM", 44.49339207048458],
  ["CR", 97.33706918890904],
  ["BA", 76.25323918113502],
  ["AZ", 96.69150038870174],
  ["CV", 87.02189686447267],
  ["BR", 87.2133324695517],
  ["CA", 92.7170251360456],
  ["BE", 86.36337133972532],
  ["TH", 83.25991189427313],
  ["WS", 75.04955947136564],
  ["UZ", 85.0732054936512],
  ["Wales", 74.83674527079555],
  ["NG", 95.79424721430424],
  ["NP", 73.78109613889609],
  ["MK", 83.55338170510494],
  ["BD", 94.70426276237367],
  ["BS", 91.8554029541332],
  ["CU", 71.02163772998186],
  ["BF", 98.66513345426277],
  ["NR", 84.44545218968645],
  ["NE", 89.36058564394921],
  ["ZW", 87.32832339984452],
  ["VC", 95.4288675822752],
  ["UY", 88.48859808240476],
  ["TJ", 88.37522674267944],
  ["VU", 64.11084477844001],
  ["ZA", 88.50608966053382],
  ["BG", 99.55947136563876],
]);

export const eeMap = new Map<string, number>([
  ["CY", 67.58524656975953],
  ["AF", 83.0624235837522],
  ["CN", 96.89172666757234],
  ["BJ", 99.32855590273061],
  ["LA", 96.21043336503192],
  ["ME", 93.88330389892677],
  ["OM", 96.89002852873251],
  ["NI", 99.69569351990218],
  ["MR", 94.52995516913462],
  ["TG", 97.87562831137073],
  ["TP", 89.84546936557533],
  ["North Korea", 95.69589729656298],
  ["VN", 98.76239641353078],
  ["ZM", 92.1522211656025],
  ["MD", 95.01969841054205],
  ["CO", 99.30715935334872],
  ["AG", 90.84499388670018],
  ["AE", 99.27964950414346],
  ["CZ", 97.9343839152289],
  ["AR", 99.76905311778292],
  ["BI", 84.32889553049857],
  ["CM", 99.21613911153376],
  ["LB", 91.65670425213965],
  ["LU", 100],
  ["TD", 98.33208803151746],
  ["TR", 97.79207988045103],
  ["Scotland", 82.5947561472626],
  ["UA", 99.53810623556582],
  ["LT", 99.53810623556582],
  ["LC", 94.3730471403342],
  ["MG", 88.58612960195626],
  ["CL", 98.56812933025404],
  ["BH", 87.64196440700992],
  ["AD", 97.1753158538242],
  ["CH", 96.14624371688629],
  ["NO", 95.0947561472626],
  ["MT", 81.92365167776117],
  ["MC", 66.51270207852194],
  ["TV", 91.8013856812933],
  ["TW", 96.62851514739845],
  ["US", 80.50366797989403],
  ["MU", 96.07390300230946],
  ["BZ", 88.2580491781008],
  ["CI", 88.56031789159081],
  ["BM", 94.4919168591224],
  ["CK", 91.75247928270616],
  ["AT", 100],
  ["BO", 99.53810623556582],
  ["LS", 93.7046596929765],
  ["NL", 99.76905311778292],
  ["MW", 96.81089525879636],
  ["YE", 99.76905311778292],
  ["UG", 84.2650455101209],
  ["TT", 87.98227143051216],
  ["NZ", 92.14848526015487],
  ["MA", 98.56235565819861],
  ["MV", 98.73862246977313],
  ["LR", 83.41325906806141],
  ["BY", 95.55155549517728],
  ["BN", 86.53409862790382],
  ["AU", 88.74133949191686],
  ["GE", 71.64447765249287],
  ["GR", 88.1401983426165],
  ["IN", 98.69379160440157],
  ["GBENG", 70.5957071050129],
  ["RS", 61.680817823665265],
  ["PL", 99.76905311778292],
  ["SV", 99.31972558076349],
  ["SA", 95.60759407689173],
  ["HK", 97.82332563510393],
  ["KG", 96.43594620296156],
  ["GD", 93.74167911968483],
  ["GQ", 98.57831816329303],
  ["DJ", 92.20825974731694],
  ["KE", 90.28155141964407],
  ["KR", 68.58578997418829],
  ["SC", 88.20302947969026],
  ["ST", 95.92174976226056],
  ["PY", 99.46644477652494],
  ["SB", 92.6341529683467],
  ["IL", 79.21206357831817],
  ["DK", 95.91291944029344],
  ["DO", 93.35110718652358],
  ["RU", 96.99769053117782],
  ["SG", 63.34669202554001],
  ["PK", 85.88473033555223],
  ["GB", 84.42263279445727],
  ["GU", 95.191550061133],
  ["GW", 97.83860888466242],
  ["JP", 67.98974324140742],
  ["SR", 88.53280804238554],
  ["SE", 99.0504007607662],
  ["SD", 98.10861296019563],
  ["SS", 96.97153919304442],
  ["PH", 92.36924330933297],
  ["RW", 95.38174161119413],
  ["KU", 90.786238282842],
  ["HN", 99.75682651813612],
  ["GA", 99.65595707105012],
  ["DZ", 80.97541094959924],
  ["DM", 90.06385002037767],
  ["FR", 87.60019019155007],
  ["GL", 94.30308382013314],
  ["ES", 97.8437033011819],
  ["HT", 95.77061540551556],
  ["PE", 88.53756283113708],
  ["QA", 86.15575329438934],
  ["SI", 98.4285423176199],
  ["PR", 87.40999864148893],
  ["PS", 99.15568536883576],
  ["IQ", 99.76905311778292],
  ["HU", 99.76905311778292],
  ["KN", 83.91285151473986],
  ["KY", 91.91617986686592],
  ["FI", 71.41624779241951],
  ["ER", 93.58069555766879],
  ["GM", 99.30715935334872],
  ["EE", 0],
  ["EG", 99.6885613367749],
  ["ID", 66.51270207852194],
  ["IS", 93.78684961282434],
  ["SK", 98.69922564868904],
  ["RO", 98.42956120092379],
  ["PG", 81.73753566091564],
  ["KM", 97.6490965901372],
  ["IR", 92.95102567585926],
  ["KZ", 95.13653036272245],
  ["IE", 88.56541230811031],
  ["GN", 99.22157315582122],
  ["FJ", 93.19385952995518],
  ["GY", 91.25254720825976],
  ["JM", 72.78868360277137],
  ["KI", 81.50624915093057],
  ["HR", 95.52166825159625],
  ["SO", 66.58334465425894],
  ["PT", 96.03518543676131],
  ["SY", 99.76905311778292],
  ["SN", 99.16859122401847],
  ["KH", 97.65894579540824],
  ["EC", 98.70296155413666],
  ["ET", 97.08089933432957],
  ["DE", 99.30715935334872],
  ["SL", 99.76905311778292],
  ["PW", 68.87549246026356],
  ["SZ", 86.13435674500747],
  ["PA", 83.91692704795544],
  ["SM", 96.63632658606167],
  ["IT", 88.48865643254993],
  ["JO", 98.90334193723679],
  ["GH", 96.71240320608612],
  ["CG", 96.53375900013586],
  ["BT", 94.20798804510257],
  ["AO", 82.97649775845673],
  ["ML", 99.24466784404294],
  ["TN", 97.67287053389485],
  ["LI", 95.34098627903818],
  ["MM", 97.84540144002175],
  ["MZ", 74.71437304714034],
  ["NA", 92.54652900421138],
  ["CF", 99.06024996603723],
  ["BB", 94.1400624915093],
  ["CD", 96.00937372639588],
  ["BW", 83.37182448036951],
  ["AL", 88.43193859529956],
  ["NC", 96.63768509713354],
  ["MX", 87.62973780736313],
  ["TZ", 85.38038310012227],
  ["VE", 93.43737263958701],
  ["MN", 97.56622741475343],
  ["MY", 75.34438255671783],
  ["AM", 96.30484988452656],
  ["CR", 83.01147941855727],
  ["BA", 93.18808585789974],
  ["AZ", 99.27863062083956],
  ["CV", 86.965765520989],
  ["BR", 92.39063985871485],
  ["CA", 83.61058280124983],
  ["BE", 88.36571118054613],
  ["TH", 80.36951501154735],
  ["WS", 97.13082461622062],
  ["UZ", 95.80491781008014],
  ["Wales", 91.63496807498981],
  ["NG", 88.53756283113708],
  ["NP", 83.47269392745551],
  ["MK", 88.1269528596658],
  ["BD", 97.58015215324005],
  ["BS", 87.338676810216],
  ["CU", 87.95204455916316],
  ["BF", 99.49938867001767],
  ["NR", 94.17979894036137],
  ["NE", 100],
  ["ZW", 84.41957614454559],
  ["VC", 98.46182583888059],
  ["UY", 73.44382556717838],
  ["TJ", 95.22822986007336],
  ["VU", 80.08083140877598],
  ["ZA", 86.81700855861976],
  ["BG", 99.76905311778292],
]);

export const egMap = new Map<string, number>([
  ["CY", 72.54090377873004],
  ["AF", 82.71847811972471],
  ["CN", 67.0419426048565],
  ["BJ", 95.6697182184132],
  ["LA", 67.39546812102324],
  ["ME", 71.52869757174393],
  ["OM", 92.4272821711466],
  ["NI", 67.68536553694325],
  ["MR", 96.04304635761589],
  ["TG", 85.11751720555772],
  ["TP", 63.85891442669783],
  ["North Korea", 91.13783924165692],
  ["VN", 62.96519932476302],
  ["ZM", 91.71503700818076],
  ["MD", 84.97338008050902],
  ["CO", 95.30905077262693],
  ["AG", 89.95390209063758],
  ["AE", 42.98402804830542],
  ["CZ", 89.05077262693156],
  ["AR", 68.44695494091675],
  ["BI", 73.83781327100377],
  ["CM", 86.45435657706791],
  ["LB", 46.255356447214645],
  ["LU", 35.89014413712505],
  ["TD", 82.82495779768861],
  ["TR", 64.15887547071809],
  ["Scotland", 85.6158291131022],
  ["UA", 96.66861446565382],
  ["LT", 99.77924944812362],
  ["LC", 96.93676145955071],
  ["MG", 87.6658875470718],
  ["CL", 87.86716010907675],
  ["BH", 67.07862615244773],
  ["AD", 84.52700947928841],
  ["CH", 86.19172834696792],
  ["NO", 86.56148552136086],
  ["MT", 66.74490325931697],
  ["MC", 51.049863654070904],
  ["TV", 83.74269575379822],
  ["TW", 96.09011816647188],
  ["US", 71.29528632645112],
  ["MU", 71.28457343202182],
  ["BZ", 77.46429035190235],
  ["CI", 90.23243734579924],
  ["BM", 70.27139332554214],
  ["CK", 83.53525516166732],
  ["AT", 35.24996753668355],
  ["BO", 59.8759901311518],
  ["LS", 70.21847811972471],
  ["NL", 67.43994286456304],
  ["MW", 93.59401376444617],
  ["YE", 1.7072458122321776],
  ["UG", 96.71179067653551],
  ["TT", 66.76243345020127],
  ["NZ", 85.54733151538761],
  ["MA", 65.94663030775224],
  ["MV", 70.06914686404363],
  ["LR", 72.61751720555772],
  ["BY", 94.61823139851967],
  ["BN", 78.18172964550058],
  ["AU", 88.86345929100116],
  ["GE", 86.5416828983249],
  ["GR", 80.20938839111804],
  ["IN", 69.13550188287235],
  ["GBENG", 84.30853135956369],
  ["RS", 92.52272432151669],
  ["PL", 83.49759771458251],
  ["SV", 68.34664329307883],
  ["SA", 93.12134787689911],
  ["HK", 61.39170237631476],
  ["KG", 62.47370471367355],
  ["GD", 77.12472406181016],
  ["GQ", 74.01636151149201],
  ["DJ", 85.16264121542658],
  ["KE", 94.07706791325802],
  ["KR", 72.21269964939619],
  ["SC", 72.68698870276587],
  ["ST", 89.14329307882093],
  ["PY", 35.33047656148552],
  ["SB", 90.91027139332553],
  ["IL", 70.7586677054928],
  ["DK", 54.897091286845864],
  ["DO", 64.37118556031685],
  ["RU", 99.77924944812362],
  ["SG", 52.46266718607973],
  ["PK", 87.66361511492013],
  ["GB", 73.83878717049733],
  ["GU", 88.87482145175952],
  ["GW", 84.53837164004675],
  ["JP", 75.53986495260355],
  ["SR", 93.77321127126346],
  ["SE", 95.35904427996364],
  ["SD", 17.492533437215947],
  ["SS", 95.1671860797299],
  ["PH", 87.54772107518504],
  ["RW", 93.00383067134138],
  ["KU", 71.40858330087002],
  ["HN", 68.88715751201143],
  ["GA", 92.23639787040644],
  ["DZ", 82.94994156603039],
  ["DM", 89.66400467471757],
  ["FR", 79.6604337099078],
  ["GL", 79.74061810154525],
  ["ES", 67.18543046357615],
  ["HT", 97.59057265290222],
  ["PE", 68.32489287105571],
  ["QA", 86.94260485651213],
  ["SI", 97.66491364757823],
  ["PR", 87.39546812102324],
  ["PS", 73.65407089988312],
  ["IQ", 4.043630697312038],
  ["HU", 35.03992987923647],
  ["KN", 84.19620828463836],
  ["KY", 85.29054668224906],
  ["FI", 95.1428385923906],
  ["ER", 84.78606674457862],
  ["GM", 56.01220620698611],
  ["EE", 99.71334891572523],
  ["EG", 0],
  ["ID", 51.054083885209714],
  ["IS", 85.79080638878068],
  ["SK", 96.25697961303726],
  ["RO", 82.87430203869627],
  ["PG", 44.54746136865342],
  ["KM", 86.39267627580833],
  ["IR", 64.69062459420854],
  ["KZ", 92.8100246721205],
  ["IE", 90.28502791845216],
  ["GN", 82.9002726918582],
  ["FJ", 82.32761978963771],
  ["GY", 83.2424360472666],
  ["JM", 85.17465264251396],
  ["KI", 53.9933125568108],
  ["HR", 65.13309959745487],
  ["SO", 96.47091286845864],
  ["PT", 93.8693026879626],
  ["SY", 3.5125308401506303],
  ["SN", 84.79450720685625],
  ["KH", 85.2561355668095],
  ["EC", 93.24990261005064],
  ["ET", 92.5012985326581],
  ["DE", 100],
  ["SL", 68.08596286196598],
  ["PW", 93.62745098039215],
  ["SZ", 85.02142578885858],
  ["PA", 66.75853785222698],
  ["SM", 83.68004155304506],
  ["IT", 79.27996364108557],
  ["JO", 75.54863004804571],
  ["GH", 61.56245942085443],
  ["CG", 89.53707310738865],
  ["BT", 91.90624594208543],
  ["AO", 32.719127386053756],
  ["ML", 82.9259187118556],
  ["TN", 61.95721334891573],
  ["LI", 97.47338008050902],
  ["MM", 93.86995195429165],
  ["MZ", 90.93332034800675],
  ["NA", 83.83326840670043],
  ["CF", 78.98681989352032],
  ["BB", 95.4528632645111],
  ["CD", 89.44779898714452],
  ["BW", 91.31249188417088],
  ["AL", 68.4070250616803],
  ["NC", 92.3182054278665],
  ["MX", 80.93461888066484],
  ["TZ", 85.58011946500454],
  ["VE", 98.17913258018439],
  ["MN", 76.64913647578237],
  ["MY", 76.83580054538372],
  ["AM", 66.66666666666666],
  ["CR", 97.95253863134657],
  ["BA", 90.38047006882223],
  ["AZ", 98.13693026879626],
  ["CV", 89.9191663420335],
  ["BR", 93.33755356447215],
  ["CA", 92.03350214257888],
  ["BE", 73.3112582781457],
  ["TH", 84.10596026490066],
  ["WS", 80.46455005843397],
  ["UZ", 65.30288274250098],
  ["Wales", 83.80502532138682],
  ["NG", 90.330151928321],
  ["NP", 73.91085573302169],
  ["MK", 85.3707310738865],
  ["BD", 96.2991819244254],
  ["BS", 87.59966238150889],
  ["CU", 83.61511492014024],
  ["BF", 96.24237112063369],
  ["NR", 96.14855213608622],
  ["NE", 70.56388780677834],
  ["ZW", 88.14861706271913],
  ["VC", 94.5727827554863],
  ["UY", 85.22432151668615],
  ["TJ", 67.39449422152967],
  ["VU", 63.793987793793015],
  ["ZA", 88.7225684975977],
  ["BG", 98.59726009609142],
]);

export const idMap = new Map<string, number>([
  ["CY", 52.41202441241397],
  ["AF", 92.48149590962213],
  ["CN", 52.8181405012336],
  ["BJ", 99.43903389170238],
  ["LA", 69.69614335800546],
  ["ME", 61.80918062589274],
  ["OM", 76.95299311777691],
  ["NI", 83.69497467861316],
  ["MR", 96.58713154135826],
  ["TG", 83.20542786651085],
  ["TP", 59.16244643552785],
  ["North Korea", 89.88735229191013],
  ["VN", 52.49253343721595],
  ["ZM", 95.23048954681211],
  ["MD", 78.39468900142839],
  ["CO", 99.77924944812362],
  ["AG", 78.67906765355148],
  ["AE", 75.17367874302039],
  ["CZ", 98.94883781327101],
  ["AR", 84.03454096870536],
  ["BI", 65.8138553434619],
  ["CM", 82.33800805090247],
  ["LB", 51.78126217374367],
  ["LU", 50.993377483443716],
  ["TD", 82.02863264511102],
  ["TR", 50.349305285027924],
  ["Scotland", 75.55771977665239],
  ["UA", 96.68874172185431],
  ["LT", 99.55849889624724],
  ["LC", 96.67965199324763],
  ["MG", 83.03856641994545],
  ["CL", 98.59985716140761],
  ["BH", 50.20419426048564],
  ["AD", 80.09933774834437],
  ["CH", 88.83391767302948],
  ["NO", 88.5712894429295],
  ["MT", 49.51564731853006],
  ["MC", 0],
  ["TV", 81.97928840410337],
  ["TW", 95.30710297363979],
  ["US", 53.230749253343724],
  ["MU", 70.86092715231787],
  ["BZ", 75.22951564731854],
  ["CI", 83.26808206726399],
  ["BM", 67.14907154914947],
  ["CK", 83.29827295156474],
  ["AT", 50.993377483443716],
  ["BO", 63.796909492273734],
  ["LS", 82.29093624204648],
  ["NL", 82.560706401766],
  ["MW", 77.20458382028308],
  ["YE", 50.11037527593819],
  ["UG", 95.8625503181405],
  ["TT", 60.321062199714326],
  ["NZ", 83.88520971302428],
  ["MA", 50.18828723542397],
  ["MV", 64.8733930658356],
  ["LR", 56.84261784183873],
  ["BY", 94.28256070640177],
  ["BN", 89.92338657317231],
  ["AU", 87.5834307232827],
  ["GE", 64.28580703804701],
  ["GR", 76.53064537073108],
  ["IN", 83.5686923776133],
  ["GBENG", 50.11037527593819],
  ["RS", 60.756070640176596],
  ["PL", 100],
  ["SV", 84.39553304765614],
  ["SA", 92.26756265420076],
  ["HK", 51.02908713154136],
  ["KG", 50.82262043890404],
  ["GD", 73.82872354239709],
  ["GQ", 85.30028567718479],
  ["DJ", 85.61420594727957],
  ["KE", 92.55226593948838],
  ["KR", 54.11602389300091],
  ["SC", 59.5107778210622],
  ["ST", 89.501363459291],
  ["PY", 50.330151928321],
  ["SB", 89.16504350084405],
  ["IL", 66.0524607193871],
  ["DK", 50.173354109855865],
  ["DO", 67.02636021295936],
  ["RU", 99.77924944812362],
  ["SG", 3.5011686793922867],
  ["PK", 81.95461628359953],
  ["GB", 61.56927671730944],
  ["GU", 89.12576288793663],
  ["GW", 83.98422282820412],
  ["JP", 50.22984028048305],
  ["SR", 71.07745747305545],
  ["SE", 96.48195039605247],
  ["SD", 57.64543565770679],
  ["SS", 84.04720166212181],
  ["PH", 87.79801324503312],
  ["RW", 92.58862485391508],
  ["KU", 86.90267497727568],
  ["HN", 83.46123880015583],
  ["GA", 96.273535904428],
  ["DZ", 72.13413842358135],
  ["DM", 86.70302558109336],
  ["FR", 67.05427866510843],
  ["GL", 76.6617971691988],
  ["ES", 69.96591351772497],
  ["HT", 98.36774444877288],
  ["PE", 50.40189585768082],
  ["QA", 82.56232956758862],
  ["SI", 97.60323334631866],
  ["PR", 80.59278015842098],
  ["PS", 79.68900142838592],
  ["IQ", 51.44039735099337],
  ["HU", 50.55187637969095],
  ["KN", 89.26892611349176],
  ["KY", 83.00999870146735],
  ["FI", 69.6133619010518],
  ["ER", 73.31320607713285],
  ["GM", 61.147902869757175],
  ["EE", 66.66666666666666],
  ["EG", 51.054083885209714],
  ["ID", 0],
  ["IS", 82.85514868198935],
  ["SK", 96.5147383456694],
  ["RO", 82.09680560966109],
  ["PG", 66.16251136216076],
  ["KM", 95.20711595896637],
  ["IR", 77.46786131671212],
  ["KZ", 93.72451629658485],
  ["IE", 83.34437086092716],
  ["GN", 82.10199974029346],
  ["FJ", 82.96844565640826],
  ["GY", 80.30125957667835],
  ["JM", 92.30522010128554],
  ["KI", 33.83359303986495],
  ["HR", 79.47636670562265],
  ["SO", 97.2951564731853],
  ["PT", 93.54791585508376],
  ["SY", 50.232437345799255],
  ["SN", 81.49493572263341],
  ["KH", 71.45305804440981],
  ["EC", 96.25373328139203],
  ["ET", 91.06901701077781],
  ["DE", 98.67549668874173],
  ["SL", 83.44370860927152],
  ["PW", 97.90676535514868],
  ["SZ", 78.68361251785483],
  ["PA", 50.47071808855993],
  ["SM", 95.94273470977795],
  ["IT", 66.83677444487729],
  ["JO", 77.81684196857552],
  ["GH", 63.338852097130236],
  ["CG", 87.75548630048046],
  ["BT", 92.01402415270744],
  ["AO", 49.563693026879626],
  ["ML", 82.02636021295936],
  ["TN", 50.3733281392027],
  ["LI", 97.39741592001039],
  ["MM", 93.29048175561616],
  ["MZ", 85.85768082067264],
  ["NA", 77.22568497597715],
  ["CF", 90.19932476301778],
  ["BB", 97.94085183742371],
  ["CD", 85.59992208804051],
  ["BW", 96.02649006622516],
  ["AL", 57.90481755616154],
  ["NC", 94.54421503700819],
  ["MX", 66.92961952993117],
  ["TZ", 93.52941176470588],
  ["VE", 96.87930138942994],
  ["MN", 66.88384625373328],
  ["MY", 56.492338657317234],
  ["AM", 66.22516556291392],
  ["CR", 67.47240618101546],
  ["BA", 92.00331125827815],
  ["AZ", 83.24925334372159],
  ["CV", 81.14303337228931],
  ["BR", 91.47415920010388],
  ["CA", 79.15725230489547],
  ["BE", 82.46396571873782],
  ["TH", 67.3289183222958],
  ["WS", 71.90105181145306],
  ["UZ", 79.51142708739125],
  ["Wales", 87.99798727437995],
  ["NG", 83.41449162446436],
  ["NP", 59.52473704713673],
  ["MK", 82.94799376704324],
  ["BD", 97.06693935852486],
  ["BS", 96.4825996623815],
  ["CU", 73.40215556421244],
  ["BF", 97.91293338527464],
  ["NR", 94.4799376704324],
  ["NE", 85.46552395792754],
  ["ZW", 80.07921049214389],
  ["VC", 95.89890923256719],
  ["UY", 70.15809635112323],
  ["TJ", 78.73198285936891],
  ["VU", 63.97675626542008],
  ["ZA", 90.75444747435398],
  ["BG", 99.77924944812362],
]);

export const isMap = new Map<string, number>([
  ["CY", 83.63685474189676],
  ["AF", 90.15996398559423],
  ["CN", 80.16146458583432],
  ["BJ", 92.29621848739495],
  ["LA", 89.36284513805522],
  ["ME", 89.96188475390156],
  ["OM", 78.6923769507803],
  ["NI", 87.71608643457382],
  ["MR", 94.47779111644657],
  ["TG", 88.13085234093637],
  ["TP", 82.77611044417768],
  ["North Korea", 56.957382953181266],
  ["VN", 81.05192076830733],
  ["ZM", 91.80282112845138],
  ["MD", 68.32112845138056],
  ["CO", 81.40426170468187],
  ["AG", 83.35504201680672],
  ["AE", 87.57352941176471],
  ["CZ", 72.54171668667468],
  ["AR", 88.76470588235294],
  ["BI", 85.65846338535414],
  ["CM", 86.61374549819928],
  ["LB", 82.40906362545019],
  ["LU", 85.91656662665066],
  ["TD", 91.35894357743098],
  ["TR", 80.87815126050421],
  ["Scotland", 86.19117647058823],
  ["UA", 93.03871548619448],
  ["LT", 94.5846338535414],
  ["LC", 97.28061224489795],
  ["MG", 91.52430972388956],
  ["CL", 75.4561824729892],
  ["BH", 81.90396158463386],
  ["AD", 90.4126650660264],
  ["CH", 90.86524609843937],
  ["NO", 81.20468187274909],
  ["MT", 83.07533013205281],
  ["MC", 82.64825930372149],
  ["TV", 87.67677070828331],
  ["TW", 93.84363745498199],
  ["US", 79.43817527010805],
  ["MU", 91.02641056422569],
  ["BZ", 47.11974789915966],
  ["CI", 91.84903961584634],
  ["BM", 75.97388955582232],
  ["CK", 41.55552220888355],
  ["AT", 83.54441776710684],
  ["BO", 90.0561224489796],
  ["LS", 63.4327731092437],
  ["NL", 88.06602641056422],
  ["MW", 78.91836734693878],
  ["YE", 86.0204081632653],
  ["UG", 88.99129651860744],
  ["TT", 82.5483193277311],
  ["NZ", 37.68937575030012],
  ["MA", 80.93937575030013],
  ["MV", 88.16446578631452],
  ["LR", 90.25030012004802],
  ["BY", 79.32442977190877],
  ["BN", 84.46068427370949],
  ["AU", 88.89945978391357],
  ["GE", 88.0234093637455],
  ["GR", 85.84783913565425],
  ["IN", 88.45318127250901],
  ["GBENG", 80.23289315726291],
  ["RS", 86.72989195678271],
  ["PL", 82.73979591836735],
  ["SV", 32.74369747899159],
  ["SA", 90.50660264105642],
  ["HK", 80.3343337334934],
  ["KG", 78.3343337334934],
  ["GD", 83.36974789915966],
  ["GQ", 84.67887154861945],
  ["DJ", 91.67106842737095],
  ["KE", 91.96908763505402],
  ["KR", 79.70468187274909],
  ["SC", 69.70528211284514],
  ["ST", 88.08583433373349],
  ["PY", 57.66446578631452],
  ["SB", 87.48379351740697],
  ["IL", 82.84093637454983],
  ["DK", 93.61284513805522],
  ["DO", 85.43847539015607],
  ["RU", 83.87484993997599],
  ["SG", 80.33913565426171],
  ["PK", 91.8202280912365],
  ["GB", 53.64705882352941],
  ["GU", 44.23529411764706],
  ["GW", 91.77460984393757],
  ["JP", 86.08613445378151],
  ["SR", 94.57893157262906],
  ["SE", 28.628151260504204],
  ["SD", 85.03181272509003],
  ["SS", 74.74009603841537],
  ["PH", 60.92617046818728],
  ["RW", 93.26620648259303],
  ["KU", 85.01620648259303],
  ["HN", 88.04951980792318],
  ["GA", 92.22058823529412],
  ["DZ", 87.75840336134453],
  ["DM", 84.69477791116446],
  ["FR", 67.26500600240097],
  ["GL", 81.38085234093637],
  ["ES", 85.47689075630252],
  ["HT", 55.851440576230495],
  ["PE", 85.11914765906361],
  ["QA", 90.75630252100841],
  ["SI", 90.98469387755101],
  ["PR", 88.47629051620648],
  ["PS", 84.3076230492197],
  ["IQ", 85.93877551020408],
  ["HU", 85.73169267707082],
  ["KN", 86.36314525810324],
  ["KY", 41.433073229291715],
  ["FI", 94.46428571428571],
  ["ER", 85.06122448979592],
  ["GM", 94.57803121248499],
  ["EE", 95.68817527010805],
  ["EG", 85.67527010804322],
  ["ID", 82.85234093637455],
  ["IS", 0],
  ["SK", 81.67376950780312],
  ["RO", 70.04501800720288],
  ["PG", 84.99429771908763],
  ["KM", 83.35234093637455],
  ["IR", 81.43157262905162],
  ["KZ", 95.12545018007202],
  ["IE", 92.0093037214886],
  ["GN", 90.82142857142857],
  ["FJ", 87.00690276110444],
  ["GY", 80.93217286914765],
  ["JM", 88.25570228091236],
  ["KI", 62.37665066026411],
  ["HR", 84.30672268907563],
  ["SO", 95.8376350540216],
  ["PT", 90.9297719087635],
  ["SY", 85.14585834333734],
  ["SN", 90.76500600240097],
  ["KH", 51.00180072028812],
  ["EC", 79.17527010804322],
  ["ET", 79.97539015606242],
  ["DE", 94.33823529411765],
  ["SL", 88.08313325330131],
  ["PW", 94.53751500600241],
  ["SZ", 88.07593037214886],
  ["PA", 73.6062424969988],
  ["SM", 88.11524609843937],
  ["IT", 88.63265306122449],
  ["JO", 82.67917166866746],
  ["GH", 89.59603841536614],
  ["CG", 88.26230492196879],
  ["BT", 88.48799519807923],
  ["AO", 86.94327731092437],
  ["ML", 91.3844537815126],
  ["TN", 91.94477791116446],
  ["LI", 57.85654261704681],
  ["MM", 90.53241296518607],
  ["MZ", 89.56302521008404],
  ["NA", 70.6593637454982],
  ["CF", 74.41116446578631],
  ["BB", 47.23259303721489],
  ["CD", 89.15876350540216],
  ["BW", 94.64285714285714],
  ["AL", 78.8580432172869],
  ["NC", 59.873349339735896],
  ["MX", 88.4624849939976],
  ["TZ", 92.27731092436974],
  ["VE", 83.42076830732293],
  ["MN", 70.68757503001201],
  ["MY", 72.6782713085234],
  ["AM", 88.86314525810323],
  ["CR", 56.85324129651861],
  ["BA", 47.36674669867947],
  ["AZ", 95.18847539015606],
  ["CV", 38.89765906362545],
  ["BR", 87.765606242497],
  ["CA", 88.10924369747899],
  ["BE", 92.31752701080433],
  ["TH", 93.17857142857143],
  ["WS", 73.58313325330133],
  ["UZ", 84.8922569027611],
  ["Wales", 72.53451380552221],
  ["NG", 91.98319327731093],
  ["NP", 69.43877551020408],
  ["MK", 82.98319327731093],
  ["BD", 87.08343337334934],
  ["BS", 92.66626650660264],
  ["CU", 57.46308523409363],
  ["BF", 89.31602641056423],
  ["NR", 33.75690276110444],
  ["NE", 89.53451380552221],
  ["ZW", 89.6671668667467],
  ["VC", 91.7280912364946],
  ["UY", 70.18877551020408],
  ["TJ", 86.86194477791116],
  ["VU", 87.50540216086435],
  ["ZA", 71.71158463385355],
  ["BG", 92.28571428571428],
]);

export const skMap = new Map<string, number>([
  ["CY", 65.5093494351383],
  ["AF", 90.96773146344631],
  ["CN", 57.060122062069865],
  ["BJ", 73.31775094143617],
  ["LA", 93.1090118166472],
  ["ME", 88.38884560446695],
  ["OM", 57.70127256200494],
  ["NI", 95.84826645890145],
  ["MR", 92.5512920399948],
  ["TG", 90.3678093754058],
  ["TP", 66.55336969224776],
  ["North Korea", 77.74477340605117],
  ["VN", 58.83878717049734],
  ["ZM", 89.87923646279704],
  ["MD", 83.53525516166732],
  ["CO", 78.2615894039735],
  ["AG", 90.53045059083236],
  ["AE", 95.50253213868328],
  ["CZ", 40.59148162576289],
  ["AR", 96.56278405401896],
  ["BI", 80.57330216854955],
  ["CM", 92.65517465264251],
  ["LB", 62.263991689390984],
  ["LU", 94.62375016231658],
  ["TD", 94.34683807297753],
  ["TR", 59.73769640306453],
  ["Scotland", 63.731008959875346],
  ["UA", 85.61485521360862],
  ["LT", 94.55135696662771],
  ["LC", 97.99376704324115],
  ["MG", 85.2129593559278],
  ["CL", 36.590377873003504],
  ["BH", 84.20757044539671],
  ["AD", 82.4110505129204],
  ["CH", 91.70789507856124],
  ["NO", 63.55603168419685],
  ["MT", 66.75009738994936],
  ["MC", 96.28067783404752],
  ["TV", 83.72938579405272],
  ["TW", 92.7788598883262],
  ["US", 82.99084534476042],
  ["MU", 96.32417867809376],
  ["BZ", 64.45916114790286],
  ["CI", 86.15179846773147],
  ["BM", 50.77457473055448],
  ["CK", 81.46571873782625],
  ["AT", 63.16290092195819],
  ["BO", 94.37540579145566],
  ["LS", 93.88423581353071],
  ["NL", 95.68432671081678],
  ["MW", 93.9783794312427],
  ["YE", 96.58291131021946],
  ["UG", 92.13316452408779],
  ["TT", 90.5418127515907],
  ["NZ", 83.57875600571354],
  ["MA", 92.41754317621088],
  ["MV", 66.57317231528373],
  ["LR", 83.52649006622516],
  ["BY", 88.56934164394235],
  ["BN", 83.93163225555122],
  ["AU", 86.87151019348137],
  ["GE", 66.69328658615764],
  ["GR", 75.95799246851058],
  ["IN", 95.37624983768342],
  ["GBENG", 67.0458382028308],
  ["RS", 92.02311388131412],
  ["PL", 31.026490066225165],
  ["SV", 94.81041423191793],
  ["SA", 88.89592260745358],
  ["HK", 58.82417867809375],
  ["KG", 57.38443059342943],
  ["GD", 85.9648097649656],
  ["GQ", 65.52493182703545],
  ["DJ", 88.4164394234515],
  ["KE", 88.43234644851317],
  ["KR", 66.80268796260226],
  ["SC", 80.00324633164524],
  ["ST", 90.50480457083495],
  ["PY", 94.1238150889495],
  ["SB", 80.90897286066745],
  ["IL", 78.05609661082975],
  ["DK", 91.86631606284898],
  ["DO", 90.40222049084534],
  ["RU", 8.32359433839761],
  ["SG", 91.45630437605506],
  ["PK", 85.32658096351123],
  ["GB", 72.41527074405921],
  ["GU", 90.03668354759122],
  ["GW", 90.74730554473444],
  ["JP", 66.03330736268018],
  ["SR", 85.35612258148294],
  ["SE", 85.99629918192443],
  ["SD", 93.83099597454876],
  ["SS", 84.72600960914167],
  ["PH", 81.16088819633815],
  ["RW", 94.83833268406701],
  ["KU", 94.81008959875341],
  ["HN", 95.66419945461628],
  ["GA", 95.61842617841839],
  ["DZ", 75.18633943643682],
  ["DM", 87.29126087521101],
  ["FR", 74.45039605246072],
  ["GL", 35.88884560446695],
  ["ES", 92.63829372808726],
  ["HT", 60.857031554343585],
  ["PE", 62.02506168030126],
  ["QA", 84.81950396052461],
  ["SI", 8.922217893780028],
  ["PR", 81.63322945072069],
  ["PS", 93.84982469809115],
  ["IQ", 95.12693156732892],
  ["HU", 95.92260745357746],
  ["KN", 90.72847682119205],
  ["KY", 81.58420984287756],
  ["FI", 67.56687443189196],
  ["ER", 74.68088559927281],
  ["GM", 94.64355278535254],
  ["EE", 98.72126996493962],
  ["EG", 96.25697961303726],
  ["ID", 96.5147383456694],
  ["IS", 81.71503700818076],
  ["SK", 0],
  ["RO", 94.15757693805999],
  ["PG", 92.84216335540839],
  ["KM", 84.14231917932736],
  ["IR", 88.97091286845864],
  ["KZ", 86.80885599272821],
  ["IE", 85.54408518374237],
  ["GN", 94.0728476821192],
  ["FJ", 87.16692637319828],
  ["GY", 88.38981950396052],
  ["JM", 91.43455395403194],
  ["KI", 83.61381638748215],
  ["HR", 92.81197247110765],
  ["SO", 93.62095831710168],
  ["PT", 90.44020257109466],
  ["SY", 95.56680950525906],
  ["SN", 82.65549928580704],
  ["KH", 83.49857161407608],
  ["EC", 53.24503311258278],
  ["ET", 59.456239449422156],
  ["DE", 95.00973899493572],
  ["SL", 95.9326710816777],
  ["PW", 94.3517075704454],
  ["SZ", 86.91014154005974],
  ["PA", 74.62504869497468],
  ["SM", 63.83683937151019],
  ["IT", 74.61790676535514],
  ["JO", 92.00947928840411],
  ["GH", 95.65543435917412],
  ["CG", 74.04914946110894],
  ["BT", 91.71406310868718],
  ["AO", 94.68413193091806],
  ["ML", 93.92741202441242],
  ["TN", 58.838137904168285],
  ["LI", 92.88469029996105],
  ["MM", 61.83125568108038],
  ["MZ", 87.05298013245033],
  ["NA", 78.99655888845605],
  ["CF", 81.38780677834048],
  ["BB", 94.86949746786132],
  ["CD", 84.67374366965329],
  ["BW", 98.89787040644073],
  ["AL", 58.76606934164394],
  ["NC", 93.36871834826646],
  ["MX", 83.11258278145695],
  ["TZ", 92.05038306713413],
  ["VE", 59.369562394494224],
  ["MN", 84.57862615244774],
  ["MY", 68.2992468510583],
  ["AM", 70.08018439163745],
  ["CR", 79.54096870536293],
  ["BA", 87.87917153616414],
  ["AZ", 90.35417478249578],
  ["CV", 81.69166342033503],
  ["BR", 87.99928580703805],
  ["CA", 79.16147253603428],
  ["BE", 85.80444098169069],
  ["TH", 64.76886118685886],
  ["WS", 93.11842617841839],
  ["UZ", 93.3813790416829],
  ["Wales", 53.76444617582132],
  ["NG", 85.44377353590443],
  ["NP", 47.03707310738865],
  ["MK", 80.72036099207895],
  ["BD", 86.42254252694455],
  ["BS", 95.9826645890144],
  ["CU", 78.16225165562913],
  ["BF", 90.19023503441112],
  ["NR", 92.88371640046748],
  ["NE", 94.70328528762498],
  ["ZW", 85.96902999610441],
  ["VC", 92.2750292169848],
  ["UY", 57.712634722763276],
  ["TJ", 93.03109985716141],
  ["VU", 92.67075704453967],
  ["ZA", 91.53161927022465],
  ["BG", 32.360732372419164],
]);

export const roMap = new Map<string, number>([
  ["CY", 94.16396734905416],
  ["AF", 94.27377558953096],
  ["CN", 63.86563876651983],
  ["BJ", 69.25757968385592],
  ["LA", 64.13546255506608],
  ["ME", 65.36084477844001],
  ["OM", 84.6061155739829],
  ["NI", 93.17828452967089],
  ["MR", 93.67096398030577],
  ["TG", 84.52481212749416],
  ["TP", 58.84166882612075],
  ["North Korea", 93.04029541331951],
  ["VN", 63.87762373671936],
  ["ZM", 89.94039906711583],
  ["MD", 38.641163513863695],
  ["CO", 66.32450116610521],
  ["AG", 66.30344648872764],
  ["AE", 97.37431977196165],
  ["CZ", 59.66053381705105],
  ["AR", 94.47298522933403],
  ["BI", 84.88436123348018],
  ["CM", 98.07268722466961],
  ["LB", 77.77759782327027],
  ["LU", 88.12580979528374],
  ["TD", 0],
  ["TR", 62.70050531225706],
  ["Scotland", 90.93320808499611],
  ["UA", 82.79282197460482],
  ["LT", 76.77118424462296],
  ["LC", 87.59944286084477],
  ["MG", 99.26211453744493],
  ["CL", 80.13377818087586],
  ["BH", 63.69396216636435],
  ["AD", 37.544700699663125],
  ["CH", 96.20529930033688],
  ["NO", 87.45594713656388],
  ["MT", 65.62937289453227],
  ["MC", 81.84374190204716],
  ["TV", 83.15269499870432],
  ["TW", 95.37153407618554],
  ["US", 66.08544959834154],
  ["MU", 74.48173101839855],
  ["BZ", 79.90314848406322],
  ["CI", 96.61214045089402],
  ["BM", 63.88507385332988],
  ["CK", 69.46747862140451],
  ["AT", 76.20141228297487],
  ["BO", 77.01088364861363],
  ["LS", 96.78154962425499],
  ["NL", 97.322168955688],
  ["MW", 86.29275719098212],
  ["YE", 87.79962425498834],
  ["UG", 82.05266908525525],
  ["TT", 69.49987043275459],
  ["NZ", 70.5351127235035],
  ["MA", 66.1761466701218],
  ["MV", 72.43424462295933],
  ["LR", 64.72466960352423],
  ["BY", 93.73671935734646],
  ["BN", 76.83370044052863],
  ["AU", 86.78414096916299],
  ["GE", 92.4267945063488],
  ["GR", 92.26483544959834],
  ["IN", 94.73762632806427],
  ["GBENG", 90.73885721689557],
  ["RS", 83.17310183985488],
  ["PL", 82.6454392329619],
  ["SV", 93.47143042238922],
  ["SA", 91.35430163254729],
  ["HK", 58.897382741642915],
  ["KG", 57.20782586162218],
  ["GD", 61.080590826639025],
  ["GQ", 82.16215340761856],
  ["DJ", 96.48613630474216],
  ["KE", 94.98671935734646],
  ["KR", 95.91927960611557],
  ["SC", 61.97687224669603],
  ["ST", 84.51315107540813],
  ["PY", 85.15774812127495],
  ["SB", 87.68657683337652],
  ["IL", 94.50764446747863],
  ["DK", 69.64984451930552],
  ["DO", 69.23231407100286],
  ["RU", 87.28621404508941],
  ["SG", 78.72926924073595],
  ["PK", 95.89984451930552],
  ["GB", 67.15859030837005],
  ["GU", 62.114861363047424],
  ["GW", 79.78491837263539],
  ["JP", 96.93962166364342],
  ["SR", 80.8230759264058],
  ["SE", 84.26762114537445],
  ["SD", 83.32663902565432],
  ["SS", 81.39673490541591],
  ["PH", 79.62263539777145],
  ["RW", 86.48905156776367],
  ["KU", 83.20128271572946],
  ["HN", 95.12859549105987],
  ["GA", 88.14556880020731],
  ["DZ", 95.0375745011661],
  ["DM", 93.49151334542628],
  ["FR", 31.172583570873286],
  ["GL", 79.22000518268982],
  ["ES", 66.09451930551955],
  ["HT", 79.43962166364344],
  ["PE", 63.38138118683597],
  ["QA", 97.98749676081886],
  ["SI", 85.90729463591605],
  ["PR", 90.14867841409692],
  ["PS", 94.94396216636434],
  ["IQ", 83.93819642394402],
  ["HU", 86.65684115055714],
  ["KN", 72.3545607670381],
  ["KY", 68.7140450894014],
  ["FI", 85.71747862140451],
  ["ER", 83.13131640321326],
  ["GM", 78.57378854625551],
  ["EE", 98.77688520342058],
  ["EG", 82.91234775848666],
  ["ID", 82.13559212231148],
  ["IS", 69.7139803057787],
  ["SK", 94.06517232443639],
  ["RO", 0],
  ["PG", 71.14958538481471],
  ["KM", 80.47162477325732],
  ["IR", 89.81439492096398],
  ["KZ", 92.92238922000519],
  ["IE", 96.61667530448302],
  ["GN", 65.87652241513345],
  ["FJ", 84.09918372635397],
  ["GY", 77.54340502720912],
  ["JM", 83.85073853329878],
  ["KI", 61.53990671158331],
  ["HR", 82.43521637729981],
  ["SO", 96.72356828193833],
  ["PT", 93.12548587717025],
  ["SY", 85.95069966312516],
  ["SN", 64.27701477066597],
  ["KH", 76.88714692925629],
  ["EC", 75.48555325213786],
  ["ET", 85.12568022803835],
  ["DE", 86.93346721948691],
  ["SL", 97.34322363306556],
  ["PW", 83.01438196423943],
  ["SZ", 73.55500129567245],
  ["PA", 79.29029541331951],
  ["SM", 90.66403213267687],
  ["IT", 63.52844001036539],
  ["JO", 93.41604042498057],
  ["GH", 79.55331692148225],
  ["CG", 71.17193573464628],
  ["BT", 77.36589790101063],
  ["AO", 77.37723503498316],
  ["ML", 33.23529411764706],
  ["TN", 59.71754340502721],
  ["LI", 68.43158849442861],
  ["MM", 74.58862399585385],
  ["MZ", 81.1797097693703],
  ["NA", 66.59302928219746],
  ["CF", 86.73231407100285],
  ["BB", 36.89394920963981],
  ["CD", 87.9285436641617],
  ["BW", 97.70212490282456],
  ["AL", 65.36084477844001],
  ["NC", 89.15554547810314],
  ["MX", 58.05519564654056],
  ["TZ", 91.66040424980565],
  ["VE", 64.88986784140968],
  ["MN", 62.15891422648354],
  ["MY", 73.76684374190205],
  ["AM", 86.95355014252397],
  ["CR", 73.26541850220264],
  ["BA", 49.327222078258615],
  ["AZ", 86.26004146151854],
  ["CV", 67.67005700958798],
  ["BR", 88.93398548846851],
  ["CA", 93.9268592899715],
  ["BE", 65.57819383259911],
  ["TH", 87.16863176988858],
  ["WS", 50.50725576574242],
  ["UZ", 96.29696812645763],
  ["Wales", 87.98879243327286],
  ["NG", 96.17064006219228],
  ["NP", 83.29878206789324],
  ["MK", 79.09918372635398],
  ["BD", 98.02053640839596],
  ["BS", 95.58175693184762],
  ["CU", 82.98004664420834],
  ["BF", 95.91539258875356],
  ["NR", 68.58221041720654],
  ["NE", 96.85022026431717],
  ["ZW", 76.48807981342317],
  ["VC", 71.80713915522155],
  ["UY", 92.41740088105726],
  ["TJ", 90.5730111427831],
  ["VU", 73.42154703291008],
  ["ZA", 90.80331692148225],
  ["BG", 87.35844778440011],
]);

export const pgMap = new Map<string, number>([
  ["CY", 93.8820645905421],
  ["AF", 61.78200692041522],
  ["CN", 56.022202998846595],
  ["BJ", 81.30594002306805],
  ["LA", 69.31632064590542],
  ["ME", 61.30420991926182],
  ["OM", 80.82641291810842],
  ["NI", 93.47087658592848],
  ["MR", 88.52970011534025],
  ["TG", 85.28690888119954],
  ["TP", 40.429354094579004],
  ["North Korea", 90.91205305651673],
  ["VN", 57.96799307958478],
  ["ZM", 88.24913494809688],
  ["MD", 67.76643598615917],
  ["CO", 88.54267589388697],
  ["AG", 76.9466551326413],
  ["AE", 73.29930795847751],
  ["CZ", 82.32843137254902],
  ["AR", 95.3336216839677],
  ["BI", 79.57381776239907],
  ["CM", 80.9878892733564],
  ["LB", 70.27162629757785],
  ["LU", 71.44348327566321],
  ["TD", 71.239907727797],
  ["TR", 56.71309111880046],
  ["Scotland", 91.57525951557093],
  ["UA", 94.90455594002307],
  ["LT", 87.8719723183391],
  ["LC", 94.99019607843138],
  ["MG", 93.00288350634372],
  ["CL", 82.64677047289504],
  ["BH", 59.63783160322953],
  ["AD", 70.1401384083045],
  ["CH", 93.65023068050749],
  ["NO", 91.16089965397924],
  ["MT", 66.19925028835063],
  ["MC", 66.02364475201846],
  ["TV", 81.78719723183391],
  ["TW", 88.8376585928489],
  ["US", 72.95184544405998],
  ["MU", 73.92070357554786],
  ["BZ", 80.55968858131489],
  ["CI", 95.10668973471742],
  ["BM", 64.26989619377163],
  ["CK", 81.94607843137256],
  ["AT", 66.85899653979239],
  ["BO", 71.09803921568627],
  ["LS", 90.73615916955016],
  ["NL", 93.26066897347174],
  ["MW", 73.90916955017302],
  ["YE", 44.68223760092272],
  ["UG", 84.94809688581316],
  ["TT", 59.866493656286046],
  ["NZ", 82.53258362168397],
  ["MA", 54.71683967704729],
  ["MV", 63.082756632064594],
  ["LR", 73.08506343713957],
  ["BY", 93.20790080738178],
  ["BN", 80.54613610149943],
  ["AU", 84.17502883506344],
  ["GE", 92.03662053056517],
  ["GR", 93.80074971164937],
  ["IN", 93.2843137254902],
  ["GBENG", 84.01095732410612],
  ["RS", 89.81920415224913],
  ["PL", 84.92445213379469],
  ["SV", 92.45299884659747],
  ["SA", 89.84602076124567],
  ["HK", 58.30709342560554],
  ["KG", 57.97923875432526],
  ["GD", 72.4677047289504],
  ["GQ", 88.99567474048443],
  ["DJ", 93.69809688581316],
  ["KE", 82.69290657439447],
  ["KR", 87.1179354094579],
  ["SC", 76.72029988465975],
  ["ST", 92.2649942329873],
  ["PY", 69.00201845444059],
  ["SB", 89.35005767012687],
  ["IL", 90.32035755478663],
  ["DK", 64.76153402537486],
  ["DO", 80.04123414071512],
  ["RU", 87.91349480968859],
  ["SG", 67.30997693194925],
  ["PK", 89.74913494809688],
  ["GB", 74.49826989619378],
  ["GU", 81.1825259515571],
  ["GW", 85.13927335640138],
  ["JP", 87.0957324106113],
  ["SR", 81.21222606689734],
  ["SE", 92.73846597462514],
  ["SD", 48.96136101499423],
  ["SS", 83.54959630911189],
  ["PH", 81.09140715109572],
  ["RW", 91.3670703575548],
  ["KU", 73.4333910034602],
  ["HN", 93.50230680507498],
  ["GA", 93.5888119953864],
  ["DZ", 90.50230680507497],
  ["DM", 85.21943483275663],
  ["FR", 72.15167243367937],
  ["GL", 78.97549019607844],
  ["ES", 69.78777393310264],
  ["HT", 81.8970588235294],
  ["PE", 68.6482122260669],
  ["QA", 89.27191464821223],
  ["SI", 89.01528258362168],
  ["PR", 90.260092272203],
  ["PS", 94.81286043829297],
  ["IQ", 43.9959630911188],
  ["HU", 71.01095732410612],
  ["KN", 69.33391003460207],
  ["KY", 83.92906574394465],
  ["FI", 93.02249134948097],
  ["ER", 80.40109573241061],
  ["GM", 69.3174740484429],
  ["EE", 81.88581314878893],
  ["EG", 44.66782006920415],
  ["ID", 66.19059976931949],
  ["IS", 85.24509803921568],
  ["SK", 93.04728950403691],
  ["RO", 71.23760092272204],
  ["PG", 0],
  ["KM", 86.97981545559401],
  ["IR", 89.48010380622837],
  ["KZ", 91.46078431372548],
  ["IE", 95.49480968858131],
  ["GN", 88.78863898500576],
  ["FJ", 86.12283737024221],
  ["GY", 87.14532871972318],
  ["JM", 78.14244521337947],
  ["KI", 61.65801614763552],
  ["HR", 87.66176470588235],
  ["SO", 93.51124567474048],
  ["PT", 88.3950403690888],
  ["SY", 44.17358708189158],
  ["SN", 73.15080738177625],
  ["KH", 77.24538638985005],
  ["EC", 86.74307958477509],
  ["ET", 84.53258362168397],
  ["DE", 89.93396770472894],
  ["SL", 93.90051903114187],
  ["PW", 96.12226066897347],
  ["SZ", 73.34890426758939],
  ["PA", 77.8013264129181],
  ["SM", 93.43656286043829],
  ["IT", 73.4576124567474],
  ["JO", 97.03863898500576],
  ["GH", 69.1753171856978],
  ["CG", 77.02364475201846],
  ["BT", 92.18858131487889],
  ["AO", 34.28287197231834],
  ["ML", 72.85957324106113],
  ["TN", 58.23039215686274],
  ["LI", 80.36389850057671],
  ["MM", 87.88321799307958],
  ["MZ", 91.42387543252595],
  ["NA", 80.90657439446368],
  ["CF", 85.34486735870819],
  ["BB", 89.47001153402537],
  ["CD", 86.66926182237601],
  ["BW", 89.3961937716263],
  ["AL", 56.55680507497116],
  ["NC", 88.49307958477509],
  ["MX", 71.6280276816609],
  ["TZ", 80.44694348327567],
  ["VE", 83.96885813148789],
  ["MN", 67.08189158016148],
  ["MY", 74.699538638985],
  ["AM", 69.76326412918108],
  ["CR", 81.41695501730104],
  ["BA", 82.33189158016148],
  ["AZ", 83.60899653979239],
  ["CV", 86.79209919261822],
  ["BR", 87.34659746251442],
  ["CA", 91.37312572087659],
  ["BE", 48.47289504036909],
  ["TH", 78.20098039215686],
  ["WS", 62.66695501730104],
  ["UZ", 90.88523644752019],
  ["Wales", 81.54728950403691],
  ["NG", 94.2863321799308],
  ["NP", 84.88264129181084],
  ["MK", 83.55363321799308],
  ["BD", 91.88437139561707],
  ["BS", 75.0040369088812],
  ["CU", 91.52883506343714],
  ["BF", 92.92820069204151],
  ["NR", 88.97606689734717],
  ["NE", 92.92762399077277],
  ["ZW", 82.35294117647058],
  ["VC", 91.22895040369089],
  ["UY", 92.48702422145328],
  ["TJ", 91.3336216839677],
  ["VU", 52.84198385236447],
  ["ZA", 81.57006920415225],
  ["BG", 89.35322952710496],
]);

export const kmMap = new Map<string, number>([
  ["CY", 80.36368223760093],
  ["AF", 92.63804786620531],
  ["CN", 76.4082324106113],
  ["BJ", 60.37990196078431],
  ["LA", 92.60272491349481],
  ["ME", 78.23853806228374],
  ["OM", 75.28582756632065],
  ["NI", 83.55968858131489],
  ["MR", 89.86988177623991],
  ["TG", 88.12860438292964],
  ["TP", 82.55730968858131],
  ["North Korea", 87.71410034602076],
  ["VN", 79.37572087658593],
  ["ZM", 88.12103517877739],
  ["MD", 73.24430507497117],
  ["CO", 68.25908304498269],
  ["AG", 80.80485870818916],
  ["AE", 84.7015570934256],
  ["CZ", 60.757280853517884],
  ["AR", 83.79361303344868],
  ["BI", 80.5503892733564],
  ["CM", 76.0503171856978],
  ["LB", 78.17906574394463],
  ["LU", 83.53517877739331],
  ["TD", 81.21251441753172],
  ["TR", 77.62939734717416],
  ["Scotland", 82.7137399077278],
  ["UA", 95.85676182237601],
  ["LT", 66.34623702422145],
  ["LC", 96.59529988465975],
  ["MG", 93.72549019607843],
  ["CL", 62.089100346020764],
  ["BH", 76.92978662053056],
  ["AD", 78.23745674740485],
  ["CH", 85.302407727797],
  ["NO", 86.49149365628604],
  ["MT", 79.09746251441753],
  ["MC", 95.48803344867358],
  ["TV", 80.37017012687427],
  ["TW", 92.86944925028835],
  ["US", 71.34515570934255],
  ["MU", 95.99156574394463],
  ["BZ", 81.24567474048443],
  ["CI", 85.53056516724337],
  ["BM", 72.80817474048443],
  ["CK", 88.46957900807382],
  ["AT", 78.59284890426758],
  ["BO", 92.06675317185697],
  ["LS", 83.5906862745098],
  ["NL", 83.60402249134948],
  ["MW", 80.544261822376],
  ["YE", 86.22909457900806],
  ["UG", 84.48132929642446],
  ["TT", 81.51492214532871],
  ["NZ", 90.67906574394463],
  ["MA", 77.6834630911188],
  ["MV", 87.19579008073818],
  ["LR", 77.85935697808534],
  ["BY", 87.02890715109574],
  ["BN", 62.6441753171857],
  ["AU", 89.89727508650519],
  ["GE", 80.76376874279123],
  ["GR", 81.98385236447521],
  ["IN", 84.11404267589388],
  ["GBENG", 79.59955305651673],
  ["RS", 89.8861014994233],
  ["PL", 63.2529555940023],
  ["SV", 83.3881199538639],
  ["SA", 85.61887254901961],
  ["HK", 77.70076412918108],
  ["KG", 76.62665801614763],
  ["GD", 75.54065743944636],
  ["GQ", 77.52487024221453],
  ["DJ", 91.21359573241061],
  ["KE", 86.88292964244522],
  ["KR", 83.35063437139561],
  ["SC", 81.28207900807382],
  ["ST", 89.94521337946944],
  ["PY", 83.417315455594],
  ["SB", 84.65542099192618],
  ["IL", 76.20350346020761],
  ["DK", 77.96352364475202],
  ["DO", 85.09119088811995],
  ["RU", 81.36173587081892],
  ["SG", 93.44506920415225],
  ["PK", 91.9546568627451],
  ["GB", 71.46734429065744],
  ["GU", 93.11779123414071],
  ["GW", 71.48716839677047],
  ["JP", 78.57410611303345],
  ["SR", 76.02040080738178],
  ["SE", 88.34450692041523],
  ["SD", 84.2257785467128],
  ["SS", 78.41623414071512],
  ["PH", 77.30680507497117],
  ["RW", 93.2399798154556],
  ["KU", 81.60467128027682],
  ["HN", 83.44470876585929],
  ["GA", 70.71510957324107],
  ["DZ", 77.67156862745098],
  ["DM", 85.76268742791234],
  ["FR", 83.62420703575549],
  ["GL", 71.93375144175317],
  ["ES", 86.73262687427913],
  ["HT", 79.81725778546713],
  ["PE", 77.88927335640139],
  ["QA", 91.36714244521337],
  ["SI", 80.1975201845444],
  ["PR", 78.07273644752019],
  ["PS", 82.90873702422145],
  ["IQ", 85.21085640138408],
  ["HU", 83.80803056516724],
  ["KN", 79.4344723183391],
  ["KY", 88.22736447520184],
  ["FI", 87.62290945790082],
  ["ER", 60.11281718569781],
  ["GM", 86.92293829296425],
  ["EE", 97.77897923875433],
  ["EG", 86.37146770472896],
  ["ID", 95.48803344867358],
  ["IS", 84.25028835063438],
  ["SK", 84.38148788927336],
  ["RO", 80.5716551326413],
  ["PG", 86.8699538638985],
  ["KM", 0],
  ["IR", 74.60027393310266],
  ["KZ", 83.98464532871972],
  ["IE", 86.27811418685121],
  ["GN", 83.48147347174164],
  ["FJ", 84.38256920415225],
  ["GY", 83.77450980392157],
  ["JM", 87.51081314878893],
  ["KI", 83.18627450980392],
  ["HR", 85.10272491349481],
  ["SO", 69.04015282583622],
  ["PT", 87.02458189158017],
  ["SY", 85.7086216839677],
  ["SN", 82.5392877739331],
  ["KH", 81.34010957324107],
  ["EC", 62.96280276816609],
  ["ET", 82.51477797001154],
  ["DE", 95.83044982698962],
  ["SL", 83.53878316032295],
  ["PW", 89.31408592848904],
  ["SZ", 57.54865916955018],
  ["PA", 88.38631776239907],
  ["SM", 80.69384371395617],
  ["IT", 78.27782583621683],
  ["JO", 81.74380046136102],
  ["GH", 91.97628316032296],
  ["CG", 80.14777970011534],
  ["BT", 71.97267877739331],
  ["AO", 95.07497116493656],
  ["ML", 75.21049596309112],
  ["TN", 77.58073817762399],
  ["LI", 79.0967416378316],
  ["MM", 61.85229238754325],
  ["MZ", 90.19679930795847],
  ["NA", 81.65008650519032],
  ["CF", 72.57100634371395],
  ["BB", 88.10121107266437],
  ["CD", 85.16616205305651],
  ["BW", 91.54591983852364],
  ["AL", 79.43230968858131],
  ["NC", 91.05500288350635],
  ["MX", 78.96842560553632],
  ["TZ", 88.95941464821222],
  ["VE", 65.60878027681662],
  ["MN", 82.14244521337946],
  ["MY", 75.5161476355248],
  ["AM", 94.27443771626297],
  ["CR", 75.99877450980392],
  ["BA", 82.48017589388697],
  ["AZ", 83.00461361014995],
  ["CV", 86.35705017301038],
  ["BR", 84.65433967704729],
  ["CA", 84.77436562860439],
  ["BE", 89.49034025374856],
  ["TH", 89.79671280276817],
  ["WS", 79.51196655132642],
  ["UZ", 81.17827277970011],
  ["Wales", 69.46763264129181],
  ["NG", 86.04851499423299],
  ["NP", 78.06913206459055],
  ["MK", 74.19802479815456],
  ["BD", 89.37103517877739],
  ["BS", 94.37860438292964],
  ["CU", 79.16306228373703],
  ["BF", 90.9133506343714],
  ["NR", 95.99913494809688],
  ["NE", 84.31192329873126],
  ["ZW", 75.2883506343714],
  ["VC", 76.84940888119954],
  ["UY", 81.08816320645906],
  ["TJ", 80.8881199538639],
  ["VU", 95.60841983852364],
  ["ZA", 86.42985870818916],
  ["BG", 81.44427623990774],
]);

export const irMap = new Map<string, number>([
  ["CY", 67.45982413583991],
  ["AF", 82.06564584596725],
  ["CN", 87.74939357186173],
  ["BJ", 87.66449363250454],
  ["LA", 87.84225288053365],
  ["ME", 70.06329593693147],
  ["OM", 82.64857489387506],
  ["NI", 59.415554881746516],
  ["MR", 88.90805033353547],
  ["TG", 87.27372650090965],
  ["TP", 86.57140691328078],
  ["North Korea", 85.60187992722862],
  ["VN", 87.28812916919345],
  ["ZM", 86.14956033959976],
  ["MD", 89.73279260157672],
  ["CO", 87.30670103092784],
  ["AG", 87.64743784111583],
  ["AE", 69.99734687689508],
  ["CZ", 58.86029411764706],
  ["AR", 61.569890842935116],
  ["BI", 74.52092177077017],
  ["CM", 89.19648271679806],
  ["LB", 65.53744693753791],
  ["LU", 58.14243480897514],
  ["TD", 89.76425106124924],
  ["TR", 61.653274711946636],
  ["Scotland", 77.19792298362644],
  ["UA", 90.6886749545179],
  ["LT", 91.2166464523954],
  ["LC", 93.08823529411765],
  ["MG", 80.02501516070346],
  ["CL", 80.57572771376591],
  ["BH", 79.54972710733777],
  ["AD", 90.67086112795634],
  ["CH", 78.10491206791995],
  ["NO", 78.85650394178289],
  ["MT", 75.71369011522134],
  ["MC", 77.51440266828381],
  ["TV", 85.36082474226804],
  ["TW", 90.91456943602184],
  ["US", 76.39288963007883],
  ["MU", 93.46194663432384],
  ["BZ", 77.7558368708308],
  ["CI", 82.83012431776835],
  ["BM", 82.32565191024864],
  ["CK", 85.79669496664644],
  ["AT", 58.74166161309885],
  ["BO", 85.74401152213463],
  ["LS", 64.44625530624621],
  ["NL", 59.88591570648878],
  ["MW", 90.45368405093997],
  ["YE", 64.12712249848394],
  ["UG", 73.84096422073985],
  ["TT", 87.43518799272286],
  ["NZ", 87.24492116434203],
  ["MA", 90.1065039417829],
  ["MV", 88.86673741661613],
  ["LR", 77.60688295936932],
  ["BY", 85.3646149181322],
  ["BN", 78.0253183747726],
  ["AU", 85.70269860521528],
  ["GE", 78.40509399636143],
  ["GR", 71.34248029108551],
  ["IN", 59.36097634930261],
  ["GBENG", 81.65933899332929],
  ["RS", 85.07239235900545],
  ["PL", 75.82246816252274],
  ["SV", 60.351728320194056],
  ["SA", 86.26629775621589],
  ["HK", 84.42086112795634],
  ["KG", 84.16312916919345],
  ["GD", 81.78214069132808],
  ["GQ", 64.22528805336569],
  ["DJ", 75.74514857489388],
  ["KE", 89.25788356579746],
  ["KR", 71.34664948453609],
  ["SC", 76.85680715585204],
  ["ST", 58.18564281382657],
  ["PY", 60.69587628865979],
  ["SB", 82.38250454821103],
  ["IL", 64.74871134020619],
  ["DK", 76.50242571255306],
  ["DO", 72.41055184960582],
  ["RU", 91.54260157671315],
  ["SG", 73.85536688902366],
  ["PK", 84.13887204366283],
  ["GB", 72.5496513038205],
  ["GU", 91.86969375379017],
  ["GW", 87.93852334748333],
  ["JP", 71.86590357792602],
  ["SR", 92.36090054578533],
  ["SE", 89.20216798059431],
  ["SD", 70.20997574287446],
  ["SS", 86.82496967859309],
  ["PH", 79.46785930867192],
  ["RW", 86.79616434202548],
  ["KU", 67.82671315949061],
  ["HN", 59.56223468768951],
  ["GA", 85.21224984839296],
  ["DZ", 76.85529108550637],
  ["DM", 83.31071861734385],
  ["FR", 83.85953608247422],
  ["GL", 75.43738629472408],
  ["ES", 61.13515767131595],
  ["HT", 92.83429351121893],
  ["PE", 82.61522134627046],
  ["QA", 80.19216191631293],
  ["SI", 89.74605821710128],
  ["PR", 61.688523347483326],
  ["PS", 69.80025773195877],
  ["IQ", 66.43382352941177],
  ["HU", 59.26205275924803],
  ["KN", 86.7753183747726],
  ["KY", 86.32352941176471],
  ["FI", 88.90236506973923],
  ["ER", 62.14865069739236],
  ["GM", 79.78737113402062],
  ["EE", 93.2220285021225],
  ["EG", 65.05647362037598],
  ["ID", 77.51288659793815],
  ["IS", 81.48461188599151],
  ["SK", 88.84248029108551],
  ["RO", 89.81314432989691],
  ["PG", 89.32155852031535],
  ["KM", 74.23135233474834],
  ["IR", 0],
  ["KZ", 84.23097331716191],
  ["IE", 82.8763644633111],
  ["GN", 88.04730139478471],
  ["FJ", 85.06177986658581],
  ["GY", 80.51735900545786],
  ["JM", 86.12757731958763],
  ["KI", 77.86499393571862],
  ["HR", 59.771452395391144],
  ["SO", 87.09786234081261],
  ["PT", 87.2089144936325],
  ["SY", 65.92177077016373],
  ["SN", 88.1109763493026],
  ["KH", 85.48969072164948],
  ["EC", 85.60756519102486],
  ["ET", 36.25454821103699],
  ["DE", 95.98051849605822],
  ["SL", 32.607640994542145],
  ["PW", 88.46043056397816],
  ["SZ", 84.43374772589448],
  ["PA", 75.45254699818071],
  ["SM", 80.75614008489994],
  ["IT", 82.16305336567616],
  ["JO", 73.03820497271073],
  ["GH", 86.8560491206792],
  ["CG", 84.67442389326865],
  ["BT", 84.65130382049728],
  ["AO", 92.38932686476653],
  ["ML", 79.228320194057],
  ["TN", 59.87644026682838],
  ["LI", 92.73006367495452],
  ["MM", 85.05874772589448],
  ["MZ", 87.34725591267434],
  ["NA", 84.65547301394784],
  ["CF", 76.04078229229836],
  ["BB", 91.80829290479078],
  ["CD", 85.19633110976349],
  ["BW", 84.72104305639782],
  ["AL", 90.12355973317162],
  ["NC", 86.58770466949667],
  ["MX", 85.14061552456033],
  ["TZ", 72.46096118859914],
  ["VE", 89.7775166767738],
  ["MN", 86.95838386901153],
  ["MY", 76.41866282595512],
  ["AM", 93.61696482716798],
  ["CR", 91.89736203759854],
  ["BA", 88.5464675560946],
  ["AZ", 88.43731049120679],
  ["CV", 84.7775166767738],
  ["BR", 85.8641600970285],
  ["CA", 83.70413887204367],
  ["BE", 90.95322922983627],
  ["TH", 94.11651000606427],
  ["WS", 91.81701030927834],
  ["UZ", 57.55382049727107],
  ["Wales", 75.45520012128563],
  ["NG", 82.30101576713159],
  ["NP", 72.70808065494239],
  ["MK", 64.275697392359],
  ["BD", 87.42268041237114],
  ["BS", 85.34907519708914],
  ["CU", 80.32443905397211],
  ["BF", 89.23097331716193],
  ["NR", 93.12803214069133],
  ["NE", 61.43837174044876],
  ["ZW", 85.82095209217707],
  ["VC", 86.91896604002426],
  ["UY", 79.56412977562158],
  ["TJ", 59.63121588841722],
  ["VU", 87.27675864160098],
  ["ZA", 81.59983323226197],
  ["BG", 91.43041237113401],
]);

export const kzMap = new Map<string, number>([
  ["CY", 89.02508650519032],
  ["AF", 94.58780276816609],
  ["CN", 84.6734429065744],
  ["BJ", 86.88754325259515],
  ["LA", 95.20328719723183],
  ["ME", 86.20328719723183],
  ["OM", 84.93295847750865],
  ["NI", 89.965830449827],
  ["MR", 90.56141868512111],
  ["TG", 86.50821799307958],
  ["TP", 89.13062283737024],
  ["North Korea", 85.0544982698962],
  ["VN", 88.12240484429066],
  ["ZM", 83.91435986159169],
  ["MD", 92.93728373702422],
  ["CO", 92.33131487889274],
  ["AG", 87.0696366782007],
  ["AE", 92.2612456747405],
  ["CZ", 92.42214532871972],
  ["AR", 87.04411764705883],
  ["BI", 84.67387543252595],
  ["CM", 87.62673010380622],
  ["LB", 90.41998269896195],
  ["LU", 62.38797577854671],
  ["TD", 92.54455017301038],
  ["TR", 86.3075259515571],
  ["Scotland", 86.0696366782007],
  ["UA", 90.6401384083045],
  ["LT", 86.32482698961937],
  ["LC", 85.27768166089965],
  ["MG", 89.8970588235294],
  ["CL", 91.33088235294117],
  ["BH", 87.55709342560553],
  ["AD", 89.46539792387543],
  ["CH", 90.93512110726644],
  ["NO", 88.49005190311419],
  ["MT", 91.49870242214533],
  ["MC", 93.33823529411764],
  ["TV", 73.92603806228374],
  ["TW", 90.34645328719724],
  ["US", 81.04584775086505],
  ["MU", 92.1682525951557],
  ["BZ", 85.05839100346022],
  ["CI", 90.2166955017301],
  ["BM", 83.99567474048443],
  ["CK", 94.99005190311419],
  ["AT", 91.66955017301038],
  ["BO", 87.87240484429066],
  ["LS", 94.9515570934256],
  ["NL", 91.97058823529412],
  ["MW", 86.68209342560553],
  ["YE", 94.465830449827],
  ["UG", 91.24005190311418],
  ["TT", 89.99351211072664],
  ["NZ", 94.65484429065744],
  ["MA", 88.77681660899654],
  ["MV", 87.51859861591696],
  ["LR", 89.96972318339101],
  ["BY", 84.14186851211073],
  ["BN", 91.5220588235294],
  ["AU", 93.55579584775087],
  ["GE", 85.98313148788928],
  ["GR", 90.17993079584775],
  ["IN", 89.25865051903115],
  ["GBENG", 92.07525951557093],
  ["RS", 86.10250865051903],
  ["PL", 91.18079584775086],
  ["SV", 91.51384083044982],
  ["SA", 83.88927335640139],
  ["HK", 87.67733564013841],
  ["KG", 84.67430795847751],
  ["GD", 81.23659169550173],
  ["GQ", 87.60510380622837],
  ["DJ", 85.79887543252596],
  ["KE", 97.53070934256056],
  ["KR", 94.74005190311419],
  ["SC", 87.77032871972318],
  ["ST", 86.72621107266436],
  ["PY", 93.7318339100346],
  ["SB", 81.38754325259515],
  ["IL", 95.60510380622837],
  ["DK", 90.18858131487889],
  ["DO", 93.97102076124567],
  ["RU", 95.50778546712803],
  ["SG", 88.50519031141869],
  ["PK", 97.36721453287197],
  ["GB", 84.04498269896193],
  ["GU", 93.59342560553632],
  ["GW", 85.76643598615917],
  ["JP", 96.41349480968859],
  ["SR", 89.41911764705883],
  ["SE", 88.9887543252595],
  ["SD", 91.03373702422145],
  ["SS", 87.04195501730105],
  ["PH", 92.1137543252595],
  ["RW", 48.73399653979239],
  ["KU", 92.90484429065744],
  ["HN", 90.5242214532872],
  ["GA", 86.79757785467129],
  ["DZ", 91.08217993079585],
  ["DM", 88.48442906574394],
  ["FR", 96.88840830449827],
  ["GL", 90.94679930795849],
  ["ES", 80.79282006920415],
  ["HT", 92.19031141868513],
  ["PE", 93.76989619377163],
  ["QA", 97.99610726643598],
  ["SI", 87.19290657439447],
  ["PR", 84.49437716262975],
  ["PS", 91.08001730103807],
  ["IQ", 92.77638408304499],
  ["HU", 91.7166955017301],
  ["KN", 89.965830449827],
  ["KY", 91.79195501730104],
  ["FI", 97.31487889273357],
  ["ER", 71.9818339100346],
  ["GM", 92.83780276816609],
  ["EE", 95.18944636678201],
  ["EG", 92.06358131487889],
  ["ID", 93.32136678200692],
  ["IS", 94.70847750865052],
  ["SK", 86.3659169550173],
  ["RO", 93],
  ["PG", 91.18209342560554],
  ["KM", 83.12846020761245],
  ["IR", 83.76600346020761],
  ["KZ", 0],
  ["IE", 92.81401384083044],
  ["GN", 89.4515570934256],
  ["FJ", 77.16911764705883],
  ["GY", 84.65657439446368],
  ["JM", 87.77897923875433],
  ["KI", 87.72750865051903],
  ["HR", 90.53892733564014],
  ["SO", 86.28719723183391],
  ["PT", 87.44723183391004],
  ["SY", 93.11072664359862],
  ["SN", 90.98010380622837],
  ["KH", 90.69377162629758],
  ["EC", 87.6098615916955],
  ["ET", 84.29584775086505],
  ["DE", 92.52811418685121],
  ["SL", 91.25086505190312],
  ["PW", 82.27811418685121],
  ["SZ", 90.24005190311418],
  ["PA", 91.43036332179932],
  ["SM", 85.20934256055364],
  ["IT", 93.20242214532873],
  ["JO", 88.96626297577855],
  ["GH", 89.22707612456747],
  ["CG", 87.34645328719724],
  ["BT", 78.76340830449827],
  ["AO", 93.81012110726643],
  ["ML", 88.12932525951557],
  ["TN", 89.79887543252595],
  ["LI", 92.40354671280276],
  ["MM", 87.98659169550173],
  ["MZ", 89.8166089965398],
  ["NA", 87.32352941176471],
  ["CF", 89.14576124567473],
  ["BB", 95.37586505190312],
  ["CD", 83.42214532871972],
  ["BW", 88.14446366782008],
  ["AL", 88.69420415224913],
  ["NC", 88.92733564013841],
  ["MX", 91.875],
  ["TZ", 63.85510380622838],
  ["VE", 93.98053633217994],
  ["MN", 85.47491349480968],
  ["MY", 86.25389273356402],
  ["AM", 93.25475778546712],
  ["CR", 89.15051903114187],
  ["BA", 94.23745674740485],
  ["AZ", 58.21756055363322],
  ["CV", 95.12153979238754],
  ["BR", 89.6068339100346],
  ["CA", 87.50865051903114],
  ["BE", 95.01384083044982],
  ["TH", 96.82179930795847],
  ["WS", 91.3780276816609],
  ["UZ", 62.399221453287204],
  ["Wales", 79.41046712802769],
  ["NG", 93.50951557093425],
  ["NP", 87.42993079584775],
  ["MK", 80.41825259515572],
  ["BD", 85.04757785467129],
  ["BS", 40.298875432525946],
  ["CU", 92.17171280276817],
  ["BF", 86.26600346020761],
  ["NR", 97.23832179930795],
  ["NE", 89.79887543252595],
  ["ZW", 88.27638408304497],
  ["VC", 88.61418685121107],
  ["UY", 93.1371107266436],
  ["TJ", 94.16782006920415],
  ["VU", 89.62889273356402],
  ["ZA", 84.74783737024222],
  ["BG", 88.91003460207612],
]);

export const ieMap = new Map<string, number>([
  ["CY", 75.22664359861592],
  ["AF", 96.29974048442907],
  ["CN", 97.16479238754326],
  ["BJ", 65.69636678200692],
  ["LA", 91.02205882352942],
  ["ME", 97.38754325259515],
  ["OM", 85.01643598615917],
  ["NI", 89.31963667820068],
  ["MR", 97.22015570934256],
  ["TG", 96.24178200692042],
  ["TP", 96.4446366782007],
  ["North Korea", 92.8659169550173],
  ["VN", 97.28200692041523],
  ["ZM", 88.69290657439446],
  ["MD", 93.87067474048443],
  ["CO", 97.89749134948097],
  ["AG", 87.59948096885813],
  ["AE", 85.55579584775086],
  ["CZ", 82.16998269896195],
  ["AR", 90.80579584775087],
  ["BI", 64.35034602076125],
  ["CM", 98.42474048442907],
  ["LB", 89.72534602076124],
  ["LU", 88.3083910034602],
  ["TD", 97.20501730103807],
  ["TR", 96.05320069204151],
  ["Scotland", 83.53719723183391],
  ["UA", 97.99524221453288],
  ["LT", 98.87110726643598],
  ["LC", 94.7318339100346],
  ["MG", 99.58001730103805],
  ["CL", 81.45501730103805],
  ["BH", 96.94723183391002],
  ["AD", 93.45415224913495],
  ["CH", 84.68079584775087],
  ["NO", 89.97404844290658],
  ["MT", 82.63581314878893],
  ["MC", 83.11245674740483],
  ["TV", 84.33780276816609],
  ["TW", 98.63235294117646],
  ["US", 74.72102076124567],
  ["MU", 89.79757785467129],
  ["BZ", 82.17820069204153],
  ["CI", 66.30147058823529],
  ["BM", 90.29714532871972],
  ["CK", 92.58131487889273],
  ["AT", 88.44679930795849],
  ["BO", 97.81358131487889],
  ["LS", 79.89186851211073],
  ["NL", 88.46150519031141],
  ["MW", 99.0121107266436],
  ["YE", 88.51167820069205],
  ["UG", 93.17474048442907],
  ["TT", 93.6038062283737],
  ["NZ", 93.38970588235294],
  ["MA", 99.69636678200692],
  ["MV", 89.93425605536332],
  ["LR", 82.97534602076124],
  ["BY", 92.74783737024221],
  ["BN", 89.01903114186851],
  ["AU", 92.80709342560553],
  ["GE", 80.8317474048443],
  ["GR", 84.5363321799308],
  ["IN", 90.74437716262975],
  ["GBENG", 82.63754325259515],
  ["RS", 82.88667820069205],
  ["PL", 83.03762975778547],
  ["SV", 90.50951557093425],
  ["SA", 93.23226643598616],
  ["HK", 90.92776816608996],
  ["KG", 91.77076124567473],
  ["GD", 89.19982698961938],
  ["GQ", 86.68295847750865],
  ["DJ", 88.03416955017302],
  ["KE", 95.12326989619378],
  ["KR", 79.14359861591696],
  ["SC", 86.55276816608996],
  ["ST", 78.58131487889274],
  ["PY", 90.4022491349481],
  ["SB", 88.68079584775086],
  ["IL", 80.53806228373702],
  ["DK", 86.10942906574395],
  ["DO", 82.73832179930795],
  ["RU", 88.21626297577855],
  ["SG", 79.11937716262976],
  ["PK", 94.95934256055362],
  ["GB", 85.05017301038063],
  ["GU", 97.43425605536332],
  ["GW", 96.30882352941177],
  ["JP", 83.7590830449827],
  ["SR", 91.66695501730104],
  ["SE", 96.52681660899654],
  ["SD", 85.65181660899654],
  ["SS", 86.4325259515571],
  ["PH", 96.84688581314879],
  ["RW", 92.27984429065744],
  ["KU", 87.07698961937716],
  ["HN", 88.97015570934256],
  ["GA", 86.93944636678201],
  ["DZ", 85.27249134948097],
  ["DM", 94.9173875432526],
  ["FR", 66.31401384083046],
  ["GL", 81.96756055363322],
  ["ES", 94.34039792387543],
  ["HT", 97.54195501730104],
  ["PE", 66.32223183391004],
  ["QA", 98.20328719723183],
  ["SI", 85.71150519031143],
  ["PR", 82.76340830449827],
  ["PS", 85.45717993079585],
  ["IQ", 90.2742214532872],
  ["HU", 77.41435986159169],
  ["KN", 72.80017301038062],
  ["KY", 91.49740484429066],
  ["FI", 83.42690311418686],
  ["ER", 84.66695501730104],
  ["GM", 95.51427335640138],
  ["EE", 88.50821799307958],
  ["EG", 90.18771626297578],
  ["ID", 83.11245674740483],
  ["IS", 91.70588235294119],
  ["SK", 85.26946366782006],
  ["RO", 96.61634948096885],
  ["PG", 95.27162629757785],
  ["KM", 85.40484429065745],
  ["IR", 82.85337370242215],
  ["KZ", 92.81401384083044],
  ["IE", 0],
  ["GN", 96.46193771626298],
  ["FJ", 89.89273356401384],
  ["GY", 83.625],
  ["JM", 85.94204152249135],
  ["KI", 86.98140138408304],
  ["HR", 91.01038062283737],
  ["SO", 95.22664359861591],
  ["PT", 93.85207612456747],
  ["SY", 87.92517301038062],
  ["SN", 61.74740484429066],
  ["KH", 92.75346020761246],
  ["EC", 94.20025951557093],
  ["ET", 85.25043252595155],
  ["DE", 98.05060553633218],
  ["SL", 77.35899653979239],
  ["PW", 96.15570934256056],
  ["SZ", 88.01946366782006],
  ["PA", 82.15787197231835],
  ["SM", 85.57223183391004],
  ["IT", 33.08001730103806],
  ["JO", 87.84429065743944],
  ["GH", 96.43728373702423],
  ["CG", 69.97750865051903],
  ["BT", 87.44636678200692],
  ["AO", 96.07828719723184],
  ["ML", 63.67560553633218],
  ["TN", 90.5432525951557],
  ["LI", 97.39792387543253],
  ["MM", 86.8840830449827],
  ["MZ", 90.7871972318339],
  ["NA", 89.15138408304499],
  ["CF", 92.91349480968859],
  ["BB", 94.53114186851211],
  ["CD", 92.86505190311418],
  ["BW", 96.52854671280276],
  ["AL", 97.6038062283737],
  ["NC", 86.12586505190312],
  ["MX", 70.60423875432525],
  ["TZ", 72.49740484429066],
  ["VE", 97.29498269896195],
  ["MN", 94.8295847750865],
  ["MY", 77.39186851211073],
  ["AM", 99.21496539792388],
  ["CR", 85.79584775086505],
  ["BA", 92.37153979238755],
  ["AZ", 86.52249134948097],
  ["CV", 91.78287197231833],
  ["BR", 64.83650519031143],
  ["CA", 78.90527681660899],
  ["BE", 96.75735294117646],
  ["TH", 88.30579584775087],
  ["WS", 97.0220588235294],
  ["UZ", 75.6673875432526],
  ["Wales", 74.28935986159169],
  ["NG", 33.08001730103806],
  ["NP", 80.86721453287197],
  ["MK", 87.10510380622839],
  ["BD", 98.00475778546712],
  ["BS", 96.30363321799308],
  ["CU", 81.65657439446366],
  ["BF", 81.6159169550173],
  ["NR", 97.04498269896193],
  ["NE", 82.87240484429066],
  ["ZW", 94.34126297577855],
  ["VC", 92.81228373702423],
  ["UY", 77.99221453287197],
  ["TJ", 85.81228373702422],
  ["VU", 92.01297577854672],
  ["ZA", 90.85423875432525],
  ["BG", 87.97361591695501],
]);

export const gnMap = new Map<string, number>([
  ["CY", 93.55953614926148],
  ["AF", 94.999352163773],
  ["CN", 67.63636952578388],
  ["BJ", 85.58337652241514],
  ["LA", 80.24391033946618],
  ["ME", 64.95335579165587],
  ["OM", 71.95678932365898],
  ["NI", 93.23594195387406],
  ["MR", 93.62658719875616],
  ["TG", 68.7104819901529],
  ["TP", 85.39906711583312],
  ["North Korea", 93.30169733091475],
  ["VN", 63.9981212749417],
  ["ZM", 94.90962684633325],
  ["MD", 71.57683337652242],
  ["CO", 74.23393366157035],
  ["AG", 67.66681782845296],
  ["AE", 62.27811609225188],
  ["CZ", 91.55059600932884],
  ["AR", 94.1412930811091],
  ["BI", 82.85080331692149],
  ["CM", 98.07268722466961],
  ["LB", 77.59490800725577],
  ["LU", 87.89744752526562],
  ["TD", 65.87652241513345],
  ["TR", 68.0007774034724],
  ["Scotland", 90.77092511013217],
  ["UA", 82.65029800466442],
  ["LT", 76.79677377558953],
  ["LC", 87.62308888313034],
  ["MG", 99.34115055713916],
  ["CL", 80.36667530448302],
  ["BH", 96.00803316921483],
  ["AD", 69.02273905156775],
  ["CH", 96.32871210158072],
  ["NO", 92.320225447007],
  ["MT", 98.2155351127235],
  ["MC", 81.85054418243068],
  ["TV", 80.28861103912931],
  ["TW", 95.44506348795025],
  ["US", 74.22259652759783],
  ["MU", 74.05059600932884],
  ["BZ", 79.77746825602487],
  ["CI", 63.52941176470588],
  ["BM", 67.76885203420575],
  ["CK", 85.05344648872764],
  ["AT", 75.97434568541073],
  ["BO", 76.86771184244623],
  ["LS", 87.38760041461518],
  ["NL", 97.59717543405027],
  ["MW", 87.17770147706659],
  ["YE", 87.90003887017363],
  ["UG", 84.42893236589791],
  ["TT", 69.79625550660793],
  ["NZ", 84.7143042238922],
  ["MA", 66.3232054936512],
  ["MV", 72.60268204197978],
  ["LR", 79.6757579683856],
  ["BY", 92.83266390256543],
  ["BN", 78.06264576315107],
  ["AU", 89.43184762891941],
  ["GE", 92.2172194869137],
  ["GR", 92.1401269759005],
  ["IN", 94.70069966312516],
  ["GBENG", 90.65528634361235],
  ["RS", 90.04372894532263],
  ["PL", 82.64867841409692],
  ["SV", 93.70270795542886],
  ["SA", 91.31510754081368],
  ["HK", 58.90321326768593],
  ["KG", 57.297551179061934],
  ["GD", 50.15191759523192],
  ["GQ", 84.70199533557916],
  ["DJ", 94.90573982897124],
  ["KE", 95.06122052345167],
  ["KR", 96.77215599896346],
  ["SC", 87.46987561544441],
  ["ST", 72.16539258875356],
  ["PY", 85.16390256543146],
  ["SB", 87.49222596527598],
  ["IL", 94.08557916558694],
  ["DK", 70.20860326509458],
  ["DO", 81.90561026172584],
  ["RU", 87.29107281679191],
  ["SG", 84.66506867064007],
  ["PK", 95.87652241513346],
  ["GB", 74.22615962684634],
  ["GU", 89.23684892459185],
  ["GW", 34.82605597305001],
  ["JP", 96.75628401140192],
  ["SR", 80.91215340761856],
  ["SE", 83.95957501943508],
  ["SD", 88.42154703291007],
  ["SS", 92.16506867064005],
  ["PH", 90.69933920704845],
  ["RW", 85.65463850738533],
  ["KU", 90.04923555325213],
  ["HN", 95.21929256284012],
  ["GA", 76.86900751490023],
  ["DZ", 95.0375745011661],
  ["DM", 93.87600414615184],
  ["FR", 96.31705104949468],
  ["GL", 80.3559860067375],
  ["ES", 65.64589271832081],
  ["HT", 79.94979269240736],
  ["PE", 63.376198497019956],
  ["QA", 97.81258097952838],
  ["SI", 85.70840891422648],
  ["PR", 90.20018139414357],
  ["PS", 81.4663772998186],
  ["IQ", 84.00783881834673],
  ["HU", 75.75667271313812],
  ["KN", 91.61440787768852],
  ["KY", 83.46462814200571],
  ["FI", 97.95704845814977],
  ["ER", 70.41623477584866],
  ["GM", 85.85222855662089],
  ["EE", 99.23944026949987],
  ["EG", 83.01276237367193],
  ["ID", 82.140450894014],
  ["IS", 90.535436641617],
  ["SK", 94.04087846592381],
  ["RO", 65.87652241513345],
  ["PG", 88.55143819642394],
  ["KM", 82.59620367970977],
  ["IR", 87.93955688002073],
  ["KZ", 89.3933013734128],
  ["IE", 96.4641098730241],
  ["GN", 0],
  ["FJ", 82.59555584348277],
  ["GY", 38.324371598859805],
  ["JM", 85.79392329619073],
  ["KI", 67.45011661052087],
  ["HR", 93.25796838559212],
  ["SO", 96.4288027986525],
  ["PT", 92.86959056750453],
  ["SY", 86.03038351904638],
  ["SN", 97.06756931847629],
  ["KH", 77.11745270795542],
  ["EC", 75.2866675304483],
  ["ET", 85.11596268463333],
  ["DE", 87.48931070225447],
  ["SL", 97.18515159367712],
  ["PW", 82.59231666234776],
  ["SZ", 73.39239440269499],
  ["PA", 95.97207825861622],
  ["SM", 90.53932365897901],
  ["IT", 96.61052086032652],
  ["JO", 75.09879502461779],
  ["GH", 79.41046903342836],
  ["CG", 94.66215340761856],
  ["BT", 76.68664161699922],
  ["AO", 78.38040943249547],
  ["ML", 65.73011142783103],
  ["TN", 59.70426276237367],
  ["LI", 80.08745789064524],
  ["MM", 73.74157812904897],
  ["MZ", 70.41169992225966],
  ["NA", 60.48587717025136],
  ["CF", 92.42938585125681],
  ["BB", 69.98186058564396],
  ["CD", 85.95620627105468],
  ["BW", 97.41934438973827],
  ["AL", 65.52701477066599],
  ["NC", 79.13027986525006],
  ["MX", 90.99280901788028],
  ["TZ", 91.9616480953615],
  ["VE", 74.95400362788287],
  ["MN", 72.82748121274942],
  ["MY", 81.23088883130345],
  ["AM", 87.55571391552216],
  ["CR", 86.77669085255248],
  ["BA", 78.3574112464369],
  ["AZ", 75.08940139932625],
  ["CV", 90.892394402695],
  ["BR", 88.81024876911117],
  ["CA", 93.82871210158072],
  ["BE", 98.95536408395958],
  ["TH", 86.90204716247733],
  ["WS", 80.08162736460223],
  ["UZ", 93.85106245141228],
  ["Wales", 88.58771702513604],
  ["NG", 63.23497019953356],
  ["NP", 74.93975123088883],
  ["MK", 78.79534853589013],
  ["BD", 97.45951023581239],
  ["BS", 96.08868877947656],
  ["CU", 76.02293340243587],
  ["BF", 80.03109613889609],
  ["NR", 93.95698367452708],
  ["NE", 96.54217413837782],
  ["ZW", 84.81147965794247],
  ["VC", 46.80746307333506],
  ["UY", 91.93735423684892],
  ["TJ", 90.40327805130863],
  ["VU", 76.964563358383],
  ["ZA", 85.97693703031874],
  ["BG", 76.48030577869915],
]);

export const fjMap = new Map<string, number>([
  ["CY", 85.4022491349481],
  ["AF", 88.28373702422145],
  ["CN", 84.04282006920415],
  ["BJ", 87.30449826989619],
  ["LA", 85.5220588235294],
  ["ME", 78.17301038062284],
  ["OM", 84.66782006920414],
  ["NI", 87.96929065743944],
  ["MR", 87.15787197231833],
  ["TG", 83.51124567474048],
  ["TP", 83.72967128027682],
  ["North Korea", 83.76730103806229],
  ["VN", 80.1310553633218],
  ["ZM", 88.66176470588235],
  ["MD", 82.86721453287197],
  ["CO", 86.90787197231835],
  ["AG", 88.00865051903114],
  ["AE", 86.82612456747405],
  ["CZ", 86.36029411764706],
  ["AR", 44.08607266435986],
  ["BI", 83.7136678200692],
  ["CM", 85.37283737024222],
  ["LB", 82.08044982698962],
  ["LU", 85.10813148788927],
  ["TD", 84.0493079584775],
  ["TR", 82.9000865051903],
  ["Scotland", 87.43858131487889],
  ["UA", 87.39705882352942],
  ["LT", 91.2923875432526],
  ["LC", 41.53200692041523],
  ["MG", 88.06185121107266],
  ["CL", 88.15268166089966],
  ["BH", 85.05190311418686],
  ["AD", 80.8386678200692],
  ["CH", 89.05103806228374],
  ["NO", 86.49437716262976],
  ["MT", 86.78287197231835],
  ["MC", 82.78546712802768],
  ["TV", 60.91479238754325],
  ["TW", 88.90441176470588],
  ["US", 83.17301038062284],
  ["MU", 83.32093425605537],
  ["BZ", 82.67171280276817],
  ["CI", 91.72750865051903],
  ["BM", 70.94593425605537],
  ["CK", 71.2288062283737],
  ["AT", 85.40095155709344],
  ["BO", 82.01859861591696],
  ["LS", 87.81401384083046],
  ["NL", 88.37110726643598],
  ["MW", 87.77508650519032],
  ["YE", 84.08823529411765],
  ["UG", 87.35250865051903],
  ["TT", 86.51081314878893],
  ["NZ", 71.36245674740485],
  ["MA", 82.34688581314879],
  ["MV", 85.39878892733564],
  ["LR", 82.8083910034602],
  ["BY", 86.0787197231834],
  ["BN", 83.12326989619378],
  ["AU", 79.99005190311419],
  ["GE", 86.95415224913495],
  ["GR", 86.92214532871972],
  ["IN", 91.26427335640138],
  ["GBENG", 85.99826989619376],
  ["RS", 84.875],
  ["PL", 87.24437716262976],
  ["SV", 88.40268166089965],
  ["SA", 87.2863321799308],
  ["HK", 82.93944636678201],
  ["KG", 76.04714532871972],
  ["GD", 80.40397923875433],
  ["GQ", 88.17387543252595],
  ["DJ", 69.7136678200692],
  ["KE", 89.83347750865052],
  ["KR", 87.45069204152249],
  ["SC", 82.43901384083046],
  ["ST", 82.70977508650519],
  ["PY", 83.09342560553632],
  ["SB", 81.9567474048443],
  ["IL", 87.4022491349481],
  ["DK", 83.7681660899654],
  ["DO", 87.07093425605537],
  ["RU", 88.29282006920415],
  ["SG", 85.93382352941177],
  ["PK", 88.93814878892734],
  ["GB", 83.23096885813149],
  ["GU", 84.97058823529412],
  ["GW", 82.94679930795847],
  ["JP", 86.94636678200692],
  ["SR", 87.18209342560553],
  ["SE", 87.05579584775086],
  ["SD", 85.08607266435986],
  ["SS", 88.530276816609],
  ["PH", 86.90916955017302],
  ["RW", 85.27076124567475],
  ["KU", 90.06055363321799],
  ["HN", 89.02032871972318],
  ["GA", 87.32179930795849],
  ["DZ", 88.97707612456747],
  ["DM", 87.63321799307958],
  ["FR", 88.22534602076124],
  ["GL", 84.780276816609],
  ["ES", 79.58044982698962],
  ["HT", 88.67517301038063],
  ["PE", 83.55493079584775],
  ["QA", 87.61678200692042],
  ["SI", 86.9515570934256],
  ["PR", 89.48659169550173],
  ["PS", 89.52378892733563],
  ["IQ", 83.52941176470588],
  ["HU", 83.85121107266436],
  ["KN", 84.66911764705883],
  ["KY", 69.51903114186851],
  ["FI", 86.89662629757785],
  ["ER", 76.78719723183391],
  ["GM", 84.09818339100346],
  ["EE", 93.44896193771626],
  ["EG", 82.24567474048443],
  ["ID", 82.8659169550173],
  ["IS", 86.71929065743946],
  ["SK", 87.00173010380622],
  ["RO", 84.18814878892734],
  ["PG", 86.10813148788927],
  ["KM", 83.93295847750865],
  ["IR", 84.51513840830451],
  ["KZ", 77.16911764705883],
  ["IE", 89.89273356401384],
  ["GN", 82.89143598615917],
  ["FJ", 0],
  ["GY", 82.84991349480968],
  ["JM", 83.91392733564014],
  ["KI", 79.70025951557093],
  ["HR", 86.23961937716264],
  ["SO", 88.01124567474048],
  ["PT", 85.1098615916955],
  ["SY", 83.62759515570934],
  ["SN", 85.7136678200692],
  ["KH", 82.72318339100346],
  ["EC", 82.92214532871972],
  ["ET", 84.61894463667821],
  ["DE", 91.28503460207612],
  ["SL", 89.74740484429066],
  ["PW", 38.44506920415225],
  ["SZ", 84.34645328719724],
  ["PA", 86.14965397923875],
  ["SM", 44.20415224913495],
  ["IT", 88.07309688581316],
  ["JO", 88.29411764705883],
  ["GH", 80.79930795847751],
  ["CG", 84.25994809688582],
  ["BT", 84.92863321799308],
  ["AO", 80.76167820069205],
  ["ML", 87.45847750865052],
  ["TN", 82.44852941176471],
  ["LI", 84.31141868512111],
  ["MM", 85.53287197231833],
  ["MZ", 86.11375432525952],
  ["NA", 84.17690311418686],
  ["CF", 84.78157439446366],
  ["BB", 84.50735294117648],
  ["CD", 83.50043252595157],
  ["BW", 43.2833044982699],
  ["AL", 83.69852941176471],
  ["NC", 84.33823529411765],
  ["MX", 85.88235294117646],
  ["TZ", 81.78157439446366],
  ["VE", 85.68944636678201],
  ["MN", 82.14359861591696],
  ["MY", 85.24653979238754],
  ["AM", 84.31314878892734],
  ["CR", 84.1712802768166],
  ["BA", 85.23659169550173],
  ["AZ", 87.30276816608996],
  ["CV", 87.53416955017302],
  ["BR", 83.39965397923875],
  ["CA", 87.68122837370242],
  ["BE", 88.51384083044984],
  ["TH", 87.47102076124568],
  ["WS", 83.73269896193771],
  ["UZ", 87.50129757785467],
  ["Wales", 81.40311418685121],
  ["NG", 89.21410034602076],
  ["NP", 84.2863321799308],
  ["MK", 76.55882352941177],
  ["BD", 88.5008650519031],
  ["BS", 86.72750865051904],
  ["CU", 87.60164359861592],
  ["BF", 89.18166089965398],
  ["NR", 85.80103806228374],
  ["NE", 88.12586505190312],
  ["ZW", 86.42257785467127],
  ["VC", 82.94593425605537],
  ["UY", 86.32525951557093],
  ["TJ", 86.06790657439446],
  ["VU", 84.41479238754324],
  ["ZA", 86.00821799307958],
  ["BG", 89.16998269896193],
]);

export const gyMap = new Map<string, number>([
  ["CY", 84.10647347174164],
  ["AF", 86.925461361015],
  ["CN", 76.43995098039215],
  ["BJ", 79.30471453287197],
  ["LA", 88.07129469434832],
  ["ME", 68.5553633217993],
  ["OM", 74.06538350634372],
  ["NI", 86.03445790080738],
  ["MR", 84.1118800461361],
  ["TG", 73.85741061130334],
  ["TP", 94.75634371395617],
  ["North Korea", 89.15873702422145],
  ["VN", 74.08016147635524],
  ["ZM", 89.04664071510958],
  ["MD", 81.84760668973472],
  ["CO", 81.11555651672434],
  ["AG", 72.30572376009228],
  ["AE", 55.84702998846598],
  ["CZ", 83.11058246828144],
  ["AR", 85.3770184544406],
  ["BI", 72.82439446366782],
  ["CM", 86.9899798154556],
  ["LB", 84.23551038062284],
  ["LU", 86.88797577854672],
  ["TD", 78.92589388696655],
  ["TR", 76.29577566320646],
  ["Scotland", 83.61303344867359],
  ["UA", 83.93562572087659],
  ["LT", 88.35568050749711],
  ["LC", 89.78085351787773],
  ["MG", 91.79822664359861],
  ["CL", 79.47339965397924],
  ["BH", 89.64604959630911],
  ["AD", 81.18331891580162],
  ["CH", 88.02335640138408],
  ["NO", 84.98810553633218],
  ["MT", 89.92683102652825],
  ["MC", 80.48947520184544],
  ["TV", 79.10719434832757],
  ["TW", 88.9788783160323],
  ["US", 75.81423010380622],
  ["MU", 65.13372260668973],
  ["BZ", 78.92084775086505],
  ["CI", 63.07345732410611],
  ["BM", 74.6723615916955],
  ["CK", 85.66500865051904],
  ["AT", 83.1895184544406],
  ["BO", 78.35099480968857],
  ["LS", 68.71503748558247],
  ["NL", 88.43713956170703],
  ["MW", 79.30291234140715],
  ["YE", 86.9636678200692],
  ["UG", 83.04101787773934],
  ["TT", 73.15491637831603],
  ["NZ", 85.97066032295271],
  ["MA", 75.93858131487889],
  ["MV", 79.89475201845444],
  ["LR", 80.02234717416378],
  ["BY", 88.62420703575548],
  ["BN", 83.30269607843137],
  ["AU", 87.93504901960785],
  ["GE", 86.79570357554786],
  ["GR", 85.9234429065744],
  ["IN", 87.3165369088812],
  ["GBENG", 81.45076412918108],
  ["RS", 86.99754901960785],
  ["PL", 81.09897635524798],
  ["SV", 85.37305363321799],
  ["SA", 86.61043829296425],
  ["HK", 70.78899942329872],
  ["KG", 69.14576124567475],
  ["GD", 68.18447231833909],
  ["GQ", 84.99279123414073],
  ["DJ", 65.20004325259515],
  ["KE", 86.72613898500576],
  ["KR", 87.37276528258361],
  ["SC", 81.35993367935409],
  ["ST", 32.778979238754324],
  ["PY", 83.69160899653978],
  ["SB", 83.89814013840831],
  ["IL", 84.304354094579],
  ["DK", 77.70689158016147],
  ["DO", 81.86130334486735],
  ["RU", 85.87334198385237],
  ["SG", 81.30875144175317],
  ["PK", 87.6946366782007],
  ["GB", 76.49509803921568],
  ["GU", 84.23659169550173],
  ["GW", 49.1763985005767],
  ["JP", 85.97498558246828],
  ["SR", 74.949538638985],
  ["SE", 78.98104094579008],
  ["SD", 88.11274509803921],
  ["SS", 68.6299740484429],
  ["PH", 94.3991493656286],
  ["RW", 80.98039215686275],
  ["KU", 89.33391003460207],
  ["HN", 87.80817474048442],
  ["GA", 60.56985294117647],
  ["DZ", 83.447592272203],
  ["DM", 90.1250720876586],
  ["FR", 86.4435553633218],
  ["GL", 79.85654555940023],
  ["ES", 72.51189446366783],
  ["HT", 82.09306516724337],
  ["PE", 72.80493079584775],
  ["QA", 89.04880334486735],
  ["SI", 87.29418973471742],
  ["PR", 83.72332756632065],
  ["PS", 75.05875144175317],
  ["IQ", 83.99906286043829],
  ["HU", 65.53020472895041],
  ["KN", 78.79901960784314],
  ["KY", 83.61447520184544],
  ["FI", 88.17798442906575],
  ["ER", 56.605752595155714],
  ["GM", 87.06278835063436],
  ["EE", 91.60755478662053],
  ["EG", 83.3044982698962],
  ["ID", 80.34205594002307],
  ["IS", 81.16349480968859],
  ["SK", 88.43101211072664],
  ["RO", 77.93180507497117],
  ["PG", 87.11252883506344],
  ["KM", 83.77450980392157],
  ["IR", 80.59616493656286],
  ["KZ", 85.12579296424451],
  ["IE", 84.10935697808534],
  ["GN", 38.505983275663205],
  ["FJ", 82.97541810841984],
  ["GY", 0],
  ["JM", 60.099120530565166],
  ["KI", 69.94341118800462],
  ["HR", 84.68966262975779],
  ["SO", 90.88271337946944],
  ["PT", 85.84090253748559],
  ["SY", 85.4819059976932],
  ["SN", 84.27660034602076],
  ["KH", 72.23940311418686],
  ["EC", 81.5635813148789],
  ["ET", 61.50446943483276],
  ["DE", 88.89994232987313],
  ["SL", 68.7308967704729],
  ["PW", 80.45271049596309],
  ["SZ", 71.7661476355248],
  ["PA", 85.5363321799308],
  ["SM", 84.96828143021915],
  ["IT", 85.05730968858131],
  ["JO", 73.15563725490196],
  ["GH", 79.95891003460207],
  ["CG", 79.73976355247981],
  ["BT", 83.90246539792388],
  ["AO", 81.30298442906575],
  ["ML", 76.88977797001154],
  ["TN", 70.83765859284891],
  ["LI", 82.40196078431372],
  ["MM", 83.30666089965398],
  ["MZ", 74.52962802768167],
  ["NA", 57.93973471741638],
  ["CF", 86.91861303344868],
  ["BB", 83.03849480968857],
  ["CD", 83.47858996539792],
  ["BW", 89.47412053056517],
  ["AL", 74.69506920415225],
  ["NC", 64.77977220299884],
  ["MX", 85.80305651672434],
  ["TZ", 78.26989619377163],
  ["VE", 84.44600634371395],
  ["MN", 80.13985005767013],
  ["MY", 76.28243944636678],
  ["AM", 87.83268454440599],
  ["CR", 79.5635092272203],
  ["BA", 78.10517589388697],
  ["AZ", 59.900879469434834],
  ["CV", 81.71064013840831],
  ["BR", 42.55767012687428],
  ["CA", 86.80219146482122],
  ["BE", 88.9183246828143],
  ["TH", 88.45552191464822],
  ["WS", 81.01463379469435],
  ["UZ", 66.46554209919262],
  ["Wales", 65.0140570934256],
  ["NG", 58.70602652825836],
  ["NP", 78.40506055363322],
  ["MK", 69.1803633217993],
  ["BD", 90.43180507497117],
  ["BS", 89.28236735870819],
  ["CU", 70.20833333333333],
  ["BF", 68.10805940023069],
  ["NR", 85.20004325259517],
  ["NE", 67.90873702422145],
  ["ZW", 86.1905276816609],
  ["VC", 59.64280565167244],
  ["UY", 84.03726931949251],
  ["TJ", 81.17322664359861],
  ["VU", 67.50720876585929],
  ["ZA", 86.33145905420993],
  ["BG", 87.68634659746252],
]);

export const jmMap = new Map<string, number>([
  ["CY", 87.49005190311419],
  ["AF", 74.46885813148789],
  ["CN", 92.70242214532873],
  ["BJ", 78.93771626297578],
  ["LA", 90.11115916955018],
  ["ME", 80.21280276816609],
  ["OM", 92.8317474048443],
  ["NI", 88.68685121107266],
  ["MR", 87.6159169550173],
  ["TG", 83.89489619377163],
  ["TP", 77.51773356401384],
  ["North Korea", 89.78503460207612],
  ["VN", 92.59558823529412],
  ["ZM", 88.62889273356402],
  ["MD", 85.97967128027682],
  ["CO", 78.75086505190312],
  ["AG", 91.5242214532872],
  ["AE", 77.3780276816609],
  ["CZ", 89.8810553633218],
  ["AR", 83.0795847750865],
  ["BI", 82.09818339100346],
  ["CM", 83.22967128027682],
  ["LB", 87.50735294117648],
  ["LU", 92.10899653979239],
  ["TD", 85.0674740484429],
  ["TR", 91.98313148788927],
  ["Scotland", 86.58261245674741],
  ["UA", 80.57915224913495],
  ["LT", 85.22534602076125],
  ["LC", 88.6613321799308],
  ["MG", 81.01989619377163],
  ["CL", 91.50346020761245],
  ["BH", 90.88062283737024],
  ["AD", 85.80449826989619],
  ["CH", 92.40181660899654],
  ["NO", 89.76600346020761],
  ["MT", 91.17603806228374],
  ["MC", 92.45977508650519],
  ["TV", 81.78157439446366],
  ["TW", 92.01038062283737],
  ["US", 81.62759515570934],
  ["MU", 72.76686851211073],
  ["BZ", 78.81185121107266],
  ["CI", 90.3317474048443],
  ["BM", 87.29108996539793],
  ["CK", 84.51730103806229],
  ["AT", 91.54368512110726],
  ["BO", 84.90916955017302],
  ["LS", 76.90138408304499],
  ["NL", 91.33996539792388],
  ["MW", 88.02249134948097],
  ["YE", 88.83131487889273],
  ["UG", 72.14100346020761],
  ["TT", 91.64143598615917],
  ["NZ", 85.7932525951557],
  ["MA", 94.62413494809688],
  ["MV", 91.43036332179932],
  ["LR", 85.38538062283737],
  ["BY", 92.78114186851211],
  ["BN", 66.86548442906575],
  ["AU", 86.32958477508652],
  ["GE", 88.56314878892734],
  ["GR", 87.42993079584775],
  ["IN", 88.04671280276817],
  ["GBENG", 90.73226643598616],
  ["RS", 85.46842560553634],
  ["PL", 92.7015570934256],
  ["SV", 87.59256055363322],
  ["SA", 89.40960207612457],
  ["HK", 89.57223183391004],
  ["KG", 87.44420415224914],
  ["GD", 79.00475778546713],
  ["GQ", 89.79411764705883],
  ["DJ", 71.51167820069205],
  ["KE", 83.38105536332179],
  ["KR", 88.4628027681661],
  ["SC", 88.46496539792388],
  ["ST", 55.14835640138408],
  ["PY", 89.37759515570934],
  ["SB", 83.91652249134948],
  ["IL", 86.99091695501731],
  ["DK", 93.27897923875432],
  ["DO", 90.47967128027682],
  ["RU", 91.17733564013841],
  ["SG", 90.5272491349481],
  ["PK", 87.50259515570934],
  ["GB", 81.44636678200692],
  ["GU", 84.91911764705883],
  ["GW", 71.63451557093425],
  ["JP", 89.36072664359862],
  ["SR", 87.52292387543253],
  ["SE", 84.57525951557093],
  ["SD", 90.90960207612456],
  ["SS", 77.86245674740483],
  ["PH", 88.98096885813149],
  ["RW", 86.1189446366782],
  ["KU", 77.70934256055362],
  ["HN", 90.02595155709344],
  ["GA", 84.77681660899654],
  ["DZ", 87.36245674740485],
  ["DM", 84.23745674740485],
  ["FR", 91.45631487889273],
  ["GL", 90.47750865051903],
  ["ES", 81.05795847750865],
  ["HT", 90.29195501730104],
  ["PE", 93.3568339100346],
  ["QA", 87.95415224913495],
  ["SI", 93.14489619377163],
  ["PR", 91.1128892733564],
  ["PS", 91.79411764705883],
  ["IQ", 86.7378892733564],
  ["HU", 77.60250865051903],
  ["KN", 78.40268166089966],
  ["KY", 85.0696366782007],
  ["FI", 89.48572664359861],
  ["ER", 77.62759515570934],
  ["GM", 90.49567474048443],
  ["EE", 72.93987889273356],
  ["EG", 85.19723183391004],
  ["ID", 92.40138408304497],
  ["IS", 87.87889273356402],
  ["SK", 91.47145328719724],
  ["RO", 83.98140138408304],
  ["PG", 78.22188581314879],
  ["KM", 87.58391003460207],
  ["IR", 86.02032871972318],
  ["KZ", 87.77897923875433],
  ["IE", 85.94204152249135],
  ["GN", 86.03416955017302],
  ["FJ", 83.91392733564014],
  ["GY", 60.21193771626297],
  ["JM", 0],
  ["KI", 81.64792387543253],
  ["HR", 83.95544982698962],
  ["SO", 91.9212802768166],
  ["PT", 87.70501730103805],
  ["SY", 88.75173010380622],
  ["SN", 88.5454152249135],
  ["KH", 88.07742214532873],
  ["EC", 76.66868512110727],
  ["ET", 76.29757785467129],
  ["DE", 81.00519031141869],
  ["SL", 77.32785467128028],
  ["PW", 77.93295847750865],
  ["SZ", 79.61894463667821],
  ["PA", 90.76470588235294],
  ["SM", 85.12586505190312],
  ["IT", 89.11548442906575],
  ["JO", 91.61937716262976],
  ["GH", 87.34645328719724],
  ["CG", 81.72923875432527],
  ["BT", 82.78287197231833],
  ["AO", 78.340830449827],
  ["ML", 81.62456747404845],
  ["TN", 90.08607266435986],
  ["LI", 90.44247404844292],
  ["MM", 83.22361591695501],
  ["MZ", 75.80493079584775],
  ["NA", 76.24956747404845],
  ["CF", 82.98096885813149],
  ["BB", 88.8135813148789],
  ["CD", 89.93382352941175],
  ["BW", 73.875],
  ["AL", 92.65051903114187],
  ["NC", 72.91046712802768],
  ["MX", 89.8628892733564],
  ["TZ", 79.875],
  ["VE", 82.12110726643598],
  ["MN", 92.39792387543253],
  ["MY", 82.16522491349481],
  ["AM", 90.83391003460207],
  ["CR", 90.0432525951557],
  ["BA", 79.66998269896195],
  ["AZ", 93.23313148788928],
  ["CV", 85.96064013840831],
  ["BR", 67.49480968858131],
  ["CA", 90.17863321799308],
  ["BE", 79.38970588235294],
  ["TH", 89.72491349480968],
  ["WS", 92.04108996539793],
  ["UZ", 76.3598615916955],
  ["Wales", 75.17387543252595],
  ["NG", 84.55233564013841],
  ["NP", 84.96756055363322],
  ["MK", 71.04671280276817],
  ["BD", 92.1492214532872],
  ["BS", 76.07871972318338],
  ["CU", 89.55622837370242],
  ["BF", 78.49740484429066],
  ["NR", 88.09126297577855],
  ["NE", 77.54671280276817],
  ["ZW", 78.67776816608996],
  ["VC", 83.61202422145328],
  ["UY", 84.17517301038062],
  ["TJ", 84.77811418685121],
  ["VU", 58.21756055363322],
  ["ZA", 82.69593425605535],
  ["BG", 93.0765570934256],
]);

export const kiMap = new Map<string, number>([
  ["CY", 74.87024221453287],
  ["AF", 84.84515570934256],
  ["CN", 48.20804498269896],
  ["BJ", 85.57482698961938],
  ["LA", 59.39662629757786],
  ["ME", 52.09818339100346],
  ["OM", 69.81920415224914],
  ["NI", 82.79282006920415],
  ["MR", 87.84861591695503],
  ["TG", 71.64965397923876],
  ["TP", 55.49956747404844],
  ["North Korea", 71.73442906574394],
  ["VN", 44.212802768166085],
  ["ZM", 84.20977508650519],
  ["MD", 69.68987889273356],
  ["CO", 81.81314878892734],
  ["AG", 73.034169550173],
  ["AE", 72.12846020761245],
  ["CZ", 84.58693771626298],
  ["AR", 87.35942906574394],
  ["BI", 69.47318339100346],
  ["CM", 77.91652249134948],
  ["LB", 57.67603806228374],
  ["LU", 58.37024221453287],
  ["TD", 67.90570934256056],
  ["TR", 46.423442906574394],
  ["Scotland", 77.85596885813149],
  ["UA", 86.12024221453287],
  ["LT", 88.87543252595155],
  ["LC", 92.18295847750865],
  ["MG", 83.73053633217992],
  ["CL", 87.26643598615918],
  ["BH", 58.00821799307958],
  ["AD", 69.75692041522491],
  ["CH", 83.37586505190312],
  ["NO", 81.67820069204153],
  ["MT", 60.6280276816609],
  ["MC", 33.60726643598616],
  ["TV", 79.24653979238754],
  ["TW", 85.70198961937716],
  ["US", 57.56833910034602],
  ["MU", 62.609861591695505],
  ["BZ", 63.379325259515575],
  ["CI", 86.8628892733564],
  ["BM", 61.52335640138409],
  ["CK", 57.6712802768166],
  ["AT", 54.70458477508651],
  ["BO", 55.75173010380623],
  ["LS", 81.95544982698962],
  ["NL", 83.50216262975778],
  ["MW", 73.35250865051903],
  ["YE", 55.121539792387544],
  ["UG", 85.27638408304499],
  ["TT", 55.08044982698962],
  ["NZ", 57.13797577854671],
  ["MA", 45.84904844290658],
  ["MV", 56.18468858131488],
  ["LR", 60.04584775086506],
  ["BY", 87.5272491349481],
  ["BN", 83.38192041522491],
  ["AU", 80.09126297577855],
  ["GE", 79.90311418685121],
  ["GR", 80.83520761245676],
  ["IN", 83.89792387543253],
  ["GBENG", 73.2106401384083],
  ["RS", 72.28157439446366],
  ["PL", 88.71496539792388],
  ["SV", 84.0112456747405],
  ["SA", 83.02984429065744],
  ["HK", 43.71453287197232],
  ["KG", 42.5272491349481],
  ["GD", 63.95371972318339],
  ["GQ", 81.57223183391004],
  ["DJ", 82.14532871972318],
  ["KE", 85.6401384083045],
  ["KR", 78.17171280276817],
  ["SC", 62.294982698961945],
  ["ST", 76.59818339100346],
  ["PY", 54.70328719723183],
  ["SB", 80.28589965397924],
  ["IL", 79.93685121107266],
  ["DK", 51.34256055363322],
  ["DO", 66.00735294117646],
  ["RU", 86.42474048442907],
  ["SG", 36.113321799307954],
  ["PK", 83.24134948096886],
  ["GB", 62.48918685121107],
  ["GU", 65.73918685121107],
  ["GW", 69.91695501730104],
  ["JP", 75.6098615916955],
  ["SR", 70.12586505190312],
  ["SE", 68.75692041522491],
  ["SD", 57.80795847750865],
  ["SS", 78.89100346020761],
  ["PH", 84.32612456747405],
  ["RW", 80.56401384083046],
  ["KU", 83.7067474048443],
  ["HN", 83.07309688581314],
  ["GA", 84.78762975778547],
  ["DZ", 81.66911764705883],
  ["DM", 84.98053633217994],
  ["FR", 61.71150519031142],
  ["GL", 78.44939446366782],
  ["ES", 59.01038062283737],
  ["HT", 86.54801038062284],
  ["PE", 50.95501730103806],
  ["QA", 83.63624567474048],
  ["SI", 83.62153979238755],
  ["PR", 80.90743944636678],
  ["PS", 77.52508650519032],
  ["IQ", 55.89359861591695],
  ["HU", 54.36937716262976],
  ["KN", 82.0220588235294],
  ["KY", 58.07093425605536],
  ["FI", 77.16565743944636],
  ["ER", 69.63884083044982],
  ["GM", 59.66955017301038],
  ["EE", 81.55017301038062],
  ["EG", 54.12846020761246],
  ["ID", 33.602941176470594],
  ["IS", 62.08347750865052],
  ["SK", 83.64446366782006],
  ["RO", 61.76643598615917],
  ["PG", 61.81444636678201],
  ["KM", 83.16349480968857],
  ["IR", 77.98010380622837],
  ["KZ", 87.72750865051903],
  ["IE", 86.98140138408304],
  ["GN", 67.87024221453287],
  ["FJ", 79.70025951557093],
  ["GY", 70.00389273356402],
  ["JM", 81.64792387543253],
  ["KI", 0],
  ["HR", 81.56012110726644],
  ["SO", 91.76600346020761],
  ["PT", 84.5],
  ["SY", 54.34515570934256],
  ["SN", 70.33131487889274],
  ["KH", 51.775086505190316],
  ["EC", 82.99956747404845],
  ["ET", 83.5544982698962],
  ["DE", 88.14662629757785],
  ["SL", 83.16652249134948],
  ["PW", 90.65960207612457],
  ["SZ", 70.8992214532872],
  ["PA", 51.28546712802768],
  ["SM", 92.25346020761246],
  ["IT", 67.63927335640139],
  ["JO", 74.84861591695503],
  ["GH", 57.471020761245676],
  ["CG", 76.03935986159169],
  ["BT", 83.56444636678201],
  ["AO", 46.58693771626298],
  ["ML", 69.7128027681661],
  ["TN", 44.0393598615917],
  ["LI", 86.3871107266436],
  ["MM", 86.74653979238755],
  ["MZ", 76.57396193771626],
  ["NA", 70.88321799307958],
  ["CF", 85.27984429065744],
  ["BB", 74.02119377162629],
  ["CD", 77.6431660899654],
  ["BW", 94.61461937716264],
  ["AL", 48.38192041522492],
  ["NC", 84.39532871972318],
  ["MX", 68.2984429065744],
  ["TZ", 88.42474048442907],
  ["VE", 84.01470588235294],
  ["MN", 49.779411764705884],
  ["MY", 60.589965397923876],
  ["AM", 59.29411764705882],
  ["CR", 61.47318339100346],
  ["BA", 66.36678200692042],
  ["AZ", 74.88062283737025],
  ["CV", 64.0674740484429],
  ["BR", 83.41695501730104],
  ["CA", 84.00821799307958],
  ["BE", 70.73615916955018],
  ["TH", 70.37240484429066],
  ["WS", 64.96064013840831],
  ["UZ", 82.90787197231833],
  ["Wales", 78.11807958477509],
  ["NG", 84.72448096885813],
  ["NP", 62.01081314878893],
  ["MK", 75.15354671280276],
  ["BD", 85.46756055363322],
  ["BS", 88.89489619377161],
  ["CU", 64.31877162629758],
  ["BF", 85.94204152249135],
  ["NR", 69.2227508650519],
  ["NE", 85.92733564013841],
  ["ZW", 77.45415224913495],
  ["VC", 85.49610726643598],
  ["UY", 79.83780276816609],
  ["TJ", 78.78287197231833],
  ["VU", 59.88451557093426],
  ["ZA", 75.26859861591696],
  ["BG", 89.63667820069205],
]);

export const hrMap = new Map<string, number>([
  ["CY", 69.8992214532872],
  ["AF", 88.84385813148789],
  ["CN", 90.29152249134948],
  ["BJ", 92.35164359861592],
  ["LA", 89.37673010380622],
  ["ME", 89.15830449826989],
  ["OM", 92.83996539792388],
  ["NI", 64.52032871972318],
  ["MR", 90.94852941176471],
  ["TG", 89.36764705882354],
  ["TP", 86.13148788927336],
  ["North Korea", 88.48442906574394],
  ["VN", 90.47491349480968],
  ["ZM", 88.0432525951557],
  ["MD", 93.46842560553632],
  ["CO", 91.03935986159169],
  ["AG", 92.02941176470588],
  ["AE", 72.5],
  ["CZ", 86.46712802768167],
  ["AR", 68.27897923875432],
  ["BI", 82.58737024221453],
  ["CM", 89.74956747404845],
  ["LB", 67.15051903114187],
  ["LU", 65.31920415224913],
  ["TD", 93.01297577854672],
  ["TR", 60.68209342560553],
  ["Scotland", 80.45761245674741],
  ["UA", 91.24480968858131],
  ["LT", 95.97664359861592],
  ["LC", 96.10207612456747],
  ["MG", 81.71237024221453],
  ["CL", 86.82915224913495],
  ["BH", 80.25951557093425],
  ["AD", 83.49264705882354],
  ["CH", 57.07915224913494],
  ["NO", 84.47404844290658],
  ["MT", 80.2318339100346],
  ["MC", 79.23918685121107],
  ["TV", 85.08217993079585],
  ["TW", 75.46496539792388],
  ["US", 76.65311418685121],
  ["MU", 91.24178200692042],
  ["BZ", 82.37629757785467],
  ["CI", 91.10294117647058],
  ["BM", 83.34212802768167],
  ["CK", 56.95588235294118],
  ["AT", 65.17128027681662],
  ["BO", 85.97750865051903],
  ["LS", 68.30320069204151],
  ["NL", 64.22188581314879],
  ["MW", 91.78589965397924],
  ["YE", 64.55017301038062],
  ["UG", 94.94117647058825],
  ["TT", 90.51773356401384],
  ["NZ", 55.82093425605537],
  ["MA", 91.86980968858131],
  ["MV", 90.73442906574395],
  ["LR", 81.15354671280276],
  ["BY", 87.86807958477509],
  ["BN", 80.09645328719724],
  ["AU", 53.32915224913495],
  ["GE", 76.92084775086505],
  ["GR", 77.54455017301038],
  ["IN", 66.89446366782008],
  ["GBENG", 84.10813148788927],
  ["RS", 87.15614186851212],
  ["PL", 82.3992214532872],
  ["SV", 64.30579584775087],
  ["SA", 88.57309688581316],
  ["HK", 89.1340830449827],
  ["KG", 88.47534602076125],
  ["GD", 86.48572664359861],
  ["GQ", 68.99134948096885],
  ["DJ", 83.34126297577855],
  ["KE", 91.98269896193771],
  ["KR", 72.17949826989619],
  ["SC", 79.5756920415225],
  ["ST", 86.09602076124567],
  ["PY", 64.38278546712802],
  ["SB", 83.62067474048442],
  ["IL", 66.625],
  ["DK", 80.05017301038062],
  ["DO", 77.14446366782008],
  ["RU", 96.54108996539792],
  ["SG", 75.68339100346022],
  ["PK", 83.68079584775087],
  ["GB", 59.45847750865052],
  ["GU", 66.22448096885813],
  ["GW", 93.16911764705881],
  ["JP", 73.29065743944638],
  ["SR", 93.60683391003461],
  ["SE", 91.06574394463668],
  ["SD", 70.82006920415225],
  ["SS", 90.15138408304499],
  ["PH", 80.17906574394463],
  ["RW", 87.48010380622837],
  ["KU", 69.93901384083046],
  ["HN", 64.91608996539793],
  ["GA", 87.27681660899654],
  ["DZ", 79.98313148788927],
  ["DM", 85.44420415224914],
  ["FR", 80.00951557093425],
  ["GL", 79.4143598615917],
  ["ES", 86.60942906574395],
  ["HT", 93.30363321799308],
  ["PE", 90.95242214532871],
  ["QA", 80.24956747404845],
  ["SI", 94.30968858131487],
  ["PR", 67.66911764705881],
  ["PS", 74.60034602076124],
  ["IQ", 66.39835640138408],
  ["HU", 64.23010380622839],
  ["KN", 88.59645328719724],
  ["KY", 57.720155709342556],
  ["FI", 91.63235294117648],
  ["ER", 83.49437716262976],
  ["GM", 83.73183391003461],
  ["EE", 95.53200692041523],
  ["EG", 65.02595155709344],
  ["ID", 79.2378892733564],
  ["IS", 83.98832179930797],
  ["SK", 92.57093425605535],
  ["RO", 82.35813148788928],
  ["PG", 87.15527681660899],
  ["KM", 84.73961937716264],
  ["IR", 59.89230103806228],
  ["KZ", 90.53892733564014],
  ["IE", 91.01038062283737],
  ["GN", 93.22361591695501],
  ["FJ", 86.23961937716264],
  ["GY", 84.28243944636678],
  ["JM", 83.95544982698962],
  ["KI", 81.56012110726644],
  ["HR", 0],
  ["SO", 92.00519031141869],
  ["PT", 73.1734429065744],
  ["SY", 66.38754325259517],
  ["SN", 93.59818339100346],
  ["KH", 64.34385813148789],
  ["EC", 89.02292387543253],
  ["ET", 83.81660899653978],
  ["DE", 96.58477508650519],
  ["SL", 64.63365051903114],
  ["PW", 93.06704152249135],
  ["SZ", 87.79887543252595],
  ["PA", 79.29584775086505],
  ["SM", 83.9204152249135],
  ["IT", 90.80709342560553],
  ["JO", 77.25692041522491],
  ["GH", 86.90138408304499],
  ["CG", 88.53589965397924],
  ["BT", 91.09818339100346],
  ["AO", 90.81444636678201],
  ["ML", 93.26557093425606],
  ["TN", 60.44982698961938],
  ["LI", 91.60813148788928],
  ["MM", 92.02811418685121],
  ["MZ", 89.35207612456747],
  ["NA", 84.4567474048443],
  ["CF", 78.28546712802769],
  ["BB", 73.04714532871972],
  ["CD", 88.77378892733564],
  ["BW", 89.7651384083045],
  ["AL", 93.92474048442907],
  ["NC", 87.46929065743944],
  ["MX", 91.43122837370242],
  ["TZ", 90.98442906574394],
  ["VE", 94.78979238754324],
  ["MN", 88.30190311418686],
  ["MY", 78.29974048442907],
  ["AM", 93.83996539792388],
  ["CR", 78.02162629757785],
  ["BA", 62.87456747404845],
  ["AZ", 93.06660899653978],
  ["CV", 84.28892733564014],
  ["BR", 89.03243944636678],
  ["CA", 71.25735294117646],
  ["BE", 93.54714532871972],
  ["TH", 96.52076124567473],
  ["WS", 90.68598615916954],
  ["UZ", 63.1522491349481],
  ["Wales", 79.08088235294117],
  ["NG", 91.00043252595155],
  ["NP", 75.375],
  ["MK", 80.65657439446366],
  ["BD", 91.2257785467128],
  ["BS", 86.44506920415225],
  ["CU", 66.87153979238755],
  ["BF", 92.76557093425606],
  ["NR", 62.628892733564015],
  ["NE", 66.51903114186851],
  ["ZW", 86.59558823529412],
  ["VC", 92.8598615916955],
  ["UY", 80.37975778546713],
  ["TJ", 65.01730103806229],
  ["VU", 85.11548442906575],
  ["ZA", 64.49221453287197],
  ["BG", 96.24048442906575],
]);

export const soMap = new Map<string, number>([
  ["CY", 93.44630567458772],
  ["AF", 94.9474094273471],
  ["CN", 94.62375016231658],
  ["BJ", 96.47383456693936],
  ["LA", 95.25191533567069],
  ["ME", 88.04408518374237],
  ["OM", 92.70776522529542],
  ["NI", 95.65316192702247],
  ["MR", 95.14965588884561],
  ["TG", 93.98876769250747],
  ["TP", 94.77178288533956],
  ["North Korea", 94.91267367874302],
  ["VN", 94.40851837423712],
  ["ZM", 93.60829762368523],
  ["MD", 95.49701337488638],
  ["CO", 97.84768211920529],
  ["AG", 93.39728606674458],
  ["AE", 94.79872743799507],
  ["CZ", 95.4648746915985],
  ["AR", 97.05362939877938],
  ["BI", 87.14939618231399],
  ["CM", 96.39105310998572],
  ["LB", 95.19542916504349],
  ["LU", 94.90618101545255],
  ["TD", 96.76373198285937],
  ["TR", 94.61693286586159],
  ["Scotland", 87.54415011037527],
  ["UA", 97.8152188027529],
  ["LT", 97.22341254382549],
  ["LC", 96.23522919101416],
  ["MG", 97.53928061290742],
  ["CL", 96.1024542267238],
  ["BH", 94.73867030255812],
  ["AD", 93.08855992728216],
  ["CH", 92.69607843137256],
  ["NO", 93.65601869887028],
  ["MT", 95.73659265030516],
  ["MC", 97.11888066484872],
  ["TV", 85.05746007012075],
  ["TW", 96.8896896506947],
  ["US", 84.59323464485132],
  ["MU", 97.62368523568368],
  ["BZ", 87.40131151798468],
  ["CI", 95.20451889365017],
  ["BM", 90.24055317491235],
  ["CK", 95.56258927412024],
  ["AT", 95.11394624074796],
  ["BO", 95.87780807687314],
  ["LS", 97.4474094273471],
  ["NL", 94.93474873393066],
  ["MW", 94.57927541877679],
  ["YE", 95.41975068172964],
  ["UG", 93.16095312297105],
  ["TT", 94.34489027399039],
  ["NZ", 94.84190364887677],
  ["MA", 98.14472146474483],
  ["MV", 95.42202311388131],
  ["LR", 95.49798727437995],
  ["BY", 90.519088430074],
  ["BN", 93.45312297104272],
  ["AU", 93.99850668744318],
  ["GE", 91.4608492403584],
  ["GR", 95.36326451110246],
  ["IN", 96.4663680041553],
  ["GBENG", 97.61037527593818],
  ["RS", 88.74691598493702],
  ["PL", 96.62413972211401],
  ["SV", 96.43390468770289],
  ["SA", 90.37982080249319],
  ["HK", 93.57226334242306],
  ["KG", 89.09946760161019],
  ["GD", 87.50876509544216],
  ["GQ", 92.60485651214128],
  ["DJ", 92.91682898324893],
  ["KE", 97.84248798857291],
  ["KR", 97.34190364887677],
  ["SC", 91.14498117127646],
  ["ST", 92.66815997922347],
  ["PY", 96.886767952214],
  ["SB", 86.91988053499546],
  ["IL", 96.2063368393715],
  ["DK", 95.54538371640047],
  ["DO", 96.25957667835347],
  ["RU", 98.97188676795221],
  ["SG", 92.97104272172444],
  ["PK", 97.88436566679653],
  ["GB", 88.53980002597065],
  ["GU", 95.3989741592001],
  ["GW", 94.98896247240619],
  ["JP", 96.72445136995196],
  ["SR", 96.43390468770289],
  ["SE", 96.01123230749253],
  ["SD", 92.17536683547591],
  ["SS", 92.64608492403585],
  ["PH", 97.08154785092844],
  ["RW", 91.3355408388521],
  ["KU", 93.69757174392936],
  ["HN", 94.49779249448123],
  ["GA", 63.82872354239709],
  ["DZ", 93.79755875860279],
  ["DM", 92.93468380729776],
  ["FR", 95.2249707830152],
  ["GL", 94.69549409167641],
  ["ES", 91.76665368134009],
  ["HT", 97.53278794961693],
  ["PE", 95.36034281262174],
  ["QA", 99.34196857550968],
  ["SI", 95.0970653161927],
  ["PR", 89.26340734969484],
  ["PS", 92.05720036358915],
  ["IQ", 94.6117387352292],
  ["HU", 95.31749123490457],
  ["KN", 94.1335540838852],
  ["KY", 92.11011556940657],
  ["FI", 99.37930138942994],
  ["ER", 57.988248279444235],
  ["GM", 98.22068562524348],
  ["EE", 66.52123100895989],
  ["EG", 96.47091286845864],
  ["ID", 97.2951564731853],
  ["IS", 95.79567588624855],
  ["SK", 93.62095831710168],
  ["RO", 96.79132580184393],
  ["PG", 93.21743929359823],
  ["KM", 68.24276068043112],
  ["IR", 87.06499155953772],
  ["KZ", 86.06090118166472],
  ["IE", 95.21328398909232],
  ["GN", 96.49688352162057],
  ["FJ", 87.71101155694066],
  ["GY", 90.45610959615634],
  ["JM", 91.62024412413973],
  ["KI", 91.06090118166472],
  ["HR", 92.44773406051162],
  ["SO", 0],
  ["PT", 91.81015452538631],
  ["SY", 94.04233216465394],
  ["SN", 96.70757044539671],
  ["KH", 93.15965459031294],
  ["EC", 93.69757174392936],
  ["ET", 88.12686664069601],
  ["DE", 97.50714192961954],
  ["SL", 95.11134917543176],
  ["PW", 94.35527853525517],
  ["SZ", 89.8464485131801],
  ["PA", 95.0525905726529],
  ["SM", 92.09713024282561],
  ["IT", 95.20873912478899],
  ["JO", 91.23912478898845],
  ["GH", 96.72964550058434],
  ["CG", 93.106414751331],
  ["BT", 89.21308920919361],
  ["AO", 96.75496688741721],
  ["ML", 96.3504739644202],
  ["TN", 93.8693026879626],
  ["LI", 97.7249707830152],
  ["MM", 91.87313335930398],
  ["MZ", 93.35346058953384],
  ["NA", 91.88709258537853],
  ["CF", 96.47870406440721],
  ["BB", 97.8879366316063],
  ["CD", 92.16919880534995],
  ["BW", 97.50097389949357],
  ["AL", 92.57466562784053],
  ["NC", 94.95195429165044],
  ["MX", 96.07064017660043],
  ["TZ", 94.08453447604207],
  ["VE", 98.25931697182185],
  ["MN", 92.52532138683289],
  ["MY", 91.48941695883651],
  ["AM", 99.04785092845086],
  ["CR", 94.98409297493832],
  ["BA", 97.02343851447864],
  ["AZ", 95.41455655109726],
  ["CV", 96.8020386962732],
  ["BR", 94.26925074665627],
  ["CA", 93.6238800155824],
  ["BE", 96.7384105960265],
  ["TH", 99.1806258927412],
  ["WS", 98.03532008830022],
  ["UZ", 90.86579664978575],
  ["Wales", 82.81164783794313],
  ["NG", 95.36034281262174],
  ["NP", 89.74776003116477],
  ["MK", 81.26834177379561],
  ["BD", 92.44513699519543],
  ["BS", 94.29749383196987],
  ["CU", 93.77028957278274],
  ["BF", 96.37514608492404],
  ["NR", 98.91897156213479],
  ["NE", 97.3652772367225],
  ["ZW", 93.43818984547461],
  ["VC", 95.45383716400467],
  ["UY", 94.73120373977405],
  ["TJ", 94.69614335800546],
  ["VU", 94.83313855343461],
  ["ZA", 90.11751720555772],
  ["BG", 97.63309959745487],
]);

export const ptMap = new Map<string, number>([
  ["CY", 91.63643430940658],
  ["AF", 88.54819901528893],
  ["CN", 88.38526820419797],
  ["BJ", 91.50427571909822],
  ["LA", 90.79521896864473],
  ["ME", 85.78744493392071],
  ["OM", 83.47207825861622],
  ["NI", 92.52688520342058],
  ["MR", 89.91027468256026],
  ["TG", 90.35047939880798],
  ["TP", 87.19972790878467],
  ["North Korea", 90.63941435605079],
  ["VN", 90.08097952837522],
  ["ZM", 86.25809795283752],
  ["MD", 91.33810572687224],
  ["CO", 92.22596527597823],
  ["AG", 88.55921223114797],
  ["AE", 92.74293858512567],
  ["CZ", 90.65528634361235],
  ["AR", 94.57793469810832],
  ["BI", 86.46087069188908],
  ["CM", 91.04366416169992],
  ["LB", 91.8576703809277],
  ["LU", 92.99073594195387],
  ["TD", 93.02604301632547],
  ["TR", 38.959251101321584],
  ["Scotland", 86.86674008810573],
  ["UA", 92.57741642912671],
  ["LT", 91.31186835967867],
  ["LC", 95.55940658201607],
  ["MG", 92.13202902306297],
  ["CL", 91.13792433272869],
  ["BH", 90.86777662606893],
  ["AD", 92.51911116869655],
  ["CH", 49.11084477844001],
  ["NO", 89.37062710546773],
  ["MT", 93.83583830007774],
  ["MC", 93.1092899714952],
  ["TV", 79.86201088364862],
  ["TW", 38.91390256543146],
  ["US", 84.06452448820939],
  ["MU", 92.0361492614667],
  ["BZ", 86.35592122311479],
  ["CI", 94.95173620108837],
  ["BM", 82.40120497538223],
  ["CK", 82.77792174138378],
  ["AT", 92.94506348795024],
  ["BO", 92.09801762114537],
  ["LS", 94.38941435605079],
  ["NL", 93.1083182171547],
  ["MW", 85.79230370562321],
  ["YE", 93.746760818865],
  ["UG", 88.90710028504793],
  ["TT", 90.16390256543147],
  ["NZ", 83.02669085255248],
  ["MA", 90.76282715729464],
  ["MV", 89.72985229334024],
  ["LR", 91.07929515418502],
  ["BY", 85.51179061933144],
  ["BN", 91.1414874319772],
  ["AU", 81.50621922777923],
  ["GE", 72.48963462036797],
  ["GR", 91.31316403213268],
  ["IN", 93.4957242809018],
  ["GBENG", 93.44648872764965],
  ["RS", 88.12419020471624],
  ["PL", 92.95510494946878],
  ["SV", 93.0331692148225],
  ["SA", 87.80448302669085],
  ["HK", 90.16228297486396],
  ["KG", 87.1420704845815],
  ["GD", 84.47752008292304],
  ["GQ", 89.65535112723504],
  ["DJ", 92.18385592122311],
  ["KE", 77.9282197460482],
  ["KR", 90.21605338170511],
  ["SC", 85.05603783363566],
  ["ST", 90.15807203938844],
  ["PY", 92.43554029541332],
  ["SB", 83.71177766260689],
  ["IL", 94.04703291008033],
  ["DK", 93.2479269240736],
  ["DO", 92.34095620627104],
  ["RU", 91.8955688002073],
  ["SG", 89.89893754858772],
  ["PK", 90.46806167400881],
  ["GB", 79.58830007774034],
  ["GU", 87.63183467219487],
  ["GW", 89.27345167141748],
  ["JP", 91.24319771961648],
  ["SR", 92.0393884426017],
  ["SE", 92.38338947913968],
  ["SD", 90.0728815755377],
  ["SS", 87.12263539777145],
  ["PH", 89.80176211453744],
  ["RW", 87.66260689297745],
  ["KU", 89.3213915522156],
  ["HN", 92.74099507644468],
  ["GA", 91.90042757190983],
  ["DZ", 90.3265094584089],
  ["DM", 87.81841150557139],
  ["FR", 93.90936771184245],
  ["GL", 90.66921482249288],
  ["ES", 92.00343353200311],
  ["HT", 90.73820938066856],
  ["PE", 93.51483544959834],
  ["QA", 90.79651464109874],
  ["SI", 89.90703550142524],
  ["PR", 56.80649131899456],
  ["PS", 89.87853070743716],
  ["IQ", 93.06653278051309],
  ["HU", 92.86829489505052],
  ["KN", 90.0693184762892],
  ["KY", 82.47667789582793],
  ["FI", 92.33026690852553],
  ["ER", 77.34613889608707],
  ["GM", 92.44590567504535],
  ["EE", 96.24416947395699],
  ["EG", 93.85073853329878],
  ["ID", 93.2495465146411],
  ["IS", 90.65917336097435],
  ["SK", 90.48944026949987],
  ["RO", 93.12548587717025],
  ["PG", 88.01956465405546],
  ["KM", 86.34328841668825],
  ["IR", 87.01282715729464],
  ["KZ", 88.22557657424203],
  ["IE", 93.70659497279088],
  ["GN", 92.86959056750453],
  ["FJ", 85.18949209639803],
  ["GY", 85.6073464628142],
  ["JM", 87.77727390515678],
  ["KI", 84.0042757190982],
  ["HR", 73.03964757709251],
  ["SO", 92.16895568800207],
  ["PT", 0],
  ["SY", 92.7837522674268],
  ["SN", 92.4271184244623],
  ["KH", 88.70238403731537],
  ["EC", 89.79236848924592],
  ["ET", 86.01030059600933],
  ["DE", 91.04010106245141],
  ["SL", 92.72965794247214],
  ["PW", 94.44188909043794],
  ["SZ", 85.06575537704069],
  ["PA", 91.15930292821974],
  ["SM", 92.29690334283494],
  ["IT", 93.57637989116353],
  ["JO", 89.2151464109873],
  ["GH", 92.6402565431459],
  ["CG", 89.54521896864472],
  ["BT", 90.91733609743457],
  ["AO", 91.66526302150815],
  ["ML", 92.66195905675045],
  ["TN", 43.833246955169734],
  ["LI", 88.83681005441825],
  ["MM", 91.34004923555325],
  ["MZ", 88.91908525524748],
  ["NA", 83.26574242031614],
  ["CF", 89.52027727390515],
  ["BB", 93.89673490541591],
  ["CD", 87.00699663125162],
  ["BW", 95.71585903083701],
  ["AL", 88.51321585903084],
  ["NC", 88.80506607929516],
  ["MX", 92.72382741642913],
  ["TZ", 90.27403472402177],
  ["VE", 90.86453744493393],
  ["MN", 86.65101062451413],
  ["MY", 84.20834413060379],
  ["AM", 91.64647577092511],
  ["CR", 92.0691889090438],
  ["BA", 87.29431199792693],
  ["AZ", 89.4279606115574],
  ["CV", 90.39841927960612],
  ["BR", 86.48386887794766],
  ["CA", 62.50874578906453],
  ["BE", 93.64796579424721],
  ["TH", 93.34639803057787],
  ["WS", 89.20218968644727],
  ["UZ", 89.27928219746049],
  ["Wales", 75.85093288416688],
  ["NG", 93.61168696553511],
  ["NP", 81.61181653278051],
  ["MK", 79.4655351127235],
  ["BD", 86.31607929515418],
  ["BS", 89.63623995853848],
  ["CU", 88.9990930292822],
  ["BF", 90.43210676341022],
  ["NR", 90.26204975382224],
  ["NE", 91.97266131122052],
  ["ZW", 88.50868100544183],
  ["VC", 92.48801502980047],
  ["UY", 91.4634620367971],
  ["TJ", 82.58065561026172],
  ["VU", 84.16072816791915],
  ["ZA", 87.40671158331173],
  ["BG", 91.36207566727131],
]);

export const syMap = new Map<string, number>([
  ["CY", 73.99948058693676],
  ["AF", 82.10686923776133],
  ["CN", 66.79619529931178],
  ["BJ", 96.34820153226855],
  ["LA", 64.47863913777432],
  ["ME", 71.86761459550708],
  ["OM", 91.56310868718349],
  ["NI", 68.91085573302168],
  ["MR", 96.26574470847942],
  ["TG", 85.02532138683287],
  ["TP", 63.86183612517855],
  ["North Korea", 90.82651603687833],
  ["VN", 63.97967796390079],
  ["ZM", 91.44039735099338],
  ["MD", 83.96734190364887],
  ["CO", 95.72458122321777],
  ["AG", 89.63446305674587],
  ["AE", 43.1119335151279],
  ["CZ", 88.1482924295546],
  ["AR", 70.73302168549539],
  ["BI", 71.50954421503701],
  ["CM", 85.38241786780938],
  ["LB", 49.1387482145176],
  ["LU", 36.02162056875731],
  ["TD", 85.91351772497077],
  ["TR", 62.61784183872224],
  ["Scotland", 82.99506557589923],
  ["UA", 97.61134917543176],
  ["LT", 98.09635112323075],
  ["LC", 96.89066355018828],
  ["MG", 88.52551616673159],
  ["CL", 87.3652772367225],
  ["BH", 67.15848591092066],
  ["AD", 84.72146474483834],
  ["CH", 86.26801714063109],
  ["NO", 85.4729905207116],
  ["MT", 66.2222438644332],
  ["MC", 50.19964939618231],
  ["TV", 83.7277626282301],
  ["TW", 94.91072587975587],
  ["US", 70.18309310479158],
  ["MU", 72.28444357875601],
  ["BZ", 78.19568887157511],
  ["CI", 87.97331515387611],
  ["BM", 69.53350214257888],
  ["CK", 82.30262303596936],
  ["AT", 35.80087001688093],
  ["BO", 61.959161147902876],
  ["LS", 71.2965848591092],
  ["NL", 67.5889494870796],
  ["MW", 92.33184002077653],
  ["YE", 1.8052850279184522],
  ["UG", 95.02272432151668],
  ["TT", 65.55934294247501],
  ["NZ", 84.31599792234775],
  ["MA", 65.12303596935463],
  ["MV", 68.29307882093235],
  ["LR", 72.78957278275549],
  ["BY", 94.44585118815738],
  ["BN", 78.32294507206856],
  ["AU", 88.16127775613558],
  ["GE", 85.35125308401507],
  ["GR", 78.81281651733542],
  ["IN", 70.22042591871185],
  ["GBENG", 83.03109985716141],
  ["RS", 91.83872224386444],
  ["PL", 83.60310349305286],
  ["SV", 69.80554473445008],
  ["SA", 92.0997273081418],
  ["HK", 59.6912738605376],
  ["KG", 61.49526035579795],
  ["GD", 76.97701597195169],
  ["GQ", 75.01688092455525],
  ["DJ", 85.16491364757823],
  ["KE", 92.72789248149591],
  ["KR", 73.81411504999352],
  ["SC", 72.86034281262174],
  ["ST", 90.09414361771199],
  ["PY", 36.94650045448643],
  ["SB", 90.49636410855733],
  ["IL", 71.86469289702636],
  ["DK", 55.342487988572906],
  ["DO", 65.36261524477341],
  ["RU", 98.54272172445137],
  ["SG", 51.98902739903909],
  ["PK", 85.60479158550838],
  ["GB", 72.62952863264512],
  ["GU", 89.1092065965459],
  ["GW", 85.69828593689131],
  ["JP", 75.89371510193482],
  ["SR", 94.36891312816518],
  ["SE", 96.78385923905986],
  ["SD", 16.444942215296717],
  ["SS", 94.02447734060512],
  ["PH", 87.87495130502532],
  ["RW", 94.55914816257629],
  ["KU", 71.55207115958966],
  ["HN", 69.14589014413713],
  ["GA", 94.80002597065315],
  ["DZ", 82.67497727567849],
  ["DM", 90.32333463186599],
  ["FR", 76.89520841449162],
  ["GL", 79.05272042591871],
  ["ES", 69.25496688741721],
  ["HT", 96.36021295935593],
  ["PE", 65.61615374626672],
  ["QA", 86.90592130892092],
  ["SI", 96.3611868588495],
  ["PR", 85.97649655888846],
  ["PS", 72.73178807947019],
  ["IQ", 4.110180496039476],
  ["HU", 35.13861836125179],
  ["KN", 84.50038955979743],
  ["KY", 84.52376314764317],
  ["FI", 93.81086871834826],
  ["ER", 83.6670562264641],
  ["GM", 54.63738475522659],
  ["EE", 99.77924944812362],
  ["EG", 3.5125308401506303],
  ["ID", 50.232437345799255],
  ["IS", 85.30288274250097],
  ["SK", 95.56680950525906],
  ["RO", 85.9313725490196],
  ["PG", 44.056291390728475],
  ["KM", 85.7654850019478],
  ["IR", 65.58044409816907],
  ["KZ", 93.94981171276457],
  ["IE", 87.99896117387352],
  ["GN", 85.9378652123101],
  ["FJ", 83.665757693806],
  ["GY", 85.44701986754967],
  ["JM", 88.66121282950267],
  ["KI", 54.236462797039344],
  ["HR", 66.48844305934294],
  ["SO", 94.04233216465394],
  ["PT", 92.95448643033373],
  ["SY", 0],
  ["SN", 84.615958966368],
  ["KH", 84.04395533047656],
  ["EC", 92.8489806518634],
  ["ET", 91.29463706012206],
  ["DE", 98.73490455784963],
  ["SL", 68.25120114270874],
  ["PW", 97.29353330736268],
  ["SZ", 84.65588884560447],
  ["PA", 66.23522919101416],
  ["SM", 85.26457602908714],
  ["IT", 76.35404492922997],
  ["JO", 74.54356577067914],
  ["GH", 60.71127126347228],
  ["CG", 91.67997662641216],
  ["BT", 91.52642513959226],
  ["AO", 32.311388131411505],
  ["ML", 85.95474613686534],
  ["TN", 60.10972600960914],
  ["LI", 96.26444617582132],
  ["MM", 91.46247240618102],
  ["MZ", 90.23568367744448],
  ["NA", 83.37943124269576],
  ["CF", 79.40462277626283],
  ["BB", 95.2373068432671],
  ["CD", 89.20042851577718],
  ["BW", 91.41020646669263],
  ["AL", 67.20977795091547],
  ["NC", 93.46188806648487],
  ["MX", 80.1766004415011],
  ["TZ", 85.9271523178808],
  ["VE", 97.03804700688222],
  ["MN", 75.31619270224647],
  ["MY", 76.36638098948188],
  ["AM", 65.43435917413322],
  ["CR", 96.96954940916764],
  ["BA", 89.64387741851706],
  ["AZ", 96.48811842617842],
  ["CV", 89.63998182054279],
  ["BR", 92.03350214257888],
  ["CA", 93.14342293208674],
  ["BE", 75.91708868978054],
  ["TH", 82.89183222958057],
  ["WS", 79.17867809375406],
  ["UZ", 65.20322036099208],
  ["Wales", 82.70127256200493],
  ["NG", 87.22925594078691],
  ["NP", 72.84183872224386],
  ["MK", 86.37806778340476],
  ["BD", 96.08979353330737],
  ["BS", 90.36910790806388],
  ["CU", 82.12245162965849],
  ["BF", 96.79002726918581],
  ["NR", 96.29983119075445],
  ["NE", 73.67062719127387],
  ["ZW", 88.76574470847942],
  ["VC", 94.3085313595637],
  ["UY", 85.72198415790157],
  ["TJ", 68.2021815348656],
  ["VU", 64.14653941046618],
  ["ZA", 87.76717309440333],
  ["BG", 98.43624204648746],
]);

export const snMap = new Map<string, number>([
  ["CY", 92.68146993896896],
  ["AF", 95.31067393844955],
  ["CN", 62.80028567718479],
  ["BJ", 48.58167770419426],
  ["LA", 79.11667315932995],
  ["ME", 66.5416828983249],
  ["OM", 83.45312297104273],
  ["NI", 94.51045318789768],
  ["MR", 94.81041423191793],
  ["TG", 87.60128554733151],
  ["TP", 62.015647318530064],
  ["North Korea", 73.24892871055707],
  ["VN", 62.69348136605636],
  ["ZM", 88.29275418776781],
  ["MD", 61.70140241527074],
  ["CO", 89.22347747045838],
  ["AG", 69.29976626412154],
  ["AE", 94.06797818465135],
  ["CZ", 78.95273341124529],
  ["AR", 94.91494611089469],
  ["BI", 82.60842747695104],
  ["CM", 98.67322425659005],
  ["LB", 76.03006103103493],
  ["LU", 86.00084404622777],
  ["TD", 64.83930658356057],
  ["TR", 62.277626282301],
  ["Scotland", 89.72665887547072],
  ["UA", 97.70322036099208],
  ["LT", 85.6077132839891],
  ["LC", 92.51655629139073],
  ["MG", 97.43896896506948],
  ["CL", 79.27509414361771],
  ["BH", 62.94117647058823],
  ["AD", 59.59518244383847],
  ["CH", 94.84255291520581],
  ["NO", 68.46188806648487],
  ["MT", 64.08550837553565],
  ["MC", 81.25438254772106],
  ["TV", 84.15790157122451],
  ["TW", 96.82054278665109],
  ["US", 81.37027658745617],
  ["MU", 88.19049474094274],
  ["BZ", 80.7924295546033],
  ["CI", 94.76886118685886],
  ["BM", 66.74977275678484],
  ["CK", 88.03564472146475],
  ["AT", 74.41468640436307],
  ["BO", 75.54343591741332],
  ["LS", 86.56278405401896],
  ["NL", 96.22678872873652],
  ["MW", 86.2426957537982],
  ["YE", 86.58291131021946],
  ["UG", 94.79710427217245],
  ["TT", 69.93767043241137],
  ["NZ", 88.02720425918712],
  ["MA", 96.52123100895987],
  ["MV", 63.427801584209845],
  ["LR", 88.9757823659265],
  ["BY", 69.97045838202831],
  ["BN", 93.44727957408129],
  ["AU", 92.96649785742112],
  ["GE", 90.57784703285287],
  ["GR", 91.52512660693417],
  ["IN", 94.97630177898975],
  ["GBENG", 89.57343202181535],
  ["RS", 79.42150370081808],
  ["PL", 81.69750681729646],
  ["SV", 94.28061290741462],
  ["SA", 60.359368913128165],
  ["HK", 59.031294637060114],
  ["KG", 57.89248149590962],
  ["GD", 75.28210621997144],
  ["GQ", 81.02973639787041],
  ["DJ", 95.41325801843917],
  ["KE", 94.23094403324244],
  ["KR", 95.56096610829763],
  ["SC", 66.89293598233995],
  ["ST", 93.83748863783924],
  ["PY", 84.91851707570446],
  ["SB", 88.02785352551616],
  ["IL", 94.26567978184652],
  ["DK", 69.21795870666148],
  ["DO", 81.2063368393715],
  ["RU", 86.24983768341774],
  ["SG", 77.48798857291261],
  ["PK", 95.55577197766524],
  ["GB", 72.87884690299961],
  ["GU", 90.54538371640047],
  ["GW", 95.61031034930528],
  ["JP", 96.17452278924816],
  ["SR", 95.70283080119465],
  ["SE", 97.11920529801324],
  ["SD", 68.55992728217115],
  ["SS", 76.32872354239709],
  ["PH", 78.834242306194],
  ["RW", 92.93793013894299],
  ["KU", 78.21808855992728],
  ["HN", 94.3491105051292],
  ["GA", 96.43585248669004],
  ["DZ", 94.32898324892871],
  ["DM", 70.3989741592001],
  ["FR", 61.9838332684067],
  ["GL", 78.60472665887546],
  ["ES", 77.38702765874562],
  ["HT", 79.9824698091157],
  ["PE", 61.9838332684067],
  ["QA", 96.7413322945072],
  ["SI", 84.21341384235814],
  ["PR", 88.7761329697442],
  ["PS", 87.93598233995586],
  ["IQ", 83.91864692897026],
  ["HU", 74.94968185949877],
  ["KN", 56.48746915984937],
  ["KY", 87.29061160888196],
  ["FI", 97.33346318659915],
  ["ER", 79.831515387612],
  ["GM", 84.9474094273471],
  ["EE", 99.18517075704455],
  ["EG", 84.79450720685625],
  ["ID", 81.49493572263341],
  ["IS", 90.87099078041813],
  ["SK", 82.65549928580704],
  ["RO", 64.40592130892092],
  ["PG", 72.97396442020518],
  ["KM", 81.93319049474094],
  ["IR", 88.09602649006622],
  ["KZ", 91.01837423711207],
  ["IE", 61.83222958057395],
  ["GN", 97.19646799116998],
  ["FJ", 85.75899233865731],
  ["GY", 83.92546422542527],
  ["JM", 88.4313725490196],
  ["KI", 69.91819244253993],
  ["HR", 94.09751980262303],
  ["SO", 96.70757044539671],
  ["PT", 92.49123490455786],
  ["SY", 84.615958966368],
  ["SN", 0],
  ["KH", 77.64381249188416],
  ["EC", 84.94903259316972],
  ["ET", 73.95955070770029],
  ["DE", 97.5727178288534],
  ["SL", 96.46734190364887],
  ["PW", 94.99091027139332],
  ["SZ", 88.106414751331],
  ["PA", 78.65179846773145],
  ["SM", 92.65030515517465],
  ["IT", 29.18939098818335],
  ["JO", 88.93260615504481],
  ["GH", 75.35547331515387],
  ["CG", 35.45546032982729],
  ["BT", 88.28269055966757],
  ["AO", 79.18452149071548],
  ["ML", 64.25951175172055],
  ["TN", 60.150629788339174],
  ["LI", 79.29879236462797],
  ["MM", 82.49123490455786],
  ["MZ", 92.82528243085314],
  ["NA", 79.39001428385923],
  ["CF", 95.26782236073237],
  ["BB", 96.25957667835347],
  ["CD", 86.69198805349954],
  ["BW", 97.80483054148812],
  ["AL", 65.6145305804441],
  ["NC", 84.51240098688481],
  ["MX", 25.689845474613687],
  ["TZ", 93.28626152447734],
  ["VE", 84.69354629268926],
  ["MN", 61.56895208414491],
  ["MY", 72.42533437215945],
  ["AM", 86.78937800285678],
  ["CR", 82.30067523698222],
  ["BA", 91.1670562264641],
  ["AZ", 85.18179457213348],
  ["CV", 90.55349954551357],
  ["BR", 65.16296584859109],
  ["CA", 92.84216335540839],
  ["BE", 35.46195299311778],
  ["TH", 85.36423841059603],
  ["WS", 62.46558888456044],
  ["UZ", 92.64154005973249],
  ["Wales", 87.22081547850928],
  ["NG", 61.68582002337358],
  ["NP", 85.77002986625114],
  ["MK", 83.56252434748734],
  ["BD", 67.4272821711466],
  ["BS", 88.91280353200884],
  ["CU", 90.37917153616414],
  ["BF", 65.85410985586287],
  ["NR", 95.26165433060642],
  ["NE", 95.38079470198676],
  ["ZW", 85.73009998701467],
  ["VC", 94.92241267367875],
  ["UY", 91.62024412413973],
  ["TJ", 91.1871834826646],
  ["VU", 78.01389429944163],
  ["ZA", 67.82625633034671],
  ["BG", 86.26801714063109],
]);

export const khMap = new Map<string, number>([
  ["CY", 91.06964164976335],
  ["AF", 88.95571331981068],
  ["CN", 90.5895875591616],
  ["BJ", 85.86240703177823],
  ["LA", 91.53853955375254],
  ["ME", 60.32724814063557],
  ["OM", 65.58384043272481],
  ["NI", 92.26876267748479],
  ["MR", 91.76436781609195],
  ["TG", 83.54496281271129],
  ["TP", 90.03617308992563],
  ["North Korea", 58.15415821501014],
  ["VN", 91.88607167004733],
  ["ZM", 89.36646382691006],
  ["MD", 60.19675456389453],
  ["CO", 95.5],
  ["AG", 63.28093306288033],
  ["AE", 80.43340094658554],
  ["CZ", 84.2055442866802],
  ["AR", 94.7423935091278],
  ["BI", 86.80358350236646],
  ["CM", 88.60716700473293],
  ["LB", 92.8843813387424],
  ["LU", 92.5155510480054],
  ["TD", 77.40703177822853],
  ["TR", 61.014536849222445],
  ["Scotland", 86.04563894523326],
  ["UA", 93.39553752535497],
  ["LT", 94.12136578769439],
  ["LC", 96.44658553076403],
  ["MG", 94.22177146720757],
  ["CL", 86.67072346179852],
  ["BH", 71.62339418526031],
  ["AD", 62.08722109533469],
  ["CH", 89.79344151453685],
  ["NO", 90.51893171061528],
  ["MT", 75.00642325895875],
  ["MC", 71.03211629479378],
  ["TV", 85.81541582150102],
  ["TW", 88.6291413116971],
  ["US", 84.35395537525355],
  ["MU", 93.44252873563218],
  ["BZ", 83.32657200811359],
  ["CI", 92.43813387423934],
  ["BM", 66.34584178498986],
  ["CK", 82.09803921568627],
  ["AT", 92.32691007437458],
  ["BO", 93.1866125760649],
  ["LS", 68.85125084516565],
  ["NL", 68.2447599729547],
  ["MW", 62.31575388776201],
  ["YE", 85.34617985125085],
  ["UG", 90.18897903989182],
  ["TT", 62.38640973630831],
  ["NZ", 82.32319134550372],
  ["MA", 93.72785665990534],
  ["MV", 76.30831643002028],
  ["LR", 87.99256254225828],
  ["BY", 88.25321162947938],
  ["BN", 93.9263015551048],
  ["AU", 83.8918187964841],
  ["GE", 88.19100743745774],
  ["GR", 91.79006085192698],
  ["IN", 93.4367816091954],
  ["GBENG", 79.42055442866803],
  ["RS", 88.30561189993239],
  ["PL", 79.54969574036511],
  ["SV", 43.72785665990534],
  ["SA", 89.21162947937796],
  ["HK", 90.61494252873563],
  ["KG", 56.28972278566599],
  ["GD", 77.58012170385396],
  ["GQ", 89.28532792427316],
  ["DJ", 92.94962812711292],
  ["KE", 89.9448951994591],
  ["KR", 91.07606490872212],
  ["SC", 78.81473968897905],
  ["ST", 83.04428668018932],
  ["PY", 60.228870858688296],
  ["SB", 84.49628127112913],
  ["IL", 63.07505070993915],
  ["DK", 68.65956727518594],
  ["DO", 82.81102096010818],
  ["RU", 94.58586883029074],
  ["SG", 87.35158891142663],
  ["PK", 91.80933062880324],
  ["GB", 65.07707910750507],
  ["GU", 88.7210953346856],
  ["GW", 80.0868830290737],
  ["JP", 93.61629479377957],
  ["SR", 94.28329952670722],
  ["SE", 92.31000676132523],
  ["SD", 83.79682217714672],
  ["SS", 72.44590939824207],
  ["PH", 62.724137931034484],
  ["RW", 91.46382691007437],
  ["KU", 86.32319134550372],
  ["HN", 92.33637592968222],
  ["GA", 92.86037863421231],
  ["DZ", 91.1707234617985],
  ["DM", 89.85936443542934],
  ["FR", 75.32454361054766],
  ["GL", 72.64401622718053],
  ["ES", 91.82454361054766],
  ["HT", 53.556795131845846],
  ["PE", 60.476335361730904],
  ["QA", 92.80189317106154],
  ["SI", 86.47836375929681],
  ["PR", 85.54462474645031],
  ["PS", 77.64367816091954],
  ["IQ", 85.23225152129818],
  ["HU", 85.0682893847194],
  ["KN", 80.64570655848546],
  ["KY", 82.05138607167005],
  ["FI", 93.78025693035835],
  ["ER", 67.71771467207573],
  ["GM", 85.7315753887762],
  ["EE", 97.32048681541582],
  ["EG", 85.54530087897227],
  ["ID", 71.33333333333334],
  ["IS", 50.89722785665991],
  ["SK", 83.3211629479378],
  ["RO", 77.11663286004057],
  ["PG", 76.94658553076404],
  ["KM", 80.55375253549695],
  ["IR", 85.62981744421907],
  ["KZ", 91.23630831643001],
  ["IE", 92.72954699121027],
  ["GN", 77.39384719405004],
  ["FJ", 83.2762001352265],
  ["GY", 72.22177146720757],
  ["JM", 88.42765382014875],
  ["KI", 51.852941176470594],
  ["HR", 64.41244083840432],
  ["SO", 93.32792427315754],
  ["PT", 88.95368492224476],
  ["SY", 84.05138607167004],
  ["SN", 77.61899932386747],
  ["KH", 0],
  ["EC", 91.7944557133198],
  ["ET", 82.54901960784314],
  ["DE", 93.36308316430019],
  ["SL", 92.93847194050034],
  ["PW", 95.51014198782961],
  ["SZ", 85.04665314401623],
  ["PA", 81.52704530087898],
  ["SM", 94.43306288032454],
  ["IT", 91.64705882352942],
  ["JO", 74.54665314401623],
  ["GH", 86.28870858688303],
  ["CG", 90.16058147396889],
  ["BT", 91.13083164300203],
  ["AO", 73.9317106152806],
  ["ML", 78.02231237322516],
  ["TN", 57.99797160243407],
  ["LI", 75.45909398242055],
  ["MM", 90.21298174442191],
  ["MZ", 82.93542934415146],
  ["NA", 79.05375253549695],
  ["CF", 73.37694388100068],
  ["BB", 92.47464503042596],
  ["CD", 85.47261663286004],
  ["BW", 97.37525354969574],
  ["AL", 60.54868154158215],
  ["NC", 66.50439486139284],
  ["MX", 76.29716024340772],
  ["TZ", 92.41987829614604],
  ["VE", 88.79107505070995],
  ["MN", 89.15889114266396],
  ["MY", 74.74104124408385],
  ["AM", 87.34212305611901],
  ["CR", 71.61697092630156],
  ["BA", 58.05780933062881],
  ["AZ", 70.59567275185937],
  ["CV", 41.68627450980392],
  ["BR", 89.84956051386071],
  ["CA", 90.8603786342123],
  ["BE", 93.48242055442867],
  ["TH", 95.20453008789723],
  ["WS", 64.98039215686275],
  ["UZ", 88.60987153482083],
  ["Wales", 76.10818120351588],
  ["NG", 91.93779580797836],
  ["NP", 68.63353617308992],
  ["MK", 80.28296146044624],
  ["BD", 89.04394861392832],
  ["BS", 92.5922920892495],
  ["CU", 42.34110885733604],
  ["BF", 92.4787018255578],
  ["NR", 89.67207572684246],
  ["NE", 93.44624746450305],
  ["ZW", 90.2447599729547],
  ["VC", 92.41210277214334],
  ["UY", 74.41548343475321],
  ["TJ", 90.61697092630155],
  ["VU", 80.20993914807302],
  ["ZA", 72.78025693035836],
  ["BG", 93.9050033806626],
]);

export const ecMap = new Map<string, number>([
  ["CY", 90.27204259187118],
  ["AF", 90.93494351382937],
  ["CN", 65.15939488378133],
  ["BJ", 52.40975198026231],
  ["LA", 70.75639527334113],
  ["ME", 89.12900921958186],
  ["OM", 82.94312426957538],
  ["NI", 90.79567588624855],
  ["MR", 91.05765485001947],
  ["TG", 82.32145175951176],
  ["TP", 67.67335410985586],
  ["North Korea", 82.2438644331905],
  ["VN", 69.16536813400857],
  ["ZM", 86.65692767173094],
  ["MD", 74.34002077652254],
  ["CO", 5.169458511881573],
  ["AG", 68.7790546682249],
  ["AE", 87.72724321516687],
  ["CZ", 68.28009349435138],
  ["AR", 93.67322425659005],
  ["BI", 73.91734839631216],
  ["CM", 68.14861706271913],
  ["LB", 64.78152188027528],
  ["LU", 91.98545643422932],
  ["TD", 82.37631476431633],
  ["TR", 66.53681340085703],
  ["Scotland", 75.384041033632],
  ["UA", 95.37267887287365],
  ["LT", 91.59492273730685],
  ["LC", 94.07576938059992],
  ["MG", 91.90267497727568],
  ["CL", 65.77749642903518],
  ["BH", 73.8611868588495],
  ["AD", 73.55148681989351],
  ["CH", 92.66491364757825],
  ["NO", 69.18257369172835],
  ["MT", 79.01928320997274],
  ["MC", 96.24983768341774],
  ["TV", 82.87495130502532],
  ["TW", 89.47896377093883],
  ["US", 85.14218932606154],
  ["MU", 95.06557589923386],
  ["BZ", 66.5741462147773],
  ["CI", 93.82612647708089],
  ["BM", 58.451499805220095],
  ["CK", 83.76152447734061],
  ["AT", 68.38495000649266],
  ["BO", 79.70003895597975],
  ["LS", 93.13141150499935],
  ["NL", 85.99045578496299],
  ["MW", 91.26444617582132],
  ["YE", 94.40721984157902],
  ["UG", 77.92884041033632],
  ["TT", 76.72607453577459],
  ["NZ", 84.20919361121932],
  ["MA", 91.76600441501104],
  ["MV", 68.80405142189326],
  ["LR", 83.87092585378522],
  ["BY", 85.39572782755486],
  ["BN", 61.16348526165433],
  ["AU", 85.99175431762109],
  ["GE", 85.15679781846514],
  ["GR", 75.39767562654201],
  ["IN", 91.06349824698091],
  ["GBENG", 88.9838981950396],
  ["RS", 90.4973380080509],
  ["PL", 67.05979742890533],
  ["SV", 84.70847941825737],
  ["SA", 88.40312946370601],
  ["HK", 68.31710167510712],
  ["KG", 65.7830151928321],
  ["GD", 66.0066874431892],
  ["GQ", 68.21354369562394],
  ["DJ", 87.69737696403065],
  ["KE", 89.13744968185951],
  ["KR", 90.43793013894299],
  ["SC", 81.16218672899623],
  ["ST", 78.77548370341513],
  ["PY", 84.53415140890793],
  ["SB", 77.32924295546033],
  ["IL", 89.36274509803923],
  ["DK", 91.37384755226594],
  ["DO", 82.3370341514089],
  ["RU", 55.22464614985066],
  ["SG", 93.66640696013505],
  ["PK", 92.77788598883262],
  ["GB", 71.98935203220361],
  ["GU", 90.44896766653682],
  ["GW", 54.39033891702376],
  ["JP", 91.0904427996364],
  ["SR", 91.8961823139852],
  ["SE", 70.61712764576029],
  ["SD", 91.94844825347359],
  ["SS", 81.45370731073886],
  ["PH", 65.5067523698221],
  ["RW", 92.36300480457083],
  ["KU", 70.62978833917674],
  ["HN", 89.96136865342163],
  ["GA", 79.4594857810674],
  ["DZ", 91.79814309829892],
  ["DM", 83.70114270873913],
  ["FR", 85.80476561485521],
  ["GL", 70.33794312426957],
  ["ES", 73.37715881054409],
  ["HT", 69.64582521750422],
  ["PE", 78.05999220880405],
  ["QA", 90.04901960784314],
  ["SI", 53.2856122581483],
  ["PR", 88.00090897286067],
  ["PS", 90.03473574860408],
  ["IQ", 92.63602129593559],
  ["HU", 90.9446825087651],
  ["KN", 74.27444487728866],
  ["KY", 83.17978184651345],
  ["FI", 91.9783145046098],
  ["ER", 76.89520841449162],
  ["GM", 90.6103103493053],
  ["EE", 98.79950655758992],
  ["EG", 93.24990261005064],
  ["ID", 96.25373328139203],
  ["IS", 78.60115569406571],
  ["SK", 53.24503311258278],
  ["RO", 75.07499026100506],
  ["PG", 86.77541877678223],
  ["KM", 62.76230359693547],
  ["IR", 85.84274769510452],
  ["KZ", 87.0929100116868],
  ["IE", 93.79820802493182],
  ["GN", 74.8746915984937],
  ["FJ", 82.74315024022854],
  ["GY", 81.47383456693936],
  ["JM", 76.58875470718088],
  ["KI", 82.27015971951694],
  ["HR", 89.07382158161278],
  ["SO", 93.69757174392936],
  ["PT", 89.34099467601611],
  ["SY", 92.8489806518634],
  ["SN", 84.94903259316972],
  ["KH", 91.65660303856642],
  ["EC", 0],
  ["ET", 55.673938449552004],
  ["DE", 94.83443708609272],
  ["SL", 92.79996104402025],
  ["PW", 88.75373328139202],
  ["SZ", 82.41137514608492],
  ["PA", 81.94779898714452],
  ["SM", 91.08914426697832],
  ["IT", 86.19107908063887],
  ["JO", 88.56512141280352],
  ["GH", 80.12303596935463],
  ["CG", 76.88871575120115],
  ["BT", 85.09511751720555],
  ["AO", 94.01408907934035],
  ["ML", 74.93085313595637],
  ["TN", 68.58524866900403],
  ["LI", 67.85125308401506],
  ["MM", 37.98597584729256],
  ["MZ", 90.89371510193482],
  ["NA", 78.77386053759253],
  ["CF", 83.32294507206856],
  ["BB", 93.18659914296845],
  ["CD", 80.89988313206076],
  ["BW", 94.7545773276198],
  ["AL", 68.15575899233866],
  ["NC", 85.18341773795612],
  ["MX", 84.39780547980781],
  ["TZ", 88.7816517335411],
  ["VE", 36.83644981171276],
  ["MN", 85.43013894299442],
  ["MY", 73.86995195429165],
  ["AM", 83.00512920399949],
  ["CR", 86.1832878846903],
  ["BA", 74.82307492533438],
  ["AZ", 92.94766913387872],
  ["CV", 92.48344370860927],
  ["BR", 82.39709128684586],
  ["CA", 87.4259836384885],
  ["BE", 86.04402025710947],
  ["TH", 76.95364238410596],
  ["WS", 68.38624853915076],
  ["UZ", 89.51499805220101],
  ["Wales", 78.19374107258797],
  ["NG", 93.61511492014024],
  ["NP", 72.9892221789378],
  ["MK", 73.27587326321256],
  ["BD", 87.94539670172705],
  ["BS", 91.30080509024802],
  ["CU", 85.50967406830281],
  ["BF", 91.65400597325024],
  ["NR", 87.84833138553435],
  ["NE", 88.91475133099598],
  ["ZW", 77.95318789767562],
  ["VC", 72.61134917543176],
  ["UY", 77.14257888585898],
  ["TJ", 90.75282430853136],
  ["VU", 85.94241007661343],
  ["ZA", 85.77684716270613],
  ["BG", 65.5755746007012],
]);

export const etMap = new Map<string, number>([
  ["CY", 93.2932525951557],
  ["AF", 79.89749134948096],
  ["CN", 57.45415224913495],
  ["BJ", 69.47361591695503],
  ["LA", 65.69593425605537],
  ["ME", 62.61245674740484],
  ["OM", 79.48745674740483],
  ["NI", 88.91306228373702],
  ["MR", 87.375],
  ["TG", 72.59429065743944],
  ["TP", 63.06790657439446],
  ["North Korea", 77.6946366782007],
  ["VN", 60.1492214532872],
  ["ZM", 79.93512110726644],
  ["MD", 83.15657439446366],
  ["CO", 55.542820069204154],
  ["AG", 65.09948096885813],
  ["AE", 63.40700692041522],
  ["CZ", 66.56098615916956],
  ["AR", 94.08001730103807],
  ["BI", 74.56314878892734],
  ["CM", 70.90095155709342],
  ["LB", 67.56098615916954],
  ["LU", 89.21712802768165],
  ["TD", 84.91392733564014],
  ["TR", 59.14878892733564],
  ["Scotland", 83.71107266435986],
  ["UA", 78.48226643598616],
  ["LT", 62.227941176470594],
  ["LC", 96.13884083044982],
  ["MG", 87.66911764705883],
  ["CL", 62.4173875432526],
  ["BH", 68.58304498269896],
  ["AD", 93.2954152249135],
  ["CH", 91.24351211072664],
  ["NO", 60.15268166089965],
  ["MT", 77.60856401384083],
  ["MC", 90.64273356401384],
  ["TV", 82.6574394463668],
  ["TW", 87.5484429065744],
  ["US", 77.23702422145328],
  ["MU", 79.07612456747405],
  ["BZ", 78.5242214532872],
  ["CI", 96.06055363321799],
  ["BM", 54.58693771626297],
  ["CK", 81.23529411764706],
  ["AT", 59.477076124567475],
  ["BO", 68.77681660899654],
  ["LS", 92.22361591695501],
  ["NL", 87.42517301038062],
  ["MW", 86.25994809688581],
  ["YE", 92.09731833910034],
  ["UG", 78.88235294117646],
  ["TT", 70.70588235294117],
  ["NZ", 80.94290657439447],
  ["MA", 59.55103806228374],
  ["MV", 59.26946366782006],
  ["LR", 71.33304498269895],
  ["BY", 82.13581314878893],
  ["BN", 85.11980968858131],
  ["AU", 83.09385813148789],
  ["GE", 87.51038062283737],
  ["GR", 82.29152249134948],
  ["IN", 89.2863321799308],
  ["GBENG", 87.9628027681661],
  ["RS", 86.26254325259517],
  ["PL", 90.42474048442907],
  ["SV", 82.8538062283737],
  ["SA", 58.826989619377166],
  ["HK", 60.902681660899646],
  ["KG", 59.682525951557096],
  ["GD", 72.65873702422145],
  ["GQ", 59.58996539792387],
  ["DJ", 89.54195501730104],
  ["KE", 87.80968858131489],
  ["KR", 89.76686851211073],
  ["SC", 82.2750865051903],
  ["ST", 73.34731833910034],
  ["PY", 87.2279411764706],
  ["SB", 78.97102076124567],
  ["IL", 90.16695501730105],
  ["DK", 63.985294117647065],
  ["DO", 77.51730103806229],
  ["RU", 59.70371972318339],
  ["SG", 86.91522491349481],
  ["PK", 87.39576124567475],
  ["GB", 70.94420415224914],
  ["GU", 79.2923875432526],
  ["GW", 75.18728373702423],
  ["JP", 89.77335640138409],
  ["SR", 90.84645328719722],
  ["SE", 74.85813148788927],
  ["SD", 86.09731833910035],
  ["SS", 85.84731833910034],
  ["PH", 54.673010380622834],
  ["RW", 76.13538062283737],
  ["KU", 40.304930795847746],
  ["HN", 86.57352941176471],
  ["GA", 68.62240484429066],
  ["DZ", 89.9022491349481],
  ["DM", 54.562716262975776],
  ["FR", 86.05320069204151],
  ["GL", 63.81920415224913],
  ["ES", 49.40095155709343],
  ["HT", 60.31055363321799],
  ["PE", 74.8931660899654],
  ["QA", 85.52032871972318],
  ["SI", 57.60596885813148],
  ["PR", 69.56877162629758],
  ["PS", 87.23010380622837],
  ["IQ", 89.96107266435986],
  ["HU", 87.47231833910034],
  ["KN", 55.99221453287198],
  ["KY", 80.89316608996539],
  ["FI", 89.85640138408304],
  ["ER", 71.715830449827],
  ["GM", 84.07266435986159],
  ["EE", 97.11894463667821],
  ["EG", 91.83780276816609],
  ["ID", 90.44896193771626],
  ["IS", 78.56790657439447],
  ["SK", 59.321366782006926],
  ["RO", 84.85034602076125],
  ["PG", 84.33693771626297],
  ["KM", 81.89749134948097],
  ["IR", 36.371539792387544],
  ["KZ", 84.29584775086505],
  ["IE", 85.25043252595155],
  ["GN", 84.91565743944636],
  ["FJ", 84.61894463667821],
  ["GY", 61.238754325259514],
  ["JM", 76.29757785467129],
  ["KI", 83.5544982698962],
  ["HR", 83.81660899653978],
  ["SO", 88.00951557093425],
  ["PT", 85.9294982698962],
  ["SY", 90.46842560553632],
  ["SN", 73.84385813148789],
  ["KH", 82.24005190311419],
  ["EC", 55.62543252595156],
  ["ET", 0],
  ["DE", 93.41262975778547],
  ["SL", 87.02854671280276],
  ["PW", 91.43425605536332],
  ["SZ", 86.89403114186851],
  ["PA", 87.21756055363322],
  ["SM", 91.42387543252595],
  ["IT", 75.16349480968859],
  ["JO", 84.58823529411764],
  ["GH", 69.14100346020761],
  ["CG", 58.06574394463668],
  ["BT", 89.57785467128028],
  ["AO", 92.34558823529412],
  ["ML", 84.6159169550173],
  ["TN", 61.71626297577855],
  ["LI", 57.86980968858132],
  ["MM", 64.97145328719724],
  ["MZ", 86.45112456747405],
  ["NA", 72.88451557093425],
  ["CF", 84.4666955017301],
  ["BB", 95.7651384083045],
  ["CD", 77.9628027681661],
  ["BW", 94.02162629757785],
  ["AL", 58.259948096885815],
  ["NC", 83.04368512110727],
  ["MX", 84.31574394463668],
  ["TZ", 88.67776816608996],
  ["VE", 61.67690311418686],
  ["MN", 58.292820069204154],
  ["MY", 67.46885813148789],
  ["AM", 88.59818339100346],
  ["CR", 90.9848615916955],
  ["BA", 84.4515570934256],
  ["AZ", 88.19636678200692],
  ["CV", 82.15441176470588],
  ["BR", 51.24999999999999],
  ["CA", 88.11505190311418],
  ["BE", 85.9991349480969],
  ["TH", 77.25994809688581],
  ["WS", 57.27638408304499],
  ["UZ", 84.11245674740483],
  ["Wales", 77.24913494809688],
  ["NG", 96.62673010380622],
  ["NP", 81.04930795847751],
  ["MK", 55.2742214532872],
  ["BD", 57.92647058823529],
  ["BS", 88.29714532871972],
  ["CU", 82.66782006920415],
  ["BF", 88.14835640138409],
  ["NR", 82.81704152249135],
  ["NE", 86.44506920415225],
  ["ZW", 84.76384083044984],
  ["VC", 88.17128027681662],
  ["UY", 84.3862456747405],
  ["TJ", 91.79498269896193],
  ["VU", 83.72318339100346],
  ["ZA", 76.26427335640138],
  ["BG", 62.19723183391004],
]);

export const deMap = new Map<string, number>([
  ["CY", 96.89770761245676],
  ["AF", 78.70422433679354],
  ["CN", 97.6492214532872],
  ["BJ", 98.68007497116493],
  ["LA", 95.1542675893887],
  ["ME", 67.77861880046136],
  ["OM", 66.68540945790082],
  ["NI", 99.7862600922722],
  ["MR", 93.3286476355248],
  ["TG", 84.6330738177624],
  ["TP", 92.2913062283737],
  ["North Korea", 93.56905997693194],
  ["VN", 96.12961361014995],
  ["ZM", 96.8901384083045],
  ["MD", 83.19564590542099],
  ["CO", 99.50980392156863],
  ["AG", 71.55709342560553],
  ["AE", 99.5000720876586],
  ["CZ", 87.88386678200692],
  ["AR", 99.10503171856978],
  ["BI", 92.43115628604383],
  ["CM", 86.41363898500578],
  ["LB", 93.86642156862746],
  ["LU", 100],
  ["TD", 86.83282871972318],
  ["TR", 69.70083621683968],
  ["Scotland", 93.72765282583622],
  ["UA", 64.95098039215686],
  ["LT", 98.52941176470588],
  ["LC", 91.69369953863898],
  ["MG", 98.08463091118801],
  ["CL", 97.48558246828142],
  ["BH", 96.4446366782007],
  ["AD", 84.16774798154556],
  ["CH", 95.68303056516724],
  ["NO", 93.82497116493657],
  ["MT", 98.75216262975779],
  ["MC", 98.2843137254902],
  ["TV", 90.27032871972318],
  ["TW", 92.46215397923876],
  ["US", 84.55918396770473],
  ["MU", 87.74509803921569],
  ["BZ", 88.06012110726643],
  ["CI", 98.2731401384083],
  ["BM", 91.79029700115339],
  ["CK", 89.68425605536332],
  ["AT", 100],
  ["BO", 100],
  ["LS", 96.11123125720876],
  ["NL", 100],
  ["MW", 69.91349480968859],
  ["YE", 100],
  ["UG", 51.633506343713954],
  ["TT", 88.25728085351788],
  ["NZ", 89.83023356401384],
  ["MA", 94.96792099192618],
  ["MV", 97.26283160322953],
  ["LR", 90.11101499423299],
  ["BY", 94.4546568627451],
  ["BN", 70.66212514417532],
  ["AU", 87.97794117647058],
  ["GE", 91.8054354094579],
  ["GR", 94.50223471741637],
  ["IN", 98.34594867358707],
  ["GBENG", 98.14158016147636],
  ["RS", 92.66652249134948],
  ["PL", 99.75490196078431],
  ["SV", 97.21020761245674],
  ["SA", 93.23637543252595],
  ["HK", 94.50547866205305],
  ["KG", 92.18029123414071],
  ["GD", 82.29418973471742],
  ["GQ", 98.42776816608996],
  ["DJ", 96.59349769319493],
  ["KE", 63.20898212226067],
  ["KR", 90.85171568627452],
  ["SC", 89.74120530565168],
  ["ST", 94.11620530565166],
  ["PY", 98.44182525951557],
  ["SB", 90.87586505190312],
  ["IL", 95.39395905420992],
  ["DK", 97.24733275663206],
  ["DO", 95.46424452133795],
  ["RU", 98.2843137254902],
  ["SG", 97.69103229527106],
  ["PK", 93.92877739331027],
  ["GB", 87.45025951557093],
  ["GU", 91.54159457900808],
  ["GW", 96.72325547866205],
  ["JP", 96.32929642445214],
  ["SR", 91.45148500576701],
  ["SE", 92.2671568627451],
  ["SD", 98.86714244521337],
  ["SS", 49.243440023068054],
  ["PH", 92.64129181084199],
  ["RW", 89.19730392156863],
  ["KU", 95.7771049596309],
  ["HN", 98.99329584775086],
  ["GA", 99.69939446366783],
  ["DZ", 94.17964244521337],
  ["DM", 88.44290657439447],
  ["FR", 97.12622549019608],
  ["GL", 97.25129757785467],
  ["ES", 88.52292387543253],
  ["HT", 93.28395328719724],
  ["PE", 97.77789792387543],
  ["QA", 94.43375144175317],
  ["SI", 97.19579008073818],
  ["PR", 84.64641003460207],
  ["PS", 69.36598904267589],
  ["IQ", 98.28106978085351],
  ["HU", 99.75490196078431],
  ["KN", 83.68692329873126],
  ["KY", 88.75865051903115],
  ["FI", 97.76600346020761],
  ["ER", 90.30493079584775],
  ["GM", 99.26470588235294],
  ["EE", 99.26470588235294],
  ["EG", 100],
  ["ID", 98.2843137254902],
  ["IS", 94.01023644752019],
  ["SK", 94.66479238754324],
  ["RO", 86.96294694348327],
  ["PG", 89.79455017301038],
  ["KM", 95.83044982698962],
  ["IR", 95.95335928489042],
  ["KZ", 92.38754325259517],
  ["IE", 98.27494232987313],
  ["GN", 87.6582324106113],
  ["FJ", 91.36930507497117],
  ["GY", 88.89994232987313],
  ["JM", 81.17466839677047],
  ["KI", 87.84529988465974],
  ["HR", 96.63675028835064],
  ["SO", 97.26643598615917],
  ["PT", 91.29577566320646],
  ["SY", 98.68512110726644],
  ["SN", 97.43223760092272],
  ["KH", 93.68692329873126],
  ["EC", 94.61108708189158],
  ["ET", 93.49553056516724],
  ["DE", 0],
  ["SL", 100],
  ["PW", 96.00454152249135],
  ["SZ", 79.71633506343714],
  ["PA", 96.5776384083045],
  ["SM", 94.32093425605537],
  ["IT", 97.9213523644752],
  ["JO", 69.99315167243368],
  ["GH", 99.40419550173011],
  ["CG", 95.8275663206459],
  ["BT", 93.89345444059977],
  ["AO", 94.65614186851211],
  ["ML", 87.61173587081892],
  ["TN", 72.37420703575548],
  ["LI", 93.7629757785467],
  ["MM", 96.1501585928489],
  ["MZ", 66.34047001153402],
  ["NA", 89.08953287197232],
  ["CF", 77.22498558246829],
  ["BB", 95.95732410611303],
  ["CD", 93.79072952710496],
  ["BW", 99.26470588235294],
  ["AL", 88.46813725490196],
  ["NC", 92.0220588235294],
  ["MX", 95.01477797001154],
  ["TZ", 83.67899365628605],
  ["VE", 94.61613321799308],
  ["MN", 93.41226931949251],
  ["MY", 84.35841983852364],
  ["AM", 96.56862745098039],
  ["CR", 94.80175893886967],
  ["BA", 88.48363610149941],
  ["AZ", 95.68375144175317],
  ["CV", 88.20934256055364],
  ["BR", 88.99077277970011],
  ["CA", 93.51895905420992],
  ["BE", 87.96208189158016],
  ["TH", 95.34313725490196],
  ["WS", 93.91003460207612],
  ["UZ", 99.26470588235294],
  ["Wales", 82.49927912341407],
  ["NG", 97.86980968858131],
  ["NP", 89.10106689734717],
  ["MK", 67.71301903114187],
  ["BD", 96.71712802768167],
  ["BS", 94.8659169550173],
  ["CU", 94.7761678200692],
  ["BF", 96.14727508650519],
  ["NR", 94.13494809688582],
  ["NE", 98.57951268742792],
  ["ZW", 82.50756920415225],
  ["VC", 81.73767301038062],
  ["UY", 91.7711937716263],
  ["TJ", 97.67985870818916],
  ["VU", 88.88696655132642],
  ["ZA", 93.6854815455594],
  ["BG", 98.52941176470588],
]);

export const slMap = new Map<string, number>([
  ["CY", 71.48775589530968],
  ["AF", 94.47557657424203],
  ["CN", 96.62930811090956],
  ["BJ", 97.6441435605079],
  ["LA", 90.9639803057787],
  ["ME", 92.45303187354237],
  ["OM", 96.31186835967867],
  ["NI", 33.92005700958798],
  ["MR", 96.19137082145633],
  ["TG", 92.12878984192795],
  ["TP", 93.84264058046125],
  ["North Korea", 94.44642394402695],
  ["VN", 96.72000518268982],
  ["ZM", 95.36376004146152],
  ["MD", 95.79197978750972],
  ["CO", 96.91629955947137],
  ["AG", 94.41856698626587],
  ["AE", 50.000971754340505],
  ["CZ", 87.54502461777662],
  ["AR", 68.55759264058045],
  ["BI", 76.37697590049235],
  ["CM", 96.31186835967867],
  ["LB", 71.31996631251619],
  ["LU", 66.07929515418502],
  ["TD", 97.30240995076444],
  ["TR", 94.18566986265871],
  ["Scotland", 64.94882093806686],
  ["UA", 96.25550660792952],
  ["LT", 99.77973568281938],
  ["LC", 96.38086291785437],
  ["MG", 88.46916299559471],
  ["CL", 88.42381445970459],
  ["BH", 86.97590049235554],
  ["AD", 95.4654703291008],
  ["CH", 84.13189945581757],
  ["NO", 86.50783881834673],
  ["MT", 81.8566986265872],
  ["MC", 83.25991189427313],
  ["TV", 87.54048976418761],
  ["TW", 98.32016066338429],
  ["US", 79.21773775589531],
  ["MU", 96.0352422907489],
  ["BZ", 82.43618813164032],
  ["CI", 88.24403990671158],
  ["BM", 89.72305001295673],
  ["CK", 91.5710028504794],
  ["AT", 66.07929515418502],
  ["BO", 93.83259911894272],
  ["LS", 69.50991189427313],
  ["NL", 66.07929515418502],
  ["MW", 96.65003887017362],
  ["YE", 66.07929515418502],
  ["UG", 93.98581238662867],
  ["TT", 93.1436252915263],
  ["NZ", 93.3221041720653],
  ["MA", 99.52772739051568],
  ["MV", 96.82592640580461],
  ["LR", 84.45193055195647],
  ["BY", 93.28031873542369],
  ["BN", 81.37891940917335],
  ["AU", 92.90003887017362],
  ["GE", 84.40204716247732],
  ["GR", 57.15697071780254],
  ["IN", 68.13164032132677],
  ["GBENG", 88.12127494169474],
  ["RS", 89.33823529411765],
  ["PL", 83.03964757709251],
  ["SV", 67.6386369525784],
  ["SA", 91.92115833117388],
  ["HK", 91.63481471883908],
  ["KG", 90.25654314589272],
  ["GD", 87.81614407877689],
  ["GQ", 69.40139932625033],
  ["DJ", 83.09147447525265],
  ["KE", 93.97674267945064],
  ["KR", 75.85287639284789],
  ["SC", 83.81834672194869],
  ["ST", 63.3224280901788],
  ["PY", 68.1031355273387],
  ["SB", 89.02565431458927],
  ["IL", 69.54683855921223],
  ["DK", 83.26898160145116],
  ["DO", 77.69953355791655],
  ["RU", 99.55947136563876],
  ["SG", 79.20316144078777],
  ["PK", 87.17057527856957],
  ["GB", 76.08059082663902],
  ["GU", 95.74274423425759],
  ["GW", 97.70892718320809],
  ["JP", 77.59847110650428],
  ["SR", 97.8948561803576],
  ["SE", 97.76237367193573],
  ["SD", 73.02993003368748],
  ["SS", 93.27319253692666],
  ["PH", 87.62989116351386],
  ["RW", 91.05370562321845],
  ["KU", 72.85695776107801],
  ["HN", 33.93657683337653],
  ["GA", 93.57637989116353],
  ["DZ", 82.14757709251101],
  ["DM", 90.03012438455559],
  ["FR", 88.35352422907489],
  ["GL", 81.22473438714692],
  ["ES", 91.75045348535889],
  ["HT", 97.62438455558436],
  ["PE", 88.37587457890646],
  ["QA", 87.81290489764187],
  ["SI", 97.62697590049235],
  ["PR", 84.59575019435087],
  ["PS", 73.16953874060637],
  ["IQ", 69.0395827934698],
  ["HU", 66.07929515418502],
  ["KN", 74.43055195646541],
  ["KY", 91.38507385332989],
  ["FI", 94.62717025136045],
  ["ER", 65.71812645763151],
  ["GM", 88.76651982378854],
  ["EE", 99.77973568281938],
  ["EG", 67.65677636693444],
  ["ID", 83.25991189427313],
  ["IS", 88.0713915522156],
  ["SK", 96.01548328582534],
  ["RO", 97.34322363306556],
  ["PG", 93.50317439751231],
  ["KM", 82.97421611816532],
  ["IR", 32.09056750453485],
  ["KZ", 91.40256543145892],
  ["IE", 77.58875356309925],
  ["GN", 97.18515159367712],
  ["FJ", 89.79819901528894],
  ["GY", 68.17115833117387],
  ["JM", 77.34905415910858],
  ["KI", 82.89388442601711],
  ["HR", 64.68612334801762],
  ["SO", 94.86622181912414],
  ["PT", 92.72965794247214],
  ["SY", 67.90489764187613],
  ["SN", 95.74306815237108],
  ["KH", 92.69435086810054],
  ["EC", 93.16532780513086],
  ["ET", 87.42258357087329],
  ["DE", 100],
  ["SL", 0],
  ["PW", 95.2895827934698],
  ["SZ", 88.88345426276237],
  ["PA", 81.49585384814719],
  ["SM", 84.58991966830786],
  ["IT", 88.23205493651204],
  ["JO", 76.88747084736978],
  ["GH", 92.7134620367971],
  ["CG", 93.56471883907747],
  ["BT", 88.51904638507385],
  ["AO", 96.79968903861104],
  ["ML", 86.24838040943249],
  ["TN", 91.43495724280902],
  ["LI", 98.54431199792693],
  ["MM", 90.83765224151334],
  ["MZ", 93.50900492355532],
  ["NA", 91.11071521119462],
  ["CF", 84.37257061414874],
  ["BB", 96.14828971236071],
  ["CD", 93.21521119461],
  ["BW", 91.18942731277532],
  ["AL", 96.14893754858772],
  ["NC", 94.89537444933922],
  ["MX", 91.96067634102099],
  ["TZ", 74.81374708473697],
  ["VE", 98.19480435345945],
  ["MN", 94.93554029541332],
  ["MY", 85.48199015288935],
  ["AM", 99.77973568281938],
  ["CR", 97.7471495206012],
  ["BA", 93.11252915263022],
  ["AZ", 97.96028763928479],
  ["CV", 89.87270018139414],
  ["BR", 65.3579295154185],
  ["CA", 90.80493651204975],
  ["BE", 97.37658719875616],
  ["TH", 100],
  ["WS", 99.0700310961389],
  ["UZ", 63.302669085255246],
  ["Wales", 80.63034464887276],
  ["NG", 88.37587457890646],
  ["NP", 78.48859808240476],
  ["MK", 84.72466960352423],
  ["BD", 95.57527856957762],
  ["BS", 92.93502202643171],
  ["CU", 86.56096138896086],
  ["BF", 98.59711065042758],
  ["NR", 97.66714174656647],
  ["NE", 71.49229074889868],
  ["ZW", 90.66467996890385],
  ["VC", 86.09549105986007],
  ["UY", 83.72700181394144],
  ["TJ", 66.44564654055455],
  ["VU", 93.83778180875875],
  ["ZA", 87.72479917076963],
  ["BG", 99.77973568281938],
]);

export const pwMap = new Map<string, number>([
  ["CY", 93.48892733564014],
  ["AF", 95.11557093425606],
  ["CN", 93.16920415224914],
  ["BJ", 91.1401384083045],
  ["LA", 98.24775086505191],
  ["ME", 93.04567474048443],
  ["OM", 97.90657439446366],
  ["NI", 92.96193771626298],
  ["MR", 94.79757785467127],
  ["TG", 88.20934256055364],
  ["TP", 91.33910034602076],
  ["North Korea", 94.07162629757786],
  ["VN", 94.77162629757785],
  ["ZM", 93.09342560553632],
  ["MD", 88.99204152249135],
  ["CO", 89.31418685121108],
  ["AG", 92.02006920415225],
  ["AE", 96.8712802768166],
  ["CZ", 98.45778546712802],
  ["AR", 91.18512110726643],
  ["BI", 90.14602076124567],
  ["CM", 98.03356401384083],
  ["LB", 92.65051903114187],
  ["LU", 96.3159169550173],
  ["TD", 82.97058823529412],
  ["TR", 96.73217993079585],
  ["Scotland", 95.4166089965398],
  ["UA", 89.54290657439446],
  ["LT", 97.21903114186851],
  ["LC", 88.14567474048442],
  ["MG", 97.90069204152249],
  ["CL", 96.98996539792387],
  ["BH", 97.68927335640139],
  ["AD", 85.05640138408305],
  ["CH", 97.30034602076124],
  ["NO", 91.70692041522491],
  ["MT", 95.90657439446368],
  ["MC", 97.79307958477509],
  ["TV", 82.64844290657439],
  ["TW", 99.3515570934256],
  ["US", 91.1522491349481],
  ["MU", 89.10276816608996],
  ["BZ", 88.00692041522491],
  ["CI", 95.56782006920416],
  ["BM", 93.51695501730104],
  ["CK", 95.96539792387543],
  ["AT", 95.60692041522492],
  ["BO", 85.77370242214532],
  ["LS", 96.14186851211073],
  ["NL", 97.49307958477509],
  ["MW", 98.79930795847751],
  ["YE", 98.36712802768166],
  ["UG", 89.27993079584775],
  ["TT", 97.1280276816609],
  ["NZ", 96.07231833910035],
  ["MA", 97.51522491349482],
  ["MV", 98.71833910034601],
  ["LR", 93.31799307958477],
  ["BY", 89.52387543252595],
  ["BN", 88.39653979238754],
  ["AU", 96.26262975778546],
  ["GE", 96.68304498269896],
  ["GR", 94.70865051903115],
  ["IN", 92.12352941176471],
  ["GBENG", 96.99204152249135],
  ["RS", 88.83321799307959],
  ["PL", 97.46228373702422],
  ["SV", 93.61003460207613],
  ["SA", 94.36608996539792],
  ["HK", 90.20968858131488],
  ["KG", 89.29550173010381],
  ["GD", 80.54290657439446],
  ["GQ", 90.28996539792388],
  ["DJ", 91.35155709342561],
  ["KE", 96.33252595155709],
  ["KR", 96.53840830449826],
  ["SC", 90.80968858131487],
  ["ST", 79.59723183391003],
  ["PY", 96.13806228373703],
  ["SB", 90.46159169550172],
  ["IL", 94.74567474048443],
  ["DK", 97.27058823529411],
  ["DO", 95.29653979238755],
  ["RU", 97.67370242214533],
  ["SG", 92.31245674740485],
  ["PK", 97.94152249134949],
  ["GB", 94.01868512110727],
  ["GU", 96.37093425605536],
  ["GW", 90.19584775086506],
  ["JP", 97.65397923875433],
  ["SR", 94.01003460207612],
  ["SE", 86.72491349480968],
  ["SD", 97.4719723183391],
  ["SS", 94.35017301038062],
  ["PH", 95.59965397923875],
  ["RW", 83.10484429065744],
  ["KU", 97.55328719723182],
  ["HN", 93.62629757785467],
  ["GA", 84.1726643598616],
  ["DZ", 97.53460207612457],
  ["DM", 96.34809688581315],
  ["FR", 97.27162629757785],
  ["GL", 96.83737024221453],
  ["ES", 84.37923875432526],
  ["HT", 97.54290657439446],
  ["PE", 97.40622837370242],
  ["QA", 97.98027681660899],
  ["SI", 97.34878892733563],
  ["PR", 96.35328719723184],
  ["PS", 98.24567474048443],
  ["IQ", 95.0955017301038],
  ["HU", 96.66020761245674],
  ["KN", 87.08892733564014],
  ["KY", 94.00795847750865],
  ["FI", 98.49861591695502],
  ["ER", 87.60795847750865],
  ["GM", 96.159169550173],
  ["EE", 68.95121107266436],
  ["EG", 93.69688581314878],
  ["ID", 97.76297577854672],
  ["IS", 94.11522491349481],
  ["SK", 94.460553633218],
  ["RO", 83.09965397923875],
  ["PG", 95.91903114186852],
  ["KM", 88.5636678200692],
  ["IR", 88.30346020761246],
  ["KZ", 82.24463667820069],
  ["IE", 96.29550173010381],
  ["GN", 82.67681660899655],
  ["FJ", 38.08062283737024],
  ["GY", 80.05121107266436],
  ["JM", 77.61349480968858],
  ["KI", 90.08304498269895],
  ["HR", 92.9515570934256],
  ["SO", 94.42629757785467],
  ["PT", 94.25640138408305],
  ["SY", 97.34532871972318],
  ["SN", 94.99100346020761],
  ["KH", 95.4038062283737],
  ["EC", 88.77923875432526],
  ["ET", 91.94844290657439],
  ["DE", 96.03287197231833],
  ["SL", 96.16332179930795],
  ["PW", 0],
  ["SZ", 91.89688581314878],
  ["PA", 97.52871972318339],
  ["SM", 88.69965397923876],
  ["IT", 96.35916955017301],
  ["JO", 98.08754325259515],
  ["GH", 89.25743944636679],
  ["CG", 92.26608996539792],
  ["BT", 83.31384083044982],
  ["AO", 95.4076124567474],
  ["ML", 81.68235294117648],
  ["TN", 93.98027681660899],
  ["LI", 98.11764705882354],
  ["MM", 90.69480968858132],
  ["MZ", 93.17162629757786],
  ["NA", 92.41038062283738],
  ["CF", 95.98408304498271],
  ["BB", 93.62664359861593],
  ["CD", 87.95328719723183],
  ["BW", 94.28823529411765],
  ["AL", 95.40138408304499],
  ["NC", 86.82110726643599],
  ["MX", 92.47128027681661],
  ["TZ", 85.51868512110727],
  ["VE", 94.81245674740485],
  ["MN", 91.6681660899654],
  ["MY", 88.17820069204151],
  ["AM", 98.319723183391],
  ["CR", 95.63806228373703],
  ["BA", 90.0643598615917],
  ["AZ", 96.10207612456747],
  ["CV", 93.82525951557093],
  ["BR", 84.08373702422145],
  ["CA", 96.0955017301038],
  ["BE", 97.39446366782006],
  ["TH", 97.6311418685121],
  ["WS", 99.66228373702423],
  ["UZ", 92.7249134948097],
  ["Wales", 94.76262975778546],
  ["NG", 96.4166089965398],
  ["NP", 93.2076124567474],
  ["MK", 85.14117647058823],
  ["BD", 96.81245674740484],
  ["BS", 94.12768166089965],
  ["CU", 96.40346020761245],
  ["BF", 94.6439446366782],
  ["NR", 96.23806228373702],
  ["NE", 95.40276816608997],
  ["ZW", 87.06608996539792],
  ["VC", 83.28373702422145],
  ["UY", 91.16885813148788],
  ["TJ", 90.86470588235294],
  ["VU", 90.20380622837371],
  ["ZA", 91.65155709342561],
  ["BG", 96.28927335640138],
]);

export const szMap = new Map<string, number>([
  ["CY", 92.02222078258616],
  ["AF", 81.67400881057269],
  ["CN", 85.2141098730241],
  ["BJ", 85.41234775848665],
  ["LA", 83.76166105208604],
  ["ME", 62.735164550401656],
  ["OM", 84.7496112982638],
  ["NI", 88.84976677895828],
  ["MR", 84.62295931588496],
  ["TG", 82.46955169733091],
  ["TP", 85.64880798134232],
  ["North Korea", 85.12891940917336],
  ["VN", 86.5350479398808],
  ["ZM", 84.50375745011661],
  ["MD", 82.22596527597823],
  ["CO", 84.14777144337911],
  ["AG", 66.63481471883908],
  ["AE", 77.5346592381446],
  ["CZ", 85.39679968903862],
  ["AR", 92.75071261984971],
  ["BI", 81.431718061674],
  ["CM", 83.6055325213786],
  ["LB", 93.98613630474216],
  ["LU", 88.9644337911376],
  ["TD", 84.06322881575538],
  ["TR", 86.64809536149262],
  ["Scotland", 81.28044830266909],
  ["UA", 84.58538481471884],
  ["LT", 87.3166623477585],
  ["LC", 92.69791396734905],
  ["MG", 91.3209380668567],
  ["CL", 87.31212749416947],
  ["BH", 76.55675045348535],
  ["AD", 84.08039647577093],
  ["CH", 84.69033428349313],
  ["NO", 84.3900621922778],
  ["MT", 90.82663902565432],
  ["MC", 78.61719357346463],
  ["TV", 84.96436900751489],
  ["TW", 84.83642135268205],
  ["US", 81.43107022544702],
  ["MU", 89.95594713656388],
  ["BZ", 81.34652759782327],
  ["CI", 88.30849961129826],
  ["BM", 80.65463850738533],
  ["CK", 78.36356569059342],
  ["AT", 88.9641098730241],
  ["BO", 91.6445322622441],
  ["LS", 88.34445452189686],
  ["NL", 84.26049494687743],
  ["MW", 71.1466701217932],
  ["YE", 84.64077481212749],
  ["UG", 70.04308110909562],
  ["TT", 62.18158849442861],
  ["NZ", 78.82676859289971],
  ["MA", 86.9269888572169],
  ["MV", 86.80875874578906],
  ["LR", 86.5697071780254],
  ["BY", 83.09536149261467],
  ["BN", 79.29159108577352],
  ["AU", 81.45050531225706],
  ["GE", 86.45892718320809],
  ["GR", 83.27060119201866],
  ["IN", 91.26198497019953],
  ["GBENG", 86.43171806167402],
  ["RS", 84.27442342575797],
  ["PL", 90.48166623477584],
  ["SV", 89.23101839854884],
  ["SA", 85.94422130085513],
  ["HK", 84.7489634620368],
  ["KG", 84.84743456854108],
  ["GD", 67.38760041461519],
  ["GQ", 85.18042238922],
  ["DJ", 92.14854884685153],
  ["KE", 73.4633324695517],
  ["KR", 91.05046644208345],
  ["SC", 85.55940658201607],
  ["ST", 86.58687483804094],
  ["PY", 84.86784140969162],
  ["SB", 79.70329100803318],
  ["IL", 91.79871728427054],
  ["DK", 89.25725576574241],
  ["DO", 79.82476030059601],
  ["RU", 87.4983804094325],
  ["SG", 87.24701995335579],
  ["PK", 86.82527856957762],
  ["GB", 76.30765742420316],
  ["GU", 83.53038351904638],
  ["GW", 75.11304742161181],
  ["JP", 91.16610520860327],
  ["SR", 83.69558175693184],
  ["SE", 89.49306815237108],
  ["SD", 84.06225706141487],
  ["SS", 84.32981342316663],
  ["PH", 79.8561803576056],
  ["RW", 89.11149261466701],
  ["KU", 82.40833117387925],
  ["HN", 89.24170769629438],
  ["GA", 74.00589530966572],
  ["DZ", 85.9630085514382],
  ["DM", 86.9263410209899],
  ["FR", 85.23062969681264],
  ["GL", 87.5369266649391],
  ["ES", 93.59646281420056],
  ["HT", 85.58305260430163],
  ["PE", 85.43405027209121],
  ["QA", 87.1789971495206],
  ["SI", 88.2116480953615],
  ["PR", 83.13228815755377],
  ["PS", 76.70154185022027],
  ["IQ", 85.4632029023063],
  ["HU", 84.33110909562063],
  ["KN", 71.90593417983933],
  ["KY", 78.20808499611299],
  ["FI", 87.2143042238922],
  ["ER", 78.51192018657683],
  ["GM", 81.54606115573982],
  ["EE", 86.62574501166105],
  ["EG", 85.14900233221042],
  ["ID", 78.62172842705364],
  ["IS", 87.69143560507904],
  ["SK", 87.27520082923037],
  ["RO", 73.55500129567245],
  ["PG", 72.83622700181395],
  ["KM", 56.924397512308886],
  ["IR", 84.67122311479658],
  ["KZ", 91.24060637470848],
  ["IE", 88.60941953874061],
  ["GN", 73.39239440269499],
  ["FJ", 84.33337652241514],
  ["GY", 71.62639284788806],
  ["JM", 79.94914485618035],
  ["KI", 71.05953614926148],
  ["HR", 88.48082404768076],
  ["SO", 88.7529152630215],
  ["PT", 85.06575537704069],
  ["SY", 84.64984451930552],
  ["SN", 88.55824047680746],
  ["KH", 85.16616999222597],
  ["EC", 82.58972531743976],
  ["ET", 87.75589530966572],
  ["DE", 79.35183985488469],
  ["SL", 88.88345426276237],
  ["PW", 92.37950246177766],
  ["SZ", 0],
  ["PA", 88.05681523710805],
  ["SM", 94.3576703809277],
  ["IT", 85.70160663384297],
  ["JO", 74.0033039647577],
  ["GH", 85.98082404768076],
  ["CG", 85.94908007255766],
  ["BT", 86.79580202124903],
  ["AO", 73.22395698367453],
  ["ML", 74.13513863695258],
  ["TN", 84.53809277014771],
  ["LI", 71.31834672194869],
  ["MM", 83.65768333765224],
  ["MZ", 76.73911635138637],
  ["NA", 79.6041720653019],
  ["CF", 83.45620627105468],
  ["BB", 86.08480176211454],
  ["CD", 83.75],
  ["BW", 89.95886239958539],
  ["AL", 82.14822492873802],
  ["NC", 85.80299300336874],
  ["MX", 76.23153666753045],
  ["TZ", 80.88721171287898],
  ["VE", 79.48302669085255],
  ["MN", 83.44065820160664],
  ["MY", 77.40703550142524],
  ["AM", 85.66500388701736],
  ["CR", 77.48509976677896],
  ["BA", 78.86531484840631],
  ["AZ", 86.16578129048976],
  ["CV", 83.34251101321586],
  ["BR", 82.97713138118684],
  ["CA", 87.50647836227002],
  ["BE", 89.23360974345685],
  ["TH", 88.4299689038611],
  ["WS", 66.20465146410987],
  ["UZ", 85.16616999222597],
  ["Wales", 80.13442601710288],
  ["NG", 85.67731277533039],
  ["NP", 81.47188390774812],
  ["MK", 76.76243845555844],
  ["BD", 85.33266390256543],
  ["BS", 87.63507385332989],
  ["CU", 82.76820419797876],
  ["BF", 87.34354755117906],
  ["NR", 85.28925887535631],
  ["NE", 90.37671676600155],
  ["ZW", 74.87010883648614],
  ["VC", 82.77824565949727],
  ["UY", 85.40975641357865],
  ["TJ", 88.24695516973308],
  ["VU", 80.39712360715211],
  ["ZA", 82.76399326250325],
  ["BG", 89.998704327546],
]);

export const paMap = new Map<string, number>([
  ["CY", 55.30739828971236],
  ["AF", 92.10676341020991],
  ["CN", 96.1466701217932],
  ["BJ", 97.13429644985747],
  ["LA", 80.30998963462036],
  ["ME", 92.6781549624255],
  ["OM", 80.73918113500908],
  ["NI", 81.94448043534595],
  ["MR", 94.70523451671417],
  ["TG", 94.55849961129826],
  ["TP", 68.01697330914745],
  ["North Korea", 68.72311479657942],
  ["VN", 72.44817310183986],
  ["ZM", 94.69681264576315],
  ["MD", 59.464563358383],
  ["CO", 84.26017102876393],
  ["AG", 85.42076962943767],
  ["AE", 86.13371339725317],
  ["CZ", 72.51166105208603],
  ["AR", 83.79308110909562],
  ["BI", 73.14913189945582],
  ["CM", 87.72836227001814],
  ["LB", 63.72603005960094],
  ["LU", 65.65269499870433],
  ["TD", 79.30130862917855],
  ["TR", 69.46035242290749],
  ["Scotland", 77.4977325732055],
  ["UA", 95.14608706918891],
  ["LT", 97.5391940917336],
  ["LC", 96.10067374967608],
  ["MG", 83.23982897123608],
  ["CL", 88.15463850738533],
  ["BH", 56.935086810054415],
  ["AD", 77.56057268722468],
  ["CH", 87.01444674786214],
  ["NO", 69.71074112464369],
  ["MT", 51.12302409950764],
  ["MC", 50.5704197978751],
  ["TV", 85.01295672454003],
  ["TW", 94.5678932365898],
  ["US", 72.94311997926924],
  ["MU", 83.19933920704847],
  ["BZ", 65.26949987043275],
  ["CI", 82.14595750194351],
  ["BM", 68.2492225965276],
  ["CK", 84.74410469033428],
  ["AT", 65.12665198237886],
  ["BO", 78.97868618813165],
  ["LS", 80.24747343871469],
  ["NL", 81.6448561803576],
  ["MW", 86.80195646540555],
  ["YE", 66.26068929774553],
  ["UG", 93.30493651204975],
  ["TT", 68.07916558693961],
  ["NZ", 86.20335579165587],
  ["MA", 72.16442083441305],
  ["MV", 79.46974604819901],
  ["LR", 66.75531225706142],
  ["BY", 92.23017621145374],
  ["BN", 77.30953614926148],
  ["AU", 85.47972272609485],
  ["GE", 65.25783881834673],
  ["GR", 66.32255765742421],
  ["IN", 82.74034724021767],
  ["GBENG", 59.7143042238922],
  ["RS", 74.04735682819383],
  ["PL", 74.75965275978233],
  ["SV", 65.30545478103136],
  ["SA", 90.51826898160145],
  ["HK", 91.54606115573984],
  ["KG", 68.97058823529412],
  ["GD", 80.05279865250064],
  ["GQ", 83.4655998963462],
  ["DJ", 84.36090956206272],
  ["KE", 90.35695776107801],
  ["KR", 54.28057786991448],
  ["SC", 58.70627105467737],
  ["ST", 92.48607152111946],
  ["PY", 49.12315366675305],
  ["SB", 89.01010624514123],
  ["IL", 65.98211972013475],
  ["DK", 64.95659497279088],
  ["DO", 65.82923037056231],
  ["RU", 74.38747084736978],
  ["SG", 48.01081886499093],
  ["PK", 81.72389220005184],
  ["GB", 67.79897641876134],
  ["GU", 90.62904897641876],
  ["GW", 96.23704327545997],
  ["JP", 56.522091215340765],
  ["SR", 80.44862658719876],
  ["SE", 80.24196683078519],
  ["SD", 69.16299559471366],
  ["SS", 75.81918890904379],
  ["PH", 86.05500129567245],
  ["RW", 91.45665975641359],
  ["KU", 84.17336097434568],
  ["HN", 81.94869137082146],
  ["GA", 95.2873153666753],
  ["DZ", 73.83681005441825],
  ["DM", 88.0377040684115],
  ["FR", 65.01716766001555],
  ["GL", 77.69435086810054],
  ["ES", 79.53874060637472],
  ["HT", 95.35922518787251],
  ["PE", 64.77584866545737],
  ["QA", 82.30759264058047],
  ["SI", 73.62723503498316],
  ["PR", 80.79651464109872],
  ["PS", 84.8211972013475],
  ["IQ", 66.51302150816274],
  ["HU", 65.30027209121533],
  ["KN", 88.92362010883649],
  ["KY", 84.64077481212749],
  ["FI", 58.55629696812645],
  ["ER", 82.41545737237627],
  ["GM", 75.90049235553252],
  ["EE", 83.79988338947913],
  ["EG", 67.04003627882871],
  ["ID", 50.5704197978751],
  ["IS", 73.59808240476808],
  ["SK", 74.52740347240217],
  ["RO", 79.29029541331951],
  ["PG", 77.83266390256543],
  ["KM", 87.87412542109355],
  ["IR", 75.73885721689557],
  ["KZ", 91.75077740347241],
  ["IE", 82.37917854366417],
  ["GN", 95.97207825861622],
  ["FJ", 86.06018398548846],
  ["GY", 85.65917336097435],
  ["JM", 90.84704586680488],
  ["KI", 51.516908525524755],
  ["HR", 79.5348535890127],
  ["SO", 94.92647058823529],
  ["PT", 91.15930292821974],
  ["SY", 66.48872764965017],
  ["SN", 78.55273386887795],
  ["KH", 81.53116092251878],
  ["EC", 82.40541591085774],
  ["ET", 87.7513604560767],
  ["DE", 96.54411764705883],
  ["SL", 81.49585384814719],
  ["PW", 97.45270795542886],
  ["SZ", 88.05681523710805],
  ["PA", 0],
  ["SM", 74.94234257579684],
  ["IT", 64.82961907229853],
  ["JO", 85.47810313552733],
  ["GH", 78.51483544959834],
  ["CG", 85.71974604819901],
  ["BT", 92.91105208603265],
  ["AO", 72.81582016066338],
  ["ML", 79.16785436641617],
  ["TN", 70.20827934698109],
  ["LI", 96.59464887276496],
  ["MM", 90.45218968644727],
  ["MZ", 90.76120756672714],
  ["NA", 74.0687354236849],
  ["CF", 82.85242290748899],
  ["BB", 95.08357087328324],
  ["CD", 84.18210676341022],
  ["BW", 95.33979010106245],
  ["AL", 73.45005182689816],
  ["NC", 92.33674527079555],
  ["MX", 65.00971754340503],
  ["TZ", 92.0695128271573],
  ["VE", 94.34115055713916],
  ["MN", 69.29839336615704],
  ["MY", 64.04185022026432],
  ["AM", 90.28342834931328],
  ["CR", 74.36285307074371],
  ["BA", 90.7485747603006],
  ["AZ", 88.3914226483545],
  ["CV", 77.83849442860846],
  ["BR", 89.2511013215859],
  ["CA", 79.34244622959315],
  ["BE", 79.81309924850997],
  ["TH", 76.87062710546773],
  ["WS", 72.04100803316922],
  ["UZ", 80.1418761337134],
  ["Wales", 67.39731795802021],
  ["NG", 81.62380150298004],
  ["NP", 63.352552474734395],
  ["MK", 81.4987691111687],
  ["BD", 94.92290748898678],
  ["BS", 93.59840632288157],
  ["CU", 82.25414615185281],
  ["BF", 72.9632029023063],
  ["NR", 93.9300984711065],
  ["NE", 83.70627105467737],
  ["ZW", 88.56115573982896],
  ["VC", 93.48600673749677],
  ["UY", 56.61764705882353],
  ["TJ", 78.15722985229334],
  ["VU", 69.18372635397772],
  ["ZA", 89.24948173101839],
  ["BG", 82.88837781808759],
]);

export const smMap = new Map<string, number>([
  ["CY", 53.989331026528255],
  ["AF", 94.43194925028835],
  ["CN", 92.99163783160323],
  ["BJ", 91.8437139561707],
  ["LA", 95.65772779700116],
  ["ME", 92.04671280276817],
  ["OM", 70.3416955017301],
  ["NI", 84.16435986159169],
  ["MR", 93.65570934256056],
  ["TG", 89.9887543252595],
  ["TP", 89.72289504036908],
  ["North Korea", 89.9103229527105],
  ["VN", 93.01038062283737],
  ["ZM", 94.35640138408304],
  ["MD", 91.76903114186851],
  ["CO", 92.00432525951557],
  ["AG", 91.94809688581314],
  ["AE", 89.06805074971165],
  ["CZ", 62.945213379469436],
  ["AR", 52.04584775086505],
  ["BI", 81.25836216839677],
  ["CM", 93.76874279123413],
  ["LB", 76.35121107266436],
  ["LU", 84.13869665513263],
  ["TD", 90.39936562860437],
  ["TR", 92.52883506343714],
  ["Scotland", 79.31805074971165],
  ["UA", 93.08506343713955],
  ["LT", 95.16262975778547],
  ["LC", 55.442329873125715],
  ["MG", 78.04728950403691],
  ["CL", 66.57785467128028],
  ["BH", 79.63379469434832],
  ["AD", 90.95271049596309],
  ["CH", 91.63264129181084],
  ["NO", 88.27393310265282],
  ["MT", 75.09515570934256],
  ["MC", 96.17791234140715],
  ["TV", 76.97866205305651],
  ["TW", 92.54815455594002],
  ["US", 81.14158016147636],
  ["MU", 93.13350634371396],
  ["BZ", 86.6683967704729],
  ["CI", 85.32151095732411],
  ["BM", 83.82497116493657],
  ["CK", 86.5816032295271],
  ["AT", 84.71885813148788],
  ["BO", 91.84861591695501],
  ["LS", 81.73327566320646],
  ["NL", 84.67214532871972],
  ["MW", 94.79094579008074],
  ["YE", 84.98558246828142],
  ["UG", 93.75259515570934],
  ["TT", 92.7791234140715],
  ["NZ", 88.32266435986159],
  ["MA", 94.59198385236448],
  ["MV", 94.76730103806229],
  ["LR", 78.46856978085351],
  ["BY", 89.80680507497117],
  ["BN", 77.18339100346022],
  ["AU", 88.4305074971165],
  ["GE", 64.06516724336794],
  ["GR", 77.44117647058823],
  ["IN", 84.1199538638985],
  ["GBENG", 61.53258362168397],
  ["RS", 92.67704728950403],
  ["PL", 51.52797001153403],
  ["SV", 84.30622837370242],
  ["SA", 91.67329873125722],
  ["HK", 94.1260092272203],
  ["KG", 92.2750865051903],
  ["GD", 86.51585928489042],
  ["GQ", 87.78171856978085],
  ["DJ", 80.719723183391],
  ["KE", 90.40138408304497],
  ["KR", 57.45299884659746],
  ["SC", 87.88292964244522],
  ["ST", 87.89648212226066],
  ["PY", 84.54382929642446],
  ["SB", 86.75663206459055],
  ["IL", 67.76528258362168],
  ["DK", 87.16522491349481],
  ["DO", 85.58621683967704],
  ["RU", 64.10813148788928],
  ["SG", 92.98154555940023],
  ["PK", 82.33737024221453],
  ["GB", 78.65138408304499],
  ["GU", 94.15686274509804],
  ["GW", 91.66983852364476],
  ["JP", 55.13869665513265],
  ["SR", 84.47462514417532],
  ["SE", 90.88898500576701],
  ["SD", 89.56978085351788],
  ["SS", 90.87053056516724],
  ["PH", 84.59659746251442],
  ["RW", 92.03806228373702],
  ["KU", 89.08073817762399],
  ["HN", 84.61014994232987],
  ["GA", 90.45040369088811],
  ["DZ", 74.38725490196079],
  ["DM", 93.86505190311418],
  ["FR", 85.51326412918108],
  ["GL", 58.911764705882355],
  ["ES", 87.77277970011535],
  ["HT", 94.77566320645904],
  ["PE", 85.51672433679354],
  ["QA", 79.60409457900808],
  ["SI", 65.00547866205305],
  ["PR", 83.7387543252595],
  ["PS", 89.56603229527104],
  ["IQ", 84.06718569780853],
  ["HU", 84.87197231833909],
  ["KN", 88.05507497116494],
  ["KY", 85.50461361014993],
  ["FI", 67.43194925028834],
  ["ER", 87.58823529411764],
  ["GM", 91.85640138408304],
  ["EE", 96.6049596309112],
  ["EG", 83.9232987312572],
  ["ID", 96.27595155709344],
  ["IS", 88.33044982698962],
  ["SK", 64.16983852364476],
  ["RO", 90.70617070357555],
  ["PG", 93.43656286043829],
  ["KM", 81.01355247981546],
  ["IR", 80.41551326412917],
  ["KZ", 85.73615916955018],
  ["IE", 85.27537485582468],
  ["GN", 90.58621683967705],
  ["FJ", 44.55161476355248],
  ["GY", 84.67762399077277],
  ["JM", 85.3820645905421],
  ["KI", 91.83650519031143],
  ["HR", 84.49077277970012],
  ["SO", 92.54181084198385],
  ["PT", 92.69896193771626],
  ["SY", 85.43598615916956],
  ["SN", 92.66003460207612],
  ["KH", 94.48529411764706],
  ["EC", 91.36476355247981],
  ["ET", 92.4224336793541],
  ["DE", 94.6199538638985],
  ["SL", 84.88869665513265],
  ["PW", 88.47895040369089],
  ["SZ", 94.49855824682814],
  ["PA", 75.02970011534026],
  ["SM", 0],
  ["IT", 85.42993079584775],
  ["JO", 89.98500576701268],
  ["GH", 92.18194925028835],
  ["CG", 89.11216839677047],
  ["BT", 86.9636678200692],
  ["AO", 94.3154555940023],
  ["ML", 90.23673587081892],
  ["TN", 94.71626297577855],
  ["LI", 94.09140715109572],
  ["MM", 92.72635524798154],
  ["MZ", 91.66407151095733],
  ["NA", 90.6098615916955],
  ["CF", 75.55911188004613],
  ["BB", 92.00836216839677],
  ["CD", 88.88898500576701],
  ["BW", 58.00086505190312],
  ["AL", 96.00951557093425],
  ["NC", 89.32324106113033],
  ["MX", 84.25576701268743],
  ["TZ", 87.83823529411765],
  ["VE", 95.16580161476355],
  ["MN", 92.14648212226066],
  ["MY", 82.48269896193771],
  ["AM", 95.21424452133795],
  ["CR", 78.54901960784314],
  ["BA", 89.95444059976931],
  ["AZ", 94.61735870818916],
  ["CV", 93.13033448673586],
  ["BR", 89.30997693194925],
  ["CA", 78.02739331026528],
  ["BE", 91.93137254901961],
  ["TH", 80.3327566320646],
  ["WS", 94.67156862745098],
  ["UZ", 83.60467128027682],
  ["Wales", 60.944348327566324],
  ["NG", 85.4515570934256],
  ["NP", 67.84602076124567],
  ["MK", 82.02681660899654],
  ["BD", 94.40282583621683],
  ["BS", 90.99452133794695],
  ["CU", 82.83246828143021],
  ["BF", 93.330738177624],
  ["NR", 93.42935409457901],
  ["NE", 83.82814302191464],
  ["ZW", 85.8538062283737],
  ["VC", 90.57122260668974],
  ["UY", 65.55622837370242],
  ["TJ", 79.45847750865052],
  ["VU", 89.49163783160323],
  ["ZA", 88.3933102652826],
  ["BG", 62.74106113033449],
]);

export const itMap = new Map<string, number>([
  ["CY", 75.18536553694325],
  ["AF", 96.30632385404493],
  ["CN", 97.12082846383586],
  ["BJ", 49.59810414231918],
  ["LA", 74.15011037527593],
  ["ME", 97.29093624204648],
  ["OM", 74.1549798727438],
  ["NI", 89.37897675626542],
  ["MR", 96.77866510842748],
  ["TG", 96.37547071808856],
  ["TP", 63.09862355538242],
  ["North Korea", 73.98909232567199],
  ["VN", 64.46922477600312],
  ["ZM", 95.22399688352162],
  ["MD", 93.32391897156214],
  ["CO", 89.47863913777432],
  ["AG", 65.44377353590443],
  ["AE", 85.73009998701467],
  ["CZ", 66.18166471886768],
  ["AR", 91.08265160368784],
  ["BI", 73.13790416828984],
  ["CM", 65.80022075055187],
  ["LB", 73.98195039605247],
  ["LU", 77.84962991819245],
  ["TD", 64.26048565121413],
  ["TR", 95.7034800675237],
  ["Scotland", 81.25633034670821],
  ["UA", 98.1616023893001],
  ["LT", 88.11680301259577],
  ["LC", 94.96039475392806],
  ["MG", 99.47896377093885],
  ["CL", 65.19867549668874],
  ["BH", 63.74366965329178],
  ["AD", 61.77444487728866],
  ["CH", 84.07609401376445],
  ["NO", 89.42702246461498],
  ["MT", 49.44487728866381],
  ["MC", 66.68939098818335],
  ["TV", 85.70607713283988],
  ["TW", 98.57486040773925],
  ["US", 60.91936112193221],
  ["MU", 81.70399948058694],
  ["BZ", 76.92117906765355],
  ["CI", 66.16056356317361],
  ["BM", 66.49526035579795],
  ["CK", 88.48850798597584],
  ["AT", 67.23380080509025],
  ["BO", 76.48195039605245],
  ["LS", 79.7600960914167],
  ["NL", 88.09115699259836],
  ["MW", 87.52272432151669],
  ["YE", 77.5727178288534],
  ["UG", 92.55194130632385],
  ["TT", 68.43559278015842],
  ["NZ", 89.2965199324763],
  ["MA", 66.61667315932995],
  ["MV", 64.65881054408518],
  ["LR", 82.19159849370212],
  ["BY", 71.1173873522919],
  ["BN", 90.35092845085055],
  ["AU", 92.98208024931827],
  ["GE", 79.3315803142449],
  ["GR", 84.86495260355798],
  ["IN", 90.65965459031294],
  ["GBENG", 76.2238670302558],
  ["RS", 71.90364887676925],
  ["PL", 66.76308271653032],
  ["SV", 90.35839501363459],
  ["SA", 91.9744189066355],
  ["HK", 90.81840020776522],
  ["KG", 59.12154265679782],
  ["GD", 65.94013764446176],
  ["GQ", 76.05116218672899],
  ["DJ", 88.81865991429684],
  ["KE", 94.7789248149591],
  ["KR", 79.1832229580574],
  ["SC", 71.0566809505259],
  ["ST", 80.54473445007142],
  ["PY", 79.57473055447345],
  ["SB", 88.6306973120374],
  ["IL", 80.89858459940268],
  ["DK", 58.782950266199194],
  ["DO", 69.64614985066875],
  ["RU", 77.06174522789249],
  ["SG", 63.26353720296065],
  ["PK", 93.79301389429943],
  ["GB", 70.80281781586807],
  ["GU", 92.17958706661472],
  ["GW", 96.47740553174913],
  ["JP", 83.44500714192962],
  ["SR", 91.43098298922217],
  ["SE", 96.9568887157512],
  ["SD", 74.6373847552266],
  ["SS", 76.7033502142579],
  ["PH", 80.07823659265031],
  ["RW", 94.09329957148422],
  ["KU", 70.19705233086611],
  ["HN", 89.58739124788988],
  ["GA", 87.17309440332424],
  ["DZ", 85.28989741592001],
  ["DM", 95.47039345539541],
  ["FR", 33.81995844695494],
  ["GL", 66.20016880924555],
  ["ES", 93.90631086871835],
  ["HT", 80.835605765485],
  ["PE", 33.08726139462407],
  ["QA", 98.09180625892742],
  ["SI", 75.0928450850539],
  ["PR", 81.0904427996364],
  ["PS", 78.96604337099077],
  ["IQ", 79.3504090377873],
  ["HU", 66.89910401246591],
  ["KN", 56.11706271912739],
  ["KY", 87.68179457213348],
  ["FI", 83.72159459810415],
  ["ER", 83.31158291131023],
  ["GM", 84.76074535774575],
  ["EE", 88.54921438774184],
  ["EG", 79.27996364108557],
  ["ID", 66.83677444487729],
  ["IS", 88.88618361251785],
  ["SK", 74.61790676535514],
  ["RO", 63.528437865212304],
  ["PG", 73.33885209713024],
  ["KM", 77.65549928580704],
  ["IR", 82.37534086482276],
  ["KZ", 93.30801194650046],
  ["IE", 33.08206726399169],
  ["GN", 96.610505129204],
  ["FJ", 87.96649785742112],
  ["GY", 84.65491494611089],
  ["JM", 88.88196338137904],
  ["KI", 67.38150889494871],
  ["HR", 91.30827165303207],
  ["SO", 95.20873912478899],
  ["PT", 93.7978833917673],
  ["SY", 76.35404492922997],
  ["SN", 29.18939098818335],
  ["KH", 91.72867160109077],
  ["EC", 86.19107908063887],
  ["ET", 75.39962342552914],
  ["DE", 98.09537722373717],
  ["SL", 88.63946240747956],
  ["PW", 96.26996493961823],
  ["SZ", 85.23178807947019],
  ["PA", 64.87241916634203],
  ["SM", 85.69276717309441],
  ["IT", 0],
  ["JO", 83.3518374237112],
  ["GH", 86.00798597584729],
  ["CG", 46.572523048954686],
  ["BT", 66.15179846773145],
  ["AO", 79.5714842228282],
  ["ML", 63.3813790416829],
  ["TN", 90.15387611998442],
  ["LI", 80.7560706401766],
  ["MM", 76.52317880794702],
  ["MZ", 91.25211011556941],
  ["NA", 80.53272302298403],
  ["CF", 93.35897935333074],
  ["BB", 94.20821971172575],
  ["CD", 88.11128424879885],
  ["BW", 97.24353980002597],
  ["AL", 66.19075444747435],
  ["NC", 76.06771847811973],
  ["MX", 37.36625113621608],
  ["TZ", 93.58946890014283],
  ["VE", 85.24022854174783],
  ["MN", 61.947474353980006],
  ["MY", 66.1251785482405],
  ["AM", 99.01863394364369],
  ["CR", 74.76561485521361],
  ["BA", 91.46117387352291],
  ["AZ", 86.52739903908582],
  ["CV", 89.38157382158161],
  ["BR", 66.2183482664589],
  ["CA", 78.31288144396832],
  ["BE", 64.4085183742371],
  ["TH", 78.10966108297623],
  ["WS", 63.618036618620955],
  ["UZ", 85.02337358784573],
  ["Wales", 70.52460719387092],
  ["NG", 33.08206726399169],
  ["NP", 76.17452278924814],
  ["MK", 84.29165043500844],
  ["BD", 97.87689910401247],
  ["BS", 96.30470068822231],
  ["CU", 81.1144656538112],
  ["BF", 81.33326840670043],
  ["NR", 96.57771717958707],
  ["NE", 83.10544085183741],
  ["ZW", 94.51110245422673],
  ["VC", 93.39631216725101],
  ["UY", 78.41351772497079],
  ["TJ", 85.76580963511232],
  ["VU", 78.45799246851058],
  ["ZA", 68.44500714192961],
  ["BG", 88.18497597714583],
]);

export const joMap = new Map<string, number>([
  ["CY", 81.57223183391004],
  ["AF", 87.11548442906575],
  ["CN", 75.52681660899654],
  ["BJ", 87.26340830449827],
  ["LA", 82.38019031141869],
  ["ME", 71.50346020761246],
  ["OM", 75.01686851211072],
  ["NI", 77.35942906574394],
  ["MR", 65.60510380622837],
  ["TG", 66.5705017301038],
  ["TP", 97.97794117647058],
  ["North Korea", 91.77595155709342],
  ["VN", 72.7772491349481],
  ["ZM", 94.36072664359861],
  ["MD", 91.86764705882354],
  ["CO", 92.92257785467129],
  ["AG", 56.911332179930795],
  ["AE", 60.97794117647059],
  ["CZ", 85.27941176470588],
  ["AR", 81.97707612456747],
  ["BI", 86.36072664359861],
  ["CM", 91.87110726643598],
  ["LB", 76.39532871972318],
  ["LU", 72.79930795847751],
  ["TD", 93.46929065743944],
  ["TR", 75.69290657439446],
  ["Scotland", 86.2772491349481],
  ["UA", 96.52897923875432],
  ["LT", 90.93598615916954],
  ["LC", 97.33996539792388],
  ["MG", 76.27681660899654],
  ["CL", 73.77162629757785],
  ["BH", 95.14749134948097],
  ["AD", 92.2629757785467],
  ["CH", 85.35164359861591],
  ["NO", 86.26643598615917],
  ["MT", 96.42776816608996],
  ["MC", 77.8295847750865],
  ["TV", 83.69896193771626],
  ["TW", 90.94117647058823],
  ["US", 76.49394463667821],
  ["MU", 90.90311418685121],
  ["BZ", 83.91911764705883],
  ["CI", 91.39100346020761],
  ["BM", 73.6712802768166],
  ["CK", 84.19290657439447],
  ["AT", 68.0454152249135],
  ["BO", 63.16998269896194],
  ["LS", 55.22058823529412],
  ["NL", 76.40873702422145],
  ["MW", 60.94506920415225],
  ["YE", 74.24480968858131],
  ["UG", 78.26340830449827],
  ["TT", 68.55449826989619],
  ["NZ", 85.70717993079585],
  ["MA", 73.93079584775086],
  ["MV", 77.30968858131489],
  ["LR", 80.57915224913495],
  ["BY", 90.05320069204153],
  ["BN", 85.4848615916955],
  ["AU", 87.8871107266436],
  ["GE", 88.39186851211073],
  ["GR", 82.57266435986159],
  ["IN", 79.1613321799308],
  ["GBENG", 84.60769896193771],
  ["RS", 93.07525951557093],
  ["PL", 76.59688581314879],
  ["SV", 77.64532871972318],
  ["SA", 65.74610726643598],
  ["HK", 70.05276816608996],
  ["KG", 69.41955017301038],
  ["GD", 82.13365051903114],
  ["GQ", 73.59515570934256],
  ["DJ", 94.7067474048443],
  ["KE", 66.83261245674741],
  ["KR", 82.71107266435986],
  ["SC", 64.4424740484429],
  ["ST", 79.92949826989619],
  ["PY", 73.36159169550173],
  ["SB", 89.54974048442907],
  ["IL", 80],
  ["DK", 72.67690311418686],
  ["DO", 76.28849480968857],
  ["RU", 91.06314878892734],
  ["SG", 80.14662629757785],
  ["PK", 93.47923875432525],
  ["GB", 72.14316608996539],
  ["GU", 88.0817474048443],
  ["GW", 76.2984429065744],
  ["JP", 84.6332179930796],
  ["SR", 81.14965397923875],
  ["SE", 93.77335640138408],
  ["SD", 75.66262975778547],
  ["SS", 40.06531141868513],
  ["PH", 94.1734429065744],
  ["RW", 93.4909169550173],
  ["KU", 76.4325259515571],
  ["HN", 76.82396193771626],
  ["GA", 93.81833910034601],
  ["DZ", 67.30060553633218],
  ["DM", 66.7166955017301],
  ["FR", 87.0151384083045],
  ["GL", 76.38581314878893],
  ["ES", 86.70804498269896],
  ["HT", 82.40570934256056],
  ["PE", 68.71885813148789],
  ["QA", 95.79498269896195],
  ["SI", 89.40657439446366],
  ["PR", 84.96150519031141],
  ["PS", 7.163494809688581],
  ["IQ", 74.840830449827],
  ["HU", 45.63970588235294],
  ["KN", 83.85726643598616],
  ["KY", 85.33044982698962],
  ["FI", 94.4121972318339],
  ["ER", 75.6734429065744],
  ["GM", 83.6371107266436],
  ["EE", 98.84948096885813],
  ["EG", 75.48269896193771],
  ["ID", 77.82915224913495],
  ["IS", 82.48140138408304],
  ["SK", 91.74610726643598],
  ["RO", 93.48659169550173],
  ["PG", 96.96756055363322],
  ["KM", 81.23832179930795],
  ["IR", 72.97015570934256],
  ["KZ", 88.96626297577855],
  ["IE", 87.84429065743944],
  ["GN", 75.12326989619376],
  ["FJ", 88.29411764705883],
  ["GY", 72.84948096885813],
  ["JM", 91.61937716262976],
  ["KI", 74.84861591695503],
  ["HR", 77.25692041522491],
  ["SO", 91.34688581314879],
  ["PT", 89.52465397923875],
  ["SY", 74.48615916955018],
  ["SN", 88.9779411764706],
  ["KH", 74.54022491349481],
  ["EC", 89.11980968858131],
  ["ET", 84.58823529411764],
  ["DE", 69.85467128027682],
  ["SL", 76.72621107266437],
  ["PW", 97.8174740484429],
  ["SZ", 73.44766435986159],
  ["PA", 85.5696366782007],
  ["SM", 90.0090830449827],
  ["IT", 83.45112456747404],
  ["JO", 0],
  ["GH", 62.29757785467128],
  ["CG", 94.18944636678201],
  ["BT", 92.24956747404845],
  ["AO", 85.04411764705883],
  ["ML", 94.41782006920415],
  ["TN", 69.42647058823529],
  ["LI", 82.11894463667821],
  ["MM", 84.02638408304497],
  ["MZ", 79.90657439446368],
  ["NA", 61.60423875432526],
  ["CF", 87.67041522491348],
  ["BB", 92.22058823529412],
  ["CD", 86.89403114186851],
  ["BW", 94.59342560553632],
  ["AL", 71.72404844290658],
  ["NC", 67.60467128027682],
  ["MX", 86.42906574394463],
  ["TZ", 84.41479238754324],
  ["VE", 89.18858131487889],
  ["MN", 81.11721453287197],
  ["MY", 77.09212802768167],
  ["AM", 92.05968858131487],
  ["CR", 86.00259515570934],
  ["BA", 87.11980968858131],
  ["AZ", 82.12283737024222],
  ["CV", 84.69377162629758],
  ["BR", 89.52378892733563],
  ["CA", 90.57785467128028],
  ["BE", 92.54887543252596],
  ["TH", 94.76038062283737],
  ["WS", 81.63321799307958],
  ["UZ", 75.40787197231835],
  ["Wales", 77.15787197231833],
  ["NG", 72.42171280276817],
  ["NP", 67.54801038062284],
  ["MK", 84.70631487889273],
  ["BD", 70.24264705882352],
  ["BS", 94.39878892733564],
  ["CU", 67.87889273356402],
  ["BF", 93.49740484429066],
  ["NR", 92.28200692041523],
  ["NE", 80.59212802768167],
  ["ZW", 92.63062283737025],
  ["VC", 92.62846020761246],
  ["UY", 89.47361591695501],
  ["TJ", 76.63538062283737],
  ["VU", 70.93555363321799],
  ["ZA", 82.95501730103805],
  ["BG", 90.88970588235294],
]);

export const ghMap = new Map<string, number>([
  ["CY", 93.39952060119202],
  ["AF", 92.80383519046384],
  ["CN", 67.7141098730241],
  ["BJ", 76.87613371339725],
  ["LA", 70.15904379372896],
  ["ME", 68.69299041202383],
  ["OM", 91.88034464887276],
  ["NI", 93.17342575796839],
  ["MR", 60.69707178025395],
  ["TG", 57.577416429126714],
  ["TP", 65.87911376004146],
  ["North Korea", 92.54437678154962],
  ["VN", 63.86887794765484],
  ["ZM", 95.4683855921223],
  ["MD", 80.38449080072557],
  ["CO", 82.50194350868101],
  ["AG", 89.82378854625551],
  ["AE", 86.3601321585903],
  ["CZ", 95.27662606892977],
  ["AR", 94.03990671158331],
  ["BI", 76.26263280642654],
  ["CM", 65.95782586162218],
  ["LB", 70.45510494946878],
  ["LU", 63.119655351127236],
  ["TD", 79.5024617776626],
  ["TR", 64.49080072557656],
  ["Scotland", 90.31841150557139],
  ["UA", 84.1749805649132],
  ["LT", 99.13740606374708],
  ["LC", 94.83156258097954],
  ["MG", 76.77993003368748],
  ["CL", 96.81782845296709],
  ["BH", 74.71689556880021],
  ["AD", 80.56458927183208],
  ["CH", 94.74280901788028],
  ["NO", 88.74416947395699],
  ["MT", 81.4320419797875],
  ["MC", 62.88902565431459],
  ["TV", 83.15917336097435],
  ["TW", 94.88662866027468],
  ["US", 73.80668566986266],
  ["MU", 57.9975382223374],
  ["BZ", 79.41953874060637],
  ["CI", 96.69247214304224],
  ["BM", 69.36900751490023],
  ["CK", 82.05655610261726],
  ["AT", 59.925822752008294],
  ["BO", 2.619201865768334],
  ["LS", 96.63449080072559],
  ["NL", 92.27584866545737],
  ["MW", 93.21132417724799],
  ["YE", 62.69111168696554],
  ["UG", 95.46093547551179],
  ["TT", 70.43631769888572],
  ["NZ", 83.22719616480954],
  ["MA", 65.11272350349832],
  ["MV", 64.7836227001814],
  ["LR", 77.48671935734647],
  ["BY", 92.40865509199274],
  ["BN", 86.49261466701218],
  ["AU", 86.75174915781291],
  ["GE", 93.75809795283753],
  ["GR", 89.79690334283494],
  ["IN", 91.91565172324437],
  ["GBENG", 92.01282715729464],
  ["RS", 89.30908266390256],
  ["PL", 97.7882871210158],
  ["SV", 90.02623736719357],
  ["SA", 60.946812645763146],
  ["HK", 61.8913578647318],
  ["KG", 60.701606633842964],
  ["GD", 70.86972013474994],
  ["GQ", 89.9306815237108],
  ["DJ", 94.82799948173101],
  ["KE", 92.2155998963462],
  ["KR", 93.94240735941955],
  ["SC", 61.56646799689038],
  ["ST", 70.76315107540813],
  ["PY", 61.71903342834931],
  ["SB", 88.87341280124384],
  ["IL", 96.05791655869396],
  ["DK", 66.68696553511272],
  ["DO", 80.76606633842964],
  ["RU", 97.63474993521638],
  ["SG", 64.30584348276756],
  ["PK", 94.57890645244882],
  ["GB", 74.62814200570097],
  ["GU", 87.84464887276496],
  ["GW", 77.84270536408397],
  ["JP", 92.97000518268982],
  ["SR", 96.11265871987561],
  ["SE", 79.16169992225966],
  ["SD", 60.23030577869915],
  ["SS", 67.86181653278052],
  ["PH", 88.45296708991967],
  ["RW", 79.3592899714952],
  ["KU", 92.38533298782068],
  ["HN", 90.55713915522155],
  ["GA", 68.09179839336615],
  ["DZ", 78.79664420834413],
  ["DM", 59.75608966053382],
  ["FR", 86.00058305260431],
  ["GL", 91.26813941435606],
  ["ES", 46.64647577092511],
  ["HT", 96.8962166364343],
  ["PE", 75.17200051826897],
  ["QA", 94.3631769888572],
  ["SI", 97.18320808499611],
  ["PR", 90.73432236330655],
  ["PS", 62.16506867064007],
  ["IQ", 59.26956465405545],
  ["HU", 26.969098211972014],
  ["KN", 89.04735682819384],
  ["KY", 82.80739828971237],
  ["FI", 94.81180357605598],
  ["ER", 78.54366416169992],
  ["GM", 58.16111686965535],
  ["EE", 96.75563617517491],
  ["EG", 61.223114796579424],
  ["ID", 63.28258616221819],
  ["IS", 89.05383519046384],
  ["SK", 95.40295413319512],
  ["RO", 79.55331692148225],
  ["PG", 69.04379372894532],
  ["KM", 91.27170251360455],
  ["IR", 87.03161440787768],
  ["KZ", 89.2501295672454],
  ["IE", 96.7468903861104],
  ["GN", 79.41046903342836],
  ["FJ", 80.93158849442861],
  ["GY", 79.57437159885981],
  ["JM", 87.10352422907489],
  ["KI", 57.072752008292305],
  ["HR", 87.0034335320031],
  ["SO", 96.66267167660016],
  ["PT", 92.6402565431459],
  ["SY", 60.343029282197456],
  ["SN", 75.44376781549624],
  ["KH", 86.09289971495207],
  ["EC", 80.72071780253953],
  ["ET", 69.00816273646022],
  ["DE", 99.11149261466701],
  ["SL", 92.7134620367971],
  ["PW", 89.12185799429903],
  ["SZ", 85.98082404768076],
  ["PA", 78.51483544959834],
  ["SM", 91.6448561803576],
  ["IT", 85.83603265094584],
  ["JO", 62.24054159108577],
  ["GH", 0],
  ["CG", 90.56070225447007],
  ["BT", 78.77688520342058],
  ["AO", 64.1098730240995],
  ["ML", 79.31232184503757],
  ["TN", 63.32858253433532],
  ["LI", 95.891746566468],
  ["MM", 95.2111946100026],
  ["MZ", 87.85436641617],
  ["NA", 80.92478621404508],
  ["CF", 90.00712619849702],
  ["BB", 91.08998445193055],
  ["CD", 85.9989634620368],
  ["BW", 92.16409691629957],
  ["AL", 65.86874838040944],
  ["NC", 88.59387146929257],
  ["MX", 72.49481731018399],
  ["TZ", 89.30713915522156],
  ["VE", 96.2163125161959],
  ["MN", 71.47058823529412],
  ["MY", 79.45452189686448],
  ["AM", 64.822816791915],
  ["CR", 96.65457372376264],
  ["BA", 84.20316144078777],
  ["AZ", 97.66908525524748],
  ["CV", 91.15250064783623],
  ["BR", 81.75855143819642],
  ["CA", 93.37587457890645],
  ["BE", 86.76891681782845],
  ["TH", 82.00991189427313],
  ["WS", 79.47978750971755],
  ["UZ", 89.31880020730759],
  ["Wales", 82.98814459704586],
  ["NG", 75.17167660015548],
  ["NP", 81.65748898678414],
  ["MK", 77.05104949468775],
  ["BD", 66.9282845296709],
  ["BS", 94.2481860585644],
  ["CU", 86.37082145633583],
  ["BF", 97.71151852811609],
  ["NR", 89.19862658719876],
  ["NE", 93.79340502720912],
  ["ZW", 87.33739310702255],
  ["VC", 87.02740347240217],
  ["UY", 91.02293340243587],
  ["TJ", 94.286732314071],
  ["VU", 63.8555973050013],
  ["ZA", 82.65224151334543],
  ["BG", 98.9971495206012],
]);

export const cgMap = new Map<string, number>([
  ["CY", 89.75814829242955],
  ["AF", 91.27450980392156],
  ["CN", 61.50305155174652],
  ["BJ", 44.88572912608752],
  ["LA", 77.84508505388911],
  ["ME", 86.76048565121413],
  ["OM", 81.70854434489027],
  ["NI", 90.07856122581482],
  ["MR", 93.09342942475003],
  ["TG", 92.5551876379691],
  ["TP", 61.8692377613297],
  ["North Korea", 72.2600960914167],
  ["VN", 62.767173094403326],
  ["ZM", 88.23009998701468],
  ["MD", 69.27152317880795],
  ["CO", 80.96740683028179],
  ["AG", 66.87443189196209],
  ["AE", 85.41455655109726],
  ["CZ", 70.69081937410726],
  ["AR", 90.41617971691987],
  ["BI", 76.36962732112713],
  ["CM", 63.68198935203221],
  ["LB", 75.97292559407869],
  ["LU", 91.72964550058434],
  ["TD", 71.56083625503182],
  ["TR", 61.31281651733541],
  ["Scotland", 86.6861446565381],
  ["UA", 95.34735748604078],
  ["LT", 77.30944033242436],
  ["LC", 91.6293338527464],
  ["MG", 95.12595766783535],
  ["CL", 70.06460199974029],
  ["BH", 61.35501882872354],
  ["AD", 66.75983638488509],
  ["CH", 92.64511102454227],
  ["NO", 68.20283080119465],
  ["MT", 64.96299181924425],
  ["MC", 87.54966887417218],
  ["TV", 82.34028048305416],
  ["TW", 94.89741592001039],
  ["US", 76.54395533047656],
  ["MU", 91.49298792364628],
  ["BZ", 78.70633683937152],
  ["CI", 93.97773016491364],
  ["BM", 64.55200623295676],
  ["CK", 86.5121412803532],
  ["AT", 73.54531878976756],
  ["BO", 89.1617971691988],
  ["LS", 91.35404492922997],
  ["NL", 94.11472536034282],
  ["MW", 83.77937930138944],
  ["YE", 93.26840670042851],
  ["UG", 92.56168030125957],
  ["TT", 72.98208024931827],
  ["NZ", 87.23931956888715],
  ["MA", 64.65621347876899],
  ["MV", 63.009024801973766],
  ["LR", 73.18270354499415],
  ["BY", 89.39358524866901],
  ["BN", 90.40222049084534],
  ["AU", 92.58667705492793],
  ["GE", 88.59239059862357],
  ["GR", 89.2948967666537],
  ["IN", 90.86579664978575],
  ["GBENG", 85.57200363589145],
  ["RS", 82.96812102324374],
  ["PL", 71.51116738085963],
  ["SV", 90.63822880145436],
  ["SA", 88.69400077911959],
  ["HK", 58.91182963251526],
  ["KG", 57.88144396831581],
  ["GD", 64.84385144786391],
  ["GQ", 71.48422282820412],
  ["DJ", 91.98610570055837],
  ["KE", 91.99259836384886],
  ["KR", 93.05414881184262],
  ["SC", 86.84521490715491],
  ["ST", 78.02071159589664],
  ["PY", 89.83930658356057],
  ["SB", 84.06505648617063],
  ["IL", 91.20016880924555],
  ["DK", 68.18497597714583],
  ["DO", 87.74347487339307],
  ["RU", 76.8854694195559],
  ["SG", 83.55862874951305],
  ["PK", 92.34157901571226],
  ["GB", 73.30703804700688],
  ["GU", 86.1738735229191],
  ["GW", 93.57940527204259],
  ["JP", 93.7816517335411],
  ["SR", 91.98026230359694],
  ["SE", 94.80911569925983],
  ["SD", 89.5958317101675],
  ["SS", 82.68796260225945],
  ["PH", 69.27801584209843],
  ["RW", 92.51785482404883],
  ["KU", 63.991689390988185],
  ["HN", 92.15264251395922],
  ["GA", 77.93955330476562],
  ["DZ", 92.43247630177899],
  ["DM", 94.00889494870796],
  ["FR", 70.64796779639008],
  ["GL", 68.98584599402675],
  ["ES", 75.41715361641346],
  ["HT", 69.54389040384366],
  ["PE", 70.43046357615894],
  ["QA", 92.94572133489157],
  ["SI", 74.79872743799505],
  ["PR", 88.45994026749773],
  ["PS", 94.70815478509284],
  ["IQ", 90.49116997792494],
  ["HU", 88.504090377873],
  ["KN", 40.89046877028957],
  ["KY", 86.36378392416569],
  ["FI", 94.08128814439684],
  ["ER", 76.16056356317361],
  ["GM", 90.40416828983248],
  ["EE", 96.75399298792364],
  ["EG", 89.53707310738865],
  ["ID", 87.75548630048046],
  ["IS", 88.450201272562],
  ["SK", 74.04914946110894],
  ["RO", 71.37125048694975],
  ["PG", 76.82086741981561],
  ["KM", 79.80846643293079],
  ["IR", 84.98539150759642],
  ["KZ", 87.40325931697183],
  ["IE", 70.2373068432671],
  ["GN", 95.0201272562005],
  ["FJ", 84.50753148941695],
  ["GY", 79.52084144916245],
  ["JM", 81.62738605375925],
  ["KI", 75.55577197766524],
  ["HR", 89.13452798337879],
  ["SO", 93.106414751331],
  ["PT", 89.53025581093364],
  ["SY", 91.67997662641216],
  ["SN", 35.45546032982729],
  ["KH", 90.2613296974419],
  ["EC", 76.88871575120115],
  ["ET", 58.28496299181924],
  ["DE", 96.19237761329697],
  ["SL", 94.3517075704454],
  ["PW", 92.48928710557071],
  ["SZ", 85.54473445007143],
  ["PA", 85.90248019737696],
  ["SM", 89.25496688741723],
  ["IT", 46.572523048954686],
  ["JO", 94.31956888715752],
  ["GH", 90.84469549409168],
  ["CG", 0],
  ["BT", 55.18049603947539],
  ["AO", 87.4217634073497],
  ["ML", 71.20309050772627],
  ["TN", 90.67426308271654],
  ["LI", 69.50785612258147],
  ["MM", 75.52687962602259],
  ["MZ", 90.67815868069083],
  ["NA", 85.13894299441631],
  ["CF", 89.12446435527853],
  ["BB", 93.26808206726399],
  ["CD", 88.47325022724321],
  ["BW", 94.110505129204],
  ["AL", 63.9407219841579],
  ["NC", 78.32197117257499],
  ["MX", 66.22581482924296],
  ["TZ", 91.22549019607843],
  ["VE", 75.60511621867289],
  ["MN", 68.0405142189326],
  ["MY", 70.70023373587846],
  ["AM", 95.02337358784574],
  ["CR", 81.06090118166472],
  ["BA", 88.26451110245422],
  ["AZ", 92.89053369692247],
  ["CV", 87.338332684067],
  ["BR", 66.1537462667186],
  ["CA", 90.42948967666537],
  ["BE", 58.29729905207116],
  ["TH", 83.54824048824828],
  ["WS", 61.45922607453578],
  ["UZ", 90.08700168809246],
  ["Wales", 78.5956369302688],
  ["NG", 70.26197896377094],
  ["NP", 78.40410336319958],
  ["MK", 81.45500584339696],
  ["BD", 92.55096740683028],
  ["BS", 86.75918711855604],
  ["CU", 89.34553954031944],
  ["BF", 79.74678613167121],
  ["NR", 93.38365147383458],
  ["NE", 90.60154525386314],
  ["ZW", 84.82145175951176],
  ["VC", 92.61946500454486],
  ["UY", 89.2371120633684],
  ["TJ", 87.83274899363718],
  ["VU", 88.77386053759253],
  ["ZA", 79.42961952993117],
  ["BG", 76.95104531878977],
]);

export const btMap = new Map<string, number>([
  ["CY", 87.2721075185041],
  ["AF", 91.48130112972342],
  ["CN", 56.05895338267758],
  ["BJ", 78.34794182573692],
  ["LA", 93.65017530190885],
  ["ME", 82.73763147643163],
  ["OM", 90.98883261914037],
  ["NI", 90.3382677574341],
  ["MR", 92.71653032073756],
  ["TG", 88.81508894948708],
  ["TP", 50.09446825087651],
  ["North Korea", 92.42663290481755],
  ["VN", 55.92228282041294],
  ["ZM", 88.32716530320738],
  ["MD", 88.1641994546163],
  ["CO", 58.02655499285807],
  ["AG", 88.47876899104013],
  ["AE", 91.57901571224517],
  ["CZ", 90.78236592650305],
  ["AR", 87.26756265420074],
  ["BI", 83.15575899233866],
  ["CM", 88.56999091027139],
  ["LB", 88.8585897935333],
  ["LU", 85.32106219971432],
  ["TD", 88.389494870796],
  ["TR", 93.79171536164135],
  ["Scotland", 85.62037397740554],
  ["UA", 91.3004804570835],
  ["LT", 69.70360992078952],
  ["LC", 90.9420854434489],
  ["MG", 85.34573432021816],
  ["CL", 61.18296325152578],
  ["BH", 93.62647708089858],
  ["AD", 87.50551876379691],
  ["CH", 89.79126087521101],
  ["NO", 67.09745487599012],
  ["MT", 89.95877158810545],
  ["MC", 91.7897026360213],
  ["TV", 81.44526684846124],
  ["TW", 94.93474873393066],
  ["US", 88.64465653811193],
  ["MU", 89.82502272432151],
  ["BZ", 85.35742111414102],
  ["CI", 87.78243085313595],
  ["BM", 90.26392676275809],
  ["CK", 95.646669263732],
  ["AT", 62.80807687313336],
  ["BO", 85.81158291131023],
  ["LS", 91.23880015582392],
  ["NL", 90.63173613816387],
  ["MW", 95.29346838072978],
  ["YE", 92.0727827554863],
  ["UG", 88.46188806648487],
  ["TT", 92.9622776262823],
  ["NZ", 96.72023113881313],
  ["MA", 94.57602908713154],
  ["MV", 94.08972860667446],
  ["LR", 89.69062459420854],
  ["BY", 77.3691728346968],
  ["BN", 84.9016361511492],
  ["AU", 96.72834696792624],
  ["GE", 89.05596675756395],
  ["GR", 91.08330087001688],
  ["IN", 65.88040514218932],
  ["GBENG", 90.77976886118687],
  ["RS", 85.35125308401507],
  ["PL", 90.55609661082975],
  ["SV", 90.78463835865472],
  ["SA", 92.21919231268666],
  ["HK", 55.9784443578756],
  ["KG", 87.29872743799505],
  ["GD", 74.82956758862485],
  ["GQ", 66.44981171276457],
  ["DJ", 87.3396312167251],
  ["KE", 92.47013374886379],
  ["KR", 92.60485651214128],
  ["SC", 81.21607583430723],
  ["ST", 87.3883261914037],
  ["PY", 90.85930398649526],
  ["SB", 87.73763147643163],
  ["IL", 88.84365666796519],
  ["DK", 90.98915725230489],
  ["DO", 91.37287365277237],
  ["RU", 69.8652772367225],
  ["SG", 83.28333982599662],
  ["PK", 96.45695364238411],
  ["GB", 89.03551486819894],
  ["GU", 89.82761978963771],
  ["GW", 74.73737176990002],
  ["JP", 92.32307492533437],
  ["SR", 90.71679002726918],
  ["SE", 89.54161797169199],
  ["SD", 92.09453317750942],
  ["SS", 92.6645890144137],
  ["PH", 90.3382677574341],
  ["RW", 86.53032073756654],
  ["KU", 90.73269705233086],
  ["HN", 90.52038696273212],
  ["GA", 76.96825087650954],
  ["DZ", 90.79470198675497],
  ["DM", 89.3354759122192],
  ["FR", 66.60985586287495],
  ["GL", 89.0134398130113],
  ["ES", 85.14511102454226],
  ["HT", 94.79548110634983],
  ["PE", 88.8585897935333],
  ["QA", 94.13128165173354],
  ["SI", 92.35813530710297],
  ["PR", 88.58167770419426],
  ["PS", 91.4433190494741],
  ["IQ", 92.49967536683548],
  ["HU", 90.60836255031815],
  ["KN", 88.98584599402675],
  ["KY", 93.35897935333074],
  ["FI", 92.85871964679912],
  ["ER", 83.11550447993767],
  ["GM", 94.52311388131412],
  ["EE", 94.32281521880276],
  ["EG", 91.90624594208543],
  ["ID", 92.01402415270744],
  ["IS", 88.70796000519412],
  ["SK", 91.71406310868718],
  ["RO", 78.42682768471627],
  ["PG", 92.20653161927022],
  ["KM", 72.02506168030126],
  ["IR", 84.27282171146604],
  ["KZ", 78.93780028567718],
  ["IE", 87.78308011946501],
  ["GN", 77.7304895468121],
  ["FJ", 84.95909622126997],
  ["GY", 84.32768471627061],
  ["JM", 83.31775094143617],
  ["KI", 83.16225165562913],
  ["HR", 91.31249188417088],
  ["SO", 89.21308920919361],
  ["PT", 90.67588624853914],
  ["SY", 91.52642513959226],
  ["SN", 88.28269055966757],
  ["KH", 91.0566809505259],
  ["EC", 85.09511751720555],
  ["ET", 89.90098688482016],
  ["DE", 94.19620828463836],
  ["SL", 89.16504350084405],
  ["PW", 84.29327360083107],
  ["SZ", 86.95461628359953],
  ["PA", 92.86975717439293],
  ["SM", 87.01727048435268],
  ["IT", 66.15179846773145],
  ["JO", 92.49577976886118],
  ["GH", 79.17348396312167],
  ["CG", 55.18049603947539],
  ["BT", 0],
  ["AO", 95.61160888196338],
  ["ML", 76.96045968056097],
  ["TN", 91.15179846773145],
  ["LI", 94.54096870536294],
  ["MM", 64.58674198156083],
  ["MZ", 93.43786521231009],
  ["NA", 89.6617322425659],
  ["CF", 91.23295675886249],
  ["BB", 82.06174522789247],
  ["CD", 88.81249188417088],
  ["BW", 91.4231917932736],
  ["AL", 94.34131930918063],
  ["NC", 79.28872873652773],
  ["MX", 89.05564212439943],
  ["TZ", 87.49642903519023],
  ["VE", 93.15673289183223],
  ["MN", 65.09284508505388],
  ["MY", 70.74373457992469],
  ["AM", 94.76366705622647],
  ["CR", 90.107778210622],
  ["BA", 89.50363589144267],
  ["AZ", 92.98532658096352],
  ["CV", 88.65017530190885],
  ["BR", 88.89040384365667],
  ["CA", 89.90520711595896],
  ["BE", 66.01869887027658],
  ["TH", 92.53960524607194],
  ["WS", 95.04187767822361],
  ["UZ", 88.19601350473964],
  ["Wales", 88.73393065835606],
  ["NG", 88.73425529152058],
  ["NP", 86.5403843656668],
  ["MK", 80.81353071029737],
  ["BD", 91.0729126087521],
  ["BS", 86.91079080638879],
  ["CU", 91.41312816517335],
  ["BF", 85.36001817945721],
  ["NR", 93.39339046877029],
  ["NE", 90.70412933385275],
  ["ZW", 79.83606025191533],
  ["VC", 70.13537202960654],
  ["UY", 86.4218932606155],
  ["TJ", 88.74756525126607],
  ["VU", 87.63569666277107],
  ["ZA", 83.94169588365148],
  ["BG", 69.20335021425788],
]);

export const aoMap = new Map<string, number>([
  ["CY", 93.83551438196423],
  ["AF", 73.26509458408914],
  ["CN", 53.94726613112205],
  ["BJ", 97.30856439492096],
  ["LA", 68.25764446747861],
  ["ME", 55.95167141746566],
  ["OM", 76.90884944286086],
  ["NI", 96.63449080072559],
  ["MR", 90.39744752526562],
  ["TG", 79.04087846592381],
  ["TP", 56.05888831303446],
  ["North Korea", 93.33830007774034],
  ["VN", 52.83557916558694],
  ["ZM", 86.90399067115833],
  ["MD", 75.62872505830526],
  ["CO", 93.27869914485618],
  ["AG", 82.76690852552476],
  ["AE", 60.861298263798915],
  ["CZ", 94.49436382482509],
  ["AR", 95.83441306037834],
  ["BI", 79.15295413319512],
  ["CM", 84.44933920704845],
  ["LB", 70.34724021767296],
  ["LU", 65.71845037574501],
  ["TD", 77.33577351645505],
  ["TR", 54.18761337133973],
  ["Scotland", 92.8187354236849],
  ["UA", 94.71333246955169],
  ["LT", 96.69538740606374],
  ["LC", 95.3945322622441],
  ["MG", 97.20199533557916],
  ["CL", 96.75596009328842],
  ["BH", 67.52137859549106],
  ["AD", 77.5706141487432],
  ["CH", 95.5305778699145],
  ["NO", 91.07897123607152],
  ["MT", 74.33726353977714],
  ["MC", 49.41014511531485],
  ["TV", 81.90139932625033],
  ["TW", 92.88416688261208],
  ["US", 72.33512568022805],
  ["MU", 69.68644726613113],
  ["BZ", 82.34192796061156],
  ["CI", 95.98503498315625],
  ["BM", 67.00764446747863],
  ["CK", 77.23244363824826],
  ["AT", 65.34691629955948],
  ["BO", 64.27701477066597],
  ["LS", 95.6407100285048],
  ["NL", 96.48030577869915],
  ["MW", 78.11350090697073],
  ["YE", 33.298458149779734],
  ["UG", 81.27623736719357],
  ["TT", 57.39537444933921],
  ["NZ", 77.05007774034725],
  ["MA", 51.08253433532003],
  ["MV", 62.97648354495984],
  ["LR", 77.24540036278829],
  ["BY", 94.10630992485099],
  ["BN", 84.50472920445712],
  ["AU", 80.73335060896605],
  ["GE", 93.50317439751231],
  ["GR", 95.30286343612335],
  ["IN", 96.15088105726872],
  ["GBENG", 84.75155480694481],
  ["RS", 88.13909043793728],
  ["PL", 98.22460481990153],
  ["SV", 96.34587976159627],
  ["SA", 91.68858512568023],
  ["HK", 53.1789323658979],
  ["KG", 52.87185799429904],
  ["GD", 74.35248769111169],
  ["GQ", 95.27824565949727],
  ["DJ", 97.04619072298523],
  ["KE", 88.69428608447785],
  ["KR", 88.38526820419797],
  ["SC", 74.93035760559731],
  ["ST", 88.0720393884426],
  ["PY", 62.798976418761335],
  ["SB", 90.8557916558694],
  ["IL", 93.27545996372118],
  ["DK", 61.98205493651206],
  ["DO", 79.4616480953615],
  ["RU", 93.31303446488728],
  ["SG", 53.16532780513086],
  ["PK", 91.20465146410987],
  ["GB", 74.63721171287898],
  ["GU", 83.45555843482767],
  ["GW", 81.02455299300337],
  ["JP", 88.13293599378078],
  ["SR", 78.81154444156518],
  ["SE", 95.09911894273128],
  ["SD", 39.28770406841151],
  ["SS", 87.53336356569059],
  ["PH", 95.77934698108318],
  ["RW", 90.69512827157294],
  ["KU", 87.57968385592122],
  ["HN", 96.96067634102099],
  ["GA", 96.18683596786732],
  ["DZ", 91.47155998963463],
  ["DM", 89.43217154703291],
  ["FR", 78.79826379891163],
  ["GL", 86.9655351127235],
  ["ES", 68.55986006737497],
  ["HT", 96.71352682041979],
  ["PE", 62.98069448043535],
  ["QA", 90.2876392847888],
  ["SI", 94.8565042757191],
  ["PR", 91.17323140710029],
  ["PS", 88.924591863177],
  ["IQ", 33.05260430163255],
  ["HU", 64.76094843223633],
  ["KN", 80.14803057786992],
  ["KY", 78.15042757190982],
  ["FI", 92.40573982897124],
  ["ER", 76.8939492096398],
  ["GM", 61.11103912930811],
  ["EE", 82.92789582793469],
  ["EG", 33.02280383519046],
  ["ID", 49.42213008551438],
  ["IS", 86.70218968644726],
  ["SK", 94.76645504016584],
  ["RO", 77.37723503498316],
  ["PG", 34.07359419538741],
  ["KM", 94.88209380668567],
  ["IR", 92.496112982638],
  ["KZ", 93.91584607411247],
  ["IE", 96.09808240476806],
  ["GN", 78.38040943249547],
  ["FJ", 80.81303446488728],
  ["GY", 81.15735941953875],
  ["JM", 78.10443119979269],
  ["KI", 46.65586939621664],
  ["HR", 91.02001813941436],
  ["SO", 96.69344389738275],
  ["PT", 91.66526302150815],
  ["SY", 32.52526561285307],
  ["SN", 79.2154703291008],
  ["KH", 73.79372894532263],
  ["EC", 93.56569059341798],
  ["ET", 92.36265871987561],
  ["DE", 94.70264317180617],
  ["SL", 96.79968903861104],
  ["PW", 95.45316144078777],
  ["SZ", 73.22395698367453],
  ["PA", 72.81582016066338],
  ["SM", 94.20802021249028],
  ["IT", 79.59672194869137],
  ["JO", 85.01425239699404],
  ["GH", 64.1098730240995],
  ["CG", 87.48477584866545],
  ["BT", 95.65237108059083],
  ["AO", 0],
  ["ML", 78.46851515936771],
  ["TN", 51.549300336874836],
  ["LI", 94.56400621922778],
  ["MM", 95.50336874838041],
  ["MZ", 78.62108059082664],
  ["NA", 79.89019175952319],
  ["CF", 89.99578906452449],
  ["BB", 92.25576574242031],
  ["CD", 87.29625550660793],
  ["BW", 89.01010624514123],
  ["AL", 55.00745011661052],
  ["NC", 92.56575537704069],
  ["MX", 78.26217932106763],
  ["TZ", 82.50745011661053],
  ["VE", 90.75343353200311],
  ["MN", 63.93690075149002],
  ["MY", 77.26418761337133],
  ["AM", 59.495335579165584],
  ["CR", 83.7490282456595],
  ["BA", 86.47641876133714],
  ["AZ", 83.89835449598341],
  ["CV", 92.68463332469553],
  ["BR", 91.43430940658202],
  ["CA", 95.0356309924851],
  ["BE", 62.92465664679969],
  ["TH", 76.73166623477586],
  ["WS", 72.21171287898419],
  ["UZ", 92.7118424462296],
  ["Wales", 84.7829748639544],
  ["NG", 96.05240995076446],
  ["NP", 77.61596268463332],
  ["MK", 83.33117387924332],
  ["BD", 94.94363824825083],
  ["BS", 85.13960870691889],
  ["CU", 81.9263410209899],
  ["BF", 96.22473438714692],
  ["NR", 92.66940917336098],
  ["NE", 96.61861881316403],
  ["ZW", 82.3587717025136],
  ["VC", 94.87367193573465],
  ["UY", 93.22622441046904],
  ["TJ", 94.60125680228039],
  ["VU", 55.33363565690593],
  ["ZA", 87.85177507126198],
  ["BG", 97.40476807463074],
]);

export const mlMap = new Map<string, number>([
  ["CY", 93.48211972013475],
  ["AF", 94.86168696553511],
  ["CN", 63.62788287121016],
  ["BJ", 69.25822752008293],
  ["LA", 80.75537704068412],
  ["ME", 64.9300336874838],
  ["OM", 84.23037056232184],
  ["NI", 93.13390774812127],
  ["MR", 93.60067374967608],
  ["TG", 84.1111686965535],
  ["TP", 61.23380409432495],
  ["North Korea", 92.80869396216637],
  ["VN", 63.86758227520083],
  ["ZM", 89.94104690334284],
  ["MD", 38.494752526561285],
  ["CO", 74.23263798911636],
  ["AG", 67.56543145892718],
  ["AE", 95.23937548587718],
  ["CZ", 79.95724280901788],
  ["AR", 94.0369914485618],
  ["BI", 67.76172583570873],
  ["CM", 98.07333506089661],
  ["LB", 77.72317958020213],
  ["LU", 87.93340243586421],
  ["TD", 33.38235294117647],
  ["TR", 62.580979528375224],
  ["Scotland", 90.65431458927183],
  ["UA", 82.50161959056751],
  ["LT", 76.65036278828711],
  ["LC", 87.03031873542369],
  ["MG", 99.18858512568023],
  ["CL", 81.37665198237886],
  ["BH", 63.5378336356569],
  ["AD", 37.70374449339207],
  ["CH", 96.09387146929255],
  ["NO", 92.1812645763151],
  ["MT", 64.81083182171548],
  ["MC", 81.77539518009847],
  ["TV", 86.66785436641618],
  ["TW", 98.05292821974605],
  ["US", 65.13086291785437],
  ["MU", 74.30033687483804],
  ["BZ", 79.84030837004406],
  ["CI", 96.16254210935476],
  ["BM", 67.83655091992745],
  ["CK", 90.06348795024618],
  ["AT", 76.15897901010624],
  ["BO", 76.76924073594196],
  ["LS", 97.00408136823012],
  ["NL", 97.27941176470588],
  ["MW", 76.4249157812905],
  ["YE", 87.85047939880798],
  ["UG", 84.23458149779735],
  ["TT", 69.71948691370822],
  ["NZ", 91.07832339984452],
  ["MA", 66.17679450634878],
  ["MV", 72.43683596786732],
  ["LR", 74.00459963721171],
  ["BY", 91.82786991448562],
  ["BN", 78.07495465146411],
  ["AU", 89.94331433013734],
  ["GE", 92.06756931847629],
  ["GR", 91.98140710028505],
  ["IN", 94.4988986784141],
  ["GBENG", 90.55130862917854],
  ["RS", 89.50408136823012],
  ["PL", 82.57255765742421],
  ["SV", 93.59905415910858],
  ["SA", 91.15541591085774],
  ["HK", 58.88896087069189],
  ["KG", 57.22402176729723],
  ["GD", 60.58434827675564],
  ["GQ", 81.39446747862141],
  ["DJ", 90.84380668566986],
  ["KE", 95.05927701477067],
  ["KR", 96.75887535630993],
  ["SC", 77.99397512308889],
  ["ST", 69.38941435605079],
  ["PY", 85.33233998445193],
  ["SB", 87.1077351645504],
  ["IL", 94.35961388960871],
  ["DK", 69.23393366157036],
  ["DO", 81.95808499611297],
  ["RU", 87.45691889090438],
  ["SG", 78.09762891940917],
  ["PK", 96.19784918372636],
  ["GB", 75.86421352682042],
  ["GU", 90.00777403472402],
  ["GW", 80.98341539258875],
  ["JP", 96.7857605597305],
  ["SR", 80.72849183726353],
  ["SE", 83.71890386110391],
  ["SD", 84.11084477844001],
  ["SS", 82.32152111946101],
  ["PH", 80.52571909821197],
  ["RW", 85.97920445711324],
  ["KU", 85.21605338170511],
  ["HN", 95.11758227520083],
  ["GA", 87.41383778180875],
  ["DZ", 95.0712619849702],
  ["DM", 93.80506607929516],
  ["FR", 64.40787768852034],
  ["GL", 79.07003109613889],
  ["ES", 65.17264835449599],
  ["HT", 80.16649391033947],
  ["PE", 63.23497019953356],
  ["QA", 98.63727649650168],
  ["SI", 85.63649909302929],
  ["PR", 89.9630733350609],
  ["PS", 95.76930551956465],
  ["IQ", 83.94726613112205],
  ["HU", 86.54120238403732],
  ["KN", 55.522479917076964],
  ["KY", 89.24138377818088],
  ["FI", 98.39952060119201],
  ["ER", 78.40081627364603],
  ["GM", 85.81400621922778],
  ["EE", 99.25887535630993],
  ["EG", 82.96320290230629],
  ["ID", 82.06497797356829],
  ["IS", 91.08771702513604],
  ["SK", 93.86887794765482],
  ["RO", 33.23529411764706],
  ["PG", 72.75265612853072],
  ["KM", 74.29644985747603],
  ["IR", 79.14809536149261],
  ["KZ", 87.95413319512826],
  ["IE", 63.675822752008294],
  ["GN", 65.73011142783103],
  ["FJ", 87.3166623477585],
  ["GY", 76.5716506867064],
  ["JM", 81.431718061674],
  ["KI", 69.33888313034466],
  ["HR", 93.22946359160404],
  ["SO", 96.2827157294636],
  ["PT", 92.66195905675045],
  ["SY", 85.9659238144597],
  ["SN", 64.13060378336357],
  ["KH", 77.68398548846851],
  ["EC", 75.26820419797875],
  ["ET", 84.93813164032133],
  ["DE", 87.51651982378854],
  ["SL", 86.24838040943249],
  ["PW", 81.54055454781032],
  ["SZ", 74.13513863695258],
  ["PA", 79.16785436641617],
  ["SM", 90.19078776885203],
  ["IT", 63.38138118683597],
  ["JO", 94.3223633065561],
  ["GH", 79.31232184503757],
  ["CG", 70.9672194869137],
  ["BT", 75.7527856957761],
  ["AO", 78.46851515936771],
  ["ML", 0],
  ["TN", 59.75608966053382],
  ["LI", 81.63837781808759],
  ["MM", 64.37742938585126],
  ["MZ", 82.06594972790879],
  ["NA", 83.80830526043016],
  ["CF", 85.62419020471624],
  ["BB", 70.27630215081628],
  ["CD", 85.64621663643432],
  ["BW", 97.30338170510495],
  ["AL", 65.95264317180617],
  ["NC", 90.48522933402437],
  ["MX", 57.908784659238144],
  ["TZ", 73.05649131899456],
  ["VE", 75.56296968126458],
  ["MN", 61.309600932884166],
  ["MY", 77.12522674267946],
  ["AM", 87.77111946100025],
  ["CR", 83.06426535371858],
  ["BA", 80.58564394920964],
  ["AZ", 85.82372376263281],
  ["CV", 91.84147447525265],
  ["BR", 62.70892718320808],
  ["CA", 93.80668566986266],
  ["BE", 66.31316403213268],
  ["TH", 87.07631510754081],
  ["WS", 65.90761855402954],
  ["UZ", 83.28582534335321],
  ["Wales", 75.73497019953356],
  ["NG", 96.02422907488986],
  ["NP", 88.06653278051309],
  ["MK", 78.59030837004404],
  ["BD", 97.24701995335579],
  ["BS", 96.1440787768852],
  ["CU", 92.51943508681005],
  ["BF", 79.66506867064005],
  ["NR", 96.22764965016843],
  ["NE", 85.60507903601969],
  ["ZW", 76.75855143819642],
  ["VC", 71.36661052086033],
  ["UY", 92.10093288416688],
  ["TJ", 90.43858512568022],
  ["VU", 80.80461259393626],
  ["ZA", 91.8942731277533],
  ["BG", 87.23956983674526],
]);

export const tnMap = new Map<string, number>([
  ["CY", 90.00584339696144],
  ["AF", 91.25730424620178],
  ["CN", 92.7386053759252],
  ["BJ", 69.94156603038566],
  ["LA", 43.06616023893001],
  ["ME", 91.44429294896767],
  ["OM", 56.02584079989612],
  ["NI", 91.89423451499805],
  ["MR", 93.19698740423321],
  ["TG", 75.21068692377614],
  ["TP", 92.6886118685885],
  ["North Korea", 52.818789767562656],
  ["VN", 93.18919620828464],
  ["ZM", 91.39559797428906],
  ["MD", 60.26977015971951],
  ["CO", 69.2614595507077],
  ["AG", 42.61362160758343],
  ["AE", 67.6253084015063],
  ["CZ", 60.14381249188418],
  ["AR", 94.0377873003506],
  ["BI", 57.20003895597975],
  ["CM", 75.65835605765486],
  ["LB", 42.405856382288015],
  ["LU", 91.48681989352032],
  ["TD", 92.13056745877158],
  ["TR", 13.631346578366447],
  ["Scotland", 85.41682898324893],
  ["UA", 94.58187248409298],
  ["LT", 95.4246201791975],
  ["LC", 97.06206986105701],
  ["MG", 95.24639657187379],
  ["CL", 91.48357356187508],
  ["BH", 41.4056616023893],
  ["AD", 94.32378911829633],
  ["CH", 13.894948707960006],
  ["NO", 90.15971951694584],
  ["MT", 50.617452278924816],
  ["MC", 50.030840150629786],
  ["TV", 81.75529152058174],
  ["TW", 27.77658745617452],
  ["US", 82.15913517724971],
  ["MU", 93.51869887027658],
  ["BZ", 73.24438384625374],
  ["CI", 90.23665757693806],
  ["BM", 27.52142578885859],
  ["CK", 79.94708479418257],
  ["AT", 91.67283469679262],
  ["BO", 93.71380340215556],
  ["LS", 92.62108817036749],
  ["NL", 91.53129463706013],
  ["MW", 67.12017919750681],
  ["YE", 61.19172834696792],
  ["UG", 66.27710686923776],
  ["TT", 31.70529801324503],
  ["NZ", 80.72587975587587],
  ["MA", 95.03213868328788],
  ["MV", 27.839241656927673],
  ["LR", 90.33924165692767],
  ["BY", 88.95760290871316],
  ["BN", 93.52356836774445],
  ["AU", 79.3721594598104],
  ["GE", 68.9770808985846],
  ["GR", 92.03025581093365],
  ["IN", 92.52791845214907],
  ["GBENG", 74.82437345799246],
  ["RS", 89.00240228541747],
  ["PL", 93.01714063108687],
  ["SV", 92.53992987923645],
  ["SA", 89.49292299701338],
  ["HK", 90.7843137254902],
  ["KG", 7.853850149331255],
  ["GD", 51.612452928191146],
  ["GQ", 89.04947409427348],
  ["DJ", 93.14731853006103],
  ["KE", 92.82787949616933],
  ["KR", 96.44981171276457],
  ["SC", 86.79554603298273],
  ["ST", 79.50525905726529],
  ["PY", 61.23977405531748],
  ["SB", 85.74763017789897],
  ["IL", 90.70705103233347],
  ["DK", 91.1144656538112],
  ["DO", 59.824373457992465],
  ["RU", 94.24912349045579],
  ["SG", 89.21958187248408],
  ["PK", 92.53863134657837],
  ["GB", 60.54408518374237],
  ["GU", 91.91014154005973],
  ["GW", 62.50292169848072],
  ["JP", 93.5553824178678],
  ["SR", 94.46240747954812],
  ["SE", 93.85469419555902],
  ["SD", 62.72334761719257],
  ["SS", 79.45331775094144],
  ["PH", 61.58096351123231],
  ["RW", 90.0512920399948],
  ["KU", 64.04947409427348],
  ["HN", 92.47240618101546],
  ["GA", 93.59628619659784],
  ["DZ", 92.20296065446047],
  ["DM", 93.11615374626672],
  ["FR", 90.00908972860667],
  ["GL", 50.9420854434489],
  ["ES", 90.45188936501754],
  ["HT", 52.35391507596417],
  ["PE", 24.489027399039088],
  ["QA", 93.18237891182963],
  ["SI", 61.04824048824828],
  ["PR", 48.20445396701727],
  ["PS", 74.7600960914167],
  ["IQ", 61.893585248669005],
  ["HU", 60.80509024801973],
  ["KN", 67.45682378911829],
  ["KY", 79.555252564602],
  ["FI", 94.4637060122062],
  ["ER", 79.44974678613167],
  ["GM", 63.487858719646795],
  ["EE", 97.73113881314114],
  ["EG", 61.95721334891573],
  ["ID", 50.3733281392027],
  ["IS", 92.0701856901701],
  ["SK", 58.838137904168285],
  ["RO", 59.909427347097775],
  ["PG", 58.18108037917153],
  ["KM", 76.95883651473835],
  ["IR", 59.94416309570185],
  ["KZ", 89.99026100506427],
  ["IE", 90.51389429944163],
  ["GN", 59.909427347097775],
  ["FJ", 82.40585638228801],
  ["GY", 70.68075574600701],
  ["JM", 89.97370471367356],
  ["KI", 43.7978833917673],
  ["HR", 60.75379820802493],
  ["SO", 93.8693026879626],
  ["PT", 44.13420335021426],
  ["SY", 60.10972600960914],
  ["SN", 60.150629788339174],
  ["KH", 58.18010647967796],
  ["EC", 68.58524866900403],
  ["ET", 61.835475912219195],
  ["DE", 72.4448123620309],
  ["SL", 91.84618880664848],
  ["PW", 94.1374496818595],
  ["SZ", 84.28061290741462],
  ["PA", 70.29022204908453],
  ["SM", 94.68478119724712],
  ["IT", 90.15387611998442],
  ["JO", 69.32021815348655],
  ["GH", 63.34761719257239],
  ["CG", 90.67426308271654],
  ["BT", 91.15179846773145],
  ["AO", 51.46831580314245],
  ["ML", 59.94773406051163],
  ["TN", 0],
  ["LI", 50.251590702506164],
  ["MM", 90.32820412933386],
  ["MZ", 79.60199974029348],
  ["NA", 69.1523828074276],
  ["CF", 84.11634852616542],
  ["BB", 93.97415920010388],
  ["CD", 77.62855473315153],
  ["BW", 97.84865601869886],
  ["AL", 14.875016231658226],
  ["NC", 91.24853915075964],
  ["MX", 58.527788598883255],
  ["TZ", 93.22490585638229],
  ["VE", 61.74328009349435],
  ["MN", 90.50545383716401],
  ["MY", 83.99363718997532],
  ["AM", 64.47604207245811],
  ["CR", 72.25003246331644],
  ["BA", 88.10511621867289],
  ["AZ", 92.81651733541099],
  ["CV", 89.66140760940138],
  ["BR", 92.15069471497208],
  ["CA", 43.198610570055834],
  ["BE", 92.9259187118556],
  ["TH", 61.87800285677185],
  ["WS", 28.35865472016621],
  ["UZ", 86.60336319958446],
  ["Wales", 78.88001558239189],
  ["NG", 90.44507206856252],
  ["NP", 83.18789767562654],
  ["MK", 34.10011686793923],
  ["BD", 91.13686534216335],
  ["BS", 92.284443578756],
  ["CU", 72.04648746915984],
  ["BF", 94.2400337618491],
  ["NR", 92.5389559797429],
  ["NE", 93.15803142449033],
  ["ZW", 91.09661082976237],
  ["VC", 92.30067523698222],
  ["UY", 90.35742111414102],
  ["TJ", 63.329437735359036],
  ["VU", 66.86339436436826],
  ["ZA", 89.60329827295156],
  ["BG", 94.43156732891832],
]);

export const liMap = new Map<string, number>([
  ["CY", 95.49488177623991],
  ["AF", 87.03179065743944],
  ["CN", 44.88285755478662],
  ["BJ", 67.12153979238754],
  ["LA", 52.708333333333336],
  ["ME", 52.12838811995386],
  ["OM", 68.97851787773934],
  ["NI", 97.21561418685121],
  ["MR", 92.55334486735872],
  ["TG", 90.81783448673588],
  ["TP", 57.66039504036908],
  ["North Korea", 93.8246107266436],
  ["VN", 49.12413494809689],
  ["ZM", 87.32698961937716],
  ["MD", 74.93800461361016],
  ["CO", 72.2267877739331],
  ["AG", 52.21705594002307],
  ["AE", 84.91169261822375],
  ["CZ", 49.86807958477509],
  ["AR", 97.27688869665513],
  ["BI", 78.76369665513265],
  ["CM", 80.29447808535178],
  ["LB", 71.42228950403691],
  ["LU", 97.67733564013841],
  ["TD", 68.4958910034602],
  ["TR", 48.92805651672434],
  ["Scotland", 90.99877450980392],
  ["UA", 97.2520184544406],
  ["LT", 63.074538638985004],
  ["LC", 97.26859861591696],
  ["MG", 97.92279411764706],
  ["CL", 46.641435986159166],
  ["BH", 62.860798731257205],
  ["AD", 78.30810265282584],
  ["CH", 94.52277970011535],
  ["NO", 83.98068050749711],
  ["MT", 72.77573529411765],
  ["MC", 97.27941176470588],
  ["TV", 83.52797001153402],
  ["TW", 92.51189446366782],
  ["US", 67.31725778546713],
  ["MU", 74.60856401384083],
  ["BZ", 80.58931660899654],
  ["CI", 97.83232410611303],
  ["BM", 39.84969723183391],
  ["CK", 63.20754036908881],
  ["AT", 65.03640426758939],
  ["BO", 97.30428200692042],
  ["LS", 70.79656862745098],
  ["NL", 97.60308535178778],
  ["MW", 71.32316897347174],
  ["YE", 97.65030276816609],
  ["UG", 90.5121828143022],
  ["TT", 60.24581891580162],
  ["NZ", 62.90224913494809],
  ["MA", 45.75511822376009],
  ["MV", 61.56069780853518],
  ["LR", 63.66061130334487],
  ["BY", 90.20617070357555],
  ["BN", 90.96056805074971],
  ["AU", 85.34638119953864],
  ["GE", 91.45400807381776],
  ["GR", 95.68375144175317],
  ["IN", 95.98760092272202],
  ["GBENG", 82.87521626297578],
  ["RS", 79.55449826989619],
  ["PL", 48.87687427912341],
  ["SV", 96.29577566320646],
  ["SA", 89.7873414071511],
  ["HK", 49.52314013840831],
  ["KG", 47.81177912341407],
  ["GD", 69.91385524798154],
  ["GQ", 65.61058246828144],
  ["DJ", 95.2833044982699],
  ["KE", 88.43533737024222],
  ["KR", 92.90549307958477],
  ["SC", 72.63552479815456],
  ["ST", 88.78496251441753],
  ["PY", 95.98327566320646],
  ["SB", 87.62831603229527],
  ["IL", 93.49444925028834],
  ["DK", 59.48241061130335],
  ["DO", 67.90873702422145],
  ["RU", 62.943339100346016],
  ["SG", 95.85928489042675],
  ["PK", 94.19117647058823],
  ["GB", 59.342920991926185],
  ["GU", 50.139489619377166],
  ["GW", 79.61108708189158],
  ["JP", 87.91270184544406],
  ["SR", 78.76441753171856],
  ["SE", 96.49041234140715],
  ["SD", 95.0746107266436],
  ["SS", 81.7308246828143],
  ["PH", 57.03215109573241],
  ["RW", 98.51138985005767],
  ["KU", 67.5241493656286],
  ["HN", 97.2801326412918],
  ["GA", 97.54613610149943],
  ["DZ", 90.16003460207612],
  ["DM", 85.54894752018456],
  ["FR", 69.02573529411765],
  ["GL", 59.058174740484425],
  ["ES", 68.49805363321799],
  ["HT", 7.103157439446367],
  ["PE", 64.24055651672434],
  ["QA", 94.96215397923875],
  ["SI", 63.91904555940023],
  ["PR", 87.6531862745098],
  ["PS", 87.17091983852364],
  ["IQ", 95.91911764705883],
  ["HU", 97.89395905420992],
  ["KN", 66.80327277970012],
  ["KY", 62.42971453287197],
  ["FI", 78.9284169550173],
  ["ER", 68.55824682814303],
  ["GM", 86.1209630911188],
  ["EE", 96.4395905420992],
  ["EG", 97.57208765859285],
  ["ID", 97.52450980392156],
  ["IS", 57.897923875432525],
  ["SK", 92.31401384083044],
  ["RO", 68.40722318339101],
  ["PG", 80.12723471741637],
  ["KM", 79.0967416378316],
  ["IR", 92.70400807381776],
  ["KZ", 92.50612745098039],
  ["IE", 97.59839965397924],
  ["GN", 80.23320357554786],
  ["FJ", 84.3115628604383],
  ["GY", 82.40196078431372],
  ["JM", 90.3175461361015],
  ["KI", 86.07050173010381],
  ["HR", 91.98853806228374],
  ["SO", 97.71842560553632],
  ["PT", 88.69016724336794],
  ["SY", 96.31415801614763],
  ["SN", 79.39049884659745],
  ["KH", 75.2025663206459],
  ["EC", 67.2711216839677],
  ["ET", 57.95487312572087],
  ["DE", 93.7629757785467],
  ["SL", 98.58924452133795],
  ["PW", 98.28719723183391],
  ["SZ", 71.41147635524798],
  ["PA", 96.50230680507497],
  ["SM", 93.69413206459055],
  ["IT", 80.82936851211072],
  ["JO", 82.20696366782006],
  ["GH", 95.91947808535178],
  ["CG", 69.43158881199538],
  ["BT", 94.6319925028835],
  ["AO", 94.49106113033449],
  ["ML", 81.69802479815456],
  ["TN", 50.229599192618224],
  ["LI", 0],
  ["MM", 65.63941753171856],
  ["MZ", 84.45898212226068],
  ["NA", 55.976066897347174],
  ["CF", 70.23248269896195],
  ["BB", 66.35957324106113],
  ["CD", 85.71294694348327],
  ["BW", 96.77551903114187],
  ["AL", 42.74726066897347],
  ["NC", 92.65859284890426],
  ["MX", 78.00461361014995],
  ["TZ", 93.48651960784314],
  ["VE", 47.32987312572088],
  ["MN", 66.8065167243368],
  ["MY", 70.06992502883507],
  ["AM", 83.25331603229526],
  ["CR", 64.68966262975778],
  ["BA", 68.12896482122261],
  ["AZ", 80.41955017301038],
  ["CV", 42.86908881199539],
  ["BR", 85.21410034602076],
  ["CA", 92.44593425605537],
  ["BE", 80.13660611303345],
  ["TH", 80.3972029988466],
  ["WS", 27.574250288350633],
  ["UZ", 95.91299019607843],
  ["Wales", 70.36836793540945],
  ["NG", 97.39186851211072],
  ["NP", 65.75079296424452],
  ["MK", 81.1159169550173],
  ["BD", 92.93180507497117],
  ["BS", 95.9111880046136],
  ["CU", 61.41075547866205],
  ["BF", 95.74394463667821],
  ["NR", 53.98212226066897],
  ["NE", 97.98983564013841],
  ["ZW", 82.56415801614764],
  ["VC", 94.91529700115339],
  ["UY", 95.72448096885813],
  ["TJ", 96.59385813148789],
  ["VU", 89.71237024221453],
  ["ZA", 92.21993944636678],
  ["BG", 63.79865916955018],
]);

export const mmMap = new Map<string, number>([
  ["CY", 88.7991819244254],
  ["AF", 93.39988313206076],
  ["CN", 93.55148681989353],
  ["BJ", 59.04265679781846],
  ["LA", 68.45766783534606],
  ["ME", 93.70666147253604],
  ["OM", 83.36482275029216],
  ["NI", 91.79489676665368],
  ["MR", 94.52473704713672],
  ["TG", 85.47266588754707],
  ["TP", 70.19932476301778],
  ["North Korea", 81.55239579275418],
  ["VN", 64.09524737047137],
  ["ZM", 94.4055966757564],
  ["MD", 73.78424879885729],
  ["CO", 40.53726788728736],
  ["AG", 62.13835865472017],
  ["AE", 83.50960914166991],
  ["CZ", 67.5740163615115],
  ["AR", 92.86261524477341],
  ["BI", 51.176795221399814],
  ["CM", 76.86728996234255],
  ["LB", 66.78320997273082],
  ["LU", 90.86579664978575],
  ["TD", 85.56226464095572],
  ["TR", 93.13498246980912],
  ["Scotland", 84.10498636540709],
  ["UA", 96.77834047526297],
  ["LT", 63.75827814569537],
  ["LC", 93.1038176860148],
  ["MG", 96.43358005453837],
  ["CL", 61.34398130112972],
  ["BH", 75.4824048824828],
  ["AD", 72.12147773016491],
  ["CH", 86.25373328139203],
  ["NO", 66.1443319049474],
  ["MT", 74.31469938968965],
  ["MC", 93.14764316322555],
  ["TV", 80.07466562784053],
  ["TW", 94.62861965978445],
  ["US", 81.7439293598234],
  ["MU", 97.4204648746916],
  ["BZ", 78.06745877158811],
  ["CI", 87.15848591092066],
  ["BM", 59.13712504869497],
  ["CK", 90.57427606804312],
  ["AT", 60.81028437865213],
  ["BO", 96.42481495909621],
  ["LS", 91.97019867549669],
  ["NL", 90.92001038826126],
  ["MW", 96.66828983248928],
  ["YE", 92.21594598104143],
  ["UG", 75.11654330606414],
  ["TT", 71.29203999480586],
  ["NZ", 90.9635112323075],
  ["MA", 96.50499935073367],
  ["MV", 68.15705752499676],
  ["LR", 85.62978833917673],
  ["BY", 90.15030515517465],
  ["BN", 68.26580963511232],
  ["AU", 91.30567458771588],
  ["GE", 88.4703285287625],
  ["GR", 88.25671990650565],
  ["IN", 92.12310089598753],
  ["GBENG", 92.54479937670432],
  ["RS", 91.18685884950007],
  ["PL", 62.83242436047266],
  ["SV", 92.27178288533958],
  ["SA", 90.07401636151148],
  ["HK", 88.99233865731723],
  ["KG", 59.990910271393325],
  ["GD", 63.467731463446306],
  ["GQ", 89.59648097649657],
  ["DJ", 89.1455655109726],
  ["KE", 94.37086092715232],
  ["KR", 94.93604726658876],
  ["SC", 86.4459161147903],
  ["ST", 86.85527853525517],
  ["PY", 92.94799376704324],
  ["SB", 86.14368263861836],
  ["IL", 87.92689261134917],
  ["DK", 91.07388650824568],
  ["DO", 76.39981820542788],
  ["RU", 65.75217504220231],
  ["SG", 90.42624334502013],
  ["PK", 93.75795351253085],
  ["GB", 80.62589274120245],
  ["GU", 95.8732632125698],
  ["GW", 61.71114141020647],
  ["JP", 96.74652642513959],
  ["SR", 91.38618361251785],
  ["SE", 91.33716400467472],
  ["SD", 90.17432800934944],
  ["SS", 91.05213608622257],
  ["PH", 69.14037138034021],
  ["RW", 92.8720296065446],
  ["KU", 65.21555642124399],
  ["HN", 90.39800025970654],
  ["GA", 96.5887547071809],
  ["DZ", 92.62238670302558],
  ["DM", 93.29405272042592],
  ["FR", 76.98058693676147],
  ["GL", 63.41416699129984],
  ["ES", 83.67841838722244],
  ["HT", 65.86514738345669],
  ["PE", 65.61258278145695],
  ["QA", 96.13426827684717],
  ["SI", 62.93046357615894],
  ["PR", 86.9312426957538],
  ["PS", 85.43176210881704],
  ["IQ", 92.6886118685885],
  ["HU", 90.73302168549539],
  ["KN", 73.76477080898584],
  ["KY", 88.9946110894689],
  ["FI", 95.75217504220231],
  ["ER", 83.41676405661602],
  ["GM", 96.54817556161538],
  ["EE", 97.89442929489677],
  ["EG", 93.86995195429165],
  ["ID", 93.29048175561616],
  ["IS", 90.5606414751331],
  ["SK", 61.83125568108038],
  ["RO", 74.74483833268407],
  ["PG", 87.7330866121283],
  ["KM", 61.318659914296845],
  ["IR", 84.91332294507207],
  ["KZ", 87.98143098298922],
  ["IE", 87.1656278405402],
  ["GN", 73.99655888845604],
  ["FJ", 85.54830541488118],
  ["GY", 83.19244253992989],
  ["JM", 83.25931697182185],
  ["KI", 86.19659784443579],
  ["HR", 92.09907804181276],
  ["SO", 91.87313335930398],
  ["PT", 91.33846253733282],
  ["SY", 91.46247240618102],
  ["SN", 82.49123490455786],
  ["KH", 90.29736397870406],
  ["EC", 37.98597584729256],
  ["ET", 65.03992987923645],
  ["DE", 96.49558498896246],
  ["SL", 90.7008830022075],
  ["PW", 90.70899883132061],
  ["SZ", 83.72938579405272],
  ["PA", 90.64472146474483],
  ["SM", 92.66556291390728],
  ["IT", 76.52317880794702],
  ["JO", 84.103687832749],
  ["GH", 95.31554343591742],
  ["CG", 75.52687962602259],
  ["BT", 64.58674198156083],
  ["AO", 95.65803142449033],
  ["ML", 64.68185949876639],
  ["TN", 90.32820412933386],
  ["LI", 65.76386183612519],
  ["MM", 0],
  ["MZ", 89.06116088819633],
  ["NA", 81.31573821581613],
  ["CF", 75.31164783794313],
  ["BB", 95.04025451240099],
  ["CD", 82.36852356836775],
  ["BW", 95.01330995974548],
  ["AL", 65.56745877158811],
  ["NC", 91.88222308791066],
  ["MX", 79.99902610050643],
  ["TZ", 83.1278405401896],
  ["VE", 34.557849629918195],
  ["MN", 91.57382158161278],
  ["MY", 78.13855343461888],
  ["AM", 99.48415790157122],
  ["CR", 87.14907154914945],
  ["BA", 80.08635242176341],
  ["AZ", 94.86008310609012],
  ["CV", 92.73211271263472],
  ["BR", 90.31099857161408],
  ["CA", 92.37112063368393],
  ["BE", 84.74970783015193],
  ["TH", 77.6535514868199],
  ["WS", 66.37709388391117],
  ["UZ", 88.60764835735618],
  ["Wales", 84.40040254512401],
  ["NG", 87.27470458382028],
  ["NP", 75.6820542786651],
  ["MK", 78.27782106219972],
  ["BD", 92.51038826126478],
  ["BS", 95.71192052980132],
  ["CU", 83.19698740423321],
  ["BF", 94.97208154785093],
  ["NR", 96.52480197376964],
  ["NE", 95.37884690299961],
  ["ZW", 80.72458122321777],
  ["VC", 77.7399039085833],
  ["UY", 84.54389040384366],
  ["TJ", 87.22471107648357],
  ["VU", 93.52973639787041],
  ["ZA", 88.69497467861316],
  ["BG", 93.62290611608883],
]);

export const mzMap = new Map<string, number>([
  ["CY", 89.82112712634722],
  ["AF", 91.39981820542786],
  ["CN", 91.49071549149461],
  ["BJ", 85.4434489027399],
  ["LA", 85.73269705233086],
  ["ME", 86.29431242695755],
  ["OM", 80.13699519542917],
  ["NI", 93.7978833917673],
  ["MR", 88.97123750162316],
  ["TG", 64.62472406181016],
  ["TP", 96.09336449811713],
  ["North Korea", 82.49837683417738],
  ["VN", 80.39150759641605],
  ["ZM", 87.10914166991299],
  ["MD", 79.50623295675886],
  ["CO", 92.17504220231139],
  ["AG", 81.81762108817037],
  ["AE", 83.5699909102714],
  ["CZ", 89.54648746915986],
  ["AR", 95.79405272042591],
  ["BI", 85.35612258148294],
  ["CM", 76.00733670951824],
  ["LB", 86.2021166082327],
  ["LU", 89.96980911569926],
  ["TD", 81.58291131021946],
  ["TR", 81.37514608492403],
  ["Scotland", 87.27567848331385],
  ["UA", 66.86858849500065],
  ["LT", 91.17192572393196],
  ["LC", 86.96208284638358],
  ["MG", 95.9826645890144],
  ["CL", 84.57343202181535],
  ["BH", 92.56557589923386],
  ["AD", 80.69406570575251],
  ["CH", 91.74847422412674],
  ["NO", 81.34885079859758],
  ["MT", 95.04512400986886],
  ["MC", 85.80703804700688],
  ["TV", 84.04785092845086],
  ["TW", 89.64679911699778],
  ["US", 79.49162446435528],
  ["MU", 84.26697831450461],
  ["BZ", 83.15997922347748],
  ["CI", 94.46467991169978],
  ["BM", 80.15842098428776],
  ["CK", 83.01714063108687],
  ["AT", 86.3855343461888],
  ["BO", 90.75964160498636],
  ["LS", 87.90416828983248],
  ["NL", 93.76249837683417],
  ["MW", 86.16608232697052],
  ["YE", 90.94241007661343],
  ["UG", 74.55427866510843],
  ["TT", 74.74353980002597],
  ["NZ", 83.78944292948968],
  ["MA", 79.93864433190495],
  ["MV", 84.49941566030385],
  ["LR", 80.07434099467602],
  ["BY", 90.05940786910791],
  ["BN", 72.7090637579535],
  ["AU", 84.22445136995196],
  ["GE", 87.45779768861188],
  ["GR", 88.85923905986236],
  ["IN", 94.31112842487988],
  ["GBENG", 89.20042851577718],
  ["RS", 85.95214907154914],
  ["PL", 87.97558758602779],
  ["SV", 93.68620958317102],
  ["SA", 88.8800155823919],
  ["HK", 91.72704843526814],
  ["KG", 77.78697571743929],
  ["GD", 71.3141150499935],
  ["GQ", 91.01869887027658],
  ["DJ", 95.53856641994545],
  ["KE", 84.40592130892092],
  ["KR", 90.22659394883782],
  ["SC", 84.6321906245942],
  ["ST", 80.92877548370342],
  ["PY", 89.62732112712635],
  ["SB", 85.95701856901701],
  ["IL", 88.16484872094533],
  ["DK", 82.55096740683028],
  ["DO", 87.5146084924036],
  ["RU", 88.03012595766783],
  ["SG", 85.57330216854953],
  ["PK", 91.67121153097001],
  ["GB", 77.338332684067],
  ["GU", 86.99487079600053],
  ["GW", 81.03525516166732],
  ["JP", 90.41228411894559],
  ["SR", 84.23548889754578],
  ["SE", 89.71529671471238],
  ["SD", 91.69166342033502],
  ["SS", 87.1805609661083],
  ["PH", 95.55285027918453],
  ["RW", 85.7424360472666],
  ["KU", 94.98182054278665],
  ["HN", 93.82385404492923],
  ["GA", 94.09979223477471],
  ["DZ", 86.26542007531489],
  ["DM", 87.43150240228542],
  ["FR", 90.21133619010519],
  ["GL", 85.89274120244124],
  ["ES", 82.2600960914167],
  ["HT", 85.13667056226464],
  ["PE", 79.16244643552785],
  ["QA", 93.14472146474483],
  ["SI", 88.36125178548241],
  ["PR", 84.85618750811584],
  ["PS", 84.63965718737826],
  ["IQ", 90.24542267238022],
  ["HU", 90.17822360732373],
  ["KN", 82.02051681599792],
  ["KY", 83.99104012465914],
  ["FI", 89.8490455784963],
  ["ER", 79.40397350993378],
  ["GM", 87.47824957797688],
  ["EE", 74.91527074405921],
  ["EG", 90.93332034800675],
  ["ID", 85.85768082067264],
  ["IS", 89.84320218153486],
  ["SK", 87.05298013245033],
  ["RO", 81.61018049603948],
  ["PG", 91.3167121153097],
  ["KM", 90.61875081158291],
  ["IR", 87.22081547850928],
  ["KZ", 90.21490715491495],
  ["IE", 91.2034151408908],
  ["GN", 70.57070510323335],
  ["FJ", 86.39267627580833],
  ["GY", 74.55752499675367],
  ["JM", 75.5862874951305],
  ["KI", 76.49526035579794],
  ["HR", 89.93442410076614],
  ["SO", 93.35346058953384],
  ["PT", 89.61076483573562],
  ["SY", 90.23568367744448],
  ["SN", 92.82528243085314],
  ["KH", 83.06680950525906],
  ["EC", 90.89371510193482],
  ["ET", 86.88644331904948],
  ["DE", 67.05492793143748],
  ["SL", 94.02642513959226],
  ["PW", 93.28918322295806],
  ["SZ", 76.83125568108038],
  ["PA", 90.9112452928191],
  ["SM", 91.73159329957147],
  ["IT", 91.25211011556941],
  ["JO", 79.89806518633944],
  ["GH", 88.20315543435918],
  ["CG", 90.67815868069083],
  ["BT", 93.43786521231009],
  ["AO", 78.8193091806259],
  ["ML", 82.61654330606414],
  ["TN", 79.60199974029348],
  ["LI", 84.74711076483572],
  ["MM", 89.06116088819633],
  ["MZ", 0],
  ["NA", 82.9580573951435],
  ["CF", 74.94253992987923],
  ["BB", 88.06226464095572],
  ["CD", 86.35891442669784],
  ["BW", 86.91923126866641],
  ["AL", 71.86501753019088],
  ["NC", 90.78074276068044],
  ["MX", 90.44929229970134],
  ["TZ", 83.09635112323075],
  ["VE", 86.05960264900662],
  ["MN", 82.51947798987145],
  ["MY", 78.42228282041293],
  ["AM", 91.6147253603428],
  ["CR", 87.4259836384885],
  ["BA", 83.10316841968576],
  ["AZ", 88.31060901181664],
  ["CV", 86.59492273730685],
  ["BR", 85.04901960784314],
  ["CA", 88.83034670821971],
  ["BE", 96.96500454486431],
  ["TH", 86.34300740163616],
  ["WS", 84.16082326970523],
  ["UZ", 91.62154265679781],
  ["Wales", 85.47623685235683],
  ["NG", 75.50350603817687],
  ["NP", 79.00986884820153],
  ["MK", 75.9784443578756],
  ["BD", 69.23873522919102],
  ["BS", 96.04499415660304],
  ["CU", 75.01688092455525],
  ["BF", 87.27567848331385],
  ["NR", 90.71062199714322],
  ["NE", 93.5161018049604],
  ["ZW", 75.22010128554733],
  ["VC", 78.58297623685235],
  ["UY", 87.2925594078691],
  ["TJ", 89.89676665368134],
  ["VU", 88.31775094143617],
  ["ZA", 85.7008830022075],
  ["BG", 93.97675626542008],
]);

export const naMap = new Map<string, number>([
  ["CY", 87.08836514738346],
  ["AF", 83.7534086482275],
  ["CN", 86.48389819503961],
  ["BJ", 84.16796519932477],
  ["LA", 66.69718218413193],
  ["ME", 81.6913387871705],
  ["OM", 73.29275418776783],
  ["NI", 88.89981820542788],
  ["MR", 86.44234514998053],
  ["TG", 86.23198285936891],
  ["TP", 70.74340994676017],
  ["North Korea", 75.08992338657318],
  ["VN", 69.13063238540448],
  ["ZM", 86.02324373457992],
  ["MD", 77.99116997792494],
  ["CO", 79.41208933904687],
  ["AG", 76.21542656797818],
  ["AE", 84.04850019477989],
  ["CZ", 71.13718997532787],
  ["AR", 93.01064796779639],
  ["BI", 83.01129723412544],
  ["CM", 80.90020776522529],
  ["LB", 75.38761199844176],
  ["LU", 81.9055966757564],
  ["TD", 66.83482664589015],
  ["TR", 67.70581742630827],
  ["Scotland", 85.27658745617452],
  ["UA", 88.65536943254124],
  ["LT", 82.56460199974029],
  ["LC", 95.67686014803273],
  ["MG", 65.41260875211012],
  ["CL", 76.46214777301648],
  ["BH", 72.22536034281262],
  ["AD", 80.32430853135956],
  ["CH", 86.5686274509804],
  ["NO", 65.87943124269574],
  ["MT", 78.03012595766783],
  ["MC", 77.09485781067393],
  ["TV", 75.95214907154914],
  ["TW", 85.44539670172705],
  ["US", 74.08193741072589],
  ["MU", 60.445072068562524],
  ["BZ", 67.41851707570446],
  ["CI", 71.34073496948449],
  ["BM", 58.27100376574471],
  ["CK", 70.57849629918192],
  ["AT", 74.30496039475393],
  ["BO", 61.23620309050772],
  ["LS", 56.038176860148035],
  ["NL", 89.18095052590573],
  ["MW", 75.0834307232827],
  ["YE", 83.79853265809635],
  ["UG", 84.25009738994935],
  ["TT", 67.44903259316972],
  ["NZ", 71.71146604337099],
  ["MA", 65.48857291260876],
  ["MV", 70.12595766783535],
  ["LR", 71.37417218543047],
  ["BY", 83.75438254772108],
  ["BN", 85.89533826775744],
  ["AU", 83.97253603428126],
  ["GE", 84.92436047266588],
  ["GR", 88.77028957278276],
  ["IN", 90.54538371640047],
  ["GBENG", 84.24944812362031],
  ["RS", 78.92546422542527],
  ["PL", 78.65017530190885],
  ["SV", 88.39533826775744],
  ["SA", 83.97902869757175],
  ["HK", 85.45026619919491],
  ["KG", 66.13232047786002],
  ["GD", 66.32774964290353],
  ["GQ", 81.07161407609401],
  ["DJ", 64.83313855343462],
  ["KE", 85.54733151538761],
  ["KR", 84.5026619919491],
  ["SC", 40.444747435398],
  ["ST", 69.50753148941696],
  ["PY", 80.04966887417218],
  ["SB", 80.08992338657318],
  ["IL", 87.41169977924945],
  ["DK", 70.69114400727177],
  ["DO", 59.52538631346579],
  ["RU", 81.46961433580054],
  ["SG", 75.55187637969095],
  ["PK", 88.02071159589664],
  ["GB", 65.60414231917933],
  ["GU", 63.60699909102714],
  ["GW", 53.44013764446176],
  ["JP", 81.12615244773406],
  ["SR", 81.99357226334241],
  ["SE", 74.88865082456823],
  ["SD", 81.50337618491105],
  ["SS", 60.95864173483964],
  ["PH", 80.63141150499935],
  ["RW", 89.22899623425529],
  ["KU", 82.99766264121543],
  ["HN", 88.74366965329178],
  ["GA", 87.9798078171666],
  ["DZ", 84.51045318789767],
  ["DM", 81.334242306194],
  ["FR", 67.0175951175172],
  ["GL", 80.69114400727179],
  ["ES", 75.10583041163486],
  ["HT", 57.25782365926503],
  ["PE", 73.61901051811454],
  ["QA", 88.0606414751331],
  ["SI", 80.62264640955719],
  ["PR", 81.36313465783664],
  ["PS", 67.36852356836775],
  ["IQ", 82.75938189845475],
  ["HU", 61.78353460589534],
  ["KN", 89.19101415400597],
  ["KY", 69.96169328658615],
  ["FI", 77.6051811453058],
  ["ER", 65.5713543695624],
  ["GM", 75.88332684067004],
  ["EE", 92.95383716400467],
  ["EG", 83.83326840670043],
  ["ID", 77.22568497597715],
  ["IS", 71.19107908063887],
  ["SK", 78.99655888845605],
  ["RO", 66.80690819374108],
  ["PG", 80.84729255940786],
  ["KM", 81.30437605505779],
  ["IR", 84.83476171925723],
  ["KZ", 87.87624983768342],
  ["IE", 89.48805349954552],
  ["GN", 60.83787819763668],
  ["FJ", 84.39780547980781],
  ["GY", 58.12719127386053],
  ["JM", 76.19919490975198],
  ["KI", 70.62621737436696],
  ["HR", 85.23957927541878],
  ["SO", 91.88709258537853],
  ["PT", 83.96928970263602],
  ["SY", 83.37943124269576],
  ["SN", 79.39001428385923],
  ["KH", 79.43221659524737],
  ["EC", 78.77386053759253],
  ["ET", 73.06680950525906],
  ["DE", 89.5296065446046],
  ["SL", 91.80918062589274],
  ["PW", 92.63439813011297],
  ["SZ", 79.70166212180236],
  ["PA", 74.45753798208024],
  ["SM", 90.2574340994676],
  ["IT", 80.53272302298403],
  ["JO", 61.82151668614466],
  ["GH", 81.24659135177251],
  ["CG", 85.13894299441631],
  ["BT", 89.6617322425659],
  ["AO", 80.222373717699],
  ["ML", 84.1806258927412],
  ["TN", 69.1523828074276],
  ["LI", 56.31508894948708],
  ["MM", 81.31573821581613],
  ["MZ", 82.9580573951435],
  ["NA", 0],
  ["CF", 75.02369822101026],
  ["BB", 73.89527334112454],
  ["CD", 72.01727048435268],
  ["BW", 95.43955330476561],
  ["AL", 67.21464744838332],
  ["NC", 66.2602259446825],
  ["MX", 79.27541877678223],
  ["TZ", 91.0875211011557],
  ["VE", 71.01253084015063],
  ["MN", 64.28548240488249],
  ["MY", 73.06745877158811],
  ["AM", 90.04642254252695],
  ["CR", 69.96851058304117],
  ["BA", 71.2115309700039],
  ["AZ", 82.33119075444748],
  ["CV", 65.15647318530061],
  ["BR", 60.03051551746527],
  ["CA", 86.14011167380859],
  ["BE", 82.61005064277367],
  ["TH", 82.65257758732632],
  ["WS", 50.06979613037268],
  ["UZ", 87.58537852226983],
  ["Wales", 52.57563952733412],
  ["NG", 67.94085183742371],
  ["NP", 71.25178548240488],
  ["MK", 76.86469289702636],
  ["BD", 84.69744189066355],
  ["BS", 89.74776003116477],
  ["CU", 71.39722114011168],
  ["BF", 51.00441501103753],
  ["NR", 65.50577847032852],
  ["NE", 70.91773795610959],
  ["ZW", 86.03655369432542],
  ["VC", 71.05181145305805],
  ["UY", 87.93403454096871],
  ["TJ", 88.82742500973899],
  ["VU", 52.15588884560447],
  ["ZA", 79.08875470718088],
  ["BG", 89.16082326970523],
]);

export const cfMap = new Map<string, number>([
  ["CY", 78.56471883907749],
  ["AF", 89.5756672713138],
  ["CN", 94.37678154962425],
  ["BJ", 86.93800207307592],
  ["LA", 88.33991966830786],
  ["ME", 88.51937030318734],
  ["OM", 85.50790360196943],
  ["NI", 82.99235553252137],
  ["MR", 94.19668307851775],
  ["TG", 88.8607799948173],
  ["TP", 77.72123607152112],
  ["North Korea", 70.90988598082404],
  ["VN", 84.66280124384555],
  ["ZM", 94.24883389479139],
  ["MD", 88.00757968385592],
  ["CO", 89.9630733350609],
  ["AG", 90.84736978491837],
  ["AE", 87.92368489245919],
  ["CZ", 74.7518787250583],
  ["AR", 83.32631510754082],
  ["BI", 65.3203550142524],
  ["CM", 72.51490023322104],
  ["LB", 65.17167660015548],
  ["LU", 78.56633842964499],
  ["TD", 92.57579683855921],
  ["TR", 78.89965016843742],
  ["Scotland", 83.7163125161959],
  ["UA", 77.21300855143821],
  ["LT", 91.48807981342316],
  ["LC", 95.70840891422648],
  ["MG", 89.01949987043275],
  ["CL", 76.40807203938844],
  ["BH", 70.78906452448821],
  ["AD", 88.67193573464628],
  ["CH", 91.12529152630215],
  ["NO", 79.21741383778182],
  ["MT", 79.35054418243068],
  ["MC", 89.6754340502721],
  ["TV", 80.69124125421094],
  ["TW", 92.13202902306297],
  ["US", 80.76995335579166],
  ["MU", 93.70465146410987],
  ["BZ", 71.73911635138637],
  ["CI", 93.48697849183726],
  ["BM", 70.18657683337652],
  ["CK", 77.7526561285307],
  ["AT", 73.50608966053382],
  ["BO", 90.4298393366157],
  ["LS", 62.13397253174398],
  ["NL", 83.64796579424721],
  ["MW", 78.27060119201866],
  ["YE", 78.97253174397513],
  ["UG", 85.81756931847629],
  ["TT", 83.94629437678155],
  ["NZ", 77.75524747343871],
  ["MA", 80.98147188390776],
  ["MV", 87.33512568022805],
  ["LR", 71.75660792951541],
  ["BY", 90.22544700699663],
  ["BN", 64.80500129567245],
  ["AU", 87.08959575019435],
  ["GE", 81.25259134490801],
  ["GR", 83.10507903601969],
  ["IN", 82.77792174138378],
  ["GBENG", 73.49378077222079],
  ["RS", 89.9653407618554],
  ["PL", 69.5040813682301],
  ["SV", 82.83849442860844],
  ["SA", 89.32268722466961],
  ["HK", 91.2891940917336],
  ["KG", 82.19001036537964],
  ["GD", 79.13449080072557],
  ["GQ", 80.74274423425759],
  ["DJ", 76.71806167400881],
  ["KE", 88.08758745789065],
  ["KR", 75.15580461259394],
  ["SC", 76.42167660015548],
  ["ST", 93.77656128530707],
  ["PY", 76.62574501166105],
  ["SB", 86.36499093029282],
  ["IL", 74.98056491318995],
  ["DK", 77.0704845814978],
  ["DO", 86.63805389997408],
  ["RU", 85.43923296190724],
  ["SG", 87.58551438196423],
  ["PK", 88.56277533039648],
  ["GB", 60.69286084477844],
  ["GU", 77.14012697590049],
  ["GW", 93.40761855402954],
  ["JP", 72.16863176988856],
  ["SR", 88.12613371339725],
  ["SE", 92.80027209121535],
  ["SD", 81.95646540554547],
  ["SS", 86.32450116610521],
  ["PH", 79.2857605597305],
  ["RW", 98.73250842187095],
  ["KU", 81.43625291526303],
  ["HN", 83.42089919668308],
  ["GA", 95.88202902306297],
  ["DZ", 83.26120756672714],
  ["DM", 87.11421352682042],
  ["FR", 87.15178802798653],
  ["GL", 74.44739569836746],
  ["ES", 82.17025136045608],
  ["HT", 70.30383519046384],
  ["PE", 93.01373412801243],
  ["QA", 87.55668566986266],
  ["SI", 84.8898678414097],
  ["PR", 82.65191759523192],
  ["PS", 86.606309924851],
  ["IQ", 78.29424721430422],
  ["HU", 78.09568541072817],
  ["KN", 85.5338170510495],
  ["KY", 75.86583311738792],
  ["FI", 86.47901010624514],
  ["ER", 79.3204197978751],
  ["GM", 86.23769111168697],
  ["EE", 98.9249157812905],
  ["EG", 78.931718061674],
  ["ID", 89.8892200051827],
  ["IS", 74.35410728167919],
  ["SK", 81.09549105986007],
  ["RO", 86.73231407100285],
  ["PG", 84.9970847369785],
  ["KM", 72.54470069966312],
  ["IR", 76.17549883389479],
  ["KZ", 89.45808499611299],
  ["IE", 93.06556102617259],
  ["GN", 92.42938585125681],
  ["FJ", 84.72758486654574],
  ["GY", 86.75434050272092],
  ["JM", 82.99948173101839],
  ["KI", 84.80435345944545],
  ["HR", 78.14654055454781],
  ["SO", 96.24287380150298],
  ["PT", 89.52027727390515],
  ["SY", 79.32754599637212],
  ["SN", 94.94817310183986],
  ["KH", 73.08596786732315],
  ["EC", 83.37263539777145],
  ["ET", 84.7875097175434],
  ["DE", 76.99565949727909],
  ["SL", 84.37257061414874],
  ["PW", 95.77545996372118],
  ["SZ", 83.45620627105468],
  ["PA", 82.85242290748899],
  ["SM", 74.95529930033688],
  ["IT", 92.97097693703031],
  ["JO", 87.56575537704069],
  ["GH", 90.00712619849702],
  ["CG", 88.68489245918632],
  ["BT", 90.93547551179061],
  ["AO", 89.99578906452449],
  ["ML", 85.62419020471624],
  ["TN", 83.6068281938326],
  ["LI", 70.24131899455817],
  ["MM", 75.01425239699404],
  ["MZ", 74.3570225447007],
  ["NA", 74.59963721171287],
  ["CF", 0],
  ["BB", 82.13040943249547],
  ["CD", 88.75906970717803],
  ["BW", 94.45938066856698],
  ["AL", 83.9288027986525],
  ["NC", 91.58136823011142],
  ["MX", 90.36149261466701],
  ["TZ", 89.07003109613889],
  ["VE", 87.4604819901529],
  ["MN", 92.50129567245399],
  ["MY", 77.99138377818088],
  ["AM", 96.72454003627882],
  ["CR", 69.7130085514382],
  ["BA", 80.75991189427313],
  ["AZ", 93.23011142783105],
  ["CV", 75.4635268204198],
  ["BR", 89.11635138636953],
  ["CA", 87.8906452448821],
  ["BE", 96.70348535890126],
  ["TH", 84.31718061674009],
  ["WS", 76.6079295154185],
  ["UZ", 79.18145892718321],
  ["Wales", 69.19052863436124],
  ["NG", 92.97227260948432],
  ["NP", 73.5012308888313],
  ["MK", 78.29781031355273],
  ["BD", 93.20063487950246],
  ["BS", 96.24254988338949],
  ["CU", 73.1073464628142],
  ["BF", 89.50926405804613],
  ["NR", 77.30370562321845],
  ["NE", 77.95607670380927],
  ["ZW", 82.39958538481473],
  ["VC", 85.34853589012698],
  ["UY", 80.74760300596009],
  ["TJ", 79.51023581238663],
  ["VU", 84.79074889867842],
  ["ZA", 89.63332469551698],
  ["BG", 90.57268722466961],
]);

export const bbMap = new Map<string, number>([
  ["CY", 92.5444747435398],
  ["AF", 94.01279054668224],
  ["CN", 96.84456564082586],
  ["BJ", 87.35781067393845],
  ["LA", 62.85385014933126],
  ["ME", 92.60972600960913],
  ["OM", 94.1686144656538],
  ["NI", 95.63400857031554],
  ["MR", 93.33982599662382],
  ["TG", 95.19705233086611],
  ["TP", 92.25652512660693],
  ["North Korea", 94.33742371120634],
  ["VN", 96.4825996623815],
  ["ZM", 94.32703544994156],
  ["MD", 93.22523048954682],
  ["CO", 66.90332424360473],
  ["AG", 86.94714972081547],
  ["AE", 96.19237761329697],
  ["CZ", 74.21471237501623],
  ["AR", 95.38404103363199],
  ["BI", 90.96610829762368],
  ["CM", 98.57843137254902],
  ["LB", 93.5323334631866],
  ["LU", 97.35359044279963],
  ["TD", 63.16777041942605],
  ["TR", 96.02389300090898],
  ["Scotland", 89.62375016231658],
  ["UA", 98.0392156862745],
  ["LT", 87.71620568757305],
  ["LC", 86.96045968056096],
  ["MG", 99.16147253603428],
  ["CL", 95.23373587845735],
  ["BH", 96.22256849759772],
  ["AD", 92.01077782106219],
  ["CH", 94.83346318659915],
  ["NO", 86.1319958446955],
  ["MT", 97.36300480457083],
  ["MC", 97.95091546552396],
  ["TV", 85.29736397870407],
  ["TW", 94.5026619919491],
  ["US", 82.43280093494351],
  ["MU", 80.19023503441112],
  ["BZ", 85.44831840020777],
  ["CI", 94.79840280483054],
  ["BM", 85.50934943513829],
  ["CK", 41.761134917543174],
  ["AT", 96.55012336060253],
  ["BO", 96.72834696792624],
  ["LS", 93.60862225684976],
  ["NL", 96.41345279833787],
  ["MW", 96.09174133229452],
  ["YE", 96.95364238410596],
  ["UG", 88.3677444487729],
  ["TT", 91.50662251655629],
  ["NZ", 38.71899753278795],
  ["MA", 98.42260745357746],
  ["MV", 97.3516426438125],
  ["LR", 81.03006103103493],
  ["BY", 94.18874172185431],
  ["BN", 74.98279444228022],
  ["AU", 84.98474224126737],
  ["GE", 91.66244643552785],
  ["GR", 92.53213868328788],
  ["IN", 94.72308791066095],
  ["GBENG", 96.5741462147773],
  ["RS", 71.06706921179068],
  ["PL", 97.58213219062459],
  ["SV", 95.2048435268147],
  ["SA", 92.69510453187898],
  ["HK", 93.84463056745878],
  ["KG", 92.21789378002858],
  ["GD", 76.76990001298533],
  ["GQ", 93.65212310089599],
  ["DJ", 95.88884560446695],
  ["KE", 93.61089468900144],
  ["KR", 93.10414231917933],
  ["SC", 75.28048305414882],
  ["ST", 93.47097779509154],
  ["PY", 95.89923386573173],
  ["SB", 88.54337099078042],
  ["IL", 92.57109466303078],
  ["DK", 96.92150370081808],
  ["DO", 68.88618361251785],
  ["RU", 94.40592130892091],
  ["SG", 97.19354629268926],
  ["PK", 94.3409946760161],
  ["GB", 67.56005713543696],
  ["GU", 39.72178937800286],
  ["GW", 82.0740812881444],
  ["JP", 93.52713933255421],
  ["SR", 94.18159979223478],
  ["SE", 96.27386053759251],
  ["SD", 92.77204259187118],
  ["SS", 91.1686793922867],
  ["PH", 93.50149331255682],
  ["RW", 97.87722373717699],
  ["KU", 93.19861057005583],
  ["HN", 94.9042332164654],
  ["GA", 92.10751850409038],
  ["DZ", 94.28872873652773],
  ["DM", 92.93143747565252],
  ["FR", 61.271588105440856],
  ["GL", 95.08310609011816],
  ["ES", 92.75646019997403],
  ["HT", 95.26587456174522],
  ["PE", 94.20821971172575],
  ["QA", 94.15497987274381],
  ["SI", 95.80249318270354],
  ["PR", 89.4744189066355],
  ["PS", 92.71458252175042],
  ["IQ", 95.2574340994676],
  ["HU", 96.89520841449163],
  ["KN", 87.15686274509804],
  ["KY", 44.3221659524737],
  ["FI", 73.88488507985976],
  ["ER", 88.84073496948449],
  ["GM", 80.60316841968576],
  ["EE", 94.41306323854045],
  ["EG", 95.4528632645111],
  ["ID", 97.94085183742371],
  ["IS", 47.28931307622386],
  ["SK", 94.86949746786132],
  ["RO", 36.884820153226855],
  ["PG", 89.23905986235555],
  ["KM", 88.19244253992989],
  ["IR", 91.75561615374626],
  ["KZ", 95.34995455135696],
  ["IE", 94.65556421243994],
  ["GN", 69.97273081417997],
  ["FJ", 84.73737176990002],
  ["GY", 82.62271133619011],
  ["JM", 88.76347227632775],
  ["KI", 74.02220490845345],
  ["HR", 73.41221919231269],
  ["SO", 97.8879366316063],
  ["PT", 93.82482794442281],
  ["SY", 95.2373068432671],
  ["SN", 96.25957667835347],
  ["KH", 92.55810933644982],
  ["EC", 93.18659914296845],
  ["ET", 95.9287754837034],
  ["DE", 96.11219322165952],
  ["SL", 96.64069601350474],
  ["PW", 93.62874951305025],
  ["SZ", 86.44948707960005],
  ["PA", 95.02791845214908],
  ["SM", 92.09128684586418],
  ["IT", 94.20821971172575],
  ["JO", 92.23412543825478],
  ["GH", 91.29398779379302],
  ["CG", 93.26808206726399],
  ["BT", 82.06174522789247],
  ["AO", 92.72302298402805],
  ["ML", 70.26717309440332],
  ["TN", 93.97415920010388],
  ["LI", 66.34203350214258],
  ["MM", 95.04025451240099],
  ["MZ", 88.06226464095572],
  ["NA", 73.89527334112454],
  ["CF", 82.08609271523179],
  ["BB", 0],
  ["CD", 96.21218023633294],
  ["BW", 95.60803791715362],
  ["AL", 93.07232826905597],
  ["NC", 92.92754187767822],
  ["MX", 91.69328658615764],
  ["TZ", 89.37962602259446],
  ["VE", 73.52746396571874],
  ["MN", 92.83404752629528],
  ["MY", 86.1469289702636],
  ["AM", 94.00889494870796],
  ["CR", 72.66945851188157],
  ["BA", 38.495974548759904],
  ["AZ", 97.89118296325152],
  ["CV", 44.79191014154006],
  ["BR", 89.85813530710297],
  ["CA", 93.98032723022985],
  ["BE", 70.74665627840541],
  ["TH", 94.00240228541747],
  ["WS", 82.19744189066354],
  ["UZ", 96.5887547071809],
  ["Wales", 87.83047656148553],
  ["NG", 94.06181015452539],
  ["NP", 83.14537073107388],
  ["MK", 84.42637319828594],
  ["BD", 98.05836904298143],
  ["BS", 90.37852226983509],
  ["CU", 61.10310349305285],
  ["BF", 98.35281132320478],
  ["NR", 36.291066095312296],
  ["NE", 97.26658875470719],
  ["ZW", 91.9260485651214],
  ["VC", 73.71380340215556],
  ["UY", 89.64485131801064],
  ["TJ", 92.3435268146994],
  ["VU", 84.65004544864303],
  ["ZA", 80.24574730554474],
  ["BG", 98.2385404492923],
]);

export const cdMap = new Map<string, number>([
  ["CY", 90.78123194267884],
  ["AF", 88.59961862937709],
  ["CN", 72.91372934242459],
  ["BJ", 89.38229515774876],
  ["LA", 88.34421587888593],
  ["ME", 70.83872645325322],
  ["OM", 79.67410146769906],
  ["NI", 93.00271582110251],
  ["MR", 91.23338726453252],
  ["TG", 87.93337570784699],
  ["TP", 83.03045186640472],
  ["North Korea", 81.52374898879002],
  ["VN", 78.42077892060557],
  ["ZM", 90.20888709118225],
  ["MD", 83.64382295157749],
  ["CO", 85.63879579336646],
  ["AG", 85.01502369120536],
  ["AE", 90.11181093262452],
  ["CZ", 89.86796486767595],
  ["AR", 91.2408991101352],
  ["BI", 86.0424130359413],
  ["CM", 87.46590777764936],
  ["LB", 82.8932162255865],
  ["LU", 88.94314110713047],
  ["TD", 88.0388304634231],
  ["TR", 76.74448168265342],
  ["Scotland", 87.27262221194961],
  ["UA", 91.61244655032937],
  ["LT", 88.56090373280942],
  ["LC", 93.00560499248816],
  ["MG", 94.15578412111407],
  ["CL", 85.067028776147],
  ["BH", 80.32069802380677],
  ["AD", 85.32849878654801],
  ["CH", 91.95539119380561],
  ["NO", 80.9990754651566],
  ["MT", 84.30110944181209],
  ["MC", 85.627816942101],
  ["TV", 82.31913787125852],
  ["TW", 92.47862013174621],
  ["US", 83.72269733040564],
  ["MU", 89.67265688200624],
  ["BZ", 84.09511152201549],
  ["CI", 92.60689934126893],
  ["BM", 72.94724373049809],
  ["CK", 91.53617242574829],
  ["AT", 83.76516814977465],
  ["BO", 86.36166647405524],
  ["LS", 95.06962903039408],
  ["NL", 94.08124349936439],
  ["MW", 85.47237952155322],
  ["YE", 90.52582919218767],
  ["UG", 88.75621171847914],
  ["TT", 77.31711545128857],
  ["NZ", 90.88697561539351],
  ["MA", 76.77019530798567],
  ["MV", 85.24211256211719],
  ["LR", 85.21206517970647],
  ["BY", 88.08245695134636],
  ["BN", 86.8239338957587],
  ["AU", 90.17970646018722],
  ["GE", 89.63480873685427],
  ["GR", 92.41881428406333],
  ["IN", 91.79793135328788],
  ["GBENG", 89.91130243846065],
  ["RS", 86.43765168149775],
  ["PL", 86.02045533341037],
  ["SV", 92.83572171501213],
  ["SA", 87.80683000115566],
  ["HK", 77.13394198543858],
  ["KG", 74.07604299087022],
  ["GD", 78.14515197041489],
  ["GQ", 87.68028429446434],
  ["DJ", 90.55414307176702],
  ["KE", 90.38714896567664],
  ["KR", 90.310585923957],
  ["SC", 73.44071420316654],
  ["ST", 87.55200508494164],
  ["PY", 87.6291459609384],
  ["SB", 86.60406795331099],
  ["IL", 91.78926383913094],
  ["DK", 81.17040332832543],
  ["DO", 81.20218421356755],
  ["RU", 89.33982433837974],
  ["SG", 82.0822258176355],
  ["PK", 94.95059516930544],
  ["GB", 81.92303247428637],
  ["GU", 91.16635848838554],
  ["GW", 84.36900496937479],
  ["JP", 87.33358372818675],
  ["SR", 92.44741708078124],
  ["SE", 91.89442967756848],
  ["SD", 88.1393736276436],
  ["SS", 85.55587657459841],
  ["PH", 88.15381948457183],
  ["RW", 88.95614237836588],
  ["KU", 92.47775338033051],
  ["HN", 93.01311683809084],
  ["GA", 90.89824338379753],
  ["DZ", 89.46550329365537],
  ["DM", 86.28481451519704],
  ["FR", 89.94828383219694],
  ["GL", 86.94354559112446],
  ["ES", 79.76742170345545],
  ["HT", 86.93574482838322],
  ["PE", 82.60429908702184],
  ["QA", 92.25210909511152],
  ["SI", 86.99843984745175],
  ["PR", 86.579509996533],
  ["PS", 92.22119496128511],
  ["IQ", 88.77730266959436],
  ["HU", 88.871778573905],
  ["KN", 91.30243846064948],
  ["KY", 89.06766439385184],
  ["FI", 95.14301398358951],
  ["ER", 71.5009245348434],
  ["GM", 89.3337570784699],
  ["EE", 96.65202819831272],
  ["EG", 89.76077660926846],
  ["ID", 85.79856697099272],
  ["IS", 89.61429562001618],
  ["SK", 84.76337686351553],
  ["RO", 88.03016294926616],
  ["PG", 86.44111868716053],
  ["KM", 84.93817173234717],
  ["IR", 85.25626950190686],
  ["KZ", 83.85155437420548],
  ["IE", 93.53981278169421],
  ["GN", 86.19467236796487],
  ["FJ", 83.8284410031203],
  ["GY", 83.33034785623484],
  ["JM", 89.67352363342194],
  ["KI", 77.07326938633999],
  ["HR", 89.21385646596556],
  ["SO", 92.56991794753264],
  ["PT", 87.02559805847683],
  ["SY", 89.5111522015486],
  ["SN", 86.9579914480527],
  ["KH", 85.56107708309257],
  ["EC", 81.10192996648561],
  ["ET", 78.27949843984744],
  ["DE", 93.81659540043916],
  ["SL", 93.97232173812552],
  ["PW", 88.51843291344042],
  ["SZ", 84.14885010978851],
  ["PA", 84.50912978157864],
  ["SM", 88.3967987981047],
  ["IT", 88.45458222581763],
  ["JO", 87.1902808274587],
  ["GH", 86.20362879926037],
  ["CG", 88.67791517392811],
  ["BT", 88.89893678493009],
  ["AO", 87.4910435687045],
  ["ML", 85.89448746099619],
  ["TN", 77.68461805154281],
  ["LI", 85.76158557725644],
  ["MM", 82.55431642205015],
  ["MZ", 86.12475442043221],
  ["NA", 71.97821564775222],
  ["CF", 89.03212758580838],
  ["BB", 96.33999768866289],
  ["CD", 0],
  ["BW", 92.63925806078817],
  ["AL", 77.6112330983474],
  ["NC", 89.88703339882122],
  ["MX", 85.3923494741708],
  ["TZ", 90.83497053045187],
  ["VE", 84.39211834045996],
  ["MN", 80.86877383566393],
  ["MY", 78.38581994683923],
  ["AM", 88.8423090257714],
  ["CR", 86.19351669941061],
  ["BA", 93.3494163873801],
  ["AZ", 86.6979660233445],
  ["CV", 89.93586039523865],
  ["BR", 87.55258291921876],
  ["CA", 90.20628683693516],
  ["BE", 90.451288570438],
  ["TH", 92.06402403790594],
  ["WS", 77.73980122500866],
  ["UZ", 89.75904310643708],
  ["Wales", 76.34693169998845],
  ["NG", 94.12458107014908],
  ["NP", 78.96972148387842],
  ["MK", 77.21397203282099],
  ["BD", 83.86600023113371],
  ["BS", 93.63920027736046],
  ["CU", 86.84964752109096],
  ["BF", 85.39610539697215],
  ["NR", 95.20253091413383],
  ["NE", 93.23558303478562],
  ["ZW", 84.0344389229169],
  ["VC", 89.57095804923148],
  ["UY", 91.27556916676298],
  ["TJ", 92.04235525251357],
  ["VU", 86.24609961862937],
  ["ZA", 89.77580030047383],
  ["BG", 88.83046342309025],
]);

export const bwMap = new Map<string, number>([
  ["CY", 91.4689650694715],
  ["AF", 90.18698870276587],
  ["CN", 98.02590572652902],
  ["BJ", 98.6293987793793],
  ["LA", 97.46753668354759],
  ["ME", 94.37735359044281],
  ["OM", 99.0741462147773],
  ["NI", 91.98318400207765],
  ["MR", 96.50824568237891],
  ["TG", 95.4392286716011],
  ["TP", 92.12504869497468],
  ["North Korea", 97.68017140631086],
  ["VN", 98.76606934164394],
  ["ZM", 96.02843786521231],
  ["MD", 94.10141540059732],
  ["CO", 97.57174392935983],
  ["AG", 96.18263861836125],
  ["AE", 93.76801714063109],
  ["CZ", 96.97506817296455],
  ["AR", 26.09044279963641],
  ["BI", 92.9473445007142],
  ["CM", 98.03629398779378],
  ["LB", 91.62219192312686],
  ["LU", 91.61147902869757],
  ["TD", 97.91520581742631],
  ["TR", 99.05142189326061],
  ["Scotland", 95.5216854953902],
  ["UA", 97.35099337748345],
  ["LT", 99.77924944812362],
  ["LC", 93.6871834826646],
  ["MG", 97.09355927801585],
  ["CL", 97.02181534865602],
  ["BH", 97.51266069341644],
  ["AD", 93.76866640696014],
  ["CH", 97.53830671341385],
  ["NO", 88.37715881054409],
  ["MT", 93.65569406570575],
  ["MC", 96.02649006622516],
  ["TV", 87.13835865472018],
  ["TW", 98.9864952603558],
  ["US", 92.23412543825478],
  ["MU", 95.80573951434879],
  ["BZ", 92.04875990131151],
  ["CI", 96.71763407349695],
  ["BM", 94.10531099857161],
  ["CK", 95.3113232047786],
  ["AT", 91.83222958057395],
  ["BO", 94.48123620309052],
  ["LS", 91.26217374366965],
  ["NL", 91.16997792494482],
  ["MW", 99.55849889624724],
  ["YE", 91.3907284768212],
  ["UG", 90.2142578885859],
  ["TT", 95.22854174782496],
  ["NZ", 95.56745877158811],
  ["MA", 99.34489027399039],
  ["MV", 99.5740812881444],
  ["LR", 98.57518504090378],
  ["BY", 94.7587975587586],
  ["BN", 84.09005323983898],
  ["AU", 95.114595507077],
  ["GE", 95.0337618491105],
  ["GR", 91.68809245552526],
  ["IN", 91.1683547591222],
  ["GBENG", 95.50837553564472],
  ["RS", 91.60368783274899],
  ["PL", 96.02649006622516],
  ["SV", 91.8153486560187],
  ["SA", 97.81976366705622],
  ["HK", 97.62725620049343],
  ["KG", 94.20789507856122],
  ["GD", 92.80061031034931],
  ["GQ", 92.6292039994806],
  ["DJ", 65.75899233865732],
  ["KE", 96.29593559278015],
  ["KR", 92.6860148032723],
  ["SC", 92.85904427996364],
  ["ST", 90.78106739384495],
  ["PY", 92.20425918711855],
  ["SB", 93.15510972600961],
  ["IL", 90.90248019737697],
  ["DK", 98.9244903259317],
  ["DO", 94.80229840280484],
  ["RU", 99.33774834437085],
  ["SG", 92.3354109855863],
  ["PK", 96.30015582391897],
  ["GB", 93.32197117257499],
  ["GU", 97.44546162835995],
  ["GW", 97.0309050772627],
  ["JP", 94.43611219322166],
  ["SR", 98.02720425918712],
  ["SE", 98.64498117127646],
  ["SD", 93.77678223607325],
  ["SS", 99.21795870666146],
  ["PH", 95.49798727437995],
  ["RW", 91.92020516815998],
  ["KU", 89.89287105570705],
  ["HN", 92.12310089598753],
  ["GA", 93.58232697052331],
  ["DZ", 95.46714712375017],
  ["DM", 88.22458122321777],
  ["FR", 96.8504090377873],
  ["GL", 95.77717179587066],
  ["ES", 92.80677834047526],
  ["HT", 98.1697182184132],
  ["PE", 97.13543695623945],
  ["QA", 96.13946240747954],
  ["SI", 99.33222958057395],
  ["PR", 96.58420984287754],
  ["PS", 93.9394234514998],
  ["IQ", 91.80268796260226],
  ["HU", 92.05298013245033],
  ["KN", 84.92565900532398],
  ["KY", 94.1630957018569],
  ["FI", 98.97740553174911],
  ["ER", 91.34300740163616],
  ["GM", 96.90949227373068],
  ["EE", 83.22295805739515],
  ["EG", 91.31249188417088],
  ["ID", 96.02649006622516],
  ["IS", 95.06979613037268],
  ["SK", 98.89787040644073],
  ["RO", 97.91747824957797],
  ["PG", 89.43741072587976],
  ["KM", 91.39007921049215],
  ["IR", 84.79840280483054],
  ["KZ", 87.86716010907675],
  ["IE", 96.725100636281],
  ["GN", 97.7817815868069],
  ["FJ", 43.213219062459416],
  ["GY", 89.22997013374886],
  ["JM", 73.39923386573173],
  ["KI", 94.2763926762758],
  ["HR", 90.38014543565771],
  ["SO", 97.50097389949357],
  ["PT", 95.83430723282692],
  ["SY", 91.41020646669263],
  ["SN", 97.80483054148812],
  ["KH", 97.77204259187118],
  ["EC", 94.7545773276198],
  ["ET", 94.59907804181276],
  ["DE", 99.55849889624724],
  ["SL", 91.83222958057395],
  ["PW", 93.87449681859499],
  ["SZ", 89.9542267238021],
  ["PA", 95.74957797688612],
  ["SM", 58.19471497208155],
  ["IT", 97.24353980002597],
  ["JO", 95.00584339696144],
  ["GH", 92.29970133748864],
  ["CG", 94.110505129204],
  ["BT", 91.4231917932736],
  ["AO", 89.15433060641476],
  ["ML", 97.51947798987145],
  ["TN", 97.84865601869886],
  ["LI", 97.03577457473057],
  ["MM", 95.01330995974548],
  ["MZ", 86.91923126866641],
  ["NA", 95.43955330476561],
  ["CF", 95.04966887417218],
  ["BB", 95.60803791715362],
  ["CD", 92.2425659005324],
  ["BW", 0],
  ["AL", 95.65121412803532],
  ["NC", 93.2924295546033],
  ["MX", 96.57512011427087],
  ["TZ", 84.35462926892612],
  ["VE", 96.84748733930658],
  ["MN", 97.1860797299052],
  ["MY", 91.76340734969484],
  ["AM", 99.55849889624724],
  ["CR", 98.83359303986495],
  ["BA", 94.1079080638878],
  ["AZ", 98.94526684846124],
  ["CV", 97.14939618231398],
  ["BR", 89.2439293598234],
  ["CA", 97.95610959615635],
  ["BE", 92.27015971951694],
  ["TH", 98.89624724061811],
  ["WS", 98.98617062719127],
  ["UZ", 87.79541617971692],
  ["Wales", 91.41312816517335],
  ["NG", 97.13641085573302],
  ["NP", 93.71769900012985],
  ["MK", 86.66147253603428],
  ["BD", 97.95188936501754],
  ["BS", 87.05298013245033],
  ["CU", 95.3869627321127],
  ["BF", 99.46662771068692],
  ["NR", 98.41676405661602],
  ["NE", 92.85222698350863],
  ["ZW", 85.52136086222568],
  ["VC", 94.83216465394105],
  ["UY", 93.79820802493182],
  ["TJ", 91.16640696013505],
  ["VU", 83.87352291910142],
  ["ZA", 89.46532917802882],
  ["BG", 99.55849889624724],
]);

export const alMap = new Map<string, number>([
  ["CY", 95.14736141370128],
  ["AF", 85.50320745582184],
  ["CN", 13.995400629387559],
  ["BJ", 70.28413217138707],
  ["LA", 41.17526022754781],
  ["ME", 81.6264221738078],
  ["OM", 58.13664972161704],
  ["NI", 95.92743887678529],
  ["MR", 86.70630597918179],
  ["TG", 77.57352941176471],
  ["TP", 34.41872427983539],
  ["North Korea", 54.988501573468895],
  ["VN", 13.765432098765432],
  ["ZM", 81.07782619220528],
  ["MD", 62.23795691115953],
  ["CO", 67.68276446381022],
  ["AG", 44.45079883805374],
  ["AE", 71.43246187363835],
  ["CZ", 60.28413217138707],
  ["AR", 99.8087630113774],
  ["BI", 66.57256112321471],
  ["CM", 76.05664488017429],
  ["LB", 49.63356330186395],
  ["LU", 68.53425320745582],
  ["TD", 65.36885741951102],
  ["TR", 16.06118373275236],
  ["Scotland", 90.55010893246187],
  ["UA", 95.23632292423142],
  ["LT", 92.83769063180827],
  ["LC", 97.31027596223674],
  ["MG", 91.99830549503753],
  ["CL", 51.710844831759864],
  ["BH", 43.23378116678771],
  ["AD", 66.0923505204551],
  ["CH", 94.46986201888163],
  ["NO", 35.7253086419753],
  ["MT", 55.048414427499395],
  ["MC", 57.7081820382474],
  ["TV", 74.44444444444444],
  ["TW", 88.74001452432825],
  ["US", 84.24655047204067],
  ["MU", 68.53213507625271],
  ["BZ", 77.43796901476641],
  ["CI", 97.67096344710724],
  ["BM", 28.689784555797626],
  ["CK", 73.06977729363349],
  ["AT", 37.28637133865892],
  ["BO", 68.36450012103606],
  ["LS", 92.47095134350036],
  ["NL", 95.5476882110869],
  ["MW", 68.28824739772452],
  ["YE", 68.53546356814331],
  ["UG", 92.30725006051803],
  ["TT", 27.754780924715565],
  ["NZ", 72.63192931493585],
  ["MA", 90.45872670055677],
  ["MV", 26.742314209634472],
  ["LR", 91.07994432340838],
  ["BY", 45.11468167513919],
  ["BN", 94.51676349552166],
  ["AU", 86.50447833454369],
  ["GE", 88.84834180585814],
  ["GR", 94.72434035342532],
  ["IN", 97.64342774146697],
  ["GBENG", 78.29187847978699],
  ["RS", 62.08212297264585],
  ["PL", 53.85469619946745],
  ["SV", 93.88495521665456],
  ["SA", 89.67532074558218],
  ["HK", 15.184882595013313],
  ["KG", 14.761256354393609],
  ["GD", 54.240498668603244],
  ["GQ", 65.08865892035827],
  ["DJ", 94.63810215444202],
  ["KE", 84.44323408375696],
  ["KR", 91.95896877269426],
  ["SC", 71.5338295812152],
  ["ST", 82.19317356572259],
  ["PY", 67.10421205519245],
  ["SB", 82.5051440329218],
  ["IL", 93.23317598644397],
  ["DK", 31.072076978939723],
  ["DO", 64.46653352699104],
  ["RU", 57.4379690147664],
  ["SG", 61.1217017671266],
  ["PK", 84.10796417332365],
  ["GB", 63.59174534011135],
  ["GU", 72.24431130476881],
  ["GW", 64.6393125151295],
  ["JP", 86.62460663277656],
  ["SR", 90.55101670297748],
  ["SE", 92.39015976761074],
  ["SD", 70.19426289034132],
  ["SS", 78.3348462841927],
  ["PH", 55.130416364076495],
  ["RW", 90.62394093439845],
  ["KU", 68.10306221253933],
  ["HN", 95.94438392641007],
  ["GA", 95.65571290244493],
  ["DZ", 91.9259864439603],
  ["DM", 91.03939724037762],
  ["FR", 65.70987654320987],
  ["GL", 56.41460905349794],
  ["ES", 47.90002420721375],
  ["HT", 44.50738320019366],
  ["PE", 34.337630113773905],
  ["QA", 82.87188332122972],
  ["SI", 59.43566932946018],
  ["PR", 87.01767126603728],
  ["PS", 78.36086903897362],
  ["IQ", 67.38592350520454],
  ["HU", 68.67556281771968],
  ["KN", 60.98039215686275],
  ["KY", 72.30422415879933],
  ["FI", 89.1999515855725],
  ["ER", 59.27045509561849],
  ["GM", 61.599794238683124],
  ["EE", 88.5702614379085],
  ["EG", 68.46707818930041],
  ["ID", 57.88943355119825],
  ["IS", 78.94274993948197],
  ["SK", 58.7657346889373],
  ["RO", 65.3815662067296],
  ["PG", 56.57740256596466],
  ["KM", 78.80961026385863],
  ["IR", 90.24782135076252],
  ["KZ", 88.6679980634229],
  ["IE", 97.65704429920116],
  ["GN", 65.59337932703946],
  ["FJ", 83.7878237714839],
  ["GY", 74.57576857903655],
  ["JM", 92.52239167271847],
  ["KI", 48.288549987896396],
  ["HR", 94.10917453401113],
  ["SO", 92.63435003631082],
  ["PT", 88.81445170660857],
  ["SY", 67.27880658436214],
  ["SN", 65.65813362381991],
  ["KH", 60.73983297022513],
  ["EC", 68.25042362624062],
  ["ET", 58.462539336722344],
  ["DE", 88.40474461389493],
  ["SL", 96.54078915516824],
  ["PW", 95.30137981118372],
  ["SZ", 82.04278625030258],
  ["PA", 73.3986928104575],
  ["SM", 95.8315177923021],
  ["IT", 66.22579278625031],
  ["JO", 71.94020818203825],
  ["GH", 65.9389373033164],
  ["CG", 63.92550229968531],
  ["BT", 94.37817719680464],
  ["AO", 55.320140401839744],
  ["ML", 65.97373517308158],
  ["TN", 14.942205277172596],
  ["LI", 42.67156862745098],
  ["MM", 65.58823529411765],
  ["MZ", 71.82159283466473],
  ["NA", 67.18076736867586],
  ["CF", 84.20025417574438],
  ["BB", 93.14239893488259],
  ["CD", 77.58169934640523],
  ["BW", 95.34283466473009],
  ["AL", 0],
  ["NC", 88.86861534737352],
  ["MX", 64.72736625514403],
  ["TZ", 86.27874606632776],
  ["VE", 56.64941902687001],
  ["MN", 80.32467925441782],
  ["MY", 59.68500363108207],
  ["AM", 58.533648027112086],
  ["CR", 75.9903776325345],
  ["BA", 87.81771968046478],
  ["AZ", 70.94559428709756],
  ["CV", 81.74019607843137],
  ["BR", 85.53679496489954],
  ["CA", 93.57933914306463],
  ["BE", 66.5674170902929],
  ["TH", 57.27366255144033],
  ["WS", 25.31136528685548],
  ["UZ", 91.3655894456548],
  ["Wales", 77.94026870007262],
  ["NG", 97.5756475429678],
  ["NP", 62.78503994190269],
  ["MK", 33.96211571048172],
  ["BD", 87.65129508593562],
  ["BS", 93.44256838537885],
  ["CU", 74.04895908980876],
  ["BF", 54.03836843379327],
  ["NR", 83.81566206729606],
  ["NE", 95.59156378600822],
  ["ZW", 78.23741224885016],
  ["VC", 97.77656741709029],
  ["UY", 90.9347010409102],
  ["TJ", 70.77553861050593],
  ["VU", 65.18276446381022],
  ["ZA", 71.68784797869765],
  ["BG", 62.3665577342048],
]);

export const ncMap = new Map<string, number>([
  ["CY", 92.99610726643598],
  ["AF", 85.90441176470588],
  ["CN", 93.32439446366782],
  ["BJ", 83.40830449826989],
  ["LA", 92.26340830449827],
  ["ME", 80.32828719723183],
  ["OM", 91.15960207612457],
  ["NI", 92.05060553633217],
  ["MR", 89.99826989619378],
  ["TG", 89.02335640138408],
  ["TP", 75.73875432525952],
  ["North Korea", 75.00735294117648],
  ["VN", 73.93122837370242],
  ["ZM", 91.39749134948096],
  ["MD", 79.59256055363322],
  ["CO", 89.23961937716263],
  ["AG", 86.50432525951557],
  ["AE", 93.09299307958477],
  ["CZ", 91.50259515570934],
  ["AR", 92.29887543252595],
  ["BI", 86.60034602076124],
  ["CM", 85.2720588235294],
  ["LB", 89.9212802768166],
  ["LU", 94.43209342560553],
  ["TD", 89.02768166089965],
  ["TR", 91.0583910034602],
  ["Scotland", 87.29887543252595],
  ["UA", 90.17776816608996],
  ["LT", 94.92863321799308],
  ["LC", 92.44506920415225],
  ["MG", 67.61418685121107],
  ["CL", 76.5765570934256],
  ["BH", 92.60337370242215],
  ["AD", 79.74307958477509],
  ["CH", 91.80882352941177],
  ["NO", 85.51643598615917],
  ["MT", 93.60121107266436],
  ["MC", 94.37153979238755],
  ["TV", 83.76686851211073],
  ["TW", 90.81574394463668],
  ["US", 83.28070934256056],
  ["MU", 66.60510380622837],
  ["BZ", 67.15787197231833],
  ["CI", 83.31531141868513],
  ["BM", 85.66565743944636],
  ["CK", 86.54887543252595],
  ["AT", 93.75865051903114],
  ["BO", 59.58391003460207],
  ["LS", 40.406141868512115],
  ["NL", 93.30276816608996],
  ["MW", 88.0129757785467],
  ["YE", 94.24826989619378],
  ["UG", 87.31185121107266],
  ["TT", 90.59299307958477],
  ["NZ", 86.46626297577855],
  ["MA", 93.33607266435986],
  ["MV", 90.14792387543253],
  ["LR", 90.09342560553634],
  ["BY", 88.90657439446366],
  ["BN", 89.840830449827],
  ["AU", 87.70934256055364],
  ["GE", 89.49870242214533],
  ["GR", 92.81012110726644],
  ["IN", 92.40873702422145],
  ["GBENG", 94.18209342560554],
  ["RS", 89.97318339100346],
  ["PL", 94.72102076124568],
  ["SV", 61.92776816608997],
  ["SA", 88.28979238754326],
  ["HK", 89.9628027681661],
  ["KG", 87.1985294117647],
  ["GD", 80.15181660899654],
  ["GQ", 89.13235294117648],
  ["DJ", 67.42949826989619],
  ["KE", 87.64143598615917],
  ["KR", 92.19679930795847],
  ["SC", 66.42863321799308],
  ["ST", 62.39403114186851],
  ["PY", 91.65570934256056],
  ["SB", 68.24956747404845],
  ["IL", 77.27638408304497],
  ["DK", 92.76686851211072],
  ["DO", 91.9636678200692],
  ["RU", 94.27465397923875],
  ["SG", 82.64532871972318],
  ["PK", 90.9515570934256],
  ["GB", 80.85510380622839],
  ["GU", 85.79108996539793],
  ["GW", 71.9294982698962],
  ["JP", 93.0272491349481],
  ["SR", 90.97318339100346],
  ["SE", 59.80882352941177],
  ["SD", 91.74437716262975],
  ["SS", 63.92387543252595],
  ["PH", 66.28027681660899],
  ["RW", 89.65181660899654],
  ["KU", 92.3174740484429],
  ["HN", 92.8568339100346],
  ["GA", 87.83520761245674],
  ["DZ", 91.85034602076124],
  ["DM", 87.40441176470588],
  ["FR", 74.38062283737025],
  ["GL", 93.04152249134948],
  ["ES", 90.44290657439447],
  ["HT", 63.57309688581315],
  ["PE", 94.81574394463668],
  ["QA", 92.31704152249135],
  ["SI", 93.95501730103805],
  ["PR", 86.56617647058825],
  ["PS", 66.45025951557093],
  ["IQ", 92.18858131487889],
  ["HU", 65.09385813148789],
  ["KN", 88.12673010380622],
  ["KY", 85.39965397923875],
  ["FI", 93.34991349480968],
  ["ER", 81.37932525951557],
  ["GM", 93.13581314878893],
  ["EE", 96.92301038062284],
  ["EG", 92.21885813148789],
  ["ID", 94.3166089965398],
  ["IS", 59.36851211072665],
  ["SK", 93.03114186851211],
  ["RO", 88.84558823529412],
  ["PG", 87.98053633217992],
  ["KM", 90.34904844290658],
  ["IR", 86.50216262975778],
  ["KZ", 88.92733564013841],
  ["IE", 86.12586505190312],
  ["GN", 78.94074394463668],
  ["FJ", 84.33823529411765],
  ["GY", 64.41522491349481],
  ["JM", 72.91046712802768],
  ["KI", 84.39532871972318],
  ["HR", 87.46929065743944],
  ["SO", 94.64878892733563],
  ["PT", 88.31141868512111],
  ["SY", 93.27768166089966],
  ["SN", 84.29195501730104],
  ["KH", 66.03676470588236],
  ["EC", 85.45588235294119],
  ["ET", 83.04368512110727],
  ["DE", 91.72231833910035],
  ["SL", 94.87629757785467],
  ["PW", 86.75],
  ["SZ", 84.89965397923875],
  ["PA", 91.91392733564014],
  ["SM", 88.77984429065744],
  ["IT", 75.91479238754324],
  ["JO", 67.60467128027682],
  ["GH", 88.41911764705883],
  ["CG", 78.24653979238755],
  ["BT", 78.9264705882353],
  ["AO", 92.31790657439446],
  ["ML", 90.16435986159169],
  ["TN", 91.14489619377163],
  ["LI", 92.63278546712803],
  ["MM", 91.42949826989619],
  ["MZ", 90.55060553633218],
  ["NA", 65.84991349480968],
  ["CF", 91.45328719723183],
  ["BB", 92.75302768166091],
  ["CD", 89.34818339100346],
  ["BW", 93.1258650519031],
  ["AL", 88.50692041522491],
  ["NC", 0],
  ["MX", 90.03762975778547],
  ["TZ", 89.96842560553632],
  ["VE", 93.38148788927334],
  ["MN", 80.49437716262976],
  ["MY", 69.18555363321799],
  ["AM", 95.11332179930795],
  ["CR", 92.7772491349481],
  ["BA", 74.74524221453287],
  ["AZ", 92.03070934256056],
  ["CV", 61.430363321799305],
  ["BR", 61.25821799307959],
  ["CA", 90.10034602076125],
  ["BE", 83.32179930795847],
  ["TH", 94.54152249134948],
  ["WS", 92.11418685121107],
  ["UZ", 90.34645328719724],
  ["Wales", 59.05449826989619],
  ["NG", 75.20934256055364],
  ["NP", 85.83737024221453],
  ["MK", 76.92647058823529],
  ["BD", 87.90787197231835],
  ["BS", 94.31790657439446],
  ["CU", 73.4870242214533],
  ["BF", 55.20891003460208],
  ["NR", 91.5242214532872],
  ["NE", 65.58996539792388],
  ["ZW", 88.99394463667821],
  ["VC", 78.90311418685121],
  ["UY", 82.83996539792388],
  ["TJ", 89.50432525951557],
  ["VU", 65.13451557093425],
  ["ZA", 86.49091695501731],
  ["BG", 94.84861591695501],
]);

export const mxMap = new Map<string, number>([
  ["CY", 74.68958459672528],
  ["AF", 94.98673438447544],
  ["CN", 60.574969678593085],
  ["BJ", 46.80109157064888],
  ["LA", 78.16555488174652],
  ["ME", 68.85498787143723],
  ["OM", 71.21816252274105],
  ["NI", 91.64796846573681],
  ["MR", 61.41449363250455],
  ["TG", 85.50598847786537],
  ["TP", 59.01341722255913],
  ["North Korea", 89.03767434808975],
  ["VN", 61.88485445724682],
  ["ZM", 86.8298969072165],
  ["MD", 58.37022437841116],
  ["CO", 86.71846573681019],
  ["AG", 62.85438144329897],
  ["AE", 91.86173438447544],
  ["CZ", 66.47854760460885],
  ["AR", 91.875],
  ["BI", 75.961188599151],
  ["CM", 63.83489993935718],
  ["LB", 71.64948453608247],
  ["LU", 81.61158277744087],
  ["TD", 57.97869921164342],
  ["TR", 61.32315039417829],
  ["Scotland", 84.23969072164948],
  ["UA", 93.10529108550637],
  ["LT", 74.40115221346271],
  ["LC", 93.94746816252274],
  ["MG", 96.40805033353547],
  ["CL", 65.9020618556701],
  ["BH", 60.96952698605216],
  ["AD", 59.60544269254093],
  ["CH", 88.29821103699211],
  ["NO", 89.67366585809582],
  ["MT", 49.37348392965434],
  ["MC", 66.80147058823529],
  ["TV", 83.54949969678593],
  ["TW", 93.18109460278957],
  ["US", 61.212098241358404],
  ["MU", 85.10119769557308],
  ["BZ", 76.94739235900546],
  ["CI", 70.43208004851425],
  ["BM", 63.74317768344451],
  ["CK", 87.25022741055184],
  ["AT", 70.89940873256519],
  ["BO", 71.7518192844148],
  ["LS", 91.29699818071558],
  ["NL", 91.79237416616131],
  ["MW", 84.5004548211037],
  ["YE", 81.5058368708308],
  ["UG", 93.2478016979988],
  ["TT", 68.71778350515464],
  ["NZ", 87.8528653729533],
  ["MA", 63.37098241358399],
  ["MV", 62.86764705882353],
  ["LR", 65.66782898726501],
  ["BY", 89.23438447543967],
  ["BN", 90.33201940570042],
  ["AU", 86.09763493026077],
  ["GE", 76.98188295936932],
  ["GR", 85.5609460278957],
  ["IN", 92.33588538508187],
  ["GBENG", 73.8375530624621],
  ["RS", 81.41828380836871],
  ["PL", 67.6633565797453],
  ["SV", 92.353320194057],
  ["SA", 60.99492116434203],
  ["HK", 59.368556701030926],
  ["KG", 58.89364766525167],
  ["GD", 63.37401455427531],
  ["GQ", 77.95747422680412],
  ["DJ", 92.34877198302001],
  ["KE", 93.77312007277138],
  ["KR", 76.58315645845968],
  ["SC", 68.24514857489388],
  ["ST", 89.39622498483931],
  ["PY", 80.7622043662826],
  ["SB", 87.6231807155852],
  ["IL", 81.58732565191025],
  ["DK", 58.63553668890237],
  ["DO", 72.16760157671315],
  ["RU", 75.1417525773196],
  ["SG", 63.36719223771983],
  ["PK", 93.88455124317768],
  ["GB", 68.4304881746513],
  ["GU", 89.76046088538509],
  ["GW", 92.49696785930868],
  ["JP", 80.71293208004852],
  ["SR", 76.55738326258337],
  ["SE", 92.72058823529412],
  ["SD", 63.94784718010915],
  ["SS", 79.62704669496664],
  ["PH", 77.49886294724075],
  ["RW", 88.65865676167374],
  ["KU", 73.3073074590661],
  ["HN", 92.7058065494239],
  ["GA", 93.07951788963008],
  ["DZ", 52.79298059429958],
  ["DM", 68.07913887204367],
  ["FR", 37.34990903577926],
  ["GL", 65.66669193450576],
  ["ES", 74.40986961795028],
  ["HT", 79.92116434202548],
  ["PE", 37.34990903577926],
  ["QA", 95.92480291085506],
  ["SI", 72.65046998180715],
  ["PR", 80.69284414796847],
  ["PS", 82.49014554275318],
  ["IQ", 82.43480897513645],
  ["HU", 70.17700121285627],
  ["KN", 73.21634323832626],
  ["KY", 86.28259551243178],
  ["FI", 82.46171922377198],
  ["ER", 78.64235900545785],
  ["GM", 84.06761673741661],
  ["EE", 87.5803517283202],
  ["EG", 81.07072468162522],
  ["ID", 66.79389023650697],
  ["IS", 88.3308065494239],
  ["SK", 82.9093389933293],
  ["RO", 58.12916919345058],
  ["PG", 71.55852031534263],
  ["KM", 78.12310491206792],
  ["IR", 85.14061552456033],
  ["KZ", 92.02092177077016],
  ["IE", 70.58520315342632],
  ["GN", 91.06617647058823],
  ["FJ", 85.92290782292298],
  ["GY", 85.38887204366283],
  ["JM", 89.69906003638569],
  ["KI", 68.01091570648879],
  ["HR", 91.52099757428745],
  ["SO", 96.05215281989085],
  ["PT", 92.81913280776229],
  ["SY", 80.26076409945422],
  ["SN", 25.73681018799272],
  ["KH", 75.9164645239539],
  ["EC", 84.15213765918739],
  ["ET", 84.4837780473014],
  ["DE", 94.89160097028501],
  ["SL", 91.88258035172832],
  ["PW", 92.46361431170406],
  ["SZ", 76.53767434808975],
  ["PA", 64.8483929654336],
  ["SM", 84.327622801698],
  ["IT", 37.34990903577926],
  ["JO", 86.16320497271074],
  ["GH", 72.45110673135233],
  ["CG", 66.11582777440873],
  ["BT", 88.88379320800485],
  ["AO", 78.19549727107338],
  ["ML", 57.982868405094],
  ["TN", 58.51349302607641],
  ["LI", 77.79677077016373],
  ["MM", 80.0731503941783],
  ["MZ", 89.81352334748333],
  ["NA", 78.93761370527592],
  ["CF", 90.53365676167374],
  ["BB", 91.65668587022438],
  ["CD", 85.1254548211037],
  ["BW", 96.5505609460279],
  ["AL", 64.69640691328078],
  ["NC", 90.11749545178897],
  ["MX", 0],
  ["TZ", 92.54661916312918],
  ["VE", 83.5275166767738],
  ["MN", 59.35908126137053],
  ["MY", 66.14425409338993],
  ["AM", 85.79707398423287],
  ["CR", 72.79298059429958],
  ["BA", 90.21869314736205],
  ["AZ", 84.56071861734384],
  ["CV", 88.56845057610673],
  ["BR", 91.22271073377804],
  ["CA", 75.7978320194057],
  ["BE", 59.55844451182535],
  ["TH", 76.69307155852032],
  ["WS", 61.0297907822923],
  ["UZ", 88.40774711946634],
  ["Wales", 79.44966646452394],
  ["NG", 37.343844754396606],
  ["NP", 75.22210430563977],
  ["MK", 82.95936931473621],
  ["BD", 65.23991813220134],
  ["BS", 91.93109460278957],
  ["CU", 81.444057004245],
  ["BF", 95.22854760460885],
  ["NR", 93.98195876288659],
  ["NE", 91.69534566403881],
  ["ZW", 83.92851728320194],
  ["VC", 91.56989084293511],
  ["UY", 78.31602486355366],
  ["TJ", 88.52448453608247],
  ["VU", 79.63955427531837],
  ["ZA", 78.19398120072772],
  ["BG", 74.00507883565798],
]);

export const tzMap = new Map<string, number>([
  ["CY", 90.97758486654574],
  ["AF", 81.91370821456336],
  ["CN", 91.95063487950246],
  ["BJ", 91.25680228038352],
  ["LA", 94.1079295154185],
  ["ME", 83.60229334024358],
  ["OM", 90.21767297227261],
  ["NI", 93.7130733350609],
  ["MR", 87.55279865250066],
  ["TG", 88.7153407618554],
  ["TP", 91.8955688002073],
  ["North Korea", 92.85371857994299],
  ["VN", 94.16008033169216],
  ["ZM", 94.7165716506867],
  ["MD", 89.41079295154185],
  ["CO", 90.98892200051827],
  ["AG", 84.04282197460482],
  ["AE", 81.45180098471107],
  ["CZ", 90.52604301632547],
  ["AR", 89.467802539518],
  ["BI", 80.05085514381965],
  ["CM", 92.82845296708993],
  ["LB", 93.35190463850739],
  ["LU", 75.28763928478881],
  ["TD", 91.61311220523451],
  ["TR", 93.98710805908267],
  ["Scotland", 91.40807203938844],
  ["UA", 91.40191759523194],
  ["LT", 92.07696294376781],
  ["LC", 84.24041202384038],
  ["MG", 94.1040424980565],
  ["CL", 93.51710287639284],
  ["BH", 92.37075667271314],
  ["AD", 91.55966571650687],
  ["CH", 91.94707178025395],
  ["NO", 92.25932884166883],
  ["MT", 92.69597045866806],
  ["MC", 93.65379632029023],
  ["TV", 84.06484840632288],
  ["TW", 94.07910080331692],
  ["US", 81.7507774034724],
  ["MU", 92.3966701217932],
  ["BZ", 85.83246955169733],
  ["CI", 91.69117647058823],
  ["BM", 87.30824047680747],
  ["CK", 89.59801762114537],
  ["AT", 93.90871987561545],
  ["BO", 91.82851775071262],
  ["LS", 91.00835708732833],
  ["NL", 93.58512568022805],
  ["MW", 84.66960352422907],
  ["YE", 85.91118165327805],
  ["UG", 83.6440787768852],
  ["TT", 88.43450375745012],
  ["NZ", 88.67161181653277],
  ["MA", 94.43638248250842],
  ["MV", 94.4279606115574],
  ["LR", 91.75919927442344],
  ["BY", 90.04470069966312],
  ["BN", 86.40094584089142],
  ["AU", 87.1057916558694],
  ["GE", 89.76256802280383],
  ["GR", 91.94674786214046],
  ["IN", 90.41850220264317],
  ["GBENG", 93.00757968385592],
  ["RS", 88.72926924073595],
  ["PL", 93.73477584866545],
  ["SV", 92.41221819124125],
  ["SA", 91.65748898678414],
  ["HK", 93.27481212749417],
  ["KG", 91.01710287639285],
  ["GD", 86.60533817051049],
  ["GQ", 92.02805130862917],
  ["DJ", 89.28478880539001],
  ["KE", 81.5690593417984],
  ["KR", 87.64511531484841],
  ["SC", 91.607605597305],
  ["ST", 74.88209380668567],
  ["PY", 93.27060119201866],
  ["SB", 90.85060896605339],
  ["IL", 89.52707955428868],
  ["DK", 94.03018916817828],
  ["DO", 93.8970588235294],
  ["RU", 93.7853070743716],
  ["SG", 89.30130862917855],
  ["PK", 93.12386628660275],
  ["GB", 87.47667789582793],
  ["GU", 92.20005182689816],
  ["GW", 90.33201606633843],
  ["JP", 90.28861103912931],
  ["SR", 92.15599896346204],
  ["SE", 90.38449080072559],
  ["SD", 86.23769111168697],
  ["SS", 84.40431458927183],
  ["PH", 93.13099248509977],
  ["RW", 89.63753563099249],
  ["KU", 91.0342705364084],
  ["HN", 93.77558953096657],
  ["GA", 91.5327805130863],
  ["DZ", 90.68573464628142],
  ["DM", 92.23373931070226],
  ["FR", 93.48989375485878],
  ["GL", 91.76600155480695],
  ["ES", 89.37030318735424],
  ["HT", 93.92588753563099],
  ["PE", 93.87308888313034],
  ["QA", 92.87704068411506],
  ["SI", 91.61440787768852],
  ["PR", 89.81277533039648],
  ["PS", 85.09102098989375],
  ["IQ", 85.7880927701477],
  ["HU", 93.86855402954133],
  ["KN", 33.80085514381964],
  ["KY", 87.55636175174915],
  ["FI", 93.08693962166365],
  ["ER", 76.62833635656906],
  ["GM", 94.30519564654055],
  ["EE", 85.57786991448562],
  ["EG", 85.73399844519307],
  ["ID", 93.8533298782068],
  ["IS", 92.28200310961388],
  ["SK", 92.26451153148484],
  ["RO", 91.66040424980565],
  ["PG", 80.47713138118684],
  ["KM", 88.69622959315885],
  ["IR", 72.48412801243845],
  ["KZ", 64.04865250064785],
  ["IE", 72.83072039388443],
  ["GN", 91.9616480953615],
  ["FJ", 82.13688779476549],
  ["GY", 78.26153148484063],
  ["JM", 79.8590956206271],
  ["KI", 88.24857476030058],
  ["HR", 91.248704327546],
  ["SO", 94.54554288675823],
  ["PT", 90.27403472402177],
  ["SY", 86.07411246436901],
  ["SN", 93.60391293081109],
  ["KH", 92.70018139414357],
  ["EC", 88.99488209380668],
  ["ET", 89.01982378854626],
  ["DE", 83.67582275200829],
  ["SL", 74.81374708473697],
  ["PW", 85.84801762114537],
  ["SZ", 80.88721171287898],
  ["PA", 92.0695128271573],
  ["SM", 88.16338429644985],
  ["IT", 93.86823011142783],
  ["JO", 84.71624773257321],
  ["GH", 89.30713915522156],
  ["CG", 91.52306296968126],
  ["BT", 87.74229074889868],
  ["AO", 82.50745011661053],
  ["ML", 73.05649131899456],
  ["TN", 93.45231925369266],
  ["LI", 93.64958538481471],
  ["MM", 83.2867970976937],
  ["MZ", 82.83719875615444],
  ["NA", 90.95523451671419],
  ["CF", 89.07003109613889],
  ["BB", 89.4247214304224],
  ["CD", 91.1395439232962],
  ["BW", 85.04567245400364],
  ["AL", 86.4631381186836],
  ["NC", 90.49170769629438],
  ["MX", 92.75978232702772],
  ["TZ", 0],
  ["VE", 90.91247732573206],
  ["MN", 90.31841150557139],
  ["MY", 90.03044830266909],
  ["AM", 92.26775071261984],
  ["CR", 92.17608188649909],
  ["BA", 90.44733091474475],
  ["AZ", 94.38876651982379],
  ["CV", 92.01444674786214],
  ["BR", 67.51101321585902],
  ["CA", 92.63474993521638],
  ["BE", 85.43405027209121],
  ["TH", 93.70465146410987],
  ["WS", 95.04113760041461],
  ["UZ", 90.20342057527857],
  ["Wales", 81.49229074889868],
  ["NG", 93.8549494687743],
  ["NP", 88.55111427831044],
  ["MK", 79.59056750453485],
  ["BD", 92.29722726094843],
  ["BS", 70.44279606115575],
  ["CU", 90.0016195905675],
  ["BF", 92.02481212749417],
  ["NR", 92.38695257838818],
  ["NE", 93.5336227001814],
  ["ZW", 84.59575019435087],
  ["VC", 90.73626587198757],
  ["UY", 90.25913449080073],
  ["TJ", 90.51859289971496],
  ["VU", 86.02520082923037],
  ["ZA", 90.31387665198238],
  ["BG", 93.74611298263798],
]);

export const veMap = new Map<string, number>([
  ["CY", 94.20724581223217],
  ["AF", 90.86287495130503],
  ["CN", 59.63089209193612],
  ["BJ", 56.32482794442281],
  ["LA", 48.391442669783146],
  ["ME", 90.24834437086092],
  ["OM", 82.9973380080509],
  ["NI", 98.3180755746007],
  ["MR", 93.14634463056746],
  ["TG", 85.90410336319958],
  ["TP", 65.4285157771718],
  ["North Korea", 79.29554603298273],
  ["VN", 62.10459680560966],
  ["ZM", 88.5323334631866],
  ["MD", 72.53830671341385],
  ["CO", 24.25107128944293],
  ["AG", 62.23899493572264],
  ["AE", 89.58739124788988],
  ["CZ", 65.90312946370601],
  ["AR", 98.41936112193221],
  ["BI", 76.70010388261265],
  ["CM", 73.12946370601222],
  ["LB", 70.66484872094533],
  ["LU", 98.14894169588365],
  ["TD", 64.90098688482016],
  ["TR", 61.15049993507336],
  ["Scotland", 89.90942734709778],
  ["UA", 96.6494611089469],
  ["LT", 28.987793793013893],
  ["LC", 96.44364368263861],
  ["MG", 97.4987014673419],
  ["CL", 61.89553304765615],
  ["BH", 71.67153616413454],
  ["AD", 73.85534346188807],
  ["CH", 95.01460849240358],
  ["NO", 56.87378262563303],
  ["MT", 76.5767432800935],
  ["MC", 96.87865212310089],
  ["TV", 82.80710297363979],
  ["TW", 91.5929749383197],
  ["US", 71.26444617582132],
  ["MU", 83.31742630827166],
  ["BZ", 82.09777950915466],
  ["CI", 97.41754317621088],
  ["BM", 52.865212310089596],
  ["CK", 65.27593818984548],
  ["AT", 65.25710946630308],
  ["BO", 97.86164134527984],
  ["LS", 95.71743929359823],
  ["NL", 98.14894169588365],
  ["MW", 95.1697831450461],
  ["YE", 98.17393844955201],
  ["UG", 72.18315803142448],
  ["TT", 69.72828204129334],
  ["NZ", 64.80684326710818],
  ["MA", 59.96883521620568],
  ["MV", 66.59459810414232],
  ["LR", 70.28535255161668],
  ["BY", 89.87761329697442],
  ["BN", 63.252499675366835],
  ["AU", 83.65374626671861],
  ["GE", 88.52681469938969],
  ["GR", 91.45500584339696],
  ["IN", 96.90754447474353],
  ["GBENG", 91.99422152967148],
  ["RS", 70.42526944552655],
  ["PL", 64.06765355148683],
  ["SV", 97.34482534735749],
  ["SA", 90.57297753538501],
  ["HK", 61.85365536943254],
  ["KG", 60.39540319439034],
  ["GD", 63.39176730294766],
  ["GQ", 66.1131671211531],
  ["DJ", 95.2386053759252],
  ["KE", 89.11375146084924],
  ["KR", 89.0014283859239],
  ["SC", 81.16738085962862],
  ["ST", 87.75353850149331],
  ["PY", 97.35261654330606],
  ["SB", 85.6872484092975],
  ["IL", 91.05992728217115],
  ["DK", 66.8705362939878],
  ["DO", 77.32112712634722],
  ["RU", 60.59115699259836],
  ["SG", 96.99129983119076],
  ["PK", 93.03402155564213],
  ["GB", 77.54415011037527],
  ["GU", 64.89546812102324],
  ["GW", 61.65335670692118],
  ["JP", 91.88449552006233],
  ["SR", 89.16699129983118],
  ["SE", 92.2977535385015],
  ["SD", 96.47123750162316],
  ["SS", 91.08459940267498],
  ["PH", 64.8477470458382],
  ["RW", 96.62413972211401],
  ["KU", 68.91247889884431],
  ["HN", 97.74672120503831],
  ["GA", 98.17101675107129],
  ["DZ", 92.49058563822881],
  ["DM", 87.80547980781716],
  ["FR", 75.84826645890143],
  ["GL", 66.10764835735618],
  ["ES", 62.88079470198675],
  ["HT", 59.46013504739645],
  ["PE", 74.19198805349954],
  ["QA", 93.14764316322555],
  ["SI", 59.88150889494871],
  ["PR", 88.834242306194],
  ["PS", 92.86748474224127],
  ["IQ", 97.01305025321388],
  ["HU", 98.3693676145955],
  ["KN", 71.07972990520712],
  ["KY", 67.27340605116218],
  ["FI", 72.77139332554214],
  ["ER", 77.8288533956629],
  ["GM", 79.17835346058953],
  ["EE", 93.95857680820673],
  ["EG", 98.17913258018439],
  ["ID", 96.87930138942994],
  ["IS", 83.6524477340605],
  ["SK", 59.369562394494224],
  ["RO", 64.85066874431892],
  ["PG", 83.89300090897287],
  ["KM", 65.50642773665759],
  ["IR", 89.75652512660693],
  ["KZ", 93.87774315024024],
  ["IE", 97.3503441111544],
  ["GN", 74.90812881443968],
  ["FJ", 85.54376055057786],
  ["GY", 84.40235034411116],
  ["JM", 82.0201921828334],
  ["KI", 83.95338267757434],
  ["HR", 94.70555771977665],
  ["SO", 98.25931697182185],
  ["PT", 90.9823399558499],
  ["SY", 97.03804700688222],
  ["SN", 84.69354629268926],
  ["KH", 88.88391118036618],
  ["EC", 36.83644981171276],
  ["ET", 61.408258667705496],
  ["DE", 95.02597065316193],
  ["SL", 98.14894169588365],
  ["PW", 94.76236852356837],
  ["SZ", 79.28385923905986],
  ["PA", 94.38157382158161],
  ["SM", 95.06719906505649],
  ["IT", 85.24022854174783],
  ["JO", 89.28450850538891],
  ["GH", 96.19984417608103],
  ["CG", 75.60511621867289],
  ["BT", 93.15673289183223],
  ["AO", 91.26736787430204],
  ["ML", 75.41553045059082],
  ["TN", 61.74328009349435],
  ["LI", 47.42663290481756],
  ["MM", 34.557849629918195],
  ["MZ", 86.05960264900662],
  ["NA", 71.01253084015063],
  ["CF", 87.5188287235424],
  ["BB", 73.52746396571874],
  ["CD", 84.44747435398],
  ["BW", 96.84748733930658],
  ["AL", 56.59751980262303],
  ["NC", 93.42098428775483],
  ["MX", 83.64530580444098],
  ["TZ", 90.78074276068044],
  ["VE", 0],
  ["MN", 73.01259576678353],
  ["MY", 74.78411894559149],
  ["AM", 94.20562264640957],
  ["CR", 89.59615634333204],
  ["BA", 58.1482924295546],
  ["AZ", 94.1900402545124],
  ["CV", 81.14141020646669],
  ["BR", 84.31047915855083],
  ["CA", 91.27873003506039],
  ["BE", 86.59492273730685],
  ["TH", 76.97344500714193],
  ["WS", 55.094792884041034],
  ["UZ", 94.90455784962992],
  ["Wales", 76.34105960264901],
  ["NG", 96.22159459810415],
  ["NP", 70.56258927412024],
  ["MK", 76.84067004285157],
  ["BD", 95.00032463316452],
  ["BS", 96.00895987534086],
  ["CU", 76.27905466822492],
  ["BF", 98.49142968445656],
  ["NR", 74.88670302558108],
  ["NE", 98.59011816647188],
  ["ZW", 75.12303596935463],
  ["VC", 80.59797428905337],
  ["UY", 87.9811063498247],
  ["TJ", 94.51954291650435],
  ["VU", 86.95429165043501],
  ["ZA", 89.6455005843397],
  ["BG", 63.629723412543825],
]);

export const mnMap = new Map<string, number>([
  ["CY", 94.11072664359862],
  ["AF", 90.04065743944636],
  ["CN", 90.9515570934256],
  ["BJ", 93.34818339100346],
  ["LA", 62.17560553633218],
  ["ME", 83.27811418685121],
  ["OM", 66.34991349480968],
  ["NI", 94.8174740484429],
  ["MR", 90.88884083044982],
  ["TG", 78.36894463667821],
  ["TP", 53.27897923875432],
  ["North Korea", 79.62024221453288],
  ["VN", 36.71453287197232],
  ["ZM", 88.89489619377161],
  ["MD", 57.634083044982695],
  ["CO", 72.38235294117648],
  ["AG", 56.147923875432525],
  ["AE", 80.27854671280276],
  ["CZ", 69.72534602076125],
  ["AR", 96.20285467128028],
  ["BI", 76.78114186851211],
  ["CM", 93.44679930795847],
  ["LB", 90.5060553633218],
  ["LU", 95.04065743944636],
  ["TD", 62.219290657439444],
  ["TR", 39.89662629757785],
  ["Scotland", 88.8075259515571],
  ["UA", 92.51730103806229],
  ["LT", 75.86807958477509],
  ["LC", 95.57050173010381],
  ["MG", 96.09515570934256],
  ["CL", 68.12024221453287],
  ["BH", 59.61418685121107],
  ["AD", 61.32828719723184],
  ["CH", 92.84558823529412],
  ["NO", 90.34645328719724],
  ["MT", 63.04541522491349],
  ["MC", 66.62370242214533],
  ["TV", 78.75216262975778],
  ["TW", 93.54455017301038],
  ["US", 63.884083044982695],
  ["MU", 91.34818339100346],
  ["BZ", 72.08477508650519],
  ["CI", 92.38624567474048],
  ["BM", 50.65051903114187],
  ["CK", 81.36245674740485],
  ["AT", 94.55017301038062],
  ["BO", 72.97750865051903],
  ["LS", 93.93166089965398],
  ["NL", 74.36807958477509],
  ["MW", 71.32179930795847],
  ["YE", 76.75562283737024],
  ["UG", 90.42603806228374],
  ["TT", 52.036764705882355],
  ["NZ", 81.52897923875433],
  ["MA", 35.713667820069205],
  ["MV", 49.69809688581315],
  ["LR", 68.8659169550173],
  ["BY", 57.8931660899654],
  ["BN", 88.10467128027682],
  ["AU", 90.03200692041521],
  ["GE", 90.2439446366782],
  ["GR", 76.11764705882354],
  ["IN", 94.29498269896193],
  ["GBENG", 85.95588235294119],
  ["RS", 63.851643598615915],
  ["PL", 69.62543252595155],
  ["SV", 72.56401384083046],
  ["SA", 86.84558823529412],
  ["HK", 89.05017301038062],
  ["KG", 84.88235294117648],
  ["GD", 59.30103806228374],
  ["GQ", 76.4074394463668],
  ["DJ", 94.4000865051903],
  ["KE", 87.29930795847751],
  ["KR", 88.41306228373702],
  ["SC", 72.65743944636678],
  ["ST", 84.44117647058823],
  ["PY", 62.52508650519031],
  ["SB", 85.9083044982699],
  ["IL", 90.96453287197231],
  ["DK", 47.60121107266436],
  ["DO", 73.16522491349481],
  ["RU", 65.74740484429066],
  ["SG", 92.74134948096886],
  ["PK", 92.63321799307958],
  ["GB", 71.16046712802768],
  ["GU", 77.13884083044982],
  ["GW", 73.10683391003461],
  ["JP", 94.95891003460207],
  ["SR", 73.20415224913495],
  ["SE", 75.81228373702422],
  ["SD", 77.22318339100346],
  ["SS", 81.22318339100346],
  ["PH", 55.22491349480969],
  ["RW", 87.6946366782007],
  ["KU", 81.12846020761245],
  ["HN", 94.24394463667821],
  ["GA", 92.64186851211072],
  ["DZ", 90.16911764705883],
  ["DM", 85.2469723183391],
  ["FR", 95.2629757785467],
  ["GL", 67.11072664359861],
  ["ES", 59.75259515570934],
  ["HT", 66.09645328719724],
  ["PE", 39.836505190311414],
  ["QA", 93.7833044982699],
  ["SI", 81.8970588235294],
  ["PR", 86.08693771626298],
  ["PS", 86.35769896193771],
  ["IQ", 74.90397923875433],
  ["HU", 74.79714532871972],
  ["KN", 71.41046712802768],
  ["KY", 81.0635813148789],
  ["FI", 78.83391003460207],
  ["ER", 79.22794117647058],
  ["GM", 73.16089965397924],
  ["EE", 97.4991349480969],
  ["EG", 76.69679930795847],
  ["ID", 66.58520761245674],
  ["IS", 70.38105536332179],
  ["SK", 84.33823529411765],
  ["RO", 62.167387543252595],
  ["PG", 66.76557093425606],
  ["KM", 81.655276816609],
  ["IR", 86.94333910034602],
  ["KZ", 85.47491349480968],
  ["IE", 94.8295847750865],
  ["GN", 72.78070934256056],
  ["FJ", 82.14359861591696],
  ["GY", 79.77941176470588],
  ["JM", 92.39792387543253],
  ["KI", 49.779411764705884],
  ["HR", 88.30190311418686],
  ["SO", 92.52292387543253],
  ["PT", 86.64230103806229],
  ["SY", 75.23918685121107],
  ["SN", 61.65657439446367],
  ["KH", 89.08520761245676],
  ["EC", 86.01038062283737],
  ["ET", 58.292820069204154],
  ["DE", 93.25778546712803],
  ["SL", 94.74524221453288],
  ["PW", 91.75821799307958],
  ["SZ", 82.34818339100346],
  ["PA", 69.04714532871972],
  ["SM", 91.73356401384083],
  ["IT", 62.047577854671275],
  ["JO", 81.11721453287197],
  ["GH", 71.35423875432527],
  ["CG", 67.91046712802769],
  ["BT", 65.09861591695501],
  ["AO", 63.45674740484429],
  ["ML", 61.47404844290657],
  ["TN", 90.37759515570934],
  ["LI", 66.42733564013841],
  ["MM", 91.37370242214533],
  ["MZ", 82.13365051903114],
  ["NA", 63.96712802768166],
  ["CF", 92.33434256055362],
  ["BB", 92.82698961937716],
  ["CD", 80.62370242214533],
  ["BW", 97.20025951557093],
  ["AL", 79.71885813148789],
  ["NC", 80.49437716262976],
  ["MX", 59.42690311418685],
  ["TZ", 89.9022491349481],
  ["VE", 72.53070934256056],
  ["MN", 0],
  ["MY", 60.58607266435986],
  ["AM", 83.91868512110726],
  ["CR", 78.42733564013841],
  ["BA", 87.30795847750865],
  ["AZ", 90.53806228373702],
  ["CV", 65.08001730103807],
  ["BR", 85.59688581314879],
  ["CA", 90.92560553633217],
  ["BE", 99.25821799307958],
  ["TH", 94.79152249134948],
  ["WS", 49.53762975778547],
  ["UZ", 89.98918685121107],
  ["Wales", 70.0220588235294],
  ["NG", 95.30968858131487],
  ["NP", 70.59472318339101],
  ["MK", 79.08910034602076],
  ["BD", 89.28070934256056],
  ["BS", 96.25475778546712],
  ["CU", 80.7871972318339],
  ["BF", 91.99394463667821],
  ["NR", 92.47923875432525],
  ["NE", 94.19377162629758],
  ["ZW", 88.1189446366782],
  ["VC", 92.2750865051903],
  ["UY", 79.63494809688582],
  ["TJ", 94.43122837370242],
  ["VU", 74.52595155709344],
  ["ZA", 79.17863321799308],
  ["BG", 76.57006920415225],
]);

export const myMap = new Map<string, number>([
  ["CY", 62.306228373702425],
  ["AF", 82.22707612456747],
  ["CN", 57.186851211072664],
  ["BJ", 74.61634948096886],
  ["LA", 69.84861591695501],
  ["ME", 77.4212802768166],
  ["OM", 68.74437716262976],
  ["NI", 81.16392733564014],
  ["MR", 84.0030276816609],
  ["TG", 82.14100346020761],
  ["TP", 61.73226643598616],
  ["North Korea", 60.56833910034602],
  ["VN", 59.049307958477506],
  ["ZM", 81.99048442906575],
  ["MD", 58.72534602076125],
  ["CO", 74.99653979238754],
  ["AG", 67.14792387543253],
  ["AE", 78.10250865051903],
  ["CZ", 67.01124567474048],
  ["AR", 85.02249134948097],
  ["BI", 68.44031141868511],
  ["CM", 73.79974048442907],
  ["LB", 59.83910034602076],
  ["LU", 75.08304498269896],
  ["TD", 72.78806228373702],
  ["TR", 59.02032871972318],
  ["Scotland", 73.10942906574395],
  ["UA", 81.12413494809688],
  ["LT", 77.82396193771626],
  ["LC", 90.8529411764706],
  ["MG", 80.23096885813149],
  ["CL", 54.86418685121107],
  ["BH", 60.34731833910034],
  ["AD", 63.94290657439446],
  ["CH", 81.30060553633218],
  ["NO", 64.53503460207612],
  ["MT", 61.9757785467128],
  ["MC", 55.390570934256054],
  ["TV", 76.97102076124568],
  ["TW", 84.45112456747404],
  ["US", 71.62586505190312],
  ["MU", 78.15700692041523],
  ["BZ", 66.0121107266436],
  ["CI", 79.25302768166091],
  ["BM", 58.0795847750865],
  ["CK", 80.21323529411765],
  ["AT", 65.4636678200692],
  ["BO", 78.0575259515571],
  ["LS", 69.84558823529412],
  ["NL", 81.32352941176471],
  ["MW", 76.07006920415225],
  ["YE", 76.83823529411765],
  ["UG", 82.36634948096886],
  ["TT", 63.1280276816609],
  ["NZ", 81.31228373702423],
  ["MA", 58.89359861591695],
  ["MV", 66.21280276816609],
  ["LR", 62.90743944636679],
  ["BY", 76.76427335640138],
  ["BN", 77.10640138408304],
  ["AU", 87.26773356401384],
  ["GE", 65.50648788927336],
  ["GR", 76.88840830449827],
  ["IN", 84.83434256055364],
  ["GBENG", 59.3568339100346],
  ["RS", 63.29714532871973],
  ["PL", 69.93339100346022],
  ["SV", 70.62240484429066],
  ["SA", 81.23140138408304],
  ["HK", 57.51297577854672],
  ["KG", 57.60726643598616],
  ["GD", 67.76167820069205],
  ["GQ", 67.63148788927336],
  ["DJ", 86.38754325259515],
  ["KE", 80.16522491349481],
  ["KR", 63.580449826989614],
  ["SC", 66.38235294117646],
  ["ST", 80.16306228373702],
  ["PY", 73.82742214532873],
  ["SB", 66.57093425605535],
  ["IL", 62.446366782006926],
  ["DK", 60.69074394463668],
  ["DO", 67.98053633217992],
  ["RU", 63.552335640138416],
  ["SG", 55.53200692041522],
  ["PK", 81.30406574394463],
  ["GB", 64.7530276816609],
  ["GU", 79.83693771626298],
  ["GW", 79.34342560553634],
  ["JP", 64.24437716262976],
  ["SR", 80.07093425605535],
  ["SE", 75.86980968858131],
  ["SD", 73.99480968858131],
  ["SS", 68.72880622837371],
  ["PH", 63.564446366782],
  ["RW", 86.89792387543253],
  ["KU", 70.34645328719724],
  ["HN", 80.36505190311418],
  ["GA", 83.91262975778547],
  ["DZ", 64.36894463667821],
  ["DM", 79.52465397923876],
  ["FR", 56.81531141868512],
  ["GL", 64.7984429065744],
  ["ES", 68.08044982698962],
  ["HT", 55.00648788927336],
  ["PE", 60.07785467128027],
  ["QA", 81.86115916955016],
  ["SI", 70.58910034602076],
  ["PR", 73.52551903114187],
  ["PS", 79.0756920415225],
  ["IQ", 76.1007785467128],
  ["HU", 74.46929065743944],
  ["KN", 74.04671280276817],
  ["KY", 80.05363321799308],
  ["FI", 67.49653979238755],
  ["ER", 75.54584775086505],
  ["GM", 76.58088235294119],
  ["EE", 74.46626297577855],
  ["EG", 76.74480968858131],
  ["ID", 55.38840830449827],
  ["IS", 70.35942906574394],
  ["SK", 66.27076124567473],
  ["RO", 72.27595155709342],
  ["PG", 74.15138408304497],
  ["KM", 73.00302768166091],
  ["IR", 75.6916089965398],
  ["KZ", 86.25389273356402],
  ["IE", 77.39186851211073],
  ["GN", 79.41522491349481],
  ["FJ", 85.24653979238754],
  ["GY", 74.4666955017301],
  ["JM", 82.16522491349481],
  ["KI", 60.589965397923876],
  ["HR", 78.29974048442907],
  ["SO", 89.15354671280276],
  ["PT", 82.11115916955018],
  ["SY", 76.22491349480968],
  ["SN", 71.18598615916954],
  ["KH", 72.77768166089965],
  ["EC", 71.9545847750865],
  ["ET", 67.46885813148789],
  ["DE", 83.7387543252595],
  ["SL", 84.15181660899654],
  ["PW", 85.64143598615917],
  ["SZ", 74.74264705882354],
  ["PA", 62.47188581314879],
  ["SM", 81.23659169550173],
  ["IT", 65.25994809688581],
  ["JO", 77.09212802768167],
  ["GH", 77.49351211072664],
  ["CG", 69.37802768166091],
  ["BT", 70.13667820069203],
  ["AO", 76.75086505190312],
  ["ML", 75.43987889273357],
  ["TN", 82.04584775086505],
  ["LI", 69],
  ["MM", 76.60769896193771],
  ["MZ", 76.53503460207612],
  ["NA", 71.61634948096885],
  ["CF", 75.97102076124568],
  ["BB", 84.63884083044982],
  ["CD", 75.98442906574394],
  ["BW", 90.60294117647058],
  ["AL", 59.11505190311419],
  ["NC", 69.18555363321799],
  ["MX", 64.99697231833909],
  ["TZ", 88.76816608996539],
  ["VE", 73.00086505190312],
  ["MN", 60.58607266435986],
  ["MY", 0],
  ["AM", 78.54195501730104],
  ["CR", 67.90614186851212],
  ["BA", 71.24437716262976],
  ["AZ", 83.27292387543253],
  ["CV", 59.49783737024221],
  ["BR", 79.32309688581314],
  ["CA", 74.63062283737024],
  ["BE", 76.02941176470588],
  ["TH", 67.1258650519031],
  ["WS", 58.465830449826996],
  ["UZ", 79.33693771626298],
  ["Wales", 70.01643598615917],
  ["NG", 78.16868512110726],
  ["NP", 59.503460207612456],
  ["MK", 74.21280276816609],
  ["BD", 83.20242214532873],
  ["BS", 85.29930795847751],
  ["CU", 65.65787197231833],
  ["BF", 73.91652249134948],
  ["NR", 83.9182525951557],
  ["NE", 83.12153979238755],
  ["ZW", 74.9991349480969],
  ["VC", 82.25043252595155],
  ["UY", 67.14403114186851],
  ["TJ", 77.21020761245676],
  ["VU", 73.80666089965398],
  ["ZA", 74.9636678200692],
  ["BG", 71.44420415224914],
]);

export const amMap = new Map<string, number>([
  ["CY", 99.02768166089966],
  ["AF", 81.3386678200692],
  ["CN", 95.26470588235294],
  ["BJ", 98.090830449827],
  ["LA", 71.88884083044982],
  ["ME", 66.82482698961938],
  ["OM", 90.09039792387543],
  ["NI", 99.51600346020761],
  ["MR", 94.85769896193771],
  ["TG", 95.8840830449827],
  ["TP", 94.33434256055364],
  ["North Korea", 82.8083910034602],
  ["VN", 96.00692041522491],
  ["ZM", 93.97361591695503],
  ["MD", 82.48053633217994],
  ["CO", 83.52941176470588],
  ["AG", 89.09904844290656],
  ["AE", 90.93425605536332],
  ["CZ", 96.20242214532871],
  ["AR", 99.81012110726644],
  ["BI", 80.76600346020761],
  ["CM", 86.26081314878893],
  ["LB", 70.50346020761246],
  ["LU", 99.70588235294117],
  ["TD", 98.09904844290658],
  ["TR", 64.38970588235294],
  ["Scotland", 93.93858131487889],
  ["UA", 98.52941176470588],
  ["LT", 98.82352941176471],
  ["LC", 96.87456747404845],
  ["MG", 98.44117647058823],
  ["CL", 92.5060553633218],
  ["BH", 75.59429065743944],
  ["AD", 85.58823529411764],
  ["CH", 96.30060553633218],
  ["NO", 94.33088235294119],
  ["MT", 82.32093425605537],
  ["MC", 65.88235294117646],
  ["TV", 82.88840830449827],
  ["TW", 92.60164359861591],
  ["US", 86.24307958477509],
  ["MU", 96.76470588235294],
  ["BZ", 68.08088235294117],
  ["CI", 99.15397923875433],
  ["BM", 70.2128027681661],
  ["CK", 83.73053633217992],
  ["AT", 99.41176470588235],
  ["BO", 99.70588235294117],
  ["LS", 98.42084775086505],
  ["NL", 99.70588235294117],
  ["MW", 90.37110726643598],
  ["YE", 66.76470588235294],
  ["UG", 90.16522491349481],
  ["TT", 73.55363321799308],
  ["NZ", 83.12153979238755],
  ["MA", 94.91782006920415],
  ["MV", 96.33650519031141],
  ["LR", 84.60423875432525],
  ["BY", 93.17084775086505],
  ["BN", 89.96885813148789],
  ["AU", 86.94593425605537],
  ["GE", 94.3961937716263],
  ["GR", 98.93944636678201],
  ["IN", 98.42171280276817],
  ["GBENG", 94.92560553633218],
  ["RS", 92.01038062283737],
  ["PL", 99.41176470588235],
  ["SV", 98.79368512110727],
  ["SA", 91.39057093425606],
  ["HK", 94.84818339100346],
  ["KG", 60.58737024221453],
  ["GD", 74.78892733564014],
  ["GQ", 97.02249134948097],
  ["DJ", 97.48096885813149],
  ["KE", 92.98875432525952],
  ["KR", 94.90354671280276],
  ["SC", 88.47621107266436],
  ["ST", 94.40095155709342],
  ["PY", 65.34602076124567],
  ["SB", 78.04195501730104],
  ["IL", 94.86029411764706],
  ["DK", 97.96799307958477],
  ["DO", 83.47491349480968],
  ["RU", 66.76470588235294],
  ["SG", 95.28849480968859],
  ["PK", 96.7772491349481],
  ["GB", 87.4182525951557],
  ["GU", 91.82006920415225],
  ["GW", 85.02724913494811],
  ["JP", 95.60423875432525],
  ["SR", 96.24048442906575],
  ["SE", 88.92430795847751],
  ["SD", 97.2257785467128],
  ["SS", 86.7288062283737],
  ["PH", 86.76038062283737],
  ["RW", 96.69679930795849],
  ["KU", 98.45112456747404],
  ["HN", 99.00951557093425],
  ["GA", 99.20242214532871],
  ["DZ", 95.17733564013841],
  ["DM", 88.98053633217992],
  ["FR", 88.0696366782007],
  ["GL", 97.21712802768167],
  ["ES", 64.59515570934255],
  ["HT", 82.54801038062284],
  ["PE", 77.02768166089965],
  ["QA", 96.81920415224914],
  ["SI", 96.71410034602076],
  ["PR", 76.08477508650519],
  ["PS", 95.00778546712803],
  ["IQ", 65.1371107266436],
  ["HU", 66.76470588235294],
  ["KN", 90.07612456747405],
  ["KY", 82.11029411764706],
  ["FI", 96.84602076124568],
  ["ER", 84.69636678200692],
  ["GM", 44.41176470588235],
  ["EE", 97.05882352941177],
  ["EG", 66.76470588235294],
  ["ID", 65.88235294117646],
  ["IS", 89.03676470588235],
  ["SK", 70.09991349480968],
  ["RO", 86.9576124567474],
  ["PG", 69.30882352941177],
  ["KM", 93.83650519031141],
  ["IR", 93.12197231833909],
  ["KZ", 93.25475778546712],
  ["IE", 99.21496539792388],
  ["GN", 87.48961937716263],
  ["FJ", 84.31314878892734],
  ["GY", 87.49567474048443],
  ["JM", 90.83391003460207],
  ["KI", 59.29411764705882],
  ["HR", 93.83996539792388],
  ["SO", 98.86807958477507],
  ["PT", 91.56704152249135],
  ["SY", 65.45458477508652],
  ["SN", 86.54065743944636],
  ["KH", 86.98572664359861],
  ["EC", 82.83564013840831],
  ["ET", 88.59818339100346],
  ["DE", 97.05882352941177],
  ["SL", 99.70588235294117],
  ["PW", 98.19766435986159],
  ["SZ", 85.1825259515571],
  ["PA", 90.18728373702423],
  ["SM", 94.89403114186851],
  ["IT", 98.77595155709342],
  ["JO", 92.05968858131487],
  ["GH", 64.95588235294117],
  ["CG", 94.47448096885813],
  ["BT", 94.4545847750865],
  ["AO", 58.93771626297578],
  ["ML", 87.7772491349481],
  ["TN", 64.07223183391004],
  ["LI", 83.70112456747405],
  ["MM", 99.58607266435986],
  ["MZ", 90.89316608996539],
  ["NA", 89.45588235294117],
  ["CF", 96.36505190311418],
  ["BB", 93.69723183391004],
  ["CD", 88.48053633217992],
  ["BW", 99.41176470588235],
  ["AL", 58.01384083044982],
  ["NC", 95.11332179930795],
  ["MX", 85.70891003460208],
  ["TZ", 91.90051903114187],
  ["VE", 94.12456747404845],
  ["MN", 83.91868512110726],
  ["MY", 78.54195501730104],
  ["AM", 0],
  ["CR", 97.01730103806229],
  ["BA", 72.60640138408304],
  ["AZ", 95.72231833910034],
  ["CV", 81.0151384083045],
  ["BR", 89.66998269896193],
  ["CA", 95.28157439446366],
  ["BE", 98.43382352941177],
  ["TH", 82.35294117647058],
  ["WS", 77.19204152249135],
  ["UZ", 94.01903114186851],
  ["Wales", 78.17690311418684],
  ["NG", 98.91868512110726],
  ["NP", 87.4303633217993],
  ["MK", 63.24783737024221],
  ["BD", 94.77941176470588],
  ["BS", 96.17214532871972],
  ["CU", 79.47923875432525],
  ["BF", 95.99913494809688],
  ["NR", 79.80839100346022],
  ["NE", 99.70501730103805],
  ["ZW", 89.85899653979239],
  ["VC", 95.85553633217994],
  ["UY", 79.31531141868511],
  ["TJ", 70.97750865051903],
  ["VU", 87.23356401384082],
  ["ZA", 92.82742214532873],
  ["BG", 99.11764705882354],
]);

export const crMap = new Map<string, number>([
  ["CY", 67.55298442906575],
  ["AF", 91.05788638985007],
  ["CN", 66.6472029988466],
  ["BJ", 83.60258073817762],
  ["LA", 96.17214532871972],
  ["ME", 73.19384371395617],
  ["OM", 53.99617935409458],
  ["NI", 97.63372260668973],
  ["MR", 94.36166378316032],
  ["TG", 87.67156862745098],
  ["TP", 74.8821366782007],
  ["North Korea", 88.94499711649365],
  ["VN", 73.29476643598616],
  ["ZM", 90.72916666666667],
  ["MD", 78.6310553633218],
  ["CO", 91.40030276816609],
  ["AG", 71.87680219146482],
  ["AE", 89.8406862745098],
  ["CZ", 71.71027970011534],
  ["AR", 97.19759227220301],
  ["BI", 78.98392445213379],
  ["CM", 86.09393021914649],
  ["LB", 78.31783448673588],
  ["LU", 98.15347462514417],
  ["TD", 73.56076989619376],
  ["TR", 68.67322664359861],
  ["Scotland", 78.61988177623991],
  ["UA", 96.10546424452133],
  ["LT", 96.68793252595155],
  ["LC", 96.15952998846598],
  ["MG", 86.18187716262976],
  ["CL", 70.2692474048443],
  ["BH", 65.31322087658593],
  ["AD", 81.07987312572088],
  ["CH", 90.32583621683968],
  ["NO", 87.0624279123414],
  ["MT", 65.20544982698962],
  ["MC", 67.2419261822376],
  ["TV", 83.74855824682814],
  ["TW", 91.31812283737024],
  ["US", 67.63985005767013],
  ["MU", 97.51369665513265],
  ["BZ", 83.30485870818916],
  ["CI", 86.8962658592849],
  ["BM", 68.6883650519031],
  ["CK", 59.50692041522492],
  ["AT", 97.9083765859285],
  ["BO", 97.27436562860439],
  ["LS", 90.96993944636678],
  ["NL", 96.68288638985005],
  ["MW", 64.88898500576701],
  ["YE", 97.9083765859285],
  ["UG", 86.74163783160323],
  ["TT", 72.34717416378315],
  ["NZ", 60.55219146482123],
  ["MA", 68.41587370242215],
  ["MV", 84.6633506343714],
  ["LR", 67.10748269896195],
  ["BY", 91.22224625144175],
  ["BN", 81.59025374855825],
  ["AU", 84.2975057670127],
  ["GE", 69.56170703575549],
  ["GR", 83.80190311418684],
  ["IN", 96.20746828143022],
  ["GBENG", 50.019103229527104],
  ["RS", 79.48277104959631],
  ["PL", 66.43562572087659],
  ["SV", 96.7791234140715],
  ["SA", 90.20797289504037],
  ["HK", 72.19579008073818],
  ["KG", 72.22931084198385],
  ["GD", 77.57533160322953],
  ["GQ", 95.8517156862745],
  ["DJ", 87.2440888119954],
  ["KE", 87.07936851211072],
  ["KR", 67.88242502883506],
  ["SC", 67.862961361015],
  ["ST", 87.83989331026528],
  ["PY", 96.06797866205305],
  ["SB", 88.04390138408304],
  ["IL", 78.36685409457901],
  ["DK", 78.76802191464822],
  ["DO", 74.13026239907727],
  ["RU", 80.54714532871972],
  ["SG", 66.57511534025375],
  ["PK", 84.83996539792388],
  ["GB", 48.94679930795848],
  ["GU", 68.03921568627452],
  ["GW", 86.80327277970011],
  ["JP", 60.6199538638985],
  ["SR", 52.19038350634372],
  ["SE", 95.30204728950403],
  ["SD", 95.60157151095733],
  ["SS", 70.51254325259517],
  ["PH", 77.88963379469435],
  ["RW", 95.86721453287197],
  ["KU", 96.48933102652826],
  ["HN", 97.39943771626297],
  ["GA", 97.59227220299884],
  ["DZ", 77.93108419838524],
  ["DM", 86.95573817762399],
  ["FR", 65.06740196078431],
  ["GL", 67.26102941176471],
  ["ES", 93.56905997693194],
  ["HT", 82.1622693194925],
  ["PE", 67.58470299884661],
  ["QA", 85.62860438292964],
  ["SI", 81.60214821222607],
  ["PR", 72.44773644752019],
  ["PS", 88.39965397923876],
  ["IQ", 96.47995963091118],
  ["HU", 97.9083765859285],
  ["KN", 81.78561130334487],
  ["KY", 57.671568627450974],
  ["FI", 64.36238465974625],
  ["ER", 71.36317762399077],
  ["GM", 97.2307525951557],
  ["EE", 82.70617070357554],
  ["EG", 97.9000865051903],
  ["ID", 67.2307525951557],
  ["IS", 56.18620242214533],
  ["SK", 79.4305074971165],
  ["RO", 73.2086216839677],
  ["PG", 81.10258073817762],
  ["KM", 75.99877450980392],
  ["IR", 92.31076989619376],
  ["KZ", 90.06163494809688],
  ["IE", 86.17070357554788],
  ["GN", 86.91861303344868],
  ["FJ", 84.69506920415225],
  ["GY", 79.5635092272203],
  ["JM", 90.03676470588235],
  ["KI", 61.29577566320646],
  ["HR", 78.26016435986159],
  ["SO", 95.3972029988466],
  ["PT", 91.90167243367935],
  ["SY", 96.88617358708188],
  ["SN", 82.58109861591696],
  ["KH", 71.34191176470588],
  ["EC", 86.49978373702422],
  ["ET", 91.41255767012687],
  ["DE", 94.80175893886967],
  ["SL", 98.15347462514417],
  ["PW", 96.0362600922722],
  ["SZ", 77.5111735870819],
  ["PA", 74.1562139561707],
  ["SM", 78.58852364475202],
  ["IT", 75.0875865051903],
  ["JO", 86.24891868512111],
  ["GH", 97.02025663206459],
  ["CG", 81.21215397923875],
  ["BT", 90.69744809688581],
  ["AO", 83.50958765859285],
  ["ML", 83.4436274509804],
  ["TN", 72.40160034602076],
  ["LI", 64.68966262975778],
  ["MM", 87.42611014994233],
  ["MZ", 87.05918396770474],
  ["NA", 69.82194348327566],
  ["CF", 69.72246251441753],
  ["BB", 72.6632785467128],
  ["CD", 86.27018454440599],
  ["BW", 98.81992502883506],
  ["AL", 75.85856401384083],
  ["NC", 93.10805940023069],
  ["MX", 72.86224048442907],
  ["TZ", 92.12153979238754],
  ["VE", 89.51521049596309],
  ["MN", 78.6137543252595],
  ["MY", 68.97671568627452],
  ["AM", 97.00800173010381],
  ["CR", 0],
  ["BA", 68.09652537485582],
  ["AZ", 71.14583333333333],
  ["CV", 48.764057093425606],
  ["BR", 87.92964244521337],
  ["CA", 81.84364186851211],
  ["BE", 85.54570357554788],
  ["TH", 64.5090830449827],
  ["WS", 65.86144752018454],
  ["UZ", 96.96510957324107],
  ["Wales", 75.37053056516724],
  ["NG", 86.04202710495963],
  ["NP", 59.34724625144175],
  ["MK", 81.42517301038062],
  ["BD", 95.2328431372549],
  ["BS", 98.4566032295271],
  ["CU", 38.174019607843135],
  ["BF", 93.75684832756632],
  ["NR", 61.944925028835065],
  ["NE", 97.19470876585929],
  ["ZW", 74.2683102652826],
  ["VC", 91.0553633217993],
  ["UY", 72.79700115340255],
  ["TJ", 88.65087946943483],
  ["VU", 88.66782006920415],
  ["ZA", 81.69838523644752],
  ["BG", 80.12651384083046],
]);

export const baMap = new Map<string, number>([
  ["CY", 89.3840830449827],
  ["AF", 88.33607266435986],
  ["CN", 89.77941176470588],
  ["BJ", 74.69982698961938],
  ["LA", 89.97318339100346],
  ["ME", 87.1583044982699],
  ["OM", 87.26773356401384],
  ["NI", 89.62153979238754],
  ["MR", 88.33520761245674],
  ["TG", 80.58088235294117],
  ["TP", 87.28589965397924],
  ["North Korea", 88.98745674740485],
  ["VN", 90.50562283737025],
  ["ZM", 87.77897923875433],
  ["MD", 79.72015570934255],
  ["CO", 57.732698961937714],
  ["AG", 82.50778546712803],
  ["AE", 90.7318339100346],
  ["CZ", 88.97837370242215],
  ["AR", 94.03157439446368],
  ["BI", 87.29368512110727],
  ["CM", 83.17993079584775],
  ["LB", 90.31487889273356],
  ["LU", 94.58131487889273],
  ["TD", 78.23010380622837],
  ["TR", 88.90311418685121],
  ["Scotland", 85.97231833910035],
  ["UA", 87.37240484429066],
  ["LT", 79.46193771626298],
  ["LC", 92.17430795847751],
  ["MG", 93.70242214532873],
  ["CL", 79.73269896193771],
  ["BH", 90.00043252595155],
  ["AD", 50.46539792387543],
  ["CH", 90.37975778546713],
  ["NO", 91.60423875432527],
  ["MT", 92.2681660899654],
  ["MC", 91.93209342560553],
  ["TV", 85.32698961937716],
  ["TW", 88.98010380622837],
  ["US", 77.50648788927336],
  ["MU", 89.5795847750865],
  ["BZ", 83.715830449827],
  ["CI", 94.38148788927336],
  ["BM", 81.44679930795847],
  ["CK", 38.07742214532872],
  ["AT", 92.55925605536332],
  ["BO", 84.41003460207612],
  ["LS", 77.39143598615918],
  ["NL", 90.68339100346022],
  ["MW", 92.72621107266436],
  ["YE", 91.31833910034602],
  ["UG", 77.93166089965398],
  ["TT", 87.11548442906575],
  ["NZ", 36.42603806228374],
  ["MA", 91.19377162629758],
  ["MV", 89.96496539792388],
  ["LR", 83.9818339100346],
  ["BY", 89.36764705882354],
  ["BN", 72.30493079584775],
  ["AU", 82.65570934256056],
  ["GE", 85.53546712802769],
  ["GR", 88.01557093425606],
  ["IN", 91.54887543252596],
  ["GBENG", 91.35813148788927],
  ["RS", 76.35942906574394],
  ["PL", 92.2772491349481],
  ["SV", 89.7893598615917],
  ["SA", 86.46193771626297],
  ["HK", 88.04498269896193],
  ["KG", 86.55493079584775],
  ["GD", 77.01038062283737],
  ["GQ", 90.05320069204153],
  ["DJ", 95.41262975778547],
  ["KE", 86.74178200692042],
  ["KR", 87.28546712802768],
  ["SC", 71.84731833910034],
  ["ST", 85.9022491349481],
  ["PY", 64.34558823529412],
  ["SB", 82.49221453287198],
  ["IL", 87.87067474048443],
  ["DK", 91.30666089965398],
  ["DO", 89.92387543252596],
  ["RU", 70.96539792387543],
  ["SG", 91.4416089965398],
  ["PK", 90.06574394463668],
  ["GB", 64.63192041522491],
  ["GU", 39.22534602076125],
  ["GW", 72.50259515570934],
  ["JP", 91.02032871972318],
  ["SR", 89.22707612456747],
  ["SE", 84.95025951557093],
  ["SD", 86.9212802768166],
  ["SS", 84.45242214532873],
  ["PH", 76.08910034602077],
  ["RW", 91.09385813148789],
  ["KU", 86.79887543252596],
  ["HN", 89.04584775086505],
  ["GA", 89.25735294117646],
  ["DZ", 88.21929065743944],
  ["DM", 84.52335640138409],
  ["FR", 62.715397923875436],
  ["GL", 90.2045847750865],
  ["ES", 78.66998269896193],
  ["HT", 65.82179930795847],
  ["PE", 91.88235294117646],
  ["QA", 90.3053633217993],
  ["SI", 89.55276816608998],
  ["PR", 84.27595155709342],
  ["PS", 87.85683391003461],
  ["IQ", 88.80276816608996],
  ["HU", 90.3901384083045],
  ["KN", 83.4567474048443],
  ["KY", 36.77984429065744],
  ["FI", 68.61721453287197],
  ["ER", 86.04887543252595],
  ["GM", 76.3135813148789],
  ["EE", 93.38365051903115],
  ["EG", 90.2923875432526],
  ["ID", 92.0756920415225],
  ["IS", 47.29022491349481],
  ["SK", 87.80103806228374],
  ["RO", 49.306228373702425],
  ["PG", 82.28200692041523],
  ["KM", 82.40095155709342],
  ["IR", 88.59861591695501],
  ["KZ", 94.23745674740485],
  ["IE", 92.37153979238755],
  ["GN", 78.49134948096885],
  ["FJ", 85.23659169550173],
  ["GY", 77.32439446366783],
  ["JM", 79.66998269896195],
  ["KI", 66.36678200692042],
  ["HR", 62.87456747404845],
  ["SO", 97.07266435986159],
  ["PT", 87.42993079584775],
  ["SY", 89.48832179930795],
  ["SN", 91.26470588235294],
  ["KH", 58.07569204152249],
  ["EC", 75.42906574394463],
  ["ET", 84.4515570934256],
  ["DE", 88.87889273356402],
  ["SL", 93.21842560553634],
  ["PW", 90.13494809688581],
  ["SZ", 78.59256055363322],
  ["PA", 90.5886678200692],
  ["SM", 90.09429065743944],
  ["IT", 91.7318339100346],
  ["JO", 87.11980968858131],
  ["GH", 84.5242214532872],
  ["CG", 88.42733564013841],
  ["BT", 88.8992214532872],
  ["AO", 86.40008650519032],
  ["ML", 80.63062283737025],
  ["TN", 88.24956747404843],
  ["LI", 68.25519031141869],
  ["MM", 80.06055363321799],
  ["MZ", 82.68209342560554],
  ["NA", 71.02811418685121],
  ["CF", 80.92733564013841],
  ["BB", 38.4485294117647],
  ["CD", 93.37456747404845],
  ["BW", 94.18122837370242],
  ["AL", 88.03503460207612],
  ["NC", 74.74524221453287],
  ["MX", 90.38797577854672],
  ["TZ", 90.22750865051903],
  ["VE", 58.213667820069205],
  ["MN", 87.30795847750865],
  ["MY", 71.24437716262976],
  ["AM", 72.60640138408304],
  ["CR", 68.41349480968859],
  ["BA", 0],
  ["AZ", 94.86072664359861],
  ["CV", 45.77724913494809],
  ["BR", 82.17257785467127],
  ["CA", 86.5454152249135],
  ["BE", 92.26470588235294],
  ["TH", 91.57396193771626],
  ["WS", 78.68122837370242],
  ["UZ", 95.04152249134948],
  ["Wales", 84.0462802768166],
  ["NG", 91.88581314878893],
  ["NP", 77.69852941176471],
  ["MK", 76.25129757785467],
  ["BD", 93.33996539792388],
  ["BS", 93.62067474048443],
  ["CU", 59.36764705882352],
  ["BF", 93.98096885813149],
  ["NR", 35.609429065743946],
  ["NE", 96.85553633217992],
  ["ZW", 80.87716262975778],
  ["VC", 73.96539792387543],
  ["UY", 67.36634948096885],
  ["TJ", 88.2469723183391],
  ["VU", 78.79757785467129],
  ["ZA", 71.77984429065744],
  ["BG", 91.63192041522491],
]);

export const azMap = new Map<string, number>([
  ["CY", 93.25475778546712],
  ["AF", 89.95415224913495],
  ["CN", 92.2136678200692],
  ["BJ", 88.32698961937716],
  ["LA", 95.85034602076125],
  ["ME", 84.60121107266436],
  ["OM", 64.60640138408304],
  ["NI", 97.66479238754326],
  ["MR", 93.00432525951557],
  ["TG", 86.13062283737024],
  ["TP", 93.20544982698962],
  ["North Korea", 68.75346020761246],
  ["VN", 94.03503460207612],
  ["ZM", 90.77768166089966],
  ["MD", 83.5211937716263],
  ["CO", 96.01384083044982],
  ["AG", 77.09429065743944],
  ["AE", 89.44636678200692],
  ["CZ", 86.33131487889273],
  ["AR", 97.52638408304499],
  ["BI", 88.8014705882353],
  ["CM", 90.18771626297578],
  ["LB", 94.40138408304499],
  ["LU", 97.82093425605535],
  ["TD", 86.28503460207612],
  ["TR", 69.34342560553634],
  ["Scotland", 89.75043252595157],
  ["UA", 96.42171280276817],
  ["LT", 95.9779411764706],
  ["LC", 98.63797577854672],
  ["MG", 95.47145328719722],
  ["CL", 95.01686851211073],
  ["BH", 76.38754325259515],
  ["AD", 84.67949826989619],
  ["CH", 94.13148788927336],
  ["NO", 92.45717993079585],
  ["MT", 81.7136678200692],
  ["MC", 83.25475778546712],
  ["TV", 82.78200692041523],
  ["TW", 92.5211937716263],
  ["US", 83.1470588235294],
  ["MU", 71.159169550173],
  ["BZ", 86.59212802768165],
  ["CI", 86.12586505190312],
  ["BM", 73.78892733564014],
  ["CK", 89.93685121107266],
  ["AT", 97.82093425605535],
  ["BO", 97.7590830449827],
  ["LS", 97.97621107266437],
  ["NL", 97.82093425605535],
  ["MW", 64.95588235294117],
  ["YE", 98.11505190311419],
  ["UG", 94.01427335640138],
  ["TT", 72.97707612456747],
  ["NZ", 88.84126297577855],
  ["MA", 94.2318339100346],
  ["MV", 82.90787197231833],
  ["LR", 94.06487889273356],
  ["BY", 89.00519031141869],
  ["BN", 93.48096885813149],
  ["AU", 88.32223183391004],
  ["GE", 90.6068339100346],
  ["GR", 96.41046712802768],
  ["IN", 97.85769896193771],
  ["GBENG", 79.78373702422145],
  ["RS", 88.0030276816609],
  ["PL", 82.98659169550173],
  ["SV", 97.19160899653978],
  ["SA", 89.09342560553634],
  ["HK", 92.55622837370242],
  ["KG", 68.47750865051904],
  ["GD", 81.19074394463668],
  ["GQ", 94.11548442906575],
  ["DJ", 93.79584775086505],
  ["KE", 92.45891003460207],
  ["KR", 91.75648788927336],
  ["SC", 87.77551903114187],
  ["ST", 87.73226643598616],
  ["PY", 96.98096885813149],
  ["SB", 86.18295847750865],
  ["IL", 95.87716262975778],
  ["DK", 82.87889273356402],
  ["DO", 91.33001730103805],
  ["RU", 96.01124567474048],
  ["SG", 92.38192041522491],
  ["PK", 95.43339100346022],
  ["GB", 71.58217993079586],
  ["GU", 92.3719723183391],
  ["GW", 65.54195501730104],
  ["JP", 94.465830449827],
  ["SR", 94.09126297577855],
  ["SE", 95.20717993079585],
  ["SD", 94.87283737024221],
  ["SS", 77.05968858131487],
  ["PH", 85.86807958477507],
  ["RW", 62.864186851211066],
  ["KU", 96.33607266435986],
  ["HN", 97.03546712802769],
  ["GA", 97.2037197231834],
  ["DZ", 92.44463667820068],
  ["DM", 88.49480968858131],
  ["FR", 96.98615916955016],
  ["GL", 81.34688581314879],
  ["ES", 93.24221453287197],
  ["HT", 81.82612456747404],
  ["PE", 75.71842560553634],
  ["QA", 93.05579584775087],
  ["SI", 93.5211937716263],
  ["PR", 87.58217993079585],
  ["PS", 86.5493079584775],
  ["IQ", 96.83304498269895],
  ["HU", 97.8205017301038],
  ["KN", 86.83391003460208],
  ["KY", 86.80925605536332],
  ["FI", 96.78589965397923],
  ["ER", 65.04108996539793],
  ["GM", 96.43944636678201],
  ["EE", 99.10337370242215],
  ["EG", 98.50043252595155],
  ["ID", 83.25389273356402],
  ["IS", 94.12629757785467],
  ["SK", 89.91176470588236],
  ["RO", 86.20112456747405],
  ["PG", 83.42257785467127],
  ["KM", 82],
  ["IR", 87.91695501730104],
  ["KZ", 58.21756055363322],
  ["IE", 86.52249134948097],
  ["GN", 75.0181660899654],
  ["FJ", 87.30276816608996],
  ["GY", 59.608131487889274],
  ["JM", 93.23313148788928],
  ["KI", 74.88062283737025],
  ["HR", 93.06660899653978],
  ["SO", 95.21107266435986],
  ["PT", 88.90138408304499],
  ["SY", 96.75951557093425],
  ["SN", 85.09429065743944],
  ["KH", 70.45025951557093],
  ["EC", 93.2984429065744],
  ["ET", 88.19636678200692],
  ["DE", 95.49913494809688],
  ["SL", 97.82093425605535],
  ["PW", 95.67214532871972],
  ["SZ", 84.52768166089966],
  ["PA", 88.05839100346022],
  ["SM", 93.51340830449827],
  ["IT", 86.47102076124568],
  ["JO", 82.12283737024222],
  ["GH", 97.53589965397924],
  ["CG", 92.64749134948097],
  ["BT", 92.86332179930797],
  ["AO", 83.84818339100346],
  ["ML", 85.71712802768167],
  ["TN", 92.5432525951557],
  ["LI", 80.30493079584775],
  ["MM", 94.52335640138408],
  ["MZ", 87.89273356401384],
  ["NA", 82.05795847750865],
  ["CF", 92.71107266435986],
  ["BB", 97.81574394463668],
  ["CD", 85.83217993079585],
  ["BW", 99.00778546712803],
  ["AL", 70.56877162629758],
  ["NC", 92.03070934256056],
  ["MX", 84.66652249134948],
  ["TZ", 93.72621107266436],
  ["VE", 93.70631487889274],
  ["MN", 90.53806228373702],
  ["MY", 83.27292387543253],
  ["AM", 95.72231833910034],
  ["CR", 70.45501730103805],
  ["BA", 94.86072664359861],
  ["AZ", 0],
  ["CV", 91.51730103806229],
  ["BR", 89.02335640138408],
  ["CA", 91.93036332179932],
  ["BE", 97.034169550173],
  ["TH", 96.04282006920415],
  ["WS", 73.94333910034602],
  ["UZ", 64.00865051903114],
  ["Wales", 75.1916089965398],
  ["NG", 97.02811418685121],
  ["NP", 77.22837370242215],
  ["MK", 80.99913494809688],
  ["BD", 89.81401384083046],
  ["BS", 68.45415224913495],
  ["CU", 84.66046712802768],
  ["BF", 62.218858131487885],
  ["NR", 94.6340830449827],
  ["NE", 96.84688581314879],
  ["ZW", 91.6583044982699],
  ["VC", 85.88884083044984],
  ["UY", 93.10942906574394],
  ["TJ", 97.2348615916955],
  ["VU", 90.13019031141869],
  ["ZA", 91.61764705882352],
  ["BG", 95.90960207612457],
]);

export const cvMap = new Map<string, number>([
  ["CY", 84.36617647058824],
  ["AF", 88.275],
  ["CN", 85.3610294117647],
  ["BJ", 91.18676470588235],
  ["LA", 92.26654411764706],
  ["ME", 83.81764705882352],
  ["OM", 83.61433823529411],
  ["NI", 89.67426470588235],
  ["MR", 92.18272058823528],
  ["TG", 88.05294117647058],
  ["TP", 85.74926470588235],
  ["North Korea", 56.18639705882353],
  ["VN", 87.15588235294118],
  ["ZM", 89.89669117647058],
  ["MD", 65.79816176470588],
  ["CO", 96.71470588235293],
  ["AG", 80.825],
  ["AE", 89.03051470588235],
  ["CZ", 76.60661764705883],
  ["AR", 90.76838235294117],
  ["BI", 86.41323529411765],
  ["CM", 88.96617647058824],
  ["LB", 80.17610294117648],
  ["LU", 90.60919117647059],
  ["TD", 90.62205882352941],
  ["TR", 85.29375],
  ["Scotland", 84.77169117647058],
  ["UA", 86.75330882352941],
  ["LT", 94.69044117647059],
  ["LC", 95.13308823529411],
  ["MG", 92.18455882352941],
  ["CL", 74.36691176470588],
  ["BH", 83.6139705882353],
  ["AD", 89.07389705882352],
  ["CH", 88.14522058823529],
  ["NO", 89.1610294117647],
  ["MT", 85.97683823529411],
  ["MC", 81.00036764705882],
  ["TV", 86.34632352941176],
  ["TW", 90.97022058823529],
  ["US", 72.8125],
  ["MU", 84.13823529411765],
  ["BZ", 46.19154411764706],
  ["CI", 92.20955882352942],
  ["BM", 77.84117647058824],
  ["CK", 42.88088235294117],
  ["AT", 89.58308823529411],
  ["BO", 91.69227941176472],
  ["LS", 57.23161764705882],
  ["NL", 89.35110294117648],
  ["MW", 79.17904411764705],
  ["YE", 89.67536764705882],
  ["UG", 85.43786764705882],
  ["TT", 85.26838235294117],
  ["NZ", 40.93713235294118],
  ["MA", 86.74264705882354],
  ["MV", 91.00183823529412],
  ["LR", 80.31911764705882],
  ["BY", 85.5625],
  ["BN", 87.04558823529412],
  ["AU", 85.41213235294117],
  ["GE", 85.37279411764706],
  ["GR", 92.63713235294118],
  ["IN", 89.45073529411765],
  ["GBENG", 87.73051470588236],
  ["RS", 73.04705882352941],
  ["PL", 89.39558823529413],
  ["SV", 34.78345588235294],
  ["SA", 89.15808823529412],
  ["HK", 86.11360294117647],
  ["KG", 83.44007352941176],
  ["GD", 83.77169117647058],
  ["GQ", 88.21617647058824],
  ["DJ", 89.49227941176471],
  ["KE", 87.18566176470588],
  ["KR", 83.04080882352942],
  ["SC", 69.28272058823529],
  ["ST", 87.56433823529412],
  ["PY", 66.83014705882353],
  ["SB", 85.34522058823529],
  ["IL", 79.01102941176471],
  ["DK", 82.95735294117647],
  ["DO", 85.67867647058823],
  ["RU", 80.96911764705882],
  ["SG", 77.28198529411765],
  ["PK", 87.69889705882353],
  ["GB", 61.591911764705884],
  ["GU", 37.53639705882353],
  ["GW", 92.04669117647059],
  ["JP", 84.71838235294118],
  ["SR", 83.2985294117647],
  ["SE", 36.071323529411764],
  ["SD", 88.16948529411765],
  ["SS", 74.27169117647058],
  ["PH", 48.218014705882354],
  ["RW", 87.4514705882353],
  ["KU", 88.275],
  ["HN", 89.43529411764706],
  ["GA", 91.80808823529412],
  ["DZ", 85.60036764705883],
  ["DM", 85.3985294117647],
  ["FR", 66.44595588235293],
  ["GL", 84.07169117647058],
  ["ES", 85.7625],
  ["HT", 42.1375],
  ["PE", 87.19301470588235],
  ["QA", 89.15404411764706],
  ["SI", 93.32867647058823],
  ["PR", 81.39779411764707],
  ["PS", 86.30808823529412],
  ["IQ", 90.82058823529412],
  ["HU", 89.33529411764705],
  ["KN", 84.92426470588235],
  ["KY", 40.72242647058823],
  ["FI", 69.14117647058823],
  ["ER", 81.48198529411765],
  ["GM", 86.86286764705883],
  ["EE", 87.51654411764706],
  ["EG", 90.07352941176471],
  ["ID", 81.24889705882353],
  ["IS", 38.724264705882355],
  ["SK", 81.5985294117647],
  ["RO", 67.55551470588236],
  ["PG", 86.47022058823529],
  ["KM", 85.87610294117647],
  ["IR", 84.84411764705882],
  ["KZ", 95.06875000000001],
  ["IE", 91.9625],
  ["GN", 90.80514705882354],
  ["FJ", 87.61286764705882],
  ["GY", 81.50110294117647],
  ["JM", 86.18308823529411],
  ["KI", 63.97169117647059],
  ["HR", 84.66764705882352],
  ["SO", 96.57573529411765],
  ["PT", 90.12536764705882],
  ["SY", 89.76691176470588],
  ["SN", 90.47132352941176],
  ["KH", 42.14264705882353],
  ["EC", 92.46433823529412],
  ["ET", 82.24485294117648],
  ["DE", 88.08860294117648],
  ["SL", 89.6279411764706],
  ["PW", 93.9297794117647],
  ["SZ", 83.78382352941176],
  ["PA", 77.47757352941177],
  ["SM", 92.97426470588235],
  ["IT", 89.28786764705883],
  ["JO", 84.96323529411765],
  ["GH", 91.10661764705881],
  ["CG", 87.06948529411764],
  ["BT", 88.66360294117646],
  ["AO", 92.63382352941176],
  ["ML", 91.8172794117647],
  ["TN", 89.625],
  ["LI", 42.80330882352941],
  ["MM", 92.65919117647059],
  ["MZ", 86.19889705882353],
  ["NA", 64.56470588235294],
  ["CF", 75.25257352941176],
  ["BB", 44.54117647058823],
  ["CD", 89.51801470588235],
  ["BW", 96.85845588235294],
  ["AL", 81.35477941176471],
  ["NC", 61.29963235294118],
  ["MX", 88.65110294117648],
  ["TZ", 92.01544117647059],
  ["VE", 81.09779411764706],
  ["MN", 65.19632352941176],
  ["MY", 60.84595588235294],
  ["AM", 81.05220588235295],
  ["CR", 48.56102941176471],
  ["BA", 45.743382352941175],
  ["AZ", 91.81764705882352],
  ["CV", 0],
  ["BR", 83.81727941176472],
  ["CA", 90.18125],
  ["BE", 91.32536764705883],
  ["TH", 87.90110294117646],
  ["WS", 66.33382352941176],
  ["UZ", 87.56213235294118],
  ["Wales", 76.91433823529412],
  ["NG", 91.99632352941177],
  ["NP", 69.44926470588236],
  ["MK", 78.36911764705883],
  ["BD", 91.05367647058824],
  ["BS", 93.00220588235294],
  ["CU", 44.824999999999996],
  ["BF", 93.6966911764706],
  ["NR", 26.37095588235294],
  ["NE", 91.29926470588235],
  ["ZW", 79.55220588235295],
  ["VC", 91.77683823529412],
  ["UY", 59.477573529411764],
  ["TJ", 84.25257352941176],
  ["VU", 83.8922794117647],
  ["ZA", 75.14154411764706],
  ["BG", 95.13235294117646],
]);

export const brMap = new Map<string, number>([
  ["CY", 91.97633465150766],
  ["AF", 88.30264458724666],
  ["CN", 88.21984676223431],
  ["BJ", 57.97392486406328],
  ["LA", 90.9549555116164],
  ["ME", 86.94976520019773],
  ["OM", 86.43691300049431],
  ["NI", 92.23492338111716],
  ["MR", 89.93913741967376],
  ["TG", 85.21749876421157],
  ["TP", 88.41293870489372],
  ["North Korea", 87.87228126544736],
  ["VN", 90.65465892239249],
  ["ZM", 86.4912876915472],
  ["MD", 86.07359120118636],
  ["CO", 81.4906697973307],
  ["AG", 89.30579584775087],
  ["AE", 74.25698220464656],
  ["CZ", 87.16849975284231],
  ["AR", 90.57865793376175],
  ["BI", 65.4448838358873],
  ["CM", 85.58638161146813],
  ["LB", 92.83984181908058],
  ["LU", 93.30326248146318],
  ["TD", 90.22244191794366],
  ["TR", 87.6121478002966],
  ["Scotland", 86.32229362333167],
  ["UA", 86.53330449826989],
  ["LT", 90.25457241720217],
  ["LC", 90.20112456747404],
  ["MG", 73.25352199703411],
  ["CL", 89.87024221453287],
  ["BH", 87.6581809194266],
  ["AD", 88.61097380128523],
  ["CH", 90.42758279782501],
  ["NO", 86.56080079090461],
  ["MT", 90.8876050420168],
  ["MC", 91.6893227879387],
  ["TV", 82.33687592684133],
  ["TW", 90.11369253583787],
  ["US", 80.19741720217499],
  ["MU", 61.34978991596639],
  ["BZ", 83.66071428571429],
  ["CI", 91.43042511122096],
  ["BM", 83.11109738012851],
  ["CK", 80.53262481463173],
  ["AT", 92.65725407810183],
  ["BO", 83.82538309441424],
  ["LS", 65.95124814631735],
  ["NL", 92.09249876421157],
  ["MW", 89.23535590706871],
  ["YE", 93.79695996045477],
  ["UG", 85.90675976272863],
  ["TT", 87.78454028670292],
  ["NZ", 80.41831438457736],
  ["MA", 88.65299060800791],
  ["MV", 85.08032624814632],
  ["LR", 88.10955264458724],
  ["BY", 84.89248640632724],
  ["BN", 85.18382352941177],
  ["AU", 88.7472194760257],
  ["GE", 86.63062283737024],
  ["GR", 89.82111962432032],
  ["IN", 91.10912011863569],
  ["GBENG", 90.9756549678695],
  ["RS", 82.59021255561048],
  ["PL", 91.06123331685615],
  ["SV", 91.3173504695996],
  ["SA", 86.6275333662877],
  ["HK", 90.22584033613445],
  ["KG", 90.10349728126545],
  ["GD", 79.44111468116658],
  ["GQ", 88.94710825506674],
  ["DJ", 67.40360850222442],
  ["KE", 87.72429560059318],
  ["KR", 84.91905585763718],
  ["SC", 82.87382600098863],
  ["ST", 38.94092931290163],
  ["PY", 91.53701186356896],
  ["SB", 82.40329955511616],
  ["IL", 90.17486406327237],
  ["DK", 90.7612456747405],
  ["DO", 90.40719228868018],
  ["RU", 90.79430301532378],
  ["SG", 89.28540533860603],
  ["PK", 89.71762234305487],
  ["GB", 79.44389520514088],
  ["GU", 84.97497528423133],
  ["GW", 69.22176223430549],
  ["JP", 89.44574888779042],
  ["SR", 89.49301779535344],
  ["SE", 83.73702422145328],
  ["SD", 90.59595897182402],
  ["SS", 66.01643598615917],
  ["PH", 86.5793376173999],
  ["RW", 84.79455017301038],
  ["KU", 91.00778546712803],
  ["HN", 91.57130499258527],
  ["GA", 84.32464162135443],
  ["DZ", 89.32927582797825],
  ["DM", 82.91368017795352],
  ["FR", 92.73479980227386],
  ["GL", 89.99598368759268],
  ["ES", 83.62086010874938],
  ["HT", 90.32532130499258],
  ["PE", 92.896070192783],
  ["QA", 88.63198220464656],
  ["SI", 88.81611468116658],
  ["PR", 83.83310677212062],
  ["PS", 90.78070934256056],
  ["IQ", 91.80795847750865],
  ["HU", 65.03800049431537],
  ["KN", 66.93431784478497],
  ["KY", 78.06073900148294],
  ["FI", 86.23208106772121],
  ["ER", 59.72009391992091],
  ["GM", 87.45211319822046],
  ["EE", 93.13426841324765],
  ["EG", 93.68944636678201],
  ["ID", 91.79034849233811],
  ["IS", 87.85498022738507],
  ["SK", 88.43703657933762],
  ["RO", 89.0731586752348],
  ["PG", 87.43759268413248],
  ["KM", 84.42412259021256],
  ["IR", 85.8746292634701],
  ["KZ", 90.0247157686604],
  ["IE", 65.19154720711813],
  ["GN", 88.95112456747405],
  ["FJ", 83.74845526445873],
  ["GY", 42.44562530894711],
  ["JM", 67.55406574394463],
  ["KI", 83.27885565991102],
  ["HR", 89.57952298566485],
  ["SO", 94.58292140385566],
  ["PT", 86.75018536826495],
  ["SY", 92.34027434503213],
  ["SN", 65.39576124567475],
  ["KH", 90.27558082056352],
  ["EC", 82.98628274839346],
  ["ET", 51.4878892733564],
  ["DE", 89.21280276816609],
  ["SL", 65.9049060800791],
  ["PW", 84.41423628274839],
  ["SZ", 83.21150519031141],
  ["PA", 89.3777805239743],
  ["SM", 89.47448096885813],
  ["IT", 66.37697726149283],
  ["JO", 89.90113692535839],
  ["GH", 82.134206623826],
  ["CG", 66.25525210084034],
  ["BT", 88.95977508650519],
  ["AO", 91.58798813643104],
  ["ML", 62.80771131982205],
  ["TN", 92.32513593672763],
  ["LI", 85.50821799307958],
  ["MM", 90.5211937716263],
  ["MZ", 84.99134948096886],
  ["NA", 59.813704893722196],
  ["CF", 89.39075630252101],
  ["BB", 90.21440929312902],
  ["CD", 87.63964409293129],
  ["BW", 89.62339347503708],
  ["AL", 85.72787938704893],
  ["NC", 61.536085022244194],
  ["MX", 91.48418190805734],
  ["TZ", 67.64582303509638],
  ["VE", 84.54955511616411],
  ["MN", 86.19995056846268],
  ["MY", 82.0461566979733],
  ["AM", 90.33613445378151],
  ["CR", 88.41880869995057],
  ["BA", 82.12895452298567],
  ["AZ", 89.89495798319328],
  ["CV", 84.17542016806723],
  ["BR", 0],
  ["CA", 86.34268413247652],
  ["BE", 93.83588729609491],
  ["TH", 90.99820810677211],
  ["WS", 86.53639396935245],
  ["UZ", 63.575444883835885],
  ["Wales", 51.69117647058824],
  ["NG", 40.029967869500744],
  ["NP", 81.76130746416213],
  ["MK", 75.28824765200198],
  ["BD", 93.16238260009885],
  ["BS", 91.76347009391992],
  ["CU", 83.51087493821058],
  ["BF", 61.3930425111221],
  ["NR", 81.63865546218487],
  ["NE", 66.55029658922392],
  ["ZW", 83.1558947108255],
  ["VC", 84.3632600098863],
  ["UY", 87.09867770637668],
  ["TJ", 92.01340830449827],
  ["VU", 64.60145823035096],
  ["ZA", 86.57192288680177],
  ["BG", 92.82748393475036],
]);

export const caMap = new Map<string, number>([
  ["CY", 66.06401384083046],
  ["AF", 91.22491349480968],
  ["CN", 93.00865051903115],
  ["BJ", 92.65051903114187],
  ["LA", 93.61721453287197],
  ["ME", 88.84385813148789],
  ["OM", 76.4204152249135],
  ["NI", 90.37413494809688],
  ["MR", 93.70415224913495],
  ["TG", 90.96237024221453],
  ["TP", 92.56920415224914],
  ["North Korea", 88.64792387543253],
  ["VN", 93.86980968858131],
  ["ZM", 91.79368512110727],
  ["MD", 90.08347750865052],
  ["CO", 92.51730103806229],
  ["AG", 88.92517301038062],
  ["AE", 91.57612456747405],
  ["CZ", 77.83261245674741],
  ["AR", 92.11245674740485],
  ["BI", 83.78589965397923],
  ["CM", 91.33131487889273],
  ["LB", 81.74091695501731],
  ["LU", 90.84385813148789],
  ["TD", 93.85553633217994],
  ["TR", 36.77984429065744],
  ["Scotland", 81.4303633217993],
  ["UA", 92.96107266435986],
  ["LT", 94.69593425605535],
  ["LC", 96.63365051903115],
  ["MG", 87.31704152249135],
  ["CL", 81.72534602076125],
  ["BH", 88.72015570934256],
  ["AD", 91.78330449826989],
  ["CH", 45.062716262975776],
  ["NO", 86.04411764705883],
  ["MT", 78.87846020761245],
  ["MC", 78.909169550173],
  ["TV", 82.17387543252596],
  ["TW", 47.79671280276816],
  ["US", 76.18641868512111],
  ["MU", 93.3075259515571],
  ["BZ", 80.71193771626297],
  ["CI", 79.18555363321799],
  ["BM", 85.32352941176471],
  ["CK", 84.49264705882354],
  ["AT", 90.875],
  ["BO", 93.75389273356402],
  ["LS", 88.49351211072664],
  ["NL", 90.91046712802768],
  ["MW", 94.82136678200692],
  ["YE", 92.13365051903114],
  ["UG", 92.06660899653978],
  ["TT", 90.9234429065744],
  ["NZ", 85.6219723183391],
  ["MA", 91.59515570934256],
  ["MV", 92.97404844290658],
  ["LR", 78.99264705882352],
  ["BY", 88.26254325259517],
  ["BN", 84.51903114186851],
  ["AU", 81.35250865051903],
  ["GE", 47.87456747404844],
  ["GR", 81.96453287197232],
  ["IN", 90.48918685121107],
  ["GBENG", 69.0462802768166],
  ["RS", 78.82915224913495],
  ["PL", 79.56833910034602],
  ["SV", 90.125],
  ["SA", 90.37932525951558],
  ["HK", 93.35769896193771],
  ["KG", 91.68555363321799],
  ["GD", 84.75086505190312],
  ["GQ", 86.98529411764706],
  ["DJ", 91.6734429065744],
  ["KE", 88.47318339100346],
  ["KR", 64.28676470588235],
  ["SC", 82.20242214532873],
  ["ST", 89.99307958477509],
  ["PY", 89.12326989619376],
  ["SB", 85.51730103806229],
  ["IL", 73.42301038062283],
  ["DK", 86.63927335640138],
  ["DO", 86.9333910034602],
  ["RU", 80.88278546712803],
  ["SG", 76.07396193771626],
  ["PK", 92.61072664359861],
  ["GB", 75.54974048442907],
  ["GU", 89.33607266435986],
  ["GW", 91.23615916955016],
  ["JP", 66.10294117647058],
  ["SR", 86.67690311418686],
  ["SE", 93.40960207612457],
  ["SD", 90.52205882352942],
  ["SS", 88.09385813148789],
  ["PH", 90.76254325259515],
  ["RW", 90.51470588235294],
  ["KU", 89.23269896193771],
  ["HN", 89.98659169550173],
  ["GA", 93.48053633217994],
  ["DZ", 76.32309688581314],
  ["DM", 85.81055363321799],
  ["FR", 78.29368512110727],
  ["GL", 78.09429065743944],
  ["ES", 91.15484429065745],
  ["HT", 92.47145328719724],
  ["PE", 78.59861591695501],
  ["QA", 89.03287197231833],
  ["SI", 79.77465397923875],
  ["PR", 61.06271626297578],
  ["PS", 90.0432525951557],
  ["IQ", 91.04887543252596],
  ["HU", 90.71150519031141],
  ["KN", 89.44290657439447],
  ["KY", 85.01903114186852],
  ["FI", 76.8961937716263],
  ["ER", 84.14230103806229],
  ["GM", 92.68771626297578],
  ["EE", 83.53589965397924],
  ["EG", 92.0705017301038],
  ["ID", 78.909169550173],
  ["IS", 87.68339100346022],
  ["SK", 78.91955017301038],
  ["RO", 93.96712802768165],
  ["PG", 91.13235294117648],
  ["KM", 83.93209342560553],
  ["IR", 83.66262975778547],
  ["KZ", 87.50865051903114],
  ["IE", 78.90527681660899],
  ["GN", 93.87543252595155],
  ["FJ", 87.68122837370242],
  ["GY", 86.344723183391],
  ["JM", 90.17863321799308],
  ["KI", 84.00821799307958],
  ["HR", 71.25735294117646],
  ["SO", 93.54498269896195],
  ["PT", 62.79714532871973],
  ["SY", 93.17474048442907],
  ["SN", 92.89532871972318],
  ["KH", 90.83737024221453],
  ["EC", 87.75994809688581],
  ["ET", 88.11505190311418],
  ["DE", 93.51946366782006],
  ["SL", 90.66868512110726],
  ["PW", 96.14230103806229],
  ["SZ", 87.08434256055362],
  ["PA", 79.22361591695501],
  ["SM", 77.32006920415225],
  ["IT", 78.30406574394463],
  ["JO", 90.57785467128028],
  ["GH", 93.2772491349481],
  ["CG", 90.2530276816609],
  ["BT", 89.78373702422145],
  ["AO", 94.83347750865052],
  ["ML", 93.85250865051903],
  ["TN", 43.20501730103806],
  ["LI", 92.42387543252595],
  ["MM", 92.36548442906575],
  ["MZ", 88.32742214532871],
  ["NA", 85.70328719723183],
  ["CF", 87.92560553633218],
  ["BB", 93.99307958477509],
  ["CD", 89.8044982698962],
  ["BW", 97.9303633217993],
  ["AL", 93.43685121107266],
  ["NC", 90.10034602076125],
  ["MX", 75.7984429065744],
  ["TZ", 92.41046712802769],
  ["VE", 91.00259515570934],
  ["MN", 90.92560553633217],
  ["MY", 74.63062283737024],
  ["AM", 95.28157439446366],
  ["CR", 82.0211937716263],
  ["BA", 86.5454152249135],
  ["AZ", 91.93036332179932],
  ["CV", 90.19290657439446],
  ["BR", 85.76686851211073],
  ["CA", 0],
  ["BE", 94.97707612456747],
  ["TH", 83.32006920415225],
  ["WS", 92.67690311418684],
  ["UZ", 88.64662629757785],
  ["Wales", 75.92949826989619],
  ["NG", 78.6007785467128],
  ["NP", 70.26254325259515],
  ["MK", 81.19377162629758],
  ["BD", 94.4878892733564],
  ["BS", 92.76859861591696],
  ["CU", 79.28589965397924],
  ["BF", 92.81271626297578],
  ["NR", 92.52595155709342],
  ["NE", 89.70198961937716],
  ["ZW", 89.88927335640139],
  ["VC", 88.20934256055364],
  ["UY", 72.84126297577855],
  ["TJ", 87.52638408304499],
  ["VU", 92.98442906574394],
  ["ZA", 88.34212802768167],
  ["BG", 80.98140138408304],
]);

export const beMap = new Map<string, number>([
  ["CY", 94.49376869391824],
  ["AF", 61.571784646061815],
  ["CN", 98.71759720837487],
  ["BJ", 82.78065802592224],
  ["LA", 81.31306081754735],
  ["ME", 91.43843469591226],
  ["OM", 89.01146560319043],
  ["NI", 93.68519441674975],
  ["MR", 95.59346959122632],
  ["TG", 98.4840478564307],
  ["TP", 49.1413260219342],
  ["North Korea", 76.12337986041874],
  ["VN", 64.96111665004986],
  ["ZM", 94.96061814556332],
  ["MD", 94.463110667996],
  ["CO", 91.29661016949152],
  ["AG", 63.43743768693918],
  ["AE", 95.85767696909272],
  ["CZ", 81.61166500498504],
  ["AR", 94.66924227318046],
  ["BI", 86.54860418743769],
  ["CM", 98.79287138584247],
  ["LB", 77.56081754735793],
  ["LU", 87.78988035892323],
  ["TD", 98.22432701894317],
  ["TR", 96.96435692921236],
  ["Scotland", 93.80383848454636],
  ["UA", 99.20164506480559],
  ["LT", 99.07178464606181],
  ["LC", 92.9666001994018],
  ["MG", 82.95712861415753],
  ["CL", 80.70837487537388],
  ["BH", 65.74277168494517],
  ["AD", 59.71211365902293],
  ["CH", 97.05583250249252],
  ["NO", 70.7073778664008],
  ["MT", 66.0191924227318],
  ["MC", 82.78464606181456],
  ["TV", 87.59920239282154],
  ["TW", 96.19516450648055],
  ["US", 88.87686939182453],
  ["MU", 90.59396809571287],
  ["BZ", 80.62013958125624],
  ["CI", 96.61390827517448],
  ["BM", 69.45513459621137],
  ["CK", 93.43544366899302],
  ["AT", 75.9481555333998],
  ["BO", 88.3970588235294],
  ["LS", 97.14656031904288],
  ["NL", 97.8437188434696],
  ["MW", 78.16176470588235],
  ["YE", 77.41176470588236],
  ["UG", 85.97681954137587],
  ["TT", 66.31131605184447],
  ["NZ", 93.28539381854438],
  ["MA", 99.26096709870389],
  ["MV", 73.39930209371884],
  ["LR", 91.79486540378863],
  ["BY", 98.77617148554337],
  ["BN", 86.70363908275175],
  ["AU", 92.77617148554337],
  ["GE", 94.62736789631107],
  ["GR", 93.79636091724825],
  ["IN", 94.94067796610169],
  ["GBENG", 91.59496510468594],
  ["RS", 93.64905284147558],
  ["PL", 82.80383848454636],
  ["SV", 93.86016949152543],
  ["SA", 93.08923230309072],
  ["HK", 91.99052841475573],
  ["KG", 58.42771684945165],
  ["GD", 80.26146560319043],
  ["GQ", 95.17472582253241],
  ["DJ", 96.96984047856431],
  ["KE", 85.27118644067797],
  ["KR", 93.37462612163509],
  ["SC", 72.1388334995015],
  ["ST", 97.571036889332],
  ["PY", 86.12512462612163],
  ["SB", 94.7041375872383],
  ["IL", 94.80259222333001],
  ["DK", 97.86091724825523],
  ["DO", 82.34047856430708],
  ["RU", 87.69092721834497],
  ["SG", 81.90428713858424],
  ["PK", 95.44267198404785],
  ["GB", 80.84421734795613],
  ["GU", 94.48130608175474],
  ["GW", 97.22283150548355],
  ["JP", 97.09546360917248],
  ["SR", 95.66375872382852],
  ["SE", 98.8536889332004],
  ["SD", 80.2123629112662],
  ["SS", 82.0333998005982],
  ["PH", 81.16201395812563],
  ["RW", 94.16101694915254],
  ["KU", 71.19591226321037],
  ["HN", 95.3654037886341],
  ["GA", 98.91824526420739],
  ["DZ", 94.79985044865403],
  ["DM", 93.93369890329014],
  ["FR", 63.08798604187438],
  ["GL", 80.97557328015952],
  ["ES", 96.29112662013958],
  ["HT", 80.47482552342971],
  ["PE", 63.96959122632103],
  ["QA", 97.78190428713859],
  ["SI", 88.29312063808574],
  ["PR", 95.4179960119641],
  ["PS", 91.80832502492522],
  ["IQ", 74.1154037886341],
  ["HU", 87.48778664007976],
  ["KN", 66.96061814556332],
  ["KY", 90.52691924227318],
  ["FI", 98.20588235294117],
  ["ER", 94.72432701894317],
  ["GM", 86.70837487537388],
  ["EE", 88.66201395812561],
  ["EG", 73.49351944167498],
  ["ID", 82.46360917248255],
  ["IS", 92.50149551345962],
  ["SK", 85.90428713858425],
  ["RO", 65.58050847457628],
  ["PG", 48.39431704885344],
  ["KM", 89.71385842472583],
  ["IR", 90.91251246261216],
  ["KZ", 94.97432701894317],
  ["IE", 96.76046859421736],
  ["GN", 98.95887337986042],
  ["FJ", 88.5919740777667],
  ["GY", 88.73280159521435],
  ["JM", 79.22632103688933],
  ["KI", 70.50772681954138],
  ["HR", 94.0259222333001],
  ["SO", 96.6999002991027],
  ["PT", 93.59022931206381],
  ["SY", 76.12088733798605],
  ["SN", 35.44641076769691],
  ["KH", 93.42173479561316],
  ["EC", 86.10343968095712],
  ["ET", 86.28664007976072],
  ["DE", 88.11864406779661],
  ["SL", 98.0685443668993],
  ["PW", 97.42871385842471],
  ["SZ", 89.75398803589232],
  ["PA", 79.85393818544367],
  ["SM", 92.07153539381855],
  ["IT", 64.40927218344964],
  ["JO", 92.72382851445663],
  ["GH", 87.1981555333998],
  ["CG", 58.305333998005985],
  ["BT", 65.01046859421736],
  ["AO", 63.02293120638086],
  ["ML", 66.31555333998006],
  ["TN", 92.87362911266202],
  ["LI", 80.31056829511466],
  ["MM", 84.70787637088733],
  ["MZ", 97.00024925224326],
  ["NA", 82.53963110667996],
  ["CF", 97.0117148554337],
  ["BB", 70.75149551345962],
  ["CD", 90.30458624127617],
  ["BW", 92.27442671984048],
  ["AL", 66.55782652043868],
  ["NC", 83.53938185443668],
  ["MX", 59.58075772681954],
  ["TZ", 85.23803589232303],
  ["VE", 86.52168494516451],
  ["MN", 99.26096709870389],
  ["MY", 77.86665004985045],
  ["AM", 98.59745762711864],
  ["CR", 85.78838484546361],
  ["BA", 92.27642073778664],
  ["AZ", 97.31331006979063],
  ["CV", 91.71834496510468],
  ["BR", 93.67248255234297],
  ["CA", 95.00648055832502],
  ["BE", 0],
  ["TH", 86.96186440677967],
  ["WS", 64.11889332003989],
  ["UZ", 97.21934197407776],
  ["Wales", 91.26221335992024],
  ["NG", 96.46684945164506],
  ["NP", 90.91101694915254],
  ["MK", 86.16350947158524],
  ["BD", 99.26096709870389],
  ["BS", 69.11166500498504],
  ["CU", 95.26520438683949],
  ["BF", 81.72233300099701],
  ["NR", 95.90752741774676],
  ["NE", 96.81979062811565],
  ["ZW", 97.49950149551346],
  ["VC", 95.76196410767696],
  ["UY", 93.70588235294117],
  ["TJ", 90.37188434695912],
  ["VU", 67.20962113659023],
  ["ZA", 75.92073778664007],
  ["BG", 98.56929212362911],
]);

export const thMap = new Map<string, number>([
  ["CY", 70.6278405401896],
  ["AF", 92.33476171925724],
  ["CN", 64.99123490455784],
  ["BJ", 87.50519413063238],
  ["LA", 66.32158161277756],
  ["ME", 93.77418517075704],
  ["OM", 76.28587196467991],
  ["NI", 99.93247630177899],
  ["MR", 96.39527334112454],
  ["TG", 89.1806258927412],
  ["TP", 65.35807038047007],
  ["North Korea", 90.79275418776783],
  ["VN", 64.11634852616544],
  ["ZM", 93.61024542267238],
  ["MD", 92.10135047396442],
  ["CO", 81.01545253863135],
  ["AG", 74.28743020386963],
  ["AE", 91.75107128944293],
  ["CZ", 69.91527074405921],
  ["AR", 99.25561615374626],
  ["BI", 62.15134398130113],
  ["CM", 85.79892221789378],
  ["LB", 51.02454226723802],
  ["LU", 83.22295805739515],
  ["TD", 97.10459680560966],
  ["TR", 62.571419296195295],
  ["Scotland", 80.19770159719516],
  ["UA", 96.46799116997794],
  ["LT", 98.45474613686534],
  ["LC", 95.74698091156992],
  ["MG", 88.06973120373978],
  ["CL", 70.48337878197637],
  ["BH", 66.6981560836255],
  ["AD", 85.14738345669393],
  ["CH", 92.20101285547332],
  ["NO", 69.26178418387222],
  ["MT", 67.55226593948838],
  ["MC", 67.3289183222958],
  ["TV", 86.74652642513959],
  ["TW", 94.0416828983249],
  ["US", 76.80723282690559],
  ["MU", 81.67770419426049],
  ["BZ", 72.7911959485781],
  ["CI", 88.58492403583949],
  ["BM", 63.01454356577067],
  ["CK", 86.40923256719907],
  ["AT", 66.88741721854305],
  ["BO", 83.44370860927152],
  ["LS", 92.78080768731334],
  ["NL", 100],
  ["MW", 97.44805869367615],
  ["YE", 84.10596026490066],
  ["UG", 84.93896896506948],
  ["TT", 72.69445526554993],
  ["NZ", 87.07895078561226],
  ["MA", 95.87293857940527],
  ["MV", 65.56226464095573],
  ["LR", 84.56953642384106],
  ["BY", 93.98811842617842],
  ["BN", 79.93896896506946],
  ["AU", 87.39806518633944],
  ["GE", 71.4676665368134],
  ["GR", 86.08265160368784],
  ["IN", 98.2359433839761],
  ["GBENG", 66.99097519802622],
  ["RS", 80.93104791585507],
  ["PL", 66.66666666666666],
  ["SV", 99.15887547071809],
  ["SA", 92.40358395013635],
  ["HK", 61.76470588235294],
  ["KG", 59.34294247500325],
  ["GD", 64.37215945981042],
  ["GQ", 82.06499155953773],
  ["DJ", 88.55148681989353],
  ["KE", 88.20640176600442],
  ["KR", 72.91877678223607],
  ["SC", 79.41144007271784],
  ["ST", 93.14861706271913],
  ["PY", 82.81554343591742],
  ["SB", 89.24035839501363],
  ["IL", 80.43013894299442],
  ["DK", 95.3113232047786],
  ["DO", 80.05096740683028],
  ["RU", 66.44591611479028],
  ["SG", 65.8881963381379],
  ["PK", 87.0701856901701],
  ["GB", 77.9405272042592],
  ["GU", 93.66510842747695],
  ["GW", 84.6967926243345],
  ["JP", 72.20718088559927],
  ["SR", 78.25120114270874],
  ["SE", 98.0538241786781],
  ["SD", 82.80742760680431],
  ["SS", 90.29152058174263],
  ["PH", 75.88170367484743],
  ["RW", 98.221010258408],
  ["KU", 84.43416439423451],
  ["HN", 99.36242046487469],
  ["GA", 99.65296714712375],
  ["DZ", 81.21802363329438],
  ["DM", 91.00798597584729],
  ["FR", 77.54350084404624],
  ["GL", 66.52317880794702],
  ["ES", 94.95098039215686],
  ["HT", 80.70964809764966],
  ["PE", 66.69101415400598],
  ["QA", 87.10751850409038],
  ["SI", 66.8854694195559],
  ["PR", 74.7331515387612],
  ["PS", 96.94390338917025],
  ["IQ", 82.57726269315673],
  ["HU", 84.10596026490066],
  ["KN", 79.95974548759901],
  ["KY", 86.54395533047656],
  ["FI", 71.95331775094144],
  ["ER", 84.8113881314115],
  ["GM", 83.44370860927152],
  ["EE", 80.79470198675497],
  ["EG", 84.10596026490066],
  ["ID", 67.3289183222958],
  ["IS", 92.75451240098688],
  ["SK", 64.76886118685886],
  ["RO", 86.9257239319569],
  ["PG", 77.83599532528243],
  ["KM", 89.81333593039865],
  ["IR", 94.13939748084664],
  ["KZ", 97.12472406181016],
  ["IE", 88.54661732242566],
  ["GN", 86.80560966108297],
  ["FJ", 87.6886118685885],
  ["GY", 88.12621737436697],
  ["JM", 89.92208804051421],
  ["KI", 70.2642513959226],
  ["HR", 96.718283339826],
  ["SO", 99.1806258927412],
  ["PT", 93.5768082067264],
  ["SY", 82.89183222958057],
  ["SN", 85.36423841059603],
  ["KH", 95.47428905336969],
  ["EC", 76.95364238410596],
  ["ET", 77.44708479418257],
  ["DE", 95.58498896247241],
  ["SL", 99.77924944812362],
  ["PW", 97.67595117517206],
  ["SZ", 88.69140371380341],
  ["PA", 76.93546292689261],
  ["SM", 80.50350603817687],
  ["IT", 78.10966108297623],
  ["JO", 95.08862485391506],
  ["GH", 82.38183352811323],
  ["CG", 83.54824048824828],
  ["BT", 92.53960524607194],
  ["AO", 77.16335540838853],
  ["ML", 86.90786910790807],
  ["TN", 61.87800285677185],
  ["LI", 80.57038047006883],
  ["MM", 77.6535514868199],
  ["MZ", 86.34300740163616],
  ["NA", 82.65257758732632],
  ["CF", 84.29716919880535],
  ["BB", 94.00240228541747],
  ["CD", 91.90657057524997],
  ["BW", 98.89624724061811],
  ["AL", 56.97961303726788],
  ["NC", 94.86365407089988],
  ["MX", 76.7169848071679],
  ["TZ", 93.37910660953122],
  ["VE", 76.97344500714193],
  ["MN", 94.86690040254513],
  ["MY", 68.98227502921699],
  ["AM", 82.560706401766],
  ["CR", 63.836190105181146],
  ["BA", 91.01610180496039],
  ["AZ", 96.30340215556421],
  ["CV", 87.73276197896377],
  ["BR", 90.53207375665498],
  ["CA", 83.3881963381379],
  ["BE", 86.46993896896507],
  ["TH", 0],
  ["WS", 73.15900532398389],
  ["UZ", 97.50649266329047],
  ["Wales", 75.0889494870796],
  ["NG", 88.5943383976107],
  ["NP", 62.39319568887157],
  ["MK", 83.04895468121023],
  ["BD", 98.1658226204389],
  ["BS", 97.41137514608492],
  ["CU", 72.92948967666537],
  ["BF", 82.54155304505908],
  ["NR", 95.97454875990131],
  ["NE", 99.77924944812362],
  ["ZW", 84.52408778080769],
  ["VC", 95.9596156343332],
  ["UY", 75.4191014154006],
  ["TJ", 90.52233476171926],
  ["VU", 74.45721334891573],
  ["ZA", 81.8721594598104],
  ["BG", 66.88741721854305],
]);

export const wsMap = new Map<string, number>([
  ["CY", 94.89965397923875],
  ["AF", 87.03806228373703],
  ["CN", 19.627162629757784],
  ["BJ", 66.33304498269896],
  ["LA", 48.52638408304498],
  ["ME", 35.74524221453287],
  ["OM", 61.08737024221453],
  ["NI", 96.52984429065744],
  ["MR", 92.65960207612457],
  ["TG", 90.53460207612457],
  ["TP", 32.92387543252595],
  ["North Korea", 91.46410034602076],
  ["VN", 26.166522491349482],
  ["ZM", 84.93339100346022],
  ["MD", 58.8689446366782],
  ["CO", 73.08737024221453],
  ["AG", 48.47750865051903],
  ["AE", 84.4022491349481],
  ["CZ", 48.79108996539792],
  ["AR", 98.78892733564014],
  ["BI", 70.98745674740485],
  ["CM", 72.11851211072664],
  ["LB", 57.48399653979239],
  ["LU", 81.57006920415225],
  ["TD", 50.621972318339104],
  ["TR", 24.878892733564015],
  ["Scotland", 90.55882352941177],
  ["UA", 96.35640138408304],
  ["LT", 64.12153979238754],
  ["LC", 99.29152249134948],
  ["MG", 98.2651384083045],
  ["CL", 46.53373702422145],
  ["BH", 38.653546712802765],
  ["AD", 62.03849480968858],
  ["CH", 94.24134948096886],
  ["NO", 86.33347750865052],
  ["MT", 48.90484429065744],
  ["MC", 71.84212802768167],
  ["TV", 80.09429065743944],
  ["TW", 93.08823529411765],
  ["US", 54.40787197231835],
  ["MU", 72.46453287197232],
  ["BZ", 76.71885813148789],
  ["CI", 99.51946366782008],
  ["BM", 21.650951557093425],
  ["CK", 82.280276816609],
  ["AT", 48.48659169550173],
  ["BO", 80.50389273356402],
  ["LS", 96.10467128027682],
  ["NL", 96.3386678200692],
  ["MW", 64.1241349480969],
  ["YE", 80.4878892733564],
  ["UG", 92.41306228373702],
  ["TT", 37.35034602076124],
  ["NZ", 83.7651384083045],
  ["MA", 21.14273356401384],
  ["MV", 44.233564013840834],
  ["LR", 49.82871972318339],
  ["BY", 89.27508650519032],
  ["BN", 91.6401384083045],
  ["AU", 85.27335640138408],
  ["GE", 91.09212802768167],
  ["GR", 95.82612456747405],
  ["IN", 97.86980968858131],
  ["GBENG", 76.1159169550173],
  ["RS", 86.5121107266436],
  ["PL", 49.40960207612457],
  ["SV", 96.31660899653978],
  ["SA", 89.35640138408304],
  ["HK", 27.50432525951557],
  ["KG", 26.594723183391007],
  ["GD", 61.20415224913495],
  ["GQ", 65.9537197231834],
  ["DJ", 96.31314878892734],
  ["KE", 88.81877162629758],
  ["KR", 91.73010380622837],
  ["SC", 52.85899653979239],
  ["ST", 90.21280276816609],
  ["PY", 78.93944636678201],
  ["SB", 87.50648788927336],
  ["IL", 93.70198961937716],
  ["DK", 37.68339100346021],
  ["DO", 49.208044982698965],
  ["RU", 63.71756055363322],
  ["SG", 70.6029411764706],
  ["PK", 94.56444636678201],
  ["GB", 57.9606401384083],
  ["GU", 63.94420415224914],
  ["GW", 79.21712802768167],
  ["JP", 84.4636678200692],
  ["SR", 69.780276816609],
  ["SE", 96.06055363321799],
  ["SD", 77.84429065743944],
  ["SS", 73.66262975778547],
  ["PH", 57.199826989619375],
  ["RW", 94.6038062283737],
  ["KU", 66.5826124567474],
  ["HN", 96.0817474048443],
  ["GA", 97.91998269896195],
  ["DZ", 90.00129757785467],
  ["DM", 84.82266435986159],
  ["FR", 51.43512110726643],
  ["GL", 56.86937716262975],
  ["ES", 56.99221453287198],
  ["HT", 46.13581314878893],
  ["PE", 47.37543252595155],
  ["QA", 95.6461937716263],
  ["SI", 63.41565743944637],
  ["PR", 86.96885813148789],
  ["PS", 86.36505190311419],
  ["IQ", 78.72534602076125],
  ["HU", 80.4878892733564],
  ["KN", 65.81055363321799],
  ["KY", 81.97967128027682],
  ["FI", 86.38840830449827],
  ["ER", 61.64489619377163],
  ["GM", 75.08823529411764],
  ["EE", 97.36851211072664],
  ["EG", 80.44809688581314],
  ["ID", 71.84212802768167],
  ["IS", 73.30103806228374],
  ["SK", 92.65657439446366],
  ["RO", 50.488321799307954],
  ["PG", 62.522923875432525],
  ["KM", 78.96107266435986],
  ["IR", 91.6258650519031],
  ["KZ", 91.3780276816609],
  ["IE", 97.0220588235294],
  ["GN", 80.18295847750865],
  ["FJ", 83.73269896193771],
  ["GY", 80.53935986159169],
  ["JM", 92.04108996539793],
  ["KI", 64.96064013840831],
  ["HR", 90.68598615916954],
  ["SO", 97.94766435986159],
  ["PT", 89.23961937716263],
  ["SY", 79.07698961937716],
  ["SN", 62.56704152249135],
  ["KH", 64.70285467128028],
  ["EC", 68.3628892733564],
  ["ET", 57.27638408304499],
  ["DE", 93.64749134948097],
  ["SL", 98.9991349480969],
  ["PW", 99.69636678200692],
  ["SZ", 65.67906574394463],
  ["PA", 71.94031141868513],
  ["SM", 94.0484429065744],
  ["IT", 63.72404844290658],
  ["JO", 81.63321799307958],
  ["GH", 79.59731833910034],
  ["CG", 61.584775086505196],
  ["BT", 95.04801038062284],
  ["AO", 72.04498269896193],
  ["ML", 65.95544982698962],
  ["TN", 28.47794117647059],
  ["LI", 27.52984429065744],
  ["MM", 66.32915224913495],
  ["MZ", 83.80449826989619],
  ["NA", 49.92430795847751],
  ["CF", 76.61505190311418],
  ["BB", 82.17906574394463],
  ["CD", 77.6522491349481],
  ["BW", 98.92084775086505],
  ["AL", 25.217128027681664],
  ["NC", 92.11418685121107],
  ["MX", 61.15873702422145],
  ["TZ", 94.7954152249135],
  ["VE", 54.779411764705884],
  ["MN", 49.53762975778547],
  ["MY", 58.465830449826996],
  ["AM", 77.19204152249135],
  ["CR", 65.69074394463668],
  ["BA", 78.68122837370242],
  ["AZ", 73.94333910034602],
  ["CV", 66.19420415224914],
  ["BR", 86.04411764705883],
  ["CA", 92.67690311418684],
  ["BE", 64.1159169550173],
  ["TH", 73.47188581314879],
  ["WS", 0],
  ["UZ", 95.32396193771626],
  ["Wales", 70.65960207612457],
  ["NG", 96.9576124567474],
  ["NP", 62.94809688581316],
  ["MK", 81.64100346020761],
  ["BD", 91.83607266435986],
  ["BS", 95.90138408304499],
  ["CU", 76.03416955017302],
  ["BF", 94.5454152249135],
  ["NR", 75.25173010380622],
  ["NE", 98.42690311418684],
  ["ZW", 76.50519031141869],
  ["VC", 95.08347750865052],
  ["UY", 95.75562283737025],
  ["TJ", 96.43166089965398],
  ["VU", 68.63538062283737],
  ["ZA", 91.46496539792388],
  ["BG", 64.31444636678201],
]);

export const uzMap = new Map<string, number>([
  ["CY", 71.7984429065744],
  ["AF", 92.81358131487889],
  ["CN", 91.20501730103805],
  ["BJ", 91.92776816608996],
  ["LA", 88.99913494809688],
  ["ME", 86.1007785467128],
  ["OM", 93.43339100346022],
  ["NI", 63.990051903114185],
  ["MR", 92.84688581314879],
  ["TG", 91.53589965397924],
  ["TP", 89.67171280276817],
  ["North Korea", 88.27638408304497],
  ["VN", 90.49221453287197],
  ["ZM", 90.59904844290656],
  ["MD", 91.88148788927336],
  ["CO", 93.67430795847751],
  ["AG", 92.25259515570934],
  ["AE", 71.23961937716263],
  ["CZ", 85.41262975778547],
  ["AR", 66.54065743944638],
  ["BI", 75.0666089965398],
  ["CM", 92.09515570934256],
  ["LB", 69.98096885813149],
  ["LU", 63.293252595155714],
  ["TD", 96.04974048442907],
  ["TR", 89.10942906574394],
  ["Scotland", 79.72404844290656],
  ["UA", 96.04195501730104],
  ["LT", 96.38105536332179],
  ["LC", 95.43771626297578],
  ["MG", 86.46280276816609],
  ["CL", 84.4000865051903],
  ["BH", 84.07612456747405],
  ["AD", 94.07093425605537],
  ["CH", 80.41219723183391],
  ["NO", 83.07179930795849],
  ["MT", 80.08477508650519],
  ["MC", 79.27162629757785],
  ["TV", 85.10856401384082],
  ["TW", 96.70458477508652],
  ["US", 74.88538062283737],
  ["MU", 71.4788062283737],
  ["BZ", 78.42517301038062],
  ["CI", 85.3620242214533],
  ["BM", 86.2954152249135],
  ["CK", 91.40527681660899],
  ["AT", 62.98615916955017],
  ["BO", 89.79368512110727],
  ["LS", 70.58261245674741],
  ["NL", 62.53460207612457],
  ["MW", 63.00259515570934],
  ["YE", 63.68339100346021],
  ["UG", 93.32785467128028],
  ["TT", 89.96842560553632],
  ["NZ", 92.18987889273357],
  ["MA", 92.46237024221453],
  ["MV", 91.79282006920415],
  ["LR", 84.59818339100346],
  ["BY", 88.12716262975778],
  ["BN", 81.24437716262976],
  ["AU", 91.05233564013841],
  ["GE", 84.6371107266436],
  ["GR", 76.80060553633218],
  ["IN", 66.46712802768167],
  ["GBENG", 88.05103806228374],
  ["RS", 86.65570934256056],
  ["PL", 80.92647058823529],
  ["SV", 65.25432525951557],
  ["SA", 88.45631487889274],
  ["HK", 85.83434256055364],
  ["KG", 85.13019031141869],
  ["GD", 85.25086505190312],
  ["GQ", 68.75043252595155],
  ["DJ", 55.95934256055364],
  ["KE", 95.4243079584775],
  ["KR", 76.07439446366782],
  ["SC", 80.94506920415225],
  ["ST", 62.01859861591695],
  ["PY", 64.83780276816609],
  ["SB", 86.59645328719722],
  ["IL", 69.36937716262975],
  ["DK", 77.61461937716263],
  ["DO", 75.78460207612456],
  ["RU", 96.51470588235294],
  ["SG", 77.32439446366783],
  ["PK", 87.26686851211073],
  ["GB", 79.13754325259515],
  ["GU", 95.60640138408304],
  ["GW", 72.08001730103805],
  ["JP", 76.65397923875432],
  ["SR", 91.25562283737024],
  ["SE", 94.47967128027682],
  ["SD", 71.06358131487889],
  ["SS", 93.88538062283736],
  ["PH", 83.625],
  ["RW", 90.99437716262976],
  ["KU", 70.57266435986159],
  ["HN", 63.94031141868513],
  ["GA", 90.125],
  ["DZ", 79.4636678200692],
  ["DM", 85.76167820069203],
  ["FR", 87.33434256055364],
  ["GL", 78.96280276816609],
  ["ES", 89.91435986159169],
  ["HT", 95.48096885813149],
  ["PE", 84.20588235294117],
  ["QA", 86.14576124567475],
  ["SI", 94.36634948096886],
  ["PR", 82.43122837370242],
  ["PS", 72.48399653979239],
  ["IQ", 66.60856401384083],
  ["HU", 62.50692041522491],
  ["KN", 88.29757785467129],
  ["KY", 89.79455017301038],
  ["FI", 95.1219723183391],
  ["ER", 82.54498269896193],
  ["GM", 85.15397923875433],
  ["EE", 95.25216262975779],
  ["EG", 65.35769896193771],
  ["ID", 79.27162629757785],
  ["IS", 84.80622837370242],
  ["SK", 92.89749134948096],
  ["RO", 95.91868512110726],
  ["PG", 90.59515570934255],
  ["KM", 80.73140138408304],
  ["IR", 57.61115916955018],
  ["KZ", 62.399221453287204],
  ["IE", 75.6673875432526],
  ["GN", 93.30925605536332],
  ["FJ", 87.50129757785467],
  ["GY", 65.93641868512111],
  ["JM", 76.3598615916955],
  ["KI", 82.90787197231833],
  ["HR", 63.1522491349481],
  ["SO", 90.1583044982699],
  ["PT", 89.17906574394465],
  ["SY", 65.52076124567475],
  ["SN", 92.1007785467128],
  ["KH", 88.45285467128028],
  ["EC", 89.98140138408304],
  ["ET", 84.11245674740483],
  ["DE", 99.11764705882354],
  ["SL", 62.92430795847751],
  ["PW", 92.44896193771626],
  ["SZ", 83.81487889273357],
  ["PA", 79.75865051903114],
  ["SM", 83.14489619377163],
  ["IT", 84.59688581314879],
  ["JO", 75.40787197231835],
  ["GH", 88.68598615916954],
  ["CG", 89.47707612456747],
  ["BT", 87.71237024221453],
  ["AO", 92.35423875432525],
  ["ML", 82.7629757785467],
  ["TN", 86.49740484429066],
  ["LI", 95.82525951557093],
  ["MM", 87.89186851211073],
  ["MZ", 91.42603806228374],
  ["NA", 87.48659169550173],
  ["CF", 78.52205882352942],
  ["BB", 96.40657439446368],
  ["CD", 88.82179930795849],
  ["BW", 87.22534602076124],
  ["AL", 90.7893598615917],
  ["NC", 90.34645328719724],
  ["MX", 88.28892733564014],
  ["TZ", 89.46626297577855],
  ["VE", 94.63667820069203],
  ["MN", 89.98918685121107],
  ["MY", 79.33693771626298],
  ["AM", 94.01903114186851],
  ["CR", 96.20588235294117],
  ["BA", 95.04152249134948],
  ["AZ", 64.00865051903114],
  ["CV", 87.03460207612457],
  ["BR", 63.125],
  ["CA", 88.64662629757785],
  ["BE", 96.66911764705883],
  ["TH", 97.3265570934256],
  ["WS", 95.32396193771626],
  ["UZ", 0],
  ["Wales", 56.424307958477506],
  ["NG", 85.34169550173011],
  ["NP", 77.25173010380622],
  ["MK", 81.8295847750865],
  ["BD", 91.5242214532872],
  ["BS", 66.88884083044982],
  ["CU", 86.94636678200692],
  ["BF", 62.897923875432525],
  ["NR", 97.33477508650519],
  ["NE", 37.47621107266436],
  ["ZW", 84.77681660899654],
  ["VC", 91.65960207612457],
  ["UY", 82.07698961937716],
  ["TJ", 65.29584775086505],
  ["VU", 90.33304498269896],
  ["ZA", 86.73140138408304],
  ["BG", 96.22058823529412],
]);

export const walesMap = new Map<string, number>([
  ["CY", 59.78054798078172],
  ["AF", 78.07167900272692],
  ["CN", 77.21724451369953],
  ["BJ", 79.04070899883132],
  ["LA", 80.13894299441631],
  ["ME", 73.65601869887027],
  ["OM", 58.04668224905857],
  ["NI", 79.69874042332165],
  ["MR", 74.87891182963251],
  ["TG", 83.41773795610959],
  ["TP", 75.20257109466303],
  ["North Korea", 76.02454226723802],
  ["VN", 79.00370081807557],
  ["ZM", 78.06843267108168],
  ["MD", 82.37923646279704],
  ["CO", 79.28483313855344],
  ["AG", 82.60680431112843],
  ["AE", 87.4837683417738],
  ["CZ", 54.17737956109596],
  ["AR", 87.13965718737826],
  ["BI", 65.39735099337747],
  ["CM", 77.1643293078821],
  ["LB", 75.07953512530841],
  ["LU", 80.52687962602259],
  ["TD", 88.03402155564213],
  ["TR", 74.58933904687703],
  ["Scotland", 74.81333593039865],
  ["UA", 89.58609271523179],
  ["LT", 78.99948058693677],
  ["LC", 97.80612907414621],
  ["MG", 68.85729126087521],
  ["CL", 60.65770679132581],
  ["BH", 62.44026749772756],
  ["AD", 86.90527204259187],
  ["CH", 85.95312297104272],
  ["NO", 75.69893520322036],
  ["MT", 69.97402934683808],
  ["MC", 87.89605246071939],
  ["TV", 71.84813660563563],
  ["TW", 73.66380989481885],
  ["US", 73.18108037917153],
  ["MU", 64.44747435398],
  ["BZ", 83.22198415790157],
  ["CI", 87.42500973899494],
  ["BM", 67.24451369951954],
  ["CK", 71.91046617322425],
  ["AT", 79.9285807038047],
  ["BO", 85.79827295156474],
  ["LS", 55.24509803921569],
  ["NL", 78.4300740163615],
  ["MW", 73.9582521750422],
  ["YE", 83.9030645370731],
  ["UG", 87.97266588754708],
  ["TT", 78.21451759511751],
  ["NZ", 71.66114790286976],
  ["MA", 73.23464485131801],
  ["MV", 76.85560316841969],
  ["LR", 71.30762238670303],
  ["BY", 72.84995455135697],
  ["BN", 80.18796260225945],
  ["AU", 73.73555382417868],
  ["GE", 60.41747824957797],
  ["GR", 74.7331515387612],
  ["IN", 82.5714192961953],
  ["GBENG", 59.258862485391504],
  ["RS", 84.23970912868458],
  ["PL", 49.85391507596416],
  ["SV", 76.66082326970523],
  ["SA", 76.39981820542788],
  ["HK", 82.08868978054798],
  ["KG", 79.37443189196209],
  ["GD", 82.28249577976887],
  ["GQ", 81.2547071808856],
  ["DJ", 57.43442410076614],
  ["KE", 73.84203350214258],
  ["KR", 56.11543955330477],
  ["SC", 76.53389170237631],
  ["ST", 68.52778859888326],
  ["PY", 76.38748214517595],
  ["SB", 69.96786131671212],
  ["IL", 65.3746266718608],
  ["DK", 77.7600311647838],
  ["DO", 78.24892871055708],
  ["RU", 51.589079340345414],
  ["SG", 87.56590053239839],
  ["PK", 68.88423581353071],
  ["GB", 68.43104791585507],
  ["GU", 77.1831580314245],
  ["GW", 62.42760680431113],
  ["JP", 48.63004804570835],
  ["SR", 74.70068822230878],
  ["SE", 82.71815348656018],
  ["SD", 85.04837034151409],
  ["SS", 73.33073626801713],
  ["PH", 66.09498766393975],
  ["RW", 87.10264900662251],
  ["KU", 81.4676665368134],
  ["HN", 80.10388261264771],
  ["GA", 87.22730814179977],
  ["DZ", 65.00486949746787],
  ["DM", 76.9218283339826],
  ["FR", 83.32684067004286],
  ["GL", 51.84521490715491],
  ["ES", 85.39475392806129],
  ["HT", 73.61414102064667],
  ["PE", 82.82560706401766],
  ["QA", 62.53798208024932],
  ["SI", 53.944942215296706],
  ["PR", 77.44383846253733],
  ["PS", 81.32774964290353],
  ["IQ", 82.2399688352162],
  ["HU", 79.25042202311388],
  ["KN", 80.29152058174263],
  ["KY", 71.88092455525256],
  ["FI", 58.28983248928711],
  ["ER", 70.48727437995066],
  ["GM", 75.60154525386314],
  ["EE", 91.77249707830151],
  ["EG", 83.80502532138682],
  ["ID", 87.99798727437995],
  ["IS", 73.23432021815348],
  ["SK", 53.76444617582132],
  ["RO", 88.21906245942085],
  ["PG", 81.78191144007272],
  ["KM", 69.55914816257629],
  ["IR", 75.62719127386053],
  ["KZ", 79.87274379950657],
  ["IE", 74.3922867160109],
  ["GN", 89.03486560186988],
  ["FJ", 81.80950525905727],
  ["GY", 65.25061680301259],
  ["JM", 75.31262173743669],
  ["KI", 78.18270354499415],
  ["HR", 79.64777301649136],
  ["SO", 82.81164783794313],
  ["PT", 76.89845474613686],
  ["SY", 82.70127256200493],
  ["SN", 87.22081547850928],
  ["KH", 76.7156862745098],
  ["EC", 78.19374107258797],
  ["ET", 78.0794701986755],
  ["DE", 83.02882742500974],
  ["SL", 81.3124918841709],
  ["PW", 95.00064926632905],
  ["SZ", 80.22659394883782],
  ["PA", 67.96000519413063],
  ["SM", 60.195104531878975],
  ["IT", 70.52460719387092],
  ["JO", 77.7330866121283],
  ["GH", 83.76379690949227],
  ["CG", 78.5956369302688],
  ["BT", 88.73393065835606],
  ["AO", 85.26749772756786],
  ["ML", 75.92487988572913],
  ["TN", 78.88001558239189],
  ["LI", 70.96416049863655],
  ["MM", 84.40040254512401],
  ["MZ", 85.47623685235683],
  ["NA", 52.57563952733412],
  ["CF", 69.82275029216984],
  ["BB", 87.83047656148553],
  ["CD", 76.66406960135048],
  ["BW", 91.41312816517335],
  ["AL", 78.4112452928191],
  ["NC", 59.4568887157512],
  ["MX", 79.81917932736008],
  ["TZ", 81.67413322945072],
  ["VE", 76.34105960264901],
  ["MN", 70.74568237891184],
  ["MY", 71.60758343072328],
  ["AM", 78.40994676016102],
  ["CR", 75.56713413842358],
  ["BA", 84.2965199324763],
  ["AZ", 75.94403324243605],
  ["CV", 77.5701207635372],
  ["BR", 51.812102324373456],
  ["CA", 76.31021945201923],
  ["BE", 91.38715751201143],
  ["TH", 75.0889494870796],
  ["WS", 71.34300740163614],
  ["UZ", 56.68809245552525],
  ["Wales", 0],
  ["NG", 83.98844305934294],
  ["NP", 57.4379950655759],
  ["MK", 71.38748214517595],
  ["BD", 76.39949357226334],
  ["BS", 86.93838462537333],
  ["CU", 78.82417867809376],
  ["BF", 46.41053109985716],
  ["NR", 78.67776912089339],
  ["NE", 53.728411894559144],
  ["ZW", 79.48188546941955],
  ["VC", 90.28080768731334],
  ["UY", 61.82411375146085],
  ["TJ", 77.94572133489157],
  ["VU", 58.50603817686015],
  ["ZA", 80.27204259187118],
  ["BG", 55.75152577587327],
]);

export const ngMap = new Map<string, number>([
  ["CY", 75.1583044982699],
  ["AF", 96.29974048442907],
  ["CN", 97.20501730103807],
  ["BJ", 65.67517301038063],
  ["LA", 90.5],
  ["ME", 96.8416955017301],
  ["OM", 83.77551903114187],
  ["NI", 89.40873702422145],
  ["MR", 96.82482698961937],
  ["TG", 70.76297577854672],
  ["TP", 96.37673010380622],
  ["North Korea", 92.95501730103807],
  ["VN", 97.30709342560554],
  ["ZM", 95.05968858131489],
  ["MD", 93.12889273356402],
  ["CO", 97.6038062283737],
  ["AG", 86.53892733564014],
  ["AE", 74.75648788927336],
  ["CZ", 81.80147058823529],
  ["AR", 90.91479238754326],
  ["BI", 78.47015570934256],
  ["CM", 65.63840830449827],
  ["LB", 89.72750865051903],
  ["LU", 88.45415224913495],
  ["TD", 96.16868512110727],
  ["TR", 96.20717993079585],
  ["Scotland", 81.5090830449827],
  ["UA", 97.9939446366782],
  ["LT", 77.31617647058823],
  ["LC", 94.87673010380622],
  ["MG", 82.98615916955018],
  ["CL", 81.45415224913495],
  ["BH", 96.81531141868513],
  ["AD", 92.73053633217994],
  ["CH", 84.41868512110726],
  ["NO", 90.18814878892734],
  ["MT", 82.5363321799308],
  ["MC", 83.18079584775087],
  ["TV", 85.82266435986159],
  ["TW", 98.45025951557093],
  ["US", 75.39749134948097],
  ["MU", 81.75389273356402],
  ["BZ", 81.20934256055364],
  ["CI", 33.07612456747405],
  ["BM", 88.47102076124568],
  ["CK", 88.55493079584775],
  ["AT", 88.5423875432526],
  ["BO", 77.08304498269896],
  ["LS", 69.91955017301038],
  ["NL", 88.6461937716263],
  ["MW", 98.38667820069205],
  ["YE", 88.84775086505189],
  ["UG", 92.6833910034602],
  ["TT", 92.74134948096886],
  ["NZ", 89.64662629757785],
  ["MA", 99.69550173010381],
  ["MV", 81.42474048442907],
  ["LR", 82.07482698961938],
  ["BY", 92.71885813148789],
  ["BN", 90.26946366782008],
  ["AU", 92.6310553633218],
  ["GE", 79.17128027681662],
  ["GR", 84.58520761245674],
  ["IN", 90.8477508650519],
  ["GBENG", 82.66608996539793],
  ["RS", 82.93555363321799],
  ["PL", 83.03979238754326],
  ["SV", 90.59861591695501],
  ["SA", 31.20458477508651],
  ["HK", 90.9666955017301],
  ["KG", 91.69766435986159],
  ["GD", 68.36029411764706],
  ["GQ", 86.60034602076124],
  ["DJ", 92.54238754325259],
  ["KE", 94.71496539792388],
  ["KR", 79.11678200692042],
  ["SC", 76.18166089965398],
  ["ST", 93.16392733564014],
  ["PY", 90.49005190311419],
  ["SB", 89.12110726643598],
  ["IL", 80.58217993079585],
  ["DK", 86.35207612456747],
  ["DO", 82.61721453287197],
  ["RU", 88.06833910034601],
  ["SG", 79.17820069204153],
  ["PK", 93.59688581314879],
  ["GB", 78.37543252595157],
  ["GU", 96.8174740484429],
  ["GW", 79.88884083044982],
  ["JP", 83.43209342560553],
  ["SR", 91.91998269896193],
  ["SE", 96.29195501730104],
  ["SD", 85.80320069204153],
  ["SS", 91.54238754325259],
  ["PH", 96.51470588235294],
  ["RW", 93.67387543252596],
  ["KU", 69.83953287197232],
  ["HN", 89.16349480968859],
  ["GA", 76.08910034602077],
  ["DZ", 85.16392733564014],
  ["DM", 46.79282006920415],
  ["FR", 66.16695501730105],
  ["GL", 82.04887543252595],
  ["ES", 94.52681660899654],
  ["HT", 97.48269896193771],
  ["PE", 66.46020761245674],
  ["QA", 98.07915224913495],
  ["SI", 85.5916955017301],
  ["PR", 80.93295847750865],
  ["PS", 68.05406574394463],
  ["IQ", 90.42171280276816],
  ["HU", 66.46539792387543],
  ["KN", 74.06055363321799],
  ["KY", 88.0008650519031],
  ["FI", 83.46842560553634],
  ["ER", 84.95588235294119],
  ["GM", 95.58823529411765],
  ["EE", 88.45934256055364],
  ["EG", 90.52378892733563],
  ["ID", 83.18079584775087],
  ["IS", 91.67690311418684],
  ["SK", 85.12413494809688],
  ["RO", 96.16868512110727],
  ["PG", 93.99437716262976],
  ["KM", 85.18685121107266],
  ["IR", 82.36375432525952],
  ["KZ", 93.50951557093425],
  ["IE", 33.08001730103806],
  ["GN", 63.2348615916955],
  ["FJ", 89.21410034602076],
  ["GY", 58.38927335640138],
  ["JM", 84.55233564013841],
  ["KI", 84.72448096885813],
  ["HR", 91.00043252595155],
  ["SO", 95.37370242214533],
  ["PT", 93.83391003460207],
  ["SY", 87.47794117647058],
  ["SN", 61.60121107266436],
  ["KH", 91.79887543252595],
  ["EC", 94.16652249134948],
  ["ET", 96.62673010380622],
  ["DE", 97.66176470588235],
  ["SL", 88.25865051903115],
  ["PW", 96.26903114186851],
  ["SZ", 84.93382352941177],
  ["PA", 81.41392733564014],
  ["SM", 85.7288062283737],
  ["IT", 33.08001730103806],
  ["JO", 72.42171280276817],
  ["GH", 74.92733564013841],
  ["CG", 69.99264705882354],
  ["BT", 88.54195501730104],
  ["AO", 95.99956747404845],
  ["ML", 96.02249134948096],
  ["TN", 90.44896193771626],
  ["LI", 97.12586505190312],
  ["MM", 87.02032871972318],
  ["MZ", 75.15527681660899],
  ["NA", 67.49524221453287],
  ["CF", 93.01600346020761],
  ["BB", 93.93555363321799],
  ["CD", 93.66522491349481],
  ["BW", 97.03979238754326],
  ["AL", 97.47621107266437],
  ["NC", 75.20934256055364],
  ["MX", 37.36202422145329],
  ["TZ", 93.50432525951558],
  ["VE", 95.92863321799308],
  ["MN", 95.30968858131487],
  ["MY", 78.16868512110726],
  ["AM", 98.91868512110726],
  ["CR", 85.99178200692042],
  ["BA", 91.88581314878893],
  ["AZ", 97.02811418685121],
  ["CV", 91.89835640138409],
  ["BR", 39.87932525951557],
  ["CA", 78.6007785467128],
  ["BE", 96.46410034602077],
  ["TH", 88.54974048442907],
  ["WS", 96.9576124567474],
  ["UZ", 85.34169550173011],
  ["Wales", 83.64489619377163],
  ["NG", 0],
  ["NP", 78.20198961937717],
  ["MK", 84.67474048442907],
  ["BD", 35.93987889273356],
  ["BS", 96.37629757785467],
  ["CU", 81.18987889273357],
  ["BF", 65.12153979238754],
  ["NR", 96.95069204152249],
  ["NE", 94.06444636678201],
  ["ZW", 94.30017301038063],
  ["VC", 68.27465397923875],
  ["UY", 77.90570934256056],
  ["TJ", 85.59904844290658],
  ["VU", 78.26600346020761],
  ["ZA", 72.57612456747404],
  ["BG", 66.2711937716263],
]);

export const npMap = new Map<string, number>([
  ["CY", 50.93506219692178],
  ["AF", 85.85837022981235],
  ["CN", 85.43432426734134],
  ["BJ", 79.66661395741093],
  ["LA", 70.34419144001687],
  ["ME", 84.16376765760067],
  ["OM", 64.90644107105209],
  ["NI", 77.87397216951297],
  ["MR", 86.30455407969639],
  ["TG", 79.67557453088763],
  ["TP", 69.39595192915876],
  ["North Korea", 64.70930845456462],
  ["VN", 61.27951718321737],
  ["ZM", 87.08834071262915],
  ["MD", 79.9770714737508],
  ["CO", 76.88277461522244],
  ["AG", 71.35858106683534],
  ["AE", 67.93010752688173],
  ["CZ", 61.14010120177104],
  ["AR", 85.14389626818469],
  ["BI", 66.07131562302341],
  ["CM", 74.92647058823529],
  ["LB", 53.84751212312883],
  ["LU", 73.33913135146531],
  ["TD", 87.68843558928948],
  ["TR", 82.17162133670672],
  ["Scotland", 68.52835757959099],
  ["UA", 89.12555344718533],
  ["LT", 76.94128188909973],
  ["LC", 97.005587181109],
  ["MG", 83.76923887834704],
  ["CL", 48.00231920725279],
  ["BH", 67.55982500527092],
  ["AD", 85.0606156441071],
  ["CH", 84.18221589711153],
  ["NO", 64.81103731815307],
  ["MT", 71.65032679738562],
  ["MC", 59.46236559139785],
  ["TV", 79.7240670461733],
  ["TW", 87.48945814885094],
  ["US", 65.6999789162977],
  ["MU", 84.43522032468903],
  ["BZ", 71.3572633354417],
  ["CI", 82.85315201349357],
  ["BM", 57.79517183217373],
  ["CK", 71.82505798018131],
  ["AT", 58.31304026987139],
  ["BO", 83.44771241830065],
  ["LS", 74.88851992409867],
  ["NL", 76.92757748260594],
  ["MW", 78.04738562091504],
  ["YE", 73.83169934640523],
  ["UG", 87.31868016023614],
  ["TT", 65.75795909761754],
  ["NZ", 73.47828378663294],
  ["MA", 57.50632511068944],
  ["MV", 66.23207885304659],
  ["LR", 61.32273877292853],
  ["BY", 72.8386569681636],
  ["BN", 81.58971115327851],
  ["AU", 85.25563989036475],
  ["GE", 57.33554712207464],
  ["GR", 70.69760699978916],
  ["IN", 81.03441914400169],
  ["GBENG", 44.95862323423993],
  ["RS", 75.00368964790218],
  ["PL", 47.883723381825845],
  ["SV", 73.94818680160236],
  ["SA", 83.09587813620072],
  ["HK", 84.30292009276829],
  ["KG", 60.81066835336285],
  ["GD", 73.32226438962682],
  ["GQ", 75.00579801813198],
  ["DJ", 87.52714526670884],
  ["KE", 80.54264178789795],
  ["KR", 50.644897744043845],
  ["SC", 66.87460468058191],
  ["ST", 80.37608053974277],
  ["PY", 67.98993253215264],
  ["SB", 79.06757326586549],
  ["IL", 61.60552393000211],
  ["DK", 63.33913135146532],
  ["DO", 72.31683533628505],
  ["RU", 52.03563145688383],
  ["SG", 60.7982816782627],
  ["PK", 83.62771452667089],
  ["GB", 55.668089816571786],
  ["GU", 81.58101412608055],
  ["GW", 75.67467847353996],
  ["JP", 50.665454353784526],
  ["SR", 77.24910394265233],
  ["SE", 83.98692810457517],
  ["SD", 75.05402698713894],
  ["SS", 78.3011806873287],
  ["PH", 71.47401433691756],
  ["RW", 90.88788741302973],
  ["KU", 67.3431899641577],
  ["HN", 77.53452456251317],
  ["GA", 88.92868437697659],
  ["DZ", 53.21421041534894],
  ["DM", 78.31620282521611],
  ["FR", 72.9764916719376],
  ["GL", 44.40754796542273],
  ["ES", 73.79401222854733],
  ["HT", 66.18912080961417],
  ["PE", 62.371125869702716],
  ["QA", 81.77472064094455],
  ["SI", 52.138414505587185],
  ["PR", 69.73513598987982],
  ["PS", 70.3831962892684],
  ["IQ", 73.60610373181531],
  ["HU", 71.91545435378453],
  ["KN", 76.2152118912081],
  ["KY", 72.29548808770821],
  ["FI", 57.64020662028252],
  ["ER", 68.09508749736453],
  ["GM", 74.78310141260806],
  ["EE", 83.89231499051233],
  ["EG", 74.112376133249],
  ["ID", 59.543801391524354],
  ["IS", 70.31493780307822],
  ["SK", 47.67947501581278],
  ["RO", 84.05202403542063],
  ["PG", 84.78072949609951],
  ["KM", 78.51359898798229],
  ["IR", 73.56472696605523],
  ["KZ", 88.43163609529834],
  ["IE", 81.08291165928738],
  ["GN", 75.32653383934219],
  ["FJ", 85.03505165507063],
  ["GY", 78.40396373603204],
  ["JM", 85.26881720430107],
  ["KI", 62.211416824794426],
  ["HR", 75.74689015391102],
  ["SO", 90.94692177946447],
  ["PT", 82.73613746573898],
  ["SY", 73.06451612903226],
  ["SN", 86.35568205776934],
  ["KH", 69.48002319207252],
  ["EC", 73.7083596879612],
  ["ET", 81.75179211469535],
  ["DE", 89.65686274509804],
  ["SL", 79.60968796120599],
  ["PW", 93.62270714737508],
  ["SZ", 82.74483449293696],
  ["PA", 64.12001897533207],
  ["SM", 67.16371494834493],
  ["IT", 76.61870124393843],
  ["JO", 67.86870124393843],
  ["GH", 82.47100990934008],
  ["CG", 78.77714526670884],
  ["BT", 87.18822475226649],
  ["AO", 77.91165928737087],
  ["ML", 88.89047016656126],
  ["TN", 83.82405650432216],
  ["LI", 66.05102255956146],
  ["MM", 76.39205144423362],
  ["MZ", 79.08602150537635],
  ["NA", 71.72227493147797],
  ["CF", 74.16376765760067],
  ["BB", 83.74051233396584],
  ["CD", 79.55065359477123],
  ["BW", 93.94528779253636],
  ["AL", 63.10931899641577],
  ["NC", 86.57126291376765],
  ["MX", 75.77877925363694],
  ["TZ", 88.9993147796753],
  ["VE", 70.76823740248787],
  ["MN", 71.53436643474595],
  ["MY", 61.22311827956989],
  ["AM", 88.04949399114484],
  ["CR", 60.00237191650854],
  ["BA", 78.03262702930634],
  ["AZ", 78.0668880455408],
  ["CV", 70.28462998102466],
  ["BR", 82.00637781994519],
  ["CA", 70.9848724436011],
  ["BE", 91.06077377187434],
  ["TH", 63.125922411975544],
  ["WS", 63.42715580855999],
  ["UZ", 78.0371073160447],
  ["Wales", 57.516076323002316],
  ["NG", 78.69307400379508],
  ["NP", 0],
  ["MK", 78.00785367910605],
  ["BD", 77.08096141682479],
  ["BS", 92.81441071052076],
  ["CU", 58.504638414505585],
  ["BF", 74.65106472696606],
  ["NR", 80.47227493147797],
  ["NE", 80.40006325110689],
  ["ZW", 86.71884882985452],
  ["VC", 87.77118912080961],
  ["UY", 64.99947290744255],
  ["TJ", 73.91893316466371],
  ["VU", 83.90364748049758],
  ["ZA", 80.00184482395109],
  ["BG", 67.73297491039426],
]);

export const mkMap = new Map<string, number>([
  ["CY", 86.69506920415225],
  ["AF", 68.81574394463668],
  ["CN", 80.25259515570934],
  ["BJ", 77.73875432525952],
  ["LA", 82.05017301038062],
  ["ME", 41.57396193771626],
  ["OM", 63.24351211072664],
  ["NI", 83.29282006920415],
  ["MR", 82.1613321799308],
  ["TG", 80.78157439446368],
  ["TP", 83.25389273356402],
  ["North Korea", 80.48226643598616],
  ["VN", 79.9143598615917],
  ["ZM", 80.29195501730104],
  ["MD", 80.02854671280278],
  ["CO", 74.65397923875432],
  ["AG", 78.1916089965398],
  ["AE", 85.74524221453287],
  ["CZ", 64.05622837370242],
  ["AR", 85.18122837370242],
  ["BI", 75.82266435986159],
  ["CM", 75.12716262975778],
  ["LB", 82.99524221453287],
  ["LU", 85.01167820069205],
  ["TD", 79.08996539792388],
  ["TR", 34.26384083044983],
  ["Scotland", 77.62543252595155],
  ["UA", 75.34212802768167],
  ["LT", 82.40743944636678],
  ["LC", 84.46107266435986],
  ["MG", 83.57439446366783],
  ["CL", 83.07050173010381],
  ["BH", 80.83001730103805],
  ["AD", 79.85207612456747],
  ["CH", 82.99221453287198],
  ["NO", 81.26903114186851],
  ["MT", 83.24697231833909],
  ["MC", 82.75519031141869],
  ["TV", 88.6189446366782],
  ["TW", 80.54930795847751],
  ["US", 75.21972318339101],
  ["MU", 78.37846020761245],
  ["BZ", 76.77984429065744],
  ["CI", 86.08693771626298],
  ["BM", 76.16608996539792],
  ["CK", 77.63235294117648],
  ["AT", 85.05882352941177],
  ["BO", 75.76686851211073],
  ["LS", 85.07093425605537],
  ["NL", 85.15138408304497],
  ["MW", 77.70544982698962],
  ["YE", 87.31098615916954],
  ["UG", 65.21885813148789],
  ["TT", 81.90700692041523],
  ["NZ", 77.74740484429066],
  ["MA", 82.41782006920415],
  ["MV", 80.77205882352942],
  ["LR", 76.49567474048443],
  ["BY", 80.76254325259515],
  ["BN", 72.73183391003461],
  ["AU", 75.94290657439447],
  ["GE", 81.4446366782007],
  ["GR", 82.47102076124567],
  ["IN", 84.44117647058823],
  ["GBENG", 84.8901384083045],
  ["RS", 78.88148788927336],
  ["PL", 83.75692041522491],
  ["SV", 84.50562283737024],
  ["SA", 79.65354671280276],
  ["HK", 81.24307958477509],
  ["KG", 79.17257785467127],
  ["GD", 71.96885813148789],
  ["GQ", 81.08737024221453],
  ["DJ", 82.40830449826989],
  ["KE", 72.31271626297577],
  ["KR", 82.9969723183391],
  ["SC", 77.98140138408304],
  ["ST", 77.45891003460208],
  ["PY", 84.52724913494811],
  ["SB", 76.01557093425606],
  ["IL", 83.25648788927336],
  ["DK", 82.29628027681662],
  ["DO", 84.18122837370242],
  ["RU", 82.97404844290658],
  ["SG", 80.26686851211072],
  ["PK", 81.9545847750865],
  ["GB", 76.86072664359862],
  ["GU", 79.60899653979239],
  ["GW", 76.83520761245674],
  ["JP", 83.76081314878893],
  ["SR", 80.11029411764706],
  ["SE", 74.15008650519032],
  ["SD", 84.0666089965398],
  ["SS", 76.16089965397924],
  ["PH", 81.24567474048443],
  ["RW", 76.49870242214533],
  ["KU", 82.94896193771626],
  ["HN", 83.80363321799308],
  ["GA", 75.38494809688582],
  ["DZ", 81.79195501730105],
  ["DM", 81.51859861591696],
  ["FR", 84.5371972318339],
  ["GL", 82.12456747404843],
  ["ES", 55.281574394463675],
  ["HT", 82.54238754325259],
  ["PE", 84.38321799307958],
  ["QA", 81.61332179930795],
  ["SI", 81.48313148788927],
  ["PR", 62.396193771626294],
  ["PS", 83.99437716262976],
  ["IQ", 84.90743944636678],
  ["HU", 85.0696366782007],
  ["KN", 78.8114186851211],
  ["KY", 75.55709342560554],
  ["FI", 84.20717993079585],
  ["ER", 74.23269896193771],
  ["GM", 83.72664359861591],
  ["EE", 89.00043252595155],
  ["EG", 85.66695501730104],
  ["ID", 82.58304498269896],
  ["IS", 82.49048442906575],
  ["SK", 80.65008650519032],
  ["RO", 79.10250865051903],
  ["PG", 83.67603806228374],
  ["KM", 74.05493079584775],
  ["IR", 64.62543252595155],
  ["KZ", 80.41825259515572],
  ["IE", 87.10510380622839],
  ["GN", 78.84126297577855],
  ["FJ", 76.55882352941177],
  ["GY", 68.8992214532872],
  ["JM", 71.04671280276817],
  ["KI", 75.15354671280276],
  ["HR", 80.65657439446366],
  ["SO", 81.67906574394463],
  ["PT", 79.5060553633218],
  ["SY", 86.54498269896193],
  ["SN", 83.76643598615917],
  ["KH", 80.02768166089965],
  ["EC", 73.46020761245676],
  ["ET", 55.2742214532872],
  ["DE", 67.95631487889273],
  ["SL", 84.92171280276817],
  ["PW", 87.44982698961937],
  ["SZ", 76.33347750865052],
  ["PA", 81.24740484429066],
  ["SM", 82.09299307958477],
  ["IT", 84.45804498269896],
  ["JO", 84.70631487889273],
  ["GH", 77.29368512110727],
  ["CG", 81.46150519031143],
  ["BT", 80.93987889273356],
  ["AO", 83.57396193771626],
  ["ML", 78.65916955017302],
  ["TN", 34.01254325259516],
  ["LI", 81.30925605536332],
  ["MM", 78.01686851211073],
  ["MZ", 75.73745674740483],
  ["NA", 76.58434256055364],
  ["CF", 77.91608996539793],
  ["BB", 84.4969723183391],
  ["CD", 77.06704152249135],
  ["BW", 87.08953287197232],
  ["AL", 33.86678200692042],
  ["NC", 76.92647058823529],
  ["MX", 83.27681660899654],
  ["TZ", 79.55666089965398],
  ["VE", 76.48572664359861],
  ["MN", 79.08910034602076],
  ["MY", 74.21280276816609],
  ["AM", 63.24783737024221],
  ["CR", 81.87586505190312],
  ["BA", 76.25129757785467],
  ["AZ", 80.99913494809688],
  ["CV", 78.4212802768166],
  ["BR", 75.07352941176471],
  ["CA", 81.19377162629758],
  ["BE", 86.15484429065744],
  ["TH", 82.87846020761246],
  ["WS", 81.64100346020761],
  ["UZ", 81.8295847750865],
  ["Wales", 71.0030276816609],
  ["NG", 84.67474048442907],
  ["NP", 77.63105536332179],
  ["MK", 0],
  ["BD", 79.65743944636678],
  ["BS", 82.4325259515571],
  ["CU", 80.57785467128028],
  ["BF", 80.45069204152249],
  ["NR", 76.31920415224913],
  ["NE", 84.62629757785467],
  ["ZW", 75.23313148788927],
  ["VC", 78.01773356401384],
  ["UY", 78.76297577854672],
  ["TJ", 67.90138408304497],
  ["VU", 77.28589965397924],
  ["ZA", 79.70328719723183],
  ["BG", 82.84688581314879],
]);

export const bdMap = new Map<string, number>([
  ["CY", 94.79851499423299],
  ["AF", 86.28964821222607],
  ["CN", 91.00850634371396],
  ["BJ", 57.97361591695501],
  ["LA", 94.42365916955018],
  ["ME", 81.23882641291812],
  ["OM", 90.59904844290656],
  ["NI", 95.01333621683968],
  ["MR", 16.299740484429066],
  ["TG", 56.426614763552486],
  ["TP", 92.22066032295271],
  ["North Korea", 81.32172722029989],
  ["VN", 91.80723760092272],
  ["ZM", 90.60193194925029],
  ["MD", 92.62218858131487],
  ["CO", 96.26802191464822],
  ["AG", 92.85899653979239],
  ["AE", 97.12262110726644],
  ["CZ", 92.74690023068051],
  ["AR", 96.69694348327566],
  ["BI", 88.71972318339101],
  ["CM", 63.30053344867359],
  ["LB", 90.4415369088812],
  ["LU", 92.54433391003461],
  ["TD", 98.18807670126874],
  ["TR", 91.96042387543253],
  ["Scotland", 89.7621107266436],
  ["UA", 96.73046424452134],
  ["LT", 76.16926182237601],
  ["LC", 99.77364475201846],
  ["MG", 64.34436274509804],
  ["CL", 94.65758362168397],
  ["BH", 92.83088235294117],
  ["AD", 93.82244809688581],
  ["CH", 94.08628892733563],
  ["NO", 89.38689446366782],
  ["MT", 96.59421856978085],
  ["MC", 96.955738177624],
  ["TV", 87.75158592848904],
  ["TW", 90.63256920415225],
  ["US", 85.53957612456747],
  ["MU", 94.3267012687428],
  ["BZ", 87.85683391003461],
  ["CI", 98.84227220299884],
  ["BM", 85.30384948096886],
  ["CK", 87.64597750865052],
  ["AT", 89.44564590542099],
  ["BO", 67.35762687427912],
  ["LS", 96.84544405997694],
  ["NL", 95.70501730103807],
  ["MW", 90.14597750865052],
  ["YE", 97.6391291810842],
  ["UG", 94.23803344867359],
  ["TT", 91.6472029988466],
  ["NZ", 87.35402249134948],
  ["MA", 90.78539504036908],
  ["MV", 78.3145905420992],
  ["LR", 93.76658016147636],
  ["BY", 87.53388119953864],
  ["BN", 93.58527970011534],
  ["AU", 86.21864186851211],
  ["GE", 91.56898788927336],
  ["GR", 95.8095444059977],
  ["IN", 97.0696366782007],
  ["GBENG", 96.87932525951557],
  ["RS", 88.67791234140715],
  ["PL", 88.2316897347174],
  ["SV", 94.95854959630911],
  ["SA", 15.745386389850058],
  ["HK", 90.23032006920415],
  ["KG", 83.32179930795847],
  ["GD", 69.23659169550173],
  ["GQ", 91.86130334486737],
  ["DJ", 93.78532295271049],
  ["KE", 90.39936562860437],
  ["KR", 94.9989186851211],
  ["SC", 64.98666378316032],
  ["ST", 92.91594579008074],
  ["PY", 93.89201268742792],
  ["SB", 84.58946078431373],
  ["IL", 93.22916666666666],
  ["DK", 95.302407727797],
  ["DO", 95.70826124567475],
  ["RU", 95.15534890426758],
  ["SG", 85.13660611303345],
  ["PK", 92.55875144175317],
  ["GB", 82.25237889273357],
  ["GU", 87.72130911188005],
  ["GW", 93.69593425605537],
  ["JP", 96.10942906574394],
  ["SR", 94.05781430219147],
  ["SE", 95.49488177623991],
  ["SD", 94.27876297577855],
  ["SS", 68.71864186851211],
  ["PH", 92.1100057670127],
  ["RW", 90.33737024221453],
  ["KU", 71.08527970011535],
  ["HN", 95.3125],
  ["GA", 95.36620530565168],
  ["DZ", 57.398356401384085],
  ["DM", 30.304930795847753],
  ["FR", 98.50598327566321],
  ["GL", 94.83636101499422],
  ["ES", 91.7711937716263],
  ["HT", 92.21345155709344],
  ["PE", 95.98724048442907],
  ["QA", 93.20934256055364],
  ["SI", 90.675461361015],
  ["PR", 87.28698096885813],
  ["PS", 70.47217416378317],
  ["IQ", 95.44369953863898],
  ["HU", 68.11274509803921],
  ["KN", 92.08513552479816],
  ["KY", 86.50735294117648],
  ["FI", 96.05680507497117],
  ["ER", 78.88660611303345],
  ["GM", 94.74048442906575],
  ["EE", 97.87161188004615],
  ["EG", 96.35164359861592],
  ["ID", 96.95609861591696],
  ["IS", 87.05197520184544],
  ["SK", 86.23882641291812],
  ["RO", 98.0907583621684],
  ["PG", 91.61151960784314],
  ["KM", 89.37103517877739],
  ["IR", 87.43295847750865],
  ["KZ", 85.14777970011535],
  ["IE", 98.11130334486737],
  ["GN", 97.53712514417532],
  ["FJ", 87.63985005767013],
  ["GY", 90.43180507497117],
  ["JM", 91.92510092272202],
  ["KI", 85.12471164936562],
  ["HR", 91.54267589388697],
  ["SO", 92.54901960784314],
  ["PT", 86.20854959630911],
  ["SY", 96.09501153402537],
  ["SN", 67.4354815455594],
  ["KH", 88.79145040369089],
  ["EC", 87.63696655132641],
  ["ET", 57.812860438292965],
  ["DE", 96.71712802768167],
  ["SL", 95.94218569780854],
  ["PW", 96.81516724336794],
  ["SZ", 85.43613033448673],
  ["PA", 94.86952133794694],
  ["SM", 93.69917820069205],
  ["IT", 97.95703575547866],
  ["JO", 70.20833333333333],
  ["GH", 67.05666089965398],
  ["CG", 92.48666378316032],
  ["BT", 91.2236880046136],
  ["AO", 94.80284025374856],
  ["ML", 97.32626874279123],
  ["TN", 91.13862456747405],
  ["LI", 92.93180507497117],
  ["MM", 92.22895040369089],
  ["MZ", 68.6411476355248],
  ["NA", 84.21604671280278],
  ["CF", 93.53698096885813],
  ["BB", 98.04606401384083],
  ["CD", 83.71647923875433],
  ["BW", 97.74545847750865],
  ["AL", 87.64453575547866],
  ["NC", 87.91378316032295],
  ["MX", 65.27573529411764],
  ["TZ", 92.07684544405997],
  ["VE", 94.92791234140715],
  ["MN", 89.51773356401385],
  ["MY", 85.79909169550173],
  ["AM", 95.12651384083046],
  ["CR", 95.2328431372549],
  ["BA", 93.25043252595155],
  ["AZ", 90.02450980392157],
  ["CV", 91.24387254901961],
  ["BR", 93.00317185697808],
  ["CA", 94.47628316032295],
  ["BE", 99.25929930795849],
  ["TH", 98.13797577854672],
  ["WS", 91.89085928489042],
  ["UZ", 91.89698673587083],
  ["Wales", 75.56805074971165],
  ["NG", 35.99877450980392],
  ["NP", 76.34912053056516],
  ["MK", 79.55413783160323],
  ["BD", 0],
  ["BS", 93.60942906574394],
  ["CU", 90.89749134948096],
  ["BF", 84.92358708189158],
  ["NR", 94.68713956170703],
  ["NE", 94.68389561707036],
  ["ZW", 94.31264417531719],
  ["VC", 96.30767012687429],
  ["UY", 93.76766147635524],
  ["TJ", 96.20242214532871],
  ["VU", 92.43692329873126],
  ["ZA", 73.26268742791234],
  ["BG", 95.61274509803923],
]);

export const bsMap = new Map<string, number>([
  ["CY", 94.32915224913495],
  ["AF", 74.55320069204151],
  ["CN", 95.7742214532872],
  ["BJ", 96.7378892733564],
  ["LA", 94.78200692041523],
  ["ME", 82.60726643598616],
  ["OM", 96.56228373702422],
  ["NI", 89.92863321799308],
  ["MR", 91.50865051903114],
  ["TG", 95.51427335640138],
  ["TP", 80.28070934256056],
  ["North Korea", 93.8862456747405],
  ["VN", 94.82785467128028],
  ["ZM", 93.46799307958477],
  ["MD", 91.85769896193771],
  ["CO", 96.89359861591696],
  ["AG", 92.16176470588235],
  ["AE", 93.55882352941177],
  ["CZ", 93.5484429065744],
  ["AR", 90.7318339100346],
  ["BI", 90.23529411764706],
  ["CM", 95.66003460207612],
  ["LB", 93.25],
  ["LU", 67.34385813148789],
  ["TD", 95.68555363321799],
  ["TR", 95.15614186851211],
  ["Scotland", 92.10986159169549],
  ["UA", 97.7166955017301],
  ["LT", 97.90268166089965],
  ["LC", 92.35510380622839],
  ["MG", 98.89403114186851],
  ["CL", 95.76384083044984],
  ["BH", 97.0674740484429],
  ["AD", 89.46669550173011],
  ["CH", 94.44939446366783],
  ["NO", 90.9204152249135],
  ["MT", 98.04498269896193],
  ["MC", 96.24264705882352],
  ["TV", 86.41695501730104],
  ["TW", 95.32266435986159],
  ["US", 82.87673010380622],
  ["MU", 95.62024221453287],
  ["BZ", 81.90397923875432],
  ["CI", 96.55103806228374],
  ["BM", 90.07742214532873],
  ["CK", 92.64489619377163],
  ["AT", 92.80968858131487],
  ["BO", 95.88970588235294],
  ["LS", 95.77551903114187],
  ["NL", 92.63451557093425],
  ["MW", 91.17517301038063],
  ["YE", 91.3416955017301],
  ["UG", 88.58650519031141],
  ["TT", 94.31487889273356],
  ["NZ", 92.65916955017302],
  ["MA", 97.48572664359861],
  ["MV", 95.01686851211073],
  ["LR", 89.78806228373702],
  ["BY", 97.16911764705883],
  ["BN", 84.38365051903114],
  ["AU", 91.74826989619376],
  ["GE", 93.74048442906575],
  ["GR", 90.85207612456747],
  ["IN", 89.86375432525952],
  ["GBENG", 96.59558823529412],
  ["RS", 89.88365051903115],
  ["PL", 97.29108996539793],
  ["SV", 87.70631487889273],
  ["SA", 90.37110726643598],
  ["HK", 90.71712802768165],
  ["KG", 87.93209342560554],
  ["GD", 89.36029411764706],
  ["GQ", 90.3053633217993],
  ["DJ", 94.51686851211073],
  ["KE", 89.57309688581316],
  ["KR", 95.30493079584775],
  ["SC", 89.80406574394463],
  ["ST", 92.98918685121107],
  ["PY", 92.74437716262976],
  ["SB", 91.67128027681662],
  ["IL", 94.78935986159169],
  ["DK", 95.1038062283737],
  ["DO", 93.14792387543253],
  ["RU", 97.42603806228374],
  ["SG", 94.04757785467129],
  ["PK", 96.89446366782008],
  ["GB", 85.84558823529412],
  ["GU", 91.89403114186851],
  ["GW", 94.75648788927336],
  ["JP", 96.9416089965398],
  ["SR", 94.54584775086505],
  ["SE", 94.34731833910035],
  ["SD", 93.40311418685121],
  ["SS", 94.92517301038063],
  ["PH", 98.29714532871972],
  ["RW", 68.70717993079585],
  ["KU", 76.18555363321799],
  ["HN", 90.9182525951557],
  ["GA", 95.44333910034602],
  ["DZ", 90.59688581314879],
  ["DM", 89.6362456747405],
  ["FR", 95.55882352941177],
  ["GL", 95.2279411764706],
  ["ES", 93.64403114186851],
  ["HT", 94.7681660899654],
  ["PE", 96.3507785467128],
  ["QA", 98.4416089965398],
  ["SI", 97.42820069204153],
  ["PR", 91.0878027681661],
  ["PS", 95.73442906574394],
  ["IQ", 88.01557093425606],
  ["HU", 92.9666955017301],
  ["KN", 87.98313148788928],
  ["KY", 90.70198961937717],
  ["FI", 95.9909169550173],
  ["ER", 88.76600346020761],
  ["GM", 91.03762975778547],
  ["EE", 87.32698961937716],
  ["EG", 87.40354671280276],
  ["ID", 96.07179930795847],
  ["IS", 91.49005190311419],
  ["SK", 95.87673010380622],
  ["RO", 95.56963667820068],
  ["PG", 74.77032871972318],
  ["KM", 94.10596885813149],
  ["IR", 84.97058823529412],
  ["KZ", 40.298875432525946],
  ["IE", 96.30363321799308],
  ["GN", 96.19636678200692],
  ["FJ", 86.72750865051904],
  ["GY", 88.99870242214533],
  ["JM", 76.07871972318338],
  ["KI", 88.89489619377161],
  ["HR", 86.44506920415225],
  ["SO", 94.31098615916954],
  ["PT", 89.23572664359861],
  ["SY", 90.11072664359861],
  ["SN", 89.02897923875432],
  ["KH", 92],
  ["EC", 91.60510380622837],
  ["ET", 88.29714532871972],
  ["DE", 94.97015570934256],
  ["SL", 92.61634948096886],
  ["PW", 94.30795847750865],
  ["SZ", 86.08347750865052],
  ["PA", 93.22231833910034],
  ["SM", 90.67474048442907],
  ["IT", 96.45847750865052],
  ["JO", 94.39878892733564],
  ["GH", 93.92776816608996],
  ["CG", 86.82742214532871],
  ["BT", 86.05493079584775],
  ["AO", 85.07742214532871],
  ["ML", 96.21020761245674],
  ["TN", 92.0674740484429],
  ["LI", 95.82698961937717],
  ["MM", 95.68771626297577],
  ["MZ", 95.71410034602076],
  ["NA", 89.39662629757785],
  ["CF", 95.86851211072664],
  ["BB", 90.41955017301038],
  ["CD", 93.35207612456747],
  ["BW", 87.08520761245676],
  ["AL", 93.5462802768166],
  ["NC", 94.31790657439446],
  ["MX", 91.9485294117647],
  ["TZ", 70.0514705882353],
  ["VE", 95.87153979238754],
  ["MN", 96.25475778546712],
  ["MY", 85.29930795847751],
  ["AM", 96.17214532871972],
  ["CR", 97.60726643598616],
  ["BA", 93.62067474048443],
  ["AZ", 68.45415224913495],
  ["CV", 92.8174740484429],
  ["BR", 91.44593425605537],
  ["CA", 92.76859861591696],
  ["BE", 69.02681660899654],
  ["TH", 97.47318339100346],
  ["WS", 95.90138408304499],
  ["UZ", 66.88884083044982],
  ["Wales", 86.53979238754326],
  ["NG", 96.37629757785467],
  ["NP", 92.40008650519032],
  ["MK", 82.4325259515571],
  ["BD", 93.58347750865052],
  ["BS", 0],
  ["CU", 95.98399653979239],
  ["BF", 96.42820069204151],
  ["NR", 95.18728373702422],
  ["NE", 92.91392733564014],
  ["ZW", 93.98010380622837],
  ["VC", 93.53806228373702],
  ["UY", 92.86764705882354],
  ["TJ", 89.19031141868513],
  ["VU", 80.76167820069205],
  ["ZA", 82.07223183391004],
  ["BG", 98.50865051903114],
]);

export const cuMap = new Map<string, number>([
  ["CY", 74.09948096885813],
  ["AF", 91.72534602076125],
  ["CN", 77.11721453287197],
  ["BJ", 89.05233564013841],
  ["LA", 86.2651384083045],
  ["ME", 89.47664359861591],
  ["OM", 67.87543252595157],
  ["NI", 85.74653979238754],
  ["MR", 90.36202422145328],
  ["TG", 78.97837370242215],
  ["TP", 94.82352941176471],
  ["North Korea", 80.13667820069205],
  ["VN", 75.7772491349481],
  ["ZM", 90.03070934256056],
  ["MD", 80],
  ["CO", 82.8053633217993],
  ["AG", 69.94982698961938],
  ["AE", 72.3507785467128],
  ["CZ", 78.30882352941177],
  ["AR", 89.54152249134948],
  ["BI", 77.74351211072664],
  ["CM", 88.93901384083046],
  ["LB", 72.73745674740485],
  ["LU", 83.0666089965398],
  ["TD", 91.33953287197232],
  ["TR", 76.83910034602076],
  ["Scotland", 77.78070934256056],
  ["UA", 93.66479238754324],
  ["LT", 90.69031141868513],
  ["LC", 96.34688581314879],
  ["MG", 94.1924740484429],
  ["CL", 67.92517301038062],
  ["BH", 91.46842560553632],
  ["AD", 81.72880622837371],
  ["CH", 85.67733564013841],
  ["NO", 77.65268166089966],
  ["MT", 87.87067474048443],
  ["MC", 73.00778546712803],
  ["TV", 85.05579584775087],
  ["TW", 88.60337370242215],
  ["US", 72.5916955017301],
  ["MU", 90.48356401384083],
  ["BZ", 80.57352941176471],
  ["CI", 85.81574394463668],
  ["BM", 72.23745674740485],
  ["CK", 47.943771626297575],
  ["AT", 78.60164359861592],
  ["BO", 88.1682525951557],
  ["LS", 65.10294117647058],
  ["NL", 86.04801038062284],
  ["MW", 81.35856401384083],
  ["YE", 83.32309688581316],
  ["UG", 85.89273356401385],
  ["TT", 74.55017301038063],
  ["NZ", 47.66306228373702],
  ["MA", 74.49524221453288],
  ["MV", 81.62889273356402],
  ["LR", 75.47664359861592],
  ["BY", 83.57396193771626],
  ["BN", 84.03287197231833],
  ["AU", 84.49264705882354],
  ["GE", 70.19636678200692],
  ["GR", 82.32525951557093],
  ["IN", 87.3356401384083],
  ["GBENG", 67.43122837370242],
  ["RS", 74.04714532871972],
  ["PL", 72.9991349480969],
  ["SV", 51.18598615916955],
  ["SA", 86.469723183391],
  ["HK", 72.25259515570934],
  ["KG", 71.32785467128028],
  ["GD", 74.58564013840831],
  ["GQ", 83.67776816608996],
  ["DJ", 94.03373702422145],
  ["KE", 84.23659169550173],
  ["KR", 74.21626297577855],
  ["SC", 75.04065743944636],
  ["ST", 80.3044982698962],
  ["PY", 63.613321799307954],
  ["SB", 85.86159169550173],
  ["IL", 72.68425605536332],
  ["DK", 77.20717993079585],
  ["DO", 82.04974048442907],
  ["RU", 69.4083044982699],
  ["SG", 73.32958477508652],
  ["PK", 90.51427335640139],
  ["GB", 52.951557093425606],
  ["GU", 55.66392733564014],
  ["GW", 74.58088235294117],
  ["JP", 77.35034602076124],
  ["SR", 68.27984429065744],
  ["SE", 93.09472318339101],
  ["SD", 84.47837370242215],
  ["SS", 84.64316608996539],
  ["PH", 73.81358131487889],
  ["RW", 94.02032871972318],
  ["KU", 87.65960207612457],
  ["HN", 85.27465397923876],
  ["GA", 92.62153979238754],
  ["DZ", 73.03070934256056],
  ["DM", 86.98226643598616],
  ["FR", 72.72188581314879],
  ["GL", 72.24913494809688],
  ["ES", 85.11980968858131],
  ["HT", 62.108131487889274],
  ["PE", 65.74264705882354],
  ["QA", 92.90700692041523],
  ["SI", 80.43901384083046],
  ["PR", 59.90657439446366],
  ["PS", 72.53892733564014],
  ["IQ", 81.4182525951557],
  ["HU", 81.99437716262976],
  ["KN", 84.60510380622837],
  ["KY", 47.32179930795848],
  ["FI", 59.564013840830455],
  ["ER", 77.14489619377163],
  ["GM", 71.08780276816609],
  ["EE", 87.7530276816609],
  ["EG", 83.57223183391004],
  ["ID", 73.00778546712803],
  ["IS", 57.560986159169545],
  ["SK", 77.89965397923876],
  ["RO", 83.06271626297578],
  ["PG", 90.909169550173],
  ["KM", 79.14749134948097],
  ["IR", 80.64230103806229],
  ["KZ", 92.17171280276817],
  ["IE", 81.65657439446366],
  ["GN", 75.9878892733564],
  ["FJ", 87.60164359861592],
  ["GY", 69.76643598615917],
  ["JM", 89.55622837370242],
  ["KI", 64.31877162629758],
  ["HR", 66.87153979238755],
  ["SO", 94.62932525951557],
  ["PT", 89.27595155709342],
  ["SY", 81.99740484429066],
  ["SN", 90.30060553633218],
  ["KH", 42.45977508650519],
  ["EC", 85.33910034602076],
  ["ET", 82.66782006920415],
  ["DE", 94.4969723183391],
  ["SL", 86.5696366782007],
  ["PW", 96.46107266435986],
  ["SZ", 82.52292387543253],
  ["PA", 81.97058823529412],
  ["SM", 83.29714532871972],
  ["IT", 81.18166089965399],
  ["JO", 67.87889273356402],
  ["GH", 86.3637543252595],
  ["CG", 89.03676470588235],
  ["BT", 91.85250865051903],
  ["AO", 81.48356401384082],
  ["ML", 92.6673875432526],
  ["TN", 71.87629757785467],
  ["LI", 61.25821799307959],
  ["MM", 83.20458477508652],
  ["MZ", 74.58217993079585],
  ["NA", 71.03762975778547],
  ["CF", 73.25562283737024],
  ["BB", 61.106833910034595],
  ["CD", 87.10553633217994],
  ["BW", 95.41565743944636],
  ["AL", 73.6643598615917],
  ["NC", 73.4870242214533],
  ["MX", 81.60726643598616],
  ["TZ", 89.97404844290658],
  ["VE", 76.12240484429066],
  ["MN", 80.7871972318339],
  ["MY", 65.65787197231833],
  ["AM", 79.47923875432525],
  ["CR", 37.91392733564014],
  ["BA", 59.36764705882352],
  ["AZ", 84.66046712802768],
  ["CV", 44.78806228373703],
  ["BR", 83.3044982698962],
  ["CA", 79.28589965397924],
  ["BE", 94.86678200692042],
  ["TH", 72.88192041522491],
  ["WS", 76.03416955017302],
  ["UZ", 86.94636678200692],
  ["Wales", 78.71410034602077],
  ["NG", 81.18987889273357],
  ["NP", 58.39359861591695],
  ["MK", 80.57785467128028],
  ["BD", 91.02465397923876],
  ["BS", 95.98399653979239],
  ["CU", 0],
  ["BF", 83.82223183391004],
  ["NR", 50.50951557093426],
  ["NE", 88.24048442906575],
  ["ZW", 86.78070934256056],
  ["VC", 88.21150519031143],
  ["UY", 60.87932525951557],
  ["TJ", 79.19766435986159],
  ["VU", 91.58131487889274],
  ["ZA", 69.09126297577855],
  ["BG", 80.72188581314879],
]);

export const bfMap = new Map<string, number>([
  ["CY", 93.78619659784444],
  ["AF", 89.03551486819894],
  ["CN", 52.707440592130894],
  ["BJ", 76.93286586157642],
  ["LA", 97.92169848071678],
  ["ME", 85.81450460979093],
  ["OM", 75.31814050123361],
  ["NI", 97.62043890403844],
  ["MR", 94.48935203220361],
  ["TG", 80.46292689261135],
  ["TP", 60.3450850538891],
  ["North Korea", 70.53467082197116],
  ["VN", 50.92065965459032],
  ["ZM", 91.5553174912349],
  ["MD", 94.06732891832229],
  ["CO", 97.11693286586157],
  ["AG", 93.05187637969095],
  ["AE", 97.96877028957279],
  ["CZ", 94.73120373977405],
  ["AR", 97.84995455135697],
  ["BI", 74.37540579145565],
  ["CM", 95.17367874302039],
  ["LB", 69.73022984028047],
  ["LU", 65.53467082197118],
  ["TD", 97.01337488637839],
  ["TR", 94.27509414361771],
  ["Scotland", 90.36521231008959],
  ["UA", 97.62693156732892],
  ["LT", 96.85527853525517],
  ["LC", 99.2884041033632],
  ["MG", 65.6252434748734],
  ["CL", 97.02278924814959],
  ["BH", 94.43416439423451],
  ["AD", 78.69075444747435],
  ["CH", 95.88235294117648],
  ["NO", 66.41247889884431],
  ["MT", 73.80275288923517],
  ["MC", 97.74639657187379],
  ["TV", 80.69439033891702],
  ["TW", 96.46993896896507],
  ["US", 85.99272821711466],
  ["MU", 47.70744059213089],
  ["BZ", 80.22951564731852],
  ["CI", 81.61634852616542],
  ["BM", 67.26626412154265],
  ["CK", 89.22347747045838],
  ["AT", 65.75185040903779],
  ["BO", 64.1293338527464],
  ["LS", 69.19653291780288],
  ["NL", 98.61543955330477],
  ["MW", 92.19906505648618],
  ["YE", 97.96909492273731],
  ["UG", 94.24457862615245],
  ["TT", 94.15887547071809],
  ["NZ", 89.6604337099078],
  ["MA", 97.61751720555772],
  ["MV", 61.16121282950267],
  ["LR", 96.52967147123749],
  ["BY", 90.37624983768342],
  ["BN", 93.54759122191923],
  ["AU", 94.2952213998182],
  ["GE", 90.92423061940008],
  ["GR", 96.77412024412413],
  ["IN", 98.37358784573432],
  ["GBENG", 95.46195299311778],
  ["RS", 88.665757693806],
  ["PL", 98.51934813660563],
  ["SV", 98.12621737436696],
  ["SA", 90.3882612647708],
  ["HK", 52.06271912738606],
  ["KG", 48.56090118166472],
  ["GD", 86.61959485781068],
  ["GQ", 94.6967926243345],
  ["DJ", 61.48032723022984],
  ["KE", 92.60583041163486],
  ["KR", 94.9243604726659],
  ["SC", 69.97727567848331],
  ["ST", 65.76223867030255],
  ["PY", 97.18770289572782],
  ["SB", 86.73678743020386],
  ["IL", 95.4298143098299],
  ["DK", 97.95546032982729],
  ["DO", 97.67724970783014],
  ["RU", 96.6699129983119],
  ["SG", 53.40670042851578],
  ["PK", 95.47948318400208],
  ["GB", 77.6577717179587],
  ["GU", 90.62459420854434],
  ["GW", 63.946565381119335],
  ["JP", 96.06934164394234],
  ["SR", 94.8182054278665],
  ["SE", 96.43552785352551],
  ["SD", 67.03350214257888],
  ["SS", 59.578950785612264],
  ["PH", 95.38761199844176],
  ["RW", 92.70516815997922],
  ["KU", 97.3357356187508],
  ["HN", 97.84605895338268],
  ["GA", 96.75074665627841],
  ["DZ", 92.72042591871185],
  ["DM", 90.6440721984158],
  ["FR", 81.77217244513699],
  ["GL", 87.74801973769641],
  ["ES", 92.00590832359434],
  ["HT", 97.03967017270485],
  ["PE", 65.62556810803791],
  ["QA", 94.8652772367225],
  ["SI", 94.52376314764315],
  ["PR", 88.92156862745098],
  ["PS", 95.27009479288404],
  ["IQ", 96.29139072847683],
  ["HU", 66.15991429684456],
  ["KN", 91.99389689650694],
  ["KY", 85.86677054927931],
  ["FI", 97.37566549798727],
  ["ER", 79.59063757953513],
  ["GM", 98.63881314115051],
  ["EE", 99.7412673678743],
  ["EG", 96.24237112063369],
  ["ID", 97.91293338527464],
  ["IS", 89.47863913777432],
  ["SK", 90.19023503441112],
  ["RO", 96.0566809505259],
  ["PG", 92.66783534605895],
  ["KM", 90.11654330606414],
  ["IR", 89.09946760161019],
  ["KZ", 86.2761329697442],
  ["IE", 81.76990001298533],
  ["GN", 80.13699519542917],
  ["FJ", 89.3140501233606],
  ["GY", 67.80353200883002],
  ["JM", 78.2398389819504],
  ["KI", 85.43176210881704],
  ["HR", 92.89864952603558],
  ["SO", 96.37514608492404],
  ["PT", 90.16945851188157],
  ["SY", 96.79002726918581],
  ["SN", 65.85410985586287],
  ["KH", 92.19387092585379],
  ["EC", 91.65400597325024],
  ["ET", 88.19763667056226],
  ["DE", 96.44494221529672],
  ["SL", 99.05272042591872],
  ["PW", 94.50818075574601],
  ["SZ", 86.5942734709778],
  ["PA", 72.7749642903519],
  ["SM", 92.6915335670692],
  ["IT", 81.33326840670043],
  ["JO", 93.47941825736918],
  ["GH", 98.1145305804441],
  ["CG", 79.74678613167121],
  ["BT", 85.36001817945721],
  ["AO", 96.03265809635111],
  ["ML", 79.77015971951694],
  ["TN", 94.2400337618491],
  ["LI", 95.781716660174],
  ["MM", 94.97208154785093],
  ["MZ", 87.27567848331385],
  ["NA", 51.00441501103753],
  ["CF", 89.46208284638358],
  ["BB", 98.35281132320478],
  ["CD", 84.99837683417738],
  ["BW", 99.46662771068692],
  ["AL", 53.957278275548624],
  ["NC", 55.398000259706535],
  ["MX", 95.31749123490457],
  ["TZ", 91.70302558109337],
  ["VE", 98.49142968445656],
  ["MN", 92.12050383067134],
  ["MY", 75.37917153616414],
  ["AM", 96.64783794312427],
  ["CR", 93.30963511232308],
  ["BA", 94.87501623165822],
  ["AZ", 62.266588754707186],
  ["CV", 93.74886378392416],
  ["BR", 61.41020646669264],
  ["CA", 93.02785352551616],
  ["BE", 81.72055577197767],
  ["TH", 82.54155304505908],
  ["WS", 94.62439942864563],
  ["UZ", 63.877418517075704],
  ["Wales", 46.41053109985716],
  ["NG", 65.19023503441112],
  ["NP", 73.7777561355668],
  ["MK", 80.32236073237242],
  ["BD", 84.88215816127776],
  ["BS", 96.19497467861316],
  ["CU", 84.05434359174133],
  ["BF", 0],
  ["NR", 95.21523178807946],
  ["NE", 65.2197766523828],
  ["ZW", 85.89046877028957],
  ["VC", 81.18815738215817],
  ["UY", 95.38501493312556],
  ["TJ", 97.22665887547072],
  ["VU", 36.3962472406181],
  ["ZA", 72.4623425529152],
  ["BG", 97.01272562004934],
]);

export const nrMap = new Map<string, number>([
  ["CY", 94.60337370242215],
  ["AF", 88.21885813148789],
  ["CN", 93.92863321799308],
  ["BJ", 94.34429065743944],
  ["LA", 65.48442906574394],
  ["ME", 87.02378892733564],
  ["OM", 91.36634948096885],
  ["NI", 95.22145328719724],
  ["MR", 93.18641868512111],
  ["TG", 93.52854671280278],
  ["TP", 91.6461937716263],
  ["North Korea", 94.46885813148789],
  ["VN", 94.2984429065744],
  ["ZM", 92.13019031141869],
  ["MD", 92.16219723183391],
  ["CO", 76.1159169550173],
  ["AG", 87.93209342560554],
  ["AE", 96.18295847750865],
  ["CZ", 77.92560553633217],
  ["AR", 97.25129757785467],
  ["BI", 92.01859861591696],
  ["CM", 92.24610726643598],
  ["LB", 95.35510380622837],
  ["LU", 98.23745674740483],
  ["TD", 69.65700692041523],
  ["TR", 93.44939446366783],
  ["Scotland", 89.74826989619376],
  ["UA", 94.80233564013841],
  ["LT", 96.11505190311419],
  ["LC", 97.44031141868513],
  ["MG", 97.03027681660899],
  ["CL", 94.90311418685121],
  ["BH", 93.9416089965398],
  ["AD", 93.59256055363322],
  ["CH", 94.80925605536332],
  ["NO", 84.13884083044982],
  ["MT", 96.61375432525952],
  ["MC", 93.97404844290658],
  ["TV", 88.89446366782006],
  ["TW", 92.73572664359861],
  ["US", 87.62240484429066],
  ["MU", 74.45847750865052],
  ["BZ", 87.35813148788927],
  ["CI", 98.85121107266436],
  ["BM", 82.83044982698962],
  ["CK", 29.195934256055363],
  ["AT", 97.56790657439446],
  ["BO", 90.49740484429066],
  ["LS", 67.09126297577855],
  ["NL", 96.23053633217992],
  ["MW", 87.77378892733564],
  ["YE", 96.92776816608996],
  ["UG", 88.67906574394465],
  ["TT", 91.50432525951557],
  ["NZ", 25.298010380622838],
  ["MA", 94.97837370242215],
  ["MV", 94.38494809688581],
  ["LR", 81.39230103806229],
  ["BY", 92.98183391003461],
  ["BN", 87.25043252595157],
  ["AU", 85.32525951557093],
  ["GE", 90.87586505190312],
  ["GR", 96.69636678200692],
  ["IN", 95.57309688581314],
  ["GBENG", 96.11159169550173],
  ["RS", 73.43209342560554],
  ["PL", 95.74307958477507],
  ["SV", 95.89878892733564],
  ["SA", 90.20891003460207],
  ["HK", 92.37543252595157],
  ["KG", 88.23529411764706],
  ["GD", 86.74134948096885],
  ["GQ", 93.81487889273356],
  ["DJ", 95.69333910034602],
  ["KE", 89.09385813148789],
  ["KR", 89.17993079584775],
  ["SC", 76.65484429065744],
  ["ST", 92.06920415224914],
  ["PY", 95.45631487889274],
  ["SB", 87.42430795847751],
  ["IL", 92.91652249134948],
  ["DK", 97.28243944636678],
  ["DO", 64.01427335640139],
  ["RU", 93.92214532871972],
  ["SG", 89.69204152249135],
  ["PK", 93.93295847750865],
  ["GB", 61.516435986159166],
  ["GU", 23.93728373702422],
  ["GW", 92.60683391003461],
  ["JP", 94.94290657439446],
  ["SR", 93.73356401384083],
  ["SE", 90.15484429065744],
  ["SD", 93.57266435986159],
  ["SS", 90.5575259515571],
  ["PH", 92.18512110726643],
  ["RW", 93.91262975778547],
  ["KU", 93.93252595155708],
  ["HN", 95.50951557093425],
  ["GA", 91.14446366782006],
  ["DZ", 92.36461937716263],
  ["DM", 88.99134948096886],
  ["FR", 71.25994809688582],
  ["GL", 92.8538062283737],
  ["ES", 89.14100346020761],
  ["HT", 53.09731833910034],
  ["PE", 96.96410034602077],
  ["QA", 94.21929065743944],
  ["SI", 94.41998269896193],
  ["PR", 88.0272491349481],
  ["PS", 93.24524221453288],
  ["IQ", 94.57396193771626],
  ["HU", 96.8196366782007],
  ["KN", 87.93166089965398],
  ["KY", 29.152681660899653],
  ["FI", 68.44982698961938],
  ["ER", 87.20198961937716],
  ["GM", 84.59515570934256],
  ["EE", 95.0583910034602],
  ["EG", 95.58953287197231],
  ["ID", 93.6946366782007],
  ["IS", 34.094723183391004],
  ["SK", 93.02292387543253],
  ["RO", 68.61851211072664],
  ["PG", 88.8114186851211],
  ["KM", 95.87024221453288],
  ["IR", 92.72318339100346],
  ["KZ", 97.23832179930795],
  ["IE", 97.04498269896193],
  ["GN", 94.2370242214533],
  ["FJ", 85.80103806228374],
  ["GY", 84.60337370242215],
  ["JM", 88.09126297577855],
  ["KI", 69.2227508650519],
  ["HR", 62.628892733564015],
  ["SO", 98.78460207612457],
  ["PT", 90.04195501730104],
  ["SY", 95.70242214532873],
  ["SN", 95.47318339100346],
  ["KH", 89.14186851211072],
  ["EC", 88.469723183391],
  ["ET", 82.81704152249135],
  ["DE", 94.25865051903114],
  ["SL", 97.12240484429066],
  ["PW", 96.2932525951557],
  ["SZ", 84.3326124567474],
  ["PA", 93.20285467128028],
  ["SM", 93.03243944636678],
  ["IT", 96.780276816609],
  ["JO", 92.28200692041523],
  ["GH", 89.56617647058823],
  ["CG", 93.37110726643598],
  ["BT", 93.48356401384082],
  ["AO", 92.3871107266436],
  ["ML", 96.4567474048443],
  ["TN", 92.6258650519031],
  ["LI", 53.989186851211066],
  ["MM", 96.37673010380622],
  ["MZ", 90.38581314878893],
  ["NA", 65.36159169550173],
  ["CF", 76.98745674740485],
  ["BB", 36.31833910034602],
  ["CD", 95.19506920415225],
  ["BW", 98.76643598615917],
  ["AL", 83.83001730103807],
  ["NC", 91.5242214532872],
  ["MX", 94.13278546712803],
  ["TZ", 92.2409169550173],
  ["VE", 74.56920415224914],
  ["MN", 92.47923875432525],
  ["MY", 83.9182525951557],
  ["AM", 79.80839100346022],
  ["CR", 62.141003460207614],
  ["BA", 35.609429065743946],
  ["AZ", 94.6340830449827],
  ["CV", 26.09342560553633],
  ["BR", 81.29282006920415],
  ["CA", 92.52595155709342],
  ["BE", 96.09991349480968],
  ["TH", 96.74567474048443],
  ["WS", 75.25173010380622],
  ["UZ", 97.33477508650519],
  ["Wales", 78.17993079584775],
  ["NG", 96.95069204152249],
  ["NP", 79.74783737024221],
  ["MK", 76.31920415224913],
  ["BD", 94.5635813148789],
  ["BS", 95.18728373702422],
  ["CU", 50.50951557093426],
  ["BF", 95.53070934256056],
  ["NR", 0],
  ["NE", 98.34731833910034],
  ["ZW", 90.14662629757785],
  ["VC", 93.98010380622837],
  ["UY", 92.47102076124567],
  ["TJ", 94.72837370242215],
  ["VU", 81.64749134948097],
  ["ZA", 71.72318339100346],
  ["BG", 96.53849480968859],
]);

export const neMap = new Map<string, number>([
  ["CY", 71.89007163757441],
  ["AF", 91.6862576934719],
  ["CN", 95.74437493693875],
  ["BJ", 96.5545858137423],
  ["LA", 97.32317626879225],
  ["ME", 87.00963575824842],
  ["OM", 95.32842296438301],
  ["NI", 70.78725658359399],
  ["MR", 95.21390374331551],
  ["TG", 93.23478962768641],
  ["TP", 92.99288669155484],
  ["North Korea", 94.19937443244879],
  ["VN", 96.8017858944607],
  ["ZM", 94.40772878619715],
  ["MD", 93.88936535162951],
  ["CO", 96.02436686509938],
  ["AG", 93.69185753203512],
  ["AE", 79.91978609625669],
  ["CZ", 88.49788114216527],
  ["AR", 70.08147512864494],
  ["BI", 80.10896983150036],
  ["CM", 95.81929169609525],
  ["LB", 69.30960548885076],
  ["LU", 71.79447079003127],
  ["TD", 96.89738674200383],
  ["TR", 93.88381596206236],
  ["Scotland", 86.44460700232065],
  ["UA", 97.43239834527293],
  ["LT", 98.70144284128746],
  ["LC", 97.29618605589748],
  ["MG", 87.34537382706084],
  ["CL", 90.60816264756332],
  ["BH", 86.12854404197357],
  ["AD", 94.24553526384824],
  ["CH", 89.80375340530723],
  ["NO", 88.50242155181111],
  ["MT", 84.33155080213903],
  ["MC", 85.54737160730501],
  ["TV", 86.96145696700636],
  ["TW", 97.21672888709514],
  ["US", 81.87367571385329],
  ["MU", 97.05554434466754],
  ["BZ", 83.90979719503582],
  ["CI", 93.87170820300676],
  ["BM", 89.57143577842801],
  ["CK", 94.11462011905964],
  ["AT", 71.9659973766522],
  ["BO", 94.48415901523559],
  ["LS", 43.12809000100898],
  ["NL", 71.79447079003127],
  ["MW", 95.51029159519726],
  ["YE", 72.13752396327313],
  ["UG", 97.48057713651498],
  ["TT", 94.49374432448793],
  ["NZ", 94.48642922005853],
  ["MA", 95.74210473211583],
  ["MV", 96.66708707496721],
  ["LR", 86.94607002320653],
  ["BY", 92.32695994349712],
  ["BN", 81.96902431641611],
  ["AU", 93.40278478458278],
  ["GE", 83.43456765210372],
  ["GR", 82.3418423973363],
  ["IN", 71.08969831500353],
  ["GBENG", 87.16804560589244],
  ["RS", 89.50358187872062],
  ["PL", 85.52517404903642],
  ["SV", 70.75320351125013],
  ["SA", 91.52835233578853],
  ["HK", 93.42296438300878],
  ["KG", 90.01336898395722],
  ["GD", 86.73443648471395],
  ["GQ", 75.4295732014933],
  ["DJ", 58.98395721925134],
  ["KE", 92.25002522449803],
  ["KR", 75.38669155483805],
  ["SC", 82.73029966703662],
  ["ST", 65.51130057511855],
  ["PY", 69.87816567450308],
  ["SB", 88.55564524265968],
  ["IL", 70.23584905660377],
  ["DK", 86.18908283725155],
  ["DO", 81.15326405004541],
  ["RU", 99.51669861769751],
  ["SG", 81.40475229542932],
  ["PK", 90.2381192614267],
  ["GB", 77.87861971546766],
  ["GU", 93.27186963979418],
  ["GW", 74.87211179497528],
  ["JP", 76.73216627989103],
  ["SR", 97.64125718898194],
  ["SE", 97.41802038139441],
  ["SD", 78.78922409444053],
  ["SS", 92.13247906366662],
  ["PH", 86.95187165775401],
  ["RW", 92.89274543436585],
  ["KU", 78.67293915851074],
  ["HN", 72.48864897588538],
  ["GA", 94.38174755322369],
  ["DZ", 82.01468065785491],
  ["DM", 86.81919079810312],
  ["FR", 93.99379477348401],
  ["GL", 83.51932196549289],
  ["ES", 93.11825244677631],
  ["HT", 97.25986277873069],
  ["PE", 94.03364948037533],
  ["QA", 86.14645343557663],
  ["SI", 96.5656845928766],
  ["PR", 84.0089294723035],
  ["PS", 78.56245585712844],
  ["IQ", 72.91494299263445],
  ["HU", 71.79447079003127],
  ["KN", 92.22379174654425],
  ["KY", 92.4725052971446],
  ["FI", 95.04969226112401],
  ["ER", 85.28200988800323],
  ["GM", 89.7346382806982],
  ["EE", 100],
  ["EG", 70.86847946725861],
  ["ID", 85.54737160730501],
  ["IS", 89.71849460195742],
  ["SK", 94.74069216022602],
  ["RO", 96.94960145293109],
  ["PG", 92.7260115023711],
  ["KM", 84.16254666532136],
  ["IR", 61.27055796589648],
  ["KZ", 89.6524064171123],
  ["IE", 83.13490061547775],
  ["GN", 96.65649278579356],
  ["FJ", 88.13313490061547],
  ["GY", 67.8203511250126],
  ["JM", 77.60417717687417],
  ["KI", 85.30622540611442],
  ["HR", 66.78589446070023],
  ["SO", 97.3945615982242],
  ["PT", 91.94203410352134],
  ["SY", 73.93905761275352],
  ["SN", 95.49616587629906],
  ["KH", 93.65250731510443],
  ["EC", 89.03768540006054],
  ["ET", 87.00282514377963],
  ["DE", 98.87952779739683],
  ["SL", 71.9659973766522],
  ["PW", 95.07441226919585],
  ["SZ", 91.34143880536777],
  ["PA", 83.73448693371002],
  ["SM", 83.27464433457774],
  ["IT", 83.24740187670265],
  ["JO", 80.38744828977903],
  ["GH", 94.45388961759662],
  ["CG", 90.58520835435375],
  ["BT", 90.77565331449904],
  ["AO", 96.54954091413582],
  ["ML", 85.69897084048027],
  ["TN", 93.13162143073353],
  ["LI", 97.96362627383715],
  ["MM", 95.45303198466351],
  ["MZ", 93.48955705781455],
  ["NA", 70.62102714155988],
  ["CF", 78.13666633034002],
  ["BB", 97.30526687518919],
  ["CD", 92.80067601654727],
  ["BW", 92.45636161840379],
  ["AL", 95.43184340631622],
  ["NC", 65.81601251135102],
  ["MX", 91.60301684996467],
  ["TZ", 93.29305821814145],
  ["VE", 98.87271718292806],
  ["MN", 94.1802038139441],
  ["MY", 85.80894965190193],
  ["AM", 99.64029865805671],
  ["CR", 97.26717788316013],
  ["BA", 96.8991524568661],
  ["AZ", 97.04923822015942],
  ["CV", 91.41282413479972],
  ["BR", 66.36388860861669],
  ["CA", 89.76314196347492],
  ["BE", 96.83180304711937],
  ["TH", 100],
  ["WS", 98.27313086469579],
  ["UZ", 37.72626374735143],
  ["Wales", 53.69110079709414],
  ["NG", 94.03238825547372],
  ["NP", 79.9066693572798],
  ["MK", 84.51997780244173],
  ["BD", 94.66728887095147],
  ["BS", 93.3906770255272],
  ["CU", 87.978256482696],
  ["BF", 64.94980324891534],
  ["NR", 98.71884774492987],
  ["NE", 0],
  ["ZW", 90.03455756230451],
  ["VC", 94.07804459691252],
  ["UY", 86.81010997881143],
  ["TJ", 71.4471294521239],
  ["VU", 68.22949248309959],
  ["ZA", 88.70245182120875],
  ["BG", 98.6542730299667],
]);

export const zwMap = new Map<string, number>([
  ["CY", 80.81617647058823],
  ["AF", 88.92084775086505],
  ["CN", 73.67993079584775],
  ["BJ", 79.06358131487889],
  ["LA", 91.74437716262975],
  ["ME", 72.91349480968859],
  ["OM", 79.7750865051903],
  ["NI", 89.96842560553632],
  ["MR", 87.68252595155708],
  ["TG", 80.03676470588236],
  ["TP", 77.70847750865052],
  ["North Korea", 72.92214532871972],
  ["VN", 78.35553633217994],
  ["ZM", 69.3317474048443],
  ["MD", 79.41652249134948],
  ["CO", 80.93814878892734],
  ["AG", 84.36245674740485],
  ["AE", 95.13840830449827],
  ["CZ", 84.28114186851211],
  ["AR", 89.71237024221453],
  ["BI", 86.95285467128028],
  ["CM", 77.2257785467128],
  ["LB", 81.93598615916954],
  ["LU", 89.78070934256056],
  ["TD", 84.02032871972318],
  ["TR", 75.98832179930795],
  ["Scotland", 88.21150519031143],
  ["UA", 82.32439446366782],
  ["LT", 79.62716262975779],
  ["LC", 88.92647058823529],
  ["MG", 83.86721453287197],
  ["CL", 84.14489619377163],
  ["BH", 64.60726643598616],
  ["AD", 82.91089965397924],
  ["CH", 91.44333910034602],
  ["NO", 81.09731833910034],
  ["MT", 71.65787197231835],
  ["MC", 80.12370242214533],
  ["TV", 82.43339100346022],
  ["TW", 93.1401384083045],
  ["US", 82.10423875432527],
  ["MU", 87.74956747404843],
  ["BZ", 80.58434256055364],
  ["CI", 91.70977508650519],
  ["BM", 77.74610726643598],
  ["CK", 84.6461937716263],
  ["AT", 89.71539792387543],
  ["BO", 88.42820069204153],
  ["LS", 87.70891003460207],
  ["NL", 89.81574394463668],
  ["MW", 79.90051903114187],
  ["YE", 88.70804498269896],
  ["UG", 66.2612456747405],
  ["TT", 79.02811418685121],
  ["NZ", 86.16089965397924],
  ["MA", 75.87240484429066],
  ["MV", 93.63192041522491],
  ["LR", 83.32352941176471],
  ["BY", 89.43295847750865],
  ["BN", 72.05276816608996],
  ["AU", 88.09212802768165],
  ["GE", 80.8598615916955],
  ["GR", 85.39965397923875],
  ["IN", 87.85942906574394],
  ["GBENG", 81.24005190311419],
  ["RS", 82.77335640138408],
  ["PL", 90.55017301038062],
  ["SV", 89.1825259515571],
  ["SA", 89.91479238754326],
  ["HK", 79.74956747404845],
  ["KG", 80.18771626297578],
  ["GD", 67.4234429065744],
  ["GQ", 77.50173010380624],
  ["DJ", 81.61937716262976],
  ["KE", 84.94377162629758],
  ["KR", 79.93814878892734],
  ["SC", 78.43685121107266],
  ["ST", 91.75778546712803],
  ["PY", 85.89532871972318],
  ["SB", 87.45588235294117],
  ["IL", 82.88062283737024],
  ["DK", 89.36548442906575],
  ["DO", 84.81877162629758],
  ["RU", 85.54887543252595],
  ["SG", 87.78243944636678],
  ["PK", 78.62456747404845],
  ["GB", 77.20631487889274],
  ["GU", 87.405276816609],
  ["GW", 84.34602076124568],
  ["JP", 80.75908304498269],
  ["SR", 84.24221453287197],
  ["SE", 87.8931660899654],
  ["SD", 95.82266435986159],
  ["SS", 82.55493079584775],
  ["PH", 70.74697231833909],
  ["RW", 87.12586505190312],
  ["KU", 92.77811418685121],
  ["HN", 89.04195501730104],
  ["GA", 90.89489619377163],
  ["DZ", 90.55233564013841],
  ["DM", 81.3166089965398],
  ["FR", 93.8992214532872],
  ["GL", 86.79455017301038],
  ["ES", 84.62889273356402],
  ["HT", 90.2893598615917],
  ["PE", 84.3862456747405],
  ["QA", 78.0242214532872],
  ["SI", 87.60510380622837],
  ["PR", 87.65700692041523],
  ["PS", 95.60769896193771],
  ["IQ", 88.31617647058823],
  ["HU", 86.89273356401384],
  ["KN", 78.08391003460208],
  ["KY", 84.16695501730104],
  ["FI", 84.7279411764706],
  ["ER", 85.8758650519031],
  ["GM", 85.6159169550173],
  ["EE", 84.09515570934256],
  ["EG", 88.56228373702422],
  ["ID", 80.12197231833909],
  ["IS", 89.0583910034602],
  ["SK", 85.50951557093425],
  ["RO", 76.25432525951558],
  ["PG", 82.09385813148788],
  ["KM", 74.18209342560553],
  ["IR", 85.89532871972318],
  ["KZ", 88.27638408304497],
  ["IE", 94.34126297577855],
  ["GN", 84.27638408304499],
  ["FJ", 86.42257785467127],
  ["GY", 85.2772491349481],
  ["JM", 78.67776816608996],
  ["KI", 77.45415224913495],
  ["HR", 86.59558823529412],
  ["SO", 92.70544982698962],
  ["PT", 87.93641868512111],
  ["SY", 89.19679930795847],
  ["SN", 85.60250865051903],
  ["KH", 90.22967128027682],
  ["EC", 77.33910034602076],
  ["ET", 84.76384083044984],
  ["DE", 81.45761245674741],
  ["SL", 89.45458477508652],
  ["PW", 86.62413494809688],
  ["SZ", 74.02551903114187],
  ["PA", 87.77897923875433],
  ["SM", 84.74264705882352],
  ["IT", 94.44377162629758],
  ["JO", 92.63062283737025],
  ["GH", 86.82828719723183],
  ["CG", 84.62543252595157],
  ["BT", 79.40311418685121],
  ["AO", 82.67430795847751],
  ["ML", 76.69550173010381],
  ["TN", 90.99221453287197],
  ["LI", 81.3477508650519],
  ["MM", 80.30795847750865],
  ["MZ", 74.53157439446368],
  ["NA", 85.1924740484429],
  ["CF", 81.21496539792388],
  ["BB", 91.34256055363322],
  ["CD", 82.74697231833909],
  ["BW", 84.91046712802768],
  ["AL", 77.36634948096885],
  ["NC", 88.99394463667821],
  ["MX", 83.81660899653978],
  ["TZ", 83.7681660899654],
  ["VE", 74.24394463667821],
  ["MN", 88.1189446366782],
  ["MY", 74.9991349480969],
  ["AM", 89.85899653979239],
  ["CR", 73.70285467128028],
  ["BA", 80.87716262975778],
  ["AZ", 91.6583044982699],
  ["CV", 78.92733564013841],
  ["BR", 82.18728373702422],
  ["CA", 89.88927335640139],
  ["BE", 96.76903114186851],
  ["TH", 83.53157439446368],
  ["WS", 76.50519031141869],
  ["UZ", 84.77681660899654],
  ["Wales", 78.7409169550173],
  ["NG", 94.30017301038063],
  ["NP", 85.7932525951557],
  ["MK", 75.23313148788927],
  ["BD", 93.64792387543253],
  ["BS", 93.98010380622837],
  ["CU", 86.78070934256056],
  ["BF", 85.49005190311418],
  ["NR", 90.14662629757785],
  ["NE", 88.70804498269896],
  ["ZW", 0],
  ["VC", 80.73659169550173],
  ["UY", 80.1461937716263],
  ["TJ", 89.35726643598616],
  ["VU", 85.01643598615917],
  ["ZA", 91.62110726643598],
  ["BG", 88.7720588235294],
]);

export const vcMap = new Map<string, number>([
  ["CY", 90.41715361641344],
  ["AF", 93.25769380599922],
  ["CN", 94.55135696662771],
  ["BJ", 81.27678223607325],
  ["LA", 95.80151928320997],
  ["ME", 90.39929879236462],
  ["OM", 92.46039475392807],
  ["NI", 78.10251915335671],
  ["MR", 93.07265290222048],
  ["TG", 80.48532658096352],
  ["TP", 91.3141150499935],
  ["North Korea", 91.57089988313206],
  ["VN", 94.98896247240619],
  ["ZM", 93.29502661991948],
  ["MD", 71.48324892871055],
  ["CO", 74.24490325931697],
  ["AG", 87.46688741721854],
  ["AE", 87.03252824308532],
  ["CZ", 94.15757693805999],
  ["AR", 92.97363978704064],
  ["BI", 85.84437086092716],
  ["CM", 87.6954291650435],
  ["LB", 89.66497857421115],
  ["LU", 95.28924814959096],
  ["TD", 71.63972211401118],
  ["TR", 93.91832229580574],
  ["Scotland", 75.8868978054798],
  ["UA", 65.148032723023],
  ["LT", 79.98896247240617],
  ["LC", 89.57213348915725],
  ["MG", 96.23393065835606],
  ["CL", 94.48740423321647],
  ["BH", 93.54661732242566],
  ["AD", 68.658940397351],
  ["CH", 93.94364368263862],
  ["NO", 90.31294637060122],
  ["MT", 95.59083235943385],
  ["MC", 95.76970523308661],
  ["TV", 82.23672250357097],
  ["TW", 95.44312426957538],
  ["US", 79.32119205298012],
  ["MU", 82.70322036099208],
  ["BZ", 83.10641475133099],
  ["CI", 68.39144266978315],
  ["BM", 87.23380080509024],
  ["CK", 91.48941695883651],
  ["AT", 94.53999480586937],
  ["BO", 86.05018828723541],
  ["LS", 87.60063628100247],
  ["NL", 94.73477470458383],
  ["MW", 96.38520971302428],
  ["YE", 96.21477730164914],
  ["UG", 80.76158940397352],
  ["TT", 93.43169718218414],
  ["NZ", 91.12323074925335],
  ["MA", 95.73172315283729],
  ["MV", 95.16815997922347],
  ["LR", 85.41423191793274],
  ["BY", 90.28275548630049],
  ["BN", 67.47338008050903],
  ["AU", 91.93708609271523],
  ["GE", 89.00077911959485],
  ["GR", 76.31963381379042],
  ["IN", 94.36144656538113],
  ["GBENG", 94.25561615374627],
  ["RS", 88.84950006492663],
  ["PL", 96.56083625503182],
  ["SV", 93.47065316192702],
  ["SA", 90.21003765744709],
  ["HK", 92.69413063238541],
  ["KG", 91.05960264900662],
  ["GD", 70.55642124399428],
  ["GQ", 80.51064796779639],
  ["DJ", 92.49675366835476],
  ["KE", 91.502726918582],
  ["KR", 93.05479807817166],
  ["SC", 87.89832489287106],
  ["ST", 80.40189585768081],
  ["PY", 94.58836514738346],
  ["SB", 85.9232567199065],
  ["IL", 91.10732372419167],
  ["DK", 94.62439942864563],
  ["DO", 93.443383976107],
  ["RU", 95.65868069081938],
  ["SG", 92.6847162706142],
  ["PK", 95.11654330606414],
  ["GB", 80.13634592910012],
  ["GU", 92.74704583820284],
  ["GW", 62.81651733541098],
  ["JP", 94.10531099857161],
  ["SR", 92.1078431372549],
  ["SE", 81.38715751201143],
  ["SD", 92.17147123750162],
  ["SS", 89.75068172964549],
  ["PH", 93.40475262952863],
  ["RW", 84.58446954940916],
  ["KU", 93.2979483184002],
  ["HN", 76.32547721075184],
  ["GA", 77.52889235164264],
  ["DZ", 93.07784703285287],
  ["DM", 88.78976756265419],
  ["FR", 93.24990261005064],
  ["GL", 93.8624853915076],
  ["ES", 80.65932995714842],
  ["HT", 95.96318659914297],
  ["PE", 93.10349305285028],
  ["QA", 93.77840540189585],
  ["SI", 93.43689131281651],
  ["PR", 87.6860148032723],
  ["PS", 92.86910790806388],
  ["IQ", 93.43072328269056],
  ["HU", 85.95539540319439],
  ["KN", 90.16523828074277],
  ["KY", 89.05207115958966],
  ["FI", 94.71075185040904],
  ["ER", 84.7195169458512],
  ["GM", 95.23893000908973],
  ["EE", 98.57161407609402],
  ["EG", 94.5727827554863],
  ["ID", 95.89890923256719],
  ["IS", 91.9393585248669],
  ["SK", 92.2750292169848],
  ["RO", 71.79587066614725],
  ["PG", 91.00473964420205],
  ["KM", 76.36573172315283],
  ["IR", 86.9137125048695],
  ["KZ", 88.55505778470328],
  ["IE", 92.94020257109466],
  ["GN", 46.796195299311776],
  ["FJ", 82.73243734579924],
  ["GY", 59.35949876639397],
  ["JM", 83.4703285287625],
  ["KI", 85.10972600960915],
  ["HR", 93.47747045838203],
  ["SO", 95.45383716400467],
  ["PT", 92.1536164134528],
  ["SY", 94.3085313595637],
  ["SN", 94.92241267367875],
  ["KH", 92.25717439293598],
  ["EC", 72.61134917543176],
  ["ET", 88.50701207635372],
  ["DE", 81.886767952214],
  ["SL", 86.74133229450722],
  ["PW", 83.17945721334891],
  ["SZ", 82.57304246201792],
  ["PA", 93.5768082067264],
  ["SM", 90.41553045059084],
  ["IT", 93.39631216725101],
  ["JO", 92.67952213998181],
  ["GH", 87.21594598104141],
  ["CG", 92.61946500454486],
  ["BT", 70.13537202960654],
  ["AO", 94.86008310609012],
  ["ML", 71.35534346188807],
  ["TN", 92.30067523698222],
  ["LI", 95.05194130632385],
  ["MM", 77.7399039085833],
  ["MZ", 78.58297623685235],
  ["NA", 71.05181145305805],
  ["CF", 85.55771977665239],
  ["BB", 73.71380340215556],
  ["CD", 89.35560316841969],
  ["BW", 94.83216465394105],
  ["AL", 97.78243085313596],
  ["NC", 79.31956888715752],
  ["MX", 91.60855733021685],
  ["TZ", 90.58433969614336],
  ["VE", 80.59797428905337],
  ["MN", 92.27860018179457],
  ["MY", 84.26340734969484],
  ["AM", 96.18653421633555],
  ["CR", 90.75801843916375],
  ["BA", 73.91507596416051],
  ["AZ", 85.96545903129463],
  ["CV", 91.91858200233736],
  ["BR", 84.26470588235294],
  ["CA", 88.29080638878068],
  ["BE", 95.84859109206596],
  ["TH", 95.9596156343332],
  ["WS", 95.14478639137775],
  ["UZ", 92.32599662381509],
  ["Wales", 90.28080768731334],
  ["NG", 68.39728606674458],
  ["NP", 87.11401116738085],
  ["MK", 77.92754187767822],
  ["BD", 96.39722114011168],
  ["BS", 93.41254382547721],
  ["CU", 88.3544344890274],
  ["BF", 81.18815738215817],
  ["NR", 94.02317880794702],
  ["NE", 93.96247240618102],
  ["ZW", 81.11901051811454],
  ["VC", 0],
  ["UY", 88.53622906116088],
  ["TJ", 92.38248279444228],
  ["VU", 83.05285027918453],
  ["ZA", 89.57927541877679],
  ["BG", 85.13602129593559],
]);

export const uyMap = new Map<string, number>([
  ["CY", 45.72360732372419],
  ["AF", 91.73419036488768],
  ["CN", 95.36326451110246],
  ["BJ", 93.00642773665759],
  ["LA", 92.81619270224645],
  ["ME", 93.51188157382158],
  ["OM", 77.37534086482275],
  ["NI", 84.57797688611869],
  ["MR", 93.85923905986235],
  ["TG", 89.89903908583301],
  ["TP", 91.17160109076742],
  ["North Korea", 84.20140241527075],
  ["VN", 93.94818854694196],
  ["ZM", 93.97318530061031],
  ["MD", 82.35456434229322],
  ["CO", 77.5564861706272],
  ["AG", 88.46156343332035],
  ["AE", 88.09537722373719],
  ["CZ", 73.6281002467212],
  ["AR", 83.40053239838981],
  ["BI", 74.65426567978184],
  ["CM", 90.94630567458772],
  ["LB", 69.30203869627321],
  ["LU", 84.11018049603948],
  ["TD", 92.15718737826256],
  ["TR", 91.54298143098299],
  ["Scotland", 72.57239319568886],
  ["UA", 89.79418257369173],
  ["LT", 94.99577976886118],
  ["LC", 91.56310868718349],
  ["MG", 75.63530710297364],
  ["CL", 77.88079470198676],
  ["BH", 74.97175691468641],
  ["AD", 89.71886767952213],
  ["CH", 83.51285547331516],
  ["NO", 88.90144137125048],
  ["MT", 66.24204648746917],
  ["MC", 70.15809635112323],
  ["TV", 89.10953122971043],
  ["TW", 94.939293598234],
  ["US", 75.54116348526165],
  ["MU", 93.14537073107388],
  ["BZ", 62.61264770808987],
  ["CI", 76.37936631606284],
  ["BM", 85.55057784703286],
  ["CK", 85.73172315283729],
  ["AT", 84.1374496818595],
  ["BO", 92.11660823269705],
  ["LS", 71.72152967147125],
  ["NL", 82.33930658356059],
  ["MW", 94.0942734709778],
  ["YE", 84.33222958057395],
  ["UG", 80.90540189585768],
  ["TT", 89.32184131930919],
  ["NZ", 87.71458252175042],
  ["MA", 95.27334112452928],
  ["MV", 95.36066744578626],
  ["LR", 72.82528243085314],
  ["BY", 92.06466692637319],
  ["BN", 72.43604726658876],
  ["AU", 86.94747435398],
  ["GE", 54.54161797169199],
  ["GR", 93.10868718348266],
  ["IN", 82.76490066225165],
  ["GBENG", 55.66419945461628],
  ["RS", 72.29418257369173],
  ["PL", 66.14335800545383],
  ["SV", 66.83385274639657],
  ["SA", 90.0970653161927],
  ["HK", 91.15407089988314],
  ["KG", 89.2049733800805],
  ["GD", 85.30223347617193],
  ["GQ", 87.46396571873782],
  ["DJ", 80.22854174782495],
  ["KE", 86.95981041423192],
  ["KR", 48.299896117387355],
  ["SC", 80.87521101155694],
  ["ST", 89.79061160888196],
  ["PY", 73.73035969354629],
  ["SB", 77.94117647058823],
  ["IL", 31.8234644851318],
  ["DK", 79.7587975587586],
  ["DO", 79.39293598233995],
  ["RU", 56.61829632515257],
  ["SG", 68.77126347227632],
  ["PK", 77.04875990131151],
  ["GB", 77.42078950785613],
  ["GU", 93.92254252694455],
  ["GW", 91.34592910011686],
  ["JP", 49.06830281781587],
  ["SR", 81.19010518114531],
  ["SE", 66.6536813400857],
  ["SD", 88.46383586547202],
  ["SS", 86.23425529152058],
  ["PH", 70.18763796909492],
  ["RW", 93.11647837943124],
  ["KU", 87.82982729515648],
  ["HN", 84.02447734060512],
  ["GA", 92.05914816257629],
  ["DZ", 70.48143098298922],
  ["DM", 88.23074925334372],
  ["FR", 71.37579535125307],
  ["GL", 66.28944292948967],
  ["ES", 87.51395922607453],
  ["HT", 82.95059083235944],
  ["PE", 78.21516686144656],
  ["QA", 76.21185560316842],
  ["SI", 73.84820153226853],
  ["PR", 75.14056616023893],
  ["PS", 88.62160758343073],
  ["IQ", 85.0782365926503],
  ["HU", 83.86053759252046],
  ["KN", 86.40111673808596],
  ["KY", 85.36099207895079],
  ["FI", 59.89384495520063],
  ["ER", 89.51499805220101],
  ["GM", 88.32424360472666],
  ["EE", 73.95792754187768],
  ["EG", 85.22432151668615],
  ["ID", 70.15809635112323],
  ["IS", 69.65816127775614],
  ["SK", 57.712634722763276],
  ["RO", 92.06369302687962],
  ["PG", 91.74068302817815],
  ["KM", 81.40111673808596],
  ["IR", 79.79483184002078],
  ["KZ", 94.25918711855603],
  ["IE", 78.51545253863135],
  ["GN", 91.78320997273082],
  ["FJ", 86.86728996234255],
  ["GY", 84.14783794312427],
  ["JM", 84.83735878457344],
  ["KI", 80.00649266329049],
  ["HR", 81.49948058693676],
  ["SO", 94.73120373977405],
  ["PT", 91.51701077782106],
  ["SY", 85.72198415790157],
  ["SN", 91.62024412413973],
  ["KH", 74.70977795091547],
  ["EC", 77.14257888585898],
  ["ET", 85.19088430074017],
  ["DE", 92.40683028178158],
  ["SL", 83.97740553174913],
  ["PW", 91.13459291001169],
  ["SZ", 85.46032982729515],
  ["PA", 56.25925204518894],
  ["SM", 65.32106219971432],
  ["IT", 78.41351772497079],
  ["JO", 90.26457602908712],
  ["GH", 91.17711985456434],
  ["CG", 89.2371120633684],
  ["BT", 86.4218932606155],
  ["AO", 92.84833138553435],
  ["ML", 92.13446305674587],
  ["TN", 90.35742111414102],
  ["LI", 95.89306583560577],
  ["MM", 84.54389040384366],
  ["MZ", 87.2925594078691],
  ["NA", 87.93403454096871],
  ["CF", 80.8138553434619],
  ["BB", 89.64485131801064],
  ["CD", 90.41877678223608],
  ["BW", 93.79820802493182],
  ["AL", 90.67815868069083],
  ["NC", 83.28853395662901],
  ["MX", 78.4878587196468],
  ["TZ", 90.15971951694584],
  ["VE", 87.9811063498247],
  ["MN", 80.21198545643423],
  ["MY", 69.06570575249967],
  ["AM", 79.45169458511882],
  ["CR", 73.10057135436956],
  ["BA", 67.54804570834956],
  ["AZ", 93.85696662771069],
  ["CV", 59.4812362030905],
  ["BR", 86.73776132969743],
  ["CA", 73.00415530450591],
  ["BE", 92.51363459291001],
  ["TH", 75.4191014154006],
  ["WS", 96.13654070899884],
  ["UZ", 83.17718478119724],
  ["Wales", 61.82411375146085],
  ["NG", 78.41286845864174],
  ["NP", 64.37637969094922],
  ["MK", 79.11147902869757],
  ["BD", 93.90923256719907],
  ["BS", 93.88423581353071],
  ["CU", 61.59070250616803],
  ["BF", 95.38501493312556],
  ["NR", 92.09388391118037],
  ["NE", 86.4689650694715],
  ["ZW", 81.54233216465394],
  ["VC", 88.53622906116088],
  ["UY", 0],
  ["TJ", 75.75542137384755],
  ["VU", 88.41514089079341],
  ["ZA", 84.1510842747695],
  ["BG", 73.93682638618361],
]);

export const tjMap = new Map<string, number>([
  ["CY", 65.46107266435986],
  ["AF", 84.48356401384083],
  ["CN", 96.20891003460208],
  ["BJ", 93.40657439446368],
  ["LA", 89.55882352941177],
  ["ME", 75.03157439446366],
  ["OM", 68.7863321799308],
  ["NI", 66.50519031141869],
  ["MR", 94.8628892733564],
  ["TG", 89.58131487889274],
  ["TP", 91.33564013840831],
  ["North Korea", 93.42863321799308],
  ["VN", 93.23875432525952],
  ["ZM", 94.98399653979239],
  ["MD", 94.00692041522491],
  ["CO", 91.58650519031141],
  ["AG", 87.31920415224913],
  ["AE", 74.00475778546712],
  ["CZ", 84.24351211072664],
  ["AR", 68.12543252595155],
  ["BI", 77.91911764705883],
  ["CM", 95.49610726643598],
  ["LB", 62.38927335640139],
  ["LU", 66.0333044982699],
  ["TD", 89.9234429065744],
  ["TR", 66.03157439446366],
  ["Scotland", 78.10899653979239],
  ["UA", 94.12975778546712],
  ["LT", 99.0181660899654],
  ["LC", 95.2249134948097],
  ["MG", 85.22015570934256],
  ["CL", 83.95544982698962],
  ["BH", 83.60726643598616],
  ["AD", 93.27378892733564],
  ["CH", 82.41392733564014],
  ["NO", 85.56401384083046],
  ["MT", 78.16955017301038],
  ["MC", 78.3507785467128],
  ["TV", 89.7742214532872],
  ["TW", 95.13278546712802],
  ["US", 77.24437716262976],
  ["MU", 92.67128027681662],
  ["BZ", 80.03243944636678],
  ["CI", 86.2378892733564],
  ["BM", 86.70891003460207],
  ["CK", 87.41695501730104],
  ["AT", 66.2348615916955],
  ["BO", 92.49351211072664],
  ["LS", 62.52032871972318],
  ["NL", 66.52854671280278],
  ["MW", 91.50216262975779],
  ["YE", 66.58217993079585],
  ["UG", 86.64143598615917],
  ["TT", 91.53157439446366],
  ["NZ", 89.28546712802769],
  ["MA", 98.79671280276816],
  ["MV", 95.9939446366782],
  ["LR", 75.19333910034602],
  ["BY", 94.79152249134948],
  ["BN", 77.2720588235294],
  ["AU", 86.94852941176471],
  ["GE", 76.69896193771626],
  ["GR", 78.02508650519032],
  ["IN", 68.60856401384083],
  ["GBENG", 79.66782006920415],
  ["RS", 87.73529411764706],
  ["PL", 78.42949826989619],
  ["SV", 67.56098615916954],
  ["SA", 91.53200692041523],
  ["HK", 90.61980968858131],
  ["KG", 89.1743079584775],
  ["GD", 84.3637543252595],
  ["GQ", 71.87370242214533],
  ["DJ", 80.5454152249135],
  ["KE", 60.30968858131488],
  ["KR", 69.51643598615917],
  ["SC", 81.090830449827],
  ["ST", 83.82439446366782],
  ["PY", 67.77854671280276],
  ["SB", 88.56574394463668],
  ["IL", 60.21020761245674],
  ["DK", 81.79887543252595],
  ["DO", 76.31617647058823],
  ["RU", 94.92776816608996],
  ["SG", 74.65441176470588],
  ["PK", 83.88538062283737],
  ["GB", 72.70847750865052],
  ["GU", 94.16392733564014],
  ["GW", 92.91089965397924],
  ["JP", 71.54195501730104],
  ["SR", 94.97967128027682],
  ["SE", 93.31790657439446],
  ["SD", 73.0220588235294],
  ["SS", 87.66911764705883],
  ["PH", 84.34126297577855],
  ["RW", 91.21323529411765],
  ["KU", 71.48832179930797],
  ["HN", 66.84515570934256],
  ["GA", 92.33131487889274],
  ["DZ", 78.56790657439447],
  ["DM", 90.02638408304499],
  ["FR", 85.44290657439446],
  ["GL", 77.4303633217993],
  ["ES", 61.24437716262976],
  ["HT", 96.60813148788927],
  ["PE", 85.39403114186851],
  ["QA", 84.1029411764706],
  ["SI", 93.66608996539793],
  ["PR", 78.06920415224914],
  ["PS", 72.6583044982699],
  ["IQ", 69.07742214532871],
  ["HU", 65.97621107266436],
  ["KN", 87.51643598615917],
  ["KY", 88.6362456747405],
  ["FI", 87.19506920415225],
  ["ER", 88.05233564013841],
  ["GM", 88.37456747404843],
  ["EE", 95.36937716262976],
  ["EG", 67.55017301038062],
  ["ID", 78.35121107266436],
  ["IS", 86.57006920415225],
  ["SK", 92.78892733564014],
  ["RO", 90.10423875432527],
  ["PG", 90.59256055363322],
  ["KM", 80.20847750865052],
  ["IR", 59.14576124567474],
  ["KZ", 94.16782006920415],
  ["IE", 85.81228373702422],
  ["GN", 90.01340830449827],
  ["FJ", 86.06790657439446],
  ["GY", 80.55795847750865],
  ["JM", 84.77811418685121],
  ["KI", 78.78287197231833],
  ["HR", 65.01730103806229],
  ["SO", 94.74221453287197],
  ["PT", 82.48442906574395],
  ["SY", 68.41955017301038],
  ["SN", 91.0878027681661],
  ["KH", 90.36072664359862],
  ["EC", 91.19982698961938],
  ["ET", 91.79498269896193],
  ["DE", 97.15484429065744],
  ["SL", 66.03546712802768],
  ["PW", 91.20242214532873],
  ["SZ", 87.29974048442907],
  ["PA", 77.75821799307958],
  ["SM", 79.46193771626298],
  ["IT", 85.52595155709342],
  ["JO", 76.63538062283737],
  ["GH", 93.76557093425606],
  ["CG", 87.54022491349481],
  ["BT", 88.58391003460207],
  ["AO", 94.20804498269895],
  ["ML", 90.05060553633218],
  ["TN", 62.96107266435986],
  ["LI", 96.06228373702422],
  ["MM", 86.39965397923875],
  ["MZ", 89.80233564013841],
  ["NA", 88.36721453287197],
  ["CF", 78.89446366782008],
  ["BB", 92.19117647058823],
  ["CD", 91.84948096885813],
  ["BW", 90.73832179930797],
  ["AL", 70.5151384083045],
  ["NC", 89.50432525951557],
  ["MX", 88.52162629757785],
  ["TZ", 89.72750865051903],
  ["VE", 94.13754325259515],
  ["MN", 94.43122837370242],
  ["MY", 77.21020761245676],
  ["AM", 70.97750865051903],
  ["CR", 88.16955017301038],
  ["BA", 88.2469723183391],
  ["AZ", 97.2348615916955],
  ["CV", 83.84342560553634],
  ["BR", 91.44420415224913],
  ["CA", 87.52638408304499],
  ["BE", 89.69679930795847],
  ["TH", 90.36375432525952],
  ["WS", 96.43166089965398],
  ["UZ", 65.29584775086505],
  ["Wales", 77.42690311418686],
  ["NG", 85.59904844290658],
  ["NP", 72.96929065743944],
  ["MK", 67.90138408304497],
  ["BD", 95.55579584775087],
  ["BS", 89.19031141868513],
  ["CU", 79.19766435986159],
  ["BF", 97.06271626297578],
  ["NR", 94.72837370242215],
  ["NE", 70.80622837370242],
  ["ZW", 89.35726643598616],
  ["VC", 92.23226643598615],
  ["UY", 75.28806228373702],
  ["TJ", 0],
  ["VU", 88.4636678200692],
  ["ZA", 88.11764705882354],
  ["BG", 94.92387543252595],
]);

export const vuMap = new Map<string, number>([
  ["CY", 91.29000865051903],
  ["AF", 73.07201557093425],
  ["CN", 94.37283737024221],
  ["BJ", 89.07871972318338],
  ["LA", 66.71893021914647],
  ["ME", 77.12045847750865],
  ["OM", 89.84212802768165],
  ["NI", 93.20033160322953],
  ["MR", 82.26787773933103],
  ["TG", 89.05420991926182],
  ["TP", 51.41904555940023],
  ["North Korea", 80.8791089965398],
  ["VN", 65.95299884659747],
  ["ZM", 91.00237889273356],
  ["MD", 71.13465974625144],
  ["CO", 88.31495098039215],
  ["AG", 88.44975490196079],
  ["AE", 92.9858708189158],
  ["CZ", 89.91097174163784],
  ["AR", 92.82691753171856],
  ["BI", 76.4071510957324],
  ["CM", 77.55298442906575],
  ["LB", 70.87442329873126],
  ["LU", 68.82388985005767],
  ["TD", 73.8256920415225],
  ["TR", 65.30276816608996],
  ["Scotland", 89.90844867358709],
  ["UA", 93.02047289504037],
  ["LT", 95.13083910034602],
  ["LC", 91.80002883506344],
  ["MG", 67.98190599769319],
  ["CL", 91.80291234140715],
  ["BH", 66.9748414071511],
  ["AD", 73.56761822376009],
  ["CH", 93.81992502883506],
  ["NO", 67.95703575547867],
  ["MT", 73.07850346020761],
  ["MC", 64.0433967704729],
  ["TV", 80.57381776239907],
  ["TW", 89.30651672433679],
  ["US", 78.42704728950403],
  ["MU", 47.57316897347174],
  ["BZ", 72.26571510957324],
  ["CI", 79.05276816608998],
  ["BM", 69.21027970011534],
  ["CK", 78.00064878892734],
  ["AT", 68.29801038062284],
  ["BO", 38.93310265282584],
  ["LS", 66.54664071510958],
  ["NL", 93.15491637831603],
  ["MW", 82.16587370242215],
  ["YE", 64.4979094579008],
  ["UG", 82.02890715109574],
  ["TT", 68.6663783160323],
  ["NZ", 78.95364763552479],
  ["MA", 65.41198096885813],
  ["MV", 65.60445501730104],
  ["LR", 74.42978662053056],
  ["BY", 94.32093425605537],
  ["BN", 84.37391868512111],
  ["AU", 83.4324538638985],
  ["GE", 91.54051326412919],
  ["GR", 93.93850922722031],
  ["IN", 92.79051326412919],
  ["GBENG", 89.76751730103805],
  ["RS", 86.32533160322953],
  ["PL", 94.44852941176471],
  ["SV", 91.4132785467128],
  ["SA", 91.15952998846598],
  ["HK", 93.63141580161476],
  ["KG", 65.12471164936564],
  ["GD", 76.10330161476355],
  ["GQ", 94.97729238754326],
  ["DJ", 62.37564878892734],
  ["KE", 79.63884083044984],
  ["KR", 87.55154267589388],
  ["SC", 53.94752018454441],
  ["ST", 68.54094579008074],
  ["PY", 65.1441753171857],
  ["SB", 90.4970444059977],
  ["IL", 88.83902825836218],
  ["DK", 68.24070069204153],
  ["DO", 74.1663062283737],
  ["RU", 88.69989907727796],
  ["SG", 64.1079152249135],
  ["PK", 85.91010668973472],
  ["GB", 78.6804354094579],
  ["GU", 75.3961216839677],
  ["GW", 63.88516435986159],
  ["JP", 85.69420415224913],
  ["SR", 84.61252883506344],
  ["SE", 87.44413206459055],
  ["SD", 68.53589965397924],
  ["SS", 66.67027104959631],
  ["PH", 88.21222606689734],
  ["RW", 88.72549019607843],
  ["KU", 84.18432814302192],
  ["HN", 93.85632929642446],
  ["GA", 90.1279555940023],
  ["DZ", 85.18238177623991],
  ["DM", 85.46244232987313],
  ["FR", 75.11317762399077],
  ["GL", 89.4434832756632],
  ["ES", 69.28993656286045],
  ["HT", 89.28056516724337],
  ["PE", 78.47714821222607],
  ["QA", 86.95393598615917],
  ["SI", 95.44333910034602],
  ["PR", 93.39857266435986],
  ["PS", 68.78460207612457],
  ["IQ", 64.2459630911188],
  ["HU", 42.66616205305652],
  ["KN", 84.66515282583622],
  ["KY", 78.54923587081892],
  ["FI", 87.7872693194925],
  ["ER", 82.6138985005767],
  ["GM", 64.2499279123414],
  ["EE", 80.23644752018454],
  ["EG", 63.823168973471745],
  ["ID", 63.9183246828143],
  ["IS", 87.73608708189158],
  ["SK", 92.54757785467127],
  ["RO", 73.63141580161476],
  ["PG", 52.688148788927336],
  ["KM", 95.60841983852364],
  ["IR", 87.19218569780853],
  ["KZ", 89.78301614763552],
  ["IE", 92.30067762399077],
  ["GN", 77.13379469434832],
  ["FJ", 84.55089388696655],
  ["GY", 67.50720876585929],
  ["JM", 58.09256055363322],
  ["KI", 59.89403114186851],
  ["HR", 85.55940023068051],
  ["SO", 94.98774509803921],
  ["PT", 84.44528546712803],
  ["SY", 64.17315455594003],
  ["SN", 78.13292964244522],
  ["KH", 80.29808246828142],
  ["EC", 85.80594002306805],
  ["ET", 83.52580738177625],
  ["DE", 88.88696655132642],
  ["SL", 94.18180507497117],
  ["PW", 90.23428489042676],
  ["SZ", 80.35755478662054],
  ["PA", 68.9284169550173],
  ["SM", 89.0080738177624],
  ["IT", 78.47102076124568],
  ["JO", 71.19989907727798],
  ["GH", 63.993295847750865],
  ["CG", 88.79072952710496],
  ["BT", 87.38249711649365],
  ["AO", 55.46640715109573],
  ["ML", 81.16061130334487],
  ["TN", 67.34032583621683],
  ["LI", 89.71237024221453],
  ["MM", 93.55572376009226],
  ["MZ", 88.1412197231834],
  ["NA", 51.99863033448674],
  ["CF", 85.1441753171857],
  ["BB", 84.5483708189158],
  ["CD", 86.27595155709342],
  ["BW", 83.73810553633217],
  ["AL", 65.12543252595155],
  ["NC", 65.35539215686275],
  ["MX", 79.96792099192618],
  ["TZ", 85.95408016147636],
  ["VE", 86.68829296424451],
  ["MN", 74.63848039215686],
  ["MY", 75.24834198385236],
  ["AM", 87.64489619377163],
  ["CR", 88.66782006920415],
  ["BA", 78.97239042675895],
  ["AZ", 90.64266147635524],
  ["CV", 83.89381487889274],
  ["BR", 64.29570357554788],
  ["CA", 93.53770184544405],
  ["BE", 67.02494232987313],
  ["TH", 74.50764129181084],
  ["WS", 69.29173875432527],
  ["UZ", 90.42423587081892],
  ["Wales", 58.105896770472896],
  ["NG", 78.3809111880046],
  ["NP", 83.73341983852364],
  ["MK", 77.09414648212226],
  ["BD", 92.43692329873126],
  ["BS", 81.08852364475202],
  ["CU", 92.30716551326414],
  ["BF", 36.339388696655135],
  ["NR", 82.07576412918108],
  ["NE", 68.19708765859285],
  ["ZW", 86.38840830449827],
  ["VC", 82.96929065743944],
  ["UY", 88.22195790080738],
  ["TJ", 89.13963379469435],
  ["VU", 0],
  ["ZA", 84.65722318339101],
  ["BG", 95.60805940023069],
]);

export const zaMap = new Map<string, number>([
  ["CY", 84.85716140760941],
  ["AF", 80.91351772497079],
  ["CN", 91.70854434489027],
  ["BJ", 77.68406700428517],
  ["LA", 90.48532658096352],
  ["ME", 85.20971302428256],
  ["OM", 90.89306583560577],
  ["NI", 88.1521880275289],
  ["MR", 65.56096610829762],
  ["TG", 72.71360862225686],
  ["TP", 65.27334112452928],
  ["North Korea", 85.26392676275808],
  ["VN", 72.4987014673419],
  ["ZM", 88.85404492922997],
  ["MD", 87.9609790936242],
  ["CO", 85.69439033891703],
  ["AG", 90.27171795870666],
  ["AE", 88.58070380470069],
  ["CZ", 84.97110764835736],
  ["AR", 88.97221140111674],
  ["BI", 83.0405142189326],
  ["CM", 78.81281651733542],
  ["LB", 70.52363329437735],
  ["LU", 69.05726529022205],
  ["TD", 91.30307752239969],
  ["TR", 92.08024931827036],
  ["Scotland", 91.23457992468511],
  ["UA", 90.2197766523828],
  ["LT", 79.17640566160239],
  ["LC", 94.08226204389041],
  ["MG", 67.40131151798468],
  ["CL", 89.77373068432671],
  ["BH", 92.30749253343721],
  ["AD", 89.28288533956629],
  ["CH", 91.12160758343072],
  ["NO", 73.25055187637969],
  ["MT", 92.39774055317491],
  ["MC", 90.60868718348266],
  ["TV", 83.827425009739],
  ["TW", 92.16400467471757],
  ["US", 86.52512660693417],
  ["MU", 74.7386703025581],
  ["BZ", 84.4286456304376],
  ["CI", 90.53726788728737],
  ["BM", 85.02791845214908],
  ["CK", 69.98571614076094],
  ["AT", 68.58946890014283],
  ["BO", 63.880015582391906],
  ["LS", 84.77470458382028],
  ["NL", 88.35833008700169],
  ["MW", 92.64673419036488],
  ["YE", 88.79755875860278],
  ["UG", 87.91617971691988],
  ["TT", 91.07908063887807],
  ["NZ", 67.71133619010517],
  ["MA", 71.06155044799377],
  ["MV", 78.58005453837164],
  ["LR", 89.44292948967667],
  ["BY", 72.14355278535255],
  ["BN", 80.89306583560577],
  ["AU", 88.56252434748734],
  ["GE", 83.08823529411765],
  ["GR", 87.062069861057],
  ["IN", 88.85664199454617],
  ["GBENG", 87.77399039085833],
  ["RS", 69.73282690559668],
  ["PL", 91.09466303077522],
  ["SV", 87.38313206077133],
  ["SA", 67.32534735748604],
  ["HK", 88.72678872873652],
  ["KG", 86.02389300090897],
  ["GD", 74.46467991169979],
  ["GQ", 86.69393585248669],
  ["DJ", 95.84859109206596],
  ["KE", 89.06408258667705],
  ["KR", 86.77314634463058],
  ["SC", 67.92526944552655],
  ["ST", 87.74055317491235],
  ["PY", 66.42903519023503],
  ["SB", 85.63108687183482],
  ["IL", 85.10485651214128],
  ["DK", 93.11128424879885],
  ["DO", 91.64751330995975],
  ["RU", 92.56362810024672],
  ["SG", 68.94526684846124],
  ["PK", 92.19192312686664],
  ["GB", 72.51850409037787],
  ["GU", 69.67731463446306],
  ["GW", 90.59635112323075],
  ["JP", 88.79203999480588],
  ["SR", 91.78905336969225],
  ["SE", 91.69101415400598],
  ["SD", 87.14842228282042],
  ["SS", 85.63465783664459],
  ["PH", 92.00590832359434],
  ["RW", 86.33456693935852],
  ["KU", 75.68530061031035],
  ["HN", 88.03304765614855],
  ["GA", 89.272172445137],
  ["DZ", 69.18971562134789],
  ["DM", 71.03720296065445],
  ["FR", 78.73035969354629],
  ["GL", 89.64485131801064],
  ["ES", 86.01415400597325],
  ["HT", 91.82021815348655],
  ["PE", 91.04694195559019],
  ["QA", 92.56038176860149],
  ["SI", 91.9500714192962],
  ["PR", 78.83748863783924],
  ["PS", 83.79139072847683],
  ["IQ", 86.37611998441761],
  ["HU", 82.49642903519023],
  ["KN", 91.25178548240488],
  ["KY", 69.65881054408518],
  ["FI", 88.64660433709908],
  ["ER", 85.19835086352423],
  ["GM", 88.41579015712246],
  ["EE", 86.75301908843008],
  ["EG", 88.7225684975977],
  ["ID", 90.75444747435398],
  ["IS", 72.08024931827035],
  ["SK", 91.53161927022465],
  ["RO", 90.6697182184132],
  ["PG", 81.17030255810934],
  ["KM", 86.24042332164655],
  ["IR", 81.67932736008311],
  ["KZ", 85.26457602908714],
  ["IE", 91.14725360342813],
  ["GN", 86.00831060901182],
  ["FJ", 85.81093364498116],
  ["GY", 85.91449162446435],
  ["JM", 82.74412413972212],
  ["KI", 75.2963900792105],
  ["HR", 64.90423321646539],
  ["SO", 90.11751720555772],
  ["PT", 87.06888715751201],
  ["SY", 87.76717309440333],
  ["SN", 67.82625633034671],
  ["KH", 72.6522529541618],
  ["EC", 85.77684716270613],
  ["ET", 76.5728476821192],
  ["DE", 93.7952863264511],
  ["SL", 87.93598233995586],
  ["PW", 91.8922867160109],
  ["SZ", 82.4824698091157],
  ["PA", 88.75665497987274],
  ["SM", 88.19179327360082],
  ["IT", 68.44500714192961],
  ["JO", 83.01843916374497],
  ["GH", 82.55583690429815],
  ["CG", 79.42961952993117],
  ["BT", 83.94169588365148],
  ["AO", 87.76912089339048],
  ["ML", 91.8491105051292],
  ["TN", 89.60329827295156],
  ["LI", 92.35553824178679],
  ["MM", 88.69497467861316],
  ["MZ", 85.7008830022075],
  ["NA", 79.08875470718088],
  ["CF", 89.86365407089988],
  ["BB", 80.24574730554474],
  ["CD", 89.55882352941177],
  ["BW", 89.46532917802882],
  ["AL", 71.63712504869497],
  ["NC", 86.83839761069991],
  ["MX", 78.02493182703545],
  ["TZ", 90.45091546552396],
  ["VE", 89.6455005843397],
  ["MN", 79.39780547980781],
  ["MY", 75.29444228022335],
  ["AM", 93.28236592650305],
  ["CR", 81.52869757174392],
  ["BA", 72.00590832359434],
  ["AZ", 91.92897026360212],
  ["CV", 75.42559407869108],
  ["BR", 86.28652123100896],
  ["CA", 88.537527593819],
  ["BE", 75.71354369562394],
  ["TH", 81.8721594598104],
  ["WS", 91.81989352032204],
  ["UZ", 86.99324763017789],
  ["Wales", 80.27204259187118],
  ["NG", 72.68309310479158],
  ["NP", 79.53155434359174],
  ["MK", 79.83606025191533],
  ["BD", 73.0499285807038],
  ["BS", 82.48896247240619],
  ["CU", 69.06245942085444],
  ["BF", 72.4623425529152],
  ["NR", 71.725100636281],
  ["NE", 88.44176081028438],
  ["ZW", 91.72737306843267],
  ["VC", 89.57927541877679],
  ["UY", 84.1510842747695],
  ["TJ", 88.24211141410207],
  ["VU", 83.98974159200104],
  ["ZA", 0],
  ["BG", 93.48623555382419],
]);

export const bgMap = new Map<string, number>([
  ["CY", 65.06343713956171],
  ["AF", 90.79188292964244],
  ["CN", 63.79469434832756],
  ["BJ", 64.59306516724337],
  ["LA", 73.51679642445214],
  ["ME", 89.22649942329873],
  ["OM", 62.18822087658593],
  ["NI", 99.2499279123414],
  ["MR", 95.70573817762399],
  ["TG", 94.49718858131489],
  ["TP", 69.42870530565168],
  ["North Korea", 80.98255478662053],
  ["VN", 64.36634948096885],
  ["ZM", 94.44672722029989],
  ["MD", 93.1621251441753],
  ["CO", 71.56862745098039],
  ["AG", 66.90635813148789],
  ["AE", 91.21251441753172],
  ["CZ", 41.61043829296425],
  ["AR", 98.43425605536332],
  ["BI", 69.59630911188005],
  ["CM", 75.3366493656286],
  ["LB", 61.06942041522492],
  ["LU", 99.75490196078431],
  ["TD", 98.2097029988466],
  ["TR", 63.765859284890425],
  ["Scotland", 80.84018166089966],
  ["UA", 99.26470588235294],
  ["LT", 65.68627450980392],
  ["LC", 98.93094002306805],
  ["MG", 87.76239907727798],
  ["CL", 42.39727508650519],
  ["BH", 65.21554209919262],
  ["AD", 94.50583910034602],
  ["CH", 94.43663494809688],
  ["NO", 66.24134948096886],
  ["MT", 67.5162197231834],
  ["MC", 99.50980392156863],
  ["TV", 84.74841407151096],
  ["TW", 94.91998269896193],
  ["US", 83.99005190311418],
  ["MU", 99.01960784313727],
  ["BZ", 79.74444925028835],
  ["CI", 77.31473471741637],
  ["BM", 55.09227220299885],
  ["CK", 88.41407151095733],
  ["AT", 99.75490196078431],
  ["BO", 99.50980392156863],
  ["LS", 96.5776384083045],
  ["NL", 99.75490196078431],
  ["MW", 98.72657151095733],
  ["YE", 99.75490196078431],
  ["UG", 93.79829873125722],
  ["TT", 71.52501441753172],
  ["NZ", 90.5958044982699],
  ["MA", 62.58506343713957],
  ["MV", 97.12658592848904],
  ["LR", 71.67171280276816],
  ["BY", 92.95451268742792],
  ["BN", 83.28972029988466],
  ["AU", 91.87752306805075],
  ["GE", 68.96806516724337],
  ["GR", 86.21107266435986],
  ["IN", 98.75720876585929],
  ["GBENG", 65.86937716262976],
  ["RS", 93.7651384083045],
  ["PL", 66.17647058823529],
  ["SV", 98.53193483275663],
  ["SA", 91.5776384083045],
  ["HK", 62.941897347174155],
  ["KG", 60.5756199538639],
  ["GD", 57.99488177623991],
  ["GQ", 67.13271337946944],
  ["DJ", 89.92286620530565],
  ["KE", 90.52840253748559],
  ["KR", 68.07958477508652],
  ["SC", 87.62254901960785],
  ["ST", 95.23969146482122],
  ["PY", 98.03849480968859],
  ["SB", 88.9687860438293],
  ["IL", 78.73197808535178],
  ["DK", 94.68065167243368],
  ["DO", 78.96121683967705],
  ["RU", 32.59803921568628],
  ["SG", 96.61584486735872],
  ["PK", 88.17293829296425],
  ["GB", 77.11649365628604],
  ["GU", 91.35488754325259],
  ["GW", 84.19478085351788],
  ["JP", 67.95991926182238],
  ["SR", 86.77407727797001],
  ["SE", 98.12752306805075],
  ["SD", 98.08607266435986],
  ["SS", 91.86022202998846],
  ["PH", 64.96936274509804],
  ["RW", 97.46395617070358],
  ["KU", 70.80341695501731],
  ["HN", 98.91868512110726],
  ["GA", 99.39878892733563],
  ["DZ", 79.4899798154556],
  ["DM", 89.33318915801614],
  ["FR", 88.08895617070358],
  ["GL", 69.02032871972318],
  ["ES", 68.11454728950403],
  ["HT", 96.17611014994233],
  ["PE", 66.24279123414073],
  ["QA", 86.69730392156862],
  ["SI", 33.42812860438293],
  ["PR", 83.08499134948096],
  ["PS", 94.29282006920415],
  ["IQ", 97.8809832756632],
  ["HU", 99.75490196078431],
  ["KN", 74.20559400230681],
  ["KY", 87.1734429065744],
  ["FI", 71.32857554786621],
  ["ER", 85.39143598615917],
  ["GM", 99.26470588235294],
  ["EE", 99.75490196078431],
  ["EG", 98.59140715109574],
  ["ID", 99.50980392156863],
  ["IS", 92.31617647058825],
  ["SK", 31.85265282583622],
  ["RO", 87.29238754325259],
  ["PG", 89.03799019607843],
  ["KM", 81.44427623990774],
  ["IR", 91.66378316032295],
  ["KZ", 89.19586216839677],
  ["IE", 88.23457324106113],
  ["GN", 76.47923875432527],
  ["FJ", 89.20523356401384],
  ["GY", 87.68634659746252],
  ["JM", 93.04642445213379],
  ["KI", 89.06790657439446],
  ["HR", 96.38155997693195],
  ["SO", 97.4225057670127],
  ["PT", 91.11807958477509],
  ["SY", 98.35351787773932],
  ["SN", 86.12276528258363],
  ["KH", 93.5878027681661],
  ["EC", 65.11173587081892],
  ["ET", 62.13739907727797],
  ["DE", 98.52941176470588],
  ["SL", 99.75490196078431],
  ["PW", 96.35452710495963],
  ["SZ", 89.39049884659747],
  ["PA", 82.7591551326413],
  ["SM", 62.272923875432525],
  ["IT", 88.13653402537486],
  ["JO", 91.1703431372549],
  ["GH", 99.32201557093425],
  ["CG", 76.67567762399077],
  ["BT", 69.35661764705883],
  ["AO", 97.01340830449827],
  ["ML", 87.2458910034602],
  ["TN", 94.23767301038062],
  ["LI", 63.79865916955018],
  ["MM", 93.31062572087659],
  ["MZ", 93.29692906574395],
  ["NA", 88.62781141868513],
  ["CF", 90.65996251441753],
  ["BB", 98.30557958477509],
  ["CD", 88.32324106113033],
  ["BW", 99.26470588235294],
  ["AL", 62.20876585928489],
  ["NC", 95.07713379469435],
  ["MX", 73.97130911188005],
  ["TZ", 93.54094579008074],
  ["VE", 63.49228662053057],
  ["MN", 76.70235005767013],
  ["MY", 72.64309400230681],
  ["AM", 98.77450980392157],
  ["CR", 80.12651384083046],
  ["BA", 91.84436274509804],
  ["AZ", 96.00021626297578],
  ["CV", 94.92647058823529],
  ["BR", 92.51153402537486],
  ["CA", 81.0070645905421],
  ["BE", 98.43569780853518],
  ["TH", 66.42156862745098],
  ["WS", 64.33499134948096],
  ["UZ", 96.71388408304499],
  ["Wales", 55.02090542099193],
  ["NG", 66.40246539792388],
  ["NP", 66.64287773933103],
  ["MK", 82.53424163783161],
  ["BD", 95.61274509803923],
  ["BS", 98.50886678200692],
  ["CU", 80.65599769319492],
  ["BF", 96.78236735870819],
  ["NR", 96.33722606689736],
  ["NE", 98.50418108419838],
  ["ZW", 89.47051614763552],
  ["VC", 84.98738465974625],
  ["UY", 73.8638985005767],
  ["TJ", 95.2782583621684],
  ["VU", 95.60805940023069],
  ["ZA", 93.28251153402537],
  ["BG", 0],
]);

export const mapMap = new Map<string, Map<string, number>>([
  ["CY", cyMap],
  ["AF", afMap],
  ["CN", cnMap],
  ["BJ", bjMap],
  ["LA", laMap],
  ["ME", meMap],
  ["OM", omMap],
  ["NI", niMap],
  ["MR", mrMap],
  ["TG", tgMap],
  ["TP", tpMap],
  ["NorthKorea", northkoreaMap],
  ["VN", vnMap],
  ["ZM", zmMap],
  ["MD", mdMap],
  ["CO", coMap],
  ["AG", agMap],
  ["AE", aeMap],
  ["CZ", czMap],
  ["AR", arMap],
  ["BI", biMap],
  ["CM", cmMap],
  ["LB", lbMap],
  ["LU", luMap],
  ["TD", tdMap],
  ["TR", trMap],
  ["Scotland", scotlandMap],
  ["UA", uaMap],
  ["LT", ltMap],
  ["LC", lcMap],
  ["MG", mgMap],
  ["CL", clMap],
  ["BH", bhMap],
  ["AD", adMap],
  ["CH", chMap],
  ["NO", noMap],
  ["MT", mtMap],
  ["MC", mcMap],
  ["TV", tvMap],
  ["TW", twMap],
  ["US", usMap],
  ["MU", muMap],
  ["BZ", bzMap],
  ["CI", ciMap],
  ["BM", bmMap],
  ["CK", ckMap],
  ["AT", atMap],
  ["BO", boMap],
  ["LS", lsMap],
  ["NL", nlMap],
  ["MW", mwMap],
  ["YE", yeMap],
  ["UG", ugMap],
  ["TT", ttMap],
  ["NZ", nzMap],
  ["MA", maMap],
  ["MV", mvMap],
  ["LR", lrMap],
  ["BY", byMap],
  ["BN", bnMap],
  ["AU", auMap],
  ["GE", geMap],
  ["GR", grMap],
  ["IN", inMap],
  ["GBENG", gbengMap],
  ["RS", rsMap],
  ["PL", plMap],
  ["SV", svMap],
  ["SA", saMap],
  ["HK", hkMap],
  ["KG", kgMap],
  ["GD", gdMap],
  ["GQ", gqMap],
  ["DJ", djMap],
  ["KE", keMap],
  ["KR", krMap],
  ["SC", scMap],
  ["ST", stMap],
  ["PY", pyMap],
  ["SB", sbMap],
  ["IL", ilMap],
  ["DK", dkMap],
  ["DO", doMap],
  ["RU", ruMap],
  ["SG", sgMap],
  ["PK", pkMap],
  ["GB", gbMap],
  ["GU", guMap],
  ["GW", gwMap],
  ["JP", jpMap],
  ["SR", srMap],
  ["SE", seMap],
  ["SD", sdMap],
  ["SS", ssMap],
  ["PH", phMap],
  ["RW", rwMap],
  ["KU", kuMap],
  ["HN", hnMap],
  ["GA", gaMap],
  ["DZ", dzMap],
  ["DM", dmMap],
  ["FR", frMap],
  ["GL", glMap],
  ["ES", esMap],
  ["HT", htMap],
  ["PE", peMap],
  ["QA", qaMap],
  ["SI", siMap],
  ["PR", prMap],
  ["PS", psMap],
  ["IQ", iqMap],
  ["HU", huMap],
  ["KN", knMap],
  ["KY", kyMap],
  ["FI", fiMap],
  ["ER", erMap],
  ["GM", gmMap],
  ["EE", eeMap],
  ["EG", egMap],
  ["ID", idMap],
  ["IS", isMap],
  ["SK", skMap],
  ["RO", roMap],
  ["PG", pgMap],
  ["KM", kmMap],
  ["IR", irMap],
  ["KZ", kzMap],
  ["IE", ieMap],
  ["GN", gnMap],
  ["FJ", fjMap],
  ["GY", gyMap],
  ["JM", jmMap],
  ["KI", kiMap],
  ["HR", hrMap],
  ["SO", soMap],
  ["PT", ptMap],
  ["SY", syMap],
  ["SN", snMap],
  ["KH", khMap],
  ["EC", ecMap],
  ["ET", etMap],
  ["DE", deMap],
  ["SL", slMap],
  ["PW", pwMap],
  ["SZ", szMap],
  ["PA", paMap],
  ["SM", smMap],
  ["IT", itMap],
  ["JO", joMap],
  ["GH", ghMap],
  ["CG", cgMap],
  ["BT", btMap],
  ["AO", aoMap],
  ["ML", mlMap],
  ["TN", tnMap],
  ["LI", liMap],
  ["MM", mmMap],
  ["MZ", mzMap],
  ["NA", naMap],
  ["CF", cfMap],
  ["BB", bbMap],
  ["CD", cdMap],
  ["BW", bwMap],
  ["AL", alMap],
  ["NC", ncMap],
  ["MX", mxMap],
  ["TZ", tzMap],
  ["VE", veMap],
  ["MN", mnMap],
  ["MY", myMap],
  ["AM", amMap],
  ["CR", crMap],
  ["BA", baMap],
  ["AZ", azMap],
  ["CV", cvMap],
  ["BR", brMap],
  ["CA", caMap],
  ["BE", beMap],
  ["TH", thMap],
  ["WS", wsMap],
  ["UZ", uzMap],
  ["Wales", walesMap],
  ["NG", ngMap],
  ["NP", npMap],
  ["MK", mkMap],
  ["BD", bdMap],
  ["BS", bsMap],
  ["CU", cuMap],
  ["BF", bfMap],
  ["NR", nrMap],
  ["NE", neMap],
  ["ZW", zwMap],
  ["VC", vcMap],
  ["UY", uyMap],
  ["TJ", tjMap],
  ["VU", vuMap],
  ["ZA", zaMap],
  ["BG", bgMap],
]);

export function getCountryName(language: string, country: Country): string {
  if (language === "fr") {
    return frenchCountryNames[country.code];
  }
  return country.name;
}

export function sanitizeCountryName(countryName: string): string {
  return countryName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[- '()]/g, "")
    .toLowerCase();
}
