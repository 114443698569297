import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import {
  countries,
  getCountryName,
  sanitizeCountryName,
  mapMap,
} from "../domain/countries";
import { useGuesses } from "../hooks/useGuesses";
import { CountryInput, countryCodeToFlag } from "./CountryInput";
import * as geolib from "geolib";
import { Share } from "./Share";
import { Guesses } from "./Guesses";
import { useTranslation } from "react-i18next";
import { SettingsData } from "../hooks/useSettings";
import { useMode } from "../hooks/useMode";
import { getDayString, useCountry } from "../hooks/useCountry";
import { Twemoji } from "react-emoji-render";

const MAX_TRY_COUNT = 6;

interface GameProps {
  settingsData: SettingsData;
}

export function Game({ settingsData }: GameProps) {
  const { t, i18n } = useTranslation();
  const dayString = useMemo(
    () => getDayString(settingsData.shiftDayCount),
    [settingsData.shiftDayCount]
  );

  const countryInputRef = useRef<HTMLInputElement>(null);

  const [country] = useCountry(dayString);

  const [currentGuess, setCurrentGuess] = useState("");
  const [guesses, addGuess] = useGuesses(dayString);
  const [hideImageMode, setHideImageMode] = useMode(
    "hideImageMode",
    dayString,
    settingsData.noImageMode
  );
  const [rotationMode, setRotationMode] = useMode(
    "rotationMode",
    dayString,
    settingsData.rotationMode
  );

  const gameEnded =
    guesses.length === MAX_TRY_COUNT ||
    guesses[guesses.length - 1]?.distance === 0;

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const guessedCountry = countries.find(
        (country) =>
          sanitizeCountryName(
            getCountryName(i18n.resolvedLanguage, country)
          ) === sanitizeCountryName(currentGuess)
      );

      if (guessedCountry == null) {
        toast.error(t("unknownCountry"));
        return;
      }

      const newGuess = {
        name: currentGuess,
        code: guessedCountry.code,
        direction: geolib.getCompassDirection(
          guessedCountry,
          country,
          (origin, dest) =>
            Math.round(geolib.getRhumbLineBearing(origin, dest) / 45) * 45
        ),
        distance: mapMap.get(country.code)?.get(guessedCountry.code),
      };

      addGuess(newGuess);
      setCurrentGuess("");

      if (newGuess.distance === 0) {
        toast.success(t("welldone"), { delay: 2000 });
      }
    },
    [addGuess, country, currentGuess, i18n.resolvedLanguage, t]
  );

  useEffect(() => {
    if (
      guesses.length === MAX_TRY_COUNT &&
      guesses[guesses.length - 1].distance > 0
    ) {
      toast.info(getCountryName(i18n.resolvedLanguage, country).toUpperCase(), {
        autoClose: false,
        delay: 1900,
      });
    }
  }, [country, guesses, i18n.resolvedLanguage]);

  return (
    <div className="flex-grow flex flex-col mx-2">
      {hideImageMode && !gameEnded && (
        <button
          className="border-2 uppercase my-2 hover:bg-gray-50 active:bg-gray-100 dark:hover:bg-slate-800 dark:active:bg-slate-700"
          type="button"
          onClick={() => setHideImageMode(false)}
        ></button>
      )}
      <div className="my-1">
        <span
          className={`max-h-52 m-auto transition-transform duration-700 ease-in text-9xl ${
            hideImageMode && !gameEnded ? "h-0" : "h-full"
          }`}
        >
          <Twemoji
            text={countryCodeToFlag(country.code)}
            options={{ ext: "svg", size: "svg" }}
          />
        </span>
      </div>
      {rotationMode && !hideImageMode && !gameEnded && (
        <button
          className="border-2 uppercase mb-2 hover:bg-gray-50 active:bg-gray-100 dark:hover:bg-slate-800 dark:active:bg-slate-700"
          type="button"
          onClick={() => setRotationMode(false)}
        ></button>
      )}
      <Guesses
        rowCount={MAX_TRY_COUNT}
        guesses={guesses}
        settingsData={settingsData}
        countryInputRef={countryInputRef}
      />
      <div className="my-2">
        {gameEnded ? (
          <>
            <Share
              guesses={guesses}
              dayString={dayString}
              settingsData={settingsData}
              hideImageMode={hideImageMode}
              rotationMode={rotationMode}
            />
            <a
              className="underline w-full text-center block mt-4"
              href={`https://flagpedia.net/${getCountryName(
                i18n.resolvedLanguage,
                country
              )}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Show Online
            </a>
          </>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="flex rounded-md flex-col">
              <CountryInput
                inputRef={countryInputRef}
                currentGuess={currentGuess}
                setCurrentGuess={setCurrentGuess}
              />
              <a
                href="https://www.youtube.com/channel/UC6kVsr9QrSG8MAxnR0dnRCQ"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="flex items-center justify-center hover:bg-gray-50 active:bg-gray-100 dark:hover:bg-slate-800 dark:active:bg-slate-700 py-2">
                  <span className="mx-1">Watch Boring Dad Gaming on</span>
                  <img
                    className={"h-4 dark:invert"}
                    alt="YouTube logo"
                    src={"./images/yt_logo_mono_light.png"}
                  />
                </div>
              </a>
              <button
                className="flex rounded-md items-center justify-center border-2 uppercase my-0.5 hover:bg-gray-50 active:bg-gray-100 dark:hover:bg-slate-800 dark:active:bg-slate-700"
                type="submit"
              >
                <span className="ml-1">{t("guess")}</span>
                <img
                  className={"h-4 w-5 dark:invert"}
                  alt="Flag logo"
                  src={"./icons/flag-svgrepo-com.svg"}
                />
              </button>
            </div>
          </form>
        )}
      </div>
      <div className="flex justify-center items-center my-4">
        <a href="https://www.warbl.org">
          <span className="flex">
            <img
              className={"ml-2 text-xl h-8 w-10 dark:invert"}
              alt="Warbl note logo"
              src={"./icons/hemidemisemiquaver-svgrepo-com.svg"}
            />
            <img
              className={"h-14 w-40 dark:invert"}
              alt="Warbl note logo"
              src={"./icons/warbl-logo.svg"}
            />
          </span>
          Try our song guessing game
        </a>
      </div>
    </div>
  );
}
