import React from "react";

export function Flagdle() {
  return (
    <span className="font-bold">
      <span className="inline-block">
        <img
          className={"h-3 w-4 dark:invert"}
          alt="Flag logo"
          src={"./icons/flag-svgrepo-com.svg"}
        />
      </span>
      <span className="text-blue-600">F</span>
      <span className="text-yellow-600">L</span>
      <span className="text-gray-600">A</span>
      <span className="text-red-600">G</span>
      <span className="text-green-600">D</span>
      <span className="text-orange-600">L</span>
      <span className="text-black-600">E</span>
    </span>
  );
}
